import { FormEvent } from "react";
import { RefetchOptions, QueryObserverResult } from "@tanstack/react-query";
import Button from "../../../../student/components/generic/button";
import { IAdminData } from "./adminUtils";

const AdminSearch: React.FC<{
  searchEmail: string;
  setSearchEmail: React.Dispatch<React.SetStateAction<string>>;
  disabled: boolean;
  admins: IAdminData[];
  searchForAdminToAdd: (
    options?: RefetchOptions
  ) => Promise<QueryObserverResult<any, Error>>;
}> = ({
  searchEmail,
  setSearchEmail,
  disabled,
  searchForAdminToAdd,
  admins,
}) => {
  //api call for searching for an admin not alreadt associated with the site

  const handleNewAdminSearch = (e: FormEvent) => {
    e.preventDefault();

    if (
      admins.some(
        (admin) =>
          admin.email.trim().toLowerCase() === searchEmail?.trim().toLowerCase()
      )
    ) {
      return;
    }

    if (searchEmail?.length > 0) {
      searchForAdminToAdd();
    }
  };

  return (
    <div className="flex flex-col gap-4 p-4">
      <div className="flex flex-row items-stretch gap-2">
        <form
          className="row flex items-stretch gap-2"
          onSubmit={handleNewAdminSearch}
        >
          <div className="self-center">Enter an email to search/add</div>

          <input
            className="min-w-[400px] rounded pl-2"
            value={searchEmail}
            disabled={disabled}
            onChange={(e) => setSearchEmail(e.target.value.toLowerCase())}
          />
          <Button submit={true}>Search</Button>
        </form>
      </div>
      {admins.some(
        (admin) =>
          admin.email.trim().toLowerCase() === searchEmail?.trim().toLowerCase()
      ) && (
        <div className="flex flex-row items-center justify-center gap-2 self-center">
          <span>
            This email is already associated with the site. Use the table above
            to manage them.
          </span>
          <Button
            onClick={() => {
              setSearchEmail("");
            }}
          >
            OK
          </Button>
        </div>
      )}
    </div>
  );
};

export default AdminSearch;
