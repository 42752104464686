import React, { useState } from "react";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { deltamathAPI } from "../../utils";
import Button from "../../../student/components/generic/button";
import Modal from "../../../student/components/generic/Modal";
import { TrashIcon } from "@heroicons/react/outline";
import ReactTooltip from "react-tooltip";

type Props = {
  versionHistory?: {
    versionKey: string;
    state: "draft" | "past" | "published" | "deleted";
    versionName: string;
  };
  type: "course" | "unit" | "subunit";
  id: string;
  icon?: boolean;
  name?: string;
  callback: () => void;
  deleteDisabled?: boolean;
};

const DeleteVersion = (props: Props) => {
  const [visible, setVisible] = useState<boolean>(false);
  const toastContext = useDeltaToastContext();

  const deleteCall = useMutation({
    mutationFn: (body: string) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/parent-portal/delete/${props.id}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess() {
      props.callback();
      setVisible(false);
      toastContext.addToast({
        status: "Success",
        message: "Deleted!",
      });
    },
    onError() {
      toastContext.addToast({
        status: "Error",
        message: `Error deleting ${props.type}`,
      });
    },
  });

  if (
    (!props.versionHistory || props.versionHistory.state === "deleted") &&
    props.type !== "subunit"
  ) {
    return <></>;
  }

  const callDelete = () => {
    const body = {
      version: props.versionHistory?.versionKey,
      type: props.type,
    };
    deleteCall.mutate(JSON.stringify(body));
  };
  ReactTooltip.rebuild();

  return (
    <div>
      {props.icon ? (
        <>
          <ReactTooltip id={`deleteSubunitTooltip-${props.id}`}>
            {props.deleteDisabled
              ? `You cannot delete a subunit in a published or previously published unit!`
              : ""}
          </ReactTooltip>
          <TrashIcon
            data-tip
            data-for={`deleteSubunitTooltip-${props.id}`}
            className="h-7 cursor-pointer"
            onClick={() => {
              if (props.deleteDisabled) {
                return;
              }
              setVisible(true);
            }}
          />
        </>
      ) : (
        <Button
          onClick={() => {
            if (props.deleteDisabled) {
              return;
            }
            setVisible(true);
          }}
        >
          Delete Version
        </Button>
      )}
      <Modal
        visible={visible}
        onClose={() => setVisible(false)}
        body={
          <>
            <p>
              Are you sure you want to delete{" "}
              {props.versionHistory?.versionName || props.name} {props.type}?
            </p>
            <p>
              {props.type === "subunit"
                ? "Once it is deleted there is no getting it back."
                : ""}
            </p>
          </>
        }
        onConfirm={callDelete}
        confirmButtonText={`Yes, delete ${props.type}`}
        secondaryButtonText="Cancel"
        secondaryOnClick={() => setVisible(false)}
        title="Warning"
      />
    </div>
  );
};

export default DeleteVersion;
