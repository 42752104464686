import clsx from "clsx";
import { uniqueId } from "lodash";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { FormFooter } from "./FormFooter";
import { Fragment, useState } from "react";
import { Listbox, Transition } from "@headlessui/react";
import Chevron from "../Chevron";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { deltamathAPI } from "../../../manager/utils";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useLocation, useParams } from "react-router-dom";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { useProblemSupportContext } from "../../contexts/ProblemSupportContext";

type FormData = {
  issue: string;
  explanation: string;
};

type RequestBody = FormData & {
  location: string;
  skillId?: string;
  problemId?: string | number;
  courseId?: string;
  unitId?: string;
  subunitId?: string;
};

export const ContactSupportForm: React.FC<{ onClose: () => void }> = ({
  onClose,
}) => {
  const {
    handleSubmit,
    control,
    register,
    formState: { isValid },
  } = useForm<FormData>({
    mode: "onChange",
  });
  const toastContext = useDeltaToastContext();
  const { learner } = useLearnerContext();
  const { problemIds, courseId, unitId, subunitId } =
    useProblemSupportContext();
  const location = useLocation();
  const { indexOfSkill } = useParams();
  const textareaId = uniqueId("explanation");
  const [nonFieldError, setNonFieldError] = useState<string | null>(null);

  const contactSupport = useMutation({
    mutationFn: (body: RequestBody) => {
      return axios.post<{ status: "success" | "error" }>(
        `${deltamathAPI()}/learner/shared/support`,
        JSON.stringify(body),
        withJsonHeader()
      );
    },
    onSuccess: (data) => {
      const message = "Thank you for contacting support!";
      toastContext.addToast({
        message,
        status: "Success",
      });
      onClose();
    },
    onError: (e) => {
      const message =
        "An error occurred while contacting support. Please try again.";
      setNonFieldError(message);
    },
  });

  const onSubmit: SubmitHandler<FormData> = (data) => {
    const includeProblemData = indexOfSkill !== undefined && problemIds;
    contactSupport.mutate({
      ...data,
      location: location.pathname,
      ...(includeProblemData
        ? {
            ...problemIds,
            courseId,
            unitId,
            subunitId,
          }
        : {}),
    });
  };

  const issues = learner?.entitlements.includes("parent")
    ? [
        "General Feedback",
        "Billing",
        "General Support",
        "I Found a Bug (Something Went Wrong)",
        "Feature Request",
        "Other",
      ]
    : [
        "General Support",
        "Billing",
        "Something Isn't Working Correctly",
        "The Math Is Incorrect",
        "Other",
      ];

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="mb-8">
        <Controller
          control={control}
          defaultValue={""}
          name="issue"
          render={({ field }) => (
            <Listbox {...field}>
              {({ open }) => (
                <>
                  <Listbox.Label className="mb-2 text-sm font-bold">
                    Issue
                  </Listbox.Label>
                  <div className="relative w-full sm:w-72">
                    <Listbox.Button className="relative w-full cursor-default rounded-[4px] border border-dm-gray-200 py-2 pl-3 pr-7 text-left text-[14px] font-bold leading-[26px] text-dm-gray-800 focus:outline-none">
                      <span className="truncate">
                        {field.value || "Select"}
                      </span>
                      <span className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-2">
                        <Chevron open={open} />
                      </span>
                    </Listbox.Button>
                    <Transition
                      as={Fragment}
                      leave="transition ease-in duration-100"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <Listbox.Options className="absolute mt-1 max-h-60 w-full overflow-auto rounded-md bg-white p-1 text-sm shadow-lg ring-1 ring-black/5 focus:outline-none">
                        {issues.map((issue) => (
                          <Listbox.Option
                            key={issue.replace(/ /g, "_")}
                            className={({ active, selected }) =>
                              clsx(
                                "relative cursor-default select-none rounded-md p-2 text-dm-gray-800",
                                active ? "bg-dm-brand-blue-100" : "",
                                selected ? "font-bold" : ""
                              )
                            }
                            value={issue}
                          >
                            {issue}
                          </Listbox.Option>
                        ))}
                      </Listbox.Options>
                    </Transition>
                  </div>
                </>
              )}
            </Listbox>
          )}
          rules={{
            required: true,
          }}
        />
      </div>
      <div className="mb-10 flex flex-col gap-1">
        <label
          htmlFor={textareaId}
          className="flex flex-col gap-2 text-sm font-bold"
        >
          Tell us more about this:
        </label>
        <textarea
          id={textareaId}
          className="h-40 w-full rounded-md border border-dm-gray-200 px-2 py-1 text-sm focus:ring-transparent"
          {...register("explanation", {
            required: true,
          })}
        />
      </div>
      {nonFieldError && (
        <div className="my-4 text-dm-error-500">{nonFieldError}</div>
      )}
      <FormFooter
        submitButtonText="Submit"
        submitDisabled={!isValid}
        secondaryButtonText="Cancel"
        secondaryOnClick={onClose}
      />
    </form>
  );
};
