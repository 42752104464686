import { useEffect, useRef, useState } from "react";
import Modal from "./generic/Modal";
import { useIdleTimer } from "react-idle-timer";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import Cookies from "js-cookie";
import { useUserContext } from "../../shared/contexts/UserContext";
import * as Sentry from "@sentry/react";
import { deltamathAPI } from "../../manager/utils";
import axios from "axios";
import { floor } from "lodash";
import { REACT_APP_HOMEPAGE_LINK } from "../../utils";
import { clearAllAnswerDataKeys } from "../utils/handleAnswerData";

const IdleCheck = () => {
  const [stillThereModal, setStillThereModal] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const userContext = useUserContext();
  // 5 minutes in ms
  const [timeRemaining, setTimeRemaining] = useState<number>(300000);
  const setTimeRef = useRef(setTimeRemaining);
  const intervalRef = useRef<number>();

  const logout = () => {
    queryClient.invalidateQueries();
    queryClient.removeQueries();
    userContext.clearJWT();
    Cookies.remove("refresh_token_javascript");
    localStorage.removeItem("admin");
    localStorage.removeItem("user");
    Sentry.setUser(null);
    clearAllAnswerDataKeys();
    window.location.href = `${REACT_APP_HOMEPAGE_LINK}`;
  };

  const refreshTokens = useMutation({
    mutationFn: () => {
      return axios.post(deltamathAPI() + "/refresh_token");
    },
    onError() {
      logout();
    },
  });

  const idlePrompt = () => {
    const timeRemainingInterval = window.setInterval(() => {
      setTimeRef.current(getRemainingTime());
    }, 1000);
    intervalRef.current = timeRemainingInterval;
    setStillThereModal(true);
  };

  const onMessage = (data: any) => {
    if (data === "close") {
      setStillThereModal(false);
    } else if (data === "logout") {
      logout();
    }
  };

  const { activate, message, getRemainingTime } = useIdleTimer({
    onIdle: logout,
    onPrompt: idlePrompt,
    // 59 min timeout
    timeout: 3540000,
    // 5 min before timeout we prompt
    promptBeforeIdle: 300000,

    throttle: 500,
    syncTimers: 500,
    crossTab: true,
    onMessage: onMessage,
  });

  const confirmStillHere = () => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }
    refreshTokens.mutate();
    activate();
    message("close");
    setStillThereModal(false);
  };

  const msToText = (ms: number) => {
    const minutes = floor(ms / 60000);
    const seconds = floor((ms - minutes * 60000) / 1000);

    if (minutes > 0) {
      return `${minutes} ${minutes > 1 ? "minutes" : "minute"}${
        seconds > 0 ? ` ${seconds} ${seconds > 1 ? "seconds" : "second"}` : ""
      }`;
    }

    return `${
      seconds > 0 ? `${seconds} ${seconds > 1 ? "seconds" : "second"}` : ""
    }`;
  };

  useEffect(() => {
    if (intervalRef.current) {
      return clearInterval(intervalRef.current);
    }
  }, []);

  return (
    <>
      <Modal
        visible={stillThereModal}
        onClose={confirmStillHere}
        onConfirm={confirmStillHere}
        header="Inactivity Log Out"
        title={
          <span className="text-lg font-bold">
            You will be logged out in{" "}
            <span className="text-dm-error-500">{msToText(timeRemaining)}</span>
          </span>
        }
        confirmButtonText="Stay Logged In"
        body="For your security, sessions automatically end after 60 minutes of inactivity unless you choose to stay logged in."
        confirmAriaLabel="Confirm still active in DeltaMath"
        secondaryButtonText="Log Out"
        secondaryAriaLabel="Log Out"
        secondaryOnClick={() => {
          message("logout");
          logout();
        }}
      />
    </>
  );
};

export default IdleCheck;
