import { ReactNode } from "react";
import {
  DMModal,
  DMModalContent,
  DMModalFooter,
  DMModalProps,
  DMModalTitle,
} from ".";
import Button from "../../student/components/generic/button";

type DMModalPropsWithOnClose = DMModalProps &
  Required<Pick<DMModalProps, "onClose">>;

interface DMAlertModalProps {
  /** A title for the alert. _Optional_ */
  title?: ReactNode;
  /**
   * The text to display on the confirmation button
   * @default "OK"
   */
  confirmText?: ReactNode;
}

/** A simple, customizeable alert modal based on `DMModal` */
export const DMAlertModal: React.FC<
  DMAlertModalProps & DMModalPropsWithOnClose
> = ({ title, confirmText = "OK", visible, onClose, children, ...props }) => (
  <DMModal visible={visible} onClose={onClose} {...props}>
    {title && <DMModalTitle>{title}</DMModalTitle>}
    <DMModalContent>{children}</DMModalContent>
    <DMModalFooter className="!justify-end">
      <Button onClick={onClose}>{confirmText}</Button>
    </DMModalFooter>
  </DMModal>
);
