import { SkillCodes } from "../../manager/components/skill-codes/SkillCodes";

/**
 * This function modifies the data for the search prototype page. It adds a property to some skill objects called searchTags.
 * The searchTags property is an array of strings that are used to search for the skill.
 * @param data  The skill codes data.
 * @returns The modified data.
 */
export function populateSearchTags(data: SkillCodes, includedYears: string[]) {
  const allCategories = getIncludedCategories(data, includedYears);
  allCategories.forEach((category) => {
    category.skills.forEach((skill) => {
      const searchTags = skillToTag[skill];
      if (searchTags) {
        const searchTagsWithAssociations = checkForAssociations(searchTags);
        data.skills[skill].searchTags = searchTagsWithAssociations;
      }
    });
  });

  return data;
}

/**
 * @param data  The skill codes data.
 * @param includedYears  The years to include in the search.
 * @returns All included categories for the included years.
 */
export function getIncludedCategories(
  data: SkillCodes,
  includedYears: string[]
) {
  const categories = data.years_order
    .filter((year) => includedYears.includes(year))
    .flatMap((year) => data.years[year].categories);
  return Object.entries(data.categories)
    .filter(([key, value]) => categories.includes(key))
    .map(([key, value]) => value);
}

/**
 * Adds associated tags to the search tags.
 * @param searchTags  The search tags for the skill.
 * Note:
 * Ultimately, I want the association check to occur upon saving the initial data, so the associations are included in the searchTags.
 * However, for the prototype, I am doing this check when modifying the data for the search page.
 */
function checkForAssociations(searchTags: string[]) {
  const allTags = [...searchTags];
  allTags.forEach((tag) => {
    const associatedTags = tagToAssociation[tag];
    if (associatedTags) {
      allTags.push(...associatedTags);
    }
  });
  return allTags;
}

/**
 * This is a list for the prototype search page with the skillcode and the corresponding search tags.
 */
const skillToTag: Record<string, string[]> = {
  _ajacoby_idPropertyLvl1: [
    "associative",
    "commutative",
    "distributive",
    "solve equations",
  ],
  _dmercier__440gcfLcmWordProblems: [
    "greatest common factor",
    "least common multiple",
    "real world",
  ],
  _dmercier__440pt5gcfLcmWordProblemsL2: [
    "greatest common factor",
    "least common multiple",
    "real world",
  ],
  _dmercier__449partsWholeMatchAndSolveIntegers: ["add", "integer", "models"],
  _dmercier__450partsWholeMatchAndSolveIntegersL2: ["add", "integer", "models"],
  _dmercier__451partsWholeMatchAndSolveWholeNumbers: [
    "add",
    "models",
    "subtract",
    "whole number",
  ],
  _dmercier__588battleshipL1: [
    "coordinate plane",
    "first quadrant",
    "ordered pair",
  ],
  _hmcdowell__411divisibilityRules: ["divide", "mental math", "whole number"],
  _hmcdowell__412plotPointIdentifyQuadrant: [
    "coordinate plane",
    "ordered pair",
  ],
  _hmcdowell__413identifyQuadrant: ["coordinate plane", "ordered pair"],
  _hmcdowell__414plotAndIdentifyPolygon: [
    "classify",
    "coordinate plane",
    "ordered pair",
  ],
  _hmcdowell__415plotPointsFindPerimeterRectilinear: [
    "coordinate plane",
    "distance",
    "ordered pair",
  ],
  _hmcdowell__416plotPointsFindAreaRectilinear: [
    "composite area",
    "coordinate plane",
    "distance",
    "ordered pair",
  ],
  _hmcdowell__417pt25ThreeDigitAddition: [
    "add",
    "mental math",
    "number sense",
    "open number line",
    "whole number",
  ],
  _hmcdowell__417pt5DecimalAdditionL1: [
    "add",
    "decimal",
    "mental math",
    "number sense",
    "open number line",
  ],
  _hmcdowell__417pt75decimalAdditionL2: [
    "add",
    "decimal",
    "mental math",
    "number sense",
    "open number line",
  ],
  _hmcdowell__417twoDigitAddition: [
    "add",
    "mental math",
    "number sense",
    "open number line",
    "whole number",
  ],
  _hmcdowell__418pt25ThreeDigitSubtractionRemoval: [
    "mental math",
    "number sense",
    "open number line",
  ],
  _hmcdowell__418pt5decimalSubtractionRemovalL1: [
    "decimal",
    "mental math",
    "number sense",
    "open number line",
    "subtract",
  ],
  _hmcdowell__418pt75decimalSubtractionRemovalL2: [
    "decimal",
    "mental math",
    "number sense",
    "open number line",
    "subtract",
  ],
  _hmcdowell__418twoDigitSubtractionRemoval: [
    "mental math",
    "number sense",
    "open number line",
  ],
  _hmcdowell__419pt25threeDigitSubtractionDistance: [
    "mental math",
    "number sense",
    "open number line",
  ],
  _hmcdowell__419pt5decimalSubtractionDistanceL1: [
    "decimal",
    "mental math",
    "number sense",
    "open number line",
    "subtract",
  ],
  _hmcdowell__419pt75decimalSubtractionDistanceL2: [
    "decimal",
    "mental math",
    "number sense",
    "open number line",
    "subtract",
  ],
  _hmcdowell__419twoDigitSubtractionDistance: [
    "mental math",
    "number sense",
    "open number line",
  ],
  _hmcdowell__420pt5decimalSubtractionDistanceVsRemoval: [
    "decimal",
    "mental math",
    "number sense",
    "open number line",
    "subtract",
  ],
  _hmcdowell__420subtractionDistanceVsRemoval: [
    "mental math",
    "number sense",
    "open number line",
  ],
  _hmcdowell__421plotAndFindDistance: ["integer"],
  _hmcdowell__421pt5decimalPlotAndFindDistance: ["integer"],
  _hmcdowell__422pt5subtractionOfSignedDecimals: [
    "decimal",
    "integer",
    "subtract",
  ],
  _hmcdowell__422subtractionOfIntegersNumberLine: ["integer", "subtract"],
  _hmcdowell__423pt5subtractionAlgorithmTwoDigitNumbers: [
    "carry",
    "subtract",
    "whole number",
  ],
  _hmcdowell__423subtractionAlgorithmTwoDigitNumbers: [
    "carry",
    "subtract",
    "whole number",
  ],
  _hmcdowell__424pt5subtractionAlgorithmThreeDigitNumbers: [
    "carry",
    "subtract",
    "whole number",
  ],
  _hmcdowell__424subtractionAlgorithmThreeDigitNumbersGuided: [
    "carry",
    "subtract",
    "whole number",
  ],
  _hmcdowell__425subtractionAlgorithmMultiDigit: [
    "carry",
    "subtract",
    "whole number",
  ],
  _hmcdowell__426pt5subtractionAlgorithmTwoDigitDecimals: [
    "carry",
    "decimal",
    "subtract",
  ],
  _hmcdowell__426subtractionAlgorithmTwoDigitDecimalsGuided: [
    "carry",
    "decimal",
    "subtract",
  ],
  _hmcdowell__427subtractionAlgorithmThreeDigitDecimalsGuided: [
    "carry",
    "decimal",
    "subtract",
  ],
  _jhall_IdPoints: ["coordinate plane", "ordered pair"],
  _jhall_IdPoints2: ["coordinate plane", "ordered pair"],
  _jprairie_volumeRectangularPrism: ["3D", "prism", "volume"],
  _kphillips__164interpretHistograms: [
    "data",
    "frequency",
    "histogram",
    "mean",
    "median",
    "mode",
    "range",
  ],
  _kphillips__164pt5InterpretStatisticalRepresentations: [
    "box plot",
    "data",
    "dot plot",
    "frequency",
    "histogram",
    "interquartile range",
    "mean",
    "median",
    "mode",
    "range",
  ],
  _kphillips__175basicPercentWordProblemsLevel2: [
    "double number line",
    "percent",
    "proportion",
    "real world",
  ],
  _kphillips__303identify3dFigureFromNet: ["3D"],
  _kphillips__378multDivByTen: ["place value"],
  _kphillips__378pt5multByMultOfTen: [],
  _kphillips__378pt75multDivByMultOfTen: [],
  _kphillips__379multDivByTenDecimals: ["place value"],
  _kphillips__381baseTenBlocksWholeNumber: ["models", "place value"],
  _kphillips__381pt5baseTenBlocksWholeNumberIdNumber: ["models", "place value"],
  _kphillips__382baseTenBlocksDecimal: ["models", "place value"],
  _kphillips__382pt5baseTenBlocksDecimalIdNumber: ["models", "place value"],
  _kphillips__383baseTenBlocksWholeNumberAdditionL1: [
    "add",
    "carry",
    "models",
    "place value",
    "whole number",
  ],
  _kphillips__384baseTenBlocksWholeNumberAdditionL2: [
    "add",
    "carry",
    "models",
    "place value",
    "whole number",
  ],
  _kphillips__385baseTenBlocksDecimalAddition: [
    "add",
    "decimal",
    "models",
    "place value",
  ],
  _kphillips__386baseTenBlocksDecimalAdditionL2: [
    "add",
    "decimal",
    "models",
    "place value",
  ],
  _kphillips__387baseTenBlocksWholeNumberSubtraction: [
    "carry",
    "models",
    "place value",
    "subtract",
    "whole number",
  ],
  _kphillips__388baseTenBlocksWholeNumberSubtractionL2: [
    "carry",
    "models",
    "place value",
    "subtract",
    "whole number",
  ],
  _kphillips__389baseTenBlocksDecimalSubtractionL1: [
    "carry",
    "decimal",
    "models",
    "place value",
    "subtract",
  ],
  _kphillips__390baseTenBlocksDecimalSubtractionL2: [
    "carry",
    "decimal",
    "models",
    "place value",
    "subtract",
  ],
  _kphillips__393timesGreaterBaseTen: ["place value", "powers of ten"],
  _kphillips__394pt5timesGreaterBaseTen: ["place value", "powers of ten"],
  _kphillips__394timesGreaterBaseTen: ["place value", "powers of ten"],
  _kphillips__395pt5scaleFactorsWholeMult: ["area model", "associative"],
  _kphillips__395wholeVsDecimalMult: [
    "area model",
    "decimal",
    "multiply",
    "place value",
  ],
  _kphillips__396wholeVsDecimalMultL2: [
    "area model",
    "decimal",
    "multiply",
    "place value",
  ],
  _kphillips__399additionAlgorithmTwoDigitNumbersGuided: [
    "add",
    "carry",
    "whole number",
  ],
  _kphillips__399pt5additionAlgorithmTwoDigitNumbers: [
    "add",
    "carry",
    "whole number",
  ],
  _kphillips__400additionAlgorithmThreeDigitNumbersGuided: [
    "add",
    "carry",
    "whole number",
  ],
  _kphillips__403additionAlgorithmThreeDigitDecimals: [
    "add",
    "carry",
    "decimal",
  ],
  _kphillips__41equivalentExpressions: [
    "combine like terms",
    "distributive",
    "equivalent",
    "expression",
  ],
  _kphillips__46percentDecilesGuided: [
    "double number line",
    "percent",
    "proportion",
  ],
  _kphillips__47percentDecilesNonGuided: [
    "double number line",
    "percent",
    "proportion",
  ],
  _kphillips__49proportionalReasoningWithPercentsL1: [
    "double number line",
    "percent",
    "proportion",
  ],
  _kphillips__50bProportionalReasoningWithPercentsL2: [
    "double number line",
    "percent",
    "proportion",
  ],
  _kphillips__50proportionalReasoningWithPercentsL2: [
    "double number line",
    "percent",
    "proportion",
  ],
  _kphillips__51basicPercents: ["double number line", "percent", "proportion"],
  _kphillips__57fractionDivisionTapeDiagram: [
    "divide",
    "fraction",
    "tape diagram",
  ],
  _kphillips__58fractionDivisionTapeDiagramL2: [
    "divide",
    "fraction",
    "tape diagram",
  ],
  _kphillips__62interpretationsOfSignedNumbers: ["integer"],
  _kphillips__63signedNumbersOnANumberLine: [
    "absolute value",
    "integer",
    "opposite",
  ],
  _kphillips__64pt5signedNumbersOnANumberLineL3: ["integer"],
  _kphillips__64signedNumbersOnANumberLineL2: ["integer"],
  _kphillips__66aOrderSignedIntegers: ["compare", "integer"],
  _kphillips__66orderNumbers: ["compare", "integer"],
  _kphillips__66pt5orderNumbersL2: ["compare", "integer"],
  _kphillips__66pt75orderNumbersL3: ["compare", "decimal", "place value"],
  _kphillips__66pt875orderNumbersL4: ["compare", "decimal", "place value"],
  _kphillips__68interpretDotPlots: [
    "data",
    "dot plot",
    "frequency",
    "mean",
    "median",
    "mode",
    "range",
  ],
  _kphillips__69interpretBoxPlots: [
    "box plot",
    "data",
    "interquartile range",
    "median",
    "range",
  ],
  _kphillips__addingAndSubtractingFractions: [
    "add",
    "common denominator",
    "fraction",
    "subtract",
  ],
  _kphillips__appliedNetChange: ["integer"],
  _kphillips__areaOfCompositeShapes: ["composite area"],
  _kphillips__areaOfCompositeShapesOnCoordinateGrid: [
    "composite area",
    "coordinate plane",
  ],
  _kphillips__areaOfCompositeShapesOnCoordinateGridLevel2: [
    "composite area",
    "coordinate plane",
  ],
  _kphillips__areaOfTriangles: ["area", "triangle"],
  _kphillips__distributionViaAreaModelVariableExpressions: [
    "area model",
    "distributive",
    "equivalent",
    "expand",
    "expression",
  ],
  _kphillips__distributionViaAreaModelWholeNumber: [
    "area model",
    "distributive",
  ],
  _kphillips__distributionViaAreaModelWholeNumberLevel2: [
    "area model",
    "distributive",
  ],
  _kphillips__distributionViaAreaModelWholeNumberMC: [
    "area model",
    "distributive",
    "equivalent",
    "expression",
    "factor",
  ],
  _kphillips__distributionViaAreaModelWholeNumberSubtraction: [
    "area model",
    "distributive",
  ],
  _kphillips__distributiveProperty: [
    "area model",
    "equivalent",
    "expand",
    "expression",
  ],
  _kphillips__doubleNumberLineCountingUp: [
    "double number line",
    "equivalent",
    "proportion",
    "ratio",
  ],
  _kphillips__doubleNumberLineScalingUpDown: [
    "double number line",
    "equivalent",
    "proportion",
    "ratio",
    "reduce",
  ],
  _kphillips__doubleNumberLineScalingUpDownLevel2: [
    "double number line",
    "equivalent",
    "proportion",
    "ratio",
    "reduce",
  ],
  _kphillips__doubleNumberLineScalingUpDownLevel3: [
    "double number line",
    "equivalent",
    "proportion",
    "ratio",
    "reduce",
  ],
  _kphillips__equivalentFractions: [
    "area model",
    "equivalent",
    "fraction",
    "reduce",
    "simplify",
  ],
  _kphillips__equivalentFractionsAreaModel: [
    "area model",
    "equivalent",
    "fraction",
  ],
  _kphillips__equivalentFractionsAreaModelL2: [
    "area model",
    "equivalent",
    "fraction",
    "reduce",
    "simplify",
  ],
  _kphillips__equivalentFractionsAreaModelL3: [
    "area model",
    "equivalent",
    "fraction",
    "reduce",
    "simplify",
  ],
  _kphillips__equivalentFractionsL2: [
    "equivalent",
    "fraction",
    "reduce",
    "simplify",
  ],
  _kphillips__evaluateExpressions: ["substitute"],
  _kphillips__evaluatingExpressionsWithExponents: ["order of operations"],
  _kphillips__fractionsOnANumberLine: [
    "common denominator",
    "compare",
    "fraction",
  ],
  _kphillips__fractionsOnANumberLineL2: [
    "common denominator",
    "compare",
    "fraction",
  ],
  _kphillips__fractionsOnANumberLineL3: [
    "common denominator",
    "compare",
    "fraction",
  ],
  _kphillips__fractionWordProblemsGrade6: [
    "divide",
    "fraction",
    "multiply",
    "real world",
  ],
  _kphillips__fractionWordProblemsGrade6Level2: [
    "divide",
    "fraction",
    "multiply",
    "real world",
  ],
  _kphillips__greaterThanLessThanOnNumberLine: [
    "compare",
    "inequality",
    "integer",
  ],
  _kphillips__greatestCommonFactor: ["greatest common factor"],
  _kphillips__horizontalVerticalDistanceWithoutPlot: [
    "coordinate plane",
    "distance",
    "ordered pair",
  ],
  _kphillips__horizontalVerticalDistanceWithPlot: [
    "coordinate plane",
    "distance",
    "ordered pair",
  ],
  _kphillips__improperFractionsAndMixedNumbers: [
    "equivalent",
    "fraction",
    "tape diagram",
  ],
  _kphillips__improperFractionsAndMixedNumbersDirect: [
    "equivalent",
    "fraction",
  ],
  _kphillips__introToRatiosLevel1: ["equivalent", "ratio", "reduce"],
  _kphillips__introToRatiosLevel2: ["equivalent", "ratio", "reduce"],
  _kphillips__leastCommonMultiple: ["least common multiple"],
  _kphillips__matchingTablesToContexts: [
    "double number line",
    "equivalent",
    "proportion",
    "ratio",
  ],
  _kphillips__multiplyAndDivideFractions: ["divide", "fraction", "multiply"],
  _kphillips__multiplyAndDivideMixedNumbers: [
    "divide",
    "equivalent",
    "fraction",
    "multiply",
  ],
  _kphillips__oneStepEquationsWithDecimals: ["solve equations"],
  _kphillips__oneStepEquationsWithWholeNumbers: ["solve equations"],
  _kphillips__orderFractionsGraphically: ["compare", "fraction"],
  _kphillips__percentageWordProblems: [
    "double number line",
    "percent",
    "proportion",
    "real world",
  ],
  _kphillips__percentsQuartilesGuided: [
    "double number line",
    "percent",
    "proportion",
  ],
  _kphillips__percentsQuartilesNonGuided: [
    "double number line",
    "percent",
    "proportion",
  ],
  _kphillips__perimeterAlgebraic: [
    "combine like terms",
    "equivalent",
    "expression",
  ],
  _kphillips__perimeterNumerical: [],
  _kphillips__plotIntegersOnANumberLine: ["integer"],
  _kphillips__plottingPointsChangeOfScale: [
    "coordinate plane",
    "first quadrant",
    "ordered pair",
  ],
  _kphillips__plottingPointsQuadrant1: ["coordinate plane", "ordered pair"],
  _kphillips__pointsReflectedOverAnAxis: [
    "coordinate plane",
    "reflect",
    "rigid",
    "transformation",
  ],
  _kphillips__pointsReflectedOverAnAxisGraphically: [
    "coordinate plane",
    "reflect",
    "rigid",
    "transformation",
  ],
  _kphillips__proportionalReasoningRates: [
    "double number line",
    "proportion",
    "ratio",
    "unit rate",
  ],
  _kphillips__proportionalReasoningWithChangeOfUnits: [
    "convert units",
    "double number line",
    "proportion",
    "ratio",
    "unit rate",
  ],
  _kphillips__scalingPercents: ["double number line", "percent", "proportion"],
  _kphillips__simplifyingFractions: [
    "equivalent",
    "fraction",
    "reduce",
    "simplify",
  ],
  _kphillips__simplifyingRatios: ["equivalent", "ratio", "reduce"],
  _kphillips__simplifyingRatiosBasic: ["equivalent", "ratio", "reduce"],
  _kphillips__surfaceAreaFromANet: ["3D"],
  _kphillips__surfaceAreaFromANetLevel2: ["3D"],
  _kphillips__tapeDiagramModelLinearEquations: [
    "solve equations",
    "tape diagram",
  ],
  _kphillips__tapeDiagramModelLinearEquationsMC: ["tape diagram"],
  _kphillips__unitRateUnitCost: [
    "double number line",
    "equivalent",
    "proportion",
    "ratio",
    "reduce",
    "unit rate",
  ],
  _kphillips__valueThatMakesEquationTrue: ["substitute"],
  _mbaum__428subtractionAsAddingTheOppositeRewrite: [
    "add",
    "equivalent",
    "expression",
    "integer",
    "subtract",
  ],
  _mbaum__429combiningIntegersNoNumberLine: [
    "integer",
    "mental math",
    "subtract",
  ],
  _mbaum__430exponentsExpandCondenseWholeNumberBase: [
    "equivalent",
    "expression",
  ],
  _mbaum__431exponentsExpandAndComputeL2: ["equivalent", "expression"],
  _mbaum__432expandAndCondenseFractionalBase: [
    "equivalent",
    "expand",
    "expression",
  ],
  _mbaum__435primeFactorization: ["factor"],
  _mbaum__439gcfLcmDivisionAlgorithm: [
    "greatest common factor",
    "least common multiple",
  ],
  _mrosenwasser__573moneyModelDecimalDivision: [
    "decimal",
    "divide",
    "real world",
  ],
  _mrosenwasser__574multiplicationDivisionOfIntegersL1: [
    "divide",
    "integer",
    "multiply",
  ],
  _mrosenwasser__575multiplicationDivisionOfIntegersL2: [
    "divide",
    "integer",
    "multiply",
  ],
  _mrosenwasser__576pemdasPositiveNoExponentGuided: ["order of operations"],
  _mrosenwasser__576pt5pemdasPositiveNoExponent: ["order of operations"],
  _mrosenwasser__577pemdasIntegersNoExponentGuided: ["order of operations"],
  _mrosenwasser__577pt5pemdasIntegersNoExponent: ["order of operations"],
  _mrosenwasser__578pemdasPositiveExponentsGuided: [
    "exponent",
    "order of operations",
  ],
  _mrosenwasser__579pemdasIntegerExponentsGuided: [
    "exponent",
    "order of operations",
  ],
  _mrosenwasser__579pt5pemdasIntegerExponents: [
    "exponent",
    "order of operations",
  ],
  _mrosenwasser__579pt7operationsWithDecimalsPositive: [
    "decimal",
    "order of operations",
  ],
  _mrosenwasser__579pt8operationsWithDecimalsPositiveAndNegative: [
    "decimal",
    "order of operations",
  ],
  _mrosenwasser__580absoluteValueAsDistanceFromZero: ["absolute value"],
  _mrosenwasser__581EvaluateAbsoluteValueBasic: ["absolute value"],
  _mrosenwasser__583evaluateExponentsPosNegBase: ["exponent"],
  _sling__153ProbabilityLanguageL1: [
    "compound events",
    "compound probability",
    "probability",
    "theoretical probability",
  ],
  _sling__154ProbabilityLanguageL2: ["probability"],
  _sling__155MostLeastLikely: [
    "compare",
    "probability",
    "theoretical probability",
  ],
  _sling__157SimpleProbability: ["experimental probability", "probability"],
  _sling__158SimpleProbabilityL2: ["experimental probability", "probability"],
  _sling__159ExpectedValue: ["experimental probability", "probability"],
  _sling__160FundamentalCountingPrinciple: ["probability", "sample space"],
  _sling__160p5FundamentalCountingPrincipleL2: ["probability", "sample space"],
  _sling__160p75PermutationsBasic: ["probability"],
  _sling__299AreaPuzzlesRectangles: ["composite area"],
  _sling__375WhichIsMoreRatioConceptL1: ["compare", "ratio"],
  _sling__376pt1WhichIsMoreFractionComparison: [
    "common denominator",
    "compare",
    "fraction",
    "proportion",
    "ratio",
  ],
  _sling__376pt2WhichIsMorePercentComparison: [
    "compare",
    "percent",
    "proportion",
    "ratio",
  ],
  _sling__376WhichIsMoreRatioConceptL2: ["compare", "equivalent", "ratio"],
  _sling__391AreaModelDecimalTimesWholeNumberL1: [
    "area model",
    "decimal",
    "distributive",
    "multiply",
  ],
  _sling__392AreaModelDecimalTimesWholeNumberL2: [
    "area model",
    "decimal",
    "distributive",
    "multiply",
  ],
  _sling__397AreaModelMultiplicationTwoDigitNumbersL1: [
    "area model",
    "distributive",
  ],
  _sling__397pt5AreaModelMultiplicationTwoDigitNumbersL2: [
    "area model",
    "distributive",
  ],
  _sling__398AreaModelMultiplicationTwoDigitDecimals: [
    "area model",
    "decimal",
    "distributive",
    "multiply",
  ],
  _sling__400pt5AdditionAlgorithmThreeDigitNumbers: [
    "add",
    "carry",
    "whole number",
  ],
  _sling__400pt75AddtionAlgorithm3DigitNumbersMissingDigits: [
    "add",
    "carry",
    "whole number",
  ],
  _sling__401AdditionAlgorithmMultidigit: ["add", "carry", "whole number"],
  _sling__401pt5AdditionAlgorithmMultidigtMissingDigits: [
    "add",
    "carry",
    "whole number",
  ],
  _sling__402AdditionAlgorithmTwoDigitDecimalsGuided: [
    "add",
    "carry",
    "decimal",
  ],
  _sling__402pt5AdditionAlgorithmTwoDigitDecimal: ["add", "carry", "decimal"],
  _sling__403pt5AdditionAlgorithmThreeDigitDecimals: [
    "add",
    "carry",
    "decimal",
  ],
  _sling__404AdditionAlgorithmMultidigitDecimals: ["add", "carry", "decimal"],
  _sling__405MultiplicationAlgorithm2x1DigitGuided: [
    "carry",
    "multiply",
    "whole number",
  ],
  _sling__405pt5MultiplicationAlgorithm2x1Digit: [
    "carry",
    "multiply",
    "whole number",
  ],
  _sling__406MultiplicationAlgorithm2x2DigitGuided: [
    "carry",
    "multiply",
    "whole number",
  ],
  _sling__406pt5MultiplicationAlgorithm2x2Digit: [
    "carry",
    "multiply",
    "whole number",
  ],
  _sling__407MultiplicationAlgorithm3x3DigitGuided: [
    "carry",
    "multiply",
    "whole number",
  ],
  _sling__407pt5MultiplicationAlgorithm3x3Digit: [
    "carry",
    "multiply",
    "whole number",
  ],
  _sling__408MultiplicationAlgorithmDecimal2x1DigitGuided: [
    "carry",
    "decimal",
    "multiply",
  ],
  _sling__408pt5MultiplicationAlgorithmDecimal2x1Digit: [
    "carry",
    "decimal",
    "multiply",
  ],
  _sling__409MultiplicationAlgorithmDecimal2x2DigitGuided: [
    "carry",
    "decimal",
    "multiply",
  ],
  _sling__409pt5MultiplicationAlgorithmDecimal2x2Digit: [
    "carry",
    "decimal",
    "multiply",
  ],
  _sling__410MultiplicationAlgorithmDecimal3x3DigitGuided: [
    "carry",
    "decimal",
    "multiply",
  ],
  _sling__410pt5MultiplicationAlgorithmDecimal3x3Digit: [
    "carry",
    "decimal",
    "multiply",
  ],
  _sling__437MultiplicationOfIntegersContextGuided: [
    "integer",
    "multiply",
    "real world",
  ],
  _sling__438MultiplicationOfIntegersContext2Guided: [
    "integer",
    "multiply",
    "real world",
  ],
  _sling__584DoublingOrTriplingARecipeWholeNumber: ["equivalent", "ratio"],
  _sling__584pt5HalvingOrThirdingARecipeWholeNumber: ["equivalent", "ratio"],
  _sling__61MultiplyDividePowerof10: ["decimal", "place value"],
  _sling__67FindingMeanAndMedian: ["mean", "median", "mode"],
  addingIntegers: ["integer"],
  addingIntegersNumberLineL1: ["add", "integer"],
  addingIntegersNumberLineL2: ["add", "integer"],
  addingSubtractingIntegers: ["integer", "mental math", "subtract"],
  combiningIntegers: ["integer", "mental math", "subtract"],
  createBoxPlot: ["box plot", "data"],
  createFrequencyTable: ["data", "frequency"],
  createHistogram: ["data", "frequency", "histogram"],
  divMultUnitFraction: ["divide", "fraction", "multiply", "number sense"],
  equivalentRatioTableGuided: ["proportion", "ratio table"],
  greatestCommonFactorTimed: ["greatest common factor"],
  identifyFractions: [
    "circle diagram",
    "equivalent",
    "fraction",
    "improper fraction",
    "mixed numbers",
  ],
  inequalityTrueFalse: ["compare", "inequality", "integer"],
  interpretBoxAndWhisker: [
    "box plot",
    "data",
    "interquartile range",
    "median",
    "range",
  ],
  leastCommonMultipleTimed: ["least common multiple"],
  meanGraphicallySpread: ["average"],
  multiply: ["mental math"],
  multiplyFractionsVisually: ["area model", "fraction", "multiply"],
  multiplyingDividingIntegers: ["integer", "mental math"],
  orderOfOperations: ["evaluate", "expression", "order of operations"],
  plotPoints: ["coordinate plane", "ordered pair"],
  propertiesAlgebraically: [
    "associative",
    "commutative",
    "distributive",
    "equivalent",
    "expression",
  ],
  propertiesNumerically: [
    "associative",
    "commutative",
    "distributive",
    "equivalent",
    "expression",
  ],
  ratioLanguageReduce: ["equivalent", "ratio", "reduce", "simplify"],
  rectangularPrismVolumeConcept: ["3D", "prism", "volume"],
  reduceFractionsGraphically: [
    "circle diagram",
    "equivalent",
    "fraction",
    "reduce",
    "simplify",
  ],
  roundingLevel1: ["place value"],
  squares1_16: ["exponent", "mental math"],
  tableEquivRatioPlot: ["proportion", "ratio table"],
  triangleAreaInteractive: ["area", "triangle"],
  unitRatesGuided: ["equivalent", "proportion", "ratio", "reduce", "unit rate"],
  visualRatios: ["ratio", "reduce", "simplify"],
  wordExpression: [],
  wordExpressionCompound: [],
  _ajacoby_completeSquareAllEqn: [
    "complete the square",
    "quadratic equation",
    "solve quadratic",
  ],
  _ajacoby_completeSquareEvenEqn: [
    "complete the square",
    "quadratic equation",
    "solve quadratic",
  ],
  _ajacoby_idPropertyLvl2: [
    "associative",
    "commutative",
    "distributive",
    "solve equations",
  ],
  _bmueller_completingTheSquare: [
    "complete the square",
    "quadratic equation",
    "standard form",
    "vertex form",
  ],
  _bmueller_familiesFromTable: ["linear", "quadratic", "exponential"],
  _bmueller_quadFactoredToStandard: [
    "factored form",
    "quadratic equation",
    "standard form",
  ],
  _bmueller_quadInterceptCoefficients: [
    "factored form",
    "quadratic equation",
    "root",
  ],
  _bmueller_quadStandardCoefficients: ["quadratic equation", "standard form"],
  _bmueller_quadVertexCoefficients: [
    "function transformation",
    "quadratic equation",
    "vertex",
    "vertex form",
  ],
  _bmueller_quadVertexToStandard: [
    "quadratic equation",
    "standard form",
    "vertex form",
  ],
  _bmueller_solvingEquationsDistClt: [
    "combine like terms",
    "distributive",
    "solve equations",
  ],
  _bmueller_storiesToGraphs: ["linear", "piecewise"],
  _bmueller_systemsInequalitiesChoosePoint: [],
  _bmueller_tableToEquation1: [
    "equation",
    "linear",
    "slope intercept form",
    "table",
    "write",
    "y=mx+b",
  ],
  _bmueller_tableToEquation2: [
    "equation",
    "linear",
    "slope intercept form",
    "table",
    "write",
    "y=mx+b",
  ],
  _csterner_twostepeq: ["solve equations"],
  _dmercier__443PartsWholeMatchAndSolvePolynomialL1: [
    "add",
    "combine like terms",
    "models",
    "polynomial",
    "subtract",
  ],
  _dmercier__444PartsWholeMatchAndSolvePolynomialL2: [
    "add",
    "combine like terms",
    "models",
    "polynomial",
    "subtract",
  ],
  _dmercier__525graphingQuadraticsFromParentL1: [
    "function transformation",
    "graph",
    "quadratic",
  ],
  _dmercier__526graphingAbsoluteValueFunctionsFromParentL1: [
    "absolute value",
    "function transformation",
    "graph",
  ],
  _dmercier__527graphingSquareRootFunctionsFromParentL1: [
    "function transformation",
    "graph",
  ],
  _dmercier__528graphingReciprocalFunctionsFromParentL1: [
    "function transformation",
  ],
  _dmercier__529makeTablePlotGraphFindDrQuadraticsL1: [
    "domain range",
    "graph",
    "quadratic",
  ],
  _dmercier__529pt5makeTablePlotGraphFindDrQuadraticsL2: [
    "domain range",
    "graph",
    "quadratic",
  ],
  _dmercier__530makeTablePlotGraphFindDRAbsoluteValueL1: [
    "absolute value",
    "domain range",
    "graph",
  ],
  _dmercier__530pt5makeTablePlotGraphFindDRAbsoluteValueL2: [
    "absolute value",
    "domain range",
    "graph",
  ],
  _dmercier__531makeTablePlotGraphFindDrSquareRootL1: ["domain range", "graph"],
  _jhall_AbsValFunc: [],
  _jhall_AbsValFunc2: [],
  _jhall_AddBinomLev1: [
    "add",
    "binomial",
    "combine like terms",
    "models",
    "polynomial",
    "subtract",
  ],
  _jhall_AlgTiles1: ["combine like terms", "expression", "models"],
  _jhall_AlgTiles2: ["combine like terms", "expression", "models"],
  _jhall_AlgTiles3: ["combine like terms", "expression", "models"],
  _jhall_CLTAlgTiles: ["combine like terms", "models"],
  _jhall_CLTAlgTiles2: ["combine like terms", "models"],
  _jhall_CLTAlgTiles3: ["combine like terms", "models"],
  _jhall_DetermineIntFactoredForm: [
    "factored form",
    "quadratic equation",
    "root",
    "zero product property",
  ],
  _jhall_EliminationMC: ["system of equations"],
  _jhall_elimlevel1: ["system of equations"],
  _jhall_elimlevel1FIRSTsteponly: ["system of equations"],
  _jhall_elimlevel2: ["system of equations"],
  _jhall_ExpressMat1: ["combine like terms", "expression", "models"],
  _jhall_ExpressMat2: ["combine like terms", "expression", "models"],
  _jhall_FuncNotGraphL2: ["evaluate", "graph"],
  _jhall_LinEqDisProp: ["distributive", "solve equations"],
  _jhall_MissingGenRec: [
    "area model",
    "binomial",
    "distributive",
    "equivalent",
    "expand",
    "expression",
    "multiply",
    "polynomial",
  ],
  _jhall_PerAlgTiles: ["combine like terms", "expression", "models"],
  _jhall_PerAlgTiles2: ["combine like terms", "expression", "models"],
  _jhall_QuadFunc: [],
  _jhall_QuadFunc2: [],
  _jhall_RatFunc1: [],
  _jhall_simpleProportions: ["equation", "proportion", "solve equations"],
  _jhall_SquRootFun2: ["radical", "radical function"],
  _jhall_SquRootFunc: ["radical", "radical function"],
  _jhall_sublevel1: ["system of equations"],
  _jhall_sublevel2: ["system of equations"],
  _jhall_sublevel3: ["system of equations"],
  _jhall_Table2LinEq0: [
    "equation",
    "linear",
    "slope intercept form",
    "table",
    "write",
    "y=mx+b",
  ],
  _jhall_TablePatterns: ["linear", "table"],
  _kphillips__131solveEquationsInContextUnwinding: [
    "solve equations",
    "tape diagram",
  ],
  _kphillips__132solveInequalitiesInContextUnwinding: [
    "inequality",
    "solve inequality",
    "write",
  ],
  _kphillips__133equationsInContextMC: [
    "equation",
    "real world",
    "tape diagram",
    "write",
  ],
  _kphillips__134inequalitiesInContextMC: ["inequality", "real world", "write"],
  _kphillips__135writeAndSolveEquationsInContext: [
    "equation",
    "real world",
    "solve equations",
    "tape diagram",
    "write",
  ],
  _kphillips__136writeAndSolveInequalitiesInContext: [
    "inequality",
    "real world",
    "solve inequality",
    "write",
  ],
  _kphillips__137basicAlgebraicInequalities: ["inequality", "substitute"],
  _kphillips__138basicAlgebraicInequalitiesL2: ["inequality", "substitute"],
  _kphillips__138pt5basicAlgebraicInequalitiesL3: ["inequality", "substitute"],
  _kphillips__139valuesThatMakeAnInequalityTrue: ["inequality", "substitute"],
  _kphillips__172pt5centerAndSpread: [
    "center",
    "compare",
    "interquartile range",
    "mean",
    "median",
    "mode",
    "spread",
  ],
  _kphillips__190interpretLinearEquationInContextMC: [
    "interpret slope",
    "linear",
    "real world",
    "slope intercept form",
    "y=mx+b",
  ],
  _kphillips__191interpretLinearGraphInContext: [
    "graph",
    "interpret slope",
    "line",
    "linear",
    "real world",
  ],
  _kphillips__193writeExpressionFromContext: ["expression", "real world"],
  _kphillips__194writeExpressionMixed: [
    "expression",
    "real world",
    "slope intercept form",
    "y=mx+b",
  ],
  _kphillips__195identifyXandYIntercept: [
    "linear",
    "standard form",
    "x y intercept",
  ],
  _kphillips__196graphsOfAxBy: [
    "graph",
    "line",
    "linear",
    "standard form",
    "x y intercept",
  ],
  _kphillips__196pt5GraphsOfAxByIntercepts: [
    "graph",
    "line",
    "linear",
    "standard form",
    "x y intercept",
  ],
  _kphillips__197scalingExpressions: [],
  _kphillips__198drawLineOfBestFit: [
    "linear",
    "linear regression",
    "scatter plot",
  ],
  _kphillips__199interpretLineOfBestFit: [
    "interpret slope",
    "linear",
    "linear regression",
    "scatter plot",
  ],
  _kphillips__201associationCorrelationScatterPlot: [
    "correlation",
    "linear",
    "scatter plot",
  ],
  _kphillips__377polyDivideByMono: ["divide", "polynomial"],
  _kphillips__compareRatesOfChangeGraphTableEquation: [
    "graph",
    "line",
    "linear",
    "rate of change",
    "slope",
    "slope intercept form",
    "table",
    "y=mx+b",
  ],
  _kphillips__compareRatesOfChangeInContext: [
    "graph",
    "line",
    "linear",
    "rate of change",
    "slope",
    "slope intercept form",
    "table",
    "y=mx+b",
  ],
  _kphillips__graphStoriesLevel1: ["linear", "piecewise"],
  _kphillips__graphStoriesLevel2: ["linear", "piecewise"],
  _kphillips__interpretingGraphStories: ["linear", "piecewise"],
  _kphillips__standardToVertexForm: [
    "complete the square",
    "quadratic equation",
    "standard form",
    "vertex form",
  ],
  _lbodner__469EvaluateFunctionsfromMultipleRepresentations: [],
  _mbaum__433exponentsExpandCondenseVariableBase: [
    "equivalent",
    "expand",
    "expression",
  ],
  _mbaum__434exponentsExpandCondenseMonomialBase: [
    "equivalent",
    "expand",
    "exponent",
    "expression",
  ],
  _mrosenwasser__441greatestCommonFactorVariableL1: ["greatest common factor"],
  _mrosenwasser__442greatestCommonFactorVariableL2: ["greatest common factor"],
  _sling__128p75TwoStepEquationsFractionalCoefficient: ["solve equations"],
  _sling__128p875TwoStepEquationsFractionalCoefficientL2: [
    "reciprocal",
    "solve equations",
  ],
  _sling__128TwoStepEquationsWithDecimals: ["solve equations"],
  _sling__173CompoundInequalitiesL1: [
    "compound inequality",
    "graph",
    "inequality",
    "solve inequality",
  ],
  _sling__174CompoundInequalitiesL2: [
    "compound inequality",
    "graph",
    "inequality",
    "solve inequality",
  ],
  _sling__176AbsoluteValueEquationsBasicL1: [
    "absolute value",
    "solve equations",
  ],
  _sling__177AbsoluteValueEquationsBasicL2: [
    "absolute value",
    "solve equations",
  ],
  _sling__202InterpretTwoWayTables: ["two way table"],
  _sling__202p5PercentsInTwoWayTables: ["two way table"],
  _sling__209SquaresCubesAndRoots: ["cube root", "radical"],
  _sling__210BasicSquareandCubicEquations: ["radical"],
  _sling__273WriteEquationOfLinePointSlopeForm: [
    "equation",
    "linear",
    "point slope form",
    "write",
  ],
  _sling__325CreateScatterPlotFromDataTable: ["correlation", "scatter plot"],
  _sling__326MatchExponentialExpressionAndContext: [
    "exponential equation",
    "exponential growth decay",
    "real world",
  ],
  _sling__327SolveLinearSystemGraphicallyGraphsGiven: ["system of equations"],
  _sling__328p10PlotPointsAndGraphExponentialFunction: [
    "exponential",
    "exponential equation",
    "graph",
    "graphing calculator",
  ],
  _sling__328p11PlotPointsAndGraphCubicFunction: ["graphing calculator"],
  _sling__328p12PlotPointsAndGraphCubeRootFunction: ["graphing calculator"],
  _sling__328p13PlotPointsAndGraphExponentialFunctionTableL1: [
    "exponential",
    "exponential equation",
    "graph",
  ],
  _sling__328p14PlotPointsAndGraphExponentialFunctionL1: [
    "exponential",
    "exponential equation",
    "graph",
    "graphing calculator",
  ],
  _sling__328p1PlotPointsAndGraphQuadraticFunctionTable: [
    "graph",
    "quadratic",
    "quadratic equation",
  ],
  _sling__328p2PlotPointsAndGraphSquareRootFunctionTable: ["graph", "radical"],
  _sling__328p3PlotPointsAndGraphAbsoluteValueFunctionTable: [
    "absolute value",
    "graph",
  ],
  _sling__328p4PlotPointsAndGraphExponentialFunctionTable: [
    "exponential",
    "exponential equation",
    "graph",
  ],
  _sling__328p5PlotPointsAndGraphCubicFunctionTable: [],
  _sling__328p6PlotPointsAndGraphCubeRootFunctionTable: [],
  _sling__328p7PlotPointsAndGraphQuadraticFunction: [
    "graph",
    "graphing calculator",
    "quadratic",
    "quadratic equation",
  ],
  _sling__328p8PlotPointsAndGraphSquareRootFunction: [
    "graph",
    "graphing calculator",
    "radical",
  ],
  _sling__328p9PlotPointsAndGraphAbsoluteValueFunction: [
    "absolute value",
    "graph",
    "graphing calculator",
  ],
  _sling__329FunctionEqualityGraphs: [
    "function",
    "intersection",
    "solve graphically",
  ],
  _sling__330FunctionEqualityGraphingTechRequired: [
    "function",
    "graphing calculator",
    "intersection",
    "solve graphically",
  ],
  _sling__331InterpretGraphsOfQuadraticsInContext: [
    "features quadratic",
    "graph",
    "minimum",
    "quadratic",
    "real world",
    "root",
    "vertex",
  ],
  _sling__332InterpretQuadraticsInContextGraphingTech: [
    "domain",
    "features quadratic",
    "graph",
    "graphing calculator",
    "minimum",
    "quadratic",
    "real world",
    "root",
    "vertex",
  ],
  _sling__333QuadraticWordProblemsL2: [
    "factor",
    "polynomial",
    "real world",
    "solve quadratic",
    "trinomial",
    "zero product property",
  ],
  _sling__334InterpretStepFunctionsInContext: [],
  _sling__335ConsecutiveIntegerProblemsLinearL1: [
    "equation",
    "solve equations",
    "write",
  ],
  _sling__336ConsecutiveIntegerProblemsLinearL2: [
    "equation",
    "solve equations",
    "write",
  ],
  _sling__337ConsecutiveIntegerProblemsQuadratic: [
    "factor",
    "polynomial",
    "real world",
    "solve quadratic",
    "trinomial",
    "zero product property",
  ],
  _sling__338LinearEquationsWithDistributionL3: [
    "combine like terms",
    "distributive",
    "solve equations",
  ],
  _sling__339LinearVsExponentialContexts: [
    "exponential equation",
    "exponential growth decay",
    "linear exponential",
  ],
  _sling__340LinearVsExponentialFunctionsAndModels: [
    "exponential equation",
    "linear exponential",
  ],
  _sling__342DirectVariationProportion: [
    "constant of proportionality",
    "proportion",
  ],
  _sling__343DirectVariationProportionWordProblems: [
    "constant of proportionality",
    "proportion",
    "real world",
  ],
  _sling__344IntroToFractionalExponentsGuided: [],
  _sling__454GraphingRationalFunctionsBasic: ["rational function"],
  _sling__456DomainAndRangeFromPointsOrTable: ["domain range"],
  _sling__460WriteTranslatedFunctionL1: [
    "function transformation",
    "translate",
  ],
  _sling__461WriteTranslatedFunctionL2: [
    "function transformation",
    "translate",
  ],
  _sling__473pt5FindExplicitSequenceFunctionFromGraph: [
    "arithmetic sequence",
    "discrete continuous",
    "exponential equation",
    "geometric sequence",
    "linear",
    "linear exponential",
  ],
  _sling__473SequencesVsFunctionsFromGraph: [
    "arithmetic sequence",
    "discrete continuous",
    "exponential equation",
    "geometric sequence",
    "linear",
    "linear exponential",
  ],
  _sling__474CalculateResidualFromContextLinear: ["residual"],
  _sling__475LinearRegressionResidualsBestModel: [
    "linear",
    "linear regression",
    "real world",
    "residual",
  ],
  absoluteValueInequalitiesL1: [
    "absolute value",
    "inequality",
    "solve inequality",
  ],
  addingRadicals: ["combine like terms", "radical"],
  addingRadicalsWithX: ["radical"],
  addSubtractPolynomials: [
    "add",
    "combine like terms",
    "polynomial",
    "subtract",
  ],
  appropriateDomainRealWorldFunctions: ["domain range", "real world"],
  areaWordProblems: [
    "factor",
    "polynomial",
    "real world",
    "solve quadratic",
    "trinomial",
    "zero product property",
  ],
  arithmeticVsGeometricFindPattern: [
    "arithmetic sequence",
    "common difference",
    "common ratio",
    "geometric sequence",
  ],
  averageRateOfChangeEquation: [],
  averageRateOfChangeGraph: [],
  averageRateOfChangeGraph2: [],
  averageRateOfChangeTable: [],
  axisOfSymmetryVertex: ["features quadratic", "graph", "minimum", "quadratic"],
  axisOfSymmetryVertexTech: [
    "features quadratic",
    "graph",
    "graphing calculator",
    "minimum",
    "quadratic",
  ],
  basicRadicalOpsTimed: ["combine like terms", "multiply", "radical"],
  binomialToPower: [
    "binomial",
    "binomial powers",
    "distributive",
    "equivalent",
    "expand",
    "expression",
    "multiply",
    "polynomial",
  ],
  boxMethodGuidedLevel1: [
    "area model",
    "binomial",
    "distributive",
    "equivalent",
    "expand",
    "expression",
    "multiply",
    "polynomial",
  ],
  boxMethodGuidedLevel2: [
    "area model",
    "binomial",
    "distributive",
    "equivalent",
    "expand",
    "expression",
    "multiply",
    "polynomial",
    "trinomial",
  ],
  boxMethodGuidedLevel3: [
    "area model",
    "binomial",
    "distributive",
    "equivalent",
    "expand",
    "expression",
    "multiply",
    "polynomial",
    "trinomial",
  ],
  chooseNumberInequalitySolutionMC: ["inequality", "substitute"],
  classifyPolynomial: ["classify", "degree"],
  coefficientsOfPolynomials: [],
  combineLikeTerms: ["combine like terms", "equivalent", "expression"],
  combineLikeTermsGuided: ["combine like terms", "equivalent", "expression"],
  commonDifferenceRatioLevel1: [
    "arithmetic sequence",
    "common difference",
    "common ratio",
    "geometric sequence",
  ],
  compareAverageRateOfChange: [],
  compareFeatureTableGraph: [
    "features quadratic",
    "graph",
    "minimum",
    "quadratic",
    "root",
    "vertex",
  ],
  compareFunctionValues: [],
  compareIntervalsOfChange: [],
  compareLinearFeaturesEquationGraphTable: ["graph", "line", "linear", "table"],
  compareLinearFunctionsContext: ["equation", "linear", "real world", "write"],
  compareLinearFunctionsContextGraphically: [
    "graph",
    "line",
    "linear",
    "real world",
  ],
  completeTheSquareIntermediate: ["complete the square", "quadratic equation"],
  completeTheSquareLevel1: [
    "complete the square",
    "quadratic equation",
    "solve equations",
    "solve quadratic",
  ],
  completeTheSquareLevel2: [
    "complete the square",
    "irrational solution",
    "quadratic equation",
    "solve equations",
    "solve quadratic",
  ],
  completeTheSquareLevel3: [
    "complete the square",
    "irrational solution",
    "quadratic equation",
    "solve equations",
    "solve quadratic",
  ],
  convertingUnitsLevel1: ["convert units"],
  convertingUnitsLevel2: ["convert units"],
  convertingUnitsRate: ["convert units"],
  correlationCoefficient: [
    "correlation coefficient",
    "linear",
    "linear regression",
  ],
  correlationVsLinearStrength: [
    "correlation coefficient",
    "linear",
    "linear regression",
  ],
  determineLinesThroughGivenPoints: ["linear"],
  differencePerfectSquares: [
    "difference of squares",
    "equivalent",
    "expression",
  ],
  dilateFuncsLevel1: ["dilate", "function transformation"],
  discriminantToNatureOfRoots: ["quadratic equation", "root"],
  distributeAndSubtract: [
    "binomial",
    "distributive",
    "equivalent",
    "expand",
    "expression",
    "multiply",
    "polynomial",
  ],
  distributiveProperty: ["distributive", "equivalent", "expression"],
  distributivePropertyLevel1: ["distributive", "equivalent", "expression"],
  dividingPowers: ["rules of exponents"],
  domainRangeLevel1: ["domain range", "graphing calculator"],
  dpsFactoringLevel1: ["difference of squares", "equivalent", "expression"],
  equationMultipleChoiceLevel1: ["substitute"],
  evaluateFunctionLevel1: ["linear"],
  evaluateOneFunction: ["quadratic equation"],
  evaluatePiecewise: ["evaluate", "graph", "piecewise"],
  exponentialFunctionFindPercentLevel1: [
    "exponential equation",
    "exponential growth decay",
    "rules of exponents",
  ],
  exponentialFunctions: [
    "exponential equation",
    "exponential growth decay",
    "real world",
  ],
  exponentialFunctionTwoPointsLevel1: [
    "equation",
    "exponential equation",
    "write",
  ],
  exponentialRegression: ["exponential equation"],
  exponentLawsLevel1: [
    "equivalent",
    "exponent",
    "expression",
    "monomial",
    "multiply",
    "power rule",
    "rules of exponents",
  ],
  exponentLawsLevel2: [
    "equivalent",
    "exponent",
    "expression",
    "power rule",
    "rules of exponents",
  ],
  factorCubic: ["factor", "greatest common factor", "polynomial", "trinomial"],
  factorCubicFactorOnly: [
    "factor",
    "greatest common factor",
    "polynomial",
    "trinomial",
  ],
  factorQuarticTrinomialL1: [
    "difference of squares",
    "factor",
    "polynomial",
    "trinomial",
  ],
  factorQuarticTrinomialL2: [
    "difference of squares",
    "factor",
    "greatest common factor",
    "polynomial",
    "trinomial",
  ],
  factorTrinomial: [
    "equivalent",
    "expression",
    "factor",
    "polynomial",
    "trinomial",
  ],
  factorTrinomial23456Level0: [
    "equivalent",
    "expression",
    "factor",
    "polynomial",
    "trinomial",
  ],
  factorTrinomialHard: [
    "equivalent",
    "expression",
    "factor",
    "polynomial",
    "trinomial",
  ],
  factorTrinomialHardLevel1: [
    "equivalent",
    "expression",
    "factor",
    "polynomial",
    "trinomial",
  ],
  findCommonDiffRatioMissingTerms: [
    "arithmetic sequence",
    "common difference",
    "common ratio",
    "geometric sequence",
  ],
  findDiscriminantBasic: ["quadratic equation"],
  findingSquareConstant: ["complete the square"],
  findMagicNumber: ["complete the square", "quadratic equation"],
  findRootsVertexCalc: [
    "features quadratic",
    "graph",
    "graphing calculator",
    "minimum",
    "polynomial",
    "quadratic",
    "quadratic equation",
    "root",
    "vertex",
  ],
  findTheDiscriminant: ["quadratic equation"],
  foilAndDistribute: ["distributive", "equivalent", "expression", "polynomial"],
  foilFractionalCoef: [
    "binomial",
    "distributive",
    "equivalent",
    "expand",
    "expression",
    "multiply",
    "polynomial",
  ],
  foilingMiddleTerm: [
    "binomial",
    "equivalent",
    "expand",
    "expression",
    "multiply",
    "polynomial",
  ],
  functionIntersectionGraphically: [
    "function",
    "graphing calculator",
    "intersection",
    "solve graphically",
  ],
  functionMappingDiagram: ["function", "function definition", "input output"],
  functionNotationGivenOutput: ["linear"],
  functionTransformationPointA1: ["function transformation", "translate"],
  functionTransformDiscoveryA1: ["function transformation"],
  functionTransformMultChoiceA1: ["function transformation"],
  gcfFactorLevel1: ["equivalent", "expression", "greatest common factor"],
  gcfFactorVariableOnly: ["equivalent", "expression", "greatest common factor"],
  givenGraphChooseEquation: [
    "absolute value",
    "exponential",
    "exponential equation",
    "graph",
    "quadratic",
    "quadratic equation",
  ],
  givenLinearSituationMakeEquation: [
    "equation",
    "linear",
    "real world",
    "write",
  ],
  givenLinearSituationMakeTable: [
    "equation",
    "linear",
    "real world",
    "slope intercept form",
    "table",
    "write",
    "y=mx+b",
  ],
  graphingLines: ["graph", "line", "linear", "slope intercept form", "y=mx+b"],
  graphingParabolas: [
    "features quadratic",
    "graph",
    "graphing calculator",
    "quadratic",
    "root",
  ],
  graphingParabolasLevel3: [
    "features quadratic",
    "graph",
    "graphing calculator",
    "quadratic",
    "root",
  ],
  graphInputVsOutput: ["evaluate", "graph"],
  graphLinearInequality: ["graph", "inequality"],
  graphParabolaA1: [
    "axis of symmetry",
    "features quadratic",
    "graph",
    "quadratic",
    "root",
    "vertex",
  ],
  graphParabolaOnly: ["features quadratic", "graph", "quadratic", "root"],
  graphPiecewiseLineOnly: ["piecewise"],
  graphPiecewiseNonLinear: ["piecewise"],
  graphQuadLinearSystem: [],
  greatestCommonFactor: ["equivalent", "expression", "greatest common factor"],
  identifyFunctionPointsL1: ["function", "function definition", "input output"],
  identifyFunctionTypeGraph: [],
  identifyLinearFunctionFromPoints: [
    "linear",
    "rate of change",
    "slope",
    "table",
  ],
  increaseDecreaseLinearNonLinear: ["piecewise"],
  increasingDecreasingLevel1: [
    "features quadratic",
    "graph",
    "quadratic",
    "quadratic equation",
  ],
  increasingDecreasnigLinearPiecewise: ["piecewise"],
  increasingVsPositiveGraph: ["polynomial"],
  inequalityLevel1: ["graph", "inequality"],
  intergerSolutionInequality: ["inequality", "solve inequality"],
  interpretCalcStat: [],
  interpretLinearFunctionCoefficients: [
    "interpret slope",
    "linear",
    "real world",
  ],
  interpretLinearFunctionCoefsGraph: [
    "equation",
    "graph",
    "line",
    "linear",
    "real world",
    "write",
  ],
  intervalNotation: ["graph", "inequality"],
  intervalNotationIneqInterval: ["inequality"],
  intervalNotationLineIneq: ["compound inequality", "graph", "inequality"],
  intervalNotationLineInterval: [],
  invertFunction: ["linear"],
  linearEquations: ["solve equations"],
  linearEquationsFracLevel2: ["distributive", "solve equations"],
  linearEquationsFractions1: ["distributive", "solve equations"],
  linearEquationsWithDecimals: [
    "combine like terms",
    "distributive",
    "solve equations",
  ],
  linearEquationsWithFractionsLevel3: ["distributive", "solve equations"],
  linearFunctionContextTableQuestions: ["linear", "real world", "table"],
  linearInequalities: ["graph", "inequality"],
  linearInequalityDecimals: ["inequality", "solve inequality"],
  linearInequalityFractions: ["inequality", "solve inequality"],
  linearInequalityLevel1: ["inequality", "solve inequality"],
  linearInequalityLevel2: ["inequality", "solve inequality"],
  linearQuadraticSystemA2Level1: ["factor", "solve quadratic"],
  linearQuadraticSystemA2Level3: ["factor", "solve quadratic"],
  linearRationalEquations: ["equation", "proportion", "solve equations"],
  linearRegression: [
    "line of best fit",
    "linear",
    "linear regression",
    "real world",
  ],
  linearSystemGraphically: ["system of equations"],
  linearSystemGraphicallyL1: ["system of equations"],
  lineFromPointsGraphically: [
    "equation",
    "graph",
    "horizontal vertical",
    "line",
    "linear",
    "point slope form",
    "write",
  ],
  lineFromPointSlope: [
    "equation",
    "horizontal vertical",
    "linear",
    "slope intercept form",
    "write",
    "y=mx+b",
  ],
  lineFromPointSlopeDiag: [
    "equation",
    "linear",
    "slope intercept form",
    "write",
    "y=mx+b",
  ],
  lineFromTwoPoints: [
    "equation",
    "horizontal vertical",
    "linear",
    "slope intercept form",
    "write",
    "y=mx+b",
  ],
  lineFromTwoPointsDiag: [
    "equation",
    "linear",
    "slope intercept form",
    "write",
    "y=mx+b",
  ],
  lineParabolaSystems: ["factor", "solve quadratic"],
  literalMultistepLevel1: [],
  literalMultistepLevel2: [],
  literalOneStepLevel1: [],
  literalOneStepLevel2: [],
  meanFromFrequency: ["average"],
  mixedTransformationsAlgebra1: [
    "dilate",
    "function transformation",
    "reflect",
    "translate",
  ],
  multiplyBinomials: [
    "area model",
    "binomial",
    "distributive",
    "equivalent",
    "expand",
    "expression",
    "multiply",
    "polynomial",
  ],
  multiplyImaginary: ["complex number", "radical"],
  multiplyPolynomialsLevel1: [
    "area model",
    "distributive",
    "expand",
    "multiply",
    "polynomial",
  ],
  multiplyPowers: ["monomial", "multiply", "rules of exponents"],
  multiStepPercentIncreaseDecrease: [
    "exponential growth decay",
    "percent",
    "percent increase",
    "real world",
  ],
  nthFormula: [
    "arithmetic sequence",
    "common difference",
    "common ratio",
    "geometric sequence",
  ],
  nthTermArithmetic: ["arithmetic sequence", "common difference"],
  nthTermGeometric: ["common ratio", "geometric sequence"],
  onePointInequality: ["graph", "inequality"],
  orderFunctionsByExtremeValue: [
    "factored form",
    "features quadratic",
    "graph",
    "minimum",
    "quadratic",
    "standard form",
    "vertex",
    "vertex form",
  ],
  parabolaAValue: [
    "features quadratic",
    "function transformation",
    "graph",
    "quadratic",
    "transformation",
  ],
  parabolaFeatures: [
    "axis of symmetry",
    "features quadratic",
    "graph",
    "quadratic",
    "root",
    "vertex",
  ],
  percentChangeExpressions: ["percent", "percent increase"],
  percentChangeTwoValues: ["percent", "percent increase", "real world"],
  percentIncreaseDecrease: ["number sense", "percent", "percent increase"],
  percentIncreaseDecreaseLevel2: ["percent", "percent increase"],
  percentIncreaseDecreaseLevel3: ["percent", "percent increase"],
  percentIncreaseDecreaseWordL2: ["percent", "percent increase", "real world"],
  percentIncreaseDecreaseWordProblems: [
    "percent",
    "percent increase",
    "real world",
  ],
  piecewiseGraphically: ["evaluate", "graph", "piecewise"],
  plotPointsAndDrawFreehandLevel1: [
    "absolute value",
    "cube root",
    "cubic",
    "exponential",
    "graph",
    "quadratic",
    "radical",
  ],
  plotPointsAndDrawFreehandLevel2: [
    "absolute value",
    "cube root",
    "cubic",
    "exponential",
    "graph",
    "graphing calculator",
    "quadratic",
    "radical",
  ],
  plotPointsAndDrawLineLevel1: ["graph", "line", "linear", "table"],
  plotPointsAndDrawLineLevel2: ["graph", "line", "linear"],
  plotPointsAndDrawLineLevel3: ["graph", "line", "linear"],
  pointSlopeForm: [
    "equation",
    "horizontal vertical",
    "linear",
    "point slope form",
    "write",
  ],
  pointSolvingInequalitySystem: [],
  pointsOnFunctionGraph: ["linear quadratic exponential"],
  polynomialArithmetic: ["add", "polynomial", "subtract"],
  polynomialInStandardForm: ["equivalent", "expression", "standard form"],
  polynomialVocab: ["classify", "degree"],
  polynomialYintercept: ["polynomial"],
  positiveOrNegativeGraphicallyLevel1: [
    "features quadratic",
    "graph",
    "quadratic",
    "quadratic equation",
  ],
  posNegIncDecLevel2: ["polynomial"],
  powersMix: [
    "equivalent",
    "exponent",
    "expression",
    "power rule",
    "rules of exponents",
  ],
  powersOfIBasic: ["complex number", "imaginary numbers"],
  powerToPower: ["rules of exponents"],
  quadLinearSystem: ["system of equations"],
  quadraticExtremeFromEquation: [
    "factored form",
    "features quadratic",
    "graph",
    "minimum",
    "quadratic",
    "standard form",
    "vertex",
    "vertex form",
  ],
  quadraticFormulaDecimal: ["quadratic formula"],
  quadraticFormulaIrrational1: ["quadratic formula"],
  quadraticFormulaIrrational2: ["quadratic formula"],
  quadraticFormulaRational: ["quadratic formula"],
  quadraticStandardFormLevel1: ["standard form"],
  quadraticStandardFormLevel2: ["standard form"],
  quadraticWordProblems: [
    "features quadratic",
    "graph",
    "quadratic",
    "quadratic equation",
    "real world",
  ],
  rationalizeMonomialDenominator: ["radical"],
  rationalOrIrrational: ["rational"],
  rationalVsIrrational: ["rational"],
  recursiveSequenceFunctionLevel1: [],
  recursiveSequenceFunctionLevel2: [],
  recursiveSequenceFunctionLevel3: [],
  recursiveSequenceFunctionLevel4: [],
  recursiveSequenceLevel1: [
    "arithmetic sequence",
    "common difference",
    "common ratio",
    "geometric sequence",
  ],
  recursiveSequenceLevel2: [],
  recursiveSequenceLevel3: [],
  recursiveSequenceLevel4: [],
  reduceRadical: ["radical"],
  reduceRadicalMultDiv: ["multiply", "radical"],
  reduceRadicalsTimed: ["radical"],
  reducingRadicalsGuided: ["radical"],
  relativeExtremaCalc1: [
    "features quadratic",
    "graph",
    "graphing calculator",
    "minimum",
    "polynomial",
    "quadratic",
    "quadratic equation",
  ],
  relativeOrAbsolteExtremeGraphically: ["extrema", "minimum"],
  rewriteExponentialAlgebra1: [
    "exponential equation",
    "real world",
    "rules of exponents",
  ],
  rootsGraphically: ["root"],
  selectCorrectRecursiveFormula: [],
  selectFactoringType: [
    "difference of squares",
    "equivalent",
    "expression",
    "factor",
    "greatest common factor",
    "polynomial",
    "trinomial",
  ],
  selectFormForQuadraticFeature: [
    "factored form",
    "features quadratic",
    "graph",
    "minimum",
    "quadratic",
    "root",
    "standard form",
    "vertex",
    "vertex form",
  ],
  selectFunctionFromGraph1: ["factored form", "multiplicity", "root"],
  selectFunctionFromGraph2: [
    "complex root",
    "factored form",
    "multiplicity",
    "root",
  ],
  sequences: [
    "arithmetic sequence",
    "common difference",
    "common ratio",
    "geometric sequence",
  ],
  setQuadraticToZero: ["standard form"],
  simplifyBasicRadicalWithX: ["radical"],
  simplifyRadicalImaginary: ["complex number", "imaginary numbers", "radical"],
  simplifySquareAlgebraic: ["radical"],
  slopeFromPoints: ["linear", "slope"],
  slopeGraphically: ["graph", "line", "linear", "slope"],
  solutionSetEquationOneVariable: ["solve equations"],
  solutionSetEquationTwoVariables: ["graph", "line", "linear"],
  solveContextInequalitySystemGraphically: ["real world"],
  solveContextInequalitySystemGraphicallyL2: ["real world"],
  solveLinearSystemElim: ["system of equations"],
  solveLinearSystemGuided: ["system of equations"],
  solveQuadraticFactoring: [
    "factor",
    "greatest common factor",
    "polynomial",
    "solve equations",
    "solve quadratic",
    "trinomial",
    "zero product property",
  ],
  solveQuadraticRational: ["factor", "radical", "solve quadratic"],
  solveQuadraticsSquareRootLevel1: [
    "quadratic equation",
    "radical",
    "solve equations",
    "solve quadratic",
  ],
  solveQuadraticsSquareRootLevel2: [
    "quadratic equation",
    "radical",
    "solve equations",
    "solve quadratic",
  ],
  solveTrinomialFactorA1: [
    "factor",
    "polynomial",
    "solve equations",
    "solve quadratic",
    "trinomial",
    "zero product property",
  ],
  solveTrinomialFactorA23456: [
    "factor",
    "polynomial",
    "solve equations",
    "solve quadratic",
    "trinomial",
    "zero product property",
  ],
  squaringSimpleBinomial: [
    "area model",
    "binomial",
    "binomial powers",
    "distributive",
    "equivalent",
    "expand",
    "expression",
    "multiply",
    "polynomial",
  ],
  standardToSlopeIntForm: [
    "linear",
    "slope intercept form",
    "standard form",
    "y=mx+b",
  ],
  sumImaginary: ["complex number", "imaginary numbers", "radical"],
  systemOfEquationsContextGuided: ["system of equations"],
  systemOfEquationsNumSolutions: ["system of equations"],
  systemOfLinearInequalitiesGraph: [],
  tableToExpEquation: ["equation", "exponential equation", "write"],
  timedVertexForm: [
    "complete the square",
    "quadratic equation",
    "standard form",
    "vertex form",
  ],
  transformFormulas: [],
  translateFuncsLevel1: ["function transformation", "translate"],
  translateFuncsLevel2: ["function transformation", "translate"],
  twoStepFactor: [
    "difference of squares",
    "equivalent",
    "expression",
    "factor",
    "greatest common factor",
    "polynomial",
    "trinomial",
  ],
  twoVariablesSystemWordLevel1: [
    "equation",
    "real world",
    "system of equations",
    "write",
  ],
  twoVariableSystemsWordGraphically: ["real world", "system of equations"],
  twoVariableSystemWord: ["real world", "system of equations"],
  vertexForm: [
    "complete the square",
    "quadratic equation",
    "standard form",
    "vertex form",
  ],
  verticalLineTestOnly: [
    "function",
    "function definition",
    "input output",
    "vertical line test",
  ],
  verticalOrHorizontalLines: ["graph", "horizontal vertical", "line", "linear"],
  visualDomainRange: ["domain range"],
  visualFunctionOneFunc: ["evaluate", "graph"],
  writeAndInterpretLinearFunction: ["real world"],
  writeExpressionOneVariable: [
    "evaluate",
    "expression",
    "slope intercept form",
    "substitute",
    "y=mx+b",
  ],
  writeInequalitySystemAndSolve: ["real world"],
  writeLineEquation: [
    "equation",
    "graph",
    "line",
    "linear",
    "slope intercept form",
    "write",
    "y=mx+b",
  ],
  writeSequenceExplicitFormula: [
    "arithmetic sequence",
    "common difference",
    "common ratio",
    "geometric sequence",
  ],
  writeSequenceRecursiveFormula: [],
  writeSingleInequality: ["inequality", "real world", "write"],
  xGameLevel1: ["integer", "number sense"],
  xGameLevel2: ["integer", "number sense"],
  xGameLevel3: ["integer", "number sense"],
  zerosCalc1: [
    "features quadratic",
    "graph",
    "graphing calculator",
    "polynomial",
    "quadratic",
    "quadratic equation",
    "root",
  ],
};

/**
 * This is a list for the prototype search page with the tag and the corresponding "associations".
 * It is a one-way association. For example, "3D" is associated with "three dimensional", but "three dimensional" is not associated with "3D".
 */
const tagToAssociation: Record<string, string[]> = {
  "3D": ["three dimensional"],
  "absolute value": [],
  add: [],
  "area model": [],
  associative: [],
  average: [],
  "box plot": ["whisker"],
  carry: ["borrow", "regroup"],
  "circle diagram": [],
  classify: ["name", "identify"],
  "combine like terms": ["clt"],
  "common denominator": [],
  commutative: [],
  compare: [],
  "composite area": [],
  "compound events": [],
  "compound probability": [],
  "convert units": [],
  "coordinate plane": ["cartesian"],
  data: [],
  decimal: [],
  distance: [],
  distributive: [],
  divide: [],
  "dot plot": ["dot chart"],
  "double number line": ["dnl"],
  equivalent: [],
  evaluate: [],
  expand: [],
  "experimental probability": [],
  exponent: [],
  factor: [],
  "first quadrant": ["Q1", "positive"],
  frequency: [],
  "greatest common factor": ["gcf"],
  histogram: [],
  "improper fraction": [],
  inequality: [],
  integer: [],
  "interquartile range": ["iqr"],
  "least common multiple": ["lcm"],
  mean: [],
  median: [],
  "mental math": ["fluency"],
  "mixed numbers": [],
  mode: [],
  models: ["manipulatives", "visuals", "concrete objects"],
  multiply: [],
  "number sense": [],
  "open number line": ["onl"],
  opposite: [],
  "order of operations": ["pemdas", "gemdas"],
  "ordered pair": [],
  percent: [],
  "place value": [],
  "powers of ten": [],
  probability: [],
  proportion: [],
  range: [],
  ratio: [],
  "ratio table": [],
  "real world": ["word problem", "context"],
  reduce: ["simplify"],
  reflect: [],
  rigid: [],
  "sample space": [],
  simplify: [],
  "solve equations": [],
  substitute: [],
  subtract: [],
  "tape diagram": [],
  "theoretical probability": [],
  transformation: [],
  triangle: [],
  "unit rate": [],
  volume: [],
  fraction: [],
  "whole number": [],
  expression: [],
  area: [],
  prism: [],
  "arithmetic sequence": [],
  "average rate of change": ["aroc"],
  "axis of symmetry": ["aos"],
  binomial: [],
  "binomial powers": [],
  center: [],
  "common difference": [],
  "common ratio": [],
  "complete the square": [],
  "complex number": [],
  "complex root": ["complex solution"],
  "compound inequality": [],
  "constant of proportionality": [],
  correlation: ["association"],
  "correlation coefficient": [],
  "cube root": [],
  cubic: [],
  degree: [],
  "difference of squares": [],
  dilate: [],
  "discrete continuous": [],
  domain: [],
  "domain range": [],
  equation: [],
  exponential: [],
  "exponential equation": ["exponential function", "exponential model"],
  "exponential growth decay": [],
  extrema: ["minimum", "maximum", "global", "absolute"],
  "factored form": ["intercept form"],
  "features quadratic": ["features parabola"],
  function: [],
  "function definition": ["input", "output", "relation"],
  "function transformation": [],
  "geometric sequence": [],
  graph: [],
  "graphing calculator": ["desmos"],
  "horizontal vertical": [],
  "imaginary numbers": [],
  "input output": [],
  "interpret slope": [],
  intersection: [],
  "irrational solution": [],
  line: [],
  "line of best fit": ["lobf"],
  linear: [],
  "linear exponential": [],
  "linear quadratic exponential": [],
  "linear regression": [],
  minimum: ["maximum"],
  monomial: [],
  multiplicity: [],
  "percent increase": ["percent decrease"],
  piecewise: [],
  "point slope form": [],
  polynomial: [],
  "power rule": [],
  "pythagorean theorem": [],
  quadratic: [],
  "quadratic equation": ["quadratic function", "quadratic model"],
  "quadratic formula": [],
  radical: ["square root"],
  "rate of change": [],
  rational: ["irrational"],
  "rational function": [],
  reciprocal: [],
  residual: [],
  root: ["solution", "zero", "x-intercept"],
  "rules of exponents": [
    "laws of exponents",
    "properties of exponents",
    "product rule",
    "quotient rule",
    "power rule",
  ],
  "scatter plot": [],
  slope: [],
  "slope intercept form": [],
  "solve graphically": ["graphing"],
  "solve inequality": [],
  "solve quadratic": [],
  spread: [],
  "standard form": [],
  "system of equations": [],
  table: [],
  translate: [],
  trinomial: [],
  "two way table": [],
  vertex: [],
  "vertex form": [],
  "vertical line test": [],
  write: [],
  "x y intercept": [],
  "y=mx+b": ["mx", "b"], // ADDED BY HANNAH (not in original list)
  "zero product property": [],
};

export const mathPhrases: Record<string, string[]> = {
  absolute: ["value"],
  area: ["model"],
  box: ["plot"],
  circle: ["diagram"],
  combin: ["like", "terms"],
  common: ["denominator", "difference", "ratio"],
  composite: ["area"],
  compound: ["events", "probability", "inequality"],
  convert: ["units"],
  coordinate: ["plane"],
  dot: ["plot", "chart"],
  double: ["number", "line"],
  experimental: ["probability"],
  first: ["quadrant"],
  greatest: ["common", "factor"],
  improper: ["fraction"],
  interquartile: ["range"],
  least: ["common", "multiple"],
  mental: ["math"],
  mixed: ["numbers"],
  concrete: ["objects"],
  number: ["sense"],
  open: ["number", "line"],
  order: ["operations"],
  ordered: ["pair"],
  place: ["value"],
  powers: ["ten", "10"],
  ratio: ["table"],
  real: ["world"],
  sample: ["space"],
  solv: ["equation", "inequalit", "quadratic", "graphically"],
  tape: ["diagram"],
  theoretical: ["probability"],
  three: ["dimensional"],
  "3": ["dimensional"],
  unit: ["rate"],
  word: ["problem"],
  whole: ["number"],
  axis: ["symmetry"],
  average: ["rate", "change"],
  binomial: ["power"],
  complete: ["square"],
  complex: ["number", "root", "solution"],
  constant: ["proportionality"],
  correlation: ["coefficient"],
  cube: ["root"],
  difference: ["squares"],
  discrete: ["continuous"],
  domain: ["range"],
  exponential: ["equation", "growth", "decay", "function", "model"],
  factored: ["form"],
  features: ["quadratic", "parabola"],
  function: ["definition", "transformation"],
  geometric: ["sequence"],
  graphing: ["calculator"],
  horizontal: ["vertical"],
  imaginary: ["number"],
  input: ["output"],
  intercept: ["form"],
  interpret: ["slope"],
  irrational: ["solution"],
  line: ["best", "fit"],
  linear: ["exponential", "quadratic", "regression"],
  percent: ["increase", "decrease"],
  point: ["slope", "form"],
  power: ["rule"],
  pythagorean: ["theorem"],
  quadratic: ["equation", "formula", "function", "model"],
  rational: ["function"],
  rules: ["exponents"],
  scatter: ["plot"],
  slope: ["intercept", "form"],
  standard: ["form"],
  system: ["equations"],
  two: ["way", "table"],
  "2": ["way", "table"],
  vertical: ["line", "test"],
  x: ["y", "intercept"],
  y: ["x", "intercept"],
  zero: ["product", "property"],
  arithmetic: ["sequence"],
  rate: ["change"],
  vertex: ["form"],
  square: ["root"],
  laws: ["exponents"],
  properties: ["exponents"],
  product: ["rule"],
  quotient: ["rule"],
};

export const wordToNumber: Record<string, string> = {
  zero: "0",
  one: "1",
  two: "2",
  three: "3",
  four: "4",
  five: "5",
  six: "6",
  seven: "7",
  eight: "8",
  nine: "9",
  ten: "10",
};

export const charPrefixOrSuffixExceptions: Record<string, boolean> = {
  "f'": true,
  "f''": true,
};

export const charWithinStrExceptions: Record<string, string> = {
  "l'hopital": "lhopital",
};

export const commonWildcardTokens: string[] = [
  "add",
  "multipl",
  "divi",
  "percent",
  "sol",
];
