import axios, { AxiosResponse } from "axios";
import { useMutation } from "@tanstack/react-query";
import { deltamathAPI } from "../../manager/utils";

export function useUpdatePassword({
  successFunc,
  errorFunc,
}: {
  successFunc: (
    data?: AxiosResponse<
      {
        status: "success" | "error";
      },
      any
    >
  ) => void;
  errorFunc: (e?: unknown) => void;
}) {
  const updatePassword = useMutation({
    mutationFn: (body: {
      password: string;
      currentPassword: string | undefined;
    }) => {
      return axios.put<{ status: "success" | "error" }>(
        `${deltamathAPI()}/learner/shared/update_password`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: (data) => {
      successFunc(data);
    },
    onError: (e) => {
      errorFunc(e);
    },
  });

  return updatePassword;
}
