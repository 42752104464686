const formatNumber = (value: number) =>
  Intl.NumberFormat("en-us").format(value);

const formatCurrency = (value: number) =>
  Intl.NumberFormat("en-us", { maximumFractionDigits: 0 }).format(value);

const Card: React.FC<{ title: string }> = ({ title, children }) => (
  <div className="rounded-lg bg-white px-4 py-2">
    <h3 className="text-sm font-bold">{title}</h3>
    <div className="py-8 text-center text-2xl text-dm-charcoal-500">
      {children}
    </div>
  </div>
);

export const CurrencyCard: React.FC<{
  title: string;
  value: number | undefined;
}> = ({ title, value }) => (
  <Card title={title}>
    {value !== undefined ? `$ ${formatCurrency(value)}` : "..."}
  </Card>
);

export const NumberCard: React.FC<{
  title: string;
  value: number | undefined;
}> = ({ title, value }) => (
  <Card title={title}>{value !== undefined ? formatNumber(value) : "..."}</Card>
);

export const UserPercentageCard: React.FC<{
  title: string;
  numerator: number | undefined;
  denominator: number | undefined;
}> = ({ title, numerator, denominator }) => (
  <Card title={title}>
    {numerator === undefined || denominator === undefined ? (
      "..."
    ) : (
      <>
        {numerator} users{" "}
        <span className="ml-2 opacity-50">
          {Math.round((numerator / denominator) * 100)}%
        </span>
      </>
    )}
  </Card>
);
