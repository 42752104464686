import { Fragment, useEffect } from "react";
import { IInvoice, RosteringDataForInvoice } from "../../utils/quoteUtils";
import { DmLoadingSpinner } from "../../utils/functions";

const IntegrationsRosteringComponent: React.FC<{
  externalData?: RosteringDataForInvoice;
  localData: IntegrationData;
  setLocalData: React.Dispatch<React.SetStateAction<IntegrationData>>;
  isLmsDataFetching?: boolean;
  edit: boolean;
  setSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  externalData,
  localData,
  setLocalData,
  isLmsDataFetching,
  edit,
  setSaveDisabled,
}) => {
  const searchRosteringData = (
    value: string,
    rosterData: IntegrationData["rosteringData"]
  ) => {
    if (!rosterData) return undefined;
    for (const key in rosterData) {
      const match = rosterData[key].find((x) => {
        if (typeof x === "string") {
          return x === value;
        } else {
          return x.id === value;
        }
      });
      if (match) {
        return key;
      }
    }
  };

  const updateRosteringData = (
    x: { id: string; name: string },
    val: string
  ) => {
    setLocalData((prev: IntegrationData) => {
      const updatedRosteringData = prev.rosteringData || {};
      for (const key in updatedRosteringData) {
        updatedRosteringData[key] = updatedRosteringData[key].filter((val) => {
          if (typeof val === "object" && val.id) {
            return val.id !== x.id;
          }
          return val !== x.id;
        });
        if (updatedRosteringData[key].length === 0) {
          delete updatedRosteringData[key];
        }
      }

      if (val === "insert") {
        updatedRosteringData[val] = [...(updatedRosteringData[val] || []), x];
      } else {
        updatedRosteringData[val] = [
          ...(updatedRosteringData[val] || []),
          x.id,
        ];
      }

      setLocalData({
        ...prev,
        rosteringData: updatedRosteringData,
      });

      return {
        ...prev,
        updatedRosteringData,
      };
    });
  };

  useEffect(() => {
    if (externalData && externalData.integrationData) {
      // Remove elements no longer in scope
      const updatedRosteringData = { ...localData.rosteringData };
      let update = false;
      for (const key in updatedRosteringData) {
        for (const val of updatedRosteringData[key]) {
          if (typeof val === "string") {
            if (!externalData.integrationData.find((x) => x.id === val)) {
              updatedRosteringData[key] = updatedRosteringData[key].filter(
                (x) => x !== val
              );
              update = true;
            }
          } else if (
            !externalData.integrationData.find((x) => x.id === val.id)
          ) {
            updatedRosteringData[key] = (
              updatedRosteringData[key] as Array<{ id: string; name: string }>
            ).filter((x) => x.id !== val.id);
            update = true;
          }
        }
        if (updatedRosteringData[key].length === 0) {
          delete updatedRosteringData[key];
          update = true;
        }
        if (update) {
          setLocalData((prev) => {
            return {
              ...prev,
              rosteringData: updatedRosteringData,
            };
          });
        }
      }

      // Apply latest integrationData, ensuring not to override any data from the payload
      for (const x of externalData.integrationData) {
        if (!searchRosteringData(x.id, localData.rosteringData)) {
          updateRosteringData(x, x.likelyMatch ? x.likelyMatch : "skip");
        }
      }
    }
  }, [externalData]);

  return (
    <>
      <div className="relative flex items-center py-5">
        <div className="flex-grow border-t border-gray-400"></div>
        <span className="mx-4 flex-shrink text-gray-400">
          Rostering Activation
        </span>
        <div className="flex-grow border-t border-gray-400"></div>
      </div>

      {isLmsDataFetching ? (
        <DmLoadingSpinner message="Loading..." />
      ) : (
        <div>
          {externalData && externalData.integrationData && (
            <dl className="grid grid-cols-1 items-center gap-x-2 gap-y-2 sm:grid-cols-2">
              {externalData.integrationData.map((x: any) => {
                return (
                  <Fragment key={`${x.name}`}>
                    <div className="inline-flex sm:col-span-1">
                      <div className="">
                        <dt className="text-sm font-medium text-gray-500">
                          {x.name}
                        </dt>
                      </div>
                    </div>
                    <div className="inline-flex sm:col-span-1">
                      <div className="">
                        <dd className="mt-1 text-sm text-gray-900">
                          {edit ? (
                            <select
                              className="border-gray-300 md:rounded-md"
                              defaultValue={
                                searchRosteringData(
                                  x.id,
                                  localData.rosteringData
                                ) ||
                                x.likelyMatch ||
                                "skip"
                              }
                              onChange={(e) => {
                                updateRosteringData(x, e.target.value);
                                setSaveDisabled(false);
                              }}
                            >
                              <option value="skip">Do not activate</option>
                              {localData.type &&
                                !["school_custom", "school"].includes(
                                  localData.type
                                ) && (
                                  <option value="insert">
                                    Insert new school
                                  </option>
                                )}
                              {externalData.diggerSchools.map((sch: any) => {
                                return (
                                  <option key={sch.nces_id} value={sch.nces_id}>
                                    {sch.name}
                                  </option>
                                );
                              })}
                            </select>
                          ) : (
                            <></>
                          )}
                        </dd>
                      </div>
                    </div>
                  </Fragment>
                );
              })}
            </dl>
          )}
        </div>
      )}
    </>
  );
};

export default IntegrationsRosteringComponent;

/**
 * The subset of invoice data that is relevant to the integrations card.
 */
export type IntegrationData = Pick<
  IInvoice,
  | "id"
  | "type"
  | "rosteringData"
  | "classlinkTenantId"
  | "cleverDistrictId"
  | "rosteringSystem"
  | "lms"
  | "integrationStatus"
>;
