import { SubmitHandler, useForm } from "react-hook-form";
import { getStatesAndProvinces } from "../../../shared/regionsUtils";
import Button from "../../../student/components/generic/button";
import { format } from "date-fns";
import { useQuery } from "@tanstack/react-query";
import { executeQuery } from "../../../utils";
import { useState } from "react";
import { DmLoadingSpinner } from "../../utils/functions";
import { CurrencyCard } from "./Cards";

interface FormData {
  startDate?: string;
  endDate?: string;
  state?: string;
}

const MAX_DATE = format(new Date(), "yyyy-MM-dd");
const STATE_NULL = "null";

export const StatsSearchForm: React.FC = () => {
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors: formErrors },
  } = useForm<FormData>();
  const [queryData, setQueryData] = useState<FormData | null>(null);

  const {
    isLoading,
    isError: isRequestError,
    data,
  } = useQuery<
    { params: FormData },
    unknown,
    { revenue: { gross: number; net: number } }
  >({
    queryKey: [
      "financialsStatsSearch",
      queryData?.startDate,
      queryData?.endDate,
      queryData?.state,
    ],
    queryFn: () =>
      executeQuery({
        path: "/payments/reports/learner/financials/stats/search",
        params: queryData ? (queryData as { [key: string]: string }) : {},
      }),
    enabled: queryData !== null,
    refetchOnWindowFocus: false,
  });

  const onSubmit: SubmitHandler<FormData> = ({
    startDate: startDateString,
    endDate: endDateString,
    state,
  }) => {
    const result: FormData = {};
    const startDate = startDateString ? new Date(startDateString) : null;
    const endDate = endDateString ? new Date(endDateString) : null;

    if (startDate) {
      result.startDate = startDateString;
    }
    if (endDate) {
      result.endDate = endDateString;
    }
    if (state !== STATE_NULL) {
      result.state = state;
    }

    setQueryData(result);
  };

  const validateDateRange = (
    start: string | undefined,
    end: string | undefined
  ) => {
    if (start && end) {
      const startDate = new Date(start).getTime();
      const endDate = new Date(end).getTime();
      if (startDate > endDate) {
        return "Start date must be before end date";
      }
    }
    return true;
  };

  return (
    <div className="flex flex-col rounded bg-white/50">
      <form className="flex gap-6 px-4 py-2" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex items-start gap-2 text-sm">
          <div className="flex flex-col gap-1">
            <label className="flex items-center gap-2">
              <span>Search by date</span>
              <input
                className="h-8 rounded px-2 py-0"
                type="date"
                max={MAX_DATE}
                {...register("startDate", {
                  validate: (value) =>
                    validateDateRange(value, getValues("endDate")),
                })}
              />
            </label>
            {formErrors.startDate && (
              <p className="text-xs text-dm-error-500">
                {formErrors.startDate.message}
              </p>
            )}
          </div>

          <div className="flex flex-col gap-1">
            <label className="flex items-center gap-2">
              <span>to</span>
              <input
                className="h-8 rounded px-2 py-0"
                type="date"
                max={MAX_DATE}
                {...register("endDate")}
              />
            </label>
            {formErrors.endDate && (
              <p className="text-xs text-dm-error-500">
                {formErrors.endDate.message}
              </p>
            )}
          </div>
        </div>

        <div className="flex items-start gap-1 text-sm">
          <label className="flex items-center gap-2">
            <span>Search by state/province</span>
            <select className="h-8 rounded px-2 py-0" {...register("state")}>
              <option value={STATE_NULL} defaultChecked>
                --
              </option>
              {getStatesAndProvinces().map((region) => (
                <option key={region.key} value={region.key}>
                  {region.name}
                </option>
              ))}
            </select>
          </label>
          {formErrors.state && (
            <p className="text-xs text-dm-error-500">
              {formErrors.state.message}
            </p>
          )}
        </div>

        <Button className="h-8 !px-6 !py-0">Search</Button>
      </form>

      {(isLoading || isRequestError || data) && (
        <div className="border-t border-dm-charcoal-100 p-4">
          {isLoading && (
            <div className="flex justify-center py-12">
              <DmLoadingSpinner message="" />
            </div>
          )}

          {isRequestError && (
            <div className="py-14 text-center text-sm text-dm-error-500">
              Unable to load stats
            </div>
          )}

          {data && (
            <div className="grid grid-cols-2 gap-4">
              <CurrencyCard title="Gross Revenue" value={data.revenue.gross} />
              <CurrencyCard title="Net Revenue" value={data.revenue.net} />
            </div>
          )}
        </div>
      )}
    </div>
  );
};
