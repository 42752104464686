import { ChartSquareBarIcon } from "@heroicons/react/outline";
import { useState, useEffect } from "react";
import ReactTooltip from "react-tooltip";
import { useDMQuery } from "../../../utils";
import { useParentContext } from "../../contexts/ParentContext";
import {
  LearnerStatsKind,
  LearnerStatsResponse,
  WeekRangeForKind,
  WeeklyData,
} from "../../types";
import { LearnerStatsDropdown } from "./LearnerStatsDropdown";
import { LearnerStatsWeeklySwitch } from "./LearnerStatsWeeklySwitch";
import { LearnerStatsBarGraph } from "./LearnerStatsBarGraph";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { parentChangeLearnerStatsEvent } from "../../analytics/events";
import { merge, mergeWith } from "lodash";

type Props = {
  showEmptyState?: boolean;
};

export const LearnerStats: React.FC<Props> = (props) => {
  const { track } = useLearnerAnalytics();
  const [weeklySwitch, setWeeklySwitch] = useState(false);
  const { currentLearner } = useParentContext();
  const [isMounted, setIsMounted] = useState(false);
  const [weekRange, setWeekRange] = useState<Record<string, WeekRangeForKind>>(
    {}
  );
  const [weeklyData, setWeeklyData] = useState<Record<string, WeeklyData>>({});

  const statType: { kind: LearnerStatsKind; name: string }[] = [
    { kind: "points", name: "Points" },
    { kind: "time", name: "Time Spent" },
    { kind: "questions", name: "Questions Answered" },
  ];
  const [selectedType, setSelectedType] = useState(statType[0]);

  const learnerId = currentLearner?._id;

  // *****************
  // * Points data
  // *****************
  const min = learnerId
    ? weekRange[learnerId]?.[selectedType.kind]?.min ?? 0
    : 0;
  const max = learnerId
    ? weekRange[learnerId]?.[selectedType.kind]?.max ?? 7
    : 7;
  const {
    refetch: fetchStats,
    isFetching: isStatsFetching,
    isSuccess: isStatsSuccess,
    data: statsData,
  } = useDMQuery<LearnerStatsResponse>({
    path: "/learner/parent/stats",
    cacheKey: [
      "parent/stats",
      learnerId || "",
      selectedType.kind,
      min.toString(),
      max.toString(),
    ],
    method: "get",
    params: {
      range: [min, max],
      learnerId: learnerId,
      statsKind: selectedType.kind,
    },
    queryOptions: {
      enabled: false,
    },
  });

  useEffect(() => {
    if (isStatsSuccess) {
      if (learnerId) {
        const reversedData = [...statsData.data].reverse();

        const updatedWeeklyData = mergeWith(
          weeklyData,
          { [learnerId]: { [statsData.kind]: reversedData } },
          (objectData, sourceData) => {
            if (Array.isArray(objectData) && Array.isArray(sourceData))
              return objectData.concat(sourceData);
          }
        );

        setWeeklyData(updatedWeeklyData);

        const updatedWeekRange = merge(weekRange, {
          [learnerId]: {
            [statsData.kind]: {
              min: min + 8,
              max: max + 8,
            },
          },
        });
        setWeekRange(updatedWeekRange);
      }
    }
  }, [statsData, isStatsSuccess]);

  useEffect(() => {
    if (learnerId && !weeklyData?.[learnerId]?.[selectedType.kind]) {
      fetchStats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType, fetchStats, learnerId]);

  useEffect(() => {
    if (isMounted) {
      track(
        parentChangeLearnerStatsEvent({
          statType: selectedType.kind,
          interval: weeklySwitch ? "weekly" : "daily",
        })
      );
    } else {
      setIsMounted(true);
    }
    // We don't want this to run when isMounted changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedType, weeklySwitch]);

  return (
    <div className="relative flex w-full flex-col rounded-xl border border-dm-charcoal-100 bg-white p-6">
      <header className="z-[5] flex flex-row items-center justify-between">
        <h3 className="flex items-center gap-2 text-sm">
          <ChartSquareBarIcon className="w-6" />
          <span className="text-dm-charcoal-500">Learner Stats</span>
        </h3>
        <LearnerStatsDropdown
          selectedType={selectedType}
          setSelectedType={setSelectedType}
          statType={statType}
        />
      </header>
      {props.showEmptyState ? (
        <div className="my-10 text-center text-dm-gray-200">
          <ChartSquareBarIcon className="w-24" />
          <p className="mt-4 text-dm-charcoal-800">
            Your student&apos;s weekly points will show here.
          </p>
        </div>
      ) : (
        <>
          <div className="mt-6 flex flex-row items-center justify-between">
            <h4 className="font-serif text-base font-bold text-dm-charcoal-600">
              {selectedType.name}
            </h4>
            <LearnerStatsWeeklySwitch
              weeklySwitch={weeklySwitch}
              setWeeklySwitch={setWeeklySwitch}
            />
          </div>
          <div className="mt-9">
            <LearnerStatsBarGraph
              kind={selectedType.kind}
              kindData={
                learnerId
                  ? weeklyData[learnerId]?.[selectedType.kind] ?? []
                  : []
              }
              fetchKindData={fetchStats}
              isWeekly={weeklySwitch}
              isStatsFetching={isStatsFetching}
            />
          </div>
          <ReactTooltip
            id="statsTooltip"
            className="!rounded !px-3 font-sans text-xs leading-normal before:!-z-10"
            effect="solid"
            delayShow={250}
            delayHide={50}
            multiline={true}
            place="top"
            html={true}
            getContent={(dataTip) => dataTip}
          />
        </>
      )}
    </div>
  );
};
