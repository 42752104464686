import { useMutation, useQuery } from "@tanstack/react-query";
import { executeQuery } from "../../../utils";
import Button from "../../../student/components/generic/button";
import axios from "axios";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { deltamathAPI } from "../../utils";
import Modal from "../../../student/components/generic/Modal";
import { useState } from "react";
import { useUserContext } from "../../../shared/contexts/UserContext";

export const SharedFileApproval: React.FC = () => {
  const toastContext = useDeltaToastContext();
  const [sharedFileId, setSharedFileId] = useState("");
  const [showModal, setShowModal] = useState(false);
  const userContext = useUserContext();

  const isModuleApprover =
    userContext.state?.decodedJwt?.data?.teacherPrivileges?.includes(
      "moduleApprover"
    );

  // Fetch the pending shared code files
  const {
    data: pendingSharedFileIds,
    isLoading: isPendingSharedFileIdsLoading,
    refetch: refetchPendingSharedFileIds,
  } = useQuery<{ _id: string }[]>({
    queryKey: ["/manager_new/module/pending_shared_files"],
    queryFn: () =>
      executeQuery({
        path: `/manager_new/module/pending_shared_files`,
      }),
  });

  const onClose = () => {
    setShowModal(false);
    refetchPendingSharedFileIds();
  };

  const approveSharedFileMutation = useMutation({
    mutationFn: (body: { _id: string }) => {
      return axios.post<{ status: "success" | "error" }>(
        `${deltamathAPI()}/manager_new/module/approve_shared_file`,
        JSON.stringify(body),
        withJsonHeader()
      );
    },
    onSuccess: (data) => {
      const message = "Module shared file approved!";
      toastContext.addToast({
        message,
        status: "Success",
      });
    },
    onError: (e) => {
      const message =
        "An error occurred while approving the shared file. Please try again.";
      toastContext.addToast({
        message,
        status: "Error",
      });
    },
    onSettled: () => {
      onClose();
    },
  });

  const approveSharedFile = () => {
    approveSharedFileMutation.mutate({ _id: sharedFileId });
  };

  return (
    <div className="px-6">
      <h2 className="mb-6 pt-10 text-2xl font-bold">Shared File Approval</h2>
      <p className="mb-6">
        Each file below is a shared custom file that is pending approval. The
        files typically affect multiple modules. Please confirm that file is
        related to a bug ticket or a new set of modules before approval.
      </p>
      {isPendingSharedFileIdsLoading ? (
        <div>Loading...</div>
      ) : (
        <div>
          {pendingSharedFileIds?.length ? (
            pendingSharedFileIds?.map((file) => (
              <div
                key={file._id}
                className="flex flex-wrap items-center justify-between gap-4 border-b border-gray-500 py-4"
              >
                <div className="text-lg font-bold">{file._id}</div>
                <div>
                  <Button
                    onClick={() => {
                      setSharedFileId(file._id);
                      setShowModal(true);
                    }}
                    disabled={!isModuleApprover}
                  >
                    Approve
                  </Button>
                </div>
              </div>
            ))
          ) : (
            <div className="py-4">
              No pending shared files to approve!
              <span
                className="ml-2 text-lg"
                role="img"
                aria-label="party popper"
              >
                &#127881;
              </span>
            </div>
          )}
          <Modal
            visible={showModal}
            onClose={onClose}
            title="Confirm Shared File Approval"
            body={"Are you sure you want to approve this shared custom file?"}
            noLine
            confirmButtonText="Yes"
            onConfirm={approveSharedFile}
            secondaryButtonText="Cancel"
            secondaryOnClick={onClose}
            renderMath
          />
        </div>
      )}
    </div>
  );
};
