import { useContext, useEffect, useState } from "react";
import { AssignmentType } from "../../_constants";
import { round } from "lodash";
import Button from "../generic/button";
import { useNavigate } from "react-router";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import {
  timeLimitText,
  updateFullAssignmentData,
  getAssignmentDueDateType,
} from "../../utils";
import StudentSectionsContext from "../../_context/StudentSectionsContext";
import { NavLink, useSearchParams } from "react-router-dom";
import clsx from "clsx";
import CorrectionQuestions from "./CorrectionQuestions";
import { getTimedAssignment, useShowResults } from "../../utils/api";
import UploadWork from "../UploadWork";
import { REACT_APP_STUDENT_LINK } from "../../../utils";

type Props = {
  timedAssignment: {
    isTimed: boolean;
    timeLimit: any;
    endTime: number | undefined;
    serverTime: any;
    additionalTime: any;
    timerText: string;
    isUnlimited: boolean;
    isOverUnlimitedTime: boolean | 0 | undefined;
    isOver: any;
  };
  sa: any;
  ta: any;
  teacherId: string;
  sectionId: string;
  refetchAssignments: () => void;
};

const TestCorrection = (props: Props) => {
  const navigate = useNavigate();
  const toastContext = useDeltaToastContext();
  const [searchParams] = useSearchParams();
  const queryClient = useQueryClient();

  const [isResetCorrectionActive, setIsResetCorrectionActive] =
    useState<boolean>(false);
  const [navigateToFirstSkill, setNavigateToFirstSkill] =
    useState<boolean>(false);

  const resetCorrection =
    props.sa.correctionInitialized === true && isResetCorrectionActive;
  const timingState: "timed" | "timed_pending" | "not_timed" = props
    .timedAssignment.isTimed
    ? props.timedAssignment.timeLimit && props.timedAssignment.endTime
      ? "timed_pending"
      : "timed"
    : "not_timed";

  const correctionState: "non_initialized" | "initialized" =
    props.sa.correctionInitialized === false
      ? "non_initialized"
      : "initialized";

  const uploadWorkAvailable = props.ta.notes === 2;

  const { dmAssignmentData, setDmAssignmentData, setLoadingData } = useContext(
    StudentSectionsContext
  );

  const startTimed = getTimedAssignment(
    props.teacherId,
    props.sectionId,
    setLoadingData,
    dmAssignmentData,
    setDmAssignmentData,
    navigate,
    queryClient,
    props.refetchAssignments
  );

  const defaultPayload: {
    points: any;
    extraPoints: number;
    test: number;
    lost_or_earned: string;
    skipWarning?: boolean;
  } = {
    points: {},
    extraPoints: 0,
    test: 0,
    lost_or_earned: props.ta.correction_points_lost_or_earned || "lost",
  };
  const [correctionPoints, setCorrectionPoints] = useState<any>({});
  const [payload, setPayload] = useState(defaultPayload);

  // If this exists in local storage it was an LTI launch
  const lti = JSON.parse(
    localStorage.getItem("lti_assignment_payload") || "{}"
  ).isLtiResourceLaunch;

  const isLinkedCorrection = props.ta.linkedTest;

  const firstSkill: any = props.ta.order?.[0]
    ? props.ta.skills[props.ta.order[0]]
    : undefined;
  const assignmentsUrl = `${REACT_APP_STUDENT_LINK}/${
    props.sectionId
  }/${getAssignmentDueDateType(props?.sa?.status)}`;
  const firstSkillUrl = `${REACT_APP_STUDENT_LINK}/${props.sectionId}/${props.teacherId}/${firstSkill?.uid}`;

  useEffect(() => {
    if (navigateToFirstSkill) {
      navigate(firstSkillUrl, { replace: true });
    }
  }, [navigateToFirstSkill]);

  const isCorrectionEmpty =
    props.sa.correctionInitialized === true && props.ta.order?.length === 0;

  /* ************************ */
  /* initializeTestCorrection */
  /* ************************ */

  const handleInitializeCorrection = useMutation({
    mutationFn: () => {
      setLoadingData((prevState: any) => ({
        ...prevState,
        isShowing: true,
        error: false,
      }));
      return axios.post(
        deltamathAPI() + `/student/initializeTestCorrection/${props.teacherId}`,
        isLinkedCorrection
          ? payload.skipWarning
            ? JSON.stringify({ skipWarning: true })
            : null
          : JSON.stringify(payload),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: async (data: any) => {
      toastContext.addToast({
        status: "Success",
        message: "Correction successfully initialized",
      });
      if (data?.data?.assignment) {
        updateFullAssignmentData(
          data.data.assignment,
          data.data.assignment.ta._id,
          props.sectionId,
          dmAssignmentData,
          setDmAssignmentData
        );

        await initializeCorrections();

        const newFirstSkill: any = data?.data?.assignment?.ta?.order?.[0]
          ? data?.data?.assignment?.ta?.skills[
              data?.data?.assignment.ta.order[0]
            ]
          : undefined;
        const newFirstSkillUrl = `${REACT_APP_STUDENT_LINK}/${props.sectionId}/${props.teacherId}/${newFirstSkill?.uid}`;
        // If it's timed we will start the timer then allow the onSuccess to navigate the user
        if (timingState !== "timed" && newFirstSkill) {
          queryClient.invalidateQueries({
            queryKey: ["/student/data/assignments"],
          });
          queryClient.invalidateQueries({
            queryKey: ["/student/data/sections"],
          });

          navigate(newFirstSkillUrl, { replace: true });
        }
      }
      setLoadingData((prevState: any) => ({
        ...prevState,
        isShowing: false,
      }));
    },
    onError: (error: any) => {
      props.refetchAssignments();
      if (error?.response?.status === 423) {
        setPayload({
          ...payload,
          skipWarning: true,
        });
      }
      setLoadingData((prevState: any) => ({
        ...prevState,
        isShowing: true,
        error: true,
        title: "Error",
        message: error?.response?.data?.message,
      }));
    },
    onSettled: (data: any) => {
      if (data?.statusText === "OK") {
        setIsResetCorrectionActive(false);
      }
    },
  });

  useEffect(() => {
    if (timingState === "timed" && handleInitializeCorrection.isSuccess) {
      startTimed.mutate();
    }
  }, [
    timingState,
    handleInitializeCorrection,
    handleInitializeCorrection.isSuccess,
  ]);

  const pointValues = (key: any, pointsValue: number) => {
    setPayload({
      ...payload,
      points: { ...payload.points, [key]: pointsValue || 0 },
      lost_or_earned: props.ta.correction_points_lost_or_earned || "lost",
    });
    setCorrectionPoints({ ...correctionPoints, [key]: pointsValue });
  };

  const correctionValue = (key: string, totalValue: number) => {
    setPayload({
      ...payload,
      [key]: totalValue || 0,
    });
  };

  const initializeCorrections = async () => {
    if (props.ta.type === AssignmentType.correction) {
      const questions: any = props.ta.questions;
      const questionPoints: any = {};
      for (const key in questions) {
        questionPoints[key] = round(
          parseFloat(props.sa.testdata?.[key]) || 0,
          3
        );
      }

      return setPayload({
        ...defaultPayload,
        points: { ...questionPoints },
        extraPoints: props.sa.extra || 0,
        test: props.sa.testgrade || 0,
        lost_or_earned: props.ta.correction_points_lost_or_earned || "lost",
      });
    }
  };

  const resetCorrections = (e: any) => {
    if (e) {
      e.preventDefault();
    }
    const correctionActiveSwitch = !isResetCorrectionActive;
    setIsResetCorrectionActive(correctionActiveSwitch);
    initializeCorrections();
  };

  const reset = searchParams.get("reset");
  useEffect(() => {
    if (reset) {
      resetCorrections(undefined);
    }
  }, [searchParams]);

  const showResultsMutation = useShowResults(props.ta._id);
  const handleShowSolutionsData = (taId: any, skillName?: string) => {
    const body = skillName ? JSON.stringify({ sk: skillName }) : "{}";
    showResultsMutation.mutate(body, {
      onSuccess: (data) => {
        if (data?.data?.assignment) {
          updateFullAssignmentData(
            data.data.assignment,
            taId,
            props.sectionId,
            dmAssignmentData,
            setDmAssignmentData
          );
          navigate(firstSkillUrl, { replace: true });
        }
      },
    });
  };

  // Waiting on searchParams to load in
  if (!searchParams) {
    return <></>;
  }

  if (props.timedAssignment.isOver && !props.sa.solutions_seen) {
    return (
      <>
        {props.timedAssignment.isOver && (
          <>
            <div className="mb-10 flex flex-col gap-6">
              <h3 className="text-2xl font-bold">
                You&apos;ve Reached The Time Limit
              </h3>
              <p>You have run out of time to complete this assignment.</p>
            </div>
            <div className="flex justify-between border-t py-8">
              {props.ta.obscuredResults && <div></div>}
              <div className="flex gap-4">
                {!lti && (
                  <Button
                    onClick={() => {
                      navigate(assignmentsUrl);
                    }}
                    type={props.ta.obscureResults ? "outline" : "primary"}
                  >
                    Back to Home
                  </Button>
                )}
                {uploadWorkAvailable && (
                  <UploadWork
                    section={{ ta: props.ta, sa: props.sa }}
                    buttonType="outline"
                  />
                )}
              </div>
              {props.ta.obscureResults &&
                (props.ta.solutionsAvailable ||
                  (props.timedAssignment?.isTimed &&
                    props.ta.showSolutionsOnTimeOver === true &&
                    props.timedAssignment.isOver)) && (
                  <Button
                    onClick={() => {
                      handleShowSolutionsData(props.ta._id);
                    }}
                  >
                    Show Results
                  </Button>
                )}
            </div>
          </>
        )}
      </>
    );
  } else if (isCorrectionEmpty) {
    return (
      <>
        <p>
          There are no problems for you to solve in this test correction.{" "}
          {!lti
            ? "Go back to the assignments list to work on another assignment or reset test correction."
            : ""}
        </p>
        <div className="mt-8 flex flex-wrap items-center justify-between gap-x-6 gap-y-3 border-t border-t-[#CCCED0] pt-8 sm:flex-nowrap">
          <button
            role="button"
            className="basis-full rounded border border-dm-charcoal-500 bg-white px-5 py-2.5 text-center text-sm text-dm-charcoal-500 hover:bg-dm-charcoal-500 hover:text-white focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:basis-auto sm:px-8"
            onClick={resetCorrections}
          >
            Reset Test Correction
          </button>
          {!lti && (
            <NavLink
              to={assignmentsUrl}
              className={clsx(
                "basis-full rounded border px-5 py-2.5 text-center text-sm text-white focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:basis-auto sm:px-8",
                "border-dm-brand-blue-500 bg-dm-brand-blue-500 hover:bg-dm-brand-blue-600"
              )}
            >
              Back To Main
            </NavLink>
          )}
        </div>
      </>
    );
  } else if (
    correctionState === "non_initialized" ||
    timingState === "timed" ||
    resetCorrection ||
    reset
  ) {
    return (
      <div className="grid grid-cols-[auto_48px_minmax(0,_1fr)_auto] items-center gap-3">
        {(timingState === "timed" || timingState === "timed_pending") && (
          <div className="col-span-4">
            {timingState === "timed_pending" ? (
              <>Your timer is running!</>
            ) : (
              <>
                Once you start this correction, you will have{" "}
                <strong>
                  {props.timedAssignment.isUnlimited
                    ? "unlimited time"
                    : timeLimitText(props.timedAssignment.timeLimit)}
                </strong>{" "}
                to complete it.
              </>
            )}
          </div>
        )}

        <CorrectionQuestions
          isLinkedCorrection={isLinkedCorrection}
          teacherId={props.teacherId}
          questions={props.ta.questions}
          questionOrder={props.ta.questionOrder}
          testgrade={props.sa.testgrade}
          totalPoints={props.ta.totalPoints}
          correctionValue={correctionValue}
          pointValues={pointValues}
          isPointsTypeEarned={
            props.ta.correction_points_lost_or_earned === "earned"
          }
          testdata={props.sa.testdata}
          extraPoints={props.ta.extraPoints}
          extra={props.sa.extra}
        />
        {!resetCorrection &&
        props.sa.correctionInitialized === false &&
        !lti ? (
          <div className="col-span-2">
            <Button
              onClick={() => {
                setIsResetCorrectionActive(false);
                if (!isCorrectionEmpty) {
                  if (lti) {
                    navigate(firstSkillUrl, { replace: true });
                  } else {
                    navigate(assignmentsUrl, { replace: true });
                  }
                }
              }}
              type="outline"
            >
              Back to Home
            </Button>
          </div>
        ) : resetCorrection &&
          props.sa.correctionInitialized === true &&
          !lti ? (
          <div className="col-span-2">
            <Button
              onClick={() => {
                setIsResetCorrectionActive(false);
                if (!isCorrectionEmpty) {
                  if (lti) {
                    navigate(firstSkillUrl, { replace: true });
                  } else {
                    navigate(assignmentsUrl, { replace: true });
                  }
                }
              }}
              type="outline"
            >
              Cancel
            </Button>
          </div>
        ) : (
          <div className="col-span-2"></div>
        )}

        <div
          className={`${
            !lti &&
            ((!resetCorrection && props.sa.correctionInitialized === false) ||
              (resetCorrection && props.sa.correctionInitialized === true))
              ? "col-span-2"
              : "col-span-4"
          } text-right`}
        >
          <button
            type="button"
            onClick={() => {
              handleInitializeCorrection.mutate();
            }}
            className={clsx(
              "w-full rounded border px-5 py-2.5 text-center text-sm text-white focus-visible:outline focus-visible:outline-offset-2 focus-visible:outline-indigo-600 sm:w-auto sm:px-8",
              "border-dm-brand-blue-500 bg-dm-brand-blue-500 hover:bg-dm-brand-blue-600"
            )}
          >
            Initialize {resetCorrection ? "New" : ""} Test Corrections
          </button>
        </div>
      </div>
    );
  }

  // We are in the else scenario where the correction has already been initialized and is not being reset
  if (!navigateToFirstSkill) {
    setNavigateToFirstSkill(true);
  }

  return <></>;
};

export default TestCorrection;
