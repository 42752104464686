import { useDocumentTitle } from "usehooks-ts";
import { DataTableContextProvider } from "../DataTable/DataTableContext";
import { DataTable } from "../DataTable/DataTable";
import { RenewalsSummary } from "./RenewalsSummary";
import RenewalLinkForm from "./RenewalLinkForm";
import { EXCLUSION_LIST } from "../../../shared/constants";
import { executeQuery } from "../../../utils";
import { IRenewal } from "../../utils/quoteUtils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { EmptyCell } from "../DataTable/EmptyCell";
import { ContactsCell } from "./ContactsCell";
import { QuotesCell } from "./QuotesCell";
import { MetadataCell } from "./MetadataCell";

const QUERY_KEY = "renewalsTable";

export const RenewalsPage: React.FC = () => {
  useDocumentTitle("Renewals");

  const queryClient = useQueryClient();
  const toastContext = useDeltaToastContext();

  const updateMutation = useMutation({
    mutationFn: async ({
      renewalId,
      update,
    }: {
      renewalId: string;
      update: Partial<IRenewal>;
    }) => {
      return await executeQuery({
        path: `/manager_new/renewals/${renewalId}`,
        method: "put",
        requestBody: update,
      });
    },
  });

  const updateRenewal = (
    renewalId: string,
    columnName: string,
    value: string | boolean
  ) => {
    const update: Partial<IRenewal> = { [columnName]: value };
    if (columnName === "renewalCleared" && update.renewalCleared) {
      const { email } = JSON.parse(localStorage.getItem("user") || "{}");
      update.manuallyClearedBy = { email, time: Date.now() };
    }
    updateMutation.mutate(
      { renewalId, update },
      {
        onSuccess: ({ success, message }) => {
          queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });

          toastContext.addToast({
            status: success ? "Success" : "Error",
            message: success
              ? "Renewal updated"
              : message ?? "Error updating renewal",
          });
        },
        onError: (error) => {
          const message = typeof error === "string" ? error : error.message;
          queryClient.invalidateQueries({ queryKey: [QUERY_KEY] });
          toastContext.addToast({
            status: "Error",
            message: `Error updating renewal: ${message}`,
          });
        },
      }
    );
  };

  return (
    <main className="flex flex-col gap-6 p-6">
      <h1 className="text-2xl font-bold">Renewals</h1>
      <RenewalsSummary />
      <DataTableContextProvider
        queryKey={QUERY_KEY}
        source="/manager_new/renewals/all/new"
        sortable={[
          "nces",
          "weight",
          "renewalWeightCalculated",
          "renewalCleared",
          "renewalPlusDowngrade",
          "renewalGradeDowngrade",
        ]}
        filterable={["name", "nces", "contacts"]}
        defaults={{ sortBy: [{ id: "nces", desc: false }] }}
        globalFilter
        fetchOnLoad
        columnOverrides={{
          generateLink: ({ cell }: any) => {
            const individualLicenses: Array<{
              licenseTier: "Plus" | "Integral";
              licenseCount: number;
            }> = [];
            if (cell.row.original.type === "individual") {
              if (cell.row.original.individualPlus) {
                individualLicenses.push({
                  licenseTier: "Plus",
                  licenseCount: cell.row.original.individualPlus,
                });
              }
              if (cell.row.original.individualIntegral) {
                individualLicenses.push({
                  licenseTier: "Integral",
                  licenseCount: cell.row.original.individualIntegral,
                });
              }
            }
            return (
              <RenewalLinkForm
                renewalID={cell.value}
                key={`renewal-link-form-${cell.value}`}
                disabled={Object.hasOwn(EXCLUSION_LIST, cell.row.original.nces)}
                type={cell.row.original.type}
                nces={cell.row.original.nces}
                individualLicenses={individualLicenses}
              />
            );
          },
          contacts: ({ cell }: any) => (
            <ContactsCell
              key={`contacts-${cell.row.original._id}`}
              contacts={cell.value}
            />
          ),
          quotes: ({ cell }: any) => (
            <QuotesCell
              key={`quotes-${cell.row.original._id}`}
              quotes={cell.value}
            />
          ),
          renewalQuoteMetadata: ({ cell }: any) => (
            <MetadataCell
              key={`meta-${cell.row.original._id}`}
              meta={cell.value}
            />
          ),
          renewalCleared: ({ cell }: any) => {
            const renewal = cell.row.original as IRenewal;
            const renewalPercentComplete = renewal.renewalWeightCalculated ?? 0;
            return (
              <input
                key={`renewalCleared-${cell.row.original._id}`}
                type="checkbox"
                checked={!!cell.value}
                disabled={
                  !!renewal.renewalCleared &&
                  (renewalPercentComplete > 0.75 ||
                    (renewal.type === "individual" &&
                      renewalPercentComplete > 0.5))
                }
                className="disabled:cursor-not-allowed disabled:opacity-50"
                onChange={() =>
                  updateRenewal(
                    cell.row.original._id,
                    "renewalCleared",
                    !cell.value
                  )
                }
              />
            );
          },
          nonRenewalConfirmed: ({ cell }: any) =>
            !cell.row.values.renewalWeight ? (
              <input
                key={`nonRenewalConfirmed-${cell.row.original._id}`}
                type="checkbox"
                checked={!!cell.value}
                onChange={() =>
                  updateRenewal(
                    cell.row.original._id,
                    "nonRenewalConfirmed",
                    !cell.value
                  )
                }
              />
            ) : (
              <EmptyCell />
            ),
          renewalNotes: ({ cell }: any) => (
            <textarea
              key={`renewalNotes-${cell.row.original._id}`}
              className="h-24 w-40 rounded-lg border-2 border-gray-300 p-2"
              defaultValue={cell.value}
              onBlur={(e) => {
                const oldNote = cell.value;
                const newNote = e.target.value.trim();
                if (
                  oldNote !== newNote &&
                  !(
                    (!oldNote && newNote === "") ||
                    (oldNote === "" && !newNote)
                  )
                ) {
                  updateRenewal(cell.row.original._id, "renewalNotes", newNote);
                }
              }}
            />
          ),
        }}
      >
        <DataTable />
      </DataTableContextProvider>
    </main>
  );
};
