import { Fragment, useEffect, useState } from "react";
import {
  coerceLicenseType,
  discriminateInvoiceType,
  getIntegrationsString,
  IFoundSiteLicenseForInvoice,
} from "../../utils/quoteUtils";
import { useActivationContext } from "./LicenseActivationContext";
import { isAfter } from "date-fns";

const DoubleActivationCheck: React.FC<{
  exactMatch: boolean;
  schoolMatch: boolean;
  districtMatch: boolean;
  districtToSchool: "warn" | "disable" | "";
  setExactMatch: React.Dispatch<React.SetStateAction<boolean>>;
  setSchoolMatch: React.Dispatch<React.SetStateAction<boolean>>;
  setDistrictMatch: React.Dispatch<React.SetStateAction<boolean>>;
  setDistrictToSchool: React.Dispatch<
    React.SetStateAction<"warn" | "disable" | "">
  >;
}> = ({
  exactMatch,
  schoolMatch,
  districtMatch,
  districtToSchool,
  setExactMatch,
  setSchoolMatch,
  setDistrictMatch,
  setDistrictToSchool,
}) => {
  const activationContext = useActivationContext();

  const [generallyQuotedType, setGenerallyQuotedType] = useState<
    "school" | "district" | "individual"
  >();

  const [siteLicenseToCompare, setSiteLicenseToCompare] = useState<
    IFoundSiteLicenseForInvoice | undefined
  >(undefined);

  const [licenseMatch, setLicenseMatch] = useState<Set<string>>(
    new Set<string>()
  );

  useEffect(() => {
    if (activationContext.invoiceDetails?.type) {
      setGenerallyQuotedType(
        discriminateInvoiceType(activationContext.invoiceDetails.type)
      );
    }
  }, [activationContext.invoiceDetails?.type]);

  useEffect(() => {
    if (
      (generallyQuotedType === "school" ||
        generallyQuotedType === "district") &&
      activationContext.licenseData?.[generallyQuotedType]?.status === "active"
    ) {
      setSiteLicenseToCompare(
        activationContext.licenseData[
          generallyQuotedType
        ] as IFoundSiteLicenseForInvoice
      );
    } else if (
      generallyQuotedType === "school" &&
      (!activationContext.licenseData?.school ||
        activationContext.licenseData?.school?.status !== "active") &&
      activationContext.licenseData?.district?.status === "active"
    ) {
      setSiteLicenseToCompare(activationContext.licenseData?.district);
    } else {
      setSiteLicenseToCompare(undefined);
    }
  }, [generallyQuotedType]);

  //Track 5 key dimensions of a license and determine if the already existing license matches the qutoed license along them. Since we're using the invoice / license details pulled down (and not the current state) I think there's an argument here for a larger useEffect which checks all the fields at once. This would prevent multiple re-renders.
  useEffect(() => {
    const matches = new Set<string>(licenseMatch);

    //integrations
    if (
      activationContext.invoiceDetails &&
      siteLicenseToCompare?.integrations ===
        getIntegrationsString(activationContext.invoiceDetails)
    ) {
      matches.add("integrations");
    } else {
      matches.delete("integrations");
    }

    //expiration
    if (
      siteLicenseToCompare &&
      activationContext.invoiceDetails?.expiration &&
      !isAfter(
        new Date(activationContext.invoiceDetails?.expiration * 1000),
        new Date(siteLicenseToCompare.expiration)
      )
    ) {
      matches.add("expiration");
    } else {
      matches.delete("expiration");
    }

    //site ID
    if (siteLicenseToCompare?.siteID === activationContext.invoiceDetails?.id) {
      matches.add("id");
    } else {
      matches.delete("id");
    }

    //licenseType
    if (siteLicenseToCompare?.type === activationContext.invoiceDetails?.type) {
      matches.add("type");
    } else {
      matches.delete("type");
    }

    //license tier
    if (
      siteLicenseToCompare?.tier?.toLowerCase() ===
      activationContext.invoiceDetails?.licenseTier?.toLowerCase()
    ) {
      matches.add("licenseTier");
    } else {
      matches.delete("licenseTier");
    }

    setLicenseMatch(matches);
  }, [activationContext.invoiceDetails, siteLicenseToCompare]);

  //The mathces on the license data alone don't tell us exactly what the warnings should be / whether to disable ativation. This useEffect will determine that.
  useEffect(() => {
    if (licenseMatch.size > 0 && siteLicenseToCompare?.type) {
      const type = coerceLicenseType(siteLicenseToCompare.type);
      const exactMatch = licenseMatch.size === 5;
      setExactMatch(exactMatch);
      setDistrictMatch(
        !exactMatch &&
          licenseMatch.has("expiration") &&
          generallyQuotedType === "district" &&
          discriminateInvoiceType(type) === "district"
      );
      setSchoolMatch(
        !exactMatch &&
          licenseMatch.has("expiration") &&
          generallyQuotedType === "school" &&
          discriminateInvoiceType(type) === "school" &&
          !siteLicenseToCompare?.isDoubleLicense
      );
      if (
        !exactMatch &&
        generallyQuotedType === "school" &&
        discriminateInvoiceType(type) === "district" &&
        licenseMatch.has("expiration")
      ) {
        setDistrictToSchool(
          licenseMatch.has("licenseTier") && licenseMatch.has("integrations")
            ? "disable"
            : "warn"
        );
      } else if (
        generallyQuotedType === "school" &&
        siteLicenseToCompare?.isDoubleLicense &&
        activationContext.licenseData?.district?.status === "active" &&
        activationContext.invoiceDetails?.expiration &&
        !isAfter(
          new Date(activationContext.invoiceDetails?.expiration * 1000),
          new Date(activationContext.licenseData.district.expiration)
        )
      ) {
        setDistrictToSchool(
          activationContext.invoiceDetails?.licenseTier ===
            activationContext.licenseData.district.tier &&
            getIntegrationsString(activationContext.invoiceDetails) ===
              activationContext.licenseData.district.integrations
            ? "disable"
            : "warn"
        );
      } else {
        setDistrictToSchool("");
      }
    }
  }, [licenseMatch]);

  return (
    <Fragment>
      {(exactMatch ||
        schoolMatch ||
        districtMatch ||
        districtToSchool === "warn" ||
        districtToSchool === "disable") && (
        <ul>
          {(districtMatch || schoolMatch || districtToSchool === "warn") && (
            <li className="max-w-[700px] text-dm-earth-yellow-600">
              {siteLicenseToCompare?.isDoubleLicense
                ? "This site is currently covered by existing school and district licenses."
                : "A current license for this site already exists."}
              {districtToSchool === "warn"
                ? ` If you activate this license, the site may be paying for license features it already has access to. Please confirm with the customer that this is correct. Leave an internal note if needed.`
                : ` Activating this license will overwrite the existing one. Please confirm with the customer that this is correct. Leave an internal note if needed.`}
            </li>
          )}
          {exactMatch && (
            <li className="mt-2 max-w-[700px] text-red-500">
              <strong>
                This site has an active license matching this quote exactly
                (i.e. the NCES, expiration, license type, license tier, and
                integrations all match)
              </strong>
            </li>
          )}
          {districtToSchool === "disable" && (
            <li className="mt-2 max-w-[700px] text-red-500">
              <strong>
                This school is covered under a district license which expires on
                or after the date of this quote and has the same integrations
                and tier.
              </strong>
            </li>
          )}
        </ul>
      )}
    </Fragment>
  );
};
export default DoubleActivationCheck;
