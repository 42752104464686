import { useState, useEffect } from "react";
import Button from "../../../student/components/generic/button";
import { useDMQuery } from "../../../utils";
import { DmLoadingSpinner } from "../../utils/functions";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { deltamathAPI } from "../../utils";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { BasicContentContainer } from "../BasicContentContainer";
import { PriceScheme } from "./types";
import { PriceSchemesTable } from "./PriceSchemesTable";
import { PriceSchemeModal } from "./PriceSchemeModal";
import DeltaMathToggle from "../../../admin/reports/student-performance/DeltaMathToggle";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useDocumentTitle } from "usehooks-ts";

export const PriceSchemesPage = () => {
  useDocumentTitle("Price Schemes");
  const toastContext = useDeltaToastContext();
  const [includeArchived, setIncludeArchived] = useState(false);
  const [selectedScheme, setSelectedScheme] = useState<PriceScheme | undefined>(
    undefined
  );
  const [showCreatePriceSchemeModal, setShowCreatePriceSchemeModal] =
    useState(false);
  const [defaultScheme, setDefaultScheme] = useState<PriceScheme | undefined>(
    undefined
  );

  const {
    data: priceSchemeData,
    refetch: refetchPriceSchemes,
    isLoading,
  } = useDMQuery<PriceScheme[]>({
    path: `/manager_new/price-scheme/list?includeArchived=${includeArchived}`,
    queryOptions: {
      staleTime: 1000 * 60 * 5, // 5 minutes
      refetchOnWindowFocus: false,
    },
  });

  useEffect(() => {
    if (priceSchemeData) {
      const defaultScheme = priceSchemeData.find((scheme) => scheme.default);
      setDefaultScheme(defaultScheme);
    }
  }, [priceSchemeData]);

  const { mutateAsync: publishScheme } = useMutation({
    mutationFn: () => {
      return axios.post(
        `${deltamathAPI()}/manager_new/price-scheme/publish`,
        { key: selectedScheme?.key },
        withJsonHeader()
      );
    },
    onError: (error: any) => {
      toastContext.addToast({
        message: error.response?.data?.message || "Unable to publish scheme",
        status: "Error",
      });
    },
  });

  const { mutate: sync, isPending: isSyncing } = useMutation({
    mutationFn: () => {
      return axios.post(`${deltamathAPI()}/manager_new/price-scheme/sync`, {
        key: selectedScheme?.key,
      });
    },
    onSuccess: () => {
      toastContext.addToast({
        message: "Products synced successfully",
        status: "Success",
      });
    },
    onError: (error: any) => {
      toastContext.addToast({
        message: error.response?.data?.message || "Unable to sync products",
        status: "Error",
      });
    },
  });

  const { mutateAsync: deleteScheme } = useMutation({
    mutationFn: () => {
      return axios.delete(
        `${deltamathAPI()}/manager_new/price-scheme/delete/${
          selectedScheme?.key
        }`,
        withJsonHeader()
      );
    },
    onError: (error: any) => {
      toastContext.addToast({
        message: error.response?.data?.message || "Unable to delete scheme",
        status: "Error",
      });
    },
  });

  const handleMakeDefault = async () => {
    if (selectedScheme) {
      try {
        await axios.post(
          `${deltamathAPI()}/manager_new/price-scheme/set-default`,
          { key: selectedScheme.key }
        );
        toastContext.addToast({
          message: "Price scheme set as default successfully",
          status: "Success",
        });
        refetchPriceSchemes();
      } catch (error: any) {
        toastContext.addToast({
          message:
            error.response?.data?.message || "Unable to set default scheme",
          status: "Error",
        });
      }
    }
  };

  const handlePublish = async () => {
    const message = `Are you sure you want to publish the scheme with key ${selectedScheme?.key}? This will make it appear on the DeltaMath Gatsby Homepage to display the prices on the quote page.`;
    if (window.confirm(message)) {
      await publishScheme();
      refetchPriceSchemes();
    }
  };

  return (
    <div className="flex flex-col gap-6 p-6">
      <header>
        <h1 className="mb-2 text-2xl font-bold">Price Schemes</h1>
        The pricing schemes defined herein serve as the common basis of pricing
        for generating DeltaMath Quotes.
        <div>
          <hr className="my-4 border-t border-gray-300" />
          <strong>Status Explanation</strong>
          <ul className="text-sm text-dm-charcoal-500">
            <li>
              <b>Draft:</b> These plans have been created, but not yet operative
            </li>
            <li>
              <b>Published:</b> These schemes are currently in effect
              <ul className="ml-3 list-inside list-disc">
                <li>
                  There will only ever be two published plans at one
                  time&mdash;Full Year and Pro-rated
                </li>
                <li>Publishing will archive the currently published plans.</li>
              </ul>
            </li>
            <li>
              <b>Archived:</b> Plans from past years
            </li>
          </ul>
        </div>
        <div className="pt-2">
          <strong>Default</strong>
          <p className="text-sm text-dm-charcoal-500">
            There will only every be one plan which is default, this plan will
            be used on the DeltaMath Gatsby Homepage to Display the prices on
            the quote page.
          </p>
        </div>
      </header>
      <div className="flex gap-4">
        <Button
          className="flex w-auto items-center gap-3 !px-4"
          size="small"
          disabled={isLoading || selectedScheme !== undefined}
          onClick={() => {
            setShowCreatePriceSchemeModal(true);
          }}
        >
          <i className="fas fa-plus" />
          Create
        </Button>
        <Button
          className="flex w-auto items-center gap-3 !px-4"
          size="small"
          disabled={!selectedScheme || selectedScheme.status !== "draft"}
          onClick={() => setShowCreatePriceSchemeModal(true)}
        >
          <i className="fas fa-edit" />
          Edit
        </Button>
        <Button
          className="flex w-auto items-center gap-3 bg-dm-charcoal-500 !px-4 hover:bg-dm-charcoal-600 disabled:hover:bg-dm-charcoal-500"
          size="small"
          disabled={!selectedScheme || selectedScheme.status !== "draft"}
          onClick={handlePublish}
        >
          <i className="fas fa-arrow-alt-circle-up" />
          Publish
        </Button>
        <Button
          className="flex w-auto items-center gap-3 bg-dm-charcoal-500 !px-4 hover:bg-dm-charcoal-600 disabled:hover:bg-dm-charcoal-500"
          size="small"
          onClick={sync}
          disabled={!selectedScheme}
        >
          <i className="fas fa-sync-alt" />
          Sync Products
        </Button>
        <Button
          className="flex w-auto items-center gap-3 bg-dm-charcoal-500 !px-4 hover:bg-dm-charcoal-600 disabled:hover:bg-dm-charcoal-500"
          size="small"
          onClick={handleMakeDefault}
          disabled={
            !selectedScheme ||
            selectedScheme.status !== "published" ||
            selectedScheme.default
          }
        >
          Make Default
        </Button>
        <Button
          className="flex w-auto items-center gap-3 bg-dm-error-500 !px-4 hover:bg-dm-error-600 disabled:hover:bg-dm-error-500"
          size="small"
          disabled={!selectedScheme || selectedScheme.status !== "draft"}
          onClick={async () => {
            if (
              window.confirm(
                `Are you sure you want to delete the scheme with key ${selectedScheme?.key}?`
              )
            ) {
              await deleteScheme();
              refetchPriceSchemes();
            }
          }}
        >
          <i className="fas fa-trash" />
          Delete
        </Button>
        <div className="toggle-container ml-auto">
          <DeltaMathToggle
            optionA="Show Archived"
            optionB="Hide Archived"
            aSelected={includeArchived}
            onChangeFn={() => {
              setIncludeArchived(!includeArchived);
              refetchPriceSchemes();
            }}
          />
        </div>
      </div>
      {isLoading && (
        <BasicContentContainer>
          <DmLoadingSpinner message="" />
        </BasicContentContainer>
      )}
      {isSyncing && (
        <div className="absolute inset-0 z-50 flex items-center justify-center bg-white bg-opacity-75">
          <i className="fas fa-spinner fa-spin text-2xl" />
        </div>
      )}
      {priceSchemeData && (
        <PriceSchemesTable
          schemes={priceSchemeData}
          setSelectedScheme={setSelectedScheme}
        />
      )}
      {priceSchemeData && (
        <PriceSchemeModal
          key={priceSchemeData.length}
          visible={showCreatePriceSchemeModal}
          onClose={() => setShowCreatePriceSchemeModal(false)}
          onSchemeCreated={() => {
            refetchPriceSchemes();
            setShowCreatePriceSchemeModal(false);
          }}
          onSchemeEdited={() => {
            refetchPriceSchemes();
            setShowCreatePriceSchemeModal(false);
          }}
          selectedScheme={selectedScheme}
          defaultScheme={defaultScheme}
        />
      )}
    </div>
  );
};
