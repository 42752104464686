import React, { useEffect, useState } from "react";
import { IInvoice, RosteringDataForInvoice } from "../../utils/quoteUtils";
import InvoicePageEditableCard from "./InvoicePageEditableCard";
import { useActivationContext } from "./LicenseActivationContext";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { executeQuery } from "../../../utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import IntegrationsRosteringComponent, {
  IntegrationData,
} from "./IntegrationsRostering";
import axios, { AxiosError } from "axios";
import { deltamathAPI } from "../../utils";
import Warnings from "./Warnings";

const LMS_VALUES = ["Schoology", "Canvas"];
const ROSTERING_SYSTEM_VALUES = ["ClassLink/OneRoster", "Clever"];
const VERIFICATION_STATUS_VALUES = ["verified", "requested"];

const IntegrationsCard: React.FC<{
  data: IInvoice;
}> = ({ data }) => {
  const queryClient = useQueryClient();
  const toastContext = useDeltaToastContext();
  const activationContext = useActivationContext();

  const [edit, setEdit] = useState(false);
  const [showEditButton, setShowEditButton] = useState(true);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [localData, setLocalData] = useState<IntegrationData>({
    id: data.id,
    type: data.type,
    ...(data.lms && { lms: data.lms }),
    ...(data.rosteringSystem && { rosteringSystem: data.rosteringSystem }),
    ...(data.classlinkTenantId && {
      classlinkTenantId: data.classlinkTenantId,
    }),
    ...(data.cleverDistrictId && { cleverDistrictId: data.cleverDistrictId }),
    ...(data.integrationStatus && {
      integrationStatus: data.integrationStatus,
    }),
    ...(data.rosteringData && { rosteringData: data.rosteringData }),
  });

  const {
    isFetching: isLmsDataFetching,
    isError: isLmsDataError,
    data: lmsData,
    error: lmsDataError,
    refetch: refetchLmsData,
  } = useQuery<{ rosteringData: RosteringDataForInvoice }>({
    queryKey: ["lms-data"],
    queryFn: async () => {
      let integration: string | undefined;
      let resourceId: string | undefined;
      if (localData.rosteringSystem === "Clever") {
        integration = "clever";
        resourceId = localData.cleverDistrictId;
      } else if (localData.rosteringSystem === "ClassLink/OneRoster") {
        integration = "class-link";
        resourceId = localData.classlinkTenantId;
      }

      try {
        // I am  using a raw axios request here instead of the executeQuery function since it (a) does not support a timeout
        // and (b) if I add the timeout then it swallows the error and misthrows it
        const response = await axios.request({
          url:
            deltamathAPI() +
            `/manager_new/invoices/details/${data.quoteNumber}/rostering`,
          method: "get",
          params: {
            rostering: integration,
            resourceId,
            id: localData.id,
          },
          timeout: 15000,
        });

        return response.data;
      } catch (err: any) {
        if (err instanceof AxiosError) {
          if (err.response?.data?.message) {
            throw new Error(err.response.data.message);
          } else {
            throw new Error(err.message);
          }
        }
        if (err instanceof Error) {
          throw err;
        }
        throw new Error(err);
      }
    },
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    retry: false,
    gcTime: 0,
    enabled: false,
  });

  const { mutate: postActivationUpdate } = useMutation({
    mutationFn: async (body: Omit<IntegrationData, "type" | "id">) => {
      return await executeQuery({
        path: `/manager_new/invoices/integrations/${data.quoteNumber}`,
        requestBody: body,
        method: "put",
      });
    },
    onSuccess: () => {
      toastContext.addToast({
        status: "Success",
        message: "Quote updated successfully",
      });
    },
    onError: (error) => {
      console.error(error);
      toastContext.addToast({
        status: "Error",
        message:
          error?.message ||
          `Error updating post activation integrations for ${data.quoteNumber}`,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["invoice", `${data.quoteNumber}`],
      });
    },
  });

  // Save the edits
  const handleLocalSave = () => {
    // We deconstruct the local data to only send the relevant which are meant to be updated
    // By this this endpoint
    const integrationData = {
      lms: localData.lms || "",
      rosteringSystem: localData.rosteringSystem || "",
      integrationStatus: localData.integrationStatus || "",
      ...(localData.classlinkTenantId && {
        classlinkTenantId: localData.classlinkTenantId,
      }),
      ...(localData.cleverDistrictId && {
        cleverDistrictId: localData.cleverDistrictId,
      }),
      ...(localData.rosteringData && {
        rosteringData: localData.rosteringData,
      }),
    } as IntegrationData;

    // If the status is activated, we need to trigger the post activation integrations update
    if (data.status === "Activated") {
      postActivationUpdate(integrationData);
    }

    // Otherwise, simply update the local data
    else {
      activationContext.simpleQuoteUpdate(integrationData);
    }

    setEdit(false);
  };

  useEffect(() => {
    if (isLmsDataError) {
      console.error(lmsDataError);
      toastContext.addToast({
        status: "Error",
        message: lmsDataError?.message || "Error fetching LMS data",
      });
    }
  }, [lmsDataError, isLmsDataError]);

  useEffect(() => {
    if (
      edit &&
      !lmsData &&
      localData.rosteringSystem &&
      (localData.classlinkTenantId || localData.cleverDistrictId)
    ) {
      refetchLmsData();
    }
  }, [edit, lmsData]);

  useEffect(() => {
    if (localData.rosteringSystem === "Clever" && !localData.cleverDistrictId) {
      activationContext.setActivationDisableReasons(
        "missingCleverTenant",
        "add"
      );
    } else {
      activationContext.setActivationDisableReasons(
        "missingCleverTenant",
        "remove"
      );
    }

    if (
      localData.rosteringSystem === "ClassLink/OneRoster" &&
      !localData.classlinkTenantId
    ) {
      activationContext.setActivationDisableReasons(
        "missingClassLinkTenant",
        "add"
      );
    } else {
      activationContext.setActivationDisableReasons(
        "missingClassLinkTenant",
        "remove"
      );
    }

    if (
      (localData.classlinkTenantId || localData.cleverDistrictId) &&
      localData.integrationStatus !== "verified" &&
      localData.rosteringSystem
    ) {
      activationContext.setActivationDisableReasons(
        "missingRosteringVerification",
        "add"
      );
    } else {
      activationContext.setActivationDisableReasons(
        "missingRosteringVerification",
        "remove"
      );
    }
  }, [localData]);

  return (
    <InvoicePageEditableCard
      title={"Integrations"}
      fullWidth={true}
      saveDisabled={saveDisabled}
      handleLocalSave={handleLocalSave}
      setEdit={setEdit}
      showEditButton={showEditButton}
    >
      <div className="flex flex-col space-y-4">
        <div className="flex flex-row space-x-4">
          <div className="flex-1">
            <dt className="text-sm font-medium text-gray-500">L.M.S.</dt>
            <dd className="mt-1 text-sm text-gray-900">
              {edit && localData ? (
                <select
                  id="lms"
                  className="border-gray-300 md:rounded-md"
                  name="lms"
                  value={localData.lms || ""}
                  onChange={(e) => {
                    if (LMS_VALUES.includes(e.target.value)) {
                      setLocalData({
                        ...localData,
                        lms: e.target.value as IntegrationData["lms"],
                      });
                    } else {
                      const { lms, ...rest } = localData;
                      setLocalData(rest);
                    }
                    setSaveDisabled(false);
                  }}
                >
                  <option value="">--</option>
                  <option value="Schoology">Schoology</option>
                  <option value="Canvas">Canvas</option>
                </select>
              ) : (
                <div>{localData?.lms || "--"}</div>
              )}
            </dd>
          </div>

          <div className="flex-1">
            <dt className="text-sm font-medium text-gray-500">
              Rostering System
            </dt>
            <dd className="mt-1 text-sm text-gray-900">
              {edit && localData ? (
                <select
                  id="rosteringSystem"
                  className="border-gray-300 md:rounded-md"
                  name="rosteringSystem"
                  value={localData.rosteringSystem || ""}
                  onChange={(e) => {
                    if (ROSTERING_SYSTEM_VALUES.includes(e.target.value)) {
                      setLocalData({
                        ...localData,
                        rosteringSystem: e.target
                          .value as IntegrationData["rosteringSystem"],
                      });
                    } else {
                      const { rosteringSystem, ...rest } = localData;
                      setLocalData(rest);
                    }
                    setSaveDisabled(false);
                  }}
                >
                  <option value="">--</option>
                  <option value="ClassLink/OneRoster">
                    ClassLink/OneRoster
                  </option>
                  <option value="Clever">Clever</option>
                </select>
              ) : (
                <div>{localData?.rosteringSystem || "--"}</div>
              )}
            </dd>
          </div>
        </div>

        {localData.rosteringSystem && (
          <div className="flex flex-col space-y-4">
            <div className="flex flex-row space-x-4">
              <div className="flex-1">
                <dt className="text-sm font-medium text-gray-500">
                  {localData.rosteringSystem === "Clever"
                    ? "Clever District ID"
                    : "ClassLink Tenant ID"}
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {edit && localData ? (
                    <>
                      <input
                        type="text"
                        className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                        value={
                          localData.rosteringSystem === "Clever"
                            ? localData.cleverDistrictId || ""
                            : localData.classlinkTenantId || ""
                        }
                        onChange={(e) => {
                          setLocalData({
                            ...localData,
                            [localData.rosteringSystem === "Clever"
                              ? "cleverDistrictId"
                              : "classlinkTenantId"]: e.target.value,
                          });
                          setSaveDisabled(false);
                        }}
                      />
                      {localData.rosteringSystem === "ClassLink/OneRoster" &&
                        localData.classlinkTenantId &&
                        !isNaN(+localData.classlinkTenantId) && (
                          <button
                            className="mt-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => {
                              refetchLmsData();
                            }}
                          >
                            Search ClassLink
                          </button>
                        )}
                      {localData.rosteringSystem === "Clever" &&
                        localData.cleverDistrictId && (
                          <button
                            className="mt-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            onClick={() => {
                              refetchLmsData();
                            }}
                          >
                            Search Clever
                          </button>
                        )}
                    </>
                  ) : (
                    <div>
                      {localData.rosteringSystem === "Clever"
                        ? localData.cleverDistrictId || "--"
                        : localData.classlinkTenantId || "--"}
                    </div>
                  )}
                </dd>
              </div>

              <div className="flex-1">
                <dt className="text-sm font-medium text-gray-500">
                  Verification Status
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {edit && localData ? (
                    <select
                      id="integrationStatus"
                      className="border-gray-300 md:rounded-md"
                      name="integrationStatus"
                      value={localData.integrationStatus || ""}
                      onChange={(e) => {
                        if (
                          VERIFICATION_STATUS_VALUES.includes(e.target.value)
                        ) {
                          setLocalData({
                            ...localData,
                            integrationStatus: e.target
                              .value as IntegrationData["integrationStatus"],
                          });
                        } else {
                          const { integrationStatus, ...rest } = localData;
                          setLocalData(rest);
                        }
                        setSaveDisabled(false);
                      }}
                    >
                      <option value="">--</option>
                      <option value="verified">Verified Data Share</option>
                      <option value="requested">Requested Data Share</option>
                    </select>
                  ) : (
                    <div>
                      {localData.integrationStatus === "verified"
                        ? "Verified Data Share"
                        : localData.integrationStatus === "requested"
                        ? "Requested Data Share"
                        : "--"}
                    </div>
                  )}
                  {activationContext.state.activationDisableReasons.has(
                    "rostering"
                  ) && (
                    <div className="mt-2 text-red-500">
                      rostering must have saved verified status to activate
                    </div>
                  )}
                </dd>
              </div>
            </div>

            {!lmsDataError && (
              <IntegrationsRosteringComponent
                externalData={lmsData?.rosteringData}
                localData={localData}
                setLocalData={setLocalData}
                isLmsDataFetching={isLmsDataFetching}
                edit={edit}
                setSaveDisabled={setSaveDisabled}
              />
            )}
          </div>
        )}

        <Warnings>
          {activationContext.state?.activationDisableReasons.has(
            "missingCleverTenant"
          ) && (
            <div>
              <div className="text-center text-sm text-red-500">
                Activation Warning:
              </div>
              <div className="text-center text-sm text-red-500">
                Need Clever ID
              </div>
            </div>
          )}

          {activationContext.state?.activationDisableReasons.has(
            "missingClassLinkTenant"
          ) && (
            <div>
              <div className="text-center text-sm text-red-500">
                Activation Warning:
              </div>
              <div className="text-center text-sm text-red-500">
                Need ClassLink Tenant ID
              </div>
            </div>
          )}

          {activationContext.state?.activationDisableReasons.has(
            "missingRosteringVerification"
          ) && (
            <div>
              <div className="text-center text-sm text-red-500">
                Activation Warning:
              </div>
              <div className="text-center text-sm text-red-500">
                Status must be &quot;verified&quot;
              </div>
            </div>
          )}
        </Warnings>
      </div>
    </InvoicePageEditableCard>
  );
};

export default IntegrationsCard;
