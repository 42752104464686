import { useContext, useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useMediaQuery } from "usehooks-ts";
import {
  skillToSolve,
  skillDataDisplay,
  getPenalty,
  timedAssg,
  findNearestUpcoming,
} from "../utils";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import ProgressBar from "../../shared/ProgressBar";
import StandardSkills from "../components/StandardSkills";
import TimedModule from "../components/TimedModule";
import ExamplesModal from "../components/ExamplesModal";
import IconProgress from "../components/IconProgress";
import { SolveHeader } from "../components/SolveHeader";
import "katex/dist/katex.min.css";
import BrailleConversionWrapper from "../utils/BrailleConversionWrapper";
import { useUserContext } from "../../shared/contexts/UserContext";
import { cloneDeep } from "lodash";
import ReactTooltip from "react-tooltip";
import { TitleAndMessage } from "../components/standard-problems/CompletionNotifications";
import clsx from "clsx";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { useHighContrastMode } from "../utils/high-contrast-mode";
import { REACT_APP_STUDENT_LINK } from "../../utils";
import VideoModal from "./VideoPlayer";

export default function Solve(): JSX.Element {
  const {
    studentNextProblems,
    setStudentNextProblems,
    currentProblemData,
    showKeyboard,
    setTooltipText,
    assignmentsIsFetched,
    dmAssignmentData,
    dmSectionsData,
    setLoadingData,
  } = useContext(StudentSectionsContext);

  const userContext = useUserContext();
  const toasterContext = useDeltaToastContext();
  const lti = JSON.parse(
    localStorage.getItem("lti_assignment_payload") || "{}"
  ).isLtiResourceLaunch;
  const location = useLocation();

  const solveSkill: any = skillToSolve();

  const [showSolution, setShowSolution] = useState<boolean>(false);
  const [showExample, setShowExample] = useState<boolean>(false);
  const [showVideo, setShowVideo] = useState<boolean>(false);
  const [showNextProblem, setShowNextProblem] = useState<boolean>(false);
  const [alreadyWatched, setAlreadyWatched] = useState<number>(() => 0);
  const [justCompleted, setJustCompleted] = useState<boolean | undefined>(
    undefined
  );
  const isLocked = userContext.getIsLocked();
  const isSmallViewport = useMediaQuery("(max-width : 639px)");

  // if student ends up at an assignment url of an assignment that isn't in
  // their dmAssignmentData
  useEffect(() => {
    if (assignmentsIsFetched && Object.keys(solveSkill).length === 0) {
      navigate(
        `${REACT_APP_STUDENT_LINK}/${
          findNearestUpcoming(dmAssignmentData) ?? dmSectionsData[0]?._id
        }/upcoming`
      );
      setTimeout(() => {
        setLoadingData((prevState: any) => ({
          ...prevState,
          isShowing: true,
          error: true,
          title: "Notice",
          message:
            "The assignment you were attempting to access is no longer accessible to you.",
        }));
      }, 100);
    }
  }, [assignmentsIsFetched, solveSkill]);

  useEffect(() => {
    /** reset already watched and show video when moving to a new section of an assignment */
    if (showVideo) {
      setShowVideo(false);
    }
    setAlreadyWatched(0);
  }, [location]);
  const [currentProblem, setCurrentProblem] = useState<any>({
    correct: undefined,
    solution: undefined,
    complete: false, // is the user done with this problem, no matter right or wrong?
    nextProblem: undefined,
    maxProblemsOneDone: undefined,
  });

  const navigate = useNavigate();

  const scoreHeader = useRef<null | HTMLDivElement>(null);
  const nextProblemBtnRef = useRef<HTMLButtonElement>(null);
  const nextSectionBtnRef = useRef<HTMLButtonElement>(null);

  /* START | CONSOLE LOG TESTING */
  // useEffect(() => {
  //   console.log("solveSkill:", cloneDeep(solveSkill));
  // }, [solveSkill]);

  // useEffect(() => {
  //   console.log("currentProblemData:", currentProblemData);
  // }, [currentProblemData]);

  // useEffect(() => {
  //   console.log("currentProblem:", currentProblem);
  // }, [currentProblem]);

  // useEffect(() => {
  //   console.log("showSolution:", showSolution);
  // }, [showSolution]);

  // useEffect(() => {
  //   console.log("katexResizingData:", katexResizingData);
  // }, [katexResizingData]);

  /* END | CONSOLE LOG TESTING */

  /**
   * Custom hook to trigger "high-contrast mode", if the user opts in
   */
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const commonColorMap = useRef<Map<string, string>>(new Map());
  useHighContrastMode(
    user?.preferences?.highContrast || false,
    commonColorMap.current
  );

  const skillData = skillDataDisplay(
    solveSkill?.ta?.skillName || "",
    solveSkill
  );

  // Timed Assignment Info
  const timedAssignment = timedAssg(solveSkill);

  useEffect(() => {
    if (
      solveSkill &&
      solveSkill?.sa?.actuallyComplete !== null &&
      solveSkill?.sa?.actuallyComplete !== undefined
    ) {
      if (justCompleted === undefined) {
        setJustCompleted(solveSkill?.sa?.actuallyComplete === 100);
      } else if (!justCompleted && solveSkill?.sa?.actuallyComplete === 100) {
        const toastData = TitleAndMessage(
          solveSkill?.sa?._id,
          solveSkill?.ta?.is_test,
          timedAssignment.isTimed,
          timedAssignment.isOver,
          solveSkill?.ta?.allow_resubmit,
          userContext.getIsLocked(),
          solveSkill?.ta?.solutionsAvailable,
          lti
        );
        if (toastData) {
          toasterContext.addToast(toastData, true);
        }
        setJustCompleted(true);
      }
    }
  }, [solveSkill]);

  // TEMPORARY FOR TESTING
  // useEffect(() => {
  //   console.log(
  //     `timedAssignment(${solveSkill.ta.skillName}):`,
  //     timedAssignment
  //   );
  // }, []);

  // If student gets to a skill in a timed assignment problem that has
  // already ended, it will kick them back to the assignments list page
  useEffect(() => {
    if (!timedAssignment?.isTimed) return;

    // if there's no endTime, student hasn't started the assignment yet and
    // shouldn't be able to see the problems. Same if the assignment is over.
    if (
      !timedAssignment.endTime ||
      (timedAssignment.isOver &&
        (!skillData.isTest ||
          (skillData.isTest && skillData.obscureResults === true)))
    ) {
      if (solveSkill?.sa?._id) clearAssignmentProblems(solveSkill.sa._id);
      navigate(`${REACT_APP_STUDENT_LINK}/link/${solveSkill.ta._id}`);
    }
  }, [timedAssignment]);

  // when a timed assignment ends, clear out the saved problems so it will
  // refetch with the solutions if student revisits
  const clearAssignmentProblems = (saId: number) => {
    const tempNextProblems = cloneDeep(studentNextProblems);
    delete tempNextProblems[saId];
    setStudentNextProblems(tempNextProblems);
  };

  const uniqueId = currentProblemData?.uniqueID || undefined;

  const problemSkillData = solveSkill?.ta?.skills[solveSkill?.ta?.skillName];

  // give 'Next Problem' button focus when student answers correctly
  // TODO - Add this logic into SolveHeader!
  useEffect(() => {
    const interval = setTimeout(() => {
      if (currentProblem?.complete && skillData?.isCompleted) {
        nextSectionBtnRef?.current?.focus();
      } else if (currentProblem?.complete && currentProblem?.correct) {
        nextProblemBtnRef?.current?.focus();
      }
    }, 100);

    return () => clearTimeout(interval);
  }, [
    currentProblem.complete,
    currentProblem.correct,
    nextProblemBtnRef.current,
    nextSectionBtnRef.current,
  ]);

  useEffect(() => {
    ReactTooltip.rebuild();
  }, []);

  if (!Object.keys(solveSkill).length) return <></>;

  return (
    <BrailleConversionWrapper>
      <div
        className={clsx(
          showKeyboard && "mb-56 sm:mb-48",
          "flex-grow overflow-auto overflow-x-hidden pb-8 sm:p-6 lg:p-8"
        )}
      >
        <main id="main-content" role="main" className="flex flex-col">
          <SolveHeader
            title={problemSkillData?.name}
            uniqueId={uniqueId}
            timedAssignment={timedAssignment}
            skillData={skillData}
            solveSkill={solveSkill}
            isLocked={isLocked}
            setShowSolution={setShowSolution}
            showSolution={showSolution}
            setShowNextProblem={setShowNextProblem}
            setCurrentProblem={setCurrentProblem}
            currentProblem={currentProblem}
            ref={scoreHeader}
          />
          {!skillData.isVideo &&
            !skillData.isTest &&
            !skillData.isTimedModule &&
            solveSkill.ta?.currentSkill?.maxProblems !== 1 &&
            skillData.type !== "special" && (
              <div className="mb-4 flex flex-col gap-x-6 gap-y-2 border-dm-charcoal-100 bg-white px-3 py-2.5 max-sm:order-first max-sm:border-b sm:rounded-lg sm:border sm:px-6 sm:py-4">
                <div className="flex flex-row gap-x-6 text-sm max-sm:justify-between">
                  <div className="flex gap-x-1.5 font-semibold text-dm-charcoal-800">
                    {isSmallViewport && (
                      <IconProgress
                        skillData={skillData}
                        setTooltipText={setTooltipText}
                        // tooltipId="piechart-app"
                        // extraClasses="sm:hidden"
                      />
                    )}
                    Score: {skillData?.scoreOnSolve || skillData?.completion}
                  </div>
                  {solveSkill?.ta?.currentSkill?.penalty !== undefined ? (
                    <div>
                      <span className="font-semibold text-dm-charcoal-800">
                        Penalty:{" "}
                      </span>
                      <span className="text-dm-charcoal-500">
                        {getPenalty(solveSkill?.ta?.currentSkill?.penalty)}
                      </span>
                    </div>
                  ) : null}
                </div>
                <div className="w-full grow max-sm:hidden">
                  <ProgressBar
                    totalSegments={skillData?.progress?.total}
                    currentScore={skillData?.progress?.score}
                    currentRecord={skillData?.progress?.record}
                    showTickMarks={skillData?.progress?.showSegments}
                    percentCompleted={skillData?.percentCompleted}
                    solvePage={true}
                    isMuted={
                      solveSkill.ta?.currentSkill?.maxProblems -
                        solveSkill.ta?.currentSkill?.solvedProblems ===
                      0
                    }
                  />
                </div>
              </div>
            )}
          {solveSkill && showVideo && (
            <VideoModal
              key={solveSkill.ta.currentSkill.uid}
              solveSkill={solveSkill}
              showInModal={solveSkill.isVideo ? false : true}
              setShowVideo={setShowVideo}
            />
          )}

          {skillData.isTimedModule && (
            <TimedModule
              key={solveSkill.ta.skillName}
              solveSkill={solveSkill}
              setShowVideo={setShowVideo}
            />
          )}
          {skillData.isVideo && (
            <VideoModal
              key={solveSkill.ta.currentSkill.uid}
              solveSkill={solveSkill}
              setShowVideo={setShowVideo}
            />
          )}
          {skillData.isStandardSkill && (
            <StandardSkills
              currentProblem={currentProblem}
              setCurrentProblem={setCurrentProblem}
              showSolution={showSolution}
              setShowSolution={setShowSolution}
              showExample={showExample}
              setShowExample={setShowExample}
              setShowVideo={setShowVideo}
              showNextProblem={showNextProblem}
              setShowNextProblem={setShowNextProblem}
              showVideo={showVideo}
              timedAssignment={timedAssignment}
            />
          )}
        </main>
        {/* <DevOptions /> */}
        <ExamplesModal
          taId={solveSkill.ta._id}
          skillName={currentProblemData?.skillcode}
          isShowing={showExample}
          setIsShowing={setShowExample}
          typesSelected={solveSkill?.ta?.currentSkill?.typesSelected}
        />
      </div>
    </BrailleConversionWrapper>
  );
}
