import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { deltamathAPI } from "../../manager/utils";
import { withJsonHeader } from "../../shared/axiosUtils";

/** Mutation that triggers resending an child's email invite */
export function useResendInviteMutation() {
  return useMutation({
    mutationFn: ({ learnerId }: { learnerId: string }) => {
      return axios.post<{ status: "success" | "error" }>(
        `${deltamathAPI()}/learner/parent/resend-invite`,
        JSON.stringify({ learnerId }),
        withJsonHeader()
      );
    },
  });
}
