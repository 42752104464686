/* eslint-disable no-useless-escape */
/* eslint-disable no-var */
/* eslint-disable prefer-const */
/* eslint-disable no-empty */
import { useEffect, useRef, useState } from "react";
import { IconKeyboard } from "@tabler/icons-react";
import { ChevronUpIcon } from "@heroicons/react/outline";
import clsx from "clsx";
import { Tooltip } from "../../../shared/Tooltip";
import {
  getRenderMathSettings,
  useFieldFocus,
  generateMQ,
  eventCleanUp,
} from "../../utils";
import renderMathInElement from "../../utils/auto-render";
import Loading from "../Loading";
import DMKeyboard, { AnswerData } from "./DMKeyboard";
import Button from "../generic/button";
import { deltamathLogo } from "../../../utils";
import { EventMap } from "../../../shared/types";
import { useLocation, useSearchParams } from "react-router-dom";
import { useAnalytics } from "../../../shared/useAnalytics";
const MQ = (window as any).MQ;
const DeltaGraph = (window as any).DeltaGraph;
const dmKAS = (window as any).dmKAS;
const number = (window as any).number;
const $ = (window as any).$;
const alertDialog = (window as any).alertDialog;
const typeset = (window as any).typeset;

const graphBounds = {
  xmin: -10,
  xmax: 10,
  ymin: -10,
  ymax: 10,
};

export default function GraphingCalculator(): JSX.Element {
  const { pageView } = useAnalytics();
  const location = useLocation();
  /* SET and DEFINE GLOBALS */
  window.renderMathInElement = renderMathInElement;

  /* initial state for loading component (which will render the alertDialog functions) */
  const [loadingData, setLoadingData] = useState<any>({
    error: false,
    isShowing: false,
    title: "Error",
    message: "You encountered an error",
  });

  /* state to store the last focused input for the keyboard */
  const [globalFocusedInput, { handleGlobalFocus }] = useFieldFocus("");
  const [globalInputsMap, setGlobalInputsMap] = useState<Map<string, any>>(
    new Map()
  );
  const [showKeyboard, setShowKeyboard] = useState(false);
  // const [previousAnswer, setPreviousAnswer] = useState(0); // TODO: refactor DMKeyboard to make this prop optional (currently unused for the graphing calculator, but required for DMKeyboard)
  const [answerData, setAnswerData] = useState<AnswerData>({
    latex: "",
    prevLatex: "",
    currentAnswer: "",
    currentAnswerFull: 0,
    prevAnswer: "",
    prevAnswerFull: 0,
  });
  const calcRef = useRef<HTMLElement>(null);

  const [searchParams] = useSearchParams();

  /* stores the focus event handlers added to MathQuill nodes */
  const focusEvents = useRef<EventMap>(new Map());

  // function to generate the MathQuill nodes
  const createMQ = () => {
    const focusFunc = (el: Element, mq: any, isTouchDevice: boolean) => {
      setGlobalInputsMap(globalInputsMap.set(el.id, mq));
      handleGlobalFocus(el.id);
      if (isTouchDevice) {
        setShowKeyboard(true);
      }
    };

    const handleFocus = (el: Element, mq: any, index: number) => {
      // set global focus to be the first MQ element
      if (index === 0) {
        setGlobalInputsMap(globalInputsMap.set(el.id, mq));
        handleGlobalFocus(el.id);
      }
    };

    focusEvents.current = generateMQ(focusFunc, undefined, handleFocus);
  };

  useEffect(() => {
    pageView(
      {},
      {
        plugins: {
          google_tag_manager: true,
        },
      }
    );
  }, [location]);

  useEffect(() => {
    // generate MQ fields (adds eventListeners, which need to be removed in clean up func)
    createMQ();

    // execute calculator script
    calculatorScripts();

    // add classes to body for styling
    document.body.classList.add("min-h-screen", "bg-dm-background-blue-100");

    // render math
    renderMathInElement(
      document.getElementById("graphingCalc"),
      getRenderMathSettings()
    );

    // clean up func to remove event listeners added in createMQ
    return () => {
      /* Remove all current event listeners from MathQuill boxes, if necessary */
      eventCleanUp(focusEvents.current);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const func = searchParams.get("func");
    const funcRef = calcRef?.current?.querySelector("#func1");
    if (
      typeof func === "string" &&
      funcRef?.classList.contains("mathquill-editable") &&
      MQ &&
      (calcRef?.current?.querySelectorAll(".calc-window-setting")?.length ||
        0) >= 4
    ) {
      const bounds = JSON.parse(searchParams.get("bounds") || "{}");
      const funcMQ = MQ(funcRef);
      funcMQ.latex(func);
      for (const key in bounds) {
        const bound = bounds[key];

        if (!isNaN(parseInt(bound, 10))) {
          $(`#${key}`).val(bound).trigger("change");
        }
      }
    }
  }, [searchParams, calcRef, MQ]);

  return (
    <>
      <main className="xl-pb-12 px-4 pb-4 pt-4 md:px-8 md:pb-8 xl:px-12">
        <div>
          <img
            className="mb-4 block h-6 w-auto md:mb-8 xl:mb-12"
            src={deltamathLogo}
            alt="DeltaMath Logo"
          />
        </div>
        <section
          id="graphingCalc"
          className="display-problem problem-page grid min-h-[700px] grid-cols-1 grid-rows-7 rounded-lg border border-dm-charcoal-100 bg-white p-8 lg:grid-cols-5 lg:grid-rows-5"
          ref={calcRef}
        >
          <div
            id="func-container"
            className="order-1 row-span-1 flex flex-wrap justify-center gap-4 px-4 lg:col-span-2 lg:row-span-1 lg:border-r lg:border-dm-charcoal-100 lg:pb-6"
          >
            <div className="grow">
              <label htmlFor="func1" className="text-dm-brand-blue-500">
                Function 1
              </label>
              <div className="">
                <span id="func1_prefix"></span>
                <span
                  id="func1"
                  className="mathquill-editable ml-1 rounded-sm border text-left"
                  style={{ minWidth: 150, width: "80%", marginBottom: 0 }}
                  aria-label="Enter function 1 here"
                ></span>
              </div>
            </div>
            <div className="grow">
              <label htmlFor="func2" className="text-dm-error-500">
                Function 2
              </label>
              <div>
                <span id="func2_prefix"></span>
                <span
                  id="func2"
                  className="mathquill-editable ml-1 rounded-sm border text-left"
                  style={{ minWidth: 150, width: "80%", marginBottom: 0 }}
                  aria-label="Enter function 2 here"
                ></span>
              </div>
            </div>
          </div>
          <div
            id="table-container"
            className="order-3 row-span-3 my-6 flex flex-col px-4 lg:col-span-2 lg:col-start-1 lg:row-span-4 lg:row-start-2 lg:my-0 lg:border-r lg:border-dm-charcoal-100 lg:px-8 lg:pt-4"
          >
            <div className="flex flex-row flex-wrap items-center justify-center gap-4">
              <div>
                <label>
                  Start:{" "}
                  <input
                    id="table-start"
                    type="number"
                    className="rounded-sm"
                  />
                </label>
              </div>
              <div>
                <label>
                  Δ Table:{" "}
                  <input
                    id="table-delta"
                    type="number"
                    className="rounded-sm"
                  />
                </label>
              </div>
              <Button id="table-update" type="outline" size="small">
                Update
              </Button>
            </div>
            <div
              id="table-wrapper"
              className="w-full sm:w-1/2 lg:w-3/4"
              style={{
                maxHeight: 500,
                overflow: "auto",
                minWidth: 200,
                margin: "15px auto",
                border: "2px #aaa double",
                fontSize: 16,
              }}
            >
              <table
                className="table-bordered table text-center"
                id="table-data"
                style={{ margin: "0px auto" }}
              ></table>
            </div>
          </div>
          <div
            id="graph-container"
            className="order-2 row-span-3 my-6 flex flex-col items-center justify-center px-4 lg:col-span-3 lg:col-start-3 lg:row-span-5 lg:row-start-1 lg:mt-0 lg:px-8"
          >
            <div className="mb-2 flex flex-col items-center justify-center gap-1">
              <label htmlFor="ymax">ymax</label>
              <div>
                <input
                  id="ymax"
                  className="calc-window-setting"
                  style={{ width: "48px" }}
                  aria-label="y-max"
                />
              </div>
            </div>
            <div className="grid grid-cols-12 items-center justify-center">
              <div className="col-span-1 flex flex-col items-center justify-center gap-1">
                <label htmlFor="xmin">xmin</label>
                <div>
                  <input
                    id="xmin"
                    className="calc-window-setting"
                    style={{ width: "48px" }}
                    aria-label="x-min"
                  />
                </div>
              </div>
              <div className="col-span-10">
                <div className="col-sm-12 text-center min-[1240px]:min-w-[470px]">
                  <svg id="canv-calc"></svg>
                </div>
              </div>
              <div className="flex flex-col items-center justify-center gap-1">
                <label htmlFor="xmax">xmax</label>
                <div>
                  <input
                    id="xmax"
                    className="calc-window-setting"
                    style={{ width: "48px" }}
                    aria-label="x-max"
                  />
                </div>
              </div>
            </div>
            <div className="col-span-1 flex flex-col items-center justify-center gap-1">
              <label htmlFor="ymin">ymin</label>
              <div>
                <input
                  id="ymin"
                  className="calc-window-setting"
                  style={{ width: "48px" }}
                  aria-label="y-min"
                />
              </div>
            </div>
          </div>
        </section>
        <Loading {...loadingData} setLoadingData={setLoadingData} />
        {showKeyboard && (
          <DMKeyboard
            close={() => setShowKeyboard(false)}
            focusedInput={globalFocusedInput}
            input={globalInputsMap}
            showCalculator={false}
            // previousAnswer={previousAnswer}
            // setPreviousAnswer={setPreviousAnswer}
            answerData={answerData}
            setAnswerData={setAnswerData}
            showKeyboard={showKeyboard}
            handleGlobalFocus={handleGlobalFocus}
            withSidebar={false}
            disableScroll={true}
            centerKeyboard={true}
          />
        )}
      </main>
      <div className="fixed bottom-4 right-4 bg-white">
        <Tooltip message={"Virtual keyboard"}>
          <Button
            type="outline"
            size="small"
            aria-label="Virtual keyboard"
            onClick={() => {
              setShowKeyboard(!showKeyboard);
              globalInputsMap.get(globalFocusedInput)?.focus();
            }}
          >
            <span className="flex items-center gap-4">
              <IconKeyboard className="h-7" aria-hidden="true" />
              <ChevronUpIcon
                className={clsx("h-4", showKeyboard && "rotate-180 transform")}
                aria-hidden="true"
              />
            </span>
          </Button>
        </Tooltip>
      </div>
    </>
  );
}

/* Script on load */
const calculatorScripts = function () {
  let keyPoints: any = [{}, {}]; // x: y for each function, keep each function separate to display key points on the table (helps accessibility). 3rd element is intersections

  const findScale = function (num: any, which: any) {
    const log = Math.log(num) / Math.log(10);
    const logDec = log - Math.floor(log);
    const logFloor = Math.floor(log);
    let scl;
    if (Math.abs(log - Math.round(log)) < 0.0001) {
      scl = Math.round(Math.pow(10, log) * 1000000) / 1000000;
    } else if (logDec <= Math.log(2) / Math.log(10) + 0.001) {
      scl = Math.round(2 * Math.pow(10, logFloor) * 1000000) / 1000000;
    } else if (logDec <= Math.log(5) / Math.log(10) + 0.001) {
      scl = Math.round(5 * Math.pow(10, logFloor) * 1000000) / 1000000;
    } else {
      scl = Math.round(Math.pow(10, logFloor + 1) * 1000000) / 1000000;
    }

    if (Math.abs(scl) < 1 && which === "x") {
      // half as many dots if they are decimals so they fit on graph
      return Math.round(scl * 2 * 1000000) / 1000000;
    } else {
      return scl;
    }
  };

  const func1ref = MQ($("#func1")[0]);
  const func2ref = MQ($("#func2")[0]);

  // TODO: don't allow decimals in the window settings with more precision than 5 decimal points
  // TODO: make error if they have "y=" or "f(x)=" anywhere

  // func1ref.latex('3\sin.5x');
  // func2ref.latex('\sqrt{x-3}+2');
  // func2ref.latex('-x^{2}+8');
  let xmin = -10,
    xmax = 10,
    xscl = 1,
    ymin = -10,
    ymax = 10,
    yscl = 1,
    func1 = "",
    func2 = "";
  $("#xmin").val(xmin);
  $("#ymin").val(ymin);
  $("#xmax").val(xmax);
  $("#ymax").val(ymax);
  function refreshGraph() {
    xscl = findScale((xmax - xmin) / 20, "x");
    yscl = findScale((ymax - ymin) / 20, "y");
    var graph = new DeltaGraph("canv-calc", {
      xmin,
      xmax,
      ymin,
      ymax,
      xscl,
      yscl,
      wideContainer: true,
      align: "center",
    });

    const func1 = func1ref.latex();
    const func2 = func2ref.latex();

    keyPoints = graph.getKeyPoints(func1, func2);

    let func1valid = false;
    let func2valid = false;
    if (func1) {
      try {
        graph.function(func1, { stroke: "#2F52E9", id: "function1" }); // color choice?
        func1valid = true;
      } catch (e) {}
    }
    if (func2) {
      try {
        graph.function(func2, { stroke: "#D21527", id: "function2" });
        func2valid = true;
      } catch (e) {}
    }

    const additionalKeyPoints: any = {};
    plotKeyPoints(graph, additionalKeyPoints);
    $("#table-start").val(Math.round(xmin / xscl) * xscl);
    $("#table-delta").val(xscl);
    $("#table-update")
      .off("click")
      .click(function () {
        const oldMode = dmKAS.getMode();
        dmKAS.setMode("radian");
        refreshTable(
          func1valid ? dmKAS.compile(func1) : undefined,
          func2valid ? dmKAS.compile(func2) : undefined
        );
        dmKAS.setMode(oldMode);
      })
      .click();
    graph.trace({
      func1,
      func1Id: "function1",
      func2,
      func2Id: "function2",
      additionalKeyPoints,
    });
  }

  func1ref.config({ handlers: { edit: refreshGraph } });
  func2ref.config({ handlers: { edit: refreshGraph } });

  const changeBounds = () => {
    if (isNaN(parseFloat($("#xmin").val()))) {
      $("#xmin").val(xmin); // set to old, acceptable value
      // return alertDialog("xmin must be a valid number");
    }
    xmin = parseFloat($("#xmin").val());

    if (isNaN(parseFloat($("#xmax").val()))) {
      $("#xmax").val(xmax); // set to old, acceptable value
      // return alertDialog("xmax must be a valid number");
    }
    xmax = parseFloat($("#xmax").val());

    if (isNaN(parseFloat($("#ymin").val()))) {
      $("#ymin").val(ymin); // set to old, acceptable value
      // return alertDialog("ymin must be a valid number");
    }
    ymin = parseFloat($("#ymin").val());

    if (isNaN(parseFloat($("#ymax").val()))) {
      $("#ymax").val(ymax); // set to old, acceptable value
      // return alertDialog("ymax must be a valid number");
    }
    ymax = parseFloat($("#ymax").val());

    if (xmax <= xmin) {
      // return alertDialog("xmax must be greater than xmin");
    }
    if (ymax <= ymin) {
      // return alertDialog("ymax must be greater than ymin");
    }

    refreshGraph();
  };

  $(".calc-window-setting")
    .off("change")
    .on("change", changeBounds)
    .eq(0)
    .change();

  function truncate(val: any) {
    if (Math.abs(Math.round(2 * val) - 2 * val) >= 1e-8) {
      // fixes rounding error
      return Math.round(Number(val) * 1e7) / 1e7;
    }
    return (
      (val > 0 ? Math.floor : Math.ceil)(number(val * 1000000).val) / 1000000
    );
  }

  function plotKeyPoints(graph: any, additionalKeyPoints: any) {
    graph.circle(xmin - xscl * 10, 0, "1px", {
      opacity: 0,
      id: "prepend-point-before-labels",
    });
    const existingPoints = []; // make circOuter a smaller radius if it is "close" to a previous one so it's not too hard to click the overlapping ones
    for (let which = 0; which <= 1; which++) {
      for (const x in keyPoints[which]) {
        const y = keyPoints[which][x].y;
        if (
          y < ymin ||
          y > ymax ||
          (which === 1 && keyPoints[which][x].intersection)
        )
          continue; // don't plot intersection twice
        if (keyPoints[which][x].intersection) {
          additionalKeyPoints[x] = {
            y: keyPoints[which][x].y,
            descrip: "key point, intersection",
          };
        }
        const circ = graph.circle(x, y, "2.5px", {
          fill: "black",
          strokeWidth: 1.5,
          prepend: "#prepend-point-before-labels",
        });
        const theseSvgCoordinates = [graph.getx(x), graph.gety(y)];
        const closePoints = existingPoints.filter(
          (svgCoords) => graph.distance(theseSvgCoordinates, svgCoords) < 10
        );
        const circOuter = graph
          .circle(x, y, closePoints.length ? "4px" : "10px", {
            fill: "transparent",
            strokeWidth: 0,
          })
          .style("cursor", "pointer"); //.attr('tabindex', 0);
        theseSvgCoordinates.push(circOuter);
        closePoints.forEach((svgCoords) => svgCoords[2].attr("r", 4)); // set all previous radii of close points to 4 so there is no unintuitive highlighting of points
        existingPoints.push(theseSvgCoordinates);
        // make it in a reasonable location
        const pointString = "(" + truncate(x) + ", " + truncate(y) + ")";
        const relativePositionX = (Number(x) - xmin) / (xmax - xmin);
        const thresh = Math.max(
          0.05,
          Math.min(0.25, (0.25 * pointString.length) / 30)
        );
        const align =
          relativePositionX > 1 - thresh
            ? "rb"
            : relativePositionX < thresh
            ? "lb"
            : "mb"; //(x<0?'r':'l')+'b';
        const circLabel = graph.text(x, y, pointString, {
          padding: 10,
          fontSize: "18px",
          align,
          textRectangle: { fillOpacity: 0.85, padding: 2, stroke: "gray" },
        });
        circLabel.attr("transform", "translate(-10000000000 0)");
        const focusInFunc = function () {
          // circ.attr('stroke-width', 2.5);
          circ.attr("fill", "yellow").attr("r", 4);
          circLabel.attr("transform", "");
        };

        const focusOutFunc = function () {
          // circ.attr('stroke-width', 1.5);
          circ.attr("fill", "black").attr("r", 2.5);
          circLabel.attr("transform", "translate(-10000000000 0)");
        };

        circOuter.on("mouseover", focusInFunc).on("mouseout", focusOutFunc);
        // accessibility? circOuter.on('focusin', focusInFunc).on('focusout', focusOutFunc);
      }
    }
  }

  function refreshTable(f1: any, f2: any) {
    const start = parseFloat($("#table-start").val());
    const dx = parseFloat($("#table-delta").val());
    // if (isNaN(dx) || dx <= 0)
    //   return alertDialog("Δ Table must be a positive number.");
    const maxTableX =
      xmax <= start
        ? number(start + 100 * dx).val
        : xmax > start + 1000 * dx
        ? number(start + 1000 * dx).val
        : xmax < start + 20 * dx
        ? number(start + 20 * dx).val
        : xmax;
    let rows = "<tr><th>\\(x\\)</th><th>\\(y_1\\)</th><th>\\(y_2\\)</th></tr>";
    for (let x = start; x <= maxTableX; x += dx) {
      x = number(x).val;
      let y1 = f1 ? number(f1({ x })).val : "";
      let y2 = f2 ? number(f2({ x })).val : "";
      x = truncate(x);
      if (y1 !== "") y1 = isNaN(y1) ? "" : truncate(y1);
      if (y2 !== "") y2 = isNaN(y2) ? "" : truncate(y2);

      rows += `<tr><td>\\(${x}\\)</td><td>\\(${y1}\\)</td><td>\\(${y2}\\)</td></tr>`;
    }
    $("#table-data").html(rows);
    typeset("table-data");
    $("#func1_prefix").html(`\\(y=\\)`);
    typeset("func1_prefix");
    $("#func2_prefix").html(`\\(y=\\)`);
    typeset("func2_prefix");
  }
};
