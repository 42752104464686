import {
  useState,
  Fragment,
  useMemo,
  FormEventHandler,
  SyntheticEvent,
} from "react";
import axios from "axios";
import { Transition, Listbox } from "@headlessui/react";
import { useMutation } from "@tanstack/react-query";
import { Link, useNavigate } from "react-router-dom";
import { Column } from "react-table";
import clsx from "clsx";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { useUserContext } from "../../shared/contexts/UserContext";
import DeltaMathTable from "../../shared/DeltaMathTable";
import {
  SelectColumnFilter,
  NoFilter,
  TextFilter,
} from "../../shared/table/filters";
import { REACT_APP_MANAGER_LINK } from "../../utils";
import { deltamathAPI } from "../utils";
import Chevron from "../../learner/components/Chevron";
import { useDocumentTitle } from "usehooks-ts";

const QUOTE_OPTIONS = [
  {
    value: "quoteNumber",
    label: "Quote Number",
    placeholder: "Quote #",
  },
  {
    value: "name",
    label: "School / District Name",
    placeholder: "Name",
  },
  {
    value: "emailDomain",
    label: "Contact Email",
    placeholder: "Email",
  },
  { value: "id", label: "NCES ID", placeholder: "7654321" },
];

const TRANSITION_PARAMS = {
  enter: "transition ease-out duration-200",
  enterFrom: "opacity-0 translate-y-1",
  enterTo: "opacity-100 translate-y-0",
  leave: "transition ease-in duration-150",
  leaveFrom: "opacity-100 translate-y-0",
  leaveTo: "opacity-0 translate-y-1",
};

function QuoteFinder() {
  useDocumentTitle("Quote Finder");
  const [activeQuoteOption, setActiveQuoteOption] = useState(QUOTE_OPTIONS[0]);
  const [quoteSearch, setQuoteSearch] = useState("");

  const navigate = useNavigate();
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const token = userContext.getJWT();

  const { mutate: quoteMutate, data: quoteData } = useMutation({
    mutationFn: (body: {
      emailDomain?: string;
      id?: string;
      name?: string;
    }) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/invoices`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    onError: (error: any) => {
      toastContext.addToast({
        title: "Error",
        message:
          error?.response?.data?.message ||
          error?.message ||
          "An error occurred",
        status: "Error",
      });
    },
  });

  const columns: Column[] = useMemo(
    () => [
      {
        Header: "Quote",
        minWidth: 80,
        accessor: "quoteNumber",
        Filter: NoFilter,
        Cell: ({ cell }) => (
          <div className="w-100 text-center">
            {cell.row.values.quoteNumber ? (
              <Link
                className="text-indigo-500 hover:underline"
                to={`${REACT_APP_MANAGER_LINK}/quote-lookup/${cell.row.values.quoteNumber}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {cell.row.values.quoteNumber}
              </Link>
            ) : (
              <>-</>
            )}
          </div>
        ),
      },
      {
        Header: "Expiration",
        accessor: "expiration",
        Filter: NoFilter,
        Cell: ({ cell }) => {
          if (cell.row.values.expiration) {
            const date = new Date(cell.row.values.expiration * 1000);
            return <div>{date.toLocaleDateString()}</div>;
          }
          return <></>;
        },
      },
      {
        Header: "School/District Name",
        accessor: "name",
        Filter: TextFilter,
      },
      {
        Header: "License Tier",
        minWidth: 200,
        accessor: "licenseTier",
        Filter: SelectColumnFilter,
        filter: "equals",
      },
      {
        Header: "Price",
        accessor: "amount",
        Filter: TextFilter,
        Cell: ({ cell }) =>
          cell.row.values.amount ? <>${cell.row.values.amount}</> : <>-</>,
      },
      {
        Header: "NCES ID",
        accessor: "id",
        Filter: TextFilter,
      },
      {
        Header: "Contact Email",
        accessor: "contactEmail",
        Filter: TextFilter,
      },
      {
        Header: "Admin Email",
        accessor: "adminEmail",
        Filter: TextFilter,
      },
      {
        Header: "License Type",
        accessor: "type",
        Filter: SelectColumnFilter,
        minWidth: 140,
        filter: "equals",
        Cell: ({ cell }) => {
          const licenseType = cell.row.values.type;
          let displayValue;
          if (licenseType === "school") {
            displayValue = "School";
          } else if (licenseType === "school_custom") {
            displayValue = "School (No NCES ID)";
          } else displayValue = licenseType;
          return <div className="w-full">{displayValue}</div>;
        },
      },
    ],
    []
  );

  const handleOnClick: FormEventHandler = (e: SyntheticEvent) => {
    e.preventDefault();
    if (activeQuoteOption.value === "quoteNumber") {
      navigate(`${REACT_APP_MANAGER_LINK}/quote-lookup/${quoteSearch}`);
    } else {
      const body = {
        [activeQuoteOption.value]: quoteSearch,
      };
      quoteMutate(body);
    }
  };

  return (
    <div className="px-3">
      <h1 className="py-4 text-2xl font-semibold text-gray-900">
        Quote Finder
      </h1>
      <form
        onSubmit={handleOnClick}
        className="flex content-center items-center pb-6"
      >
        <Listbox
          key="quote-options-menu"
          defaultValue={QUOTE_OPTIONS[0]}
          value={activeQuoteOption}
          onChange={setActiveQuoteOption}
          by="value"
        >
          {({ open }) => (
            <div className="relative w-full shrink-0 sm:w-48">
              <Listbox.Button className="relative flex w-full items-center gap-x-0.5 border border-gray-300 bg-dm-gray-100 py-2 pl-3 text-left text-sm font-bold leading-none text-dm-brand-blue-800 sm:rounded-l-md">
                <span className="grow">{activeQuoteOption.label}</span>
                <Chevron open={open} />
              </Listbox.Button>

              <Transition as={Fragment} {...TRANSITION_PARAMS}>
                <Listbox.Options className="absolute z-20 -mt-1 flex max-h-60 w-full flex-col gap-y-1 overflow-auto rounded-b-md border border-t-0 border-gray-300 bg-dm-gray-100 py-2 text-sm">
                  {QUOTE_OPTIONS.map((item) => (
                    <Listbox.Option
                      key={`item-${item.value}`}
                      value={item}
                      // as={Fragment}
                      className={({ active, selected }) =>
                        clsx(
                          active || selected
                            ? "bg-indigo-500 text-white"
                            : "text-gray-900",
                          "relative cursor-default select-none py-1 pl-3 pr-9 text-sm"
                        )
                      }
                    >
                      {item.label}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          )}
        </Listbox>
        <input
          type="text"
          id="quote-search-field"
          name="quote-search-field"
          placeholder={activeQuoteOption.placeholder}
          value={quoteSearch}
          onChange={(e) => setQuoteSearch(e.target.value)}
          className="z-10 -ml-px block w-80 border-gray-300 py-2 text-sm placeholder-gray-300 focus:outline-none focus:ring-1 max-sm:mt-2 max-sm:w-full"
        />
        <button
          type="submit"
          className="relative items-center space-x-2 border border-l-0 border-gray-300 bg-gray-50 px-4 py-2 text-center text-sm font-medium text-gray-700 hover:bg-gray-100 focus:border-indigo-500 focus:outline-none focus:ring-1 focus:ring-indigo-500 disabled:pointer-events-none disabled:opacity-50 max-sm:mt-2 max-sm:w-full sm:rounded-r-md"
          disabled={!quoteSearch}
        >
          Search
        </button>
      </form>
      {quoteData?.data.invoices && (
        <DeltaMathTable
          columns={columns}
          data={quoteData.data.invoices}
          options={{
            stickyHeader: true,
            initialState: {
              sortBy: [
                {
                  id: "quoteNumber",
                  desc: true,
                },
              ],
            },
          }}
        ></DeltaMathTable>
      )}
      {quoteData?.data.success === false && <>{quoteData.data.message}</>}
    </div>
  );
}

export default QuoteFinder;
