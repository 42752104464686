import { useNavigate } from "react-router-dom";
import { REACT_APP_MANAGER_LINK } from "../../../utils";
import { IFoundSiteLicenseForInvoice } from "../../utils/quoteUtils";
import InvoicePageCard from "./InvoicePageCard";
import Button from "../../../student/components/generic/button";
import { useActivationContext } from "./LicenseActivationContext";

const SiteLicenseCard: React.FC<{
  licenseDetails: IFoundSiteLicenseForInvoice;
  siteType: "school" | "district";
}> = ({ licenseDetails, siteType }) => {
  const activationContext = useActivationContext();

  const {
    invoice,
    quoteNumber,
    expiration,
    classlinkTenantID,
    cleverDistrictID,
    integrations,
    tier,
    students,
    teachers,
    siteID,
    primaryAdmin,
    type,
  } = licenseDetails;
  const siteLicenceDetails = [
    { title: "Invoice", value: invoice || "Not found" },
    {
      title: "Quote Number",
      value: quoteNumber || "Not found",
      href: quoteNumber
        ? `${REACT_APP_MANAGER_LINK}/quote-lookup/${quoteNumber}`
        : "",
    },
    {
      title: siteType === "district" ? "District ID" : "School ID",
      value: siteID || "Not found",
    },
    {
      title: "Expiration",
      value: new Date(expiration).toLocaleDateString() || "Not found",
    },
    ...(tier === "integral"
      ? [{ title: "Integrations", value: integrations || "Not found" }]
      : []),
    ...(classlinkTenantID && tier === "integral"
      ? [{ title: "Classlink Tenant ID", value: classlinkTenantID }]
      : []),
    ...(cleverDistrictID && tier === "integral"
      ? [{ title: "Clever District ID", value: cleverDistrictID }]
      : []),
    { title: "License Tier", value: tier?.toUpperCase() || "Not found" },
    ...(siteType === "district"
      ? [{ title: "License Type", value: type || "Not found" }]
      : []),
    { title: "Number of Students", value: students || "Not found" },
    { title: "Number of Teachers", value: teachers || "Not found" },
    {
      title: "Primary Admin",
      value:
        primaryAdmin !== "Not Found"
          ? `${primaryAdmin.first} ${primaryAdmin.last} - ${primaryAdmin.email}`
          : primaryAdmin,
    },
  ];

  const handleClick = () => {
    if (
      primaryAdmin === "Not Found" ||
      !primaryAdmin.first ||
      !primaryAdmin.last ||
      !primaryAdmin.email
    ) {
      return;
    }
    activationContext.simpleQuoteUpdate({
      adminEmail: primaryAdmin.email,
      adminFirst: primaryAdmin.first,
      adminLast: primaryAdmin.last,
    });
  };

  return (
    <InvoicePageCard
      title={
        siteType === "school"
          ? "Current School License"
          : "Current District License"
      }
      fullWidth={false}
    >
      <ul className="flex flex-col gap-2">
        {siteLicenceDetails.map((detail, index) => (
          <li key={"license" + index} className="flex items-center gap-2">
            <span>{detail.title}:</span>
            {!detail.href && <span className="font-bold">{detail.value}</span>}
            {detail.href && (
              <a
                href={detail.href}
                target="_blank"
                rel="noreferrer"
                className="font-bold"
              >
                {detail.value}
              </a>
            )}
            {activationContext.invoiceDetails?.status !== "Activated" &&
              detail.title === "Primary Admin" &&
              primaryAdmin !== "Not Found" &&
              primaryAdmin.first &&
              primaryAdmin.last &&
              primaryAdmin.email && (
                <Button onClick={handleClick} className="!px-2 !py-1 text-xs">
                  Use for Quote
                </Button>
              )}
          </li>
        ))}
      </ul>
    </InvoicePageCard>
  );
};

export default SiteLicenseCard;
