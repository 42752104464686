import { useEffect, useState } from "react";
import { useDMQuery } from "../../../utils";
import { CourseStandard } from "DeltaMathAPI/routers/standards.router/api";
import Select, { SingleValue } from "react-select";
import { SkillCodes } from "./SkillCodes";
import { FocusContextProvider } from "../../../learner/contexts/FocusContext";
import ExampleModal from "../../../learner/components/Modals/ExampleModal";
import StandardDisplay from "./StandardDisplay";

type Props = {
  skillAdd?: (name: string, sc: string) => void;
  skillCodes: SkillCodes;
};

const StandardSelect = (props: Props) => {
  const [state, setState] = useState<{ label: string; value: string }>({
    label: "Common Core",
    value: "CCK12",
  });
  const [selectedGrade, setSelectedGrade] = useState<
    { label: string; value: string } | undefined
  >();
  const [selectedSkillCode, setSelectedSkillCode] = useState<
    string | undefined
  >(undefined);

  const [showExample, setShowExample] = useState<boolean>(false);

  const skillClick = (sk?: string) => {
    if (sk) {
      setSelectedSkillCode(sk);
      setShowExample(true);
    }
  };

  const { data: codesData } = useDMQuery<
    {
      code: string;
      fullName: string;
      country: string;
    }[]
  >({
    path: `/standards/ref/codes`,
    customerServiceHeader: true,
    queryOptions: {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  const { data: gradeData, refetch: gradeRefetch } = useDMQuery<
    {
      _id: string;
      name: string;
    }[]
  >({
    path: `/standards/manage/${state.value}/live`,
    queryOptions: {
      enabled: false,
    },
  });

  useEffect(() => {
    if (state) {
      gradeRefetch();
    }
  }, [state]);

  const { data: standardData, refetch: standardRefetch } =
    useDMQuery<CourseStandard>({
      path: `/standards/manage/${selectedGrade?.value}`,
      queryOptions: {
        enabled: false,
      },
    });

  useEffect(() => {
    if (selectedGrade) {
      standardRefetch();
    }
  }, [selectedGrade]);

  useEffect(() => {
    if (gradeData && !selectedGrade && gradeData.length > 0) {
      setSelectedGrade({
        label: gradeData[0].name,
        value: gradeData[0]._id,
      });
    }
  }, [gradeData]);

  if (!codesData || !gradeData) {
    return <></>;
  }

  return (
    <div>
      <div className="mb-10 flex gap-6">
        <Select
          name="state"
          options={codesData.map((c) => {
            return {
              label: c.fullName,
              value: c.code,
            };
          })}
          onChange={(
            newValue: SingleValue<{ value: string; label: string }>
          ) => {
            if (newValue) {
              setState(newValue);
              // If selecting state clear grade as it may not match
              setSelectedGrade(undefined);
            }
          }}
          value={state}
          className="w-96"
        />
        <Select
          name="grade"
          options={gradeData.map((g) => {
            return {
              label: g.name,
              value: g._id,
            };
          })}
          onChange={(
            newValue: SingleValue<{ value: string; label: string }>
          ) => {
            if (newValue) {
              setSelectedGrade(newValue);
            }
          }}
          value={selectedGrade}
          className="w-96"
        />
      </div>

      {standardData && (
        <>
          {Object.entries(standardData.data).map(([k, v]) => (
            <StandardDisplay
              key={k}
              standard={v}
              depth={1}
              path="data."
              skillcodes={props.skillCodes}
              skillAdd={props.skillAdd}
              exampleClick={skillClick}
            />
          ))}
        </>
      )}
      <FocusContextProvider>
        <ExampleModal
          skillCode={selectedSkillCode ?? ""}
          visible={showExample}
          onClose={() => setShowExample(false)}
          toggleQuestion
          noAnalytics
        />
      </FocusContextProvider>
    </div>
  );
};

export default StandardSelect;
