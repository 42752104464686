import React, { useState } from "react";
import clsx from "clsx";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import { DMAlertModal } from "../../../shared/DMModal/DMAlertModal";

interface WarningsProps {
  children: React.ReactNode;
  className?: string;
}

const Warnings: React.FC<WarningsProps> = ({ children, className }) => {
  const [showWarningModal, setShowWarningModal] = useState(false);

  return (children && !Array.isArray(children)) ||
    (Array.isArray(children) &&
      children.length &&
      children.some((e) => !!e)) ? (
    <div className={clsx("flex items-center")}>
      {/* Display children text on screens sm and up */}
      <span className={clsx("hidden md:flex", className)}>{children}</span>
      {/* Display icon on screens smaller than sm */}
      <div className="flex md:hidden">
        <ExclamationCircleIcon
          className="h-6 w-6 text-red-600"
          onClick={() => setShowWarningModal(true)}
        />
      </div>
      {/* Warning modal */}
      <DMAlertModal
        visible={showWarningModal}
        onClose={() => setShowWarningModal(false)}
      >
        <div className="mx-auto flex flex-col items-center justify-center text-center">
          {children}
        </div>
      </DMAlertModal>
    </div>
  ) : (
    <></>
  );
};

export default Warnings;
