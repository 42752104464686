import Button from "../../../../student/components/generic/button";
import { useDeltaToastContext } from "../../../../shared/contexts/ToasterContext";
import { ComponentProps, useState } from "react";
import clsx from "clsx";
import { useResendInviteMutation } from "../../../utils/useResendInviteMutation";

export const ResendInviteButton: React.FC<{
  learnerId: string;
  type?: ComponentProps<typeof Button>["type"];
  className?: string;
}> = ({ learnerId, type = "outline", className }) => {
  const toastContext = useDeltaToastContext();
  const [wasSent, setWasSent] = useState(false);

  const { mutateAsync: resendInviteMutation, isPending } =
    useResendInviteMutation();

  const resendInvite = async () => {
    try {
      await resendInviteMutation({ learnerId });
      setWasSent(true);
      setTimeout(() => {
        setWasSent(false);
      }, 3000);
    } catch (error: any) {
      toastContext.addToast({
        status: "Error",
        message:
          error?.response?.data?.message ||
          "There was an error resending the invite email. Please try again.",
      });
    }
  };

  return (
    <Button
      className={clsx(
        "group flex h-10 w-full items-center justify-center gap-2 text-sm",
        className
      )}
      type={type}
      onClick={resendInvite}
      isLoading={isPending}
    >
      {wasSent ? (
        <>
          <i className="far fa-check !text-dm-charcoal-800 group-hover:!text-dm-brand-blue-600" />{" "}
          Sent!
        </>
      ) : (
        "Resend Invite Email"
      )}
    </Button>
  );
};
