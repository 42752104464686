import clsx from "clsx";
import { uniqueId } from "lodash";
import {
  FormEventHandler,
  KeyboardEventHandler,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

const SEARCH_DEBOUNCE_TIME = 300;

interface SearchInputProps {
  onQuery: (query: string) => void;
}

export const SearchInput: React.FC<SearchInputProps> = ({ onQuery }) => {
  const [query, setQuery] = useState<string>("");
  const [isActive, setIsActive] = useState<boolean>(false);
  const [idStr] = useState(() => `search-input--${uniqueId()}`);
  const inputRef = useRef<HTMLInputElement>(null);

  const performSearch = (force = false) => {
    const q = query.trim();
    onQuery(q);
  };

  // Search on change after debounce
  useEffect(() => {
    const t = setTimeout(() => performSearch(), SEARCH_DEBOUNCE_TIME);
    return () => clearTimeout(t);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [query]);

  // Search immediately when hitting enter
  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    performSearch();
  };

  const clearSearch = useCallback(() => {
    setQuery("");
    setIsActive(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleKeyDown: KeyboardEventHandler = useCallback((e) => {
    if (e.key === "Escape") {
      clearSearch();
      inputRef.current?.blur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <form onSubmit={handleSubmit} role="search">
      <label htmlFor={idStr} className="text-lg font-bold">
        Search
      </label>
      <div className="relative h-12 w-96">
        <input
          ref={inputRef}
          type="text"
          name="query"
          id={idStr}
          className="block h-full w-full rounded-md border-gray-300 px-2 py-1 text-sm placeholder-gray-300 ring-inset placeholder:text-sm focus:border-dm-brand-blue-500 focus:ring-0 focus:ring-dm-brand-blue-500"
          value={query}
          placeholder="Search skills"
          onChange={(e) => setQuery(e.target.value)}
          onKeyDown={handleKeyDown}
          onFocus={() => setIsActive(true)}
          aria-describedby="search-help"
        />
        <button
          type="reset"
          className={clsx(
            isActive ? "visible" : "invisible",
            "absolute inset-y-0 right-2 flex h-full items-center opacity-70 hover:opacity-100 md:right-0"
          )}
          onClick={clearSearch}
          aria-label="Clear search query and close search"
        >
          <i
            className="far fa-fw fa-times text-xl leading-none !text-black"
            aria-hidden="true"
          ></i>
        </button>

        <div id="search-help" className="sr-only">
          Results will update as you type.
        </div>
      </div>
    </form>
  );
};
