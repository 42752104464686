import { NavLink, NavLinkProps } from "react-router-dom";

export const ExternalStatsLink: React.FC<{ to: NavLinkProps["to"] }> = ({
  to,
  children,
}) => (
  <NavLink
    className="group rounded border border-dm-charcoal-200/50 bg-white/50 px-4 py-1 text-dm-charcoal-500 hover:border-dm-charcoal-200 hover:bg-white/70"
    to={to}
    target="_blank"
  >
    <i className="far fa-angle-double-right -ml-1 mr-2 opacity-30" />
    <span>{children}</span>
  </NavLink>
);
