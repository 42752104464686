import { useContext, useEffect, useState } from "react";
import DeltaMathConfirmation from "../../shared/DeltaMathConfirmation";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { updateUser } from "./Profile";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { deltamathAPI } from "../../manager/utils";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import { useUserContext } from "../../shared/contexts/UserContext";

const AddStudentId = () => {
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const toastContext = useDeltaToastContext();
  const { dmSectionsData } = useContext(StudentSectionsContext);

  const [open, setOpen] = useState(false);
  /** are there any regex requirements, or can an ID be anything? */
  const [updateUserBody, setUpdateUserBody] = useState({
    fields: {
      student_id: "",
    },
    stats: false,
    student: true,
  });

  useEffect(() => {
    if (dmSectionsData) {
      const idRequired = dmSectionsData.filter(
        (section: { id_required?: boolean }) => section.id_required === true
      );
      /** show promp if any teacher requires an id, and the user does not have a student_id set  */
      if (idRequired.length > 0 && !user.student_id) {
        setOpen(true);
      }
    }
  }, [dmSectionsData]);

  const addStudentId = useMutation({
    mutationFn: (body: string) => {
      return axios.post(deltamathAPI() + "/update_user_field", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onSuccess(data: any) {
      if (data?.data?.success) {
        toastContext.addToast({
          status: "Success",
          message: "Info successfully updated!",
        });
        const user = JSON.parse(localStorage.getItem("user") || "{}");
        const newUser = {
          ...user,
          student_id: updateUserBody.fields.student_id,
        };
        updateUser({ ...newUser });
        setOpen(false);
      } else if (data?.data?.message) {
        toastContext.addToast({
          status: "Error",
          message: data?.data?.message,
        });
      }
    },
  });

  return (
    <>
      {open && (
        <DeltaMathConfirmation
          title={"Add Student ID"}
          message={
            <>
              <label>
                Your teacher requires that you enter a student id. Type in your
                ID and press &quot;save&quot;.
              </label>
              <input
                type="text"
                placeholder="Student id"
                aria-label="student-id"
                onChange={(e) => {
                  e.preventDefault();
                  setUpdateUserBody({
                    ...updateUserBody,
                    fields: {
                      ...updateUserBody.fields,
                      student_id: e.target.value,
                    },
                  });
                }}
                className="ml-2 rounded-md"
              />
            </>
          }
          confirm={"Save"}
          confirmAction={() => {
            if (
              !updateUserBody.fields.student_id ||
              updateUserBody.fields.student_id.trim().length === 0
            ) {
              toastContext.addToast({
                status: "Error",
                message: "Please enter a valid student id.",
              });
              return;
            }
            addStudentId.mutate(JSON.stringify(updateUserBody));
          }}
          cancel={"Add later"}
          cancelAction={() => setOpen(false)}
        />
      )}
    </>
  );
};

export default AddStudentId;
