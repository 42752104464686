import { useQuery } from "@tanstack/react-query";
import { executeQuery } from "../../../utils";
import { DmLoadingSpinner } from "../../utils/functions";

const TempContent: React.FC = ({ children }) => (
  <div className="flex h-24 items-center justify-center rounded-lg bg-white">
    {children}
  </div>
);

export const RenewalsSummary: React.FC = () => {
  const { data, isLoading } = useQuery<{
    summary: Array<{ title: string; value: number }>;
  }>({
    queryKey: ["renewalsSummary"],
    queryFn: () => executeQuery({ path: "/manager_new/renewals/summary" }),
    refetchOnWindowFocus: false,
  });

  if (isLoading) {
    return (
      <TempContent>
        <DmLoadingSpinner message="" />
      </TempContent>
    );
  }

  if (data) {
    return (
      <ul className="flex gap-4">
        {data.summary.map(({ title, value }) => (
          <li
            key={title}
            className="mb-2 flex w-full flex-col gap-4 rounded-lg bg-white p-6"
          >
            <h3 className="flex-grow font-bold">{title}</h3>
            <span className="h-2 w-full rounded bg-dm-brand-blue-200">
              <span
                className="block h-full rounded bg-dm-brand-blue-500"
                style={{ width: `${value}%` }}
              />
            </span>
          </li>
        ))}
      </ul>
    );
  }

  return (
    <TempContent>
      <p className="text-dm-error-500">Unable to load summary</p>
    </TempContent>
  );
};
