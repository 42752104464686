import { createContext, useContext, useEffect, useState } from "react";
import { useDMQuery } from "../../utils";
import { Learner, Progress } from "../types";
import { useLearnerContext } from "./LearnerContext";
import { noop } from "lodash";
import { useChildLearnersQuery } from "../utils/useChildLearnersQuery";

type LoadingState = "not-started" | "loading" | "done";

interface ParentState {
  /** The current set of learners for the parent */
  learners: Learner[];
  /** The set of learners when the page loaded, or since this was last reset */
  initialLearners: null | Learner[];
  learnersLoadingState: LoadingState;
  isUpdatingLearnerProgress: boolean;
  currentLearnerId: string | null;
}

interface ParentContextApi {
  currentLearner: Learner | null;
  learners: Learner[];
  initialLearners: null | Learner[];
  fetchChildLearners: () => void;
  resetInitialLearners: () => void;
  /**
   * Get the learners that have been added since page load (or since initial
   * learners were last reset)
   */
  getAddedLearners: () => Learner[];
  setCurrentLearnerId: (learnerId: string) => void;
  learnersLoadingState: LoadingState;
  childLearnersFetched: boolean;
  isUpdatingLearnerProgress: boolean;
}

const ParentContext = createContext<{
  state: ParentState;
  setState: React.Dispatch<React.SetStateAction<ParentState>>;
  fetchChildLearners: () => void;
  setCurrentLearnerId: (learnerId: string) => void;
  childLearnersFetched: boolean;
}>({
  state: {
    learners: [],
    initialLearners: null,
    currentLearnerId: null,
    learnersLoadingState: "not-started",
    isUpdatingLearnerProgress: false,
  },
  setState: noop,
  fetchChildLearners: noop,
  setCurrentLearnerId: noop,
  childLearnersFetched: false,
});

/** Returns the parent context API for interacting with parent/child data */
export function useParentContext(): ParentContextApi {
  const {
    state,
    setState,
    fetchChildLearners,
    setCurrentLearnerId,
    childLearnersFetched,
  } = useContext(ParentContext);

  return {
    currentLearner:
      state.learners.find((l) => l._id === state.currentLearnerId) || null,
    learners: state.learners,
    initialLearners: state.initialLearners,
    fetchChildLearners,
    resetInitialLearners: () =>
      setState((prevState) => ({
        ...prevState,
        initialLearners: prevState.learners,
      })),
    getAddedLearners: () =>
      state.learners.filter(
        (l) => !state.initialLearners?.find((i) => i._id === l._id)
      ),
    setCurrentLearnerId,
    learnersLoadingState: state.learnersLoadingState,
    childLearnersFetched,
    isUpdatingLearnerProgress: state.isUpdatingLearnerProgress,
  };
}

/** Context provider for parent users */
export const ParentContextProvider: React.FC = ({ children }) => {
  const learnerContext = useLearnerContext();
  const { refetch: refetchChildLearners, isFetched: childLearnersFetched } =
    useChildLearnersQuery();

  const [state, setState] = useState<ParentState>({
    learners: [],
    initialLearners: null,
    currentLearnerId:
      (learnerContext.state.isDecampingToParent &&
        learnerContext.state.currentLearner) ||
      null, // We do not want to initialize to null if we are decamping back to parent from learner - doing so causes a bad API request to assignments
    learnersLoadingState: "not-started",
    isUpdatingLearnerProgress: false,
  });

  useEffect(() => {
    fetchChildLearners();
    // Only fetch on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    learnerContext.setParentCurrentLearner(state.currentLearnerId || undefined);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.currentLearnerId]);

  const { data, isSuccess } = useDMQuery<Progress[]>({
    path: `/learner/parent/learner-progress/${state.currentLearnerId}`,
    queryOptions: {
      enabled: !!state.currentLearnerId,
    },
  });

  useEffect(() => {
    if (isSuccess) {
      learnerContext.setProgress(data);
      setState((prevState) => {
        return {
          ...prevState,
          isUpdatingLearnerProgress: false,
        };
      });
    }
  }, [data, isSuccess]);

  const fetchChildLearners = async () => {
    setState({
      ...state,
      learnersLoadingState:
        state.learnersLoadingState === "done" ? "done" : "loading",
    });
    const response = await refetchChildLearners();
    if (response.data) {
      setState({
        learners: response.data.learners,
        initialLearners: state.initialLearners || response.data.learners,
        currentLearnerId: state.currentLearnerId
          ? state.currentLearnerId
          : response.data.learners.length > 0
          ? response.data.learners[0]._id
          : null,
        learnersLoadingState: "done",
        isUpdatingLearnerProgress: false,
      });
    }
  };

  useEffect(() => {
    if (childLearnersFetched && learnerContext.state.isDecampingToParent) {
      learnerContext.setDecampingToParent(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [childLearnersFetched, learnerContext.state.isDecampingToParent]);

  const setCurrentLearnerId = (learnerId: string) => {
    if (
      state.currentLearnerId !== learnerId &&
      state.learners.find((l) => l._id === learnerId)
    ) {
      setState((prevState) => {
        return {
          ...prevState,
          currentLearnerId: learnerId,
          isUpdatingLearnerProgress: true, // anytime currentLearnerId is updated, it triggers the learner-progress query
        };
      });
    }
  };

  return (
    <ParentContext.Provider
      value={{
        state,
        setState,
        fetchChildLearners,
        setCurrentLearnerId,
        childLearnersFetched,
      }}
    >
      {children}
    </ParentContext.Provider>
  );
};
