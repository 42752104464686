import { EXPIRATION_OCTOBER_2026 } from "../../manager/utils";
import Button from "../../student/components/generic/button";
import useStripeCheckoutMutation from "../utils/useStripeCheckoutMutation";

export const PromoTrial = ({
  productKey,
  type,
  fullPrice,
}: {
  productKey: string;
  type: string;
  fullPrice: number;
}) => {
  const { mutate } = useStripeCheckoutMutation();
  //TODO: checking if page is embedded in Angular iframe
  const browserWindow = window.self === window.top ? window : window.parent;

  const subscriptionExpiration = EXPIRATION_OCTOBER_2026
    ? new Date(EXPIRATION_OCTOBER_2026 * 1000).toLocaleDateString("en-US", {
        dateStyle: "medium",
      })
    : undefined;

  const handleStripeCheckout = async () => {
    try {
      const data = await mutate({
        productKey: type === "plus" ? "oct2026_plus" : "oct2026_integral",
      });
      const url = data.data.url;
      browserWindow.location.href = url;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <h3 className="mt-4 text-left text-lg font-bold text-neutral-700">
        <span className="underline">Special Offer</span>: Purchase a license for
        next school year (25-26) and get the rest of this school year for free!
      </h3>

      <div
        className={
          "flex flex-row items-center gap-2 pt-4 font-serif text-2xl font-bold"
        }
      >
        <span className={"rounded bg-dm-success-200 px-2"}>${fullPrice}</span>
        {subscriptionExpiration ? (
          <p className={"font-sans text-sm font-normal"}>
            Subscription through <strong>{subscriptionExpiration}</strong>
          </p>
        ) : null}
      </div>

      <Button
        type="primary"
        size="standard"
        className="mt-2 w-full"
        onClick={handleStripeCheckout}
      >
        Upgrade Today
      </Button>

      <h3 className="mt-4 text-left text-lg font-bold text-neutral-700">
        Or, pay a prorated price for the rest of this school year!
      </h3>
    </>
  );
};

export default PromoTrial;
