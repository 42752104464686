import { useQuery } from "@tanstack/react-query";
import { executeQuery } from "../../../utils";
import Renewals from "./Renewals";
import { RenewalsPage } from "./RenewalsPage";

export const FeatureFlaggedRenewalsPage: React.FC = () => {
  const user = JSON.parse(localStorage.getItem("user") ?? "{}");
  const { data } = useQuery<{ flags: string[] }>({
    queryKey: ["featureFlags"],
    queryFn: () =>
      executeQuery({
        path: "/feature-flags/user",
        params: { userId: user.teachercode ?? "" },
      }),
    refetchOnWindowFocus: false,
  });

  if (!data) {
    return <></>;
  }

  return data.flags.includes("enableNewRenewalsPage") ? (
    <RenewalsPage />
  ) : (
    <Renewals />
  );
};
