import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useDMQuery } from "../../utils";
import { APIPayload } from "../components/Search";
import axios, { AxiosResponse } from "axios";
import { deltamathAPI } from "../utils";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";

const modifyInfo = (data: any[]): APIPayload[] | any[] => {
  /**
   * Sort teacher data by number of annual logs (descending)
   */
  const sortedData =
    Array.isArray(data) &&
    data.sort((a: { annual_logs: number }, b: { annual_logs: number }) => {
      return b.annual_logs - a.annual_logs;
    });
  /*
   * manager/get-info API returns schoolinfo data as a nested object.
   * This flattens the data into individual fields
   */
  const response =
    Array.isArray(sortedData) &&
    sortedData.map((d: APIPayload, index) => ({
      ...d,
      row_num: index + 1, //start at 1, not 0
      schoolinfo_name: d?.schoolinfo?.name,
      schoolinfo_city: d?.schoolinfo?.city,
      schoolinfo_state: d?.schoolinfo?.state,
      schoolinfo_zip: d?.schoolinfo?.zip,
    }));
  if (response) {
    return response;
  } else {
    return data;
  }
};

export const GetInfo = (params: {
  first?: string;
  last?: string;
  email?: string;
  school?: string;
  city?: string;
  state?: string;
  zip?: string;
  expiration?: string;
  access_code?: string;
  teachercode?: string;
}) =>
  useDMQuery({
    path: "/manager_new/manage/get-info",
    method: "get",
    cacheKey: "get-info",
    params,
    queryOptions: {
      enabled: false,
    },
    modifyData: modifyInfo,
  });

//In search, will be used to create mutation functions for add-plus, remove-plus, downgrade-to-plus, upgrade-to-integral,merge-account, resetIPAddress, undelete assignment. May be a little awkward to wrap this way, but doing so allows use of hooks (which can't be directly called outside React component) w/o too much redundant code

export const MutateTeacher = (
  endpoint: string,
  cacheKeyToInvalidate?: string,
  successCallback?: (res: AxiosResponse<any, any>) => void
) => {
  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();

  interface ModifyReqBodyInterface {
    teachercode?: string | number;
    expiration?: number;
    _id?: number;
    newteachercode?: string | number;
    mergeWithAdminConfirmed?: boolean;
  }

  interface TeacherMutateInterface {
    body?: ModifyReqBodyInterface;
    param?: string;
  }

  return useMutation({
    mutationFn: (req: TeacherMutateInterface) => {
      const url = `${deltamathAPI()}/manager_new/manage/${endpoint}${
        typeof req.param !== "undefined" ? `/${req.param}` : ""
      }`;
      return axios.post(
        url,
        { ...(typeof req.body !== "undefined" ? req.body : {}) },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: (res) => {
      if (res.data?.message) {
        toastContext.addToast({
          status: "Success",
          message: res.data.message,
        });
      }
      if (typeof cacheKeyToInvalidate !== "undefined") {
        queryClient.invalidateQueries({ queryKey: [cacheKeyToInvalidate] });
      }
      if (typeof successCallback !== "undefined") {
        successCallback(res);
      }
    },
    onError: (error) => {
      toastContext.addToast({
        status: "Error",
        message: `A submission error has occurred: ${error}`,
      });
    },
  });
};

export const GetDeletedAssignments = (teachercode: string) =>
  useDMQuery({
    path: `/manager_new/manage/get_deleted_assignments/${teachercode}`,
    cacheKey: "get-deleted",
    method: "get",
    queryOptions: {
      enabled: false,
    },
  });

export const SchoolOrDistrictById = (
  type: string | undefined,
  id: string | undefined
) =>
  useDMQuery({
    path: `/manager_new/manage/school-or-district-by-id/${type}/${id}`,
    method: "get",
  });

export const GetStudentInfo = (params: {
  first?: string;
  last?: string;
  login?: string;
  _id?: string;
}) => {
  return useDMQuery({
    path: "/manager_new/manage/student",
    method: "get",
    cacheKey: ["get-student-info", JSON.stringify(params)],
    params,
    queryOptions: {
      enabled: false,
    },
  });
};

export const DeleteStudent = (
  successCallback: (successData: {
    success: boolean;
    message: string;
    deletedEmail: string;
  }) => void,
  errorCallback: (error: any) => void
) => {
  return useMutation({
    mutationFn: (reqInfo: {
      studentId: string;
      student: {
        _id: number;
        first: string;
        last: string;
        login: string;
      };
    }) => {
      return axios.post(
        deltamathAPI() +
          `/manager_new/manage/delete_student/${reqInfo.studentId}`,
        reqInfo.student,
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    },
    onSuccess: (data) => {
      successCallback(data.data);
    },
    onError: (error) => {
      errorCallback(error);
    },
  });
};

export const DeleteTeacher = (
  successCallback: (data: any) => void,
  errorCallback: (error: any) => void
) => {
  return useMutation({
    mutationFn: (req: { body: string; teachercode: string }) => {
      return axios.post(
        deltamathAPI() +
          `/manager_new/manage/delete_teacher/${req.teachercode}`,
        req.body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: (data) => {
      successCallback(data.data);
    },
    onError: (error) => {
      errorCallback(error);
    },
  });
};
