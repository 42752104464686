import clsx from "clsx";
import React, { forwardRef, useEffect } from "react";
import { NavLink } from "react-router-dom";

export type Props = {
  children: React.ReactNode;
  type?:
    | "primary"
    | "secondary"
    | "outline"
    | "link"
    | "submitAnswer"
    | "points";
  size?: "large" | "standard" | "small" | "xsmall" | "wide";
  submit?: boolean;
  href?: string;
  target?: "_blank";
  className?: string;
  onClick?: (e?: any) => void;
  disabled?: boolean;
  ariaLabel?: string;
  id?: string;
  isLoading?: boolean;
  onMountFunc?: () => void;
  testId?: string;
};

const Button = forwardRef((props: Props, buttonRef: any) => {
  useEffect(() => {
    props.onMountFunc?.();
    // Only run this on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const padding =
    props.size === "xsmall"
      ? "px-4 py-2 leading-6 text-sm"
      : props.size === "small"
      ? "px-8 py-1 leading-6 text-sm"
      : props.size === "large"
      ? "px-8 py-3 leading-6"
      : props.size === "wide"
      ? "px-12 py-2 leading-6"
      : "px-8 py-2 leading-6";

  const styles = props.isLoading
    ? "rounded bg-dm-brand-blue-800 text-white"
    : !props.type || props.type === "primary"
    ? "rounded bg-dm-brand-blue-500 text-white hover:bg-dm-brand-blue-600 active:bg-dm-brand-blue-800"
    : props.type === "secondary"
    ? "rounded bg-dm-brand-blue-200 hover:bg-dm-brand-blue-600 hover:text-white active:bg-dm-brand-blue-800 disabled:cursor-not-allowed disabled:text-white"
    : props.type === "outline"
    ? "rounded border border-dm-charcoal-800 text-dm-charcoal-800 hover:border-dm-brand-blue-600 hover:text-dm-brand-blue-600 active:bg-dm-charcoal-500 active:text-white"
    : props.type === "submitAnswer"
    ? "submit-answer-form m-1 inline-flex items-center rounded bg-dm-brand-blue-500 px-4 py-2 text-sm font-medium text-white hover:bg-dm-brand-blue-600 active:bg-dm-brand-blue-800"
    : props.type === "points"
    ? "rounded bg-dm-purple-600 text-white hover:bg-dm-purple-500 active:bg-dm-purple-500"
    : "";

  const classNameFinal =
    props.type === "link"
      ? clsx(
          "py-2 text-dm-brand-blue-500 hover:text-dm-brand-blue-600 active:text-dm-brand-blue-800 disabled:opacity-70",
          props.className,
          props.isLoading && "pointer-events-none opacity-40"
        )
      : clsx(
          padding,
          (props.disabled || props.isLoading) && "cursor-not-allowed",
          props.disabled && "opacity-50",
          styles,
          "text-center",
          props.className
        );

  const children = props.isLoading ? (
    <div className="relative">
      <div className="invisible">{props.children}</div>
      <div className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2">
        <div
          style={{ borderTopColor: "transparent" }}
          className="h-5 w-5 animate-spin rounded-full border-[3px] border-solid border-white"
        ></div>
      </div>
    </div>
  ) : (
    props.children
  );

  return props.href ? (
    <NavLink
      id={props.id}
      to={props.href}
      target={props.target}
      aria-label={props.ariaLabel}
      className={classNameFinal}
      ref={buttonRef}
      data-testid={props.testId}
    >
      {children}
    </NavLink>
  ) : (
    <button
      id={props.id}
      disabled={props.disabled || props.isLoading}
      onClick={props.onClick}
      type={
        props.submit === true
          ? "submit"
          : props.submit === false
          ? "button"
          : undefined
      }
      aria-label={props.ariaLabel}
      className={classNameFinal}
      ref={buttonRef}
      data-testid={props.testId}
    >
      {children}
    </button>
  );
});
Button.displayName = "GenericButton";

export default Button;
