import { useState } from "react";
import Button from "../../../student/components/generic/button";
import Modal from "../../../student/components/generic/Modal";
import { ExperimentState } from "./ExperimentState";
import { Experiment } from "./types";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { EXPERIMENT_ITEM, EXPERIMENTS_LIST } from "./queries";
import { deltamathAPI } from "../../utils";
import axios from "axios";
import { withJsonHeader } from "../../../shared/axiosUtils";

type ModalState = "confirmChange" | "closeExperiment" | "hidden";

export const ChangeStateForm: React.FC<{ experiment: Experiment }> = ({
  experiment,
}) => {
  const queryClient = useQueryClient();
  const [modalState, setModalState] = useState<ModalState>("hidden");
  const [winningVariant, setWinningVariant] = useState<string | null>(null);
  const [winningVariantError, setWinningVariantError] = useState<string | null>(
    null
  );

  const config: {
    nextExperimentState: Experiment["state"];
    buttonText: string;
    buttonExpl: string;
    modalState: ModalState;
    modalText?: string;
  } | null =
    experiment.state === "draft"
      ? {
          nextExperimentState: "open",
          buttonText: "Open experiment",
          buttonExpl: "This will make the experiment live",
          modalState: "confirmChange",
          modalText:
            "Are you sure you want to open this experiment? This will make the experiment live, and users will start seeing it.",
        }
      : experiment.state === "open"
      ? {
          nextExperimentState: "closed",
          buttonText: "Close experiment",
          buttonExpl: "This will stop the experiment and declare a winner",
          modalState: "closeExperiment",
        }
      : experiment.state === "closed"
      ? {
          nextExperimentState: "retired",
          buttonText: "Retire experiment",
          buttonExpl:
            "This will show that the experiment has been completed and all code has been removed",
          modalState: "confirmChange",
          modalText:
            "Are you sure you want to retire this experiment? This will indicate that the experiment has been completed and all code has been removed.",
        }
      : null;

  const { mutateAsync: editStateMutation, isPending } = useMutation({
    mutationFn: () =>
      axios.post(
        `${deltamathAPI()}/experiments/edit/state`,
        JSON.stringify({
          experimentId: experiment._id,
          state: config?.nextExperimentState,
          ...(config?.nextExperimentState === "closed"
            ? { winningVariant }
            : {}),
        }),
        withJsonHeader()
      ),
  });

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    if (config) {
      setModalState(config.modalState);
    }
  };

  const handleConfirm = async () => {
    if (modalState === "closeExperiment") {
      if (!winningVariant) {
        setWinningVariantError("Please select a winning variant");
        return;
      }
    }
    await editStateMutation();
    await queryClient.invalidateQueries({ queryKey: [EXPERIMENTS_LIST] });
    await queryClient.invalidateQueries({ queryKey: [EXPERIMENT_ITEM] });
    setModalState("hidden");
  };

  return (
    <div>
      <h3 className="mb-2 text-lg font-bold">Experiment state</h3>
      <form
        className="flex items-center gap-20 rounded border border-dm-charcoal-200 bg-white/50 px-10 py-6"
        onSubmit={handleSubmit}
      >
        <ExperimentState state={experiment.state} />

        {config && (
          <div>
            <Button className="mb-1">{config.buttonText}</Button>
            <p className="text-sm text-dm-charcoal-500">{config.buttonExpl}</p>
          </div>
        )}
      </form>

      <Modal
        visible={modalState !== "hidden"}
        onClose={() => setModalState("hidden")}
        title={
          <span className="font-serif text-dm-brand-blue-600">
            {modalState === "closeExperiment"
              ? "Close experiment"
              : "Confirm change"}
          </span>
        }
        body={
          modalState === "closeExperiment" ? (
            <div className="flex flex-col gap-4">
              <p>
                Select the winning variant for this experiment. This variant
                will be shown to all users.
              </p>
              <ul className="flex flex-col gap-2 px-2">
                {experiment.variants.map((variant) => (
                  <li key={variant.name}>
                    <label className="flex items-center gap-2">
                      <input
                        type="radio"
                        value={variant.name}
                        checked={winningVariant === variant.name}
                        onChange={() => {
                          setWinningVariant(variant.name);
                          setWinningVariantError(null);
                        }}
                      />
                      <code>{variant.name}</code>
                    </label>
                  </li>
                ))}
              </ul>
              {winningVariantError && (
                <p className="text-sm text-dm-error-500">
                  {winningVariantError}
                </p>
              )}
            </div>
          ) : (
            <div>{config?.modalText}</div>
          )
        }
        secondaryButtonText="Cancel"
        secondaryOnClick={() => setModalState("hidden")}
        confirmDisabled={isPending}
        confirmButtonText={
          modalState === "closeExperiment" ? "Close experiment" : "Confirm"
        }
        onConfirm={handleConfirm}
      />
    </div>
  );
};
