import React from "react";
import Button from "../../../student/components/generic/button";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { deltamathAPI } from "../../utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";

type Props = {
  versionHistory?: {
    versionKey: string;
    state: "draft" | "past" | "published" | "deleted";
  };
  type: "course" | "unit";
  id: string;
  callback: () => void;
};

const PublishVersion = (props: Props) => {
  const toastContext = useDeltaToastContext();

  const publish = useMutation({
    mutationFn: (body: string) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/parent-portal/publish/${props.id}`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess() {
      props.callback();
      toastContext.addToast({
        status: "Success",
        message: "Published!",
      });
    },
    onError(e) {
      toastContext.addToast({
        status: "Error",
        message: `Error publishing ${props.type}`,
      });
    },
  });

  if (!props.versionHistory) {
    return <></>;
  }

  if (props.versionHistory.state === "published") {
    return <h5 className="text-xl font-bold">Published!</h5>;
  } else if (props.versionHistory.state === "deleted") {
    return <h5 className="text-xl font-bold text-dm-error-500">Deleted</h5>;
  }

  const callPublish = () => {
    const body = {
      version: props.versionHistory?.versionKey,
      type: props.type,
    };
    publish.mutate(JSON.stringify(body));
  };

  return <Button onClick={callPublish}>Publish Version</Button>;
};

export default PublishVersion;
