import { useState } from "react";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useMutation } from "@tanstack/react-query";
import { deltamathAPI } from "../../../manager/utils";
import axios from "axios";
import Modal from "../../../student/components/generic/Modal";
import { ResponsiveImage } from "../ResponsiveImage";
import { getFilePath } from "../../../utils";
import { useLearnerContext } from "../../contexts/LearnerContext";
import Button from "../../../student/components/generic/button";

type Props = {
  courseId: string;
};

const ArchiveCourse = (props: Props) => {
  const learnerContext = useLearnerContext();
  const [showModal, setShowModal] = useState<boolean>(false);

  const toastContext = useDeltaToastContext();

  const { mutate: archiveCourse } = useMutation({
    mutationFn: (body: { courseId: string }) => {
      return axios.post(
        deltamathAPI() + "/learner/data/archive_course",
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: () => {
      // put this in a timeout so the modal can gracefully fade out
      // we then archive the course which removes it from the sidebar
      setShowModal(false);
      setTimeout(() => {
        learnerContext.archiveCourse(props.courseId);
      }, 350);
    },
    onError() {
      toastContext.addToast({
        status: "Error",
        message: "There was an issue archiving your course.",
      });
    },
  });

  const hideCourse = () => {
    const body = { courseId: props.courseId };
    archiveCourse(body);
  };

  const closeModal = () => {
    setShowModal(false);
  };

  return (
    <>
      <div className="flex-1 py-1 pl-2 pr-4 text-right text-sm">
        <Button
          onClick={() => {
            setShowModal(true);
          }}
          type="link"
          className="!py-0"
        >
          Hide Course
        </Button>
      </div>
      <Modal
        visible={showModal}
        onClose={closeModal}
        body={
          <div className="flex flex-col items-center">
            <ResponsiveImage
              className="mx-auto h-auto w-56"
              srcs={[
                getFilePath(
                  "/images/learner/archivecourse/deltie-thinking@2x.png"
                ),
                getFilePath(
                  "/images/learner/archivecourse/deltie-thinking.png"
                ),
              ]}
              alt="Deltie thumb on chin thinking"
            />
            <h3 className="mt-10 w-full text-2xl font-bold">
              Hide Course from Sidebar?
            </h3>
            <p className="mt-4">
              {
                "Your progress is saved. The course will be hidden from the sidebar. You can access it anytime from 'All Courses'. It will reappear here when you resume working on it."
              }
            </p>
          </div>
        }
        onConfirm={hideCourse}
        confirmButtonText="Hide Course"
        secondaryButtonText="Keep Course"
        secondaryOnClick={closeModal}
      />
    </>
  );
};

export default ArchiveCourse;
