import React, { useState, useEffect } from "react";
import {
  executeQuery,
  REACT_APP_MANAGER_LINK,
  useDMQuery,
} from "../../../utils";
import DeltaMathTable from "../../../shared/DeltaMathTable";
import { TextFilterNoPersist } from "../../../shared/table/filters";
import RenewalLinkForm from "./RenewalLinkForm";
import ProgressBar from "../../../shared/ProgressBar";
import { Link } from "react-router-dom";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { IRenewal } from "../../utils/quoteUtils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { EXCLUSION_LIST } from "../../../shared/constants";
import { useDocumentTitle } from "usehooks-ts";

export default function Renewals() {
  useDocumentTitle("Renewals");
  //Was using as the query function for infinite query
  // const fetchRenewals = async ({ pageParam = 1 }) => {
  //   const data = await executeQuery({
  //     path: `/manager_new/renewals/all`,
  //     params: {
  //       page: pageParam,
  //       limit: 100,
  //     },
  //   });

  //   return data;
  // };

  //POC for infinite query but for filtering purposes its easier to have all the data and the records are not overwhemling (~4000)

  // const { data, refetch, isLoading } = useInfiniteQuery({
  //   queryKey: "renewals",
  //   queryFn: fetchRenewals,
  //   getNextPageParam: (lastPage, allPages) => {
  //     const { currentPage, totalPages } = lastPage;
  //     return currentPage < totalPages ? currentPage + 1 : undefined;
  //   },
  //   staleTime: Infinity,
  //   refetchOnWindowFocus: false,
  //   refetchOnMount: false,
  //   refetchOnReconnect: false,
  // });

  interface IRenewalsData {
    renewals: IRenewal[];
    summary: Record<string, number>[];
  }

  const [updatedRenewals, setUpdatedRenewals] = useState<IRenewal[]>([]);
  const [updatedSummary, setUpdatedSummary] = useState<
    Record<string, number>[]
  >([]); //TODO: possibly optimistically update summary as renewal clearances happen

  const queryClient = useQueryClient();
  const toastContext = useDeltaToastContext();

  const {
    data: initialData,
    refetch,
    isSuccess: renewalsSuccess,
  } = useDMQuery({
    path: "/manager_new/renewals/all",
    cacheKey: "renewals",
    queryOptions: {
      staleTime: Infinity,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  });

  useEffect(() => {
    if (renewalsSuccess) {
      if (
        Array.isArray(initialData?.renewals) &&
        initialData.renewals?.length > 0
      ) {
        initialData.renewals.forEach((renewal: IRenewal) => {
          queryClient.setQueryData(["renewals", renewal._id], renewal);
        });
      }
    }
  }, [renewalsSuccess, initialData]);

  const mutateRenewal = async ({
    renewalID,
    update,
  }: {
    renewalID: string;
    update: Partial<IRenewal>;
  }) => {
    return await executeQuery({
      path: `/manager_new/renewals/${renewalID}`,
      method: "put",
      requestBody: update,
    });
  };

  //utility to determine if notes field is actually different (i.e. undefined and "" should be treated as equal)
  const notesAreDifferent = (
    oldNote: string | undefined,
    newNote: string | undefined
  ) => {
    if (oldNote === newNote) {
      return false;
    }

    if ((!oldNote && newNote === "") || (oldNote === "" && !newNote)) {
      return false;
    }

    return true;
  };

  const updateMutation = useMutation({
    mutationFn: mutateRenewal,
    onSuccess: (data, { renewalID }) => {
      queryClient.invalidateQueries({ queryKey: ["renewals", renewalID] });

      toastContext.addToast({
        status: data.success ? "Success" : "Error",
        message: data.success
          ? "Renewal updated"
          : data.message || "Error updating renewal",
      });
      //reload renewals if there was an error message returned to keep things in sync
      if (!data.success) {
        refetch();
      }
    },
    onError: (error) => {
      //refetch data to keep things in sync - possible TODO is to see if a pattern of optimisitc update/rollback using cache is actually helpful
      refetch();

      toastContext.addToast({
        status: "Error",
        message: "Error updating renewal: " + error,
      });
    },
  });

  const handleChange = (e: any) => {
    const { email } = JSON.parse(localStorage.getItem("user") || "{}");
    const {
      type,
      tagName,
      value,
      dataset: { rowId: id, rowName: name },
    } = e.target;
    setUpdatedRenewals((prev: IRenewal[]) =>
      prev.map((renewal: IRenewal) => {
        if (renewal._id === id) {
          const updateValue =
            type === "checkbox" && tagName === "INPUT"
              ? !renewal[name as keyof IRenewal]
              : value;
          const update: Partial<IRenewal> = { [name]: updateValue };
          if (name === "renewalCleared" && update.renewalCleared) {
            update.manuallyClearedBy = { email, time: Date.now() };
          }
          updateMutation.mutate({ renewalID: id, update });
          return { ...renewal, ...update };
        } else {
          return renewal;
        }
      })
    );
  };

  useEffect(() => {
    if (
      Array.isArray(initialData?.renewals) &&
      initialData.renewals.length > 0 &&
      updatedRenewals.length === 0
    ) {
      setUpdatedRenewals(
        initialData.renewals
          .filter((e: IRenewal) => !e.newCustomerFlag)
          .map((e: IRenewal) => {
            const displayName =
              e.customerName || e.links?.[0]?.siteName || e.nces;
            const renewalPercentComplete = e.renewalWeight / e.weight;
            const autoCleared: boolean =
              (e.renewalCleared || false) &&
              (renewalPercentComplete > 0.75 ||
                (e.type === "individual" && renewalPercentComplete > 0.5));
            return { ...e, displayName, autoCleared };
          })
      );
    }

    if (Array.isArray(initialData?.summary) && initialData.summary.length > 0) {
      setUpdatedSummary(initialData.summary);
    }
  }, [initialData, updatedRenewals?.length]);

  const columns = React.useMemo(
    () => [
      {
        Header: " ",
      },
      {
        Header: "School/District Name",
        accessor: "displayName",
        Filter: TextFilterNoPersist,
      },
      {
        Header: "NCES",
        accessor: "nces",
        Filter: TextFilterNoPersist,
      },
      {
        Header: "Generate Link",
        accessor: "_id",
        Cell: ({ cell }: { cell: any }) => {
          const individualLicenses: Array<{
            licenseTier: "Plus" | "Integral";
            licenseCount: number;
          }> = [];
          if (cell.row.original.type === "individual") {
            if (cell.row.original.individualPlus) {
              individualLicenses.push({
                licenseTier: "Plus",
                licenseCount: cell.row.original.individualPlus,
              });
            }
            if (cell.row.original.individualIntegral) {
              individualLicenses.push({
                licenseTier: "Integral",
                licenseCount: cell.row.original.individualIntegral,
              });
            }
          }
          return (
            <RenewalLinkForm
              renewalID={cell.value}
              key={`renewal-link-form-${cell.value}`}
              disabled={Object.hasOwn(EXCLUSION_LIST, cell.row.original.nces)}
              type={cell.row.original.type}
              nces={cell.row.original.nces}
              individualLicenses={individualLicenses}
            />
          );
        },
      },
      {
        Header: "Current License",
        accessor: "type",
        Cell: ({ cell }: { cell: any; props: any }) => {
          let description = "";
          if (cell.value !== "individual") {
            description = `${cell.value} (${
              cell.row.original.hasIntegral ? "Integral" : "Plus"
            })`;
          } else {
            let breakdown = "";
            const individualPlus = cell.row.original.individualPlus;
            const individualIntegral = cell.row.original.individualIntegral;
            if (individualPlus) {
              breakdown += `${individualPlus}P`;
            }
            if (individualPlus && individualIntegral) {
              breakdown += ", ";
            }
            if (individualIntegral) {
              breakdown += `${individualIntegral}Int`;
            }
            description = `Individual (${breakdown})`;
          }
          return <div key={`type-${cell.row.values._id}`}>{description}</div>;
        },
      },
      {
        Header: "Contacts",
        accessor: "contacts",
        Cell: ({ cell }: { cell: any }) => {
          return (
            <div
              key={`contacts-${cell.row.id}`}
              className={`h-10 overflow-y-auto`}
            >
              <ul key={`list-${cell.row.nces}`}>
                {cell.value.map((contact: any, i: number) => (
                  <div key={`${cell.row.values.nces}-${contact}-${i}`}>
                    {contact}
                  </div>
                ))}
              </ul>
            </div>
          );
        },
        Filter: TextFilterNoPersist,
      },
      {
        Header: "Weight",
        accessor: "weight",
      },
      {
        Header: "Renewal Weight %",
        accessor: "renewalWeight",
        Cell: ({ cell }: { cell: any }) => {
          return (
            <div key={`renewalWeight-${cell.row.values._id}`}>
              {cell.value
                ? Math.round((cell.value / cell.row.values.weight) * 100) + "%"
                : "--"}
            </div>
          );
        },
        sortType: (rowA: any, rowB: any, columnId: any, desc: boolean) => {
          const a =
            !rowA.values.renewalWeight || !rowA.values.weight
              ? 0
              : rowA.values.renewalWeight / rowA.values.weight;
          const b =
            !rowB.values.renewalWeight || !rowB.values.weight
              ? 0
              : rowB.values.renewalWeight / rowB.values.weight;
          if (a === 0) {
            return desc ? -1 : 1;
          }
          if (b === 0) {
            return desc ? 1 : -1;
          }
          return a > b ? 1 : -1;
        },
      },
      {
        Header: "Integrations",
        accessor: "integrations",
      },
      {
        Header: "Quotes",
        accessor: "quotes",
        Cell: ({ cell }: { cell: any }) => {
          return (
            <div
              key={`quotes-${cell.row.id}`}
              className={`flex h-20 w-full min-w-[75px] flex-col overflow-y-auto`}
            >
              <ul key={`list-${cell.row.nces}`}>
                {cell.value.map((quote: any, i: number) => (
                  <div key={`${cell.row.values.nces}-${quote}-${i}`}>
                    <Link
                      className="text-indigo-500 hover:underline"
                      to={`${REACT_APP_MANAGER_LINK}/quote-lookup/${quote}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {quote}
                    </Link>
                  </div>
                ))}
              </ul>
            </div>
          );
        },
        minWidth: 100,
      },
      {
        Header: "Metadata",
        accessor: "renewalQuoteMetadata",
        Cell: ({ cell }: { cell: any }) => {
          return (
            <div
              key={`metadata-${cell.row.id}`}
              className={`flex h-20 w-full flex-col overflow-y-auto`}
            >
              <ul key={`list-${cell.row.nces}`}>
                {cell.value
                  ?.filter(
                    (e: Record<string, string | number>) => e.quoteNumber
                  )
                  .map(
                    (metadata: Record<string, string | number>, i: number) => (
                      <div key={`${cell.row.values.nces}-metadata-${i}`}>
                        {metadata.action}, {metadata.quoteNumber},{" "}
                        {new Date(metadata.when).toLocaleString()}
                      </div>
                    )
                  )}
              </ul>
            </div>
          );
        },
      },
      {
        Header: "Renewal Cleared",
        accessor: "renewalCleared",
        Cell: (props: any) => {
          const renewalWeightRatio =
            props.cell.row.values.renewalWeight && props.cell.row.values.weight
              ? props.cell.row.values.renewalWeight /
                props.cell.row.values.weight
              : 0;
          return (
            <input
              type="checkbox"
              checked={!!props.cell.value}
              disabled={props.cell.row.original.autoCleared}
              data-row-id={props.cell.row.values._id}
              data-row-name="renewalCleared"
              key={`renewal-cleared-${props.cell.row.values._id}`}
              className="disabled:cursor-not-allowed"
              onChange={handleChange}
            />
          );
        },
        align: "center",
        sortType: (rowA: any, rowB: any) => {
          const a = rowA.values.renewalCleared ? 1 : 0;
          const b = rowB.values.renewalCleared ? 1 : 0;
          if (a === b) {
            return 0;
          }
          return a ? 1 : -1;
        },
      },
      {
        Header: "Renewal Plus Downgrade",
        accessor: "renewalPlusDowngrade",
        Cell: ({ cell }: { cell: any }) => {
          const value = cell.value ? "yes" : "no";
          return (
            <span key={`renewal-plus-downgrade-${cell.row.values._id}`}>
              {value}
            </span>
          );
        },
        align: "center",
        sortType: (rowA: any, rowB: any) => {
          const a = rowA.values.renewalPlusDowngrade ? 1 : 0;
          const b = rowB.values.renewalPlusDowngrade ? 1 : 0;
          if (a === b) {
            const aConfirmed = rowA.values.renewalPlusDowngradeCleared;
            const bConfirmed = rowB.values.renewalPlusDowngradeCleared;
            if (aConfirmed === bConfirmed) {
              return 0;
            }
            return aConfirmed ? -1 : 1;
          }
          return a ? 1 : -1;
        },
      },
      //PAs will track confirmation of downgrade to plus separately
      // {
      //   Header: "Renewal Plus Downgrade Confirmed",
      //   accessor: "renewalPlusDowngradeCleared",
      //   Cell: (props: any) => {
      //     if (props.cell.row.values.renewalPlusDowngrade) {
      //       return (
      //         <input
      //           type="checkbox"
      //           checked={props.cell.value}
      //           data-row-id={props.cell.row.values._id}
      //           data-row-name="renewalPlusDowngradeCleared"
      //           key={`renewal-plus-downgrade-cleared-${props.cell.row.id}`}
      //           onChange={handleChange}
      //         />
      //       );
      //     } else {
      //       return "--";
      //     }
      //   },
      //   align: "center",
      // },
      {
        Header: "Renewal Grade Downgrade",
        accessor: "renewalGradeDowngrade",
        Cell: ({ cell }: { cell: any }) => {
          const value = cell.value ? "yes" : "no";
          return (
            <span key={`renewal-grade-downgrade-${cell.row.values._id}`}>
              {value}
            </span>
          );
        },
        align: "center",
        sortType: (rowA: any, rowB: any) => {
          const a = rowA.values.renewalGradeDowngrade ? 1 : 0;
          const b = rowB.values.renewalGradeDowngrade ? 1 : 0;
          if (a === b) {
            const aConfirmed = rowA.values.renewalGradeDowngradeCleared;
            const bConfirmed = rowB.values.renewalGradeDowngradeCleared;
            if (aConfirmed === bConfirmed) {
              return 0;
            }
            return aConfirmed ? -1 : 1;
          }
          return a ? 1 : -1;
        },
      },
      //PAs will track confirmation of scope downgrade separately
      // {
      //   Header: "Renewal Grade Downgrade Confirmed",
      //   accessor: "renewalGradeDowngradeCleared",
      //   Cell: (props: any) => {
      //     if (props.cell.row.values.renewalGradeDowngrade) {
      //       return (
      //         <input
      //           type="checkbox"
      //           checked={props.cell.value}
      //           data-row-id={props.cell.row.values._id}
      //           data-row-name="renewalGradeDowngradeCleared"
      //           key={`renewal-plus-downgrade-cleared-${props.cell.row.id}`}
      //           onChange={handleChange}
      //         />
      //       );
      //     } else {
      //       return "--";
      //     }
      //   },
      //   align: "center",
      // },
      {
        Header: "Non-Renewal Confirmed",
        accessor: "nonRenewalConfirmed",
        Cell: (props: any) => {
          if (!props.cell.row.values.renewalWeight) {
            return (
              <input
                type="checkbox"
                checked={!!props.cell.value}
                data-row-id={props.cell.row.values._id}
                data-row-name="nonRenewalConfirmed"
                key={`nonrenewal-cleared-${props.cell.row.id}`}
                onChange={handleChange}
              />
            );
          } else {
            return "--";
          }
        },
        align: "center",
      },
      {
        Header: "Notes",
        accessor: "renewalNotes",
        Cell: (props: any) => {
          return (
            <div>
              <textarea
                className="h-24 w-full rounded-lg border-2 border-gray-300 p-2"
                defaultValue={props.cell.value}
                key={`notes-${props.cell.row.values._id}`}
                data-row-id={props.cell.row.values._id}
                data-row-name="renewalNotes"
                onBlur={(e) => {
                  if (notesAreDifferent(props.cell.value, e.target.value)) {
                    handleChange(e);
                  }
                }}
              />
            </div>
          );
        },
        minWidth: 200,
      },
    ],
    []
  );

  return (
    <>
      <div className="px-2 md:px-0">
        <h1 className="mx-4 py-4 text-2xl font-semibold text-gray-900">
          Renewals
        </h1>
        {updatedSummary.length > 0 && (
          <>
            <h2 className="mx-4 text-xl font-semibold text-gray-900">
              Summary
            </h2>

            <div className="flex flex-row justify-between">
              {updatedSummary.map((datum: Record<string, any>, i: number) => (
                <div
                  className="m-4 w-full rounded-lg bg-white p-4 shadow-md"
                  key={`summary-${i}`}
                >
                  <div className="mb-4 w-full text-center font-bold">
                    {datum.title} - {datum.value}%
                  </div>
                  <ProgressBar percentCompleted={datum.value} width="w-full" />
                </div>
              ))}
            </div>
          </>
        )}
        {updatedRenewals && (
          <DeltaMathTable
            columns={columns}
            data={updatedRenewals}
            key={`delta-math-table-renewals`}
            options={{
              autoResetSortBy: false,
              globalFilter: true,
              autoResetFilters: false,
              stickyHeader: true,
            }}
            updateMutation={updateMutation}
          />
        )}
      </div>
    </>
  );
}
