import axios from "axios";
import React, { ChangeEvent, useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import {
  CourseStandard,
  ListStandardsResponse,
  SkillCodes,
  SearchResults,
} from "DeltaMathAPI/routers/standards.router/api";
import { deltamathAPI } from "../../utils";
import DeltaMathConfirmation from "../../../shared/DeltaMathConfirmation";
import { DeltaMathSelect, SelectInput } from "../../../shared/DeltaMathSelect";
import { Tooltip } from "../../../shared/Tooltip";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import StandardTree from "./StandardTree";
import StandardsList from "./StandardsList";
import {
  CheckCircleIcon,
  DownloadIcon,
  ArchiveIcon,
  TrashIcon,
} from "@heroicons/react/outline";
import { handleErr } from "./common/util";
import { useUserContext } from "../../../shared/contexts/UserContext";

/**
 * Defines the input for the Status Dropdown
 */
const statusOptions: SelectInput = [
  { key: "preview", val: "Preview" },
  { key: "live", val: "Live" },
];

/**
 * The main component for the Standards Management page.
 * Used to view / edit / approve / archive standards.
 */
const StandardsManage = (props: {
  getActivated: string;
  setActivated: React.Dispatch<React.SetStateAction<string>>;
  getStateCode: string;
  setStateCode: React.Dispatch<React.SetStateAction<string>>;
  getStatus: string;
  setStatus: React.Dispatch<React.SetStateAction<string>>;
  getStandard: CourseStandard | undefined;
  setStandard: React.Dispatch<React.SetStateAction<CourseStandard | undefined>>;
  setSearchResults: React.Dispatch<
    React.SetStateAction<
      { searchData: SearchResults; searchVal: string } | undefined
    >
  >;
  skillcodes: SkillCodes;
}) => {
  const queryClient = useQueryClient();
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const {
    getActivated,
    setActivated,
    getStateCode,
    setStateCode,
    getStatus,
    setStatus,
    getStandard,
    setStandard,
    setSearchResults,
    skillcodes,
  } = props;
  const [showApproveConfirmation, setShowApproveConfirmation] = useState(false);
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showArchiveConfirmation, setShowArchiveConfirmation] = useState(false);

  // Get the list of state codes
  const { data: stateCodes } = useQuery<SelectInput>({
    queryKey: ["stateCodes"],
    queryFn: async () => {
      return axios
        .request({
          method: "get",
          url: deltamathAPI() + "/standards/ref/codes",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          return response.data.map((x: { code: string; fullName: string }) => ({
            key: x.code,
            val: x.fullName,
          }));
        })
        .catch((e) => {
          toastContext.addToast({ status: "Error", message: handleErr(e) });
        });
    },
    staleTime: 1000 * 60 * 15,
  });

  // Get the list of standards for a given state code and status
  const { data: standards } = useQuery<ListStandardsResponse[]>({
    queryKey: [
      "standards-list",
      `/standards/manage/${getStateCode}/${getStatus}`,
    ],
    queryFn: async () => {
      return axios
        .request({
          method: "get",
          url:
            deltamathAPI() + `/standards/manage/${getStateCode}/${getStatus}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((e) => {
          toastContext.addToast({ status: "Error", message: handleErr(e) });
        });
    },
    staleTime: 1000 * 60 * 15,
  });

  // Get a single standard, by id
  const { refetch: refetchStandard } = useQuery<CourseStandard>({
    queryKey: [`/standards/manage/${getActivated}`],
    queryFn: async () => {
      return axios
        .request({
          method: "get",
          url: deltamathAPI() + `/standards/manage/${getActivated}`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        })
        .then((response) => {
          setStandard(response.data);
          return response.data;
        })
        .catch((e) => {
          toastContext.addToast({ status: "Error", message: handleErr(e) });
        });
    },
    staleTime: 1000 * 60 * 15,
    enabled: false,
  });

  // Trigger the get standard useQuery, since it is undefined on load
  useEffect(() => {
    if (getActivated) {
      refetchStandard();
    }
  }, [getActivated]);

  // Initialize a standard on screen load
  useEffect(() => {
    if (standards && standards[0] && !getStandard) {
      setActivated(standards[0]._id);
    } else if (standards && standards.length === 0 && !getStandard) {
      setStandard(undefined);
    }
  }, [standards, getStandard]);

  // Clear the activated standard whenever state changes
  useEffect(() => {
    if (getStateCode !== getStandard?.code) {
      setStandard(undefined);
    }
  }, [getStateCode]);

  // Change the sort order of a standard
  const changeSort = async (_id: string, direction: 1 | -1) => {
    axios
      .request({
        method: "post",
        url: deltamathAPI() + `/standards/manage/order/${_id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: { direction: direction },
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [
            "standards-list",
            `/standards/manage/${getStateCode}/${getStatus}`,
          ],
        });
      })
      .catch((e) => {
        toastContext.addToast({ status: "Error", message: handleErr(e) });
      });
  };

  // Approve the standard for live use
  const approveStandard = async (id: string) => {
    axios
      .request({
        method: "put",
        url: deltamathAPI() + `/standards/approve/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [
            "standards-list",
            `/standards/manage/${getStateCode}/preview`,
          ],
        });
        queryClient.invalidateQueries({
          queryKey: [
            "standards-list",
            `/standards/manage/${getStateCode}/live`,
          ],
        });
        toastContext.addToast({
          status: "Success",
          message: "Approved",
        });
        refetchStandard();
        setSearchResults(undefined);
      })
      .catch((e) => {
        toastContext.addToast({ status: "Error", message: handleErr(e) });
      });
  };

  // Archive the standard for live use
  const archiveStandard = async (id: string) => {
    axios
      .request({
        method: "delete",
        url: deltamathAPI() + `/standards/approve/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [
            "standards-list",
            `/standards/manage/${getStateCode}/live`,
          ],
        });
        setStandard(undefined);
        setSearchResults(undefined);
      })
      .catch((e) => {
        toastContext.addToast({ status: "Error", message: handleErr(e) });
      });
  };

  // Delete the standard for live use
  const deleteStandard = async (id: string) => {
    axios
      .request({
        method: "delete",
        url: deltamathAPI() + `/standards/approve/${id}?force=true`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      })
      .then(() => {
        queryClient.invalidateQueries({
          queryKey: [
            "standards-list",
            `/standards/manage/${getStateCode}/preview`,
          ],
        });
        setStandard(undefined);
        setSearchResults(undefined);
      })
      .catch((e) => {
        toastContext.addToast({ status: "Error", message: handleErr(e) });
      });
  };

  // Download the xlsx version of the standard
  const downloadStandard = async (id: string) => {
    axios
      .request({
        method: "get",
        url: deltamathAPI() + `/standards/manage/download/xlsx/${id}`,
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        responseType: "blob",
      })
      .then((response) => {
        const file = response.data;
        const href = window.URL.createObjectURL(file);
        const link = document.createElement("a");
        link.href = href;
        link.setAttribute(
          "download",
          `${getStandard?.code} - ${getStandard?.name}.csv`
        );
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      })
      .catch((e) => {
        toastContext.addToast({ status: "Error", message: handleErr(e) });
      });
  };

  // Upload a new / modified standard to be loaded by the server
  const uploadStandard = async (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      axios
        .request<{ id: string; code: string }>({
          method: "put",
          url: deltamathAPI() + "/standards/manage/upload",
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${token}`,
          },
          data: formData,
        })
        .then((response) => {
          event.target.value = "";
          queryClient.invalidateQueries({
            queryKey: [
              "standards-list",
              `/standards/manage/${getStateCode}/preview`,
            ],
          });
          setActivated(response.data.id);
          setStateCode(response.data.code);
          setStatus("preview");
          toastContext.addToast({
            status: "Success",
            message: "Upload Complete",
          });
        })
        .catch((e) => {
          toastContext.addToast({ status: "Error", message: handleErr(e) });
          event.target.value = "";
        });
    }
  };

  return (
    <>
      {showApproveConfirmation && getStandard && (
        <DeltaMathConfirmation
          title="Approve Standard"
          message={
            <>
              Are you sure you want to approve{" "}
              <b>
                {getStandard.code} - {getStandard.name}
              </b>
              ?
            </>
          }
          confirm="Approve"
          confirmAction={() => {
            approveStandard(getStandard._id);
            setShowApproveConfirmation(false);
          }}
          cancel="Cancel"
          cancelAction={() => setShowApproveConfirmation(false)}
        />
      )}

      {showDeleteConfirmation && getStandard && (
        <DeltaMathConfirmation
          title="Delete Standard"
          message={
            <>
              Are you sure you want to delete{" "}
              <b>
                {getStandard.code} - {getStandard.name}
              </b>
              ?
            </>
          }
          confirm="Delete"
          confirmAction={() => {
            deleteStandard(getStandard._id);
            setShowDeleteConfirmation(false);
          }}
          cancel="Cancel"
          cancelAction={() => setShowDeleteConfirmation(false)}
        />
      )}

      {showArchiveConfirmation && getStandard && (
        <DeltaMathConfirmation
          title="Archive Standard"
          message={
            <>
              Are you sure you want to archive{" "}
              <b>
                {getStandard.code} - {getStandard.name}
              </b>
              ?
            </>
          }
          confirm="Archive"
          confirmAction={() => {
            archiveStandard(getStandard._id);
            setShowArchiveConfirmation(false);
          }}
          cancel="Cancel"
          cancelAction={() => setShowArchiveConfirmation(false)}
        />
      )}

      <div className="grid grid-cols-4 gap-2 pb-4 pt-4">
        <div className="border-solid border-black">
          <div>
            <input
              className="focus:border-primary focus:shadow-primary relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-xs font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:text-neutral-700 focus:shadow-[0_0_0_1px] focus:outline-none"
              type="file"
              onChange={uploadStandard}
            />
          </div>
          <div className="pt-3">
            {stateCodes && (
              <DeltaMathSelect
                label={"State"}
                options={stateCodes}
                value={getStateCode}
                onChangeFn={setStateCode}
              />
            )}
          </div>
          <div>
            {statusOptions && (
              <DeltaMathSelect
                label={"Status"}
                options={statusOptions}
                value={getStatus}
                onChangeFn={setStatus}
              />
            )}
          </div>
          <div className="pt-6">
            {standards && (
              <StandardsList
                standards={standards}
                activated={getActivated}
                onClickFn={(e: React.MouseEvent<HTMLDivElement>) =>
                  setActivated(e.currentTarget.id)
                }
                changeSort={changeSort}
              ></StandardsList>
            )}
          </div>
        </div>
        {getStandard && (
          <div className="col-span-3">
            <span className="flow-root">
              <div className="float-left">
                <Tooltip message="Download">
                  <button
                    type="button"
                    className="rounded-md focus:outline-none focus:ring-dm-darkest-blue"
                    onClick={() => downloadStandard(getStandard._id)}
                  >
                    <DownloadIcon className="h-5 w-5" aria-hidden="true" />
                  </button>
                </Tooltip>
              </div>
              <div className="float-right inline-flex">
                {getStandard.status === "preview" && (
                  <Tooltip message="Approve">
                    <button
                      type="button"
                      className="rounded-md focus:outline-none focus:ring-dm-darkest-blue"
                      onClick={() => setShowApproveConfirmation(true)}
                    >
                      <CheckCircleIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </Tooltip>
                )}
                {getStandard.status === "preview" && (
                  <Tooltip message="Delete">
                    <button
                      type="button"
                      className="rounded-md focus:outline-none focus:ring-dm-darkest-blue"
                      onClick={() => setShowDeleteConfirmation(true)}
                    >
                      <TrashIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </Tooltip>
                )}
                {getStandard.status === "live" && (
                  <Tooltip message="Archive">
                    <button
                      type="button"
                      className="rounded-md focus:outline-none focus:ring-dm-darkest-blue"
                      onClick={() => setShowArchiveConfirmation(true)}
                    >
                      <ArchiveIcon className="h-5 w-5" aria-hidden="true" />
                    </button>
                  </Tooltip>
                )}
              </div>
            </span>
            <div className="relative flex justify-center">
              <span className="bg-white px-2 text-sm text-gray-500">
                {getStandard.name}
              </span>
            </div>
            {skillcodes &&
              getStandard?.order.map((x: string) => (
                <StandardTree
                  key={x}
                  standard={getStandard?.data[x]}
                  depth={1}
                  path="data."
                  skillcodes={skillcodes}
                />
              ))}
          </div>
        )}
      </div>
    </>
  );
};

export default StandardsManage;
