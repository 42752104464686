import {
  ChangeEventHandler,
  FormEvent,
  useEffect,
  useRef,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { endOfDay, getTime } from "date-fns";
import Modal from "../../../student/components/generic/Modal";
import { uniqueId } from "lodash";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { deltamathAPI } from "../../utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { PriceScheme } from "./types";
import { Infotip } from "../../../shared/Infotip";

const DOE_BUCKETS: PriceScheme["bucketNYC_DOE"] = [
  { lbound: undefined, ubound: 750, plus: 0, integral: 0 },
  { lbound: 750, ubound: 1500, plus: 0, integral: 0 },
  { lbound: 1500, ubound: 2250, plus: 0, integral: 0 },
  { lbound: 2250, ubound: 3000, plus: 0, integral: 0 },
  { lbound: 3000, ubound: undefined, plus: 0, integral: 0 },
];

const BOCES_BUCKETS: PriceScheme["bucketNYS_BOCES"] = [
  { lbound: undefined, ubound: 750, plus: 0, integral: 0 },
  { lbound: 750, ubound: 1500, plus: 0, integral: 0 },
  { lbound: 1500, ubound: 2250, plus: 0, integral: 0 },
  { lbound: 2250, ubound: 3000, plus: 0, integral: 0 },
  { lbound: 3000, ubound: 3750, plus: 0, integral: 0 },
  { lbound: 3750, ubound: 4500, plus: 0, integral: 0 },
  { lbound: 4500, ubound: 5250, plus: 0, integral: 0 },
  { lbound: 5250, ubound: 6000, plus: 0, integral: 0 },
  { lbound: 6000, ubound: 6750, plus: 0, integral: 0 },
  { lbound: 6750, ubound: 7500, plus: 0, integral: 0 },
  { lbound: 7500, ubound: undefined, plus: 0, integral: 0 },
];

interface PricingBucketComponentProps {
  label: string;
  subHeader?: string;
  buckets: PriceScheme["bucketNYC_DOE"] | PriceScheme["bucketNYS_BOCES"];
  formData: any;
  handleBucketChange: (
    type: "NYC_DOE" | "NYS_BOCES",
    index: number,
    field: "plus" | "integral",
    value: number
  ) => void;
  groupKey: "NYC_DOE" | "NYS_BOCES";
}

const PricingBucketComponent: React.FC<PricingBucketComponentProps> = ({
  label,
  subHeader,
  buckets,
  formData,
  handleBucketChange,
  groupKey,
}) => {
  return (
    <PricingGroup label={label} subHeader={subHeader}>
      <div className="flex flex-col gap-4">
        {buckets.map((bucket, index) => (
          <div key={index} className="flex flex-row items-center gap-4">
            <span className="min-w-[150px] flex-1">{`Bucket ${index + 1}: ${
              bucket.lbound || 0
            } - ${bucket.ubound ?? "∞"}`}</span>
            <input
              type="number"
              value={formData[`bucket${groupKey}`][index].plus}
              onChange={(e) =>
                handleBucketChange(
                  groupKey,
                  index,
                  "plus",
                  Number(e.target.value)
                )
              }
              placeholder="Plus Price"
              className="w-24 rounded bg-white px-2 py-1 text-black"
            />
            <input
              type="number"
              value={formData[`bucket${groupKey}`][index].integral}
              onChange={(e) =>
                handleBucketChange(
                  groupKey,
                  index,
                  "integral",
                  Number(e.target.value)
                )
              }
              placeholder="Integral Price"
              className="w-24 rounded bg-white px-2 py-1 text-black"
            />
          </div>
        ))}
      </div>
    </PricingGroup>
  );
};

const PricingGroup: React.FC<{ label: string; subHeader?: string }> = ({
  label,
  subHeader,
  children,
}) => (
  <div className="flex flex-col gap-3 rounded bg-dm-charcoal-100 p-4">
    <h3 className="font-bold">{label}</h3>
    {subHeader && (
      <span className="-mt-4 text-sm text-dm-gray-200">{subHeader}</span>
    )}
    <div className="flex w-full gap-6">{children}</div>
  </div>
);

const StyledInput: React.FC<{
  label: string;
  value: number | string | undefined;
  disabled?: boolean;
  type?: "number" | "text";
  tooltip?: string;
  subHeader?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}> = ({ label, value, disabled, type, tooltip, onChange }) => {
  const id = uniqueId("input");
  if (type === undefined) {
    type = "number";
  }
  return (
    <div className="flex grow flex-col gap-1">
      <label htmlFor={id}>
        {tooltip && (
          <Infotip
            message={tooltip}
            options={{
              inline: true,
              tooltipRight: true,
            }}
          >
            <i className="far fa-info-circle cursor-pointer text-dm-gray-200" />
          </Infotip>
        )}
        {label}
      </label>
      <input
        className={`w-48 rounded px-2 py-1 ${
          disabled
            ? "cursor-not-allowed bg-gray-200 text-gray-500"
            : "bg-white text-black"
        }`}
        type={type}
        id={id}
        value={value}
        disabled={disabled}
        onChange={onChange}
      />
    </div>
  );
};

export const PriceSchemeModal: React.FC<{
  visible: boolean;
  selectedScheme?: Omit<PriceScheme, "status" | "default">;
  defaultScheme?: Omit<
    PriceScheme,
    "status" | "default" | "key" | "expiration" | "type"
  >;
  onClose: () => void;
  onSchemeCreated: () => void;
  onSchemeEdited: () => void;
}> = ({
  visible,
  selectedScheme,
  defaultScheme = {
    individual: { Plus: 0, Integral: 0 },
    per_student: { Plus: 0, Integral: 0 },
    minimum: { Plus: 0, Integral: 0 },
    bucketNYC_DOE: DOE_BUCKETS,
    bucketNYS_BOCES: BOCES_BUCKETS,
  },
  onClose,
  onSchemeCreated,
  onSchemeEdited,
}) => {
  const defaults: Omit<PriceScheme, "status"> = {
    ...defaultScheme,
    key: "",
    expiration: 0,
    discount_date: 0,
    proratedDiscount: undefined,
    type: "full_year",
  };
  const toastContext = useDeltaToastContext();
  const [isEditing, setIsEditing] = useState(false);
  const datePickerRef = useRef<DatePicker>(null);
  const [formData, setFormData] =
    useState<Omit<PriceScheme, "status" | "default">>(defaults);

  useEffect(() => {
    if (selectedScheme) {
      setFormData(selectedScheme);
      setIsEditing(true);
    } else {
      setIsEditing(false);
      setFormData(defaults);
    }
  }, [selectedScheme, defaultScheme]);

  const { mutateAsync: createScheme } = useMutation({
    mutationFn: () => {
      return axios.post(
        `${deltamathAPI()}/manager_new/price-scheme/create`,
        JSON.stringify(formData),
        withJsonHeader()
      );
    },
  });

  const { mutateAsync: editScheme } = useMutation({
    mutationFn: () => {
      return axios.put(
        `${deltamathAPI()}/manager_new/price-scheme/update`,
        JSON.stringify(formData),
        withJsonHeader()
      );
    },
  });

  const handleDateChange = (date: Date | null) => {
    if (date) {
      const endOfDayTimestamp = Math.floor(getTime(endOfDay(date)) / 1000);
      setFormData({ ...formData, expiration: endOfDayTimestamp });
    }
  };

  const handleDiscountDateChange = (date: Date | null) => {
    if (date) {
      const endOfDayTimestamp = Math.floor(getTime(endOfDay(date)) / 1000);
      setFormData({ ...formData, discount_date: endOfDayTimestamp });
    }
  };

  const handleBucketChange = (
    type: "NYC_DOE" | "NYS_BOCES",
    index: number,
    field: "plus" | "integral",
    value: number
  ) => {
    setFormData((prev) => {
      const updatedBuckets = [...prev[`bucket${type}`]];
      updatedBuckets[index][field] = value;
      return { ...prev, [`bucket${type}`]: updatedBuckets };
    });
  };

  const validateKey = (key: string): boolean => {
    const regex = /^\d{2}-\d{2}/;
    return regex.test(key);
  };

  const validateExpiration = (expiration: number): boolean => {
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return expiration > currentTimestamp;
  };

  const handleSubmit = async (e?: FormEvent) => {
    if (e) {
      e.preventDefault();
    }

    if (!validateKey(formData.key)) {
      toastContext.addToast({
        message: "Key must start with a string of the form YY-YY",
        status: "Error",
      });
      return;
    }

    if (!validateExpiration(formData.expiration)) {
      toastContext.addToast({
        message: "Expiration must be a future timestamp",
        status: "Error",
      });
      return;
    }

    try {
      if (isEditing) {
        await editScheme();
        onSchemeEdited();
      } else {
        await createScheme();
        onSchemeCreated();
      }
    } catch (e: any) {
      if (e.response.status === 400 && e.response.data instanceof Array) {
        e.response.data.forEach((error: any) => {
          toastContext.addToast({
            message: error.message,
            status: "Error",
          });
        });
      } else {
        toastContext.addToast({
          message: e.response.data.message || "Unable to create scheme",
          status: "Error",
        });
      }
    }
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title={isEditing ? "Update Price Scheme" : "Create New Price Scheme"}
      confirmButtonText={isEditing ? "Update" : "Create"}
      onConfirm={handleSubmit}
      confirmDisabled={false} // Set this to true if you want to disable the confirm button conditionally
      closeX
      body={
        <div>
          <form className="flex flex-col gap-6" onSubmit={handleSubmit}>
            <div className="flex w-full gap-6 px-4">
              <StyledInput
                label="Display Name"
                value={formData.key}
                type="text"
                disabled={isEditing}
                onChange={(e) =>
                  setFormData({ ...formData, key: e.target.value })
                }
              />
              <div className="flex grow flex-col gap-1">
                <label htmlFor="type">Type</label>
                <select
                  id="type"
                  name="type"
                  value={formData.type}
                  disabled={isEditing}
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      type: e.target.value as "pro_rated" | "full_year",
                    })
                  }
                  className={`w-48 rounded px-2 py-1 ${
                    isEditing
                      ? "cursor-not-allowed bg-gray-200 text-gray-500"
                      : "bg-white text-black"
                  }`}
                >
                  <option value="full_year">Full Year</option>
                  <option value="pro_rated">Pro Rated</option>
                </select>
              </div>
            </div>
            <div className="flex w-full gap-6 px-4">
              <StyledInput
                label="Prorated Discount"
                value={formData.proratedDiscount}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    proratedDiscount: e.target.value
                      ? +e.target.value
                      : undefined,
                  })
                }
                tooltip="Write the percentage off as a decimal. (Eg: for a license prorated 60%, enter 0.4 into this box)"
              />
              <div className="flex grow flex-col gap-1">
                <div className="flex items-center">
                  <DatePicker
                    className="w-48"
                    ref={datePickerRef}
                    onChange={handleDateChange}
                    customInput={
                      <div className="flex grow flex-col gap-1">
                        <label>License Expiration Date</label>
                        <input
                          className="w-48 rounded px-2 py-1"
                          style={{
                            padding: "4px 8px",
                            border: "1px solid #6B7280",
                            fontSize: "1rem",
                            lineHeight: "1.5rem",
                          }}
                          value={formData.expiration}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              expiration: +e.target.value,
                            })
                          }
                        />
                      </div>
                    }
                  />
                </div>
              </div>
            </div>
            {formData.type === "pro_rated" && (
              <div className="flex w-full gap-6 px-4">
                <div className="flex grow flex-col gap-1">
                  <div className="flex items-center">
                    <DatePicker
                      className="w-48"
                      ref={datePickerRef}
                      onChange={handleDiscountDateChange}
                      customInput={
                        <div className="flex grow flex-col gap-1">
                          <label>Discount Effective Date</label>
                          <input
                            className="w-48 rounded px-2 py-1"
                            style={{
                              padding: "4px 8px",
                              border: "1px solid #6B7280",
                              fontSize: "1rem",
                              lineHeight: "1.5rem",
                            }}
                            value={formData.discount_date}
                            onChange={(e) =>
                              setFormData({
                                ...formData,
                                discount_date: +e.target.value,
                              })
                            }
                          />
                        </div>
                      }
                    />
                  </div>
                </div>
              </div>
            )}

            <PricingGroup
              label="Individual"
              subHeader={
                formData.type === "pro_rated"
                  ? "Enter the NEW rounded prorated price"
                  : undefined
              }
            >
              <StyledInput
                label="Plus"
                value={formData.individual.Plus}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    individual: {
                      ...formData.individual,
                      Plus: +e.target.value,
                    },
                  })
                }
              />
              <StyledInput
                label="Integral"
                value={formData.individual.Integral}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    individual: {
                      ...formData.individual,
                      Integral: +e.target.value,
                    },
                  })
                }
              />
            </PricingGroup>
            <PricingGroup
              label="Per Student"
              subHeader={
                formData.type === "pro_rated"
                  ? "Enter the CURRENT price per student"
                  : undefined
              }
            >
              <StyledInput
                label="Plus"
                value={formData.per_student.Plus}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    per_student: {
                      ...formData.per_student,
                      Plus: +e.target.value,
                    },
                  })
                }
              />
              <StyledInput
                label="Integral"
                value={formData.per_student.Integral}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    per_student: {
                      ...formData.per_student,
                      Integral: +e.target.value,
                    },
                  })
                }
              />
            </PricingGroup>
            <PricingGroup
              label="Minimum"
              subHeader={
                formData.type === "pro_rated"
                  ? "Enter the NEW minimum prices"
                  : undefined
              }
            >
              <StyledInput
                label="Plus"
                value={formData.minimum.Plus}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    minimum: { ...formData.minimum, Plus: +e.target.value },
                  })
                }
              />
              <StyledInput
                label="Integral"
                value={formData.minimum.Integral}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    minimum: { ...formData.minimum, Integral: +e.target.value },
                  })
                }
              />
            </PricingGroup>

            <PricingBucketComponent
              label="NYC DOE Pricing"
              subHeader="Enter the plus and integral prices for each bucket"
              buckets={DOE_BUCKETS}
              formData={formData}
              handleBucketChange={handleBucketChange}
              groupKey="NYC_DOE"
            />

            <PricingBucketComponent
              label="NYS BOCES Pricing"
              subHeader="Enter the plus and integral prices for each bucket"
              buckets={BOCES_BUCKETS}
              formData={formData}
              handleBucketChange={handleBucketChange}
              groupKey="NYS_BOCES"
            />

            <button className="hidden" type="submit">
              Submit
            </button>
          </form>
        </div>
      }
    />
  );
};
