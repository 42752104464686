import { useEffect, useMemo, useState } from "react";
import axios from "axios";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Column } from "react-table";
import {
  EXPIRATION_JANUARY_2025,
  EXPIRATION_OCTOBER_2024,
  deltamathAPI,
} from "../utils";
import { PlusSmIcon as PlusSmIconSolid } from "@heroicons/react/solid";
import { formatNumber } from "../utils";
import DeltaMathTable, { MultiCheckFilter } from "../../shared/DeltaMathTable";
import { useDMQuery } from "../../utils";
import { TextFilterNoPersist } from "../../shared/table/filters";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { useDocumentTitle } from "usehooks-ts";

export const Upgrade = (props: any) => {
  const queryClient = useQueryClient();

  const [showConfirmUpgrade, setShowConfirmUpgrade] = useState(false);
  interface UpgradeTrialInterface {
    nces_id: string;
    site_type: string;
  }
  const mutation = useMutation({
    mutationFn: (upgradeTrialProps: UpgradeTrialInterface) => {
      return axios.post(
        deltamathAPI() + "/manager_new/license/trial_upgrade",
        JSON.stringify({
          customer_service_token: localStorage.getItem(
            "customer_service_token"
          ),
          nces_id: upgradeTrialProps.nces_id,
          site_type: upgradeTrialProps.site_type,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["site-info"] });
      queryClient.invalidateQueries({ queryKey: ["license_info"] });
    },
  });

  return (
    <>
      {props.cell.value === "6-12" ? (
        <div>{props.cell.value}</div>
      ) : mutation.isPending ? (
        <div>Loading...</div>
      ) : showConfirmUpgrade ? (
        <div>
          <span>{props.cell.value}</span>{" "}
          <span>
            {mutation.isError ? (
              <div
                className="relative rounded border border-red-400 bg-red-100 px-4 py-3 text-red-700"
                role="alert"
              >
                <strong className="font-bold">An error occurred.</strong>
              </div>
            ) : null}

            {mutation.isSuccess ? (
              <div
                className="relative rounded border border-green-400 bg-green-100 px-4 py-3 text-green-700"
                role="alert"
              >
                <strong className="font-bold">Trial upgraded!</strong>
              </div>
            ) : null}
          </span>
          <button
            type="button"
            onClick={() =>
              mutation.mutate({
                nces_id: props.cell.row.values.nces_id,
                site_type: props.cell.row.values.type,
              })
            }
            className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            Confirm
          </button>{" "}
        </div>
      ) : (
        <div>
          <span>{props.cell.value}</span>{" "}
          {props.cell.row.values.nces_id && props.cell.row.values.type && (
            <button
              type="button"
              onClick={() => setShowConfirmUpgrade(true)}
              className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
            >
              Upgrade
            </button>
          )}
        </div>
      )}
    </>
  );
};

export const ExtendLicense = (props: any) => {
  type Extension =
    | ""
    | "10/01"
    | "10/15"
    | "11/01"
    | "11/15"
    | "12/01"
    | "12/15"
    | "01/01";

  const queryClient = useQueryClient();
  const toastContext = useDeltaToastContext();

  const [extensionDate, setExtensionDate] = useState<Extension>("");

  const mutation = useMutation({
    mutationFn: (extendProps: {
      nces_id: string;
      type: "district" | "school";
      selection: Extension;
    }) => {
      return axios.post(
        deltamathAPI() + "/manager_new/license/extend",
        JSON.stringify({
          customer_service_token: localStorage.getItem(
            "customer_service_token"
          ),
          nces_id: extendProps.nces_id,
          type: extendProps.type,
          selection: extensionDate,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    },
    onSuccess: () => {
      toastContext.addToast({
        message: `You've Extended ${props.cell.row.values.org_name}'s License`,
        status: "Success",
      });
      queryClient.invalidateQueries({ queryKey: ["site-info"] });
      queryClient.invalidateQueries({ queryKey: ["license_info"] });
      setExtensionDate("");
    },
    onError: (e: any) => {
      toastContext.addToast({
        message:
          e.response?.data?.message ||
          `There has been an error extending ${props.cell.row.values.org_name}'s License`,
        status: "Error",
      });
    },
  });

  const expiration = props.cell.row.original?.dmLicense?.expiration;
  if (
    Math.floor(expiration) < EXPIRATION_OCTOBER_2024 ||
    Math.floor(expiration) > EXPIRATION_JANUARY_2025
  ) {
    return <></>;
  }

  return (
    <>
      <select
        className="mx-2 rounded text-xs"
        id="extension-date"
        value={extensionDate}
        onChange={(e) => setExtensionDate(e.target.value as Extension)}
        key={props.cell.row.values.nces_id + extensionDate}
      >
        <option value="">Select a date</option>
        <option value="10/01">Oct. 1, 2024</option>
        <option value="10/15">Oct. 15, 2024</option>
        <option value="11/01">Nov. 1, 2024</option>
        <option value="11/15">Nov. 15, 2024</option>
        <option value="12/01">Dec. 1, 2024</option>
        <option value="12/15">Dec. 15, 2024</option>
        <option value="01/01">Jan. 1, 2025</option>
      </select>
      <button
        type="button"
        className="ml-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        onClick={() => {
          if (extensionDate !== "") {
            mutation.mutate({
              nces_id: props.cell.row.values.nces_id,
              type: props.cell.row.values.type.toLowerCase(),
              selection: extensionDate,
            });
          } else {
            toastContext.addToast({
              message: `Please select an extension date`,
              status: "Error",
            });
          }
        }}
      >
        Extend License
      </button>
    </>
  );
};

export const AddIntegration = (props: any) => {
  const queryClient = useQueryClient();

  const [showAddIntegration, setShowAddIntegration] = useState<boolean>(false);
  const [integrationToAdd, setIntegrationToAdd] = useState<string>("");
  interface AddIntegrationInterface {
    integrations: string;
    admin_email: string;
  }
  const mutation = useMutation({
    mutationFn: (addIntegrationProps: AddIntegrationInterface) => {
      return axios.post(
        deltamathAPI() + "/manager_new/license/add_integrations",
        JSON.stringify({
          customer_service_token: localStorage.getItem(
            "customer_service_token"
          ),
          integrations: addIntegrationProps.integrations,
          admin_email: addIntegrationProps.admin_email,
        }),
        {
          headers: { "Content-Type": "application/json" },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["site-info"] });
      setShowAddIntegration(false);
    },
  });

  return (
    <>
      {props.cell.value ? (
        props.cell.value
      ) : mutation.isPending ? (
        <div>Loading...</div>
      ) : showAddIntegration ? (
        <div className="inline-flex">
          <input
            type="text"
            placeholder="Add"
            onChange={(e) => setIntegrationToAdd(e.target.value)}
            className="inline-flex rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
          />
          <button
            type="button"
            onClick={() =>
              mutation.mutate({
                integrations: integrationToAdd,
                admin_email: props.row.values.admin_email,
              })
            }
            className="m-2 inline-flex items-center rounded-full border border-transparent bg-indigo-600 p-1 text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
          >
            <PlusSmIconSolid className="h-5 w-5" aria-hidden="true" />
          </button>{" "}
        </div>
      ) : (
        <button
          type="button"
          onClick={() => {
            setShowAddIntegration(true);
          }}
          className="inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Add
        </button>
      )}
      {mutation.isSuccess ? (
        <div
          className="relative mt-1 rounded border border-green-400 bg-green-100 px-4 py-1 text-green-700"
          role="alert"
        >
          <strong className="font-bold">Integration added!</strong>
        </div>
      ) : null}
      {mutation.isError ? (
        <div
          className="relative mt-1 rounded border border-red-400 bg-red-100 px-4 py-1 text-red-700"
          role="alert"
        >
          <strong className="font-bold">An error occurred.</strong>
        </div>
      ) : null}
    </>
  );
};

export interface DataInterface {
  admin_email: string;
  admin_name: string;
  all_time_logs: number;
  dmLicense: {
    emailDomains: any[];
    num_students: number;
    invoice: number;
    revenue: number;
    expiration: number;
  };
  nces_id: string;
  num_admins: number;
  num_schools: number;
  num_teachers: number;
  org_name: string;
  type: string;
}

export interface LicenseCountInterface {
  name: number;
}
export interface LicenseCountArrayInterface {
  counts: Array<LicenseCountInterface>;
}

export default function SiteInfo() {
  useDocumentTitle("Site Info");
  //TODO: convert to object[] to clean up HTML below
  const [totals, setTotals] = useState({
    num_schools: 0,
    num_admins: 0,
    num_teachers: 0,
    dmLicense: {
      integrations: 0,
      num_students: 0,
      revenue: 0,
    },
  });
  const [licenseCount, setLicenseCount] = useState({
    school: 0,
    "6-12": 0,
    "9-12": 0,
    "6-8": 0,
  });
  [{ school: 0 }, { "6_12": 0 }, { "9_12": 0 }];

  const [currentTableIndex, setCurrentTableIndex] = useState(0);

  const { isLoading, error, data } = useDMQuery({
    path: "/manager_new/manage/get-site-info",
    cacheKey: "site-info",
    queryOptions: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      // staleTime: 1000 * 60
    },
  });

  useEffect(() => {
    setLicenseCount(getLicenseCount(data, "dmLicense.license_type"));
    // setLicenseCounts(licenseCounts => [...licenseCounts, getLicenseCount(request.data, "dmLicense.license_type")]);
    setTotals({
      num_schools: getTotals(data, "num_schools"),
      num_admins: getTotals(data, "num_admins"),
      num_teachers: getTotals(data, "num_teachers"),
      dmLicense: {
        integrations: getTotals(data, "dmLicense.integrations"),
        num_students: getTotals(data, "dmLicense.num_students"),
        revenue: getTotals(data, "dmLicense.revenue"),
      },
    });
  }, [data]);

  const getLicenseCount = (
    data: DataInterface[],
    accessor: string | number
  ) => {
    /* eslint-disable prefer-const */
    let licenseCount = {
      school: 0,
      "6-12": 0,
      "9-12": 0,
      "6-8": 0,
    };
    if (Array.isArray(data)) {
      /* eslint-enable prefer-const*/
      data?.forEach((item: any) => {
        if (accessor === "dmLicense.license_type") {
          if (item["dmLicense"].license_type === "school") {
            licenseCount.school++;
          } else if (item["dmLicense"].license_type === "6-12") {
            licenseCount["6-12"]++;
          } else if (item["dmLicense"].license_type === "9-12") {
            licenseCount["9-12"]++;
          } else if (item["dmLicense"].license_type === "6-8") {
            licenseCount["6-8"]++;
          }
        }
      });
    }
    return licenseCount;
  };

  const getTotals = (data: any[], accessor: string | number) => {
    let total = 0;
    if (Array.isArray(data)) {
      data?.forEach((item: any) => {
        if (accessor === "dmLicense.integrations") {
          total += item["dmLicense"].integrations ? 1 : 0;
        } else if (accessor === "dmLicense.num_students") {
          total += item["dmLicense"].num_students || 0;
        } else if (accessor === "dmLicense.revenue") {
          total += item["dmLicense"].revenue || 0;
        } else total += item[accessor];
      });
    }
    return total;
  };

  const columns: Array<Column> = useMemo(
    () => [
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "NCES ID",
        accessor: "nces_id",
        Filter: TextFilterNoPersist,
      },
      {
        Header: "Name",
        accessor: "org_name",
        Filter: TextFilterNoPersist,
      },
      {
        Header: "Super Admin",
        accessor: "admin_name",
        Filter: TextFilterNoPersist,
      },
      {
        Header: "Admin Email",
        accessor: "admin_email",
        Filter: TextFilterNoPersist,
      },
      {
        Header: "# Schools",
        accessor: "num_schools",
        minWidth: 120,
      },
      {
        Header: "# Admins",
        accessor: "num_admins",
        minWidth: 120,
      },
      {
        Header: "# Teachers",
        accessor: "num_teachers",
        minWidth: 120,
      },
      {
        Header: "Integrations",
        accessor: "dmLicense.integrations",
        Cell: (props: any) => <AddIntegration {...props} />,
      },
      {
        Header: "Expiration",
        accessor: (row: any) => {
          return new Date(row.dmLicense.expiration * 1000).toLocaleDateString();
        },
        Cell: (props) => {
          return (
            <>
              <span>{props.value ? props.value : "-"}</span>
              <ExtendLicense {...props} />
            </>
          );
        },
        Filter: MultiCheckFilter,
        filter: "includesSome",
        minWidth: 400,
        sortType: "datetime",
      },
      {
        Header: "Revenue",
        accessor: "dmLicense.revenue",
        Cell: ({ cell: { value } }: { cell: { value: any } }) => (
          <>{value && <span>{`$${formatNumber(value)}`}</span>}</>
        ),
      },
      {
        Header: "Max Students",
        accessor: "dmLicense.num_students",
        minWidth: 130,
        Cell: ({ cell: { value } }: { cell: { value: any } }) => (
          <>{value && <span>{`${formatNumber(value)}`}</span>}</>
        ),
      },
      {
        Header: "Invoice",
        accessor: "dmLicense.invoice",
        Filter: TextFilterNoPersist,
      },
      {
        Header: "",
        accessor: "dmLicense.is_trial",
        Cell: (props: any) => <span className="hidden"></span>,
      },
      {
        Header: "License Tier",
        accessor: "dmLicense.has_integral",
        minWidth: 130,
        Cell: (props: any) => (
          <>
            {props.row.values["dmLicense.has_integral"] ? (
              <>Integral</>
            ) : (
              <>Plus</>
            )}
            {props.row.values["dmLicense.is_trial"] && <> (Trial)</>}
          </>
        ),
      },
      {
        Header: "License Type",
        accessor: "dmLicense.license_type",
        minWidth: 130,
        Cell: (props) => <Upgrade {...props} />,
      },
      {
        Header: "Notes",
        accessor: "dmLicense.note",
      },
    ],
    []
  );

  if (error) return <div>{`${error}`}</div>;
  if (data?.message) return <div>An error has occurred: {data.message}</div>;

  return (
    <>
      <div className="mx-4 px-2 md:px-0">
        <h1 className="py-4 text-2xl font-semibold text-gray-900 md:mx-2">
          Site Info
        </h1>
        {data && (
          <div>
            <dl className="grid grid-cols-1 divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow md:grid-cols-4 md:divide-x md:divide-y-0">
              <div key={"school"} className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">
                  {"School License"}
                </dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                    {formatNumber(licenseCount.school)}
                  </div>
                </dd>
              </div>
              <div key={"6_12"} className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">
                  {"Grades 6-12"}
                </dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                    {formatNumber(licenseCount["6-12"])}
                  </div>
                </dd>
              </div>
              <div key={"9_12"} className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">
                  {"Grades 9-12"}
                </dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                    {formatNumber(licenseCount["9-12"])}
                  </div>
                </dd>
              </div>
              <div key={"6_8"} className="px-4 py-5 sm:p-6">
                <dt className="text-base font-normal text-gray-900">
                  {"Grades 6-8"}
                </dt>
                <dd className="mt-1 flex items-baseline justify-between md:block lg:flex">
                  <div className="flex items-baseline text-2xl font-semibold text-indigo-600">
                    {formatNumber(licenseCount["6-8"])}
                  </div>
                </dd>
              </div>
            </dl>

            <dl className="mt-5 grid grid-cols-1 gap-5 sm:grid-cols-6">
              <div
                key={"admins"}
                className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
              >
                <dt className="truncate text-sm font-medium text-gray-500">
                  {"# Admins"}
                </dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {formatNumber(totals.num_admins)}
                </dd>
              </div>
              <div
                key={"num_schools"}
                className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
              >
                <dt className="truncate text-sm font-medium text-gray-500">
                  {"# Schools"}
                </dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {totals.num_schools}
                </dd>
              </div>
              <div
                key={"num_teachers"}
                className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
              >
                <dt className="truncate text-sm font-medium text-gray-500">
                  {"# Teachers"}
                </dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {formatNumber(totals.num_teachers)}
                </dd>
              </div>
              <div
                key={"integrations"}
                className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
              >
                <dt className="truncate text-sm font-medium text-gray-500">
                  {"# Integrations"}
                </dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {formatNumber(totals.dmLicense.integrations)}
                </dd>
              </div>
              <div
                key={"revenue"}
                className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
              >
                <dt className="truncate text-sm font-medium text-gray-500">
                  {"Total Revenue"}
                </dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {`$${formatNumber(totals.dmLicense.revenue)}`}
                </dd>
              </div>
              <div
                key={"num_students"}
                className="overflow-hidden rounded-lg bg-white px-4 py-5 shadow sm:p-6"
              >
                <dt className="truncate text-sm font-medium text-gray-500">
                  {"Max Students"}
                </dt>
                <dd className="mt-1 text-3xl font-semibold text-gray-900">
                  {formatNumber(totals.dmLicense.num_students)}
                </dd>
              </div>
            </dl>
          </div>
        )}

        <div className="mt-8 flex flex-col">
          <div className="-my-2 overflow-x-auto">
            <div className="inline-block min-w-full py-2 align-middle">
              <div className="overflow-hidden shadow-sm ring-1 ring-black ring-opacity-5">
                {isLoading && (
                  <div className="px-4 sm:px-6 lg:px-8">Loading...</div>
                )}
                {data && (
                  <DeltaMathTable
                    data={data}
                    columns={columns}
                    options={{
                      globalFilter: true,
                      autoResetFilters: false,
                      initialState: { pageIndex: currentTableIndex },
                    }}
                    setCurrentTableIndex={setCurrentTableIndex}
                  />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
