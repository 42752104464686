import { useEffect, useMemo, useState } from "react";
import {
  Category,
  Skill,
  SkillCodes,
} from "../../../manager/components/skill-codes/SkillCodes";
import Fuse from "fuse.js";
import lunr from "lunr";
import {
  lunrCustomTrimmerPlugIn,
  lunrNumberPlugIn,
  lunrSearch,
  whitelistPlugin,
  whitelistStopWords,
} from "./lunrUtils";
import {
  createScoreDistribution,
  findShortestBestList,
  fuseOptions,
} from "./fuseUtils";

type SearchResults = {
  categoryResults: Category[];
  skillResults: Skill[];
};

const initialSearchResults: SearchResults = {
  categoryResults: [],
  skillResults: [],
};

function generateFuseSearchResults(
  query: string,
  categorySearch: Fuse<Category>,
  skillSearch: Fuse<Skill>
): SearchResults {
  const categoryResults = categorySearch.search(query);
  const skillResults = skillSearch.search(query);

  const bestCategoryResults = findShortestBestList(
    categoryResults,
    1,
    [0.001, 0.1]
  );
  const bestSkillResults = findShortestBestList(
    skillResults,
    5,
    [0.001, 0.1, 0.2, 0.3, 0.4]
  );

  console.group(`FUSE Search Results for Query: ${query}`);
  console.group("Category Results");
  // const catScoreDistribution = createScoreDistribution(categoryResults);
  // console.log(`Score Distribution for Category:`);
  // console.log(catScoreDistribution);
  console.log(`Category Results`);
  console.log(bestCategoryResults);
  // console.log(`All Category Results`);
  // console.log(categoryResults);
  console.groupEnd();
  console.group("Skill Results");
  // const skillScoreDistribution = createScoreDistribution(skillResults);
  // console.log(`Score Distribution for Skills:`);
  // console.log(skillScoreDistribution);
  console.log(`Skill Results`);
  console.log(bestSkillResults);
  // console.log(`All Skill Results`);
  // console.log(skillResults);
  console.groupEnd();
  console.groupEnd();

  return {
    categoryResults: bestCategoryResults as Category[],
    skillResults: bestSkillResults as Skill[],
  };
}

function generateLunrSearchResults(
  query: string,
  categorySearch: lunr.Index,
  skillSearch: lunr.Index,
  categories: Category[],
  skills: Skill[]
): SearchResults {
  const lunrCatResults = lunrSearch(query, categorySearch);
  const lunrSkillResults = lunrSearch(query, skillSearch);

  const categoryResults = lunrCatResults.map((result) => {
    return categories.find(
      (category) => category.code === result.ref
    ) as Category;
  });
  const skillResults = lunrSkillResults.map((result) => {
    return skills.find((skill) => skill.__index === result.ref) as Skill;
  });

  console.group(`LUNR Search Results for Query: ${query}`);
  console.group("Category Results");
  console.log(`Category Results`);
  console.log(lunrCatResults);
  console.groupEnd();
  console.group("Skill Results");
  console.log(`Skill Results`);
  console.log(lunrSkillResults);
  console.groupEnd();
  console.groupEnd();

  return {
    categoryResults,
    skillResults,
  };
}

export function useSkillSearchResults(
  query: string,
  data: SkillCodes,
  allCategories: Category[],
  type: "fuse" | "lunr"
): SearchResults {
  const [results, setResults] = useState<SearchResults>(initialSearchResults);

  const allSkills = useMemo(
    () =>
      allCategories.flatMap((category) =>
        category.skills.map((skill) => {
          return {
            ...data.skills[skill],
            __index: skill,
          };
        })
      ),
    [allCategories, data.skills]
  );

  const categorySearch = useMemo(() => {
    if (type === "lunr") {
      return lunr(function () {
        this.use(lunrNumberPlugIn);
        this.use(lunrCustomTrimmerPlugIn);
        this.use(whitelistPlugin, whitelistStopWords);
        this.k1(1);
        this.b(0);
        this.ref("code");
        this.field("name");
        allCategories.forEach((category) => {
          this.add({
            code: category.code,
            name: category.name,
          });
        });
      });
    } else {
      return new Fuse(allCategories, { keys: ["name"], ...fuseOptions });
    }
  }, [allCategories, type]);

  const skillSearch = useMemo(() => {
    if (type === "lunr") {
      return lunr(function () {
        this.use(lunrNumberPlugIn);
        this.use(lunrCustomTrimmerPlugIn);
        this.use(whitelistPlugin, whitelistStopWords);
        this.k1(1);
        this.b(0);
        this.ref("__index");
        this.field("name");
        this.field("searchTags");
        allSkills.forEach((skill) => {
          this.add({
            __index: skill.__index,
            name: skill.name,
            searchTags: skill.searchTags?.join(" ") || "",
          });
        });
      });
    } else {
      return new Fuse(allSkills, {
        keys: ["name", "searchTags"],
        ...fuseOptions,
      });
    }
  }, [allSkills, type]);

  useEffect(() => {
    if (query.length > 0) {
      if (type === "lunr") {
        setResults(
          generateLunrSearchResults(
            query,
            categorySearch as lunr.Index,
            skillSearch as lunr.Index,
            allCategories,
            allSkills
          )
        );
      } else {
        setResults(
          generateFuseSearchResults(
            query,
            categorySearch as Fuse<Category>,
            skillSearch as Fuse<Skill>
          )
        );
      }
    } else {
      setResults({
        categoryResults: allCategories,
        skillResults: [],
      });
    }
  }, [
    categorySearch,
    skillSearch,
    query,
    data,
    allCategories,
    type,
    allSkills,
  ]);

  return results;
}
