import { useState } from "react";
import Button from "../../../student/components/generic/button";
import { getFilePath } from "../../../utils";
import { Learner } from "../../types";
import { DashboardImageCard } from "./DashboardImageCard";
import { LearnerStats } from "./LearnerStats";
import { RecentActivity } from "./RecentActivity";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import { withJsonHeader } from "../../../shared/axiosUtils";

export const DashboardForNewLearner: React.FC<{
  currentLearner: Learner;
  header: React.ReactElement;
}> = ({ currentLearner, header }) => {
  const toastContext = useDeltaToastContext();
  const invitedWithEmail = !!currentLearner.inviteCode;
  const first = currentLearner.first;

  const [wasSent, setWasSent] = useState(false);
  const { mutateAsync: sendGetStartedMutation, isPending } = useMutation({
    mutationFn: ({ learnerId }: { learnerId: string }) => {
      return axios.post<{ status: "success" | "error" }>(
        `${deltamathAPI()}/learner/parent/send-get-started`,
        JSON.stringify({ learnerId }),
        withJsonHeader()
      );
    },
  });

  const sendGetStarted = async () => {
    try {
      await sendGetStartedMutation({ learnerId: currentLearner._id });
      setWasSent(true);
      setTimeout(() => {
        setWasSent(false);
      }, 3000);
    } catch (error: any) {
      toastContext.addToast({
        message:
          error?.response?.data?.message ||
          "There was an error sending the email. Please try again.",
        status: "Error",
      });
    }
  };

  return (
    <>
      {header}
      <div className="flex flex-col gap-6">
        {invitedWithEmail ? (
          <DashboardImageCard
            imageSrcs={[
              getFilePath(
                "/images/learner/parent/dashboard/deltie-with-math-tools.png"
              ),
              getFilePath(
                "/images/learner/parent/dashboard/deltie-with-math-tools@2x.png"
              ),
            ]}
            imageAlt="Smiling Deltie with math tools"
            title={`Help ${first} get started!`}
            text={`Let's get ${first} started with their math journey on DeltaMath Home!`}
          >
            <Button
              className="text-[14px] md:max-w-[310px]"
              onClick={sendGetStarted}
              isLoading={isPending}
            >
              {wasSent ? "Sent!" : `Send ${first} an Email to Get Started`}
            </Button>
          </DashboardImageCard>
        ) : (
          <DashboardImageCard
            imageSrcs={[
              getFilePath(
                "/images/learner/parent/dashboard/deltie-glasses-with-math-tools.png"
              ),
              getFilePath(
                "/images/learner/parent/dashboard/deltie-glasses-with-math-tools@2x.png"
              ),
            ]}
            imageAlt="Deltie wearing glasses with math tools"
            title={`Waiting for ${first} to Start`}
            text={`Seems like ${first} hasn't started. Once they've started, you'll see their information here, on the Dashboard.`}
          />
        )}
        <div className="z-[7] order-1 grid grid-cols-1 gap-4 lg:grid-cols-2">
          <LearnerStats showEmptyState={true} />
          <RecentActivity currentLearnerId={currentLearner._id} />
        </div>
      </div>
    </>
  );
};
