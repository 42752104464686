import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import DeltaMathTable from "../../../shared/DeltaMathTable";
import { FeatureFlag, PartialFeatureFlag } from "./types";
import { deltamathAPI } from "../../utils";
import { BasicContentContainer } from "../BasicContentContainer";
import { ChangeEvent } from "react";
import { UsersCell } from "./UsersCell";

export const FeatureFlagsTable: React.FC<{
  flags: FeatureFlag[];
  refetchFlags: () => void;
  updateFlag: (flag: PartialFeatureFlag) => void;
}> = ({ flags, refetchFlags, updateFlag }) => {
  const { mutateAsync: deleteFlagMutation } = useMutation({
    mutationFn: (flagId: string) => {
      return axios.delete(`${deltamathAPI()}/feature-flags/delete`, {
        data: JSON.stringify({ flagId }),
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
  });

  const deleteFlag = async (flag: FeatureFlag) => {
    if (window.confirm(`Are you sure you want to delete '${flag.name}'?`)) {
      await deleteFlagMutation(flag._id);
      refetchFlags();
    }
  };

  const changeState = async (
    e: ChangeEvent<HTMLSelectElement>,
    flag: FeatureFlag
  ) => {
    const newState = e.target.value as FeatureFlag["state"];
    if (newState !== flag.state) {
      const message =
        newState === "active"
          ? `This will enable '${flag.name}' for all users listed under this flag.`
          : newState === "global"
          ? `This will enable '${flag.name}' for all users.`
          : `This will disable '${flag.name}' for all users.`;
      if (window.confirm(`${message} Are you sure you want to continue?`)) {
        await updateFlag({ ...flag, state: newState });
      }
    }
  };

  if (flags.length === 0) {
    return (
      <BasicContentContainer>
        <p>
          There are currently no feature flags. Create a new feature flag below.
        </p>
      </BasicContentContainer>
    );
  }

  return (
    <DeltaMathTable
      data={flags}
      columns={[
        {
          Header: "Name",
          accessor: "name",
          ...{ align: "left" },
          Cell: (props: any) => {
            const { name } = props.row.original;
            return <code>{name}</code>;
          },
        },
        {
          Header: "Users",
          accessor: "users",
          ...{ align: "left" },
          Cell: (props: any) => (
            <UsersCell flag={props.row.original} updateFlag={updateFlag} />
          ),
        },
        {
          Header: "Created",
          accessor: "createdAt",
          ...{ align: "left" },
          Cell: (props: any) => {
            const { createdAt } = props.row.original;
            return new Intl.DateTimeFormat("en-US", {
              dateStyle: "medium",
              timeStyle: "long",
            }).format(new Date(createdAt));
          },
        },
        {
          Header: "Updated",
          accessor: "updatedAt",
          ...{ align: "left" },
          Cell: (props: any) => {
            const { updatedAt } = props.row.original;
            return new Intl.DateTimeFormat("en-US", {
              dateStyle: "medium",
              timeStyle: "long",
            }).format(new Date(updatedAt));
          },
        },
        {
          Header: "State",
          accessor: "state",
          ...{ align: "left" },
          Cell: (props: any) => {
            const flag = props.row.original;
            const allStates: FeatureFlag["state"][] = [
              "disabled",
              "active",
              "global",
            ];
            return (
              <select
                className="rounded border-dm-charcoal-200 py-1 hover:border-dm-charcoal-500"
                value={flag.state}
                onChange={(e) => changeState(e, flag)}
              >
                {allStates.map((s) => (
                  <option key={s} value={s}>
                    {/* Capitalize the states */}
                    {s[0].toUpperCase() + s.slice(1)}
                  </option>
                ))}
              </select>
            );
          },
        },
        {
          Header: "Delete",
          accessor: "_id",
          ...{ align: "center" },
          Cell: (props: any) => {
            return (
              <button onClick={() => deleteFlag(props.row.original)}>
                <i className="far fa-times" />
              </button>
            );
          },
        },
      ]}
    />
  );
};
