import { useLayoutEffect } from "react";
import { Problem } from "../../learner/types";
import { setItemWithTimestamp, getItemWithExpiry } from "./handleLocalStorage";
import { useUserContext } from "../../shared/contexts/UserContext";

export const TTL = 1000 * 60 * 60 * 24 * 7; // 1 week

export const generateAnswerDataKey = (
  studentId: number | string,
  teacherAssignmentId: string,
  pid: string
) => `problemData_${studentId}_${teacherAssignmentId}_${pid}`;

export const useAnswerDataLocalStorage = (
  problem?: Pick<Problem, "unique_string" | "data">,
  teacherAssignmentId?: string
) => {
  const userContext = useUserContext();
  const studentId = userContext.getStudentId();

  /**
   * Note: useLayoutEffect is used here to ensure that the saveAnswerData function is called before the component unmounts.
   * A useEffect, by contrast, calls the cleanup function after the component is removed from the DOM.
   */
  useLayoutEffect(() => {
    return () => {
      const saveAnswerData = problem?.data?.data?.saveAnswerData;
      const pid = problem?.unique_string;
      const taId = teacherAssignmentId;

      if (typeof saveAnswerData === "function" && pid && taId && studentId) {
        const questionAnswerData = saveAnswerData();
        const key = generateAnswerDataKey(studentId, taId, pid);
        setItemWithTimestamp(key, questionAnswerData);
        updateAnswerDataKeys(key);
      }
    };
  }, [problem, studentId, teacherAssignmentId]);
};

const updateAnswerDataKeys = (key: string) => {
  const keys = localStorage.getItem("problemDataKeys");
  if (keys) {
    const keyArray = JSON.parse(keys);
    if (!keyArray.includes(key)) {
      keyArray.push(key);
      localStorage.setItem("problemDataKeys", JSON.stringify(keyArray));
    }
  } else {
    localStorage.setItem("problemDataKeys", JSON.stringify([key]));
  }
};

export const clearAllAnswerDataKeys = () => {
  const keys = localStorage.getItem("problemDataKeys");
  if (keys) {
    const keyArray = JSON.parse(keys);
    keyArray.forEach((key: string) => {
      localStorage.removeItem(key);
    });
    localStorage.removeItem("problemDataKeys");
  }
};

export const clearExpiredAnswerDataKeys = () => {
  const keys = localStorage.getItem("problemDataKeys");
  if (keys) {
    const keyArray = JSON.parse(keys);
    const newKeyArray = keyArray.filter((key: string) => {
      const value = getItemWithExpiry(key, TTL); // removes expired key
      if (value === null) {
        return false;
      }
      return true;
    });
    if (newKeyArray.length) {
      localStorage.setItem("problemDataKeys", JSON.stringify(newKeyArray));
    }
  }
};
