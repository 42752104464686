import { REACT_APP_MANAGER_LINK } from "../../../utils";
import { IFoundIndividualLicenseForInvoice } from "../../utils/quoteUtils";
import InvoicePageCard from "./InvoicePageCard";

const IndividualLicensesCard: React.FC<{
  licenseDetails: IFoundIndividualLicenseForInvoice[];
}> = ({ licenseDetails }) => {
  const createLicenseSummary = (license: IFoundIndividualLicenseForInvoice) => {
    return [
      { title: "Authorization Code", value: license.accessCode },

      { title: "Invoice", value: license.invoice || "Not Found" },
      {
        title: "Quote Number",
        value: license.quoteNumber || "Not Found",
        href: license.quoteNumber
          ? `${REACT_APP_MANAGER_LINK}/quote-lookup/${license.quoteNumber}`
          : "",
      },
      { title: "Tier", value: license.tier?.toUpperCase() || "Not Found" },
      { title: "Total Purchased", value: license.totalSubscriptions },
      { title: "Remaining Activations", value: license.subscriptionsRemaining },
      {
        title: "Expiration",
        value: new Date(license.expiration).toLocaleDateString(),
      },
      { title: "Contact", value: license.contact },
    ];
  };

  return (
    <InvoicePageCard title={`Current Individual License(s)`} fullWidth={false}>
      <div className="flex flex-row flex-wrap gap-1">
        {licenseDetails.map(
          (license: IFoundIndividualLicenseForInvoice, index: number) => {
            const licenseSummary = createLicenseSummary(license);
            return (
              <ul
                key={license.accessCode + index}
                className="rounded border-2 border-gray-300 p-1"
              >
                {licenseSummary.map((detail, index) => (
                  <li key={"license" + index} className="flex gap-2">
                    <span>{detail.title}:</span>
                    {!detail.href && (
                      <span className="font-bold">{detail.value}</span>
                    )}
                    {detail.href && (
                      <a
                        href={detail.href}
                        target="_blank"
                        rel="noreferrer"
                        className="font-bold"
                      >
                        {detail.value}
                      </a>
                    )}
                  </li>
                ))}
              </ul>
            );
          }
        )}
      </div>
    </InvoicePageCard>
  );
};

export default IndividualLicensesCard;
