import { useQuery } from "@tanstack/react-query";
import { executeQuery } from "../../utils";
import { Learner } from "../types";

/** Query to fetch learners for the current parent user */
export function useChildLearnersQuery() {
  return useQuery<{ learners: Learner[] }>({
    queryKey: ["/learner/parent/my-learners"],
    queryFn: () =>
      executeQuery({
        path: "/learner/parent/my-learners",
      }),
    enabled: false,
  });
}
