import Button from "../../../student/components/generic/button";
import {
  FormattedLearnerSubscriptionPlan,
  LearnerSubscriptionDetails,
} from "../../types";
import { useState } from "react";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { REACT_APP_LEARNER_LINK } from "../../../utils";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { AddLearnerModal } from "./AddLearnerModal";

export const AddLearnerButton: React.FC<{
  subscription: LearnerSubscriptionDetails;
  plan: FormattedLearnerSubscriptionPlan;
  className?: string;
  outline?: boolean;
}> = ({ subscription, plan, className = "", outline = false }) => {
  const navigate = useNavigate();
  const learnerContext = useLearnerContext();
  const [showConfirmModal, setShowConfirmModal] = useState(false);

  const { mutateAsync: changeQuantityMutation } = useMutation({
    mutationFn: (quantity: number) => {
      return axios.post(
        `${deltamathAPI()}/payments/subscriptions/update/quantity`,
        JSON.stringify({ quantity }),
        withJsonHeader()
      );
    },
  });

  const handleConfirmChangeQuantity = async (newQuantity: number) => {
    await changeQuantityMutation(newQuantity);
    learnerContext.updateLearner({ subscriptionQuantity: newQuantity });
    navigate(`${REACT_APP_LEARNER_LINK}/parent/learners/add`);
  };

  return (
    <>
      <Button
        className={clsx(
          "flex h-10 items-center justify-center gap-2 p-0",
          className
        )}
        type={outline ? "outline" : undefined}
        onClick={() => setShowConfirmModal(true)}
      >
        <i className="far fa-plus-circle text-xl" aria-hidden />
        Add New Learner
      </Button>
      <AddLearnerModal
        visible={showConfirmModal}
        onClose={() => setShowConfirmModal(false)}
        subscription={subscription}
        plan={plan}
        onConfirm={(q) => handleConfirmChangeQuantity(q)}
      />
    </>
  );
};
