import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { deltamathAPI } from "../../utils";
import { useActivationContext } from "./LicenseActivationContext";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import Button, {
  Props as IButtonProps,
} from "../../../student/components/generic/button";
import { Fragment, useEffect, useState } from "react";
import { DMConfirmModal } from "../../../shared/DMModal/DMConfirmModal";
import clsx from "clsx";
import { isBefore } from "date-fns";
import { DmLoadingSpinner } from "../../utils/functions";
import DoubleActivationCheck from "./DoubleActivationCheck";
import { isSNNCase } from "../../utils/quoteUtils";
import DowngradeCheck from "./DowngradeCheck";

/**
 * Actual element here is just a button, but this component is also meant to encapsulate any logic for safe-guarding the activation process.
 */
const Activate: React.FC = () => {
  const activationContext = useActivationContext();
  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();
  const [showActivateModal, setShowActivateModal] = useState(false);
  const [areBypassableWarnings, setAreBypassableWarnings] = useState(false);
  const [activationDisabled, setActivationDisabled] = useState(false);

  //props for DoubleActivationCheck - here to centralize button disable logic. These specficially capture warnings where the current quote may be over-riding an existing license
  const [exactMatch, setExactMatch] = useState<boolean>(false);
  const [schoolMatch, setSchoolMatch] = useState<boolean>(false);
  const [districtMatch, setDistrictMatch] = useState<boolean>(false);
  const [districtToSchool, setDistrictToSchool] = useState<
    "warn" | "disable" | ""
  >("");

  //props for DowngradeCheck - here to centralize button disable logic. These differ from the above in that this quote is a RENEWAL (i.e. it is extending expiration for a cusotmer) and won't overwrite an existing license, but the customer is in come way downgrading relative to their last license and we want to make sure the user has checked prior to activating the liceense
  const [isTierDowngrade, setIsTierDowngrade] = useState<boolean>(false);
  const [isDistScopeDowngrade, setIsDistScopeDowngrade] =
    useState<boolean>(false);
  const [isDistToSchool, setIsDistToSchool] = useState<boolean>(false);

  const [onlyZachCanActivate, setOnlyZachCanActivate] =
    useState<boolean>(false);

  const { mutate: activateLicense, isPending: activationPending } = useMutation(
    {
      mutationFn: () => {
        return axios.post(
          deltamathAPI() +
            `/manager_new/invoices/activate/${activationContext.state.quoteNumber}`
        );
      },
      onSuccess: () => {
        toastContext.addToast({
          status: "Success",
          message: "License activated successfully",
        });
      },
      onError: (error: AxiosError) => {
        toastContext.addToast({
          status: "Error",
          message:
            (error.response?.data as any)?.message ||
            error.message ||
            "Error activating license: " + JSON.stringify(error),
        });
      },
      onMutate: () => {
        setShowActivateModal(false);
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: ["invoice", activationContext.state.quoteNumber],
        });
      },
      retry: false,
    }
  );

  const handleActivateClick = () => {
    setShowActivateModal(true);
  };

  const confirmButtonProps: Omit<IButtonProps, "children" | "onClick"> = {
    disabled: activationDisabled || activationPending,
    className: "disabled:bg-red-600",
  };

  //catchall useEffect to decide if activate button should be yellow - can activate but user should double check
  useEffect(() => {
    if (
      activationContext.state.unsavedChanges?.size ||
      isTierDowngrade ||
      isDistScopeDowngrade ||
      isDistToSchool
    ) {
      setAreBypassableWarnings(true);
    } else {
      setAreBypassableWarnings(false);
    }
  }, [
    activationContext.state.unsavedChanges,
    isTierDowngrade,
    isDistScopeDowngrade,
    isDistToSchool,
  ]);

  useEffect(() => {
    if (
      activationContext.invoiceDetails?.cleverDistrictId ===
      "54d263946cc968010000007a"
    ) {
      setOnlyZachCanActivate(true);
    }
  }, [activationContext.invoiceDetails?.cleverDistrictId]);

  //large as this useEffect is, I think to cut down on render cycles it makes sense to do all the checking in one go. While the activationDisabledReasons is a piece of state (which I want to keep as state for now since I can imagine a world where we want to do the checking dynamically as values are updated in the UI), the data being used for the checks is static (for now) in the sense that it's currently based on what comes in from the API call.
  useEffect(() => {
    if (
      activationContext.invoiceDetails &&
      activationContext.invoiceDetails?.status !== "Activated"
    ) {
      const activateDisableReasons = new Set<string>();
      if (
        !activationContext.invoiceDetails?.adminFirst ||
        (!activationContext.invoiceDetails?.adminLast &&
          activationContext.invoiceDetails?.type !== "individual") ||
        !activationContext.invoiceDetails?.adminEmail
      ) {
        activateDisableReasons.add("admin");
      }

      if (
        activationContext.qboEstimateData?.totalQuotePrice !==
        activationContext.invoiceDetails?.amount
      ) {
        activateDisableReasons.add("price");
      }

      if (
        activationContext.invoiceDetails?.type === "individual" &&
        !activationContext.invoiceDetails?.priceInfo.numberOfTeachers
      ) {
        activateDisableReasons.add("individualCodes");
      }

      if (!activationContext.invoiceDetails?.expiration) {
        activateDisableReasons.add("expiration");
      }

      if (
        activationContext.invoiceDetails?.status !==
          "CC/Check Payment Received" &&
        activationContext.invoiceDetails?.status !== "PO Received (Good)"
      ) {
        activateDisableReasons.add("status");
      }

      if (
        (activationContext.invoiceDetails?.status === "PO Received (Good)" ||
          activationContext.invoiceDetails?.status ===
            "PO Received (Needs Updating)") &&
        !(
          activationContext.invoiceDetails?.poUploadedTime &&
          activationContext.invoiceDetails?.poNumber
        )
      ) {
        activateDisableReasons.add("purchaseOrder");
      }

      if (
        isSNNCase({
          type: activationContext.invoiceDetails?.type,
          id: activationContext.invoiceDetails?.id,
        }) &&
        !(
          activationContext.invoiceDetails?.snnLicenseType === "new" ||
          activationContext.invoiceDetails?.snnLicenseType === "renewal"
        )
      ) {
        activateDisableReasons.add("snnType");
      }

      if (
        (isSNNCase({
          type: activationContext.invoiceDetails?.type,
          id: activationContext.invoiceDetails?.id,
        }) &&
          !activationContext.invoiceDetails?.id) ||
        (parseInt(activationContext.invoiceDetails?.id || "0") <= 0 &&
          activationContext.invoiceDetails?.snnLicenseType === "renewal")
      ) {
        activateDisableReasons.add("snnID");
      }

      if (
        (isSNNCase({
          type: activationContext.invoiceDetails?.type,
          id: activationContext.invoiceDetails?.id,
        }) &&
          !activationContext.invoiceDetails?.id) ||
        (parseInt(activationContext.invoiceDetails?.id || "0") <= 0 &&
          activationContext.invoiceDetails?.snnLicenseType === "new")
      ) {
        activateDisableReasons.add("snnDetails");
      }

      if (!activationContext.invoiceDetails?.id) {
        activateDisableReasons.add("noEntityID");
      }

      if (!activationContext.invoiceDetails?.name) {
        activateDisableReasons.add("noEntityName");
      }

      if (
        activationContext.invoiceDetails.licenseTier === "integral" &&
        (activationContext.invoiceDetails?.rosteringSystem ===
          "ClassLink/OneRoster" ||
          activationContext.invoiceDetails?.rosteringSystem === "Clever") &&
        activationContext.invoiceDetails?.integrationStatus !== "verified"
      ) {
        activateDisableReasons.add("rostering");
      }

      if (
        activationContext.invoiceDetails?.hold_until &&
        isBefore(
          new Date(),
          new Date(activationContext.invoiceDetails.hold_until * 1000)
        )
      ) {
        activateDisableReasons.add("hold");
      }

      if (!activationContext.invoiceDetails?.licenseTier) {
        activateDisableReasons.add("tier");
      }

      // Add in the checks for the rostering systems
      if (
        activationContext.invoiceDetails?.rosteringSystem === "Clever" &&
        !activationContext.invoiceDetails?.cleverDistrictId
      ) {
        activateDisableReasons.add("missingCleverTenant");
      }
      if (
        activationContext.invoiceDetails?.rosteringSystem ===
          "ClassLink/OneRoster" &&
        !activationContext.invoiceDetails?.classlinkTenantId
      ) {
        activateDisableReasons.add("missingClassLinkTenant");
      }
      if (
        (activationContext.invoiceDetails?.classlinkTenantId ||
          activationContext.invoiceDetails?.cleverDistrictId) &&
        activationContext.invoiceDetails?.integrationStatus !== "verified" &&
        activationContext.invoiceDetails.rosteringSystem
      ) {
        activateDisableReasons.add("missingRosteringVerification");
      }

      activationContext.setActivationDisableReasons(
        activateDisableReasons,
        "completeUpdate"
      );
    }
  }, [activationContext.invoiceDetails, activationContext.qboEstimateData]);

  //catchall useEffect to decide if activation should be prohibited
  useEffect(() => {
    if (
      !activationContext.invoiceDetails?.id ||
      onlyZachCanActivate ||
      (activationContext.state?.activationDisableReasons?.size || 0) > 0 ||
      exactMatch ||
      districtToSchool === "disable" ||
      activationContext.invoiceDetails?.status === "Activated"
    ) {
      setActivationDisabled(true);
    } else {
      setActivationDisabled(false);
    }
  }, [
    activationContext.invoiceDetails?.id,
    onlyZachCanActivate,
    activationContext.state?.activationDisableReasons,
    exactMatch,
    districtToSchool,
    activationContext.invoiceDetails?.status,
  ]);

  return (
    <Fragment>
      <div
        className="mb-4 flex flex-col gap-2"
        id="invoice-page-activate-license"
      >
        <div className="flex flex-row gap-4">
          <Button
            onClick={handleActivateClick}
            size={"large"}
            disabled={activationDisabled}
            className={clsx(
              areBypassableWarnings &&
                !activationDisabled &&
                "active:bg-dm-earth-yellow-700 bg-dm-earth-yellow-500 hover:bg-dm-earth-yellow-600",
              activationDisabled &&
                "bg-red-500 hover:bg-red-500 active:bg-red-500"
            )}
          >
            {!activationPending ? (
              "Activate License"
            ) : (
              <DmLoadingSpinner
                spinnerColor="white"
                message="Activating"
                className="text-white"
              />
            )}
          </Button>
        </div>
        <div className="flex flex-row flex-wrap items-start gap-4">
          <DoubleActivationCheck
            exactMatch={exactMatch}
            schoolMatch={schoolMatch}
            districtMatch={districtMatch}
            districtToSchool={districtToSchool}
            setExactMatch={setExactMatch}
            setSchoolMatch={setSchoolMatch}
            setDistrictMatch={setDistrictMatch}
            setDistrictToSchool={setDistrictToSchool}
          />
          {activationContext.invoiceDetails?.type &&
            (activationContext.licenseData?.district ||
              activationContext.licenseData?.school) && (
              <DowngradeCheck
                isTierDowngrade={isTierDowngrade}
                isDistScopeDowngrade={isDistScopeDowngrade}
                isDistToSchool={isDistToSchool}
                setIsTierDowngrade={setIsTierDowngrade}
                setIsDistScopeDowngrade={setIsDistScopeDowngrade}
                setIsDistToSchool={setIsDistToSchool}
              />
            )}
        </div>
      </div>
      <DMConfirmModal
        title="Activate License"
        visible={showActivateModal}
        onConfirm={() => activateLicense()}
        confirmText={"Activate"}
        onCancel={() => setShowActivateModal(false)}
        confirmButtonProps={confirmButtonProps}
        className="activation-modal"
      >
        {!activationDisabled && (
          <p>Are you sure you want to activate this license?</p>
        )}
        {activationContext.state.unsavedChanges &&
          activationContext.state.unsavedChanges.size > 0 && (
            <div className="text-dm-earth-yellow-600">
              The following cards have unsaved changes:
              <ul className="ml-8 list-disc">
                {Array.from(activationContext.state.unsavedChanges).map(
                  (cardTitle: string, index: number) => (
                    <li key={"unsaved" + index}>{cardTitle}</li>
                  )
                )}
              </ul>
            </div>
          )}
      </DMConfirmModal>
    </Fragment>
  );
};

export default Activate;
