import React, { useState, Fragment, useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import {
  MutateTeacher,
  GetDeletedAssignments,
  GetInfo,
  DeleteTeacher,
} from "../api/index";

import { getDatesArray } from "../utils";
import { DeltaMathSearchableTable } from "../../shared/DeltaMathSearchableTable";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { useQueryClient } from "@tanstack/react-query";
import { useDocumentTitle } from "usehooks-ts";
import Impersonate from "./Impersonate";
import { DMConfirmModal } from "../../shared/DMModal/DMConfirmModal";
import { AxiosResponse } from "axios";
import { set } from "lodash";

interface TeacherSearchPayload {
  first: string;
  last: string;
  email: string;
  teachercode: string;
  schoolinfo_name: string;
  schoolinfo_city: string;
  schoolinfo_state: string;
  schoolinfo_zip: string;
  annual_logs: number;
  all_time_logs: number;
  deltamath_plus_subscription: string;
  deltamath_plus_subscription_auth_code: string;
  schoolPlus: [];
  has_integral: boolean;
}
export interface APIPayload {
  first?: string;
  last?: string;
  email?: string;
  teachercode?: string;
  schoolinfo?: {
    name?: string;
    city?: string;
    state?: string;
    zip?: string;
  };
  deltamath_plus_subscription: string;
  deltamath_plus_subscription_auth_code: string;
}

interface MenuOptionsInterface {
  downgradeToFree: boolean;
  downgradeToPlus: boolean;
  upgradeToPlus: boolean;
  upgradeToIntegral: boolean;
  resetIpAddressList: boolean;
  undeleteAssignment: boolean;
  mergeAnotherAccount: boolean;
  deleteAccount: boolean;
}

const menuReducer = (state: MenuOptionsInterface, action: { type: any }) => {
  switch (action.type) {
    case "reset":
      return {
        downgradeToFree: false,
        downgradeToPlus: false,
        upgradeToPlus: false,
        upgradeToIntegral: false,
        resetIpAddressList: false,
        undeleteAssignment: false,
        mergeAnotherAccount: false,
        deleteAccount: false,
      };
    case "downgrade-to-free":
      return {
        downgradeToFree: true,
        downgradeToPlus: false,
        upgradeToPlus: false,
        upgradeToIntegral: false,
        resetIpAddressList: false,
        undeleteAssignment: false,
        mergeAnotherAccount: false,
        deleteAccount: false,
      };
    case "downgrade-to-plus":
      return {
        downgradeToFree: false,
        downgradeToPlus: true,
        upgradeToPlus: false,
        upgradeToIntegral: false,
        resetIpAddressList: false,
        undeleteAssignment: false,
        mergeAnotherAccount: false,
        deleteAccount: false,
      };
    case "upgrade-to-plus":
      return {
        downgradeToFree: false,
        downgradeToPlus: false,
        upgradeToPlus: true,
        upgradeToIntegral: false,
        resetIpAddressList: false,
        undeleteAssignment: false,
        mergeAnotherAccount: false,
        deleteAccount: false,
      };
    case "upgrade-to-integral":
      return {
        downgradeToFree: false,
        downgradeToPlus: false,
        upgradeToPlus: false,
        upgradeToIntegral: true,
        resetIpAddressList: false,
        undeleteAssignment: false,
        mergeAnotherAccount: false,
        deleteAccount: false,
      };
    case "reset-ip-address-list":
      return {
        downgradeToFree: false,
        downgradeToPlus: false,
        upgradeToPlus: false,
        upgradeToIntegral: false,
        resetIpAddressList: true,
        undeleteAssignment: false,
        mergeAnotherAccount: false,
        deleteAccount: false,
      };
    case "undelete-assignment":
      return {
        downgradeToFree: false,
        downgradeToPlus: false,
        upgradeToPlus: false,
        upgradeToIntegral: false,
        resetIpAddressList: false,
        undeleteAssignment: true,
        mergeAnotherAccount: false,
        deleteAccount: false,
      };
    case "merge-another-account":
      return {
        downgradeToFree: false,
        downgradeToPlus: false,
        upgradeToPlus: false,
        upgradeToIntegral: false,
        resetIpAddressList: false,
        undeleteAssignment: false,
        mergeAnotherAccount: true,
        deleteAccount: false,
      };
    case "delete-account":
      return {
        downgradeToFree: false,
        downgradeToPlus: false,
        upgradeToPlus: false,
        upgradeToIntegral: false,
        resetIpAddressList: false,
        undeleteAssignment: false,
        mergeAnotherAccount: false,
        deleteAccount: true,
      };
    default:
      throw new Error();
  }
};

export const Search = () => {
  useDocumentTitle("Teacher Search");
  const toastContext = useDeltaToastContext();
  const currentUnixTime = Math.floor(Date.now() / 1000);
  const queryClient = useQueryClient();
  const october = getDatesArray({})[0];
  const octoberUnix = Math.floor(october.getTime() / 1000.0); // convert to UNIX epoch
  const [viewingTeacher, setViewingTeacher] = useState<TeacherSearchPayload>({
    first: "",
    last: "",
    email: "",
    teachercode: "",
    schoolinfo_name: "",
    schoolinfo_city: "",
    schoolinfo_state: "",
    schoolinfo_zip: "",
    annual_logs: 0,
    all_time_logs: 0,
    deltamath_plus_subscription: "",
    deltamath_plus_subscription_auth_code: "",
    schoolPlus: [],
    has_integral: false,
  });
  const [payload, setPayload] = useState<TeacherSearchPayload>({
    first: "",
    last: "",
    email: "",
    teachercode: "",
    schoolinfo_name: "",
    schoolinfo_city: "",
    schoolinfo_state: "",
    schoolinfo_zip: "",
    annual_logs: 0,
    all_time_logs: 0,
    deltamath_plus_subscription: "",
    deltamath_plus_subscription_auth_code: "",
    schoolPlus: [],
    has_integral: false,
  });

  const restoreDefaults = () => {
    setHasSearched(false);
    setPayload({
      first: "",
      last: "",
      email: "",
      teachercode: "",
      schoolinfo_name: "",
      schoolinfo_city: "",
      schoolinfo_state: "",
      schoolinfo_zip: "",
      annual_logs: 0,
      all_time_logs: 0,
      deltamath_plus_subscription: "",
      deltamath_plus_subscription_auth_code: "",
      schoolPlus: [],
      has_integral: false,
    });
    setViewingTeacher({
      first: "",
      last: "",
      email: "",
      teachercode: "",
      schoolinfo_name: "",
      schoolinfo_city: "",
      schoolinfo_state: "",
      schoolinfo_zip: "",
      annual_logs: 0,
      all_time_logs: 0,
      deltamath_plus_subscription: "",
      deltamath_plus_subscription_auth_code: "",
      schoolPlus: [],
      has_integral: false,
    });
    data = [];
  };

  const [menuState, dispatch] = React.useReducer(menuReducer, {
    downgradeToFree: false,
    downgradeToPlus: false,
    upgradeToPlus: false,
    upgradeToIntegral: false,
    resetIpAddressList: false,
    undeleteAssignment: false,
    mergeAnotherAccount: false,
    deleteAccount: false,
  });

  const deleteSuccess = (data: {
    success?: string;
    deletedEmail?: string;
    adminId?: string;
  }) => {
    queryClient.invalidateQueries({ queryKey: ["search"] });
    refetch();
    setViewingTeacher({
      first: "",
      last: "",
      email: "",
      teachercode: "",
      schoolinfo_name: "",
      schoolinfo_city: "",
      schoolinfo_state: "",
      schoolinfo_zip: "",
      annual_logs: 0,
      all_time_logs: 0,
      deltamath_plus_subscription: "",
      deltamath_plus_subscription_auth_code: "",
      schoolPlus: [],
      has_integral: false,
    });

    if (data.success) {
      toastContext.addToast({
        status: "Success",
        message: data.success,
        dismiss: "manual",
        largeDismissButton: true,
      });
    }
  };

  const deleteError = (error: any) => {
    toastContext.addToast({
      status: "Error",
      message: `${error}`,
    });
  };

  const deleteAccount = DeleteTeacher(deleteSuccess, deleteError);

  const [revenue, setRevenue] = useState<number>(95);
  const [expiration, setExpiration] = useState<number>(octoberUnix);

  const {
    data: getDeletedData,
    isSuccess: getDeletedSuccess,
    error: getDeletedError,
    isError: getDeletedIsError,
    isFetching: getDeletedIsFetching,
    refetch: getDeletedRefetch,
  } = GetDeletedAssignments(viewingTeacher.teachercode || "");

  const invalidateGetDeletedAssignments = () => {
    queryClient.invalidateQueries({
      queryKey: [
        `/manager_new/manage/get_deleted_assignments/${
          viewingTeacher.teachercode || ""
        }`,
      ],
    });
  };

  const [assignmentToUndelete, setAssignmentToUndelete] = useState<number>(-1);
  const [newTeacherCode, setNewTeacherCode] = useState("");
  const [showMergeWithAdminWarning, setShowMergeWithAdminWarning] =
    useState<boolean>(false);

  const updateAfterAction = () => {
    refetch();
    setViewingTeacher({
      first: "",
      last: "",
      email: "",
      teachercode: "",
      schoolinfo_name: "",
      schoolinfo_city: "",
      schoolinfo_state: "",
      schoolinfo_zip: "",
      annual_logs: 0,
      all_time_logs: 0,
      deltamath_plus_subscription: "",
      deltamath_plus_subscription_auth_code: "",
      schoolPlus: [],
      has_integral: false,
    });
  };

  const mergeAccountCallback = (res: AxiosResponse<any, any>) => {
    if (res.data?.adminWarning) {
      setShowMergeWithAdminWarning(true);
      return;
    } else {
      updateAfterAction();
      setShowMergeWithAdminWarning(false);
    }
  };

  const removePlus = MutateTeacher(
    "remove-plus",
    "get-info",
    updateAfterAction
  );
  const downgradeToPlus = MutateTeacher(
    "downgrade-to-plus",
    "get-info",
    updateAfterAction
  );
  const addPlus = MutateTeacher("add-plus", "get-info");
  const upgradeToIntegral = MutateTeacher(
    "upgrade-to-integral",
    "get-info",
    updateAfterAction
  );
  const mergeAccounts = MutateTeacher(
    "merge-accounts",
    "get-info",
    mergeAccountCallback
  );
  const resetIPAddress = MutateTeacher(
    "reset-ip",
    "get-info",
    updateAfterAction
  );
  const undeleteAssignment = MutateTeacher(
    "undelete_deleted_assignment",
    "get-deleted",
    updateAfterAction
  );

  const searchParams = {
    first: payload?.first.trim(),
    last: payload?.last.trim(),
    email: payload?.email.trim(),
    teachercode: payload?.teachercode.trim(),
    city: payload?.schoolinfo_city.trim(),
    state: payload?.schoolinfo_state.trim(),
    zip: payload?.schoolinfo_zip.trim(),
    school: payload?.schoolinfo_name.trim(),
    access_code: payload?.deltamath_plus_subscription_auth_code.trim(),
  };

  /*eslint-disable prefer-const*/
  let { isLoading, error, data, refetch } = GetInfo(searchParams);

  const [hasSearched, setHasSearched] = useState(false);

  useEffect(() => {
    if (data && !Array.isArray(data)) {
      toastContext.addToast({
        status: "Error",
        message: data?.message,
      });
    }
  }, [data]);

  // This is poor error handling for the case that "{}" is returned from the API.
  if (!Array.isArray(data)) {
    data = [];
  }

  const handleSubmit = async (e: { preventDefault: () => void }) => {
    e.preventDefault();
    setHasSearched(true);
    if (Object.values(searchParams).join("").length === 0) {
      return;
    }
    await refetch();
    if (error) {
      console.error(error);
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "row_num",
        totalWidth: 4,
        render: ({ row }: { row: any }) => (
          <span key={`row#-${row.id}`}>#</span>
        ),
      },
      {
        Header: "First",
        accessor: "first",
        totalLeft: 4,
      },
      {
        Header: "Last",
        accessor: "last",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Teacher Code",
        accessor: "teachercode",
      },
      {
        Header: "School",
        accessor: "schoolinfo_name",
      },
      {
        Header: "City",
        accessor: "schoolinfo_city",
      },
      {
        Header: "State",
        accessor: "schoolinfo_state",
      },
      {
        Header: "Zip",
        accessor: "schoolinfo_zip",
      },
      {
        Header: "License Type",
        accessor: "licenseType",
      },
      {
        Header: "License Tier",
        accessor: "licenseTier",
      },
      {
        Header: "Expiration",
        accessor: "licenseExpiration",
      },
      {
        Header: "Access Code",
        accessor: "deltamath_plus_subscription_auth_code",
      },
      {
        id: "schoolPlus",
        Header: "schoolPlus",
        accessor: "schoolPlus",
        Cell: ({ row }: { row: any }) => (
          <span key={`schoolPlus-${row.id}`} className="hidden">
            {" "}
          </span>
        ),
      },
      {
        Header: "Annual Logs",
        accessor: "annual_logs",
      },
      {
        Header: "All Time Logs",
        accessor: "all_time_logs",
      },
    ],
    []
  );

  const selectTeacher = async (teacher: TeacherSearchPayload) => {
    dispatch({ type: "reset" });
    setViewingTeacher(teacher);
  };

  const modifyTeacher = (action: string) => {
    switch (action) {
      case "downgrade-to-free":
        dispatch({ type: "downgrade-to-free" });
        break;
      case "downgrade-to-plus":
        dispatch({ type: "downgrade-to-plus" });
        break;
      case "upgrade-to-plus":
        dispatch({ type: "upgrade-to-plus" });
        break;
      case "upgrade-to-integral":
        dispatch({ type: "upgrade-to-integral" });
        break;
      case "reset-ip-address-list":
        dispatch({ type: "reset-ip-address-list" });
        break;
      case "undelete-assignment":
        dispatch({ type: "undelete-assignment" });
        getDeletedRefetch();
        break;
      case "merge-another-account":
        dispatch({ type: "merge-another-account" });
        break;
      case "delete-account":
        dispatch({ type: "delete-account" });
        break;
    }
  };

  const licenseExpired = (viewingTeacher: TeacherSearchPayload) => {
    if (viewingTeacher.deltamath_plus_subscription) {
      return (
        parseInt(viewingTeacher.deltamath_plus_subscription) < currentUnixTime
      );
    } else return true;
  };

  if (error) return <div>{`An error has occurred: ${error}`}</div>;
  return (
    <>
      <div className="px-2 md:px-0">
        <h1 className="py-4 text-2xl font-semibold text-gray-900 md:mx-2">
          Search
        </h1>

        {viewingTeacher && viewingTeacher.teachercode && (
          <div className="inline-flex font-semibold text-gray-900 md:mx-2">
            <div>
              <label className="block" htmlFor="options-menu">
                Viewing Teacher:
              </label>
              <Menu
                id="options-menu"
                as="div"
                className="relative z-50 mb-2 inline-block text-left"
              >
                <div>
                  <Menu.Button className="inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100">
                    {viewingTeacher.first} {viewingTeacher.last} (
                    {viewingTeacher.teachercode})
                    <ChevronDownIcon
                      className="-mr-1 ml-2 h-5 w-5"
                      aria-hidden="true"
                    />
                  </Menu.Button>
                </div>

                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute left-0 w-72 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {viewingTeacher && !licenseExpired(viewingTeacher) && (
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={`${
                                active || menuState.downgradeToFree
                                  ? "block px-4 py-2 text-sm text-indigo-700"
                                  : "block px-4 py-2 text-sm text-gray-700"
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                modifyTeacher("downgrade-to-free");
                              }}
                            >
                              Downgrade to Free Account
                            </a>
                          )}
                        </Menu.Item>
                      )}
                      {viewingTeacher && viewingTeacher.has_integral && (
                        <Menu.Item>
                          {({ active }) => (
                            <a
                              href="#"
                              className={`${
                                active || menuState.downgradeToPlus
                                  ? "block px-4 py-2 text-sm text-indigo-700"
                                  : "block px-4 py-2 text-sm text-gray-700"
                              }`}
                              onClick={(e) => {
                                e.preventDefault();
                                modifyTeacher("downgrade-to-plus");
                              }}
                            >
                              Downgrade to Plus Account
                            </a>
                          )}
                        </Menu.Item>
                      )}
                      {viewingTeacher &&
                        !viewingTeacher.has_integral &&
                        (!viewingTeacher.deltamath_plus_subscription ||
                          licenseExpired(viewingTeacher)) &&
                        !viewingTeacher?.schoolPlus?.length && (
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={`${
                                  active || menuState.upgradeToPlus
                                    ? "block px-4 py-2 text-sm text-indigo-700"
                                    : "block px-4 py-2 text-sm text-gray-700"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  modifyTeacher("upgrade-to-plus");
                                }}
                              >
                                Upgrade to Plus Account
                              </a>
                            )}
                          </Menu.Item>
                        )}
                      {viewingTeacher &&
                        (!viewingTeacher?.has_integral ||
                          licenseExpired(viewingTeacher)) && (
                          <Menu.Item>
                            {({ active }) => (
                              <a
                                href="#"
                                className={`${
                                  active || menuState.upgradeToIntegral
                                    ? "block px-4 py-2 text-sm text-indigo-700"
                                    : "block px-4 py-2 text-sm text-gray-700"
                                }`}
                                onClick={(e) => {
                                  e.preventDefault();
                                  modifyTeacher("upgrade-to-integral");
                                }}
                              >
                                Upgrade to Integral Account
                              </a>
                            )}
                          </Menu.Item>
                        )}
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={`${
                              active || menuState.resetIpAddressList
                                ? "block px-4 py-2 text-sm text-indigo-700"
                                : "block px-4 py-2 text-sm text-gray-700"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              modifyTeacher("reset-ip-address-list");
                            }}
                          >
                            Reset IP Address List
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={`${
                              active || menuState.undeleteAssignment
                                ? "block px-4 py-2 text-sm text-indigo-700"
                                : "block px-4 py-2 text-sm text-gray-700"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              modifyTeacher("undelete-assignment");
                            }}
                          >
                            Undelete an Assignment
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={`${
                              active || menuState.mergeAnotherAccount
                                ? "block px-4 py-2 text-sm text-indigo-700"
                                : "block px-4 py-2 text-sm text-gray-700"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              modifyTeacher("merge-another-account");
                            }}
                          >
                            Merge Another Account into this one
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {({ active }) => (
                          <a
                            href="#"
                            className={`${
                              active || menuState.deleteAccount
                                ? "block px-4 py-2 text-sm text-indigo-700"
                                : "block px-4 py-2 text-sm text-gray-700"
                            }`}
                            onClick={(e) => {
                              e.preventDefault();
                              modifyTeacher("delete-account");
                            }}
                          >
                            Delete Teacher
                          </a>
                        )}
                      </Menu.Item>
                      <Menu.Item>
                        {/* wrapping the component in a div seems to stop an issue w/ unintended ref passing, causing a warning  */}
                        <div>
                          <Impersonate
                            teachercode={viewingTeacher.teachercode}
                            className="block px-4 py-2 text-sm text-gray-700 hover:text-indigo-700"
                          />
                        </div>
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </Transition>
              </Menu>
            </div>
            {menuState.downgradeToFree && (
              <div className="mt-4">
                <button
                  type="submit"
                  id="downgrade-to-free"
                  name="confirm-undelete"
                  className="mb-2 ml-2 mt-0 block justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:m-2"
                  value="Submit"
                  color="secondary"
                  onClick={() => {
                    removePlus.mutate({
                      body: {
                        teachercode: viewingTeacher.teachercode,
                      },
                    });
                    dispatch({ type: "reset" });
                  }}
                >
                  Downgrade to Free
                </button>
              </div>
            )}
            {menuState.downgradeToPlus && (
              <>
                <div>
                  <label className="ml-2 block" htmlFor="plus-expiration">
                    Expiration
                  </label>
                  <input
                    type="number"
                    min="0"
                    id="plus-expiration"
                    className="ml-2 inline-flex w-72 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    placeholder="Expiration Date"
                    value={expiration || 0}
                    onChange={(e) => setExpiration(parseInt(e.target.value))}
                  />
                </div>
                <div>
                  <label className="ml-2 block" htmlFor="plus-revenue">
                    Revenue
                  </label>
                  <input
                    type="number"
                    min="0"
                    id="plus-revenue"
                    className="ml-2 inline-flex w-72 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    placeholder="Cost"
                    value={revenue || 0}
                    onChange={(e) => setRevenue(parseInt(e.target.value))}
                  />
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    id="downgrade-to-plus"
                    name="confirm-undelete"
                    className="mb-2 ml-2 mt-0 block justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:m-2"
                    value="Submit"
                    color="secondary"
                    onClick={() => {
                      downgradeToPlus.mutate({
                        body: {
                          teachercode: viewingTeacher.teachercode,
                          expiration: expiration,
                        },
                      });
                      dispatch({ type: "reset" });
                    }}
                  >
                    Downgrade to Plus
                  </button>
                </div>
              </>
            )}
            {menuState.upgradeToPlus && (
              <>
                <div>
                  <label className="ml-2 block" htmlFor="plus-expiration">
                    Expiration
                  </label>
                  <input
                    type="number"
                    min="0"
                    id="plus-expiration"
                    className="ml-2 inline-flex w-72 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    placeholder="Expiration Date"
                    value={expiration || 0}
                    onChange={(e) => setExpiration(parseInt(e.target.value))}
                  />
                </div>
                <div>
                  <label className="ml-2 block" htmlFor="plus-revenue">
                    Revenue
                  </label>
                  <input
                    type="number"
                    min="0"
                    id="plus-revenue"
                    className="ml-2 inline-flex w-72 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    placeholder="Cost"
                    value={revenue || 0}
                    onChange={(e) => setRevenue(parseInt(e.target.value))}
                  />
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    name="upgrade-to-plus"
                    className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:m-2"
                    value="Submit"
                    color="secondary"
                    onClick={() => {
                      addPlus.mutate({
                        body: {
                          teachercode: viewingTeacher.teachercode,
                          expiration: expiration,
                        },
                      });
                      dispatch({ type: "reset" });
                    }}
                  >
                    Upgrade to Plus
                  </button>
                </div>
              </>
            )}
            {menuState.upgradeToIntegral && (
              <>
                <div>
                  <label className="ml-2 block" htmlFor="integral-expiration">
                    Expiration
                  </label>
                  <input
                    type="number"
                    min="0"
                    id="integral-expiration"
                    className="ml-2 inline-flex w-72 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    placeholder="Expiration Date"
                    value={expiration || 0}
                    onChange={(e) => setExpiration(parseInt(e.target.value))}
                  />
                </div>
                <div>
                  <label className="ml-2 block" htmlFor="integral-revenue">
                    Revenue
                  </label>
                  <input
                    type="number"
                    min="0"
                    id="integral-reveue"
                    className="ml-2 inline-flex w-72 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    placeholder="Cost"
                    value={revenue || 0}
                    onChange={(e) => setRevenue(parseInt(e.target.value))}
                  />
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    name="confirm-undelete"
                    className="mb-2 ml-2 mt-2 block justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:m-2"
                    value="Submit"
                    color="secondary"
                    onClick={() => {
                      upgradeToIntegral.mutate({
                        body: {
                          teachercode: viewingTeacher.teachercode,
                          expiration: expiration,
                        },
                      });
                      dispatch({ type: "reset" });
                    }}
                  >
                    Upgrade to Integral
                  </button>
                </div>
              </>
            )}
            {menuState.resetIpAddressList && (
              <>
                <div className="mt-4">
                  <button
                    type="submit"
                    name="confirm-undelete"
                    className="mt-6 inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:m-2"
                    value="Submit"
                    color="secondary"
                    onClick={() => {
                      resetIPAddress.mutate({
                        param: viewingTeacher.teachercode,
                      });
                      dispatch({ type: "reset" });
                    }}
                  >
                    Confirm Remove IP Whitelist
                  </button>
                </div>
              </>
            )}
            {menuState.undeleteAssignment &&
              getDeletedIsError &&
              !getDeletedIsFetching &&
              !getDeletedSuccess &&
              (getDeletedError as any)?.code === 404 && (
                <div className="ml-4 flex items-center">
                  <p className="text-dm-error-500">
                    No deleted assignments found for teachercode:{" "}
                    {viewingTeacher.teachercode}
                  </p>
                </div>
              )}
            {menuState.undeleteAssignment &&
              !getDeletedIsError &&
              !getDeletedIsFetching &&
              getDeletedSuccess &&
              Array.isArray(getDeletedData?.data) && (
                <>
                  <select
                    className="mb-2 ml-2 mt-6 inline-flex justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    onChange={(e) =>
                      setAssignmentToUndelete(parseInt(e.target.value))
                    }
                  >
                    <option value="-1">Select an assignment</option>
                    {getDeletedData.data.map((assignment: any) => {
                      return (
                        <option key={assignment._id} value={assignment._id}>
                          {assignment.name}
                        </option>
                      );
                    })}
                  </select>
                  <span className="relative z-0 mt-4 inline-flex rounded-md">
                    <button
                      type="submit"
                      name="confirm-undelete"
                      className="flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:m-2"
                      value="Submit"
                      color="secondary"
                      disabled={assignmentToUndelete === -1}
                      onClick={() => {
                        undeleteAssignment.mutate({
                          body: {
                            teachercode: viewingTeacher.teachercode,
                            _id: assignmentToUndelete,
                          },
                        });
                        invalidateGetDeletedAssignments();
                      }}
                    >
                      Confirm Undelete Assignment
                    </button>
                  </span>
                </>
              )}
            {menuState.mergeAnotherAccount && (
              <>
                <div>
                  <label
                    className="ml-2 block"
                    htmlFor="merge-account-expiration"
                  >
                    Teachercode to remove
                  </label>
                  <input
                    type="text"
                    id="merge-account"
                    className="ml-2 inline-flex w-72 justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100"
                    placeholder="teachercode to be removed (new)"
                    onChange={(e) => setNewTeacherCode(e.target.value)}
                  />
                </div>
                <span className="relative z-0 mt-4 inline-flex rounded-md">
                  <button
                    type="submit"
                    name="confirm-merge"
                    className="mt-6 flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 sm:m-2"
                    value="Submit"
                    color="secondary"
                    onClick={() => {
                      mergeAccounts.mutate({
                        body: {
                          teachercode: parseInt(viewingTeacher.teachercode),
                          newteachercode: parseInt(newTeacherCode),
                        },
                      });
                      dispatch({ type: "reset" });
                    }}
                  >
                    Confirm Merge Accounts
                  </button>
                </span>
              </>
            )}
            {menuState.deleteAccount && (
              <>
                <span className="relative z-0 mt-4 inline-flex rounded-md">
                  <button
                    type="submit"
                    name="confirm-delete-teacher"
                    className="mt-6 flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 focus:ring-offset-gray-100 sm:m-2"
                    value="Submit"
                    color="secondary"
                    onClick={() => {
                      deleteAccount.mutate({
                        body: JSON.stringify(viewingTeacher),
                        teachercode: viewingTeacher.teachercode,
                      });
                      dispatch({ type: "reset" });
                    }}
                  >
                    Confirm Delete
                  </button>
                </span>
              </>
            )}
          </div>
        )}

        <DMConfirmModal
          visible={showMergeWithAdminWarning}
          confirmText={"Yes, merge them!"}
          title={"Account(s) Associated with Admin  Account"}
          onConfirm={() => {
            mergeAccounts.mutate({
              body: {
                teachercode: parseInt(viewingTeacher.teachercode),
                newteachercode: parseInt(newTeacherCode),
                mergeWithAdminConfirmed: true,
              },
            });
            dispatch({ type: "reset" });
          }}
          onCancel={() => setShowMergeWithAdminWarning(false)}
        >
          <div className="text-lg">
            At least one of the accounts you are trying to merge is connected to
            an administrator account. Are you sure you want to merge?
          </div>
        </DMConfirmModal>

        {Array.isArray(data) && (
          <DeltaMathSearchableTable
            columns={columns}
            data={data}
            currentSearch={payload}
            updateSearch={setPayload}
            handleSubmit={handleSubmit}
            refetch={refetch}
            isLoading={isLoading}
            restoreDefaults={restoreDefaults}
            selectAccount={selectTeacher}
          />
        )}
        {!isLoading && data.length === 0 && hasSearched && (
          <div className="m-6">
            No results found. Please try again with different search criteria.
          </div>
        )}
      </div>
    </>
  );
};

export default Search;
