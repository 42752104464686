import { useState } from "react";
import { DmLoadingSpinner } from "../../../manager/utils/functions";
import { getFilePath, useDMQuery } from "../../../utils";
import { Course } from "../../types";
import { ResponsiveImage } from "../ResponsiveImage";
import Button from "../../../student/components/generic/button";
import { Transition } from "@headlessui/react";
import { SectionTitle } from "./SectionTitle";
import clsx from "clsx";
import { MAX_CONTAINER_WIDTH } from "./constants";

export const CoursesList: React.FC = () => {
  const [expanded, isExpanded] = useState(false);

  const { data: courseData } = useDMQuery<{ courses: Course[] }>({
    path: "/learner/default/courses/published",
    queryOptions: { refetchOnWindowFocus: false },
  });

  const featuredCourses = courseData?.courses
    ?.filter((c) => c.featuredCourseOrder !== undefined)
    .sort((a, b) => (a.featuredCourseOrder ?? 0) - (b.featuredCourseOrder ?? 0))
    .slice(0, 3);

  const activeCoursesSet = expanded
    ? courseData?.courses || []
    : featuredCourses || [];

  return (
    <section
      className={clsx(
        "relative flex w-full flex-col items-center px-4 md:px-6 xl:px-0",
        MAX_CONTAINER_WIDTH
      )}
    >
      <SectionTitle
        title="Your Math Journey Starts Here"
        subtitle="Find the perfect course to match your grade and goals"
      />

      {!courseData || !activeCoursesSet.length ? (
        <div className="flex h-52 items-center justify-center">
          <DmLoadingSpinner message="" />
        </div>
      ) : (
        <>
          <ul className="flex grid-cols-3 flex-col gap-4 md:grid md:gap-6">
            {activeCoursesSet.map((course, index) => (
              <Transition
                key={course.id}
                show={index < 3 || expanded}
                enter="transition ease-in duration-200"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                appear
              >
                <li className="flex h-full flex-col gap-4 rounded-lg border-2 border-dm-brand-blue-500 p-8 md:gap-6">
                  <div className="flex flex-col-reverse md:flex-row md:items-center">
                    <h5 className="flex-grow font-bold text-dm-brand-blue-600 md:font-serif md:text-2xl">
                      {course.name}
                    </h5>
                    <div className="relative mb-6 h-16 w-16 flex-none md:mb-0 md:ml-4">
                      <img
                        className="absolute left-1/2 top-1/2 -ml-12 -mt-12 h-24 w-24 max-w-24"
                        src={course.iconUrl}
                        alt={course.name}
                      />
                    </div>
                  </div>
                  {course.description && (
                    <p className="line-clamp-3">{course.description}</p>
                  )}
                </li>
              </Transition>
            ))}
          </ul>
          <Button
            className="mt-6 w-[396px] max-w-full text-sm md:mt-8"
            onClick={() => isExpanded(!expanded)}
          >
            {expanded ? "Hide Courses" : "Explore All Courses"}
          </Button>
        </>
      )}
      <ResponsiveImage
        className="absolute -left-10 -top-10 hidden md:block 2xl:-left-28"
        srcs={[
          getFilePath("/images/learner/home/pencil.png"),
          getFilePath("/images/learner/home/pencil@2x.png"),
        ]}
        alt="Pencil"
      />
    </section>
  );
};
