import { useEffect, useState } from "react";
import { useUserContext } from "../../../shared/contexts/UserContext";
import { formatNumber, useDMQuery } from "../../../utils";
import { getStartEndDate } from "../StudentUsageUtils";
import StudentsIcon from "../../../shared/icons/StudentsIcon";
import AssignmentsIcon from "../../../shared/icons/AssignmentsIcon";
import WarningCircleIcon from "../../../shared/icons/WarningCircleIcon";
import ClockIcon from "../../../shared/icons/ClockIcon";
import { DmLoadingSpinner } from "../../../manager/utils/functions";

interface DataItemInterface {
  icon: any;
  name: string;
  data: number;
}

const QuickStats = () => {
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const { yesterday, yearToDate } = getStartEndDate();
  const [problemsSolved, setProblemsSolved] = useState<number>(0);
  const [hoursSpent, setHoursSpent] = useState<number>(0);
  const [numStudents, setNumStudents] = useState<number>(0);
  const [numAssignments, setNumAssignments] = useState<number>(0);

  const {
    data: teacherCountsData,
    refetch: refetchTeacherCountsData,
    isSuccess: teacherCountsSuccess,
  } = useDMQuery({
    path: "/admin_new/data/teacherCounts",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      enabled: true,
    },
  });

  useEffect(() => {
    if (teacherCountsSuccess) {
      const sums = Object.values<{ students: number; assignments: number }>(
        teacherCountsData
      ).reduce(
        (
          valA: { students: number; assignments: number },
          valB: { students: number; assignments: number }
        ) => ({
          students: valA.students + valB.students,
          assignments: valA.assignments + valB.assignments,
        }),
        { students: 0, assignments: 0 }
      );
      setNumAssignments(sums.assignments);
      setNumStudents(sums.students);
    }
  }, [teacherCountsData, teacherCountsSuccess]);

  const { data, refetch, isSuccess } = useDMQuery({
    cacheKey: [`usage-quick-stats`],
    path: `/admin_new/data/usage`,
    params: {
      start:
        yearToDate?.getTime() &&
        Math.floor(yearToDate?.getTime() / 1000).toString(),
      end:
        yesterday?.getTime() &&
        Math.floor(yesterday?.getTime() / 1000).toString(),
      district: true,
    },
    method: "get",
    additionalHeaders: {
      Authorization: `Bearer ${token}`,
    },
    queryOptions: {
      enabled: true,
      staleTime: 1000 * 60 * 15,
    },
  });

  useEffect(() => {
    if (isSuccess) {
      const sums = Object.values<{ n: number; t: number }>(data.data).reduce(
        (valA: { n: number; t: number }, valB: { n: number; t: number }) => ({
          n: valA.n + valB.n,
          t: valA.t + valB.t,
        }),
        { n: 0, t: 0 }
      );
      setHoursSpent(sums.t / 60);
      setProblemsSolved(sums.n);
    }
  }, [isSuccess, data]);

  const aggregateUsageData = (data: Array<{ n: number; t: number }>) =>
    data.reduce(
      (valA: { n: number; t: number }, valB: { n: number; t: number }) => ({
        n: valA.n + valB.n,
        t: valA.t + valB.t,
      }),
      { n: 0, t: 0 }
    );

  const aggregateStudentData = (
    data: Array<{ students: number; assignments: number }>
  ) =>
    data.reduce(
      (
        valA: { students: number; assignments: number },
        valB: { students: number; assignments: number }
      ) => ({
        students: valA.students + valB.students,
        assignments: valA.assignments + valB.assignments,
      }),
      { students: 0, assignments: 0 }
    );

  useEffect(() => {
    if (data && data.data) {
      const usageSums = aggregateUsageData(Object.values(data.data));
      setHoursSpent(usageSums.t / 60);
      setProblemsSolved(usageSums.n);
    } else {
      refetch();
    }
    if (teacherCountsData) {
      const studentSums = aggregateStudentData(
        Object.values(teacherCountsData)
      );
      setNumAssignments(studentSums.assignments);
      setNumStudents(studentSums.students);
    } else {
      refetchTeacherCountsData();
    }
  }, []);

  return (
    <div className="flex justify-between space-x-[24px] px-6">
      {teacherCountsData ? (
        <>
          <StatItem
            icon={<StudentsIcon />}
            name={"Total Students"}
            data={numStudents}
          />
          <StatItem
            key={"Total Assignments"}
            icon={<AssignmentsIcon />}
            name={"Total Assignments"}
            data={numAssignments}
          />
        </>
      ) : (
        <>
          <LoadingSkeloton />
          <LoadingSkeloton />
        </>
      )}

      {data ? (
        <>
          <StatItem
            key={2}
            icon={<WarningCircleIcon />}
            name={"Problems Solved"}
            data={problemsSolved}
          />
          <StatItem
            key={3}
            icon={<ClockIcon />}
            name={"Hours Spent on Site"}
            data={hoursSpent}
          />
        </>
      ) : (
        <>
          <LoadingSkeloton />
          <LoadingSkeloton />
        </>
      )}
    </div>
  );
};

export default QuickStats;

const StatItem = (props: DataItemInterface) => {
  return (
    <div className="flex w-full flex-row rounded-md border p-6 pr-8">
      <span>{props.icon}</span>
      <div className="ml-3.5">
        <p className="text-md font-serif font-[500]">
          {formatNumber(props.data)}
        </p>
        <p className="text-xs">{props.name}</p>
      </div>
    </div>
  );
};

const LoadingSkeloton = () => {
  return (
    <div className="flex w-full flex-row rounded-md border border-dm-gray-200 p-6 pr-8 text-dm-gray-200">
      <DmLoadingSpinner message="Loading data..." />
    </div>
  );
};
