import React, { useEffect } from "react";
import { useState } from "react";
import { useParams } from "react-router-dom";
import DeltaMathTable from "../../shared/DeltaMathTable";
import { executeQuery } from "../../utils";
import { useQuery } from "@tanstack/react-query";

/**
 * Describes the price scheme selector / response values for price-scheme/published
 */
type PriceSchemeSelector = {
  key: string;
  default?: boolean;
};

/**
 * Describes the license options for a district
 */
type DistrictLicenseOptions = {
  six_twelve: LicenseOption;
  six_eight: LicenseOption;
  nine_twelve: LicenseOption;
};

/**
 * A license option for the district, as defined in the backend
 */
type LicenseOption = {
  price: {
    plus: number;
    integral: number;
  };
  discount: number;
  priceWithDiscount: {
    plus: number;
    integral: number;
  };
};

/**
 * Desribes the response from the /school-or-district-by-id endpoint
 */
type SchoolOrDistrictByIdResponse = {
  district: {
    licenseOptions: DistrictLicenseOptions;
  };
  schools: Array<{
    students: number;
    lowGrade: string;
    highGrade: string;
    name: string;
    nces_id: string;
    licenseOptions: LicenseOption;
  }>;
};

export interface ISchoolPopData {
  students: any;
  lowGrade: any;
  highGrade: any;
  name: string;
  nces_id: string;
}

export const DISTRICT_LICENSE_TO_GRADES: {
  [key: string]: { low: number; high: number };
} = {
  "District License (Grades 6-12)": {
    low: 6,
    high: 12,
  },
  "District License (Grades 7-12)": {
    low: 7,
    high: 12,
  },
  "District License (Grades 9-12)": {
    low: 9,
    high: 12,
  },
  "District License (Grades 6-8)": {
    low: 6,
    high: 8,
  },
  "District License (Grades 7-8)": {
    low: 7,
    high: 8,
  },
};

function getSchoolLowHigh(school: any): { low: number; high: number } {
  /**
   * returns the lowest and highest grades a school encompasses
   */
  let low = school.lowGrade;
  if (low === "K") low = 0;
  else if (low === "PK") low = -1;
  else low = parseInt(low);
  if (isNaN(low)) low = 6;

  let high = school.highGrade;
  if (high === "K") high = 0;
  else if (high === "PK") high = -1;
  else high = parseInt(school.highGrade);
  if (isNaN(high)) high = 12;
  return { low, high };
}

function getStudentGradesCounts(schools: any[]): {
  elemTotal: number;
  middleTotal: number;
  highTotal: number;
  studentsTotal: number;
} {
  let elemTotal = 0;
  let middleTotal = 0;
  let highTotal = 0;
  let studentsTotal = 0;
  for (const org of schools) {
    org.students = parseInt(org.students);
    if (org.students) studentsTotal += org.students;
    else continue;

    let low = org.lowGrade;
    if (low === "K") low = 0;
    else if (low === "PK") low = -1;
    else low = parseInt(low);
    if (isNaN(low)) low = 6;
    let high = parseInt(org.highGrade);
    if (isNaN(high)) high = 12;

    // relative proportion in PK-5, 6-8, 9-12
    let weight1 = 0;
    let weight2 = 0;
    let weight3 = 0;
    let totalWeight = 0;
    for (let i = low; i <= high; i++) {
      totalWeight++;
      if (i <= 5) weight1++;
      else if (i <= 8) weight2++;
      else weight3++;
    }
    elemTotal += Math.round((weight1 / totalWeight) * org.students);
    middleTotal += Math.round((weight2 / totalWeight) * org.students);
    highTotal += Math.round((weight3 / totalWeight) * org.students);
  }
  return { elemTotal, middleTotal, highTotal, studentsTotal };
}

const QuoteDistrict = () => {
  const params = useParams();

  const [districtPricing, setDistrictPricing] =
    useState<null | DistrictLicenseOptions>(null);
  const [districtHighTotal, setDistrictHighTotal] = useState(0);
  const [districtMiddleTotal, setDistrictMiddleTotal] = useState(0);

  const [priceScheme, setPriceScheme] = useState<string>("");
  const [priceSchemes, setPriceSchemes] = useState<Array<PriceSchemeSelector>>(
    []
  );

  const [schoolsData, setSchoolsData] = useState<any>([]);

  // Fetch price scheme keys
  const { data: priceSchemesData, isSuccess: priceSchemesSuccess } = useQuery<
    Array<PriceSchemeSelector>
  >({
    queryKey: ["price_schemes"],
    queryFn: () =>
      executeQuery({
        path: "/public/quote/price-scheme/published",
        customerServiceHeader: true,
      }),
    enabled: true,
  });

  useEffect(() => {
    if (priceSchemesSuccess) {
      setPriceSchemes(priceSchemesData);
      setPriceScheme(
        priceSchemesData.find((scheme) => scheme.default)?.key ||
          priceSchemesData[0].key
      );
    }
  }, [priceSchemesSuccess, priceSchemesData]);

  // Fetch the school info, which includes the relevant license options
  const {
    isLoading: isLoadingSchoolData,
    isSuccess: isSuccessSchoolData,
    data,
    refetch: fetchSchoolData,
  } = useQuery({
    queryKey: ["school_data", params.type, params.id, priceScheme],
    queryFn: () =>
      executeQuery({
        path: `/manager_new/manage/school-or-district-by-id/${params.type}/${params.id}`,
        params: { priceSchemeKey: priceScheme },
        method: "get",
      }),
    enabled: !!priceScheme,
  });

  useEffect(() => {
    if (isSuccessSchoolData) {
      setDistrictPricing(data.district.licenseOptions);
    }
  }, [isSuccessSchoolData, data]);

  useEffect(() => {
    if (priceScheme) {
      fetchSchoolData();
    }
  }, [priceScheme, fetchSchoolData]);

  const filterSchoolsByGrade = ({
    schools,
    licenseType,
  }: {
    schools: ISchoolPopData[];
    licenseType: string;
  }) => {
    const filteredSchools: ISchoolPopData[] = [];
    schools?.forEach((school: ISchoolPopData) => {
      const { low, high } = getSchoolLowHigh(school);
      // check if school overlaps with license, if so add it to filteredSchools
      if (params.type === "School License") filteredSchools.push(school);
      else {
        const gradeRange = DISTRICT_LICENSE_TO_GRADES[licenseType];
        const schoolInRange =
          (low >= gradeRange.low && low <= gradeRange.high) ||
          (high <= gradeRange.high && high >= gradeRange.low) ||
          (low < gradeRange.low && high > gradeRange.high);

        if (schoolInRange) filteredSchools.push(school);
      }
    });
    return filteredSchools;
  };

  useEffect(() => {
    if (data === undefined) return;

    //6-8 Student Info
    const sixToEightSchools = filterSchoolsByGrade({
      schools: data?.schools,
      licenseType: "District License (Grades 6-8)",
    });
    const sixToEightGradeCounts = getStudentGradesCounts(sixToEightSchools);

    //9-12 Student Info
    const nineToTwelveSchools = filterSchoolsByGrade({
      schools: data?.schools,
      licenseType: "District License (Grades 9-12)",
    });
    const nineToTwelveGradeCounts = getStudentGradesCounts(nineToTwelveSchools);

    setDistrictMiddleTotal(sixToEightGradeCounts.middleTotal);
    setDistrictHighTotal(nineToTwelveGradeCounts.highTotal);

    const ourSchools: any[] = [];
    data?.schools.map(
      (
        org: ISchoolPopData & {
          licenseOptions: LicenseOption;
        }
        // eslint-disable-next-line array-callback-return
      ) => {
        const {
          elemTotal: numElementaryStudents,
          middleTotal: numMiddleStudents,
          highTotal: numHighStudents,
        } = getStudentGradesCounts([org]);

        ourSchools.push({
          ...org,
          gradespk_5: numElementaryStudents,
          grades6_8: numMiddleStudents,
          grades9_12: numHighStudents,
          full_price_plus: org.licenseOptions?.price.plus,
          full_price_integral: org.licenseOptions?.price.integral,
          discount: org.licenseOptions?.discount,
          final_price_plus: org.licenseOptions?.priceWithDiscount.plus,
          final_price_integral: org.licenseOptions?.priceWithDiscount.integral,
        });
      }
    );
    setSchoolsData(ourSchools);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, priceScheme]);

  const formatNumber = (num: number) => {
    if (isNaN(num)) {
      return "-";
    }
    return num === Math.round(num)
      ? num.toLocaleString()
      : num.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, "$&,");
  };
  return (
    <React.Fragment>
      {isLoadingSchoolData ? (
        <div>Loading...</div>
      ) : status === "error" ? (
        <span>Error...</span>
      ) : (
        <>
          <div className="inline-flex px-2">
            <h1 className="py-4 text-2xl font-semibold">Quote Info</h1>
            <select
              id="year"
              name="year"
              placeholder="License Type"
              value={priceScheme}
              onChange={(e) => setPriceScheme(e.target.value)}
              className="my-4 ml-4 block w-min rounded-md border-gray-300 text-sm"
            >
              <option disabled value="-">
                Select Price Scheme
              </option>
              {priceSchemes.map((scheme) => (
                <option key={scheme.key} value={scheme.key}>
                  {scheme.key}
                </option>
              ))}
            </select>
          </div>
          {data?.district?.type === "district" && districtPricing && (
            // should this be aligned with the table edge or the table text?
            <table className="mb-4">
              <tbody>
                <tr className="space-x-2 divide-solid">
                  <td className="px-4 py-8">
                    <b>District:</b>
                    <div>{data.district.name}</div>
                    <div>
                      {`${data.district.address.street}, ${data.district.address.city}, 
                          ${data.district.address.state} ${data.district.address.zip}`}
                    </div>
                    <div>{data.district.phone}</div>
                    <div>
                      <a
                        href={`${data.district?.url}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        link
                      </a>
                    </div>
                  </td>

                  <td className="px-4 py-8">
                    <b>District 6-12</b>
                    <div>
                      {`(${formatNumber(
                        districtMiddleTotal + districtHighTotal
                      )} students)`}
                    </div>
                    <div>{`Original Plus Price: ${formatNumber(
                      districtPricing.six_twelve.price.plus
                    )}`}</div>
                    <div>{`Original Integral Price: ${formatNumber(
                      districtPricing.six_twelve.price.integral
                    )}`}</div>
                    <div>{`Discount: ${districtPricing.six_twelve.discount}%`}</div>
                    <div>{`Final Plus Price: ${formatNumber(
                      districtPricing.six_twelve.priceWithDiscount.plus
                    )}`}</div>
                    <div>{`Final Integral Price: ${formatNumber(
                      districtPricing.six_twelve.priceWithDiscount.integral
                    )}`}</div>
                  </td>

                  <td className="px-4 py-8">
                    <b>District 6-8</b>
                    <div>{`(${formatNumber(
                      districtMiddleTotal
                    )} students)`}</div>
                    <div>{`Original Plus Price: ${formatNumber(
                      districtPricing.six_eight.price.plus
                    )}`}</div>
                    <div>{`Original Integral Price: ${formatNumber(
                      districtPricing.six_eight.price.integral
                    )}`}</div>
                    <div>{`Discount: ${districtPricing.six_eight.discount}%`}</div>
                    <div>{`Final Plus Price: ${formatNumber(
                      districtPricing.six_eight.priceWithDiscount.plus
                    )}`}</div>
                    <div>{`Final Integral Price: ${formatNumber(
                      districtPricing.six_eight.priceWithDiscount.integral
                    )}`}</div>
                  </td>

                  <td className="px-4 py-8">
                    <b>District 9-12</b>
                    <div>{`(${formatNumber(districtHighTotal)} students)`}</div>
                    <div>{`Original Plus Price: ${formatNumber(
                      districtPricing.nine_twelve.price.plus
                    )}`}</div>
                    <div>{`Original Integral Price: ${formatNumber(
                      districtPricing.nine_twelve.price.integral
                    )}`}</div>
                    <div>{`Discount: ${districtPricing.nine_twelve.discount}%`}</div>
                    <div>{`Final Plus Price: ${formatNumber(
                      districtPricing.nine_twelve.priceWithDiscount.plus
                    )}`}</div>
                    <div>{`Final Integral Price: ${formatNumber(
                      districtPricing.nine_twelve.priceWithDiscount.integral
                    )}`}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          )}
          {data && (
            <DeltaMathTable
              columns={[
                {
                  Header: "Name",
                  accessor: "name",
                },
                {
                  Header: "Level",
                  accessor: "lowGrade",
                  Cell: (props: any) => (
                    <span>{`${props.row.values.lowGrade} - ${props.row.values.highGrade}`}</span>
                  ),
                },
                {
                  //this is a hacky way of writing "highGrade" into row.values but not displaying it
                  id: "highGrade",
                  accessor: "highGrade",
                  Cell: () => <span></span>,
                },
                {
                  Header: "Students",
                  accessor: "students",
                  Cell: (props: any) => (
                    <span>{formatNumber(props.row.values.students)}</span>
                  ),
                },
                {
                  Header: "PK-5",
                  accessor: "gradespk_5",
                  Cell: (props: any) => (
                    <span>{formatNumber(props.row.values.gradespk_5)}</span>
                  ),
                },
                {
                  Header: "6-8",
                  accessor: "grades6_8",
                  Cell: (props: any) => (
                    <span>{formatNumber(props.row.values.grades6_8)}</span>
                  ),
                },
                {
                  Header: "9-12",
                  accessor: "grades9_12",
                  Cell: (props: any) => (
                    <span>{formatNumber(props.row.values.grades9_12)}</span>
                  ),
                },
                {
                  Header: "Full Price Plus",
                  accessor: "full_price_plus",
                  Cell: (props: any) => (
                    <span>{`$${formatNumber(
                      props.row.values.full_price_plus
                    )}`}</span>
                  ),
                },
                {
                  Header: "Full Price Integral",
                  accessor: "full_price_integral",
                  Cell: (props: any) => (
                    <span>{`$${formatNumber(
                      props.row.values.full_price_integral
                    )}`}</span>
                  ),
                },
                {
                  Header: "Discount",
                  accessor: "discount",
                  Cell: (props: any) => (
                    <span>{`${props.row.values.discount}%`}</span>
                  ),
                },
                {
                  Header: "Final Price Plus",
                  accessor: "final_price_plus",
                  Cell: (props: any) => (
                    <span>{`$${formatNumber(
                      props.row.values.final_price_plus
                    )}`}</span>
                  ),
                },
                {
                  Header: "Final Price Integral",
                  accessor: "final_price_integral",
                  Cell: (props: any) => (
                    <span>{`$${formatNumber(
                      props.row.values.final_price_integral
                    )}`}</span>
                  ),
                },
                {
                  Header: "NCES ID",
                  accessor: "id",
                },
                {
                  Header: "Link",
                  accessor: "url",
                  Cell: (props: any) => (
                    <a
                      className="text-teal-300"
                      href={props.row.values.url}
                      target="_blank"
                      rel="noreferrer"
                    >
                      {" "}
                      SchoolDigger{" "}
                    </a>
                  ),
                },
              ]}
              data={schoolsData}
            ></DeltaMathTable>
          )}
        </>
      )}
    </React.Fragment>
  );
};

export default QuoteDistrict;
