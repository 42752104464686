import { Dialog, Transition } from "@headlessui/react";
import { ExclamationCircleIcon } from "@heroicons/react/outline";
import axios from "axios";
import { Fragment, useRef } from "react";
import { useMutation } from "@tanstack/react-query";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { deltamathAPI } from "../utils";
import { useUserContext } from "../../shared/contexts/UserContext";

const CreateEntityModal = ({
  open,
  setOpen,
  entityType,
  ncesId,
}: {
  open: boolean;
  setOpen: (state: any) => void;
  entityType: string;
  ncesId: string;
}) => {
  const cancelButtonRef = useRef(null);
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const toastContext = useDeltaToastContext();

  const createSchool = useMutation({
    mutationFn: () => {
      return axios.post(
        deltamathAPI() + `/manager_new/license/create-school/${ncesId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    onSuccess: () => {
      toastContext.addToast({
        message: "School successfully created.",
        status: "Success",
      });
    },
    onError: () => {
      toastContext.addToast({
        message: "There was an issue creating this school.",
        status: "Error",
      });
    },
  });
  const createDistrict = useMutation({
    mutationFn: () => {
      return axios.post(
        deltamathAPI() + `/manager_new/license/create-district/${ncesId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    onSuccess: () => {
      toastContext.addToast({
        message: "District successfully created.",
        status: "Success",
      });
    },
    onError: () => {
      toastContext.addToast({
        message: "There was an issue creating this district.",
        status: "Error",
      });
    },
  });
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        initialFocus={cancelButtonRef}
        onClose={setOpen}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-0 z-10 overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg sm:p-6">
                <div className="sm:flex sm:items-start">
                  <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
                    <ExclamationCircleIcon
                      className="h-6 w-6 text-red-600"
                      aria-hidden="true"
                    />
                  </div>
                  <div className="mt-3 text-center sm:ml-4 sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-base font-semibold leading-6 text-gray-900"
                    >
                      Add School or District?
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        No record of this {entityType} was found in our
                        database. Would you like to add it?
                      </p>
                    </div>
                  </div>
                </div>
                <div className="sm:ml-12">
                  <form className="mt-2 text-sm sm:inline-flex">
                    <div>
                      <label className="mr-2" htmlFor="type">
                        Entity Type
                      </label>
                      <input
                        name="type"
                        type="text"
                        readOnly={true}
                        className="rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={entityType}
                      />
                    </div>
                    <div className="mt-2 sm:mt-0">
                      <label className="mr-2" htmlFor="id">
                        NCES ID
                      </label>
                      <input
                        name="id"
                        type="text"
                        readOnly={true}
                        className="rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                        value={ncesId}
                      />
                    </div>
                  </form>
                </div>
                <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-red-600 px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-red-500 sm:ml-3 sm:w-auto"
                    onClick={() => {
                      entityType === "school"
                        ? createSchool.mutate()
                        : createDistrict.mutate();
                      setOpen(false);
                    }}
                  >
                    Create {entityType}
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => setOpen(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateEntityModal;
