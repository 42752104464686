import React from "react";
import { formatNumber } from "../../utils";
import DeltaMathAccordion from "../../../shared/DeltaMathAccordion";
import {
  IQuickbooksData,
  IProcessedEstimate,
} from "../../utils/quickbookDataUtils";
import InvoicePageCard from "./InvoicePageCard";

const QuickbooksCard: React.FC<{
  quickbooksEstimateData: IProcessedEstimate | undefined;
  quickbooksInvoiceData: IQuickbooksData | undefined;
}> = ({ quickbooksEstimateData, quickbooksInvoiceData }) => {
  return (
    <InvoicePageCard title={"Quickbooks Estimate"} fullWidth={true}>
      <div>
        {!quickbooksEstimateData && (
          <div className="w-full">
            <p>No Quickbooks estimate found.</p>
          </div>
        )}
        {quickbooksEstimateData && (
          <>
            <>
              <div className="w-full">
                {quickbooksEstimateData?.dateCreated !==
                  quickbooksEstimateData?.lastUpdateTime && (
                  <div className="flex text-red-700">
                    <svg
                      className="mr-2 mt-1 h-4 w-4 fill-current text-red-700"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                    This Estimate has been updated since it was first created.
                  </div>
                )}
                {quickbooksEstimateData?.email?.includes("@deltamath.com") && (
                  <div className="mt-2 flex text-red-700">
                    <svg
                      className="mr-2 mt-1 h-4 w-4 fill-current text-red-700"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 20 20"
                    >
                      <path d="M2.93 17.07A10 10 0 1 1 17.07 2.93 10 10 0 0 1 2.93 17.07zm12.73-1.41A8 8 0 1 0 4.34 4.34a8 8 0 0 0 11.32 11.32zM9 11V9h2v6H9v-4zm0-6h2v2H9V5z" />
                    </svg>
                    This Estimate was created by a DeltaMath employee.
                  </div>
                )}

                {quickbooksEstimateData.billingAddress && (
                  <div className="mb-4 mt-4">
                    <h3 className="font-bold">Billing Address:</h3>
                    <div>{quickbooksEstimateData.billingAddress}</div>
                    <div>
                      {quickbooksEstimateData.billingCity}{" "}
                      {quickbooksEstimateData.billingState}{" "}
                      {quickbooksEstimateData.billingZip}
                    </div>
                  </div>
                )}

                <table className="mt-4 w-full">
                  <thead>
                    <tr>
                      {quickbooksInvoiceData && <th>Invoice #</th>}
                      <th className="">Date Created</th>
                      <th className="">Customer Email</th>
                      <th className="">Total</th>
                      <th className="">Last Updated</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {quickbooksInvoiceData && (
                        <td>{quickbooksInvoiceData.DocNumber}</td>
                      )}
                      <td>{quickbooksEstimateData.dateCreated}</td>
                      <td>{quickbooksEstimateData.email}</td>
                      <td>
                        ${" "}
                        {quickbooksEstimateData.totalQuotePrice &&
                          formatNumber(quickbooksEstimateData?.totalQuotePrice)}
                      </td>
                      <td>{quickbooksEstimateData.lastUpdateTime}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </>

            <DeltaMathAccordion
              title={<strong>View Estimate Details</strong>}
              renderExpanded={true}
              showExpand={true}
              content={
                <>
                  <table className="w-full table-fixed border-gray-300">
                    <thead className="mt-2">
                      <tr>
                        <th className="text-left">Product</th>
                        <th className="pl-4 text-left">Description</th>
                        <th className="pr-4 text-right">Quantity</th>
                        <th className="pr-4 text-right">Price</th>
                        <th className="pr-4 text-right">Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {quickbooksEstimateData &&
                        Array.isArray(quickbooksEstimateData.lineItems) &&
                        quickbooksEstimateData.lineItems.map((item) => {
                          if (item)
                            return (
                              <tr className="" key={item.product}>
                                <td className="border-r text-center">
                                  {item.product}
                                </td>
                                <td className="border-r pl-4">
                                  {item.description}
                                </td>
                                <td className="mt-0 border-r pr-4 text-right">
                                  {item.quantity}
                                </td>
                                <td className="mt-0 border-r pr-4 text-right">
                                  {formatNumber(
                                    parseInt(item.totalProductPrice || "0")
                                  )}
                                </td>
                                <td className="">{item.status}</td>
                              </tr>
                            );
                          else return;
                        })}
                    </tbody>
                  </table>
                  <div className="mt-8 inline-flex">
                    <div className="mr-24">
                      <h3 className="font-bold">
                        Message displayed on estimate:
                      </h3>
                      <div>{quickbooksEstimateData.customerMemo}</div>
                    </div>
                  </div>
                </>
              }
            />
          </>
        )}
      </div>
    </InvoicePageCard>
  );
};

export default QuickbooksCard;
