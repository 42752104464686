import { useEffect, useState } from "react";
import { useDMQuery } from "../../../utils";
import CategoryDisplay from "./CategoryDisplay";
import Select from "react-select";
import Button from "../../../student/components/generic/button";
import StandardSelect from "./StandardSelect";

export type Category = {
  code: string;
  name: string;
  skills: string[];
  yearcode: string;
};

type Experimental = {
  abbrev: string;
  categories: string[];
  code: string;
  color: string;
  name: string;
};

export type Skill = {
  categories: string[];
  description: string;
  done: boolean;
  idcodes: [number[]];
  name?: string;
  timed?: boolean;
  tags: string[];
  video_seconds: number;
  vimeo_id: number;
  when: number;
  youtube_id: string;
  __index?: string;
  searchTags?: string[];
};

type Domain = {
  code: string;
  description: string;
  domain: string;
  order: string[];
};

type Grade = {
  domains: Domain[];
  name: string;
  order: string[];
};

type Standards = {
  grades: { [key: string]: Grade };
  order: string[];
};

type Year = {
  _id: string;
  abbrev: string;
  categories: string[];
  code: string;
  color: string;
  name: string;
};

export type SkillCodes = {
  _id: string;
  categories: { [key: string]: Category };
  experimental: { [key: string]: Experimental };
  skills: { [key: string]: Skill };
  special: boolean;
  standards: Standards;
  standards_tags: string[];
  tags: string[];
  version: number;
  years: { [key: string]: Year };
  years_order: string[];
};

type Props = {
  skillAdd?: (name: string, skillCode: string) => void;
};

const SkillCodes = (props: Props) => {
  // Placing state here so we dont instantiate an opened state in each sub component,
  // we can keep a list of opened categories here
  const [openedCategories, setOpenedCategories] = useState<string[]>([]);
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [filteredYears, setFilteredYears] = useState<string[]>([]);
  const [viewByStandards, setViewByStandards] = useState<boolean>(false);

  const { data, status } = useDMQuery<SkillCodes>({
    path: `/manager_new/skill-codes/all`,
    customerServiceHeader: true,
    queryOptions: {
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  const openCategory = (name: string) => {
    setOpenedCategories([...openedCategories, name]);
  };

  const closeCategory = (name: string) => {
    setOpenedCategories([...openedCategories.filter((cat) => cat !== name)]);
  };

  const handleChange = (e: any) => {
    setSearchTerm(e.target.value);
  };

  // Clear opened categories when search term is changed
  useEffect(() => {
    setOpenedCategories([]);
  }, [searchTerm]);

  const yearOptions = data
    ? data.years_order
        .map((year) => data.years[year])
        .map((x) => {
          return {
            label: x.name,
            value: x.code,
          };
        })
    : undefined;

  if (status !== "success" || !yearOptions) {
    return <>Loading...</>;
  }

  const filteredCategories = Object.entries(data.categories)
    .flatMap((x) => x[1])
    .filter((cat) =>
      cat.name.toLowerCase().includes(searchTerm.trim().toLowerCase())
    );

  const filteredSkills = Object.entries(data.skills)
    .flatMap((x) => {
      return {
        __index: x[0],
        ...x[1],
      };
    })
    .filter(
      (skill) =>
        searchTerm.trim() !== "" &&
        (skill.name ?? "")
          .toLowerCase()
          .includes(searchTerm.trim().toLowerCase())
    );

  const categoriesToShow =
    searchTerm.trim() === ""
      ? Object.entries(data.categories).flatMap((x) => x[1])
      : Object.entries(data.categories)
          .flatMap((x) => x[1])
          .filter(
            (cat) =>
              filteredCategories.some((c) => c.code === cat.code) ||
              cat.skills.some((catS) =>
                filteredSkills.some((fs) => fs.__index === catS)
              )
          );

  const toggleStandardView = () => {
    if (viewByStandards) {
      setFilteredYears([]);
    }
    setViewByStandards(!viewByStandards);
  };

  return (
    <>
      <div className="relative pt-8">
        <div className="sticky top-0 flex items-end justify-between gap-8 bg-white pb-8">
          <div className="flex gap-8">
            <div>
              <p className="mb-2 text-lg font-bold">Search</p>
              <input
                type="text"
                name="classcode"
                id="classcode"
                className="block h-12 w-96 rounded-none rounded-l-md border-gray-300 px-2 py-1 text-sm placeholder-gray-300 ring-inset placeholder:text-sm focus:border-dm-brand-blue-500 focus:ring-0 focus:ring-dm-brand-blue-500"
                placeholder="Search skill or CC standard"
                onChange={handleChange}
              />
            </div>
            <div>
              {!viewByStandards && (
                <>
                  <p className="mb-2 text-lg font-bold">Year</p>
                  <Select
                    placeholder="All Years"
                    isMulti
                    name="colors"
                    options={yearOptions}
                    isClearable={true}
                    isSearchable={false}
                    closeMenuOnSelect={false}
                    className="w-96"
                    onChange={(newValue) => {
                      setFilteredYears(newValue.map((x) => x.value));
                    }}
                  />
                </>
              )}
            </div>
          </div>
          <Button onClick={toggleStandardView}>
            {viewByStandards ? "View by Year/Topic" : "View By Standards"}
          </Button>
        </div>
        <div className="flex flex-wrap gap-4 overflow-scroll pb-10">
          {!viewByStandards ? (
            <>
              {data?.years_order
                .filter(
                  (x) => filteredYears.length === 0 || filteredYears.includes(x)
                )
                .map((year, j) => {
                  const categoriesInYear = data.years[year];

                  return (
                    <>
                      {categoriesToShow
                        .filter((x) => x.yearcode === year)
                        .map((category, i) => {
                          const allSkills = category.skills.map((skill) => {
                            return {
                              ...data.skills[skill],
                              __index: skill,
                            };
                          });

                          const filteredSkillsForCat = category.skills
                            .filter((x) =>
                              filteredSkills.some((s) => s.__index === x)
                            )
                            .map((skill) => {
                              return {
                                ...data.skills[skill],
                                __index: skill,
                              };
                            });

                          if (category) {
                            return (
                              <CategoryDisplay
                                category={category}
                                color={categoriesInYear.color}
                                year={categoriesInYear.abbrev}
                                skills={allSkills}
                                filteredSkills={filteredSkillsForCat}
                                opened={openedCategories.some(
                                  (c) =>
                                    c ===
                                    `${categoriesInYear.abbrev}${category.name}`
                                )}
                                onOpen={() =>
                                  openCategory(
                                    `${categoriesInYear.abbrev}${category.name}`
                                  )
                                }
                                onClose={() =>
                                  closeCategory(
                                    `${categoriesInYear.abbrev}${category.name}`
                                  )
                                }
                                skillAdd={props.skillAdd}
                                key={`category-${year}-${category.code}-${i}-${j}`}
                              />
                            );
                          }
                        })}
                    </>
                  );
                })}
            </>
          ) : (
            <>
              <StandardSelect skillAdd={props.skillAdd} skillCodes={data} />
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SkillCodes;
