import {
  PropsWithChildren,
  createContext,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  GetProblem,
  LearnerAssignment,
  LogData,
  Problem,
  ProblemIds,
} from "../types";
import { useDMQuery } from "../../utils";
import { obfuscate } from "../../student/utils";

interface ProblemSolvingContextState {
  assignment: LearnerAssignment;
  skillId: string;
  versionIndex: number | undefined;
  currentProblem: Problem | undefined;
  setCurrentProblem: (problem: Problem | undefined) => void;
  logData: LogData | undefined;
  setLogData: (logData: LogData | undefined) => void;
  attempts: number;
  setAttempts: (attempts: number) => void;
  problemData: GetProblem | undefined;
  resetCurrentProblem: () => void;
  isLoadingProblem: boolean;
  questionSwap: string | undefined;
}

const ProblemSolvingContext = createContext<ProblemSolvingContextState>({
  assignment: {} as LearnerAssignment,
  skillId: "",
  versionIndex: undefined,
  currentProblem: undefined,
  questionSwap: undefined,
  setCurrentProblem: () => {
    return;
  },
  logData: undefined,
  setLogData: () => {
    return;
  },
  attempts: 0,
  setAttempts: () => {
    return;
  },
  problemData: undefined,
  resetCurrentProblem: () => {
    return;
  },
  isLoadingProblem: false,
});

/**
 * Component that manages several aspects of problem solving state including
 * the current problem, log data, and attempt count
 */
export const ProblemSolvingContextProvider: React.FC<
  PropsWithChildren<{
    skillId: string;
    assignment: LearnerAssignment;
    versionIndex: number | undefined;
    questionSwap: string | undefined;
    setProblemIds: (ids: ProblemIds | undefined) => void;
  }>
> = ({
  skillId,
  assignment,
  versionIndex,
  questionSwap,
  setProblemIds,
  children,
}) => {
  const [currentProblem, setProblem] = useState<Problem | undefined>();
  const [logData, setLogData] = useState<LogData | undefined>();
  const [attempts, setAttempts] = useState<number>(0);
  const [isLoadingProblem, setIsLoadingProblem] = useState(true);

  const {
    data: problemData,
    refetch: triggerProblemQuery,
    isSuccess: problemSuccess,
    isError: problemError,
  } = useDMQuery<GetProblem>({
    path: `/learner/assignment/problem/${skillId}?assignmentId=${
      assignment._id
    }${versionIndex !== undefined ? `&version=${versionIndex}` : ""}`,
    queryOptions: {
      enabled: false,
      refetchOnMount: false,
      retryOnMount: false,
      gcTime: 1,
    },
  });

  useEffect(() => {
    if (problemSuccess) {
      setLogData(problemData.problem.log_data);
      setCurrentProblem({
        ...problemData.problem,
        lines: problemData.problem.lines || [],
        data:
          typeof problemData.problem.data === "string"
            ? obfuscate(`${problemData.problem._id}`).reveal(
                `${problemData.problem.data}`
              )
            : problemData.problem.data,
      });

      setAttempts(
        problemData.problem.attempts ? problemData.problem.attempts.used : 0
      );
      setIsLoadingProblem(false);
    }
  }, [problemSuccess, problemData]);

  useEffect(() => {
    if (problemError) {
      setIsLoadingProblem(false);
    }
  }, [problemError]);

  const setCurrentProblem = (problem: Problem | undefined) => {
    setProblem(problem);
    if (!problem) {
      setProblemIds(undefined);
    } else {
      setProblemIds({
        problemId: problem._id,
        skillId: problem.skillcode,
      });
    }
  };

  const fetchProblem = () => {
    setIsLoadingProblem(true);
    triggerProblemQuery();
  };

  return (
    <ProblemSolvingContext.Provider
      value={{
        assignment,
        skillId,
        versionIndex,
        currentProblem,
        setCurrentProblem,
        logData,
        setLogData,
        attempts,
        setAttempts,
        problemData,
        questionSwap,
        resetCurrentProblem() {
          setLogData(undefined);
          setCurrentProblem(undefined);

          // Without the setTimeout here the fetchProblem call
          // uses the old skillId. This gives the state time to
          // update before triggering the fetch.
          setTimeout(() => {
            fetchProblem();
          }, 1);
        },
        isLoadingProblem,
      }}
    >
      {children}
    </ProblemSolvingContext.Provider>
  );
};

/** Hook to get problem solving context */
export function useProblemSolvingContext() {
  return useContext(ProblemSolvingContext);
}
