import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { deltamathAPI } from "../../manager/utils";
import { withJsonHeader } from "../../shared/axiosUtils";

/** Hook to that navigates to the correct url for the user to update their payment information */
export function usePaymentUpdate() {
  const { mutate } = useMutation<{ data: { url: string } }, unknown>({
    mutationFn: () => {
      return axios.post(
        `${deltamathAPI()}/payments/subscriptions/update/payment-method`,
        JSON.stringify({
          url: window.location.origin + window.location.pathname,
        }),
        withJsonHeader()
      );
    },
    onSuccess: (data) => {
      window.location.href = data.data.url;
    },
  });

  return { updatePaymentMethod: mutate };
}
