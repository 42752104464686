import { useQuery } from "@tanstack/react-query";
import { executeQuery } from "../../../utils";
import { CurrencyCard, NumberCard, UserPercentageCard } from "./Cards";

export const FinancialsStatsCards: React.FC = () => {
  const { isLoading, data } = useQuery<
    unknown,
    unknown,
    {
      lifetimeRevenue: { gross: number; net: number };
      recurringRevenue: { monthly: number; annual: number };
      learnerStats: {
        last24Hours: number;
        last7Days: number;
        last30Days: number;
        allTime: number;
      };
      numActiveLearners: number;
    }
  >({
    queryKey: ["financialsStats"],
    queryFn: () =>
      executeQuery({ path: "/payments/reports/learner/financials/stats/all" }),
    refetchOnWindowFocus: false,
  });

  if (!isLoading && !data) {
    return (
      <div className="py-10 text-center text-dm-error-500">
        <p>Unable to load financial stats</p>
      </div>
    );
  }

  return (
    <div className="grid grid-cols-5 gap-4">
      <CurrencyCard
        title="Lifetime gross revenue"
        value={data?.lifetimeRevenue.gross}
      />
      <NumberCard
        title="Total subscription quantity"
        value={data?.numActiveLearners}
      />
      <CurrencyCard
        title="Monthly recurring revenue"
        value={data?.recurringRevenue.monthly}
      />
      <CurrencyCard
        title="Annual recurring revenue"
        value={data?.recurringRevenue.annual}
      />
      <CurrencyCard
        title="Total annual revenue"
        value={
          data && data.recurringRevenue
            ? data.recurringRevenue.monthly * 12 + data.recurringRevenue.annual
            : undefined
        }
      />
      <CurrencyCard
        title="Lifetime net revenue"
        value={data?.lifetimeRevenue.net}
      />
      <UserPercentageCard
        title="Active learners - previous 24h"
        numerator={data?.learnerStats.last24Hours}
        denominator={data?.numActiveLearners}
      />
      <UserPercentageCard
        title="Active learners - previous 7d"
        numerator={data?.learnerStats.last7Days}
        denominator={data?.numActiveLearners}
      />
      <UserPercentageCard
        title="Active learners - previous 30d"
        numerator={data?.learnerStats.last30Days}
        denominator={data?.numActiveLearners}
      />
      <UserPercentageCard
        title="Active learners - all time"
        numerator={data?.learnerStats.allTime}
        denominator={data?.numActiveLearners}
      />
    </div>
  );
};
