import QuickbooksCard from "./QuickbooksCard";
import InvoiceDetailCard from "./InvoiceDetailCard";
import LicenseDetails from "./LicenseDetails";
import InvoiceSearch from "./InvoiceSearch";
import InvoicePageCard from "./InvoicePageCard";
import { useActivationContext } from "./LicenseActivationContext";
import IntegrationsCard from "./Integrations";
import ContactAdminDetails from "./ContactAdminDetails";
import LicenseToActivateDetails from "./LicenseToActivateDetails";
import SchoolDistrictDetailsForInvoice from "./SchoolDistrictDetails";
import Activate from "./Activate";
import LoadingCard from "./LoadingCard";
import { useDocumentTitle } from "usehooks-ts";
import { isRequestFromProd } from "../../../utils";

const QuoteManagement: React.FC = () => {
  useDocumentTitle(`${useActivationContext().state.quoteNumber}`);
  const activationContext = useActivationContext();

  const isDev = !isRequestFromProd(window.location.origin);

  //[MB] instances of LoadingCard here represent a compromise between UI and our desire for consistency across the page. While invoice updates to push across the page when new data pulls down, I think we want a full unmount/mount of each component so editable states / saveable states are reset. At the same time, visually it kinda sucks to have the entire page go blank and re-load (which I had been doing via conditional render of the children prop in the LicenseActivationContext.)

  // Another approach would be some a useEffect in each component which manually resets specific state variables manually. While that approach offers more control, long-term it means something else to maintain (remembering to update said use effect any time more state variables are added). Ultimately, I'm happy to do whatever everyone agrees makes the most sense.

  // Build an array of nav links that correspond to our cards.
  // (These will only be useful if the corresponding card is rendered.)
  const navLinks = [
    { label: "Quickbooks", id: "quickbooks-estimate" },
    { label: "Quote Status", id: "quote-status" },
    { label: "Current License", id: "current-license" },
    { label: "School License", id: "current-school-license" },
    { label: "District License", id: "current-district-license" },
    { label: "Individual Licenses", id: "individual-licenses" },
    { label: "Individual Licenses", id: "current-individual-license(s)" },
    { label: "License Details", id: "license-details" },
    { label: "Contact Admin", id: "contact-admin-details" },
    { label: "School/District", id: "school-district-information" },
    { label: "Integrations", id: "integrations" },
    { label: "Activate", id: "activate-license" },
  ];

  // Scroll handler: scrolls smoothly to the referenced element
  const handleScroll = (element: HTMLElement) => {
    element.scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="flex w-full flex-col gap-4 p-4">
      <div className="inline-flex w-full justify-between">
        <InvoiceSearch quoteNumber={activationContext.state.quoteNumber} />
      </div>
      <div className="flex h-full flex-row">
        <div className="sticky top-8 h-full basis-1/6">
          <p className="mt-1 text-sm text-gray-600">
            {activationContext.qboEstimateData?.txnId && (
              <a
                className="text-indigo-500 hover:underline"
                href={`https://${
                  isDev ? "sandbox" : "app"
                }.qbo.intuit.com/app/estimate?txnId=${
                  activationContext.qboEstimateData?.txnId
                }`}
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-external-link mr-2 inline-block"
                >
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6M15 3h6v6M10 14 21 3" />
                </svg>
                Open Quickbooks Estimate
              </a>
            )}
          </p>
          <p className="mt-2 text-sm text-gray-600">
            {activationContext.qboInvoiceData?.Id && (
              <a
                className="text-indigo-500 hover:underline"
                href={`https://${
                  isDev ? "sandbox" : "app"
                }.qbo.intuit.com/app/invoice?txnId=${
                  activationContext.qboInvoiceData?.Id
                }`}
                target="_blank"
                rel="noreferrer"
              >
                <svg
                  width="24"
                  height="24"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="feather feather-external-link mr-2 inline-block"
                >
                  <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6M15 3h6v6M10 14 21 3" />
                </svg>
                Open Quickbooks Invoice
              </a>
            )}
          </p>
          {/* Navigation links for in-page scrolling */}
          <div className="ml-4 mt-4 flex flex-col gap-y-2">
            {navLinks.map(({ label, id }) => {
              const el = document.getElementById(`invoice-page-${id}`);
              if (!el) {
                return null;
              }
              return (
                <button
                  key={label}
                  className="text-left text-indigo-500 hover:underline"
                  onClick={() => handleScroll(el)}
                >
                  {label}
                </button>
              );
            })}
          </div>
        </div>
        <div className="flex basis-5/6 flex-row flex-wrap gap-y-4 overflow-auto">
          <div className="mr-4 grid w-full grid-cols-2 gap-x-2 gap-y-3">
            <QuickbooksCard
              quickbooksEstimateData={activationContext.qboEstimateData}
              quickbooksInvoiceData={activationContext.qboInvoiceData}
            />
            {!activationContext.fetchingInvoice &&
              activationContext.invoiceDetails && (
                <InvoiceDetailCard
                  data={activationContext.invoiceDetails}
                  quickbooksEstimateData={activationContext.qboEstimateData}
                />
              )}
            {!activationContext.fetchingInvoice &&
              !activationContext.invoiceDetails && (
                <InvoicePageCard title="Quote Status" fullWidth={true}>
                  <p>No DeltaMath invoice found.</p>
                </InvoicePageCard>
              )}
            {activationContext.fetchingInvoice && (
              <LoadingCard
                title="Invoice Details"
                fullWidth={true}
                editable={true}
              />
            )}

            {!activationContext.fetchingInvoice &&
              !(
                activationContext.invoiceDetails?.status === "Activated" ||
                (activationContext.invoiceDetails?.expiration &&
                  activationContext.invoiceDetails?.expiration <
                    Math.floor(new Date().getTime() / 1000))
              ) && (
                <LicenseDetails
                  initialLicenseData={activationContext.licenseData}
                />
              )}
            {activationContext.fetchingInvoice && (
              <LoadingCard title="Current License Details" />
            )}
            {!activationContext.fetchingInvoice &&
              activationContext.invoiceDetails?.type && (
                <LicenseToActivateDetails
                  invoiceType={activationContext.invoiceDetails?.type}
                />
              )}
            {activationContext.fetchingInvoice && (
              <LoadingCard title="License Details" editable={true} />
            )}
            {!activationContext.fetchingInvoice &&
              activationContext.invoiceDetails && (
                <ContactAdminDetails
                  quoteNumber={activationContext.state.quoteNumber || ""}
                  invoiceContactEmail={
                    activationContext.invoiceDetails.contactEmail || ""
                  }
                  invoiceAdminLast={activationContext.invoiceDetails.adminLast}
                  invoiceAdminFirst={
                    activationContext.invoiceDetails.adminFirst
                  }
                  invoiceAdminEmail={
                    activationContext.invoiceDetails.adminEmail
                  }
                  invoiceContactName={
                    activationContext.invoiceDetails.contactName || ""
                  }
                />
              )}
            {activationContext.fetchingInvoice && (
              <LoadingCard title="Contact / Admin Details" editable={true} />
            )}
            {!activationContext.fetchingInvoice && (
              <SchoolDistrictDetailsForInvoice />
            )}
            {activationContext.fetchingInvoice && (
              <LoadingCard
                title="School / District Information"
                editable={true}
              />
            )}

            {!activationContext.fetchingInvoice &&
              activationContext.invoiceDetails &&
              activationContext.invoiceDetails.type !== "individual" &&
              activationContext.invoiceDetails.licenseTier === "Integral" && (
                <IntegrationsCard data={activationContext.invoiceDetails} />
              )}
            {activationContext.invoiceDetails?.status !== "Activated" && (
              <div className="col-span-2 mb-2">
                <Activate />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default QuoteManagement;
