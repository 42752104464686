import { useQuery } from "@tanstack/react-query";
import {
  FormattedLearnerSubscriptionPlan,
  LearnerSubscriptionDetails,
} from "../types";
import { executeQuery } from "../../utils";
import { useLearnerContext } from "../contexts/LearnerContext";

export function useSubscription(): {
  subscription: LearnerSubscriptionDetails | undefined;
  plan: FormattedLearnerSubscriptionPlan | undefined;
  isLoading: boolean;
  refetchSubscription: () => Promise<void>;
} {
  const { learner } = useLearnerContext();
  const {
    data: subscriptionData,
    isLoading,
    refetch: refetchSubscriptionData,
  } = useQuery<{
    subscription: LearnerSubscriptionDetails;
    plan: FormattedLearnerSubscriptionPlan;
  }>({
    queryKey: ["/payments/subscriptions/active"],
    queryFn: () => executeQuery({ path: "/payments/subscriptions/active" }),
    refetchOnWindowFocus: false,
    staleTime: 1000 * 60 * 5, // 5 minutes
    enabled: learner?.entitlements.includes("billing"),
  });

  return {
    subscription: subscriptionData?.subscription,
    plan: subscriptionData?.plan,
    isLoading,
    refetchSubscription: async () => {
      await refetchSubscriptionData();
    },
  };
}
