import { useDocumentTitle } from "usehooks-ts";
import { DataTableContextProvider } from "../DataTable/DataTableContext";
import { DataTable } from "../DataTable/DataTable";
import { FinancialsStatsCards } from "./FinancialsStatsCards";
import { isRequestFromProd } from "../../../utils";
import { ExternalStatsLink } from "./ExternalStatsLink";
import { StatsSearchForm } from "./StatsSearchForm";

export const LearnerFinancialsPage: React.FC = () => {
  useDocumentTitle("DMH Financials");
  return (
    <DataTableContextProvider
      source="/payments/reports/learner/financials/weekly"
      defaults={{ sortBy: [{ id: "dateRange", desc: true }] }}
      sortable={[
        "dateRange",
        "total",
        "newSubscribers",
        "churnedSubscribers",
        "netSubscribers",
        "trialists",
        "conversionRate",
      ]}
      fetchOnLoad
    >
      <FinancialsContent />
    </DataTableContextProvider>
  );
};

const FinancialsContent: React.FC = () => {
  return (
    <div className="flex flex-col gap-8 p-6">
      <h1 className="text-2xl font-bold">DeltaMath for Home Financials</h1>

      <StatsSearchForm />

      <div className="flex flex-col gap-4">
        <FinancialsStatsCards />

        <div className="grid grid-cols-2 gap-4">
          <ExternalStatsLink
            to={
              isRequestFromProd(window.location.origin)
                ? "https://dashboard.stripe.com/billing/revenue"
                : "https://dashboard.stripe.com/test/billing/revenue"
            }
          >
            See more detailed subscriber stats in <b>Stripe</b>
          </ExternalStatsLink>

          <ExternalStatsLink
            to={
              "https://mixpanel.com/project/3357715/view/3864507/app/boards#id=7672663"
            }
          >
            See more detailed product stats in <b>Mixpanel</b>
          </ExternalStatsLink>
        </div>
      </div>

      <DataTable />
    </div>
  );
};
