import { useQuery } from "@tanstack/react-query";
import { Experiment } from "./types";
import { executeQuery } from "../../../utils";

export const EXPERIMENTS_LIST = "experiments/list/all";
export function useExperimentsListQuery() {
  return useQuery<{
    experiments: Experiment[];
  }>({
    queryKey: [EXPERIMENTS_LIST],
    queryFn: () => executeQuery({ path: "/experiments/list/all" }),
    staleTime: 1000 * 60 * 5, // 5 minutes
    refetchOnWindowFocus: false,
    retry: false,
  });
}

export const EXPERIMENT_ITEM = "experiments/get";
export function useExperimentItemQuery(experimentId: string) {
  return useQuery<{
    experiment: Experiment;
  }>({
    queryKey: [EXPERIMENT_ITEM, experimentId],
    queryFn: () => executeQuery({ path: `/experiments/get/${experimentId}` }),
    refetchOnWindowFocus: false,
    retry: false,
  });
}
