import { useCallback } from "react";
import { AnalyticsEvent } from "./analytics/types";
import { getAnalyticsInstance } from "./analytics/getAnalyticsInstance";
import { ANALYTICS_PLUGINS } from "./analytics/plugins";

interface AnalyticsApi {
  /** Track a full page load or navigation */
  pageView: (
    data?: Record<string, any>,
    options?: Record<string, any>,
    callback?: () => void
  ) => void;
  /** Track that an event has occurred */
  track: (payload: AnalyticsEvent<any>) => void;
  /** Reset analytics state */
  reset: () => void;
}

export const combinePluginsObject = (plugins: Record<string, boolean>) => {
  return Object.keys(ANALYTICS_PLUGINS).reduce(
    (
      acc: Record<string, boolean>,
      plugin: typeof ANALYTICS_PLUGINS[keyof typeof ANALYTICS_PLUGINS]
    ) => {
      acc[plugin] = plugins[plugin] ?? false;
      return acc;
    },
    {} as Record<string, boolean>
  );
};

/** Hook to expose analytics functions */
export function useAnalytics(): AnalyticsApi {
  const analytics = getAnalyticsInstance();

  // useCallback prevents this function from firing multiple times
  // when included in useEffect
  const pageView = useCallback(
    (
      data?: Record<string, any>,
      options?: Record<string, any>,
      callback?: () => void
    ) => {
      const optionsToSend = options || {};
      if (options?.plugins) {
        optionsToSend.plugins = combinePluginsObject(options.plugins);
      }
      analytics.page(data, optionsToSend, callback);
    },
    []
  );

  // useCallback prevents this function from firing multiple times
  // when included in useEffect
  const track = useCallback((payload: AnalyticsEvent<any>) => {
    const pluginsAsObject = {
      ...payload.plugins.reduce(
        (
          acc: Record<string, boolean>,
          plugin: typeof ANALYTICS_PLUGINS[keyof typeof ANALYTICS_PLUGINS]
        ) => {
          acc[plugin] = true;
          return acc;
        },
        {} as Record<string, boolean>
      ),
    };
    const combinedPluginsObject = combinePluginsObject(pluginsAsObject);
    analytics.track(payload.event, payload.properties, {
      plugins: {
        ...combinedPluginsObject,
      },
    });
  }, []);

  // useCallback prevents this function from firing multiple times
  // when included in useEffect
  const reset = useCallback(() => {
    analytics.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    pageView,
    track,
    reset,
  };
}
