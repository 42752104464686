import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { deltamathAPI } from "../../utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { timeConverter } from "../../../utils";
import { IInvoice } from "../../utils/quoteUtils";
import { IProcessedEstimate } from "../../utils/quickbookDataUtils";
import { XIcon } from "@heroicons/react/solid";
import clsx from "clsx";
import InvoicePageEditableCard from "./InvoicePageEditableCard";
import { useActivationContext } from "./LicenseActivationContext";
import { DmLoadingSpinner } from "../../utils/functions";
import Warnings from "./Warnings";

const InvoiceDetailCard: React.FC<{
  data: IInvoice;
  quickbooksEstimateData?: IProcessedEstimate;
}> = ({ data, quickbooksEstimateData }) => {
  const [edit, setEdit] = useState(data?.status !== "Activated");
  const [showEditButton, setShowEditButton] = useState(
    data?.status === "Activated"
  );
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [showAddPONumber, setShowAddPONumber] = useState(false);
  const [selectedPDF, setSelectedPDF] = useState<File | undefined>(undefined);
  const [quoteStatus, setQuoteStatus] = useState<IInvoice["status"]>(
    data?.status
  );
  const [quoteNotes, setQuoteNotes] = useState<IInvoice["notes"]>(data?.notes);

  const [poNumber, setPoNumber] = useState<string | undefined>(data?.poNumber);

  const [statusSummary, setStatusSummary] = useState<
    "active" | "expired" | "pending" | "none"
  >("none");

  const [uploadingPDF, setUploadingPDF] = useState(false);

  const activationContext = useActivationContext();
  const queryClient = useQueryClient();
  const toastContext = useDeltaToastContext();
  const fileInputRef = useRef<HTMLInputElement>(null);
  const globalSaveDisabled = activationContext.state.globalSaveDisabled;

  const uploadPDF = () => {
    setUploadingPDF(true);
    if (
      selectedPDF &&
      quickbooksEstimateData?.quoteNumber &&
      quickbooksEstimateData?.txnId
    ) {
      const formData = new FormData();
      formData.append("file", selectedPDF);
      formData.append("quoteNumber", quickbooksEstimateData.quoteNumber);
      formData.append("quoteId", quickbooksEstimateData.txnId);
      if (!saveDisabled) {
        formData.append("status", quoteStatus || "");
        formData.append("notes", quoteNotes?.trim() || "");
        formData.append("poNumber", poNumber?.trim() || "");
      }
      axios({
        method: "post",
        url:
          deltamathAPI() + `/manager_new/quickbooks/add_quickbooks_attachment`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          if (response.status === 200) {
            toastContext.addToast({
              status: "Success",
              message: "Upload complete! Thanks!",
            });
            if (fileInputRef && fileInputRef.current) {
              fileInputRef.current.value = "";
            }
          }
        })
        .catch(function (response) {
          toastContext.addToast({
            status: "Error",
            message: "Error uploading file: " + JSON.stringify(response),
          });
          console.error(response);
        })
        .finally(() => {
          setUploadingPDF(false);
          queryClient.invalidateQueries({
            queryKey: ["invoice", `${data?.quoteNumber}`],
          });
        });
    }
  };

  const handleUploadClick = () => {
    uploadPDF();
  };

  const { mutate: deletePO, isPending: deletingPDF } = useMutation({
    mutationFn: () => {
      return axios.delete(
        deltamathAPI() + `/manager_new/invoices/${data?.quoteNumber}/delete_po`
      );
    },
    onSuccess: () => {
      toastContext.addToast({
        status: "Success",
        message: "PO Deleted",
      });
    },
    onError: (error) => {
      toastContext.addToast({
        status: "Error",
        message: error.message || "Error deleting PO: " + JSON.stringify(error),
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["invoice", `${data?.quoteNumber}`],
      });
    },
  });

  const handleLocalSave = () => {
    const body = {
      status: quoteStatus,
      notes: quoteNotes?.trim() || "",
      ...(typeof poNumber === "string" ? { poNumber: poNumber?.trim() } : {}),
    };
    activationContext.simpleQuoteUpdate(body);
  };

  const handleStatusChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setQuoteStatus(e.target.value as IInvoice["status"]);
  };

  const handleNotesChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setQuoteNotes(e.target.value);
  };

  const handlePoNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPoNumber(e.target.value);
  };

  useEffect(() => {
    if (
      quoteStatus === "PO Received (Good)" ||
      quoteStatus === "PO Received (Needs Updating)" ||
      (data?.status === "Activated" && data?.poNumber)
    ) {
      setShowAddPONumber(true);
    } else {
      setShowAddPONumber(false);
    }
  }, [quoteStatus, data?.status]);

  useEffect(() => {
    if (
      globalSaveDisabled ||
      ((quoteStatus === data?.status || (!quoteStatus && !data?.status)) &&
        (quoteNotes?.trim() === data?.notes?.trim() ||
          (!quoteNotes && !data?.notes)) &&
        (poNumber === data?.poNumber || (!poNumber && !data?.poNumber)))
    ) {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  }, [quoteStatus, quoteNotes, poNumber, globalSaveDisabled]);

  useEffect(() => {
    if (data?.status === "Activated" && !edit) {
      setShowEditButton(true);
    } else {
      setShowEditButton(false);
    }
  }, [data?.status, !edit]);

  //set the overallStatus - used to determine the color coding for a quick visual representation of the status
  useEffect(() => {
    switch (quoteStatus) {
      case "Activated":
        if (
          data?.expiration &&
          data.expiration < Math.floor(new Date().getTime() / 1000)
        ) {
          setStatusSummary("expired");
        } else {
          setStatusSummary("active");
        }
        break;
      case "PO Received (Good)":
      case "PO Received (Needs Updating)":
      case "CC/Check Payment Requested":
      case "CC/Check Payment Received":
        setStatusSummary("pending");
        break;
      case "":
        setStatusSummary("none");
        break;
      default:
        setStatusSummary("none");
        break;
    }
  }, [quoteStatus, data?.expiration]);

  return (
    <InvoicePageEditableCard
      title={"Quote Status"}
      fullWidth={true}
      saveDisabled={saveDisabled}
      handleLocalSave={handleLocalSave}
      setEdit={setEdit}
      showEditButton={showEditButton}
    >
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="flex flex-row items-center gap-x-1">
          <div
            className={clsx(
              `inline-block h-4 w-4 rounded-full`,
              statusSummary === "active"
                ? "bg-green-500"
                : statusSummary === "expired"
                ? "bg-red-500"
                : statusSummary === "pending"
                ? "bg-yellow-500"
                : "bg-gray-500"
            )}
          ></div>
          <dt className="mx-1 text-sm font-medium text-gray-500">Status</dt>
          <dd className="text-sm text-gray-900">
            {edit &&
            activationContext.invoiceDetails?.status !== "Activated" ? (
              <select
                className="w-full border-gray-300 md:rounded-md"
                value={quoteStatus || ""}
                onChange={handleStatusChange}
              >
                <option value="">--</option>
                <option value="PO Received (Needs Updating)">
                  PO Received -- needs updating
                </option>
                <option value="PO Received (Good)">
                  {"PO Received (Good)"}
                </option>
                <option value="CC/Check Payment Requested">
                  CC/Check Payment Requested
                </option>
                <option value="CC/Check Payment Received">
                  CC/Check Payment Received
                </option>
              </select>
            ) : (
              <div>{quoteStatus || "--"}</div>
            )}
          </dd>
          <Warnings>
            {
              <div className="ml-4 flex flex-col text-sm">
                {activationContext.state.activationDisableReasons.has(
                  "status"
                ) && (
                  <div className="text-red-500">
                    Status must be CC/Check Payment Received or PO Received
                    (Good) to activate{" "}
                  </div>
                )}
                {activationContext.state.activationDisableReasons.has(
                  "purchaseOrder"
                ) && (
                  <div className="text-red-500">
                    {!data.poUploadedTime && (
                      <div>PO Must be uploaded to activate</div>
                    )}
                    {!data?.poNumber && (
                      <div>PO Number must be entered to activate</div>
                    )}
                  </div>
                )}
              </div>
            }
          </Warnings>
        </div>

        <div className="ml-2">
          {showAddPONumber && (
            <>
              <dt className="text-sm font-medium text-gray-500">PO Number</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {data.status !== "Activated" ? (
                  <input
                    className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                    type="text"
                    value={poNumber}
                    disabled={!edit}
                    onChange={handlePoNumberChange}
                  ></input>
                ) : (
                  <dd className="mt-1 text-sm text-gray-900">{poNumber}</dd>
                )}
              </dd>
            </>
          )}
        </div>

        <div className="">
          {quoteStatus !== "Activated" &&
          quickbooksEstimateData?.txnId &&
          !data?.poUploadedTime ? (
            <>
              <dt className="text-sm font-medium text-gray-500">
                Attach P.O. PDF
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                <input
                  className="form-control
                                m-0
                                block
                                w-full rounded border
                                border-solid
                                border-gray-300
                                bg-white bg-clip-padding
                                px-3 py-1.5 text-base
                                font-normal
                                text-gray-700
                                transition
                                ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
                  id="file-upload"
                  name="file-upload"
                  type="file"
                  ref={fileInputRef}
                  accept="application/pdf"
                  onChange={(event) => {
                    event.target.files && setSelectedPDF(event.target.files[0]);
                  }}
                />
                <button
                  className={clsx(
                    "mt-2 inline-flex items-center rounded border border-gray-300 px-2.5 py-1.5 text-xs font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2",
                    {
                      "cursor-not-allowed bg-gray-200 text-gray-400":
                        !selectedPDF || uploadingPDF,
                      "bg-white text-gray-700 hover:bg-gray-50":
                        selectedPDF && !uploadingPDF,
                    }
                  )}
                  type="button"
                  onClick={handleUploadClick}
                  disabled={!selectedPDF || uploadingPDF}
                >
                  {!uploadingPDF ? (
                    "Upload File"
                  ) : (
                    <DmLoadingSpinner
                      message="Uploading"
                      className="text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50"
                    />
                  )}
                </button>
              </dd>
            </>
          ) : (
            <>
              <dt className="text-sm font-medium text-gray-500">
                PO Uploaded:
              </dt>
              {data?.poInfo && (
                <dd className="mt-1 text-sm text-gray-900">
                  {data?.poInfo?.fileUrl ? (
                    <a
                      href={data.poInfo.fileUrl}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-blue-600 underline hover:text-blue-800"
                    >
                      {data.poInfo.filename}
                    </a>
                  ) : (
                    <span>{data?.poInfo?.filename}</span>
                  )}
                  {activationContext.invoiceDetails?.status !== "Activated" && (
                    <button
                      onClick={() => {
                        deletePO();
                      }}
                    >
                      {!deletingPDF ? (
                        <XIcon className="ml-1 inline h-6 w-6 text-red-600" />
                      ) : (
                        <DmLoadingSpinner
                          message="Deleting"
                          className="ml-1 inline h-6 w-6 text-red-600"
                        />
                      )}
                    </button>
                  )}
                </dd>
              )}
              <dd className="mt-1 text-sm text-gray-900">
                {data?.poUploadedTime
                  ? timeConverter(data?.poUploadedTime)
                  : "--"}
              </dd>
            </>
          )}
        </div>

        <div>
          <dt className="text-sm font-medium text-gray-500">Internal Notes</dt>
          <dd className="mt-1 text-sm text-gray-900">
            <textarea
              className="w-full disabled:border-none disabled:bg-gray-300 disabled:bg-opacity-50 disabled:text-black"
              rows={3}
              value={quoteNotes}
              onChange={handleNotesChange}
              disabled={!edit}
            ></textarea>
          </dd>
        </div>
      </dl>
    </InvoicePageEditableCard>
  );
};

export default InvoiceDetailCard;
