import axios from "axios";
import { useCallback, useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import {
  EXPIRATION_OCTOBER_2024,
  EXPIRATION_OCTOBER_2024_EXTENDED,
  deltamathAPI,
} from "../manager/utils";
import { useDeltaToastContext } from "../shared/contexts/ToasterContext";
import { useUserContext } from "../shared/contexts/UserContext";
import { getAdminParams } from "./utils";
import { useDMQuery } from "../utils";
import { EXCLUSION_LIST } from "../shared/constants";

export function RenewalBanner({
  schoolData,
  districtData,
  districtDataRefetch,
  schoolDataRefetch,
  isDistrictDataFetched,
  isSchoolDataFetched,
}: {
  schoolData: any;
  districtData: any;
  districtDataRefetch: () => void;
  schoolDataRefetch: () => void;
  isDistrictDataFetched: boolean;
  isSchoolDataFetched: boolean;
}) {
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const adminParams = getAdminParams();
  const isSchoolAdmin =
    adminParams.account_type === "school" ||
    adminParams.account_type === "super_school";
  const isDistrictAdmin =
    adminParams.account_type === "district" ||
    adminParams.account_type === "super_district";

  const [showBanner, setShowBanner] = useState<boolean>(false);
  const [showRenewalLink, setShowRenewalLink] = useState<boolean>(false);
  const [expires, setExpires] = useState<any>(false);
  const [expirationEpoch, setExpirationEpoch] = useState<number>(0);
  const [showExtendOption, setShowExtendOption] = useState(false);

  const now = new Date().getTime() / 1000;

  const { mutate: extendLicense, isPending: isExtendLicenseLoading } =
    useMutation({
      mutationFn: () => {
        const customer_service_token = localStorage.getItem(
          "customer_service_token"
        );
        const token = userContext.getJWT();
        return axios.post(
          deltamathAPI() + "/admin_new/license/extend",
          customer_service_token,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`,
            },
          }
        );
      },
      onSuccess: () => {
        // Note: cannot use query invalidation here, since these queries are not enabled by default.
        // This will only refetch the data if it has already been fetched for this user.
        if (isDistrictDataFetched) districtDataRefetch();
        if (isSchoolDataFetched) schoolDataRefetch();

        toastContext.addToast({
          status: "Success",
          message: "Your license has been extended.",
        });
      },
      onError() {
        toastContext.addToast({
          status: "Error",
          message: "Please contact DeltaMath for assistance.",
        });
      },
    });

  const { data: schoolLicenseTypeData } = useDMQuery<{
    currentLicenseType: "school" | "district";
  }>({
    path: "/admin_new/data/school_license_type",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
      enabled: isSchoolAdmin,
    },
  });

  const { data: renewalLinkData, isSuccess: renewalLinkIsSuccess } =
    useDMQuery<{
      success: boolean;
      link?: string;
      message?: string;
    }>({
      path: "/admin_new/data/renewal_link",
      queryOptions: {
        staleTime: 1000 * 60 * 15,
        refetchOnWindowFocus: false,
        enabled: showRenewalLink, // only attempt to get the data under certain conditions
      },
    });

  // NOTE: the use of this endpoint is odd, considering it is for learner -- currently, the feature flag system in the backend is designed for learner users. However, this endpoint is also accessible if you are NOT logged in to get a list of global feature flag names. A larger "todo" is update the feature flag system for ease of across multiple apps.
  const { data: renewalExtensionFlag } = useDMQuery<{
    flags: string[];
  }>({
    path: "/feature-flags/learner",
    queryOptions: {
      staleTime: 1000 * 60 * 15,
      refetchOnWindowFocus: false,
    },
  });

  const enableRenewalExtension = renewalExtensionFlag?.flags.includes(
    "enableRenewalExtension"
  );

  const onExclusionList = useCallback(
    (isDistrictAdmin: boolean, isSchoolAdmin: boolean) => {
      if (isDistrictAdmin && districtData) {
        if (Object.hasOwn(EXCLUSION_LIST, districtData.districtID)) return true;
      } else if (isSchoolAdmin && schoolData) {
        const exclusionSchool = schoolData.find((school: any) =>
          Object.hasOwn(EXCLUSION_LIST, school.schoolid)
        );
        if (exclusionSchool) return true;
      }
      return false;
    },
    [districtData, schoolData]
  );

  useEffect(() => {
    // early return if required data has not successfully fetched yet
    if (
      (!isDistrictAdmin && !isSchoolAdmin) || // not a valid admin
      (isSchoolAdmin && schoolLicenseTypeData === undefined) || // school admin needs school license type
      enableRenewalExtension === undefined
    )
      // is it covered by a feature flag? (needs to be either true or false)
      return;

    let expiration: undefined | number;

    if (isDistrictAdmin && districtData) {
      // Edge case 1: There is a district admin where there is only one school in the district covered by the license.
      // It is possible that they renewed on a school license, in which case they should not see the banner.
      if (schoolData) {
        const schoolsCoveredByDistrictLicense = schoolData?.filter(
          (school: any) => school.coveredByDistrictLicense
        );
        if (schoolsCoveredByDistrictLicense.length === 1) {
          const schoolExp =
            schoolsCoveredByDistrictLicense[0].dmLicense?.expiration;
          if (schoolExp && schoolExp > EXPIRATION_OCTOBER_2024) return;
        }
      }

      expiration = districtData.dmLicense?.expiration
        ? parseInt(districtData.dmLicense.expiration)
        : undefined;
    } else if (isSchoolAdmin && schoolData) {
      // Edge case 2: Don't show a banner to a school admin for where the school has a district license
      if (schoolLicenseTypeData?.currentLicenseType === "district") {
        setShowBanner(false);
        return;
      }

      const allExpirations = schoolData
        .filter((s: any) => s.dmLicense?.expiration !== undefined)
        .map((s: any) => parseInt(s.dmLicense.expiration));

      expiration = allExpirations.length
        ? Math.max(...allExpirations)
        : undefined;
    }

    const expToCheck = enableRenewalExtension
      ? EXPIRATION_OCTOBER_2024_EXTENDED
      : EXPIRATION_OCTOBER_2024;
    if (expiration && expiration <= expToCheck) {
      setShowBanner(true);
      const expirationToSet = new Date(expiration * 1000).toLocaleDateString(
        "en-US"
      );
      setExpires(expirationToSet);
      setExpirationEpoch(expiration);
      // Edge case 3: Only attempt to retrieve renewal link if the NCES is NOT on the exclusion list
      if (!onExclusionList(isDistrictAdmin, isSchoolAdmin))
        setShowRenewalLink(true);
      // Determine if the user should be shown the extension option
      if (enableRenewalExtension) {
        setShowExtendOption(expiration <= EXPIRATION_OCTOBER_2024);
      }
    }
  }, [
    districtData,
    schoolData,
    schoolLicenseTypeData,
    enableRenewalExtension,
    onExclusionList,
    isDistrictAdmin,
    isSchoolAdmin,
  ]);

  if (!showBanner || (showRenewalLink && !renewalLinkIsSuccess)) return <></>;

  return (
    <div className="relative bg-sky-400">
      <div className="px-3 py-3 sm:ml-0 sm:px-6 lg:ml-64 lg:px-8">
        <div className="text-center font-medium text-white sm:px-16">
          <p>
            Your license {now > expirationEpoch ? "expired " : "expires "}
            on {expires}.
            {enableRenewalExtension && showExtendOption && (
              <span className="block sm:ml-2 sm:inline-block">
                <button
                  className="font-bold underline hover:cursor-pointer"
                  onClick={() => extendLicense()}
                  disabled={isExtendLicenseLoading}
                >
                  Click here to extend
                </button>{" "}
                your license through October 15.
              </span>
            )}
          </p>
          {showRenewalLink &&
          renewalLinkData?.success &&
          renewalLinkData?.link ? (
            <p>
              {"Click "}
              <a
                href={renewalLinkData.link}
                target="_blank"
                rel="noreferrer"
                className="font-bold underline"
              >
                here
              </a>{" "}
              to see your renewal options. Email{" "}
              <a
                className="font-bold underline"
                href="mailto:orders@deltamath.com"
              >
                orders@deltamath.com
              </a>{" "}
              with any questions.
            </p>
          ) : (
            <p>
              Email{" "}
              <a
                className="font-bold underline"
                href="mailto:orders@deltamath.com"
              >
                orders@deltamath.com
              </a>{" "}
              for a renewal quote or if you have any questions.
            </p>
          )}
        </div>
      </div>
    </div>
  );
}
