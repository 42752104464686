import React, { useEffect, useState } from "react";
import { executeQuery, useDMQuery } from "../../../utils";
import { timeConverter } from "../../../utils";
import {
  districtLicenseQuoteTypes,
  ISchoolPopData,
  LicenseInfo,
  schoolIsCoveredByLicenseType,
  TGradeRange,
} from "../../utils/quoteUtils";
import SecondaryLicenseLookup from "./SecondaryLicenseLookup";

export const CurrentLicense = ({
  nces_id,
  name,
  zip,
  email,
  setCurrentlyIntegral,
  setCurrentLicenseInfo,
  setLinkToActivatedQuote,
  activateWarnings,
  quotedLicenseType,
  setShowDistQuoteForSingleSchool,
  setLicenseBasedWarnings,
}: {
  nces_id?: string;
  name?: string;
  zip?: string;
  email?: string;
  setCurrentlyIntegral: React.Dispatch<React.SetStateAction<boolean>>;
  setCurrentLicenseInfo: React.Dispatch<
    React.SetStateAction<LicenseInfo | undefined>
  >;
  setLinkToActivatedQuote: React.Dispatch<React.SetStateAction<string>>;
  activateWarnings: string[];
  quotedLicenseType?: string;
  setShowDistQuoteForSingleSchool: React.Dispatch<
    React.SetStateAction<boolean>
  >;
  setLicenseBasedWarnings: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  const [districtLicense, setDistrictLicense] = useState<any>();
  const [schoolLicense, setSchoolLicense] = useState<any>();
  const mainParams = new URLSearchParams({
    ...(nces_id && { id: nces_id }),
    ...(name && { name }),
    ...(zip && { zip }),
  });

  const [searchEmailAsPrimary, setSearchEmailAsPrimary] =
    useState<boolean>(false);
  const [searchEmailAsAny, setSearchEmailAsAny] = useState<boolean>(false);

  const [showSingleSchoolDistWarning, setShowSingleSchoolDistWarning] =
    useState<boolean>(false);
  const [showCoveredSchoolMessage, setShowCoveredSchoolMessage] =
    useState<boolean>(false);
  const [schoolHasBothLicenses, setSchoolHasBothLicenses] =
    useState<boolean>(false);

  const getParams = () => {
    if (!searchEmailAsPrimary && !searchEmailAsAny) {
      return mainParams;
    } else if (!searchEmailAsAny) {
      return new URLSearchParams({
        email: email || "",
      });
    } else {
      return new URLSearchParams({
        email: email || "",
        searchAllAdminTypes: "true",
      });
    }
  };

  const { data, isSuccess } = useDMQuery({
    path: `/manager_new/license/lookup?${getParams()}`,
    cacheKey: [
      "license",
      JSON.stringify(getParams()),
      `${searchEmailAsPrimary}`,
      `${searchEmailAsAny}`,
    ],
    customerServiceHeader: true,
    queryOptions: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 1000 * 60 * 5,
    },
  });

  useEffect(() => {
    if (isSuccess) {
      if (
        (typeof data === "undefined" || data === "") &&
        quotedLicenseType === "school_custom"
      ) {
        if (!searchEmailAsPrimary && !searchEmailAsAny) {
          setSearchEmailAsPrimary(true);
        } else if (!searchEmailAsAny) {
          setSearchEmailAsAny(true);
        }
      }
    }
  }, [isSuccess, data]);

  const getActivatedQuote = async (invoiceNumber: string) => {
    const data = await executeQuery({
      path: `/manager_new/invoices/findByInvoiceNumber/${invoiceNumber}`,
      method: "get",
    });
    return data;
  };

  //track whether a district license is for a single school (shouldn't happen, see note below)
  let singleSchoolOnDistLicense = false;
  useEffect(() => {
    setSchoolLicense(undefined);
    setDistrictLicense(undefined);
    setCurrentLicenseInfo(undefined);
    setCurrentlyIntegral(false);
    setShowCoveredSchoolMessage(false);
    setShowSingleSchoolDistWarning(false);
    if (data) {
      //When a site asks for a district license but the district only has a school, we activate the license as a school license. For some reason we do seem to have at least 1 case where a single school did have a district license (NCES: 250547002267). We want to make sure the license is still displayed in this case.
      singleSchoolOnDistLicense =
        data.Schools.filter(
          (school: ISchoolPopData) => school.coveredByDistLicense
        ).length === 1;

      //check for matched school license. Doing this before setting for dist/school license to detect difference between a school that only is covered by a district license and a school that has a district license and also its own school license.
      const matchedSchoolLicense = data.Schools.find(
        (s: { match: any; dmLicense: any }) => !!s.match
      );

      //The site license quote may be for a school license but the school may currently be covered by a district license. In this case, we still want to show the district license info.

      const schoolCoveredUnderDistLicense =
        !singleSchoolOnDistLicense &&
        matchedSchoolLicense?.coveredByDistLicense;
      const doubleLicensedSchool =
        matchedSchoolLicense?.dmLicense?.expiration * 1000 >= Date.now() &&
        schoolCoveredUnderDistLicense;
      setSchoolHasBothLicenses(doubleLicensedSchool);

      const schoolsCoveredByQuotedDistrictLicense =
        districtLicenseQuoteTypes.includes(quotedLicenseType || "")
          ? data.Schools.filter((school: ISchoolPopData) => {
              const licenseType = quotedLicenseType as TGradeRange;
              return schoolIsCoveredByLicenseType(licenseType, school);
            })
          : [];

      const eligibleSchools = data.Schools.filter(
        (school: ISchoolPopData) =>
          !["PK", "K"].includes(school.highGrade) &&
          (isNaN(parseInt(school.highGrade)) || parseInt(school.highGrade) > 5)
      );

      //When the quote is associated w/ a district NCES, we need to be able to account for the case where this combination of NCES and quoted product actually resulted in a school license on a prior activation. If there is only one school in the district that could have any license, we will associate the district w/ the school but only for 6-8 or 9-12 licenses. Also, it is possible that while there are multiple schools in the district, only one would be covered by the currently quoted license type.
      const districtQuoteForSingleSchool =
        data.District.match &&
        ["9-12", "6-8"].includes(quotedLicenseType || "") &&
        (schoolsCoveredByQuotedDistrictLicense.length === 1 ||
          eligibleSchools.length === 1);

      setShowSingleSchoolDistWarning(singleSchoolOnDistLicense);

      if (
        data.District &&
        data.District.districtID &&
        (data.District.districtID == nces_id ||
          singleSchoolOnDistLicense ||
          (schoolCoveredUnderDistLicense && !doubleLicensedSchool)) &&
        data.District.dmLicense &&
        typeof data.District.dmLicense.expiration !== "undefined"
      ) {
        //if district license is for a single school, primary admin may be not be flagged as "match" but may be in the data
        if (
          (singleSchoolOnDistLicense || schoolCoveredUnderDistLicense) &&
          (typeof data.District.match === "undefined" ||
            data.District.match === null)
        ) {
          if (
            data.Schools.filter(
              (school: ISchoolPopData) => school.coveredByDistLicense
            )[0].match?.email
          ) {
            data.District.match = data.Schools.filter(
              (school: ISchoolPopData) => school.coveredByDistLicense
            )[0].match;
          } else if (data.District.super_admin?.email) {
            data.District.match = data.District.super_admin;
          }
        }
        setDistrictLicense(data.District);
        setCurrentlyIntegral(data.District.dmLicense.has_integral);
        setCurrentLicenseInfo({
          expiration: data.District.dmLicense.expiration,
          integrations: data.District.dmLicense.integrations,
          licenseTier: data.District.dmLicense.has_integral
            ? "Integral"
            : "Plus",
          type: data.District.dmLicense.license_type,
          id: data.District.districtID,
          admin: data.District.match,
        });
      } else if (
        data.Schools &&
        data.Schools.length >= 1 &&
        !districtQuoteForSingleSchool
      ) {
        if (
          matchedSchoolLicense?.dmLicense?.expiration &&
          !singleSchoolOnDistLicense
        ) {
          setSchoolLicense(matchedSchoolLicense);
          setCurrentlyIntegral(matchedSchoolLicense?.dmLicense?.has_integral);
          setCurrentLicenseInfo({
            expiration: matchedSchoolLicense.dmLicense?.expiration,
            integrations: matchedSchoolLicense.dmLicense?.integrations,
            licenseTier: matchedSchoolLicense.dmLicense?.has_integral
              ? "Integral"
              : "Plus",
            type: matchedSchoolLicense.dmLicense?.license_type,
            id: matchedSchoolLicense.schoolid,
            ...(doubleLicensedSchool
              ? {
                  schoolAndDistLicense: {
                    licenseTier: data.District.dmLicense.has_integral
                      ? "Integral"
                      : "Plus",
                    integrations: data.District.dmLicense.integrations,
                    expiration: data.District.dmLicense.expiration,
                  },
                }
              : {}),
            admin: matchedSchoolLicense.match,
          });
        }
        if (schoolCoveredUnderDistLicense) {
          setDistrictLicense(data.District);
          setCurrentlyIntegral(
            data.District?.dmLicense?.has_integral ||
              matchedSchoolLicense?.dmLicense?.has_integral
          );
        }
      } else if (districtQuoteForSingleSchool) {
        //If the current quote is associated w/ a district id but the license was converted to a school license b/c there is only one school in the district, the 'match' property will come back on the district part of the license. For sake of consistency, we will set the match to the super_admin of the relevant schoool
        const theSchool =
          eligibleSchools?.length === 1
            ? eligibleSchools[0]
            : schoolsCoveredByQuotedDistrictLicense[0];
        if (theSchool.dmLicense?.expiration) {
          theSchool.match = theSchool.super_admin;
          setSchoolLicense(theSchool);
          setCurrentlyIntegral(theSchool?.dmLicense?.has_integral);
          setCurrentLicenseInfo({
            expiration: theSchool.dmLicense?.expiration,
            integrations: theSchool.dmLicense?.integrations,
            licenseTier: theSchool.dmLicense?.has_integral
              ? "Integral"
              : "Plus",
            type: theSchool.dmLicense?.license_type,
            id: theSchool.schoolid,
            admin: theSchool.match,
          });
        }
        setShowDistQuoteForSingleSchool(true);
      }
    }
  }, [data, quotedLicenseType]);
  useEffect(() => {
    if (activateWarnings.filter((e) => e !== "Zach").length > 0 && data) {
      const invoiceNumber =
        districtLicense?.dmLicense?.invoice ||
        schoolLicense?.dmLicense?.invoice;
      if (invoiceNumber) {
        getActivatedQuote(invoiceNumber).then((data) => {
          if (data.success) {
            setLinkToActivatedQuote(data.quote);
          }
        });
      }
    }
  }, [activateWarnings]);

  const licenseIsExpired =
    !schoolLicense && !districtLicense
      ? true
      : schoolLicense?.dmLicense?.expiration * 1000 < Date.now() ||
        districtLicense?.dmLicense?.expiration * 1000 < Date.now()
      ? true
      : false;

  return (
    <>
      <div className="mt-6">
        <div className="md:grid md:grid-cols-4 md:gap-6">
          <div className="md:col-span-1">
            <div className="px-4 sm:px-0">
              <h3 className="text-lg font-medium leading-6 text-gray-900">
                Current License
              </h3>
              <p className="mt-1 text-sm text-gray-600"></p>
            </div>
          </div>
          <div className="mt-5 rounded border-2 shadow-xl md:col-span-3 md:mt-0">
            <div className="overflow-hidden">
              <div className="bg-white px-4 py-5 sm:p-6">
                <dl className="grid grid-cols-2 gap-x-4 gap-y-2 sm:grid-cols-2">
                  {((!districtLicense && !schoolLicense) ||
                    licenseIsExpired) && (
                    <>
                      <h2 className="col-span-2 mb-1 mr-1">
                        No Current Schoolwide or Districtwide License Found
                      </h2>
                      {licenseIsExpired && (schoolLicense || districtLicense) && (
                        <h3>
                          <strong>See Below for Expired License Info</strong>
                        </h3>
                      )}
                    </>
                  )}
                  {((districtLicense?.dmLicense?.expiration &&
                    districtLicense?.dmLicense?.license_type) ||
                    schoolHasBothLicenses) && (
                    <div
                      className={`${
                        schoolHasBothLicenses ? "col-span-1" : "col-span-2"
                      }`}
                    >
                      <h2 className="text-lg font-medium leading-6 text-gray-900">
                        District License:
                      </h2>

                      <ul>
                        <li>
                          Invoice:{" "}
                          <strong>
                            {districtLicense?.dmLicense?.invoice || ""}
                          </strong>
                        </li>
                        <li>
                          District ID:{" "}
                          <strong>{districtLicense?.districtID || ""}</strong>
                        </li>
                        <li>
                          Expiration:{" "}
                          <strong>
                            {districtLicense?.dmLicense?.expiration &&
                              timeConverter(
                                districtLicense.dmLicense.expiration
                              )}
                          </strong>
                        </li>
                        <li>
                          Integrations:{" "}
                          <strong>
                            {districtLicense?.dmLicense?.integrations || "none"}
                          </strong>
                        </li>
                        <li>
                          License Tier:{" "}
                          <strong>
                            {districtLicense?.dmLicense?.has_integral
                              ? "Integral"
                              : "Plus"}
                          </strong>
                          {districtLicense?.dmLicense?.is_trial && " (Trial)"}
                        </li>
                        <li>
                          License Type:{" "}
                          <strong>
                            {districtLicense.dmLicense.license_type}
                          </strong>
                        </li>
                        <li>
                          Number of Students:{" "}
                          <strong>
                            {districtLicense?.dmLicense?.num_students ?? ""}
                          </strong>
                        </li>
                        <li>
                          Current Admin:{" "}
                          <strong>
                            {districtLicense?.match?.name ||
                              districtLicense.super_admin?.name ||
                              ""}

                            {districtLicense?.match?.email && (
                              <> - {districtLicense.match.email}</>
                            )}

                            {!districtLicense?.match?.email &&
                              districtLicense.super_admin?.email && (
                                <> - {districtLicense.super_admin?.email}</>
                              )}
                          </strong>
                        </li>
                        {showSingleSchoolDistWarning &&
                          ["9-12", "6-8"].includes(
                            districtLicense?.dmLicense?.license_type
                          ) && (
                            <li>
                              <strong className="text-red-500">
                                {`This is a ${districtLicense?.dmLicense?.license_type} district license that covers a single
                              school. Activating the old quote for this license
                              should have converted it to a school license.`}
                              </strong>
                            </li>
                          )}
                        {showCoveredSchoolMessage && (
                          <li>
                            <strong className="text-red-500">
                              This quote is for a School License but the school
                              is currently covered by a district license for
                              {" " + data?.District.districtName || ""}.
                            </strong>
                          </li>
                        )}
                      </ul>
                    </div>
                  )}

                  {schoolLicense && (
                    <div
                      className={`${
                        schoolHasBothLicenses ? "col-span-1" : "col-span-2"
                      }`}
                    >
                      <h2 className="text-lg font-medium leading-6 text-gray-900">
                        School License
                      </h2>
                      <ul>
                        <li>
                          Invoice:{" "}
                          <strong>{schoolLicense?.dmLicense?.invoice}</strong>
                        </li>
                        {schoolLicense?.schoolid && (
                          <li>
                            School ID:{" "}
                            <strong>{schoolLicense?.schoolid}</strong>
                          </li>
                        )}
                        <li>
                          Expiration:{" "}
                          <strong>
                            {schoolLicense?.dmLicense?.expiration &&
                              timeConverter(schoolLicense.dmLicense.expiration)}
                          </strong>
                        </li>
                        <li>
                          Integrations:{" "}
                          <strong>
                            {schoolLicense?.dmLicense?.integrations
                              ? schoolLicense.dmLicense.integrations
                              : "none"}
                          </strong>
                        </li>
                        <li>
                          License Tier:{" "}
                          <strong>
                            {schoolLicense?.dmLicense?.has_integral
                              ? "Integral"
                              : "Plus"}
                          </strong>
                          {schoolLicense?.dmLicense?.is_trial && " (Trial)"}
                        </li>
                        {schoolLicense?.dmLicense?.num_students > 0 && (
                          <li>
                            Number of Students:{" "}
                            <strong>
                              {schoolLicense?.dmLicense?.num_students}
                            </strong>
                          </li>
                        )}

                        {typeof schoolLicense?.dmLicense?.num_teachers !==
                          "undefined" && (
                          <li>
                            Number of Teachers:{" "}
                            <strong>
                              {schoolLicense?.dmLicense?.num_teachers}
                            </strong>
                          </li>
                        )}
                        <li>
                          Current Admin:{" "}
                          <strong>
                            {schoolLicense?.match?.name}

                            {schoolLicense?.match?.email && (
                              <> - {schoolLicense.match.email}</>
                            )}
                          </strong>
                        </li>
                      </ul>
                    </div>
                  )}
                </dl>

                <SecondaryLicenseLookup
                  email={email || ""}
                  name={name || ""}
                  initialTree={data}
                  quoteNCES={nces_id || ""}
                  setLicenseBasedWarnings={setLicenseBasedWarnings}
                  quotedLicenseType={quotedLicenseType}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CurrentLicense;
