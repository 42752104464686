import clsx from "clsx";
import { useDataTable } from "./DataTableContext";

export const TableFoot: React.FC = () => {
  const { isExpanded, pageCount, table } = useDataTable();
  return pageCount !== 1 ? (
    <footer
      className={clsx(
        "flex w-full justify-center gap-4 border-t px-4 py-2 text-sm text-dm-charcoal-500",
        isExpanded
          ? "sticky bottom-0 left-0 w-screen border-t-dm-charcoal-200 bg-dm-charcoal-100"
          : "rounded-b-lg border-t-dm-charcoal-100 bg-white"
      )}
    >
      <button
        className="h-8 w-8 rounded border border-dm-charcoal-200 bg-white !p-0 hover:border-dm-charcoal-500 disabled:opacity-50 disabled:hover:border-dm-charcoal-200"
        onClick={table.previousPage}
        disabled={!table.canPreviousPage}
      >
        <i className="fas fa-chevron-left" />
      </button>
      <span className="flex items-center gap-2 text-dm-charcoal-500">
        Page {table.state.pageIndex + 1} of {!pageCount ? "unknown" : pageCount}
      </span>
      <button
        className="h-8 w-8 rounded border border-dm-charcoal-200 bg-white !p-0 hover:border-dm-charcoal-500 disabled:opacity-50 disabled:hover:border-dm-charcoal-200"
        onClick={table.nextPage}
        disabled={!table.canNextPage}
      >
        <i className="fas fa-chevron-right" />
      </button>
    </footer>
  ) : (
    <></>
  );
};
