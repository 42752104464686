import React, { useEffect, Fragment } from "react";
import { UseQueryResult } from "@tanstack/react-query";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { IActivationPayload } from "./Invoice";

export interface IIntegrationData {
  integrationData: Array<{
    id: string;
    name: string;
    nces_id?: string;
    likelyMatch?: string;
  }>;
  diggerSchools: Array<{ nces_id: string; name: string }>;
  code?: number;
  message?: string;
  statusText?: string;
}

interface RosteringActivationProps {
  lmsIntegrationData: IIntegrationData | undefined;
  rosteringData: IActivationPayload["rosteringData"];
  payload: IActivationPayload;
  setPayload: React.Dispatch<React.SetStateAction<any>>;
  setRosteringData: React.Dispatch<React.SetStateAction<any>>;
  edit: boolean;
  setSaveDisabled: React.Dispatch<React.SetStateAction<boolean>>;
  refetch: UseQueryResult["refetch"];
}

const RosteringActivation: React.FC<RosteringActivationProps> = ({
  lmsIntegrationData,
  rosteringData,
  setRosteringData,
  payload,
  setPayload,
  edit,
  setSaveDisabled,
  refetch,
}) => {
  const toastContext = useDeltaToastContext();

  const searchRosteringData = (value: string) => {
    if (!rosteringData) return undefined;
    for (const key in rosteringData) {
      const match = rosteringData[key].find((x) => {
        if (typeof x === "string") {
          return x === value;
        } else {
          return x.id === value;
        }
      });
      if (match) {
        return key;
      }
    }
  };

  const updateRosteringData = (
    x: { id: string; name: string },
    val: string
  ) => {
    setRosteringData((prev: any) => {
      const updatedRosteringData = prev;
      for (const key in updatedRosteringData) {
        updatedRosteringData[key] = updatedRosteringData[key].filter(
          (val: any) => {
            if (val.id) {
              return val.id !== x.id;
            }
            return val !== x.id;
          }
        );
        if (updatedRosteringData[key].length === 0) {
          delete updatedRosteringData[key];
        }
      }

      if (val === "insert") {
        updatedRosteringData[val] = [...(updatedRosteringData[val] || []), x];
      } else {
        updatedRosteringData[val] = [
          ...(updatedRosteringData[val] || []),
          x.id,
        ];
      }

      setPayload({
        ...payload,
        rosteringData: updatedRosteringData,
      });

      return updatedRosteringData;
    });
  };

  useEffect(() => {
    if (lmsIntegrationData) {
      if (lmsIntegrationData?.code && lmsIntegrationData?.code >= 400) {
        toastContext.addToast({
          status: "Error",
          message: lmsIntegrationData?.message || "Error",
        });
      } else {
        // Remove elements no longer in scope
        const updatedRosteringData = { ...rosteringData };
        let update = false;
        for (const key in updatedRosteringData) {
          for (const val of updatedRosteringData[key]) {
            if (typeof val === "string") {
              if (
                !lmsIntegrationData.integrationData.find(
                  (x: any) => x.id === val
                )
              ) {
                updatedRosteringData[key] = updatedRosteringData[key].filter(
                  (x) => x !== val
                );
                update = true;
              }
            } else if (
              !lmsIntegrationData.integrationData.find(
                (x: any) => x.id === val.id
              )
            ) {
              updatedRosteringData[key] = updatedRosteringData[key].filter(
                (x) => x.id !== val.id
              );
              update = true;
            }
          }
          if (updatedRosteringData[key].length === 0) {
            delete updatedRosteringData[key];
            update = true;
          }
          if (update) {
            setRosteringData({ ...updatedRosteringData });
            setPayload((prev: IActivationPayload) => {
              return {
                ...prev,
                rosteringData: updatedRosteringData,
              };
            });
          }
        }

        // Apply latest integrationData, ensuring not to override any data from the payload
        for (const x of lmsIntegrationData.integrationData) {
          if (!searchRosteringData(x.id)) {
            updateRosteringData(x, x.likelyMatch ? x.likelyMatch : "skip");
          }
        }
      }
    }
  }, [lmsIntegrationData]);

  if (
    !lmsIntegrationData ||
    lmsIntegrationData.statusText ||
    (lmsIntegrationData.integrationData.length === 0 &&
      lmsIntegrationData.diggerSchools.length === 0)
  ) {
    return <></>;
  } else {
    return (
      <Fragment>
        <div className="relative flex items-center py-5">
          <div className="flex-grow border-t border-gray-400"></div>
          <span className="mx-4 flex-shrink text-gray-400">
            Rostering Activation
          </span>
          <div className="flex-grow border-t border-gray-400"></div>
        </div>

        {lmsIntegrationData && lmsIntegrationData.integrationData && (
          <dl className="grid grid-cols-1 items-center gap-x-2 gap-y-2 sm:grid-cols-2">
            {lmsIntegrationData.integrationData.map((x: any) => {
              return (
                <Fragment key={`${x.name}`}>
                  <div className="inline-flex sm:col-span-1">
                    <div className="">
                      <dt className="text-sm font-medium text-gray-500">
                        {x.name}
                      </dt>
                    </div>
                  </div>
                  <div className="inline-flex sm:col-span-1">
                    <div className="">
                      <dd className="mt-1 text-sm text-gray-900">
                        {edit ? (
                          <select
                            className="border-gray-300 md:rounded-md"
                            defaultValue={
                              searchRosteringData(x.id) ||
                              x.likelyMatch ||
                              "skip"
                            }
                            onChange={(e) => {
                              updateRosteringData(x, e.target.value);
                              setSaveDisabled(false);
                            }}
                          >
                            <option value="skip">Do not activate</option>
                            {payload.type === "school_custom" && (
                              <option value="generated">
                                Use {payload.id ? payload.id : "generated"}
                              </option>
                            )}
                            {payload.type &&
                              !["school_custom", "school"].includes(
                                payload.type
                              ) && (
                                <option value="insert">
                                  Insert new school
                                </option>
                              )}
                            {lmsIntegrationData.diggerSchools.map(
                              (sch: any) => {
                                return (
                                  <option key={sch.nces_id} value={sch.nces_id}>
                                    {sch.name}
                                  </option>
                                );
                              }
                            )}
                          </select>
                        ) : (
                          <></>
                        )}
                      </dd>
                    </div>
                  </div>
                </Fragment>
              );
            })}
          </dl>
        )}
      </Fragment>
    );
  }
};

export default RosteringActivation;
