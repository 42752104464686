import { useState } from "react";
import Modal from "../../../../student/components/generic/Modal";
import { ConfigPrize, PrizeModalProps } from "../../../types";
import { useMutation } from "@tanstack/react-query";
import { deltamathAPI } from "../../../../manager/utils";
import axios from "axios";
import { useLearnerContext } from "../../../contexts/LearnerContext";
import { useDeltaToastContext } from "../../../../shared/contexts/ToasterContext";
import { PrizeModalHeader } from "./PrizeModalHeader";
import { useStep, useMediaQuery } from "usehooks-ts";
import { StepIndicator } from "../../StepIndicator";
import { useLearnerAnalytics } from "../../../analytics/useLearnerAnalytics";
import { newAnimationEvent, newConfettiEvent } from "../../../analytics/events";

const NUM_STEPS = 2;

interface Props extends PrizeModalProps {
  prize: ConfigPrize;
  showConfig?: boolean;
}

export const ConfigPrizeModal = (props: Props) => {
  const learnerContext = useLearnerContext();
  const toastContext = useDeltaToastContext();
  const [config, setConfig] = useState<Record<string, any>>({});
  const [title, setTitle] = useState<string>("");
  const [confirmButtonText, setConfirmButtonText] = useState<string>("");
  const [confirmButtonDisabled, setConfirmButtonDisabled] =
    useState<boolean>(false);
  const [currentStep, { goToNextStep }] = useStep(NUM_STEPS);
  const { track } = useLearnerAnalytics();

  const [showConfig, setShowConfig] = useState<boolean>(
    props.showConfig === true
  );

  const isLargeModal = useMediaQuery("(max-width: 767px)");

  const { mutateAsync } = useMutation({
    mutationFn: (body: { prizeKey: string; config: object }) => {
      return axios.post(
        `${deltamathAPI()}/learner/data/prize`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
  });

  const onConfirm = async () => {
    if (showConfig) {
      setConfirmButtonDisabled(true);
      try {
        await mutateAsync({ prizeKey: props.prize.key, config });
        learnerContext.updateLearner({
          prizesState: {
            ...learnerContext?.state?.learner?.prizesState,
            [props.prize.key]: config,
          },
        });
        if (props.prize.key === "confetti") {
          track(
            newConfettiEvent({
              effect: config.type,
              shape: config.shape,
            })
          );
        } else if (props.prize.key === "loadingIcon") {
          track(newAnimationEvent({ animation: config.image }));
        }
      } catch (e) {
        toastContext.addToast({
          title: "Error",
          message: "Unable to save your choice",
          status: "Error",
        });
      }
      props.onClose();
    } else {
      goToNextStep();
      setShowConfig(true);
    }
  };

  // TODO: update button text / title according to prize for the configuration page
  return (
    <Modal
      visible={props.visible}
      onClose={props.onClose}
      largeModal={isLargeModal || (props.prize.isLargeModal && showConfig)}
      title={
        showConfig ? (
          <PrizeModalHeader>{title}</PrizeModalHeader>
        ) : (
          <PrizeModalHeader image={props.prize.image}>
            {props.prize.unlockTitle}
          </PrizeModalHeader>
        )
      }
      body={
        showConfig ? (
          <>
            <props.prize.ConfigComponent
              config={config}
              setConfig={setConfig}
              setTitle={setTitle}
              setConfirmButtonText={setConfirmButtonText}
              setConfirmButtonDisabled={setConfirmButtonDisabled}
            />
            {currentStep === NUM_STEPS && (
              <StepIndicator
                className="mt-10"
                totalSteps={NUM_STEPS}
                currentStep={currentStep}
              />
            )}
          </>
        ) : (
          <>
            <p>{props.prize.unlockText}</p>
            <StepIndicator
              className="mt-10"
              totalSteps={NUM_STEPS}
              currentStep={currentStep}
            />
          </>
        )
      }
      closeX={true}
      secondaryButtonText={
        currentStep === 1 && !showConfig ? "Maybe Later" : "Cancel"
      }
      secondaryOnClick={props.onClose}
      confirmButtonText={
        showConfig
          ? currentStep === 2
            ? confirmButtonText
            : "Save"
          : props.prize.configCta
      }
      confirmDisabled={showConfig ? confirmButtonDisabled : false}
      onConfirm={onConfirm}
    />
  );
};
