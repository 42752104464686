import axios from "axios";
import { useEffect, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { deltamathAPI } from "../../utils";
import {
  SearchResults,
  SkillCodes,
} from "DeltaMathAPI/routers/standards.router/api";
import { DmLoadingSpinner } from "../../utils/functions";
import DeltaMathConfirmation from "../../../shared/DeltaMathConfirmation";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { handleErr } from "./common/util";
import { Tooltip } from "../../../shared/Tooltip";
import { DeltaMathSelect } from "../../../shared/DeltaMathSelect";
import { useUserContext } from "../../../shared/contexts/UserContext";

const StandardsSearch = (props: {
  getSearchVal: string;
  setSearchVal: React.Dispatch<React.SetStateAction<string>>;
  getSearchResults:
    | { searchData: SearchResults; searchVal: string }
    | undefined;
  setSearchResults: React.Dispatch<
    React.SetStateAction<
      { searchData: SearchResults; searchVal: string } | undefined
    >
  >;
  skillcodes: SkillCodes;
}) => {
  const queryClient = useQueryClient();
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const {
    getSearchVal,
    setSearchVal,
    getSearchResults,
    setSearchResults,
    skillcodes,
  } = props;
  const [getSelectedPaths, setSelectedPaths] = useState<string[]>([]);
  const [getSelectedSkillCode, setSelectedSkillCode] = useState<string>("");
  const [getCourseFilter, setCourseFilter] = useState<
    { key: string; val: string }[]
  >([]);
  const [getShowAddModuleConfirmation, setShowAddModuleConfirmation] =
    useState(false);
  const [query, setQuery] = useState("");

  // Listen for changes to the search bar
  useEffect(() => {
    const getData = setTimeout(() => {
      if (
        getSearchVal.length >= 5 &&
        getSearchResults?.searchVal !== getSearchVal
      ) {
        search();
      } else if (getSearchVal && getSearchVal.length < 5) {
        toastContext.addToast({
          status: "Error",
          message: "Please enter at least 5 characters",
        });
      }
    }, 500);
    return () => clearTimeout(getData);
  }, [getSearchVal]);

  // Listen for changes to the searchResults
  useEffect(() => {
    if (getSearchResults) {
      setCourseFilter(
        Array.from(
          new Set(getSearchResults.searchData.map((result) => result[0].d))
        ).map((x) => ({ key: x, val: x }))
      );
    }
  }, [getSearchResults]);

  // Query the search
  const { refetch: search, isFetching } = useQuery<SearchResults>({
    queryKey: [`/standards/manage/search`],
    queryFn: async () => {
      return axios
        .request<SearchResults>({
          method: "post",
          url: deltamathAPI() + `/standards/manage/search`,
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          data: {
            val: getSearchVal,
          },
        })
        .then((response) => {
          setSearchResults({
            searchVal: getSearchVal,
            searchData: response.data,
          });
          setCourseFilter([]);
          return response.data;
        })
        .catch((e) => {
          toastContext.addToast({ status: "Error", message: handleErr(e) });
          return [];
        });
    },
    staleTime: 1000 * 60 * 15,
    enabled: false,
  });

  /**
   * Triggered when the user clicks the add module button
   */
  const addModule = () => {
    if (getSelectedSkillCode === "") {
      toastContext.addToast({
        status: "Error",
        message: "Please select a skill code",
      });
      return;
    }
    if (getSelectedPaths.length === 0) {
      toastContext.addToast({
        status: "Error",
        message: "Please select at least one standard",
      });
      return;
    }
    setShowAddModuleConfirmation(true);
  };

  /**
   * Submit an add module request to the api.
   */
  function addModuleRequest() {
    axios
      .request({
        method: "post",
        url: deltamathAPI() + "/standards/manage/bulkUpdate",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        data: {
          paths: getSelectedPaths,
          sk: getSelectedSkillCode,
        },
      })
      .then(() => {
        toastContext.addToast({
          status: "Success",
          message: "Module Added",
        });
        queryClient.invalidateQueries({ queryKey: ["standards-list"] });
      })
      .catch((e) => {
        toastContext.addToast({ status: "Error", message: handleErr(e) });
      });
  }

  return (
    <>
      {getShowAddModuleConfirmation &&
        skillcodes &&
        skillcodes[getSelectedSkillCode] && (
          <DeltaMathConfirmation
            title="Add Module"
            message={
              <>
                Are you sure you want to add the module{" "}
                <b>{skillcodes[getSelectedSkillCode].name}</b> to{" "}
                <b>{getSelectedPaths.length}</b> standards?
              </>
            }
            confirm="Add Module"
            confirmAction={() => {
              addModuleRequest();
              setShowAddModuleConfirmation(false);
            }}
            cancel="Cancel"
            cancelAction={() => setShowAddModuleConfirmation(false)}
          />
        )}

      <div className="sticky top-0 z-30 bg-slate-200 py-2">
        <label
          htmlFor="search"
          className="block flow-root text-sm font-medium leading-6 text-gray-900"
        >
          <div className="float-left inline-flex">
            <div className="mt-1.5">Search Standards...</div>
            <div className="-mt-1 w-96 pl-2 font-normal sm:text-sm sm:leading-6">
              {getCourseFilter && getCourseFilter.length > 0 && (
                <DeltaMathSelect
                  options={getCourseFilter}
                  onChangeFn={() => null}
                  filterable={true}
                  placeholder="Select a course..."
                  input={[query, setQuery]}
                />
              )}
            </div>
          </div>
          <div className="float-right">
            <div className="inline-flex">
              <div className="-mt-1 w-96 pr-2 font-normal sm:text-sm sm:leading-6">
                <DeltaMathSelect
                  options={Object.entries(skillcodes)
                    .sort((a, b) => {
                      if (a[1].name < b[1].name) {
                        return -1;
                      }
                      if (a[1].name > b[1].name) {
                        return 1;
                      }
                      return 0;
                    })
                    .map(([key, val]) => {
                      return {
                        key: key,
                        val: val.name,
                      };
                    })}
                  onChangeFn={setSelectedSkillCode}
                  filterable={true}
                  placeholder="Select a skill code..."
                />
              </div>
              <button
                type="button"
                className="focus:shadow-outline-dm-lightest-blue mt-0.5 inline-flex h-8 items-center rounded border border-transparent bg-dm-light-blue px-2.5 py-1.5 text-xs font-medium leading-4 text-white transition duration-150 ease-in-out hover:bg-dm-lightest-blue focus:border-dm-lightest-blue focus:outline-none active:bg-dm-lightest-blue"
                onClick={addModule}
              >
                Add Module
              </button>
            </div>
          </div>
        </label>
        <div className="relative mt-2 flex items-center">
          <input
            type="text"
            name="search"
            id="search"
            defaultValue={getSearchVal}
            className="block w-full rounded-md border-0 py-1.5 pl-4 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-dm-lightest-blue sm:text-sm sm:leading-6"
            onChange={(event) => setSearchVal(event.target.value)}
          />
        </div>
      </div>
      <div>
        {isFetching && <DmLoadingSpinner message="Loading..." />}
        {!isFetching &&
          getSearchResults &&
          getSearchResults.searchData.length === 0 && (
            <div className="py-4 text-center text-gray-500">
              No results found.
            </div>
          )}
        <ul role="list" className="divide-y divide-gray-100">
          {getSearchResults &&
            getSearchResults.searchData
              .filter((searchItem) =>
                query
                  ? searchItem[0].d
                      .toLocaleLowerCase()
                      .includes(query.toLocaleLowerCase())
                  : true
              )
              .map((searchItem) => {
                const standard = searchItem[searchItem.length - 1];
                const path = searchItem.map((item) => item.id).join(".");
                return (
                  <li
                    key={standard.id}
                    className="flex items-center gap-x-6 rounded-md bg-white px-4 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300"
                  >
                    <div className="pl-2">
                      <input
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-dm-lightest-blue focus:ring-dm-lightest-blue"
                        value={path}
                        checked={getSelectedPaths.includes(path)}
                        onChange={(e) =>
                          setSelectedPaths(
                            e.target.checked
                              ? [...getSelectedPaths, path]
                              : getSelectedPaths.filter((p) => p !== path)
                          )
                        }
                      />
                    </div>

                    <div className="min-w-0">
                      <div className="mt-1 flex items-center gap-x-2 text-xs leading-5 text-gray-500">
                        {searchItem.map((item, index) => {
                          const text = item.l?.trim()
                            ? item.l
                            : item.d.length > 15
                            ? item.d.substring(0, 15) + "..."
                            : item.d;

                          return (
                            <span key={item.id} className="inline-flex">
                              {index === 0 && (
                                <>
                                  <p className="whitespace-nowrap">{item.c}</p>
                                  <p className="pl-2 pr-2">&gt;</p>
                                </>
                              )}
                              <Tooltip message={item.d}>
                                <p className="whitespace-nowrap">{text}</p>
                              </Tooltip>
                              {index < searchItem.length - 1 && (
                                <p className="pl-2">&gt;</p>
                              )}
                            </span>
                          );
                        })}
                      </div>
                      <div className="flex items-start gap-x-3">
                        <p className="text-sm font-semibold leading-6 text-gray-900">
                          {standard.d}
                        </p>
                      </div>
                      <div>
                        {standard.m &&
                          standard.m.map((m) => (
                            <span
                              key={m}
                              className="inline-flex items-center rounded-md px-2 py-1 text-xs font-medium ring-1 ring-inset ring-dm-lightest-blue"
                            >
                              {skillcodes[m].name}
                            </span>
                          ))}
                      </div>
                    </div>
                  </li>
                );
              })}
        </ul>
      </div>
    </>
  );
};

export default StandardsSearch;
