import { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { TrashIcon } from "@heroicons/react/outline";
import { REACT_APP_ADMIN_LINK, formatNumber } from "../../../utils";
import { NoFilter } from "../../../shared/table/filters";
import DeltaMathTable from "../../../shared/DeltaMathTable";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import DeltaMathConfirmation from "../../../shared/DeltaMathConfirmation";
import { DemoMode } from "../../../utils/demo";
import { useUserContext } from "../../../shared/contexts/UserContext";

export default function CurrentTeachers({
  teacherDataisLoading,
  teachers,
  teacherDataForTable,
}: {
  teacherDataisLoading: any;
  teachers: any;
  teacherDataForTable: any;
}) {
  const params = useParams();
  const userContext = useUserContext();
  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();
  const token = userContext.getJWT();

  const [userToDelete, setUserToDelete] = useState({
    id: "",
    first: "",
    last: "",
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);

  const removeTeacher = useMutation({
    mutationFn: (body: { teachercode: string; schoolId: string }) => {
      return axios.post(
        deltamathAPI() + "/admin_new/manage/remove_teacher",
        body,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: ["/admin_new/data/teacher"],
      });
      setShowDeleteConfirmation(false);
      toastContext.addToast({
        status: "Success",
        message: "Teacher Account deleted.",
      });
    },
    onError: () => {
      setShowDeleteConfirmation(false);
      toastContext.addToast({
        status: "Error",
        message: "There was an error with the request",
      });
      setUserToDelete({
        id: "",
        first: "",
        last: "",
      });
    },
  });

  const teachersTableColumns = [
    {
      Header: "Teacher",
      accessor: "teacher.last",
      align: "left",
      Cell: (props: any) => {
        const { id: teachercode, first, last } = props.row.original.teacher;
        return (
          <p className="text-md truncate font-medium">
            <Link to={`${REACT_APP_ADMIN_LINK}/teacher/${teachercode}`}>
              <DemoMode value={`${first} ${last}`} type="person_full" />
            </Link>
          </p>
        );
      },
    },
    {
      Header: "Last Login",
      align: "center",
      accessor: "last_login",
      sortDescFirst: true,
      Cell: (props: any) => {
        const { last_login } = props.row.original;
        return last_login
          ? new Date(last_login * 1000).toLocaleDateString()
          : "-";
      },
    },
    {
      Header: "Students",
      align: "left",
      accessor: "num_students",
      sortDescFirst: true,
    },
    {
      Header: "Assignments",
      align: "left",
      accessor: "assignments",
      sortDescFirst: true,
    },
    {
      Header: "Problems",
      align: "left",
      accessor: "annual_logs",
      Cell: (props: { row: { original: any } }) =>
        formatNumber(props.row.original.annual_logs),
      Filter: NoFilter,
      sortDescFirst: true,
    },
    {
      Header: "",
      align: "right",
      accessor: "showDeleteButton",
      disableSortBy: true,
      Cell: (props: { row: { original: any } }) => (
        <>
          <span
            onClick={() => {
              setShowDeleteConfirmation(true);
              setUserToDelete(props.row.original.teacher);
            }}
          >
            <TrashIcon className="h-6 cursor-pointer text-right text-gray-600" />
          </span>
        </>
      ),
    },
  ];

  return (
    <>
      {teacherDataisLoading && <div>Loading...</div>}

      {!teacherDataisLoading &&
        teacherDataForTable &&
        teachers &&
        teachers.length > 0 && (
          <>
            <div className="rounded-md border">
              <DeltaMathTable
                columns={teachersTableColumns}
                data={teacherDataForTable}
                options={{
                  initialState: {
                    sortBy: [
                      {
                        id: "teacher.last",
                        desc: false,
                      },
                    ],
                  },
                }}
              />
            </div>
          </>
        )}
      {!teacherDataisLoading && teachers && teachers.length === 0 && (
        <>
          <p className="text-md my-1 mb-6 text-gray-500">No teachers found.</p>

          <p>
            <Link
              className="underline hover:no-underline"
              to={`${REACT_APP_ADMIN_LINK}/schools/${params.school_id}/invite-teachers`}
            >
              Invite a teacher
            </Link>{" "}
            to get started.
          </p>
        </>
      )}

      {showDeleteConfirmation && userToDelete && (
        <DeltaMathConfirmation
          title={
            <>
              Remove Teacher{" "}
              <DemoMode
                value={`${userToDelete.first} ${userToDelete.last}`}
                type="person_full"
              />
            </>
          }
          message={
            <>
              Are you sure you want to remove{" "}
              <DemoMode
                value={`${userToDelete.first} ${userToDelete.last}`}
                type="person_full"
              />{" "}
              from the site license?
            </>
          }
          confirm="Delete"
          confirmAction={() => {
            removeTeacher.mutate({
              teachercode: userToDelete.id,
              schoolId: params.school_id || "",
            });
          }}
          cancel="Cancel"
          cancelAction={() => {
            setShowDeleteConfirmation(false);
            setUserToDelete({
              id: "",
              first: "",
              last: "",
            });
          }}
        />
      )}
    </>
  );
}
