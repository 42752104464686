import { useEffect, useState } from "react";
import {
  PasswordErrorMessages,
  REACT_APP_LEARNER_LINK,
  evaluatePassword,
  useDMQuery,
} from "../../../utils";
import clsx from "clsx";
import PasswordBox from "./PasswordBox";
import DebounceTextField from "./DebounceTextField";
import Button from "../../../student/components/generic/button";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { TextField } from "../Splash/TextField";
import ErrorState from "./Error";
import { useNavigate } from "react-router-dom";
import { ResponsiveImage } from "../../components/ResponsiveImage";
import { getFilePath } from "../../../utils";
import { processPasswordForRequest } from "../../../utils/processPasswordForRequest";

const usernameRegex = /^[a-zA-Z0-9_.+-@]{5,}$/;

type formData = {
  password: string;
  confirmPassword: string;
  studentUsername: string;
  studentPassword: string;
  studentFirstName: string;
  studentLastName: string;
  studentConfirmPassword: string;
};

const BetaSignUp = () => {
  const navigate = useNavigate();
  const toastContext = useDeltaToastContext();
  const containerSize = "w-[1281px] px-4 md:px-6 max-w-full";
  const urlParams = new URLSearchParams(window.location.search);
  const key = urlParams.get("key");
  const [formData, setFormData] = useState<formData>({
    password: "",
    confirmPassword: "",
    studentUsername: "",
    studentFirstName: "",
    studentLastName: "",
    studentPassword: "",
    studentConfirmPassword: "",
  });
  const [formErrors] = useState<{
    [K in keyof formData]?: string;
  }>({});
  const [inviteError, setInviteError] = useState<
    "notfound" | "claimed" | undefined
  >();

  const { data, refetch, isSuccess, isError } = useDMQuery<{
    status: "pending" | "claimed" | "notfound";
    email?: string;
  }>({
    path: `/learner/beta/beta_lookup/${key}`,
    queryOptions: {
      enabled: false,
      refetchOnWindowFocus: false,
    },
  });

  useEffect(() => {
    if (isSuccess) {
      // TODO: if we want different error messages based on claimed/notfound status, we can use the invite error to determine the message to render
      if (data.status === "claimed") setInviteError("claimed");
    }
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError) {
      setInviteError("notfound");
    }
  }, [isError]);

  const {
    refetch: fetchUsernameAvailability,
    isSuccess: usernameAvailabilitySuccess,
    data: usernameAvailability,
  } = useDMQuery<{ available: boolean }>({
    path: `/learner/beta/username_check/${formData.studentUsername}`,
    queryOptions: {
      enabled: false,
      refetchOnWindowFocus: false,
    },
  });

  useEffect(() => {
    if (usernameAvailabilitySuccess) {
      if (!usernameAvailability.available) {
        formErrors["studentUsername"] = "This username is already taken.";
      } else if (
        usernameAvailability.available &&
        formErrors["studentUsername"] === "This username is already taken."
      ) {
        delete formErrors["studentUsername"];
      }
    }
  }, [usernameAvailabilitySuccess, usernameAvailability]);

  const createAccount = useMutation({
    mutationFn: (body: {
      inviteKey: string | null;
      password: string;
      studentUsername: string;
      studentFirst: string;
      studentLast: string;
      studentPassword: string;
    }) => {
      return axios.post<{ status: "success" | "error" }>(
        `${deltamathAPI()}/learner/beta/create_account`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess: (data) => {
      navigate(`${REACT_APP_LEARNER_LINK}/sign-in`);
    },
    onError: () => {
      toastContext.addToast({
        message: "There was an error creating your account.",
        status: "Error",
      });
    },
  });

  const create = () => {
    const body = {
      inviteKey: key,
      password: processPasswordForRequest(formData.password),
      studentUsername: formData.studentUsername,
      studentFirst: formData.studentFirstName,
      studentLast: formData.studentLastName,
      studentPassword: processPasswordForRequest(formData.studentPassword),
    };

    createAccount.mutate(body);
  };

  useEffect(() => {
    if (formData.studentUsername) {
      fetchUsernameAvailability();
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formData.studentUsername]);

  const studentUsernameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!usernameRegex.test(e.target.value)) {
      formErrors["studentUsername"] =
        "Your username must be at least 5 characters long and no special characters.";
    } else {
      delete formErrors["studentUsername"];
    }
    setFormData({
      ...formData,
      studentUsername: e.target.value,
    });
  };

  const parentPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "primary" | "secondary"
  ) => {
    if (type === "primary") {
      if (!evaluatePassword(e.target.value) && e.target.value) {
        formErrors["password"] = "true";
      } else if (formErrors["password"]) {
        delete formErrors["password"];
      }

      setFormData({
        ...formData,
        password: e.target.value,
      });
      if (
        formData.confirmPassword !== "" &&
        formData.confirmPassword !== e.target.value
      ) {
        formErrors["confirmPassword"] = "Passwords do not match.";
      } else if (formData.confirmPassword === e.target.value) {
        delete formErrors["confirmPassword"];
      }
    } else {
      setFormData({
        ...formData,
        confirmPassword: e.target.value,
      });
      if (formData.password !== "" && formData.password !== e.target.value) {
        formErrors["confirmPassword"] = "Passwords do not match.";
      } else if (formData.password === e.target.value) {
        delete formErrors["confirmPassword"];
      }
    }
  };

  const studentPasswordChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    type: "primary" | "secondary"
  ) => {
    if (type === "primary") {
      if (!evaluatePassword(e.target.value) && e.target.value) {
        formErrors["studentPassword"] = "true";
      } else if (formErrors["studentPassword"]) {
        delete formErrors["studentPassword"];
      }

      setFormData({
        ...formData,
        studentPassword: e.target.value,
      });
      if (
        formData.studentConfirmPassword !== "" &&
        formData.studentConfirmPassword !== e.target.value
      ) {
        formErrors["studentConfirmPassword"] = "Passwords do not match.";
      } else if (formData.studentConfirmPassword === e.target.value) {
        delete formErrors["studentConfirmPassword"];
      }
    } else {
      setFormData({
        ...formData,
        studentConfirmPassword: e.target.value,
      });
      if (
        formData.studentPassword !== "" &&
        formData.studentPassword !== e.target.value
      ) {
        formErrors["studentConfirmPassword"] = "Passwords do not match.";
      } else if (formData.studentPassword === e.target.value) {
        delete formErrors["studentConfirmPassword"];
      }
    }
  };

  useEffect(() => {
    if (key) {
      refetch();
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [key]);

  const signupEnabled =
    formData.password &&
    formData.confirmPassword &&
    formData.studentUsername &&
    formData.studentFirstName &&
    formData.studentLastName &&
    formData.studentPassword &&
    formData.studentConfirmPassword &&
    data?.status === "pending" &&
    !Object.keys(formErrors).length;

  return (
    <main className="bg-dm-background-blue flex min-h-dvh w-full flex-col items-center gap-20 md:gap-32">
      <section className="relative flex w-full flex-col items-center bg-[length:200px_200px]">
        <header className="w-full px-4 pb-3 pt-4 md:px-14 md:pb-6 md:pt-6">
          <ResponsiveImage
            srcs={[getFilePath("/images/DeltaMath-Logo_Home.svg")]}
            alt="DeltaMath Home"
          />
        </header>

        <div
          className={clsx(
            "relative flex w-full flex-col items-center justify-center gap-12 pb-20 pt-6 md:pb-24 md:pt-24",
            containerSize
          )}
        >
          {inviteError && (
            <ErrorState
              title="Oops! It looks like this page isn't working because the invite link is invalid or has expired."
              body={"Please check your invite link or request a new one."}
            />
          )}
          {key && !inviteError && (
            <>
              <h3 className="mb-8 text-2xl font-bold text-dm-brand-blue-600">
                Sign Up
              </h3>
              <ResponsiveImage
                className="absolute h-auto w-32 max-sm:right-2 max-sm:top-28 sm:-left-10 sm:bottom-0 md:w-56 lg:w-auto"
                srcs={[
                  getFilePath("/images/learner/signup/deltie-thumbs-up.png"),
                  getFilePath("/images/learner/signup/deltie-thumbs-up@2x.png"),
                ]}
                alt=""
                aria-hidden="true"
              />
              <div className="relative w-full sm:w-96">
                <div className="mb-10 flex flex-col gap-4">
                  <h5 className="text-lg font-bold text-dm-brand-blue-600">
                    Complete Parent Sign Up
                  </h5>
                  <div className="mb-2">
                    <label
                      htmlFor="email"
                      className="text-sm font-bold leading-6"
                    >
                      Email
                    </label>
                    <p id="email" className="leading-7">
                      {data?.email}
                    </p>
                  </div>
                  <PasswordBox
                    label="Create Password"
                    value={formData.password}
                    error={undefined}
                    onChange={(e) => parentPasswordChange(e, "primary")}
                  />
                  {formErrors.password && (
                    <PasswordErrorMessages passwordValue={formData.password} />
                  )}
                  <PasswordBox
                    label="Confirm Password"
                    value={formData.confirmPassword}
                    error={formErrors.confirmPassword}
                    onChange={(e) => parentPasswordChange(e, "secondary")}
                  />
                </div>
                <div className="flex flex-col gap-4">
                  <h5 className="text-lg font-bold text-dm-brand-blue-600">
                    Student Sign Up
                  </h5>
                  <TextField
                    label="Student's First Name"
                    value={formData.studentFirstName}
                    error={formErrors.studentFirstName}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        studentFirstName: e.target.value,
                      })
                    }
                  />
                  <TextField
                    label="Student's Last Name"
                    value={formData.studentLastName}
                    error={formErrors.studentLastName}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        studentLastName: e.target.value,
                      })
                    }
                  />
                  <DebounceTextField
                    label="Student User Name"
                    value={formData.studentUsername}
                    error={formErrors.studentUsername}
                    onChange={studentUsernameChange}
                  />
                  <PasswordBox
                    label="Create Password"
                    value={formData.studentPassword}
                    error={undefined}
                    onChange={(e) => studentPasswordChange(e, "primary")}
                  />
                  {formErrors.studentPassword && (
                    <PasswordErrorMessages
                      passwordValue={formData.studentPassword}
                    />
                  )}
                  <PasswordBox
                    label="Confirm Password"
                    value={formData.studentConfirmPassword}
                    error={formErrors.studentConfirmPassword}
                    onChange={(e) => studentPasswordChange(e, "secondary")}
                  />
                </div>
              </div>
              <Button
                className="relative w-full sm:w-96"
                disabled={!signupEnabled}
                onClick={create}
              >
                Sign Up
              </Button>
            </>
          )}
        </div>
      </section>
    </main>
  );
};

export default BetaSignUp;
