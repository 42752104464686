import { useEffect } from "react";
import { clearExpiredAnswerDataKeys } from "./handleAnswerData";

export const useClearExpiredAnswerData = () => {
  useEffect(() => {
    const intervalId = setInterval(() => {
      clearExpiredAnswerDataKeys();
    }, 10 * 60 * 1000); // 10 minutes

    return () => {
      clearInterval(intervalId);
    };
  }, []);
};
