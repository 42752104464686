import { ChangeEventHandler, FormEvent, useState } from "react";
import Modal from "../../../student/components/generic/Modal";
import { uniqueId } from "lodash";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { deltamathAPI } from "../../utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { LearnerSubscriptionPlan } from "../../../learner/types";

const PricingGroup: React.FC<{ label: string }> = ({ label, children }) => (
  <div className="flex flex-col gap-4 rounded bg-dm-charcoal-100 p-4">
    <h3 className="font-bold">{label}</h3>
    <div className="flex w-full gap-6">{children}</div>
  </div>
);

const StyledInput: React.FC<{
  label: string;
  value: number;
  onChange: ChangeEventHandler<HTMLInputElement>;
}> = ({ label, value, onChange }) => {
  const id = uniqueId("input");
  return (
    <div className="flex grow flex-col gap-1">
      <label htmlFor={id}>{label}</label>
      <input
        className="w-28 rounded px-2 py-1"
        type="number"
        id={id}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export const CreatePlansModal: React.FC<{
  visible: boolean;
  currentMonthlyPlan: LearnerSubscriptionPlan | undefined;
  currentAnnualPlan: LearnerSubscriptionPlan | undefined;
  onClose: () => void;
  onPlansCreated: () => void;
}> = ({
  visible,
  currentMonthlyPlan,
  currentAnnualPlan,
  onClose,
  onPlansCreated,
}) => {
  const toastContext = useDeltaToastContext();
  const [formData, setFormData] = useState<{
    monthlyPrice: number;
    monthlyAdditionalPrice: number;
    annualPrice: number;
    annualAdditionalPrice: number;
    trialLength: number;
  }>({
    monthlyPrice: currentMonthlyPlan?.learnerPrice ?? 0,
    monthlyAdditionalPrice: currentMonthlyPlan?.additionalLearnerPrice ?? 0,
    annualPrice: currentAnnualPlan?.learnerPrice ?? 0,
    annualAdditionalPrice: currentAnnualPlan?.additionalLearnerPrice ?? 0,
    trialLength: currentMonthlyPlan?.trialLength ?? 0,
  });

  const { mutateAsync: createPlans, isPending } = useMutation({
    mutationFn: () => {
      return axios.post(
        `${deltamathAPI()}/payments/subscriptions/plans/create`,
        JSON.stringify(formData),
        withJsonHeader()
      );
    },
  });

  const handleSubmit = async (e?: FormEvent) => {
    if (e) {
      e.preventDefault();
    }
    try {
      await createPlans();
      onPlansCreated();
    } catch (e: any) {
      toastContext.addToast({
        message: e.response.data.message || "Unable to create plans",
        status: "Error",
      });
    }
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      title="Create new draft plans"
      confirmButtonText="Create plans"
      onConfirm={handleSubmit}
      confirmDisabled={isPending}
      closeX
      body={
        <div>
          <form className="flex flex-col gap-6" onSubmit={handleSubmit}>
            <PricingGroup label="Monthly pricing">
              <StyledInput
                label="First learner"
                value={formData.monthlyPrice}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    monthlyPrice: Number(e.target.value),
                  })
                }
              />
              <StyledInput
                label="Additional learners"
                value={formData.monthlyAdditionalPrice}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    monthlyAdditionalPrice: Number(e.target.value),
                  })
                }
              />
            </PricingGroup>
            <PricingGroup label="Annual pricing">
              <StyledInput
                label="First learner"
                value={formData.annualPrice}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    annualPrice: Number(e.target.value),
                  })
                }
              />
              <StyledInput
                label="Additional learners"
                value={formData.annualAdditionalPrice}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    annualAdditionalPrice: Number(e.target.value),
                  })
                }
              />
            </PricingGroup>
            <StyledInput
              label="Trial length in days"
              value={formData.trialLength}
              onChange={(e) =>
                setFormData({
                  ...formData,
                  trialLength: Number(e.target.value),
                })
              }
            />
            <button className="hidden" type="submit">
              Submit
            </button>
          </form>
        </div>
      }
    />
  );
};
