import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { REACT_APP_MANAGER_LINK, useDMQuery } from "../../../utils";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import Button from "../../../student/components/generic/button";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deltamathAPI } from "../../utils";
import axios from "axios";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { CourseType, Unit, UnitVersionHistory } from "../../types";
import DraggableTable from "../../../shared/DraggableTable/DraggableTable";
import Select, { SingleValue } from "react-select";
import EditName from "../GenericCourseBuilder/EditName";
import PublishVersion from "../GenericCourseBuilder/PublishVersion";
import DeleteVersion from "../GenericCourseBuilder/DeleteVersion";
import { Tooltip } from "../../../shared/Tooltip";
import NewVersion from "../GenericCourseBuilder/NewVersion";
import { Switch } from "@headlessui/react";
import clsx from "clsx";

const Course = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const toastContext = useDeltaToastContext();
  const [newUnitName, setNewUnitName] = useState<string>("");
  const params = useParams();
  const courseId = params.courseId ?? "";
  const version = urlParams.get("version");
  const [enabled, setEnabled] = useState(false);

  const {
    data: courseData,
    refetch: courseRefetch,
    status: courseStatus,
  } = useDMQuery<CourseType>({
    path: `/manager_new/parent-portal/course/${courseId}`,
    queryOptions: {
      enabled: false,
    },
  });

  const { data: unitData, refetch: unitRefetch } = useDMQuery<Unit[]>({
    path: `/manager_new/parent-portal/course/${courseId}/units?versionId=${urlParams.get(
      "version"
    )}`,
    queryOptions: {
      enabled: false,
    },
  });

  const uploadIcon = useMutation({
    mutationFn: (body: FormData) => {
      return axios.put(
        `${deltamathAPI()}/manager_new/parent-portal/upload_icon`,
        body,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
    },
    onSuccess() {
      courseRefetch();
      toastContext.addToast({
        status: "Success",
        message: "Icon Uploaded",
      });
    },
    onError() {
      toastContext.addToast({
        status: "Error",
        message: "Problem uploading icon",
      });
    },
  });

  // Upload a new / modified standard to be loaded by the server
  const upload = async (
    event: ChangeEvent<HTMLInputElement>,
    size: "small" | "large" | "grey"
  ) => {
    const file = event.target.files?.[0];
    if (file) {
      const formData = new FormData();
      formData.append("file", file);
      formData.append("courseId", courseId);
      formData.append("size", size);
      uploadIcon.mutate(formData);
    }
  };

  const createUnit = useMutation({
    mutationFn: (body: string) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/parent-portal/unit`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [
          `/manager_new/parent-portal/course/${courseId}/units?versionId=${urlParams.get(
            "version"
          )}`,
        ],
      });
      setNewUnitName("");
      toastContext.addToast({
        status: "Success",
        message: "Unit Created",
      });
      unitRefetch();
    },
    onError() {
      toastContext.addToast({
        status: "Error",
        message: "Problem creating unit",
      });
    },
  });

  useEffect(() => {
    if (courseId !== "") {
      courseRefetch();
      unitRefetch();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [courseId, version]);

  const create = () => {
    const body = {
      name: newUnitName,
      courseId: courseId,
      versionId: urlParams.get("version"),
    };
    createUnit.mutate(JSON.stringify(body));
  };

  const columns = [
    {
      Header: "Unit Name",
      accessor: "name",
      align: "center",
      Cell: (props: any) => {
        return (
          <Button
            href={`${REACT_APP_MANAGER_LINK}/unit/${
              props.row.original._id
            }?version=${
              props.row.original.versionHistory[
                props.row.original.versionHistory.length - 1
              ].versionKey
            }`}
            type="link"
          >
            {props.row.original.name}
          </Button>
        );
      },
    },
    {
      Header: "# of Subunits",
      align: "center",
      Cell: (props: any) => {
        return (
          <>
            {
              props.row.original.versionHistory[
                props.row.original.versionHistory.length - 1
              ].subUnitOrder.length
            }
          </>
        );
      },
    },
    {
      Header: "Version",
      Cell: (props: any) => {
        if (
          props.row.original.versionHistory &&
          props.row.original.versionHistory.length > 0
        ) {
          return (
            <Select
              placeholder="Select Specific Version"
              name="colorstypeOptions"
              options={props.row.original.versionHistory.map(
                (v: UnitVersionHistory) => {
                  return {
                    value: v.versionKey,
                    label: `V${v.versionName}`,
                  };
                }
              )}
              isClearable={true}
              isSearchable={false}
              closeMenuOnSelect={false}
              onChange={(
                newValue: SingleValue<{ label: string; value: string }>
              ) => {
                if (newValue) {
                  navigate(
                    `${REACT_APP_MANAGER_LINK}/unit/${props.row.original._id}?version=${newValue.value}`
                  );
                }
              }}
            />
          );
        }
        return 0;
      },
    },
    {
      Header: "State",
      Cell: (props: any) => {
        if (
          props.row.original.versionHistory &&
          props.row.original.versionHistory.length > 0
        ) {
          const state =
            props.row.original.versionHistory[
              props.row.original.versionHistory.length - 1
            ].state;
          if (state === "draft") {
            return <div className="rounded-full border px-2 py-1">Draft</div>;
          } else if (state === "published") {
            return (
              <div className="rounded-full border bg-dm-success-200 px-2 py-1">
                Published
              </div>
            );
          } else if (state === "deleted") {
            return (
              <div className="rounded-full border bg-dm-error-200 px-2 py-1">
                Deleted
              </div>
            );
          }
          return props.row.original.versionHistory[
            props.row.original.versionHistory.length - 1
          ].state;
        }
      },
    },
  ];

  const unitReorder = useMutation({
    mutationFn: (body: string) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/parent-portal/order_units`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [
          `/manager_new/parent-portal/course/${courseId}/units?versionId=${urlParams.get(
            "version"
          )}`,
        ],
      });
      toastContext.addToast({
        status: "Success",
        message: "Units Reordered",
      });
      unitRefetch();
    },
    onError() {
      toastContext.addToast({
        status: "Error",
        message: "Issue Reordering Units",
      });
    },
  });

  const copyCourse = useMutation({
    mutationFn: (body: any) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/parent-portal/copy_course`,
        JSON.stringify(body),
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess(data) {
      queryClient.invalidateQueries({
        queryKey: ["/manager_new/parent-portal/courses"],
      });
      navigate(
        `${REACT_APP_MANAGER_LINK}/course/${data.data._id}?version=${
          data.data.versionHistory[data.data.versionHistory.length - 1]
            .versionKey
        }`
      );
    },
    onError() {
      toastContext.addToast({
        status: "Error",
        message: " Issue creating copy of course",
      });
    },
  });

  const updateOrder = (data: Unit[]) => {
    const body = {
      courseId: courseId,
      versionId: urlParams.get("version"),
      unitIds: data.map((u) => u._id),
    };
    unitReorder.mutate(JSON.stringify(body));
  };

  const newVersionCallback = (id: string, versionKey: string) => {
    queryClient.invalidateQueries({
      queryKey: ["/manager_new/parent-portal/courses"],
    });
    navigate(`${REACT_APP_MANAGER_LINK}/course/${id}?version=${versionKey}`);
  };

  if (courseStatus !== "success") {
    return <>LOADING...</>;
  }

  const courseVersion = courseData.versionHistory.find(
    (vh) => vh.versionKey === urlParams.get("version")
  );
  const restrictUnitCreation = courseVersion?.state !== "draft";

  const filteredData =
    !enabled && unitData
      ? unitData?.filter(
          (x) =>
            x.versionHistory[x.versionHistory.length - 1].state !== "deleted"
        )
      : unitData;

  return (
    <div className="px-6">
      <div className="flex justify-between py-10">
        <div>
          <Button
            href={`${REACT_APP_MANAGER_LINK}/course-builder`}
            type="link"
            className="flex items-center gap-2"
          >
            <ArrowLeftIcon
              className="h-5 w-5 text-dm-gray-200"
              aria-hidden="true"
            />
            Back to Course List
          </Button>
          <Button
            href={`${REACT_APP_MANAGER_LINK}/course-detail/${courseId}`}
            type="link"
            className="flex items-center gap-2"
          >
            <ArrowLeftIcon
              className="h-5 w-5 text-dm-gray-200"
              aria-hidden="true"
            />
            Detailed Course View
          </Button>
        </div>

        <div className="flex h-min items-center gap-4">
          <Button
            onClick={() => {
              copyCourse.mutate({ courseId });
            }}
          >
            Copy Course
          </Button>
          <NewVersion
            type="course"
            id={courseId}
            versionKey={courseVersion?.versionKey || ""}
            callback={newVersionCallback}
          />
          <PublishVersion
            type="course"
            id={courseId}
            versionHistory={courseVersion}
            callback={() => {
              courseRefetch();
              unitRefetch();
            }}
          />
          <DeleteVersion
            type="course"
            id={courseId}
            versionHistory={
              courseVersion
                ? {
                    ...courseVersion,
                    versionName: String(courseVersion?.versionName || ""),
                  }
                : undefined
            }
            callback={() => {
              courseRefetch();
              unitRefetch();
            }}
          />
        </div>
      </div>
      <div className="mb-8 flex flex-col gap-6">
        <div className="max-w-3xl">
          <EditName
            name={courseData.name}
            friendlyUrl={courseData.friendlyPath ?? ""}
            description={courseData.description}
            featuredCourseOrder={courseData.featuredCourseOrder}
            type="course"
            id={courseId}
            callback={() => courseRefetch()}
            versionState={courseVersion?.state || "draft"}
          />
        </div>
        {courseData.iconUrl && (
          <div className="flex items-center gap-2">
            Large: Icon{" "}
            <img
              src={`${courseData.iconUrl}`}
              alt="Course Icon"
              className="h-20 w-20"
            />
          </div>
        )}
        {courseData.smallIconUrl && (
          <div className="flex items-center gap-2">
            Small Icon:{" "}
            <img
              src={`${courseData.smallIconUrl}`}
              alt="Course Icon"
              className="h-20 w-20"
            />
          </div>
        )}
        {courseData.greySmallIconUrl && (
          <div className="flex items-center gap-2">
            Grey Small Icon:{" "}
            <img
              src={`${courseData.greySmallIconUrl}`}
              alt="Course Icon"
              className="h-20 w-20"
            />
          </div>
        )}
        <div className="flex items-center gap-4">
          <p>Upload Large Course Icon: </p>
          <input
            className="focus:border-primary focus:shadow-primary w-fit cursor-pointer rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-3 text-xs file:cursor-pointer"
            type="file"
            onChange={(e) => upload(e, "large")}
          />
        </div>
        <div className="flex items-center gap-4">
          <p>Upload Small Course Icon: </p>
          <input
            className="focus:border-primary focus:shadow-primary w-fit cursor-pointer rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-3 text-xs file:cursor-pointer"
            type="file"
            onChange={(e) => upload(e, "small")}
          />
        </div>
        <div className="flex items-center gap-4">
          <p>Upload Grey Small Course Icon: </p>
          <input
            className="focus:border-primary focus:shadow-primary w-fit cursor-pointer rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-3 text-xs file:cursor-pointer"
            type="file"
            onChange={(e) => upload(e, "grey")}
          />
        </div>
      </div>
      <p>Unit Name</p>
      <form
        className="mb-8 flex gap-4"
        onSubmit={async (e) => {
          e.preventDefault();
        }}
      >
        <input
          type="text"
          name="unitName"
          id="unitName"
          className="mt-1 block h-12 w-96 rounded-none rounded-l-md border-gray-300 px-2 py-1 text-sm placeholder-gray-300 ring-inset placeholder:text-sm focus:border-dm-brand-blue-500 focus:ring-0 focus:ring-dm-brand-blue-500"
          placeholder="New Unit Name"
          value={newUnitName}
          onChange={(e) => setNewUnitName(e.target.value)}
        />
        <Tooltip
          message={
            restrictUnitCreation
              ? "You cannot create a new unit in a published or previously published course! Please create a new version if you would like to edit"
              : ""
          }
        >
          <Button
            className="flex items-center gap-2"
            onClick={create}
            disabled={restrictUnitCreation}
            submit
          >
            <i className="far fa-plus-circle cursor-pointer text-2xl" />
            Add Unit
          </Button>
        </Tooltip>
      </form>
      <Switch.Group>
        <div className="mb-4 flex items-center gap-x-2">
          <Switch.Label
            passive
            className={clsx(
              "min-w-[30px] text-right text-xs text-dm-charcoal-600",
              !enabled && "font-bold"
            )}
          >
            Hide Deleted
          </Switch.Label>
          <Switch
            checked={enabled}
            onChange={setEnabled}
            className={`${
              enabled ? "bg-dm-brand-blue-800" : "bg-dm-brand-blue-500"
            } relative inline-flex h-6 w-11 shrink-0 items-center rounded-full`}
          >
            <span className="sr-only">daily/weekly</span>
            <span
              aria-hidden="true"
              className={`${
                enabled ? "translate-x-6" : "translate-x-1"
              } inline-block h-4 w-4 transform rounded-full bg-white transition`}
            />
          </Switch>
          <Switch.Label
            passive
            className={clsx(
              "min-w-[44px] text-left text-xs text-dm-charcoal-600",
              enabled && "font-bold"
            )}
          >
            Show Deleted
          </Switch.Label>
        </div>
      </Switch.Group>
      {/* <DeltaMathTable columns={columns} data={[]}></DeltaMathTable> */}
      {!filteredData || filteredData.length === 0 ? (
        <div className="mb-20 w-full bg-white py-20">
          <div className="text-center">
            <h2 className="my-10 text-xl font-bold">
              There are no units in this course yet
            </h2>
          </div>
        </div>
      ) : (
        <div>
          <DraggableTable
            cols={columns}
            data={filteredData}
            updateData={updateOrder}
          />
        </div>
      )}
    </div>
  );
};

export default Course;
