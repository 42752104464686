import { compact } from "lodash";
import Modal from "../../../student/components/generic/Modal";
import { REACT_APP_MANAGER_LINK, useDMQuery } from "../../../utils";
import { Subunit, Unit } from "../../types";
import Select, { SingleValue } from "react-select";
import { useEffect, useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { deltamathAPI } from "../../utils";
import axios from "axios";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useNavigate } from "react-router-dom";
import Button from "../../../student/components/generic/button";

type Props = {
  visible: boolean;
  onClose: () => void;
  subunit: Subunit | undefined;
  courseId: string;
  courseVersion: string;
};

const MoveSubunitModal = (props: Props) => {
  const [unitToMoveTo, setUnitToMoveTo] = useState<{
    unitId: string;
    unitName: string;
    unitVersion: string;
  }>();

  const toastContext = useDeltaToastContext();
  const navigate = useNavigate();

  const { data: unitData, refetch: unitRefetch } = useDMQuery<Unit[]>({
    path: `/manager_new/parent-portal/course/${props.courseId}/units?versionId=${props.courseVersion}`,
    queryOptions: {
      enabled: false,
    },
  });

  const unitsToShow = unitData
    ? compact(
        unitData.map((u) => {
          // Don't include the current uint as a possibility to move to
          if (u._id === props.subunit?.unitId) {
            return;
          }

          // move to reverse order to find the latest draft version
          const draftVersion = u.versionHistory
            .reverse()
            .find((vh) => vh.state === "draft");
          if (!draftVersion) {
            return;
          }

          return {
            unitId: u._id,
            unitName: u.name,
            unitVersion: draftVersion.versionKey,
          };
        })
      )
    : [];

  const moveUnit = useMutation({
    mutationFn: (body: any) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/parent-portal/move_subunit`,
        JSON.stringify(body),
        { headers: { "Content-Type": "application/json" } }
      );
    },
    onSuccess(data: any) {
      props.onClose();
      navigate(
        `${REACT_APP_MANAGER_LINK}/unit/${unitToMoveTo?.unitId}?version=${unitToMoveTo?.unitVersion}`
      );
    },
    onError(err: any) {
      toastContext.addToast({
        status: "Error",
        message:
          err?.response?.data?.message ||
          "There was an error moving this subunit, please contact a dev.",
      });
    },
  });

  useEffect(() => {
    if (props.visible) {
      setUnitToMoveTo(undefined);
      unitRefetch();
    }
  }, [props.visible]);

  const submit = () => {
    if (!props.subunit || !unitToMoveTo) {
      toastContext.addToast({
        status: "Error",
        message: "Something went super wrong.",
      });
      return;
    }
    moveUnit.mutate({
      subunitId: props.subunit._id,
      unitId: unitToMoveTo.unitId,
      unitVersionKey: unitToMoveTo.unitVersion,
    });
  };

  return (
    <Modal
      visible={props.visible}
      onClose={props.onClose}
      body={
        <div className="min-h-96 pb-10">
          <Select
            className="pt-6"
            placeholder="Select Unit"
            name="colorstypeOptions"
            options={unitsToShow.map((u) => {
              return {
                value: u.unitId,
                label: u.unitName,
              };
            })}
            isSearchable={false}
            isMulti={false}
            onChange={(
              newValue: SingleValue<{ label: string; value: string }>
            ) => {
              if (newValue) {
                const unit = unitsToShow.find(
                  (u) => u.unitId === newValue.value
                );
                setUnitToMoveTo(unit);

                console.log("MOVING HERE", unit);
              }
            }}
          />
          <Button disabled={!unitToMoveTo} onClick={submit} className="mt-10">
            Move Unit
          </Button>
        </div>
      }
    />
  );
};

export default MoveSubunitModal;
