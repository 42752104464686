import { useEffect } from "react";
import { coerceLicenseType } from "../../utils/quoteUtils";
import { useActivationContext } from "./LicenseActivationContext";

const DowngradeCheck: React.FC<{
  isTierDowngrade: boolean;
  isDistScopeDowngrade: boolean;
  isDistToSchool: boolean;
  setIsTierDowngrade: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDistScopeDowngrade: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDistToSchool: React.Dispatch<React.SetStateAction<boolean>>;
}> = ({
  isTierDowngrade,
  isDistScopeDowngrade,
  isDistToSchool,
  setIsTierDowngrade,
  setIsDistScopeDowngrade,
  setIsDistToSchool,
}) => {
  const activationContext = useActivationContext();

  useEffect(() => {
    if (activationContext.invoiceDetails && activationContext.licenseData) {
      const siteHasOrHadIntegral =
        (activationContext.licenseData.district &&
          activationContext.licenseData.district.status !== "none" &&
          activationContext.licenseData.district.tier?.toLowerCase() ===
            "integral") ||
        (activationContext.licenseData.school &&
          activationContext.licenseData.school.status !== "none" &&
          activationContext.licenseData.school.tier?.toLowerCase() ===
            "integral");

      if (
        activationContext.invoiceDetails.licenseTier?.toLowerCase() !==
          "integral" &&
        siteHasOrHadIntegral
      ) {
        setIsTierDowngrade(true);
      } else {
        setIsTierDowngrade(false);
      }

      if (
        ["9-12", "6-8", "7-12", "7-8"].includes(
          coerceLicenseType(activationContext.invoiceDetails.type as string)
        ) &&
        activationContext.licenseData.district?.status !== "none" &&
        coerceLicenseType(
          activationContext.licenseData.district?.type || ""
        ) === "6-12"
      ) {
        setIsDistScopeDowngrade(true);
      } else {
        setIsDistScopeDowngrade(false);
      }

      //fairly complicated but trying to capture a narrow case where we are looking at a school quote and the school was on a district license BUT exclude cases where this may be normal for this school (already on both a dist and school license) and where we are already picking up a possible double activation (hence the expiration check to make sure this is meant to be a renewal quote)
      if (
        activationContext.invoiceDetails?.expiration &&
        activationContext.licenseData.district?.status &&
        activationContext.licenseData.district.status !== "none" &&
        Math.floor(
          new Date(
            activationContext.licenseData.district.expiration
          ).getTime() / 1000
        ) < activationContext.invoiceDetails?.expiration &&
        activationContext.invoiceDetails.type === "school" &&
        !(
          activationContext.licenseData.school?.status !== "none" &&
          activationContext.licenseData.school?.isDoubleLicense
        )
      ) {
        setIsDistToSchool(true);
      } else {
        setIsDistToSchool(false);
      }
    } else {
      // If we don't have the invoice details or license data, reset all downgrade states
      setIsTierDowngrade(false);
      setIsDistScopeDowngrade(false);
      setIsDistToSchool(false);
    }
  }, [
    activationContext.invoiceDetails,
    activationContext.licenseData,
    setIsTierDowngrade,
    setIsDistScopeDowngrade,
    setIsDistToSchool,
  ]);

  return isTierDowngrade || isDistScopeDowngrade || isDistToSchool ? (
    <div>
      <ul className="text-md text-dm-earth-yellow-600">
        {isTierDowngrade && (
          <li>
            <strong>Notice</strong> License is currently Integral, but
            downgrading to Plus. Please confirm with customer.
          </li>
        )}
        {isDistScopeDowngrade && (
          <li>
            <strong>Notice</strong> License is currently 6-12, but downgrading
            scope. Please confirm with customer.
          </li>
        )}
        {isDistToSchool && (
          <li>
            <strong>Notice</strong> License is currently district, but
            downgrading to school. Please confirm with customer.
          </li>
        )}
      </ul>
    </div>
  ) : (
    <></>
  );
};

export default DowngradeCheck;
