import { TrashIcon } from "@heroicons/react/outline";
import axios from "axios";
import React, { useEffect } from "react";
import { useState } from "react";
import { useMutation } from "@tanstack/react-query";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import DeltaMathConfirmation from "../../shared/DeltaMathConfirmation";
import { DeltaMathSelect } from "../../shared/DeltaMathSelect";
import DeltaMathTable from "../../shared/DeltaMathTable";
import { NCESFlagEditable } from "../../shared/table/editable";
import { useDMQuery } from "../../utils";
import { deltamathAPI } from "../utils";
import CreateEntityModal from "./CreateEntityModal";
import { useUserContext } from "../../shared/contexts/UserContext";
import { TextFilter } from "../../shared/table/filters";
import { IconButton } from "../../student/components/generic/IconButton";
import { useDocumentTitle } from "usehooks-ts";

interface NewFlagInterface {
  nces_id: string;
  nces_notes: string;
  nces_flag: boolean;
  target: "school" | "district" | "select";
  nces_scopes: {
    district?: boolean;
    school: boolean;
    individual: boolean;
  };
}
interface NcesFlagTableData {
  name: string;
  nces_id: string;
  nces_notes: string;
  showDeleteButton: boolean;
  state?: string;
  parent_nces_notes?: string;
  target: "school" | "district";
  nces_scopes: {
    district?: boolean;
    school: boolean;
    individual: boolean;
  };
}

const NcesFlags = () => {
  useDocumentTitle("NCES Flags");
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const toastContext = useDeltaToastContext();

  const [tableData, setTableData] = useState<any[]>([]);
  const [newPayload, setNewPayload] = useState<NewFlagInterface>({
    nces_id: "",
    nces_notes: "",
    nces_flag: true,
    target: "select",
    nces_scopes: {
      district: true,
      school: true,
      individual: true,
    },
  });
  const [deletePayload, setDeletePayload] = useState({
    target: "",
    nces_id: "",
  });
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [showAddModal, setShowAddModal] = useState(false);

  /**
   * make API call to get districts and schools with a flag set to true
   */
  const { data: schoolData, refetch: refetchSchools } = useDMQuery({
    cacheKey: "schools-nces-flag",
    path: "/manager_new/license/query-schools",
    requestBody: {
      filter: { nces_flag: true },
      projection: {
        schoolName: 1,
        schoolid: 1,
        nces_notes: 1,
        nces_id: 1,
        "district.districtID": 1,
        "address.state": 1,
        nces_scopes: 1,
      },
    },
    method: "post",
    queryOptions: {
      enabled: true,
    },
  });

  const { data: districtData, refetch: refetchDistricts } = useDMQuery({
    cacheKey: ["districts-nces-flag"], //add something (Date?) to make individual queries unique
    method: "post",
    path: "/manager_new/license/query-districts",
    requestBody: {
      filter: { nces_flag: true },
      projection: {
        districtName: 1,
        districtID: 1,
        nces_notes: 1,
        nces_id: 1,
        "address.state": 1,
        nces_scopes: 1,
      },
    },
    queryOptions: {
      enabled: true,
    },
  });

  useEffect(() => {
    const parsedData: NcesFlagTableData[] = [];
    if (districtData && districtData.length) {
      districtData.forEach((entry: any) => {
        parsedData.push({
          name: entry.districtName,
          nces_id: entry.districtID,
          nces_notes: entry.nces_notes,
          showDeleteButton: true,
          state: entry.address.state,
          target: "district",
          nces_scopes: entry.nces_scopes,
        });
      });
      if (schoolData && schoolData.length) {
        schoolData.forEach((entry: any) => {
          // Find the parent district note
          const parentDistrict = districtData.find(
            (district: any) =>
              district.districtID === entry?.district?.districtID
          );
          parsedData.push({
            name: entry.schoolName,
            nces_id: entry.schoolid,
            nces_notes: entry.nces_notes,
            parent_nces_notes: parentDistrict ? parentDistrict.nces_notes : "",
            showDeleteButton: true,
            state: entry.address.state,
            target: "school",
            nces_scopes: entry.nces_scopes,
          });
        });
      }
    }
    setTableData(parsedData);
  }, [schoolData, districtData]);

  /**
   * mutation to update single item by nces id
   */
  const createNcesFlag = useMutation({
    mutationFn: () => {
      if (newPayload.target === "school") {
        delete newPayload.nces_scopes.district;
      }
      return axios.post(
        deltamathAPI() + "/manager_new/license/nces-flag",
        newPayload,
        {
          params: { target: newPayload.target },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    onError: () => {
      toastContext.addToast({
        message: "There was an issue adding this flag",
        status: "Error",
      });
      // setShowAddModal(true);
    },
    onSuccess: (res) => {
      if (
        typeof res.data[newPayload.target] !== "undefined" &&
        res.data[newPayload.target] === null
      ) {
        setShowAddModal(true);
      } else if (
        typeof res.data[newPayload.target] !== "undefined" &&
        res.data[newPayload.target] !== null
      ) {
        refetchDistricts();
        refetchSchools();
        toastContext.addToast({
          message: "NCES Flag successfully added.",
          status: "Success",
        });
        setNewPayload({
          nces_id: "",
          nces_notes: "",
          nces_flag: true,
          target: "select",
          nces_scopes: {
            district: true,
            school: true,
            individual: true,
          },
        });
      } else {
        throw new Error("There was an issue adding this flag");
      }
    },
  });

  const updateNcesFlag = useMutation({
    mutationFn: (body: {
      nces_id: string;
      nces_flag: boolean;
      nces_notes: string;
      target: string;
      nces_scopes: {
        district?: boolean;
        school: boolean;
        individual: boolean;
      };
    }) => {
      if (body.target === "school") {
        delete body.nces_scopes.district;
      }
      return axios.put(
        deltamathAPI() + `/manager_new/license/nces-flag/${body.nces_id}`,
        body,
        {
          // params: {target: newPayload.target},
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    onSuccess: () => {
      refetchDistricts();
      refetchSchools();
      toastContext.addToast({
        message: "NCES Flag successfully updated.",
        status: "Success",
      });
      setNewPayload({
        nces_id: "",
        nces_notes: "",
        nces_flag: true,
        target: "select",
        nces_scopes: {
          district: true,
          school: true,
          individual: true,
        },
      });
    },
  });

  // Delete mutation
  const deleteNcesFlag = useMutation({
    mutationFn: ({ target, nces_id }: { target: string; nces_id: string }) => {
      return axios.delete(
        deltamathAPI() + `/manager_new/license/nces-flag/${nces_id}`,
        {
          data: {
            target: target,
            nces_id: nces_id,
          },
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    onSuccess: () => {
      refetchDistricts();
      refetchSchools();
      toastContext.addToast({
        message: "NCES Flag successfully removed.",
        status: "Success",
      });
      setNewPayload({
        nces_id: "",
        nces_notes: "",
        nces_flag: true,
        target: "select",
        nces_scopes: {
          district: true,
          school: true,
          individual: true,
        },
      });
    },
  });

  const handleCheckboxChange = (
    nces_id: string,
    scope: "district" | "school" | "individual"
  ) => {
    setTableData((prevTableData) =>
      prevTableData.map((item) => {
        if (item.nces_id === nces_id) {
          const updatedScopes = {
            ...(item.nces_scopes || {
              district: true,
              school: true,
              individual: true,
            }),
            [scope]: !item.nces_scopes?.[scope],
          };

          // Call the update mutation
          updateNcesFlag.mutate({
            nces_id: item.nces_id,
            nces_flag: true,
            nces_notes: item.nces_notes,
            target: item.target,
            nces_scopes: updatedScopes,
          });

          return {
            ...item,
            nces_scopes: updatedScopes,
          };
        }
        return item;
      })
    );
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Name",
        accessor: "name",
        align: "left",
        Filter: TextFilter,
      },
      {
        Header: "State",
        align: "left",
        accessor: "state",
      },
      {
        Header: "NCES ID",
        accessor: "nces_id",
        align: "left",
        Filter: TextFilter,
      },
      {
        Header: "Note Type",
        accessor: "target",
        align: "left",
        Cell: (props: { row: { original: any } }) => (
          <span className="capitalize">{props.row.original.target}</span>
        ),
      },
      {
        Header: "District Notes",
        accessor: "parent_nces_notes",
        align: "left",
        Cell: (props: { row: { original: any } }) => (
          <div
            style={{
              maxWidth: "200px",
              whiteSpace: "normal",
              wordWrap: "break-word",
            }}
          >
            {props.row.original.parent_nces_notes}
          </div>
        ),
      },
      {
        Header: "Notes",
        accessor: "nces_notes",
        align: "left",
        Cell: NCESFlagEditable,
      },
      {
        Header: "Delete",
        accessor: "showDeleteButton",
        disableSortBy: true,
        Cell: (props: { row: { original: any } }) => (
          <>
            {props.row.original.showDeleteButton && (
              <IconButton
                onClick={() => {
                  setDeletePayload({
                    nces_id: props.row.original.nces_id,
                    target: props.row.original.target,
                  });
                  setShowDeleteConfirmation(true);
                }}
                icon={
                  <TrashIcon className="h-6 w-full cursor-pointer text-gray-600" />
                }
                aria-label={"Delete"}
              />
            )}
          </>
        ),
      },
      {
        Header: "District",
        accessor: "nces_scopes.district",
        align: "left",
        Cell: ({ row }: { row: { original: any } }) =>
          row.original.target === "school" ? (
            "--"
          ) : (
            <input
              type="checkbox"
              checked={row.original.nces_scopes?.district ?? true}
              onChange={() =>
                handleCheckboxChange(row.original.nces_id, "district")
              }
            />
          ),
      },
      {
        Header: "School",
        accessor: "nces_scopes.school",
        align: "left",
        Cell: ({ row }: { row: { original: any } }) => (
          <input
            type="checkbox"
            checked={row.original.nces_scopes?.school ?? true}
            onChange={() =>
              handleCheckboxChange(row.original.nces_id, "school")
            }
          />
        ),
      },
      {
        Header: "Individual",
        accessor: "nces_scopes.individual",
        align: "left",
        Cell: ({ row }: { row: { original: any } }) => (
          <input
            type="checkbox"
            checked={row.original.nces_scopes?.individual ?? true}
            onChange={() =>
              handleCheckboxChange(row.original.nces_id, "individual")
            }
          />
        ),
      },
    ],
    []
  );

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (newPayload.target === "select") {
      toastContext.addToast({
        status: "Error",
        message: "Please select an entity type.",
      });
      return;
    } else if (newPayload.nces_id.length < 7) {
      toastContext.addToast({
        status: "Error",
        message: "Please specify a valid NCES ID.",
      });
      return;
    } else if (newPayload.nces_notes.length < 1) {
      toastContext.addToast({
        status: "Error",
        message: "Please add a note for this entry.",
      });
      return;
    }
    createNcesFlag.mutate();
  };

  const handleSelectTarget = (value: any) => {
    setNewPayload({
      ...newPayload,
      target: value,
    });
  };

  return (
    <div className="px-4">
      <h1 className="py-4 text-2xl font-semibold text-gray-900">
        NCES ID Flagger
      </h1>
      <form onSubmit={handleSubmit} className="grid space-x-2 sm:grid-cols-6">
        <div className="col-span-1 mt-1">
          <label
            htmlFor="nces-id"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Type
          </label>
          <DeltaMathSelect
            options={[
              { key: "school", val: "School" },
              { key: "district", val: "District" },
            ]}
            placeholder="Select..."
            onChangeFn={handleSelectTarget}
            filterable={true}
          />
        </div>
        <div className="col-span-1 mt-1 sm:mt-0">
          <label
            htmlFor="nces-id"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            NCES ID
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="ncesId"
              id="nces-id"
              value={newPayload.nces_id}
              className="rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="-------"
              onChange={(e) =>
                setNewPayload({ ...newPayload, nces_id: e.target.value.trim() })
              }
            />
          </div>
        </div>
        <div className="w-full pr-2 sm:col-span-3">
          <label
            htmlFor="note"
            className="block text-sm font-medium leading-6 text-gray-900"
          >
            Note
          </label>
          <div className="mt-2">
            <input
              type="text"
              name="note"
              id="note"
              value={newPayload.nces_notes}
              className="w-full rounded-md border-0 py-2 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              placeholder="-------"
              onChange={(e) =>
                setNewPayload({ ...newPayload, nces_notes: e.target.value })
              }
            />
          </div>
        </div>
        <div className="col-span-1">
          <button
            type="submit"
            disabled={
              newPayload.nces_id.length > 1 &&
              newPayload.nces_flag &&
              newPayload.nces_notes.length > 1
                ? false
                : true
            }
            className="ml-2 mt-8 inline-flex items-center rounded-md bg-dm-darkest-blue px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-dm-darkest-blue focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-dm-darkest-blue disabled:cursor-not-allowed disabled:bg-gray-500"
          >
            Submit
          </button>
        </div>
      </form>
      <div>
        {tableData && tableData.length && (
          <DeltaMathTable
            columns={columns}
            data={tableData}
            updateMutation={updateNcesFlag}
          />
        )}
      </div>
      <CreateEntityModal
        open={showAddModal}
        setOpen={setShowAddModal}
        entityType={newPayload.target}
        ncesId={newPayload.nces_id}
      />
      {showDeleteConfirmation && (
        <DeltaMathConfirmation
          title={"Confirm Deletion"}
          message={`Are you sure you want to remove the flag on NCES id: ${deletePayload.nces_id}?`}
          confirm={"Delete"}
          confirmAction={() => {
            deleteNcesFlag.mutate({
              target: deletePayload.target,
              nces_id: deletePayload.nces_id,
            });
            setShowDeleteConfirmation(false);
          }}
          cancel={"Cancel"}
          cancelAction={() => setShowDeleteConfirmation(false)}
        />
      )}
    </div>
  );
};

export default NcesFlags;
