import { Fragment } from "react";
import { SupportActionOutput } from "./types";
import { useParams } from "react-router-dom";
import Button from "../../../student/components/generic/button";

const ResultsContainer: React.FC = ({ children }) => (
  <div className="rounded-xl border border-dm-charcoal-200 bg-white">
    {children}
  </div>
);

const ResultsItem: React.FC = ({ children }) => (
  <div className="border-b border-b-dm-charcoal-100 px-6 py-4 last:border-b-0">
    {children}
  </div>
);

export const SupportActionResults: React.FC<{
  formData: Record<string, unknown>;
  outputError?: string;
  outputResults?: SupportActionOutput[];
}> = ({ formData, outputError, outputResults }) => {
  const { actionKey } = useParams<{ actionKey: string }>();

  const downloadToCsv = (output: SupportActionOutput) => {
    if (output.type !== "table") {
      throw new Error(`Unable to convert output type "${output.type}" to CSV`);
    }
    const csvRows = [output.headers, ...output.rows]
      .map((row) =>
        row
          .map((cell) => (typeof cell === "string" ? `"${cell}"` : cell))
          .join(",")
      )
      .join("\n");
    const csvContent = `data:text/csv;charset=utf-8,${encodeURIComponent(
      csvRows
    )}`;

    const formattedInputs = Object.entries(formData)
      .map(([k, v]) => `${k}:${v}`)
      .join("-");
    const filename = [
      "table",
      actionKey,
      formattedInputs,
      new Date().toISOString().split(".")[0],
    ].join("--");

    const encodedUri = csvContent;
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${filename}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  if (outputError) {
    return (
      <ResultsContainer>
        <p className="py-20 text-center text-dm-error-500">
          <strong>Error:</strong> {outputError}
        </p>
      </ResultsContainer>
    );
  }

  if (outputResults) {
    return (
      <ResultsContainer>
        {outputResults.map((output, i) => {
          switch (output.type) {
            case "message":
              return <ResultsItem key={i}>{output.message}</ResultsItem>;
            case "table":
              return (
                <Fragment key={i}>
                  {output.rows.length > 0 && (
                    <ResultsItem>
                      <Button
                        className="!py-0"
                        type="link"
                        onClick={() => downloadToCsv(output)}
                      >
                        Download CSV
                      </Button>
                    </ResultsItem>
                  )}
                  <ResultsItem>
                    <table className="w-full">
                      <thead>
                        <tr>
                          {output.headers.map((header) => (
                            <th key={header} className="text-left">
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody>
                        {output.rows.length > 0 ? (
                          output.rows.map((row, j) => (
                            <tr key={j}>
                              {row.map((cell, k) => (
                                <td key={k} className="pr-2 align-top">
                                  {cell}
                                </td>
                              ))}
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td
                              colSpan={output.headers.length}
                              className="pt-2 italic text-dm-charcoal-500"
                            >
                              No results
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </ResultsItem>
                </Fragment>
              );
            default:
              return (
                <ResultsItem key={i}>
                  Unknown output type &quo;
                  {(output as SupportActionOutput).type}&quo;
                </ResultsItem>
              );
          }
        })}
      </ResultsContainer>
    );
  }

  return null;
};
