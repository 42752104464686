import { useEffect, useRef, useState } from "react";
import ReactPlayer from "react-player";
import FocusTrap from "focus-trap-react";
import clsx from "clsx";
import { XIcon } from "@heroicons/react/outline";
import PictureInPictureIcon from "../../../student/components/icons/PictureInPictureIcon";
import { compact, sum } from "lodash";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useBeforeUnload } from "react-router-dom";
import { useDMQuery } from "../../../utils";
import { LearnerAssignmentTypes, VideoProgressResponse } from "../../types";
import { useLearnerContext } from "../../contexts/LearnerContext";
import { addPointsToLearner } from "../../utils/addPointsToLearner";
import { IconButton } from "../../../student/components/generic/IconButton";
import Select from "react-select";
import { useLearnerAnalytics } from "../../analytics/useLearnerAnalytics";
import { watchVideoEvent } from "../../analytics/events";

type Props = {
  visible: boolean;
  onClose: () => void;
  skillCode: string;
  courseId?: string;
  assignmentId?: string;
  assignmentType?: LearnerAssignmentTypes;
  public?: boolean;
};

type TimeInterval = {
  startTime: number;
  endTime: number;
};
const BLUE = "#dbeafe";
const GREEN = "#117035";

function calculatePercentWatched(
  videoProgress: TimeInterval[],
  duration: number
): number {
  const watchedDuration = sum(
    videoProgress.map((p) => p.endTime - p.startTime)
  );
  return parseFloat((watchedDuration / duration).toFixed(2));
}

const VideoModal = (props: Props) => {
  const toastContext = useDeltaToastContext();
  const learnerContext = useLearnerContext();
  const videoRef = useRef<ReactPlayer>(null);
  const [pipOpen, setPipOpen] = useState<boolean>(false);
  const root = `https://videos.deltamath.com`;
  const videoUrl = `${root}${props.public ? "/public" : ""}/${
    props.skillCode
  }/Default/HLS/${props.skillCode}.m3u8`;
  const subtitleUrl = `${root}/captions/${props.skillCode}.mp4.vtt`;

  const [videoProgress, setVideoProgress] = useState<TimeInterval[]>([]);
  const [currentProgress, setCurrentProgress] = useState<number>(0);
  const initialProgress = useRef<number | undefined>(undefined);
  const [ariaValueText, setAriaValueText] = useState<string>("0:00");
  const [isWindowClosing, setIsWindowClosing] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean | undefined>(undefined);
  const [startedPlayingSecond, setStartedPlayingSecond] = useState<number>(0);
  const [lastUpdated, setLastUpdated] = useState<number>(0);
  const [seekedTo, setSeekedTo] = useState<number | undefined>(undefined);
  const [seeking, setSeeking] = useState<boolean>(false);
  const [volume, setVolume] = useState<number>(1);
  const [mute, setMute] = useState<boolean>(false);
  const [playbackRate, setPlaybackRate] = useState<number>(1);
  const [isReadyToPlay, setIsReadyToPlay] = useState<boolean>(false);
  const isTouchDevice: boolean = (window as any).is_touch_device();

  const { track, getAssignmentData } = useLearnerAnalytics();

  useEffect(() => {
    const handlePipClose = () => setPipOpen(false);
    const handlePipOpen = () => setPipOpen(true);
    const videoElement = document.querySelector("video");
    if (props.visible && videoElement && isReadyToPlay) {
      videoElement.addEventListener("enterpictureinpicture", handlePipOpen);
      videoElement.addEventListener("leavepictureinpicture", handlePipClose);
    }
    return () => {
      if (videoElement) {
        videoElement.removeEventListener(
          "enterpictureinpicture",
          handlePipOpen
        );
        videoElement.removeEventListener(
          "leavepictureinpicture",
          handlePipClose
        );
      }
    };
  }, [props.visible, isReadyToPlay]);

  const {
    refetch: vdRefetch,
    isSuccess: vdSuccess,
    data: vdData,
  } = useDMQuery<{
    progress: number;
    tracker: { [key: number]: boolean };
  }>({
    path: `/learner/data/videoData/${props.courseId}/${props.skillCode}`,
    additionalHeaders: {
      "Cache-Control": "no-cache",
      Pragma: "no-cache",
      Expires: "0",
    },
    queryOptions: {
      staleTime: 1000,
      refetchOnWindowFocus: false,
      enabled: false,
    },
  });

  useEffect(() => {
    if (vdSuccess) {
      if (vdData.tracker) {
        const keys = Object.keys(vdData.tracker);
        const vp: TimeInterval[] = [];

        for (const key of keys) {
          if (vdData.tracker[Number(key)]) {
            const startTime = Number(key);
            const endTime = Number(key) + 5;

            vp.push({ startTime, endTime });
          }
        }
        const merged = mergeOverlappingIntervals(vp);
        setVideoProgress(compact(merged));
      }
    }
  }, [vdSuccess, vdData]);

  useEffect(() => {
    if (props.courseId && props.skillCode && props.visible) {
      vdRefetch();
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.courseId, props.skillCode, props.visible]);

  const updateVideoProgress = useMutation({
    mutationFn: (body: string) => {
      return axios.post(
        `${deltamathAPI()}/learner/assignment/updateVideoProgress/${
          props.skillCode
        }`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess(response: { data: VideoProgressResponse }) {
      const updatedLearner = addPointsToLearner(
        learnerContext.state.learner,
        response.data.pointsEarned
      );
      if (updatedLearner) {
        learnerContext.updateLearner(updatedLearner);
      }
    },
    onError(e) {
      toastContext.addToast({
        status: "Error",
        message: "Issue tracking video progress",
      });
    },
  });

  const [mouseInHouse, setMouseInHouse] = useState(true);
  const [hoverInterval, setHoverInterval] = useState(true);

  const handleMouseInHouse = () => {
    setMouseInHouse(true);

    if (hoverInterval) {
      setHoverInterval(false);
      setTimeout(() => {
        /** this causes a brief thrash when the interval runs out.  Is there a better way of handing this? */
        setMouseInHouse(false);
        setHoverInterval(true);
        /** does not quite align with native timeout because this gets reset onMouseMove */
      }, 3500);
    }
  };

  const duration = videoRef.current?.getDuration() || 0;

  const sections = Math.ceil(duration / 5);

  const colorSpan =
    videoProgress.length > 0
      ? `linear-gradient(90deg, ${compact(
          videoProgress
            .sort((a, b) => (a.startTime > b.startTime ? 1 : -1))
            .map((k, i) => {
              // if we start at 0 paint green at 0%, if we don't the sections can get a little bit weird
              if (i === 0 && k.startTime === 0) {
                return `${GREEN} 0%, ${GREEN} ${
                  (k.endTime / duration) * 100
                }%, ${BLUE} ${(k.endTime / duration) * 100}%`;
              }

              return `${BLUE} ${(k.startTime / duration) * 100}%, ${GREEN} ${
                (k.startTime / duration) * 100
              }%, ${GREEN} ${(k.endTime / duration) * 100}%, ${BLUE} ${
                (k.endTime / duration) * 100
              }%`;
            })
        ).join(", ")}, ${BLUE} 100%)`
      : `linear-gradient(90deg, blue 100%)`;

  useEffect(() => {
    if (initialProgress.current === undefined && duration) {
      initialProgress.current = calculatePercentWatched(
        videoProgress,
        duration
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [duration]);

  const resetStateAndClose = () => {
    const watchPercentStart = initialProgress.current || 0;
    const watchPercentEnd = calculatePercentWatched(videoProgress, duration);
    const secondsWatched = Math.round(
      (watchPercentEnd - watchPercentStart) * duration
    );
    if (props.assignmentType !== undefined) {
      track(
        watchVideoEvent({
          ...getAssignmentData(props.assignmentType),
          watchPercentStart,
          watchPercentEnd,
          secondsWatched,
          skillCode: props.skillCode,
        })
      );
    }

    initialProgress.current = undefined;
    setCurrentProgress(0);
    setAriaValueText("0:00");
    setStartedPlayingSecond(0);
    setLastUpdated(0);
    setSeekedTo(undefined);
    setSeeking(false);
    setIsPlaying(undefined);
    setIsReadyToPlay(false);
    props.onClose();
  };

  const mutate = (closeModal?: boolean) => {
    if (videoProgress.length !== 0 && props.skillCode && props.assignmentId) {
      const tracker: { [key: number]: boolean } = { 0: false };
      Array.from({ length: sections }).forEach((_, i) => {
        const startTime = i * 5;
        const endTime = (i + 1) * 5 >= duration ? duration : (i + 1) * 5;

        const vp = videoProgress.find(
          (x) => x.startTime <= startTime && x.endTime >= endTime
        );

        tracker[i * 5] = !!vp;
      });

      const body = {
        courseId: props.courseId,
        assignmentId: props.assignmentId,
        tracker: tracker,
      };

      updateVideoProgress.mutate(JSON.stringify(body));
    }
    if (closeModal) {
      resetStateAndClose();
    }
  };

  useEffect(() => {
    if (isPlaying === false) {
      mutate();
    }
    // This seems to work as-is
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, isWindowClosing]);

  // If the window is closing or refreshing prompt user if the video is currently playing then pause video
  const windowClosing = (event: BeforeUnloadEvent) => {
    setIsWindowClosing(true);
    if (!isPlaying) {
      return;
    }
    // setting is playing to false will pause the video and run the useEffect above :)
    setIsPlaying(false);
    event.preventDefault();

    // mutate();
    event.returnValue = "onbeforeunload";
    setIsWindowClosing(false);
    return "onbeforeunload";
  };

  useBeforeUnload(windowClosing);

  // this will make it so 2 intervals will not overlap at all
  // ie { startTime:2, endTime: 5} and {startTime: 3, endTime: 7} will become {startTime: 2, endTime: 7}
  const mergeOverlappingIntervals = (
    intervals: TimeInterval[]
  ): TimeInterval[] => {
    // Sort intervals based on their start times
    intervals.sort((a, b) => a.startTime - b.startTime);

    const mergedIntervals: TimeInterval[] = [];

    let currentInterval = intervals[0];

    for (let i = 1; i < intervals.length; i++) {
      const nextInterval = intervals[i];

      // Check if the next interval overlaps with the current one
      if (nextInterval.startTime <= currentInterval.endTime) {
        // Merge the intervals
        currentInterval.endTime = Math.max(
          currentInterval.endTime,
          nextInterval.endTime
        );
      } else {
        // No overlap, push the current interval to the result and update currentInterval
        mergedIntervals.push(currentInterval);
        currentInterval = nextInterval;
      }
    }

    // Push the last interval
    mergedIntervals.push(currentInterval);

    return mergedIntervals;
  };

  const updateInterval = (playedSeconds: number) => {
    if (!isPlaying || seeking) {
      return;
    }

    // if the last updated time is more than 2 seconds off from the current time, we're probably seeking
    if (Math.abs(lastUpdated - playedSeconds) > 2 && seekedTo === undefined) {
      return;
    }

    const start = seekedTo !== undefined ? seekedTo : startedPlayingSecond;

    if (seekedTo !== undefined) {
      setSeekedTo(undefined);
    }

    setLastUpdated(playedSeconds);

    // if the start time in the middle of an interval the existed or we started playing before the start of the interval
    const newVideoProgress = videoProgress.find(
      (x) =>
        (x.startTime <= start && x.endTime >= start) ||
        (x.startTime >= start && x.endTime <= playedSeconds)
    ) || {
      startTime: start,
      endTime: playedSeconds,
    };

    // early return if we're not adding more time
    if (playedSeconds < newVideoProgress.endTime) {
      return;
    }

    newVideoProgress.endTime = playedSeconds;
    // Since on progress isn't necessarily called at the end of the video,
    // we allow the user to get full credit within the last second of the video
    if (playedSeconds >= duration - 1) {
      newVideoProgress.endTime = duration;
    }

    const vp = mergeOverlappingIntervals([
      ...videoProgress.filter(
        (vp) => vp.startTime !== newVideoProgress.startTime
      ),
      newVideoProgress,
    ]);

    setVideoProgress(vp);
  };

  const readableSeconds = (seconds: number) => {
    const min = Math.floor(seconds / 60);
    const sec = Math.floor(seconds % 60);
    return `${min}:${sec < 10 ? `0${sec}` : sec}`;
  };

  const seek = (progress: number) => {
    setSeekedTo(progress * duration);
    setStartedPlayingSecond(progress * duration);
  };

  const muted = volume === 0 || mute;
  const containerRef = useRef<HTMLDivElement | null>(null);

  return (
    <>
      {props.visible && (
        <FocusTrap paused={pipOpen}>
          <div
            id="video-modal-container"
            ref={containerRef}
            className={`${
              pipOpen ? "hidden" : "block"
            } fixed inset-0 z-50 overflow-y-auto`}
            onMouseMove={() => handleMouseInHouse()}
            onMouseLeave={() => setMouseInHouse(false)}
          >
            {!pipOpen && (
              <div className={"fixed inset-0 block bg-black bg-opacity-60"} />
            )}
            <div className="flex h-screen max-h-screen flex-col justify-center overflow-hidden p-4 sm:p-8">
              <div className="mx-auto flex w-full max-w-5xl transform flex-col overflow-hidden rounded-2xl bg-white text-left align-middle shadow-xl transition-all">
                <div
                  className={clsx(
                    mouseInHouse || !isPlaying
                      ? "block bg-gradient-to-b from-black"
                      : "hidden",
                    "absolute z-50 h-1/4 w-full from-black transition delay-300 ease-in-out sm:h-1/6"
                  )}
                >
                  <button
                    type="button"
                    className="absolute left-2 top-2 rounded-md text-white focus:outline-dm-brand-blue-500 focus:ring-offset-2 sm:left-6 sm:top-6"
                    onClick={() => {
                      mutate(true);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                  {ReactPlayer.canEnablePIP(videoUrl) && !isTouchDevice && (
                    <button
                      type="button"
                      className="absolute right-2 top-2 rounded-md focus:outline-dm-brand-blue-500 focus:ring-offset-2 sm:right-6 sm:top-6"
                      onClick={() => {
                        setPipOpen(true);
                      }}
                    >
                      <span className="sr-only">Picture in picture</span>
                      <PictureInPictureIcon classes="h-8 w-8" />
                    </button>
                  )}
                </div>
                <div
                  id="video-modal"
                  className="relative grow overflow-y-auto bg-white"
                  aria-live="polite"
                >
                  <div className="w-full rounded-lg bg-white shadow-md">
                    <ReactPlayer
                      playing={isPlaying}
                      ref={videoRef}
                      url={videoUrl}
                      controls={false}
                      style={{
                        margin: "0 auto auto",
                        maxWidth: "100%",
                      }}
                      onProgress={(state) => {
                        setCurrentProgress(state.played);

                        if (isPlaying) {
                          updateInterval(state.playedSeconds);
                        }
                      }}
                      onEnded={() => setIsPlaying(false)}
                      onReady={() => setIsReadyToPlay(true)}
                      height="100%"
                      width="100%"
                      onDisablePIP={() => setPipOpen(false)}
                      progressInterval={500}
                      pip={pipOpen}
                      muted={mute}
                      // stopOnUnmount={false}
                      volume={mute ? 0 : volume}
                      playbackRate={playbackRate}
                      config={{
                        file: {
                          hlsOptions: {
                            forceHLS: true,
                            maxMaxBufferLength: 30,
                          },
                          attributes: { crossOrigin: "anonymous" },
                          tracks: [
                            {
                              label: "English",
                              kind: "captions",
                              src: subtitleUrl,
                              srcLang: "en",
                              default: false,
                            },
                          ],
                        },
                      }}
                    />
                  </div>
                  <div className="m-4 flex items-center gap-2">
                    <IconButton
                      onClick={() => setIsPlaying(!isPlaying)}
                      icon={
                        isPlaying ? (
                          <i className="far fa-pause text-xl" />
                        ) : (
                          <i className="far fa-play-circle text-xl" />
                        )
                      }
                      aria-label={isPlaying ? "pause" : "play"}
                    />
                    <input
                      type="range"
                      value={currentProgress}
                      min={0}
                      max={0.9999999999}
                      step="any"
                      style={{
                        background: colorSpan,
                        WebkitAppearance: "none",
                      }}
                      className="h-3 w-full"
                      onFocus={() =>
                        setAriaValueText(
                          readableSeconds(currentProgress * duration)
                        )
                      }
                      onPointerDown={() => {
                        setSeeking(true);
                        if (videoRef?.current && seekedTo !== undefined) {
                          videoRef.current.seekTo(seekedTo);
                        }
                      }}
                      onPointerUp={() => setSeeking(false)}
                      onChange={(e) => {
                        const frac = Number(e.target.value);
                        if (videoRef?.current) {
                          videoRef.current.seekTo(frac);
                        }
                        seek(frac);
                        setCurrentProgress(frac);
                        setAriaValueText(readableSeconds(frac * duration));
                      }}
                      aria-label="Seek"
                      aria-valuetext={ariaValueText}
                    />
                    <p aria-live="off">
                      {readableSeconds(lastUpdated)}/{readableSeconds(duration)}
                    </p>
                    <p>|</p>
                    <div className="flex items-center gap-2">
                      <IconButton
                        onClick={() => {
                          if (muted) {
                            if (volume === 0) {
                              setVolume(0.5);
                            }
                            setMute(false);
                          } else {
                            setMute(true);
                          }
                        }}
                        aria-label={muted ? "Unmute" : "Mute"}
                        icon={
                          muted ? (
                            <i className="far fa-volume-mute w-6 text-xl" />
                          ) : (
                            <i className="far fa-volume-up w-6 text-xl" />
                          )
                        }
                      />
                      <input
                        type="range"
                        value={mute ? 0 : volume}
                        min={0}
                        max={0.999999}
                        step={0.01}
                        className="h-3"
                        onChange={(e) => {
                          if (mute && Number(e.target.value) > 0) {
                            setMute(false);
                          }
                          setVolume(Number(e.target.value));
                        }}
                        aria-label="Volume"
                        aria-valuetext={`${Math.round(volume * 100)} percent`}
                      />

                      <Select
                        className="min-w-14"
                        options={[
                          { value: 0.25, label: ".25x" },
                          { value: 0.5, label: ".5x" },
                          { value: 0.75, label: ".75x" },
                          { value: 1, label: "1x" },
                          { value: 1.25, label: "1.25x" },
                          { value: 1.5, label: "1.5x" },
                          { value: 1.75, label: "1.75x" },
                          { value: 2, label: "2x" },
                        ]}
                        defaultValue={{ value: 1, label: "1x" }}
                        isSearchable={false}
                        menuPortalTarget={containerRef.current}
                        components={{
                          IndicatorSeparator: () => null,
                          DropdownIndicator: () => <></>,
                        }}
                        onChange={(e) => setPlaybackRate(e?.value || 1)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </FocusTrap>
      )}
    </>
  );
};

export default VideoModal;
