export const setItemWithTimestamp = (key: string, value: unknown) => {
  const now = new Date();
  const item = {
    value: value,
    timestamp: now.getTime(),
  };
  localStorage.setItem(key, JSON.stringify(item));
};

export const getItemWithExpiry = (key: string, ttl: number) => {
  const itemStr = localStorage.getItem(key);
  if (!itemStr) {
    return null;
  }
  const item = JSON.parse(itemStr);
  const now = new Date();
  if (now.getTime() > item.timestamp + ttl) {
    localStorage.removeItem(key);
    return null;
  }
  return item.value;
};
