/** An item in a list of states/provinces */
export interface RegionItem {
  key: string;
  name: string;
}

type RegionSortBy = "name" | "code";

function regionSorter(sortBy: RegionSortBy) {
  return (a: RegionItem, b: RegionItem) => {
    if (sortBy === "name") {
      return a.name.localeCompare(b.name);
    } else {
      return a.key.localeCompare(b.key);
    }
  };
}

/** List of all US states */
const STATES: RegionItem[] = [
  { key: "AL", name: "Alabama" },
  { key: "AK", name: "Alaska" },
  { key: "AZ", name: "Arizona" },
  { key: "AR", name: "Arkansas" },
  { key: "CA", name: "California" },
  { key: "CO", name: "Colorado" },
  { key: "CT", name: "Connecticut" },
  { key: "DE", name: "Delaware" },
  { key: "FL", name: "Florida" },
  { key: "GA", name: "Georgia" },
  { key: "HI", name: "Hawaii" },
  { key: "ID", name: "Idaho" },
  { key: "IL", name: "Illinois" },
  { key: "IN", name: "Indiana" },
  { key: "IA", name: "Iowa" },
  { key: "KS", name: "Kansas" },
  { key: "KY", name: "Kentucky" },
  { key: "LA", name: "Louisiana" },
  { key: "ME", name: "Maine" },
  { key: "MD", name: "Maryland" },
  { key: "MA", name: "Massachusetts" },
  { key: "MI", name: "Michigan" },
  { key: "MN", name: "Minnesota" },
  { key: "MS", name: "Mississippi" },
  { key: "MO", name: "Missouri" },
  { key: "MT", name: "Montana" },
  { key: "NE", name: "Nebraska" },
  { key: "NV", name: "Nevada" },
  { key: "NH", name: "New Hampshire" },
  { key: "NJ", name: "New Jersey" },
  { key: "NM", name: "New Mexico" },
  { key: "NY", name: "New York" },
  { key: "NC", name: "North Carolina" },
  { key: "ND", name: "North Dakota" },
  { key: "OH", name: "Ohio" },
  { key: "OK", name: "Oklahoma" },
  { key: "OR", name: "Oregon" },
  { key: "PA", name: "Pennsylvania" },
  { key: "RI", name: "Rhode Island" },
  { key: "SC", name: "South Carolina" },
  { key: "SD", name: "South Dakota" },
  { key: "TN", name: "Tennessee" },
  { key: "TX", name: "Texas" },
  { key: "UT", name: "Utah" },
  { key: "VT", name: "Vermont" },
  { key: "VA", name: "Virginia" },
  { key: "WA", name: "Washington" },
  { key: "WV", name: "West Virginia" },
  { key: "WI", name: "Wisconsin" },
  { key: "WY", name: "Wyoming" },
];

export function getStates(sortBy: RegionSortBy = "name"): RegionItem[] {
  return STATES.sort(regionSorter(sortBy));
}

/** List of all Canadian provinces */
const PROVINCES: RegionItem[] = [
  { key: "AB", name: "Alberta" },
  { key: "BC", name: "British Columbia" },
  { key: "MB", name: "Manitoba" },
  { key: "NB", name: "New Brunswick" },
  { key: "NL", name: "Newfoundland and Labrador" },
  { key: "NT", name: "Northwest Territories" },
  { key: "NS", name: "Nova Scotia" },
  { key: "NU", name: "Nunavut" },
  { key: "ON", name: "Ontario" },
  { key: "PE", name: "Prince Edward Island" },
  { key: "QC", name: "Quebec" },
  { key: "SK", name: "Saskatchewan" },
  { key: "YT", name: "Yukon" },
];

export function getProvinces(sortBy: RegionSortBy = "name"): RegionItem[] {
  return PROVINCES.sort(regionSorter(sortBy));
}

/**
 * Get a combined list of all US states and Canadian provinces
 * @param sortBy Sort by "name" or "code"
 */
export function getStatesAndProvinces(
  sortBy: RegionSortBy = "name"
): RegionItem[] {
  return [...STATES, ...PROVINCES].sort(regionSorter(sortBy));
}
