import { format, isAfter } from "date-fns";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useActivationContext } from "./LicenseActivationContext";

const ExtendLicense: React.FC<{
  handleExtend: () => void;
  extensionDate: number;
  setExtensionDate: React.Dispatch<React.SetStateAction<number>>;
}> = ({ handleExtend, extensionDate, setExtensionDate }) => {
  const toastContext = useDeltaToastContext();
  const activationContext = useActivationContext();

  const dateStrings = [
    "2025-10-01 23:59:00-4:00",
    "2025-10-15 23:59:00-4:00",
    "2025-11-01 23:59:00-4:00",
    "2025-11-15 23:59:00-5:00",
    "2025-12-01 23:59:00-5:00",
    "2025-12-15 23:59:00-5:00",
    "2026-01-01 23:59:00-5:00",
    "2027-01-01 23:59:00-5:00",
  ];

  let datesToRender = dateStrings.filter((dateString) =>
    isAfter(new Date(dateString), new Date())
  );
  if (activationContext?.invoiceDetails?.expiration) {
    const expirationDate = new Date(
      activationContext.invoiceDetails.expiration * 1000
    );
    datesToRender = dateStrings.filter((dateString) =>
      isAfter(new Date(dateString), expirationDate)
    );
  }

  return (
    <div className="flex flex-row items-center">
      <select
        className="mx-2 rounded text-xs"
        id="extension-date"
        value={extensionDate}
        onChange={(e) => setExtensionDate(Number(e.target.value))}
      >
        <option value="">Select a date</option>
        {datesToRender.map((dateStr) => {
          const date = new Date(dateStr);
          return (
            <option key={dateStr} value={Math.floor(date.getTime() / 1000)}>
              {format(date, "MMM. d, yyyy")}
            </option>
          );
        })}
      </select>
      <button
        type="button"
        className="ml-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        onClick={() => {
          if (extensionDate) {
            handleExtend();
          } else {
            toastContext.addToast({
              message: `Please select an extension date`,
              status: "Error",
            });
          }
        }}
      >
        Extend
      </button>
    </div>
  );
};

export default ExtendLicense;
