import { SearchIcon } from "@heroicons/react/outline";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { REACT_APP_MANAGER_LINK } from "../../../utils";
import { useQueryClient } from "@tanstack/react-query";

export const InvoiceSearch = ({ quoteNumber }: { quoteNumber?: string }) => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const [codeToLookup, setCodeToLookup] = useState(quoteNumber || "");

  useEffect(() => {
    if (quoteNumber) {
      setCodeToLookup(quoteNumber);
    }
  }, [quoteNumber]);

  return (
    <div className="px-2 md:px-0">
      <h1 className="md:mx-22 py-4 text-2xl font-semibold text-gray-900">
        Quote Lookup
      </h1>
      <form
        className="ml-1 inline-flex"
        onSubmit={(e) => {
          e.preventDefault();
          window.location.href = `${REACT_APP_MANAGER_LINK}/quote-lookup/${codeToLookup}`;
        }}
      >
        <div className="relative">
          <SearchIcon
            className="pointer-events-none absolute left-4 top-3.5 h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          className="col-span-1 block h-12 w-full border-gray-300 bg-white pl-11 pr-4 text-gray-800 placeholder-gray-400 focus:border-indigo-500 focus:ring-0 focus:ring-indigo-500 sm:text-sm md:col-span-2 md:rounded-md"
          type="text"
          placeholder="Quote #"
          value={codeToLookup}
          onChange={(e) => setCodeToLookup(e.target.value)}
        ></input>
        <button
          type="submit"
          className="ml-2 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          Search
        </button>
      </form>
    </div>
  );
};

export default InvoiceSearch;
