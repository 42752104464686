import { useState } from "react";
import Button from "../../../student/components/generic/button";
import { CreatePlansModal } from "./CreatePlansModal";
import { LearnerSubscriptionPlan } from "../../../learner/types";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { deltamathAPI } from "../../utils";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { useDocumentTitle } from "usehooks-ts";
import { DataTable } from "../DataTable/DataTable";
import { capitalize } from "lodash";
import clsx from "clsx";
import {
  DataTableContextProvider,
  useDataTable,
} from "../DataTable/DataTableContext";

export const SubscriptionPlansPage = () => (
  <DataTableContextProvider
    source="/payments/subscriptions/plans/list/all"
    sortable={["createdAt", "publishedAt", "archivedAt"]}
    filterable={["stripeProductId", "stripePlanId"]}
    defaults={{ sortBy: [{ id: "createdAt", desc: true }] }}
    columnOverrides={{
      state: (row) => {
        const bgColor =
          row.value === "draft"
            ? "bg-yellow-500"
            : row.value === "published"
            ? "bg-green-700"
            : "bg-gray-400";
        return (
          <span className={clsx("rounded px-2 py-1 text-white", bgColor)}>
            {capitalize(String(row.value))}
          </span>
        );
      },
    }}
    globalFilter
    fetchOnLoad
  >
    <SubscriptionPlansPageContent />
  </DataTableContextProvider>
);

export const SubscriptionPlansPageContent = () => {
  useDocumentTitle("Subscription Plans");
  const {
    rows,
    refetchTableData,
    isFetching,
    draftAnnual,
    draftMonthly,
    publishedAnnual,
    publishedMonthly,
  } = useDataTable<{
    draftMonthly?: LearnerSubscriptionPlan;
    draftAnnual?: LearnerSubscriptionPlan;
    publishedMonthly?: LearnerSubscriptionPlan;
    publishedAnnual?: LearnerSubscriptionPlan;
  }>();
  const [showCreatePlansModal, setShowCreatePlansModal] = useState(false);

  const { mutateAsync: publishPlans } = useMutation({
    mutationFn: () => {
      return axios.post(
        `${deltamathAPI()}/payments/subscriptions/plans/publish`,
        {},
        withJsonHeader()
      );
    },
  });

  const handlePublish = async () => {
    const message =
      "Are you sure you want to publish the current draft plans? This will make them visible to learners.";
    if (window.confirm(message)) {
      await publishPlans();
      refetchTableData();
    }
  };

  return (
    <div className="flex flex-col gap-6 p-6">
      <header>
        <h1 className="mb-2 text-2xl font-bold">Subscription Plans</h1>
        <ul className="text-sm text-dm-charcoal-500">
          <li>
            <b>Draft:</b> These plans have been created but are not yet visible
            to learners
            <ul className="ml-3 list-inside list-disc">
              <li>
                There will only ever be two draft plans at one
                time&mdash;monthly and annual
              </li>
              <li>Creating new drafts will archive existing drafts</li>
            </ul>
          </li>
          <li>
            <b>Published:</b> These plans are currently visible to learners
            <ul className="ml-3 list-inside list-disc">
              <li>
                There will only ever be two published plans at one
                time&mdash;monthly and annual
              </li>
              <li>
                Publishing will archive the current published plans and make the
                drafts visible
              </li>
            </ul>
          </li>
          <li>
            <b>Archived:</b> These plans are no longer visible to learners
          </li>
        </ul>
      </header>
      <div className="flex gap-4">
        <Button
          className="flex w-auto items-center gap-3 !px-4"
          size="small"
          disabled={isFetching}
          onClick={() => setShowCreatePlansModal(true)}
        >
          <i className="fas fa-plus" />
          Create new draft plans
        </Button>
        <Button
          className="flex w-auto items-center gap-3 bg-dm-charcoal-500 !px-4 hover:bg-dm-charcoal-600 disabled:hover:bg-dm-charcoal-500"
          size="small"
          disabled={!(draftAnnual || draftMonthly)}
          onClick={handlePublish}
        >
          <i className="fas fa-arrow-alt-circle-up" />
          Publish current draft plans
        </Button>
      </div>

      <DataTable />

      {rows && (
        <CreatePlansModal
          key={rows.length}
          visible={showCreatePlansModal}
          currentMonthlyPlan={publishedMonthly || draftMonthly}
          currentAnnualPlan={publishedAnnual || draftAnnual}
          onClose={() => setShowCreatePlansModal(false)}
          onPlansCreated={() => {
            refetchTableData();
            setShowCreatePlansModal(false);
          }}
        />
      )}
    </div>
  );
};
