import { Column, ColumnInstance } from "react-table";
import { ColumnOverrides, TableJsonResponse, TableRow } from "./types";
import { TableHeaderFilterField } from "./TableHeaderFilterField";
import { DateCell } from "./DateCell";
import { CurrencyCell } from "./CurrencyCell";
import { LinkCell } from "./LinkCell";
import { ReactNode } from "react";
import { EmptyCell } from "./EmptyCell";
import { PercentageCell } from "./PercentageCell";
import { NumberCell } from "./NumberCell";
import { BooleanCell } from "./BooleanCell";

export const OptionalCell: React.FC<{ value?: any }> = ({ value }) => {
  if (value === null || value === undefined) {
    return <EmptyCell />;
  }
  return value;
};

export function buildColumnConfig(
  data: TableJsonResponse | undefined,
  columnOverrides: ColumnOverrides,
  filterable: string[]
): Column<TableRow>[] {
  return (data?.columns ?? []).map((c) => {
    const override = columnOverrides?.[c.key];
    const defaultColumn = {
      Header: c.label,
      accessor: c.key,
      Filter: ({ column }: { column: ColumnInstance<TableRow> }) =>
        filterable.includes(column.id) ? (
          <TableHeaderFilterField column={column} />
        ) : (
          <></>
        ),
    };
    const overrideableCell = (cell?: (d: any) => ReactNode) =>
      override ? { Cell: override } : cell ? { Cell: cell } : {};
    switch (c.type) {
      case "number":
        return {
          ...defaultColumn,
          align: "right",
          ...overrideableCell(NumberCell),
        };
      case "string":
        return {
          ...defaultColumn,
          ...overrideableCell(OptionalCell),
        };
      case "date":
        return {
          ...defaultColumn,
          ...overrideableCell(DateCell),
        };
      case "currency":
        return {
          ...defaultColumn,
          align: "right",
          ...overrideableCell(CurrencyCell),
        };
      case "percentage":
        return {
          ...defaultColumn,
          align: "right",
          ...overrideableCell(PercentageCell),
        };
      case "link":
        return {
          ...defaultColumn,
          ...overrideableCell(LinkCell),
        };
      case "boolean":
        return {
          ...defaultColumn,
          ...overrideableCell(BooleanCell),
        };
      case "custom":
        return {
          ...defaultColumn,
          ...overrideableCell(() => (
            <span className="text-dm-error-500">Implementation needed!</span>
          )),
        };
      default:
        throw new Error(`Unknown column type: ${(c as any).type}`);
    }
  });
}
