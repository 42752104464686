import { useState } from "react";
import Button from "../../../student/components/generic/button";

export const ContactsCell: React.FC<{ contacts: string[] }> = ({
  contacts,
}) => {
  const head = contacts.slice(0, 2);
  const tail = contacts.slice(2);
  const [showTail, setShowTail] = useState(false);
  return (
    <ul>
      {head.map((c: string) => (
        <li key={c}>{c}</li>
      ))}
      {tail.length > 0 && !showTail && (
        <li>
          <Button type="link" onClick={() => setShowTail(true)}>
            +{tail.length} more
          </Button>
        </li>
      )}
      {showTail && tail.length && (
        <>
          {tail.map((c: string) => (
            <li key={c}>{c}</li>
          ))}
          {tail.length > 0 && showTail && (
            <li>
              <Button type="link" onClick={() => setShowTail(false)}>
                Collapse
              </Button>
            </li>
          )}
        </>
      )}
    </ul>
  );
};
