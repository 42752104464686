import { useState, useEffect } from "react";
import {
  coerceLicenseType,
  discriminateInvoiceType,
  IInvoice,
  isValidLicenseType,
  TLicenseRange,
} from "../../utils/quoteUtils";
import InvoicePageEditableCard from "./InvoicePageEditableCard";
import { useActivationContext } from "./LicenseActivationContext";
import { deltamathAPI, formatNumber, getDatesArray } from "../../utils";
import { timeConverter } from "../../../utils";
import DatePicker from "react-datepicker";
import { startOfDay, isBefore } from "date-fns";
import Warnings from "./Warnings";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import clsx from "clsx";
import ExtendLicense from "./Extend";

const LicenseToActivateDetails: React.FC<{ invoiceType: IInvoice["type"] }> = ({
  invoiceType,
}) => {
  const activationContext = useActivationContext();
  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();

  const [saveDisabled, setSaveDisabled] = useState(true);
  const [showEditButton, setShowEditButton] = useState(
    activationContext?.invoiceDetails?.status === "Activated"
  );

  //used specifically for fields that should be editable after activation (when edit button clicked). Pre-activation, editable fields are determined by status not being "Activated"
  const [edit, setEdit] = useState(false);
  const [currentNumberOfStudents, setCurrentNumberOfStudents] = useState<
    IInvoice["priceInfo"]["numberOfStudents"]
  >(activationContext.invoiceDetails?.priceInfo?.numberOfStudents || 0);
  const [currentNumberOfTeachers, setCurrentNumberOfTeachers] = useState<
    IInvoice["priceInfo"]["numberOfTeachers"]
  >(activationContext.invoiceDetails?.priceInfo?.numberOfTeachers || 0);
  const [currentTotalPrice, setCurrentTotalPrice] = useState<number>(
    activationContext.invoiceDetails?.amount || 0
  );
  const [currentExpiration, setCurrentExpiration] = useState<number>(
    activationContext.invoiceDetails?.expiration || 0
  );
  const [currentTier, setCurrentTier] = useState<string>(
    activationContext.invoiceDetails?.licenseTier || ""
  );

  const [currentHoldUntilDate, setHoldUntilDate] = useState<Date | null>(
    activationContext.invoiceDetails?.hold_until
      ? new Date(activationContext.invoiceDetails?.hold_until * 1000)
      : null
  );

  const [detailsChanged, setDetailsChanged] = useState<
    Set<
      | "numberOfStudents"
      | "numberOfTeachers"
      | "amount"
      | "type"
      | "expiration"
      | "licenseTier"
      | "hold_until"
    >
  >(new Set());

  const [generalType, setGeneralType] = useState<
    "school" | "district" | "individual"
  >(discriminateInvoiceType(coerceLicenseType(invoiceType)));

  const [extensionDate, setExtensionDate] = useState<number>(0);

  const nextDates = getDatesArray({ max: 6 });

  const handleLocalSave = () => {
    const body = {
      "priceInfo.numberOfStudents": currentNumberOfStudents,
      "priceInfo.numberOfTeachers": currentNumberOfTeachers,
      //[MB] - does priceInfo.priceWithDiscount need to be updated here?
      ...(currentTotalPrice ===
      activationContext.qboEstimateData?.totalQuotePrice
        ? { amount: currentTotalPrice }
        : {}),
      type: activationContext.state.currentQuoteType,
      expiration: currentExpiration,
      licenseTier: currentTier,
      ...(currentHoldUntilDate &&
      !isBefore(currentHoldUntilDate, startOfDay(new Date())) &&
      detailsChanged.has("hold_until")
        ? { hold_until: Math.floor(currentHoldUntilDate.getTime() / 1000) }
        : { hold_until: null }),
    };
    activationContext.simpleQuoteUpdate(body);
  };

  const { mutate: postActivationUpdate } = useMutation({
    mutationFn: (updateAction: "priceAndStudentCount" | "extension") => {
      let body;

      if (updateAction === "priceAndStudentCount") {
        body = {
          newPrice: currentTotalPrice,
          newStudentCount: currentNumberOfStudents,
        };
      }

      if (updateAction === "extension") {
        body = {
          newExpiration: extensionDate,
        };
      }

      return axios.put(
        deltamathAPI() +
          `manager_new/invoices/post_activation_update/${activationContext.state.quoteNumber}?updateAction=${updateAction}`,
        body
      );
    },
    onSuccess: () => {
      toastContext.addToast({
        status: "Success",
        message: `License and Quote Updated`,
      });
    },
    onError: (err: any) => {
      toastContext.addToast({
        status: "Error",
        message:
          err?.response?.data?.message ||
          `Error updating license/quote: ${JSON.stringify(err)} `,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["invoice", activationContext.state.quoteNumber],
      });
    },
  });

  const updateChangedDetails = (
    detail:
      | "numberOfStudents"
      | "numberOfTeachers"
      | "amount"
      | "type"
      | "expiration"
      | "licenseTier"
      | "hold_until",
    action: "add" | "delete"
  ) => {
    setDetailsChanged((prev) => {
      const updated = new Set(prev);
      updated[action](detail);
      return updated;
    });
  };

  const handleDetailChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    switch (name) {
      case "numberOfStudents":
        setCurrentNumberOfStudents(parseInt(value, 10));
        break;
      case "numberOfTeachers":
        setCurrentNumberOfTeachers(parseInt(value, 10));
        break;
      case "amount":
        if (
          !activationContext.qboEstimateData?.totalQuotePrice ||
          activationContext.invoiceDetails?.amount !==
            activationContext.qboEstimateData?.totalQuotePrice
        ) {
          setCurrentTotalPrice(parseInt(value, 10));
        }
        break;
      case "type":
        activationContext.setCurrentQuoteType(e.target.value as TLicenseRange);
        if (e.target.value === "") {
          setSaveDisabled(true);
        }
        break;
      case "expiration":
        setCurrentExpiration(parseInt(e.target.value));
        break;
      case "licenseTier":
        setCurrentTier(e.target.value);
        break;
      default:
        break;
    }
    if (name === "numberOfStudents" || name === "numberOfTeachers") {
      if (
        parseInt(value, 10) ===
          activationContext.invoiceDetails?.priceInfo?.[name] ||
        (!value && !activationContext.invoiceDetails?.priceInfo?.[name])
      ) {
        updateChangedDetails(name, "delete");
      } else {
        updateChangedDetails(name, "add");
      }
    } else if (name === "amount" || name === "expiration") {
      if (
        parseInt(value, 10) === activationContext.invoiceDetails?.[name] ||
        (!value && !activationContext.invoiceDetails?.[name])
      ) {
        updateChangedDetails(name, "delete");
      } else {
        updateChangedDetails(name, "add");
      }
    } else if (name === "type" || name === "licenseTier") {
      if (
        value === activationContext.invoiceDetails?.[name] ||
        (!value && !activationContext.invoiceDetails?.[name])
      ) {
        updateChangedDetails(name, "delete");
      } else {
        updateChangedDetails(name, "add");
      }
    }
  };

  //separate b/c of datepicker
  const handleHoldUntilChange = (date: Date) => {
    if (!isBefore(date, startOfDay(new Date()))) {
      setHoldUntilDate(date);
    }
    if (
      (date &&
        Math.floor(date.getTime() / 1000) ===
          activationContext.invoiceDetails?.hold_until) ||
      (!date && !activationContext.invoiceDetails?.hold_until)
    ) {
      updateChangedDetails("hold_until", "delete");
    } else {
      updateChangedDetails("hold_until", "add");
    }
  };

  useEffect(() => {
    let currentLicenseType = invoiceType;
    if (!isValidLicenseType(currentLicenseType)) {
      currentLicenseType = coerceLicenseType(currentLicenseType);
    }
    activationContext.setCurrentQuoteType(
      currentLicenseType as TLicenseRange | ""
    );
  }, [invoiceType]);

  useEffect(() => {
    if (activationContext?.invoiceDetails?.status === "Activated" && !edit) {
      setShowEditButton(true);
    } else {
      setShowEditButton(false);
    }
  }, [activationContext?.invoiceDetails?.status, edit]);

  useEffect(() => {
    if (detailsChanged.size) {
      setSaveDisabled(false);
    } else {
      setSaveDisabled(true);
    }
  }, [detailsChanged]);

  return (
    <InvoicePageEditableCard
      title="License Details"
      handleLocalSave={handleLocalSave}
      handlePostActivationEdit={() =>
        postActivationUpdate("priceAndStudentCount")
      }
      saveDisabled={saveDisabled}
      setEdit={setEdit}
      showEditButton={showEditButton}
      overflow={"visible"}
    >
      <div className="flex flex-row gap-1">
        <dl className="flex flex-col gap-y-5">
          <div className="item mb-2 flex w-full flex-row gap-3">
            <div className="">
              <dt className="text-sm font-medium text-gray-500">
                License Type
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {activationContext.invoiceDetails?.status !== "Activated" ? (
                  <select
                    className="border-gray-300 md:rounded-md"
                    id="license_type"
                    name="type"
                    value={activationContext.state.currentQuoteType}
                    onChange={handleDetailChange}
                  >
                    <option value=""> - </option>
                    <option value="school">School License</option>
                    <option value="6-12">District (6-12)</option>
                    <option value="9-12">District (9-12)</option>
                    <option value="6-8">District (6-8)</option>
                    <option value="7-12">District (7-12)</option>
                    <option value="8-12">District (8-12)</option>
                    <option value="7-8">District (7-8)</option>
                    <option value="individual" className="text-xs">
                      Individual Licenses
                    </option>
                  </select>
                ) : (
                  <div>{invoiceType || "--"}</div>
                )}
              </dd>
            </div>

            {activationContext.state.currentQuoteType !== "individual" && (
              <div className="">
                <dt className="text-sm font-medium text-gray-500">
                  Quote # Students
                </dt>
                <dd className="mt-1 inline-flex text-sm text-gray-900">
                  {edit ||
                  activationContext.invoiceDetails?.status !== "Activated" ? (
                    <input
                      className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                      type="number"
                      name="numberOfStudents"
                      min="0"
                      value={currentNumberOfStudents}
                      onChange={handleDetailChange}
                    ></input>
                  ) : (
                    <div>
                      {activationContext.invoiceDetails?.priceInfo
                        ?.numberOfStudents || "--"}
                    </div>
                  )}
                </dd>
              </div>
            )}
            {activationContext.state.currentQuoteType === "individual" && (
              <div className="flex flex-col">
                <dt className="text-sm font-medium text-gray-500">
                  Number of{" "}
                  {activationContext.invoiceDetails?.status === "Activated"
                    ? "Quoted"
                    : "License"}{" "}
                  Codes
                </dt>
                <dd className="mt-1 inline-flex text-sm text-gray-900">
                  {activationContext.invoiceDetails?.status !== "Activated" ? (
                    <input
                      className="block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                      type="number"
                      name="numberOfTeachers"
                      min="0"
                      value={currentNumberOfTeachers}
                      onChange={handleDetailChange}
                    ></input>
                  ) : (
                    <div>
                      {activationContext.invoiceDetails?.priceInfo
                        ?.numberOfTeachers || "--"}
                    </div>
                  )}
                </dd>{" "}
              </div>
            )}
            {activationContext.invoiceDetails?.type === "individual" &&
              activationContext.invoiceDetails?.status === "Activated" &&
              activationContext.invoiceDetails.licenseTier && (
                <div className="flex flex-row gap-2">
                  <div className="flex flex-col">
                    <div className="text-sm font-medium text-gray-500">
                      Total Codes (Current)
                    </div>
                    <div>
                      {activationContext.licenseData?.individual?.[
                        activationContext.invoiceDetails.licenseTier.toLowerCase() as
                          | "plus"
                          | "integral"
                      ]?.[0].totalSubscriptions || "--"}
                    </div>
                  </div>
                  <div className="flex flex-col">
                    <div className="text-sm font-medium text-gray-500">
                      Codes Remaining
                    </div>
                    <div>
                      {activationContext.licenseData?.individual?.[
                        activationContext.invoiceDetails.licenseTier.toLowerCase() as
                          | "plus"
                          | "integral"
                      ]?.[0].subscriptionsRemaining || "--"}
                    </div>
                  </div>
                </div>
              )}
          </div>

          <div
            className={clsx(
              "item mb-2 flex w-full flex-row items-end",
              activationContext.invoiceDetails?.status === "Activated"
                ? "gap-8"
                : "gap-4"
            )}
          >
            <div className="self-stretch">
              <dt className="text-sm font-medium text-gray-500">Price</dt>
              <dd className="relative mt-1 text-sm text-gray-900">
                {edit ? (
                  <>
                    <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                      <span className="text-gray-500 sm:text-sm">$</span>
                    </div>
                    <input
                      className="col-span-1 block border-gray-300 pl-7 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                      type="number"
                      min="0"
                      name="amount"
                      value={currentTotalPrice}
                      onChange={handleDetailChange}
                    ></input>
                  </>
                ) : (
                  <div className="text-sm">
                    ${" "}
                    {formatNumber(
                      activationContext.invoiceDetails?.amount || 0
                    ) || "--"}
                  </div>
                )}
              </dd>
            </div>
            <div className="flex flex-col self-stretch">
              <dt className="text-sm font-medium text-gray-500">Expiration</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {activationContext.invoiceDetails?.status !== "Activated" ? (
                  <select
                    id="expiration"
                    className="border-gray-300 md:rounded-md"
                    name="expiration"
                    value={currentExpiration}
                    onChange={handleDetailChange}
                  >
                    <option value={0}>--</option>
                    {nextDates.map((date, index) => {
                      return (
                        <option
                          key={index}
                          value={Math.floor(date.getTime() / 1000)}
                        >
                          {`October ${date.getDate()}, ${date.getFullYear()}`}
                        </option>
                      );
                    })}
                  </select>
                ) : (
                  <div>
                    {activationContext.invoiceDetails?.expiration &&
                      timeConverter(
                        activationContext.invoiceDetails?.expiration
                      )}
                  </div>
                )}
              </dd>
            </div>
            {activationContext.invoiceDetails?.type === "individual" &&
              activationContext.invoiceDetails?.status === "Activated" &&
              activationContext.invoiceDetails.licenseTier && (
                <div className="flex flex-col self-stretch">
                  <div className="text-sm font-medium text-gray-500">
                    Auth Code
                  </div>
                  <div>
                    {activationContext.licenseData?.individual?.[
                      activationContext.invoiceDetails.licenseTier.toLowerCase() as
                        | "plus"
                        | "integral"
                    ]?.[0].accessCode || "--"}
                  </div>
                </div>
              )}

            {activationContext.invoiceDetails?.status === "Activated" &&
              activationContext.invoiceDetails?.quoteNumber &&
              (generalType === "school" || generalType === "district") &&
              activationContext.invoiceDetails?.id && (
                <ExtendLicense
                  handleExtend={() => postActivationUpdate("extension")}
                  extensionDate={extensionDate}
                  setExtensionDate={setExtensionDate}
                />
              )}
          </div>

          <div className="item mb-2 flex w-full flex-row gap-1">
            <div className="basis-1/2">
              <dt className="text-sm font-medium text-gray-500">
                License Tier
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {activationContext.invoiceDetails?.status !== "Activated" ? (
                  <select
                    id="licenseTier"
                    className="border-gray-300 md:rounded-md"
                    name="licenseTier"
                    value={currentTier}
                    onChange={handleDetailChange}
                  >
                    <option value="">--</option>
                    <option value="Integral">Integral</option>
                    <option value="Plus">Plus</option>
                  </select>
                ) : (
                  <div>
                    {activationContext.invoiceDetails?.licenseTier || "--"}
                  </div>
                )}
              </dd>
            </div>
            {activationContext.invoiceDetails?.status !== "Activated" && (
              <div className="basis-1/2">
                <dt className="text-sm font-medium text-gray-500">
                  Hold Until
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  <DatePicker
                    minDate={new Date()}
                    selected={currentHoldUntilDate}
                    onChange={handleHoldUntilChange}
                    popperPlacement="top-start"
                    popperModifiers={[
                      {
                        name: "flip",
                        options: {
                          flipVariations: false,
                          allowedAutoPlacements: ["bottom", "bottom-start"],
                          fallbackPlacements: ["bottom", "bottom-start"],
                        },
                      },
                      {
                        name: "preventOverflow",
                        enabled: false,
                      },
                      {
                        name: "hide",
                        enabled: false,
                      },
                      {
                        name: "offset",
                        options: {
                          offset: [0, 0],
                        },
                      },
                    ]}
                    selectsStart
                    nextMonthButtonLabel=">"
                    previousMonthButtonLabel="<"
                    dateFormat="MM/dd/yyyy"
                  />
                </dd>
              </div>
            )}
            {activationContext.invoiceDetails?.status === "Activated" &&
              activationContext.invoiceDetails?.hold_until && (
                <div className="basis-1/2">
                  <dt className="text-sm font-medium text-gray-500">
                    Hold Until
                  </dt>
                  <dd className="mt-1 text-sm text-gray-900">
                    {activationContext.invoiceDetails?.hold_until &&
                      timeConverter(
                        activationContext.invoiceDetails?.hold_until
                      )}
                  </dd>
                </div>
              )}
          </div>
        </dl>
        <Warnings className="flex flex-col">
          {activationContext.state.activationDisableReasons.has(
            "individualCodes"
          ) && (
            <div className="text-center text-sm text-red-500">
              Enter a # of codes for the individual license{" "}
            </div>
          )}
          {activationContext.state.activationDisableReasons.has("price") && (
            <div className="text-center text-sm text-red-500">
              This price must match QBO price. If the QBO price is wrong, please
              edit it in Quickbooks
            </div>
          )}
          {activationContext.state.activationDisableReasons.has(
            "expiration"
          ) && (
            <div className="text-center text-sm text-red-500">
              Enter an Expiration
            </div>
          )}
          {activationContext.state.activationDisableReasons.has("hold") && (
            <div className="text-center text-sm text-red-500">
              Cannot activate until hold date
            </div>
          )}
          {activationContext.state.activationDisableReasons.has("tier") && (
            <div className="text-center text-sm text-red-500">
              A license tier must be saved
            </div>
          )}
        </Warnings>
      </div>
    </InvoicePageEditableCard>
  );
};

export default LicenseToActivateDetails;
