import React, { useCallback } from "react";
import { IAdminData } from "./adminUtils";

interface TableInputProps extends React.InputHTMLAttributes<HTMLInputElement> {
  type: "radio" | "checkbox";
  checked: boolean;
  rowData: IAdminData;
  onValueChange: (rowData: IAdminData, isChecked: boolean) => void;
}

const AdminInputSwitch: React.FC<TableInputProps> = ({
  type,
  checked,
  rowData,
  onValueChange,
  ...inputProps
}) => {
  const handleChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      e.preventDefault();
      onValueChange(rowData, e.target.checked);
    },
    [rowData, onValueChange]
  );

  return (
    <input
      type={type}
      checked={checked}
      onChange={handleChange}
      {...inputProps}
    />
  );
};
export default AdminInputSwitch;
