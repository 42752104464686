import { useState, useRef, useMemo } from "react";
import axios from "axios";
import { useInfiniteQuery } from "@tanstack/react-query";
import { ParentActivity } from "../../types";
import { ChartSquareBarIcon } from "@heroicons/react/outline";
import RecentActivityCard from "./RecentActivityCard";
import RecentActivityModal from "../Modals/RecentActivityModal";
import Button from "../../../student/components/generic/button";
import { deltamathAPI } from "../../../manager/utils";

interface ActivityQuery {
  learnerId: string | undefined;
  size: number;
  page: number;
}

const QUERY_DEFAULTS = {
  size: 10,
  page: 0,
};

const fetchActivityData = async ({
  learnerId,
  size,
  page,
}: ActivityQuery): Promise<{
  totalCount: number;
  activity: ParentActivity[];
}> => {
  return axios
    .get(`${deltamathAPI()}/learner/parent/activity/${learnerId}`, {
      params: {
        limit: size,
        skip: page,
      },
    })
    .then((res) => res.data);
};

const useActivityQuery = (query: ActivityQuery) =>
  useInfiniteQuery<{ totalCount: number; activity: ParentActivity[] }, Error>({
    enabled: !!query.learnerId,
    queryKey: ["activity", query],
    initialPageParam: QUERY_DEFAULTS.page,
    queryFn: ({ pageParam }) => {
      const size = (pageParam as any).size || query.size;
      const page = (pageParam as any).page || query.page;

      return fetchActivityData({
        size,
        page,
        learnerId: query.learnerId,
      });
    },
    getNextPageParam: (lastPage, allPages) => {
      const totalCount = lastPage.totalCount;
      const activityTotal = allPages.reduce((acc, page) => {
        return acc.concat(page.activity);
      }, [] as ParentActivity[]).length;

      if (activityTotal >= totalCount) return undefined;

      const remainingActivityCount = totalCount - activityTotal;
      return {
        ...query,
        size: Math.min(remainingActivityCount, QUERY_DEFAULTS.size),
        page: activityTotal,
      };
    },
  });

type Props = {
  currentLearnerId?: string;
};

export const RecentActivity: React.FC<Props> = (props) => {
  const [showActivityModal, setShowActivityModal] = useState<boolean>(false);

  const {
    data: activityData,
    hasNextPage,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
  } = useActivityQuery({
    learnerId: props.currentLearnerId,
    size: QUERY_DEFAULTS.size,
    page: QUERY_DEFAULTS.page,
  });

  const currentActivity = useMemo(
    () =>
      activityData?.pages?.reduce((acc, page) => {
        return acc.concat(page.activity);
      }, [] as ParentActivity[]) ?? [],
    [activityData]
  );

  const showedTestInProgress = useRef(false);

  const CARD_MAXIMUMS = [
    { small: 7, large: 0 },
    { small: 5, large: 1 },
    { small: 3, large: 2 },
    { small: 1, large: 3 },
    { small: 0, large: 4 },
  ];

  const cardCount = useRef({ small: 0, large: 0 });

  const isCardAllowed = (cardType: "small" | "large") =>
    CARD_MAXIMUMS.find(({ small, large }) => {
      const nextSmall =
        cardCount.current.small + (cardType === "small" ? 1 : 0);
      const nextLarge =
        cardCount.current.large + (cardType === "large" ? 1 : 0);
      return nextSmall <= small && nextLarge <= large;
    });

  return (
    <>
      <div className="flex w-full flex-col rounded-xl border border-dm-charcoal-100 bg-white p-6">
        <h3 className="flex h-10 items-center gap-2 text-sm">
          <ChartSquareBarIcon className="w-6 rotate-90 -scale-x-100" />
          <span className="grow text-dm-charcoal-500">Recent Activity</span>
          <Button
            type="link"
            onClick={() => setShowActivityModal(true)}
            className="cursor-pointer"
          >
            View All
          </Button>
        </h3>
        {currentActivity && currentActivity.length === 0 ? (
          <div className="my-10 text-center text-dm-gray-200">
            <ChartSquareBarIcon className="w-24 rotate-90 -scale-x-100" />
            <br />
            {isFetching ? (
              <p className="animate-ellipsis mx-auto mt-4 inline-block min-w-52 text-left text-dm-charcoal-800">
                Checking for recent activity
              </p>
            ) : (
              <p className="mt-4 text-dm-charcoal-800">
                Your student&apos;s activity will show here
              </p>
            )}
          </div>
        ) : (
          <main className="mt-6 flex flex-col gap-y-[1.375rem]">
            {currentActivity?.map((activity, index) => {
              if (index === 0) {
                showedTestInProgress.current = false;
                cardCount.current = { small: 0, large: 0 };
              }
              // ****  Rules for showing 'In Progress' tests **** //
              // 1. The first test in progress will be shown, whether it's a retake or new attempt
              // 2. Any subsequent tests in progress will only be shown with 'In Progress' label if they are first attempts
              // 3. Any retake test currently in progress will be hidden
              // 4. Any completed test, whether first attempt or retake, will be shown with its score

              if (["UnitTest", "CourseTest"].includes(activity.type)) {
                if (!isCardAllowed("small")) return null;
                if (activity.progress?.submitted === undefined) {
                  if (!showedTestInProgress.current) {
                    showedTestInProgress.current = true;
                  } else if (activity.progress?.pastAttempts !== undefined) {
                    return null;
                  }
                }
                cardCount.current.small++;
              } else {
                if (!isCardAllowed("large")) return null;
                cardCount.current.large++;
              }

              return (
                <RecentActivityCard
                  activity={activity}
                  key={`${activity.title}-${index}`}
                />
              );
            })}
          </main>
        )}
      </div>
      <RecentActivityModal
        activity={currentActivity}
        visible={showActivityModal}
        onClose={() => setShowActivityModal(false)}
        hasNextPage={hasNextPage}
        fetchNextPage={fetchNextPage}
        isFetchingNextPage={isFetchingNextPage}
      />
    </>
  );
};
