import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { useLocation, useNavigate } from "react-router-dom";
import { useUserContext } from "./contexts/UserContext";
import Button from "../student/components/generic/button";
import { REACT_APP_LEARNER_LINK, REACT_APP_MANAGER_LINK } from "../utils";
import { deltamathAPI } from "../manager/utils";
import { useEffect } from "react";
import { useLearnerContext } from "../learner/contexts/LearnerContext";

const ImpersonationHeader = () => {
  const navigate = useNavigate();
  const userContext = useUserContext();
  const learnerContext = useLearnerContext();
  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const userName = `${user.first}`;
  const isStaffImpersonatingLearner = userContext.isTeacherImpersonating;
  //at least for now, there are slightly different systems for impersonating a learner/parent vs. a teacher/admin/student. The main practical implication is that the decamp target would be to a different place in the manager app. The decamp target on the token in state is a good proxy for this being a staff impersonating a teacher.
  const isStaffImpersonatingTeacher =
    userContext.state?.decodedJwt?.data?.decampTarget === "manager";
  const decampEndpoint = isStaffImpersonatingLearner
    ? `${deltamathAPI()}/impersonate/staff/decamp`
    : `${deltamathAPI()}/impersonate/decamp`;

  const location = useLocation();

  const activeEl: any = document.querySelector(".impersonation-header");
  useEffect(() => {
    if (activeEl) {
      window.scrollTo(0, 0);
    }
  }, [activeEl]);

  const decamp = useMutation({
    mutationFn: () => {
      return axios.post(decampEndpoint, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
      });
    },
    onSuccess: (data) => {
      localStorage.setItem("user", JSON.stringify(data.data.user));
      if (isStaffImpersonatingLearner) {
        const decamp = location.state?.from || "/all-learners";
        navigate(`${REACT_APP_MANAGER_LINK}${decamp}`);
      } else if (isStaffImpersonatingTeacher) {
        navigate(`${REACT_APP_MANAGER_LINK}/search`);
      } else {
        navigate(`${REACT_APP_LEARNER_LINK}/parent`);
      }
    },
  });

  if (userContext.state.decodedJwt?.data?.decampTarget === undefined) {
    return <></>;
  }

  const handleDecamp = () => {
    if (!(isStaffImpersonatingLearner || isStaffImpersonatingTeacher)) {
      learnerContext.setDecampingToParent(true);
    }
    decamp.mutate();
  };

  return (
    <div className="impersonation-header flex justify-between bg-dm-brand-blue-100 px-4 py-2">
      <p className="p-2 font-bold">
        {isStaffImpersonatingLearner
          ? `This is a DeltaMath employee impersonating ${userName}`
          : `You're currently viewing DeltaMath as ${userName}. Any actions you take here will affect their account.`}
      </p>
      <Button size="wide" onClick={handleDecamp}>
        {isStaffImpersonatingLearner || isStaffImpersonatingTeacher
          ? "Return to Staff View"
          : "Return to Parent View"}
      </Button>
    </div>
  );
};

export default ImpersonationHeader;
