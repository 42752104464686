import { useMemo, useState } from "react";
import { SkillCodes } from "../../../manager/components/skill-codes/SkillCodes";
import { getIncludedCategories } from "../../utils/searchPrototypeData";
import CategoryDisplay from "../../../manager/components/skill-codes/CategoryDisplay";
import { useSkillSearchResults } from "./useSkillSearchResults";

type SearchResultsProps = {
  query: string;
  data: SkillCodes;
  type: "fuse" | "lunr";
  filteredYears: string[];
};

export const SearchResults: React.FC<SearchResultsProps> = ({
  query,
  data,
  type,
  filteredYears,
}) => {
  const [openedCategories, setOpenedCategories] = useState<string[]>([]);
  const allCategories = useMemo(
    () => getIncludedCategories(data, filteredYears),
    [data, filteredYears]
  );
  const { categoryResults, skillResults } = useSkillSearchResults(
    query,
    data,
    allCategories,
    type
  );

  const openCategory = (name: string) => {
    setOpenedCategories([...openedCategories, name]);
  };

  const closeCategory = (name: string) => {
    setOpenedCategories([...openedCategories.filter((cat) => cat !== name)]);
  };

  const categoriesToDisplay = allCategories.filter((category) => {
    const fromCatResult = categoryResults.some(
      (result) => result.code === category.code
    );
    const fromSkillResult = category.skills.some((skill) =>
      skillResults.some((result) => result.__index === skill)
    );
    return fromCatResult || fromSkillResult;
  });

  return (
    <div className="flex flex-wrap gap-4 pb-10">
      <>
        {data.years_order
          .filter((year) => filteredYears.includes(year))
          .map((year, j) => {
            const categoriesInYear = data.years[year];
            return (
              <>
                {categoriesToDisplay
                  .filter((category) => category.yearcode === year)
                  .map((category, i) => {
                    const allSkills = category.skills.map((skill) => {
                      return {
                        ...data.skills[skill],
                        __index: skill,
                      };
                    });

                    const filteredSkillsForCat = category.skills
                      .filter((x) => skillResults.some((s) => s.__index === x))
                      .map((skill) => {
                        return {
                          ...data.skills[skill],
                          __index: skill,
                        };
                      });

                    return (
                      <CategoryDisplay
                        category={category}
                        color={categoriesInYear.color}
                        year={categoriesInYear.abbrev}
                        skills={allSkills}
                        filteredSkills={filteredSkillsForCat}
                        opened={openedCategories.some(
                          (c) =>
                            c === `${categoriesInYear.abbrev}${category.name}`
                        )}
                        onOpen={() =>
                          openCategory(
                            `${categoriesInYear.abbrev}${category.name}`
                          )
                        }
                        onClose={() =>
                          closeCategory(
                            `${categoriesInYear.abbrev}${category.name}`
                          )
                        }
                        key={`category-${year}-${category.code}-${i}-${j}`}
                      />
                    );
                  })}
              </>
            );
          })}
      </>
    </div>
  );
};
