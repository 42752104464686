import { dateFormatOptions } from ".";

export interface IQuickbooksLine {
  LineNum: number;
  Description: string;
  Amount: string;
  Id: string;
  DetailType: string;
  SalesItemLineDetail: {
    ItemRef: {
      value: string;
      name: string;
    };
    TaxCodeRef: {
      value: string;
    };
    Qty: string;
    UnitPrice: number;
  };
  TxnStatus: string;
}

export interface IQuickbooksData {
  CustomerMemo?: {
    value: string;
  };
  TotalAmt: number;
  Line: Array<IQuickbooksLine>;
  DocNumber: string;
  Id: string;
  CustomerRef?: {
    value: string;
  };
  LinkedTxn: Array<Record<string, string>>;
  BillAddr: Record<string, string>;
  BillEmail?: { Address?: string };
  MetaData: Record<string, string>;
}

export interface IQuickbookProduct {
  product: string;
  description: string;
  quantity: string;
  unitPrice: number;
  totalProductPrice: string;
  status: string;
}

export interface IProcessedEstimate {
  quoteNumber: IQuickbooksData["DocNumber"];
  txnId: IQuickbooksData["Id"];
  linkedTxn: IQuickbooksData["LinkedTxn"];
  billingAddress: string;
  billingCity: string;
  billingState: string;
  billingZip: string;
  email?: string;
  customerMemo?: string;
  lineItems: Array<IQuickbookProduct>;
  dateCreated: string;
  lastUpdateTime?: string;
  totalQuotePrice: IQuickbooksData["TotalAmt"];
}

export const processQuickbooksEstimate = (
  estimateData: IQuickbooksData
): IProcessedEstimate => {
  return {
    quoteNumber: estimateData.DocNumber,
    txnId: estimateData.Id || "",
    linkedTxn: estimateData.LinkedTxn || [],
    billingAddress: estimateData.BillAddr?.Line1,
    billingCity: estimateData.BillAddr?.City,
    billingState: estimateData.BillAddr?.CountrySubDivisionCode,
    billingZip: estimateData.BillAddr?.PostalCode,
    email: estimateData.BillEmail?.Address,
    customerMemo: estimateData.CustomerMemo?.value,
    lineItems: Array.isArray(estimateData.Line)
      ? estimateData.Line?.reduce(
          (acc: Array<IQuickbookProduct>, line: IQuickbooksLine) => {
            if (line.LineNum) {
              return [
                ...acc,
                {
                  product: line?.SalesItemLineDetail?.ItemRef?.name,
                  description: line?.Description,
                  quantity: line?.SalesItemLineDetail?.Qty,
                  unitPrice: line?.SalesItemLineDetail?.UnitPrice,
                  totalProductPrice: line?.Amount,
                  status: line?.TxnStatus,
                },
              ];
            } else return acc;
          },
          []
        )
      : [],
    dateCreated: estimateData.MetaData?.CreateTime
      ? new Date(estimateData.MetaData.CreateTime).toLocaleDateString(
          "en-US",
          dateFormatOptions
        )
      : "",
    lastUpdateTime: estimateData.MetaData?.LastUpdatedTime
      ? new Date(estimateData.MetaData.LastUpdatedTime).toLocaleDateString(
          "en-US",
          dateFormatOptions
        )
      : "",
    totalQuotePrice: estimateData.TotalAmt,
  };
};
