import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Link, useParams } from "react-router-dom";
import DatePicker from "react-datepicker";
import { isFuture } from "date-fns";
import {
  REACT_APP_MANAGER_LINK,
  timeConverter,
  useDMQuery,
  executeQuery,
} from "../../../utils";
import { deltamathAPI, getDatesArray } from "../../utils";
import { DmModal, DmLoadingSpinner } from "../../utils/functions";
import { InvoiceSearch } from "../InvoiceSearch";
import { TIMEZONES_FOR_PICKER, US_STATES } from "../../utils";
import EstimateInfo from "../EstimateInfo";
import CurrentLicense from "./CurrentLicense";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import RosteringActivation, { IIntegrationData } from "./RosteringActivation";
import { LicenseInfo, predictExpiration } from "../../utils/quoteUtils";
import { useDocumentTitle } from "usehooks-ts";

export interface ActivationSummary {
  numStudents: number;
  numTeachers: number;
  adminEmail?: string;
  type?: string;
  tier: string;
  rostering: string;
  lms: string;
}

export interface IActivationPayload {
  hold_until?: number;
  hiddenFromView?: boolean;
  quoteNumber?: string;
  schoolYear?: string;
  id?: string;
  invoiceId?: string;
  invoiceNumber?: string;
  type?: string;
  name?: string;
  status?: string | null;
  poNumber?: string;
  poUploadedTime?: number;
  contactName?: string;
  contactEmail?: string;
  adminFirst?: string;
  adminLast?: string;
  adminEmail?: string;
  notes?: string;
  address?: string;
  city?: string;
  state?: string;
  zip?: string;
  priceInfo: {
    price?: number;
    numberOfStudents: number;
    numberOfTeachers: number;
    discount?: number;
    priceWithDiscount?: number;
    minPrice?: number;
    sendCustomerEmail?: boolean;
    // licenseType?: string;
  };
  amount?: number;
  licenseTier: string;
  lms: string;
  rosteringSystem: string;
  integrationStatus?: string;
  cleverDistrictId?: string;
  classlinkTenantId?: string;
  site_timezone?: string;
  expiration?: number;
  time?: number;
  quoteId?: string;
  customerId?: string;
  rosteringData?: {
    [key: string]: any[];
  };
  snnLicenseType?: string | null;
}

//utiltlity to determine rostering integration b/c clever/classlink id fields could be empty string
export const rosteringIntegration = (
  payload: IActivationPayload
): "clever" | "class-link" | "none" => {
  const { cleverDistrictId, classlinkTenantId } = payload;
  if (typeof cleverDistrictId !== "undefined" && cleverDistrictId !== "") {
    return "clever";
  } else if (
    typeof classlinkTenantId !== "undefined" &&
    classlinkTenantId !== ""
  ) {
    return "class-link";
  } else {
    return "none";
  }
};

export const Invoice = () => {
  const toastContext = useDeltaToastContext();
  const params = useParams();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const nextDates = getDatesArray({ max: 3 });

  const [edit, setEdit] = useState(true);
  const [
    showSuccessMessageWithThisUpdate,
    setShowSuccessMessageWithThisUpdate,
  ] = useState(true);

  const [currentlyIntegral, setCurrentlyIntegral] = useState(false);
  const [snnRenewal, setSnnRenewal] = useState<boolean | undefined>(undefined);
  const [priceChangeWarning, setPriceChangeWarning] = useState("");
  const [activateDisabled, setActivateDisabled] = useState(true);
  const [licenseBasedWarnings, setLicenseBasedWarnings] =
    useState<boolean>(false);
  const [disabledMessages, setDisabledMessages] = useState([] as string[]);
  const [showDistQuoteForSingleSchool, setShowDistQuoteForSingleSchool] =
    useState<boolean>(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [editMode, setEditMode] = useState(false);
  const [showAddPONumber, setShowAddPONumber] = useState(false);
  const [quickbooksData, setQuickbooksData] = useState<{
    CustomerMemo?: {
      value?: string;
    };
    TotalAmt?: number;
    Line?: any[];
    DocNumber?: string;
    Id?: string;
    CustomerRef?: {
      value: string;
    };
    message?: string;
  }>({});
  const [quickbooksInvoiceData, setQuickbooksInvoiceData] = useState<{
    CustomerMemo?: {
      value?: string;
    };
    TotalAmt?: number;
    Line?: any[];
    DocNumber?: string;
    Id?: string;
    CustomerRef?: {
      value: string;
    };
  }>({});

  const defaultResetPayload: IActivationPayload = {
    id: "", //ncesID -- could be renamed in the Invoice model.
    type: "",
    name: "",
    status: null,
    poNumber: "",
    invoiceId: "",
    invoiceNumber: "",
    contactName: "",
    contactEmail: "",
    adminFirst: "",
    adminLast: "",
    adminEmail: "",
    notes: "",
    address: "",
    city: "",
    state: "",
    zip: "",
    priceInfo: {
      price: 0,
      numberOfStudents: 0,
      numberOfTeachers: 0,
      discount: 0,
      priceWithDiscount: 0,
      minPrice: 0,
      sendCustomerEmail: true,
    },
    hold_until: undefined,
    amount: 1,
    licenseTier: "",
    lms: "",
    rosteringSystem: "",
    integrationStatus: "",
    site_timezone: "",
    cleverDistrictId: "",
    classlinkTenantId: "",
    rosteringData: {},
    snnLicenseType: null,
    expiration: 0, //not using an actual date as a default to enforce an active choice prior to activation
  };

  const [selectedPDF, setSelectedPDF] = useState<File | undefined>(undefined);
  //using the below to help trigger re-render when the param changes to avoid issues with stale data
  const [currentQuoteNumber, setCurrentQuoteNumber] = useState(
    params.quoteNumber
  );
  const [showAddress, setShowAddress] = useState(true);

  const [activationSummary, setActivationSummary] = useState<
    ActivationSummary | undefined
  >(undefined);
  const [showActivationSummary, setShowActivationSummary] = useState(false);
  const [payload, setPayload] =
    useState<IActivationPayload>(defaultResetPayload);
  const [holdUntilDate, setHoldUntilDate] = useState<Date | undefined>(
    undefined
  );
  const [rosteringData, setRosteringData] = useState<
    typeof defaultResetPayload["rosteringData"]
  >(defaultResetPayload["rosteringData"]);

  const [activateAnyway, setActivateAnyway] = useState(false);

  //prettier-ignore
  const [currentLicenseInfo, setCurrentLicenseInfo] = useState<LicenseInfo | undefined>(undefined);
  const [activateWarnings, setActivateWarnings] = useState<string[]>([
    "no match",
  ]);
  const [linkToActivatedQuote, setLinkToActivatedQuote] = useState<string>("");

  useDocumentTitle(`${currentQuoteNumber}`);

  const queryClient = useQueryClient();

  //custom state setter for activate warnings since the "zach" message should be handled differently than others
  const setLicenseCompare = (warning: string) => {
    const uniqueWarnings = [
      "exact match",
      "district match",
      "school match",
      "district to school disable",
      "district to school warn",
      "no match",
    ];
    setActivateWarnings((prev) => [
      ...prev.filter((w) => !uniqueWarnings.includes(w)),
      warning,
    ]);
  };

  const { data, refetch, isLoading, error, isError, isSuccess } = useDMQuery({
    path: `/manager_new/invoices/${params.quoteNumber}`,
    cacheKey: ["invoice", params.quoteNumber || ""],
    customerServiceHeader: true,
    queryOptions: {
      enabled: false,
    },
  });

  useEffect(() => {
    //[MB - I added the isSuccess to the check b/c the query is initially disabled meaning in initial render there will never be data leading to an unnecessary toast]
    if (!data && isSuccess) {
      toastContext.addToast({
        status: "Error",
        message: "No DeltaMath Record was found.",
      });
    }
    queryClient.invalidateQueries({
      queryKey: [
        "license",
        `${payload.id || ""}-${payload.name || ""}-${payload.zip || ""}`,
      ],
    });
  }, [isSuccess, data]);

  useEffect(() => {
    if (isError) {
      toastContext.addToast({
        status: "Error",
        message: error.message,
      });
    }
  }, [error, isError]);

  const { data: schoolDiggerData, refetch: schoolDiggerRefetch } = useDMQuery({
    path: `/manager_new/license/schooldigger-lookup?nces_id=${payload.id}`,
    customerServiceHeader: true,
    queryOptions: {
      enabled: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: 1000 * 60 * 5,
    },
  });

  const {
    data: lmsIntegrationData,
    refetch: lmsRefetch,
    isLoading: isLmsDataLoading,
  } = useDMQuery({
    path: `/manager_new/license/lms-data`,
    cacheKey: ["lms-data"],
    customerServiceHeader: true,
    params: {
      integration: rosteringIntegration(payload),
      resource_id:
        rosteringIntegration(payload) === "clever"
          ? payload.cleverDistrictId
          : payload.classlinkTenantId,
      nces_id: payload.id,
    },
    queryOptions: {
      enabled:
        rosteringIntegration(payload) &&
        rosteringIntegration(payload) !== "none",
      refetchOnWindowFocus: false,
      refetchOnMount: false,
    },
  });

  useEffect(() => {
    if (params?.quoteNumber !== currentQuoteNumber) {
      window.location.reload();
    }
    refetch();
  }, [params]);

  useEffect(() => {
    setPriceChangeWarning("");
  }, []);

  // We have new Schooldigger data, update the payload and the form
  useEffect(() => {
    if (schoolDiggerData) {
      if (schoolDiggerData?.code >= 400) {
        toastContext.addToast({
          status: "Error",
          message:
            "School not found in School Digger" + schoolDiggerData.message,
        });
      } else {
        setPayload({
          ...payload,
          name:
            schoolDiggerData.type === "school"
              ? schoolDiggerData.schooldigger.schoolName
              : schoolDiggerData.schooldigger.districtName,
          address: schoolDiggerData.schooldigger.address.street,
          city: schoolDiggerData.schooldigger.address.city,
          state: schoolDiggerData.schooldigger.address.state,
          zip: schoolDiggerData.schooldigger.address.zip,
          site_timezone: schoolDiggerData.inferred_timezone,
          id: payload.id,
        });
        if (!schoolDiggerData.inferred_timezone) {
          toastContext.addToast({
            status: "Error",
            message:
              "Timezone was not able to be inferred, please make sure to select the correct timezone.",
          });
        }
        setShowAddress(true); // for now, continue to show the form, we can kill it later
      }
    }
  }, [schoolDiggerData]);

  const {
    data: updatedInvoiceData,
    mutate: updateInvoice,
    isPending: updateLoading,
    error: updateError,
  } = useMutation({
    mutationKey: ["invoice", params.quoteNumber || ""],
    mutationFn: async () => {
      const token = localStorage.getItem("customer_service_token");
      payload.hiddenFromView = false;
      return await axios.put(
        deltamathAPI() + `/manager_new/invoices/${params.quoteNumber}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    onSuccess: () => {
      if (showSuccessMessageWithThisUpdate) {
        toastContext.addToast({
          status: "Success",
          message: "Successfully Saved Updates.",
        });
      }
      refetch();
      setShowSuccessMessageWithThisUpdate(true);
    },
  });

  useEffect(() => {
    if (updateError) {
      toastContext.addToast({
        status: "Error",
        message: updateError?.message,
      });
    }
  }, [updateError]);

  const getIntegrationsString = () => {
    const res: string[] = [];
    if (payload.lms) {
      res.push(payload.lms.toLowerCase());
    }
    if (payload.rosteringSystem && payload.rosteringSystem === "Clever") {
      res.push("clever");
    } else if (
      payload.rosteringSystem &&
      payload.rosteringSystem === "ClassLink/OneRoster"
    ) {
      res.push("classlink");
    }
    return res.length === 0 ? undefined : res.join(", ");
  };

  //helper function to determine whether a type represents a district license or not
  const isDistrictType = (type: string | undefined) => {
    if (typeof type === "undefined") {
      return false;
    }
    return (
      type.toLowerCase().match(/school/) === null &&
      type.toLowerCase().match(/individual/) === null
    );
  };

  const activateLicenseFunc = async () => {
    return await executeQuery({
      path: `/manager_new/license/manage${editMode ? "?editMode=true" : ""}`,
      method: "post",
      requestBody: {
        type:
          payload?.type === "individual"
            ? "individual"
            : payload?.type === "school_custom" ||
              payload?.type === "school" ||
              payload?.type === "School License"
            ? "school"
            : "district",
        nces_id: payload?.id,
        license: {
          license_type: payload?.type,
          num_students: payload?.priceInfo?.numberOfStudents,
          num_teachers: payload?.priceInfo?.numberOfTeachers,
          expiration: payload?.expiration,
          revenue: payload?.priceInfo?.price,
          note: payload?.notes,
          has_integral: payload.licenseTier === "Integral",
          integrations: getIntegrationsString(),
        },
        quote_number: data?.quoteNumber,
        site_name: payload?.name,
        site_timezone: payload?.site_timezone,
        admin_user: {
          first: payload?.adminFirst,
          last: payload?.adminLast,
          email: payload?.adminEmail,
        },
        site_address: {
          street: payload?.address,
          city: payload?.city,
          state: payload?.state,
          zip: payload?.zip,
          country: data?.country || "US",
        },
        integrations: {
          lms: payload?.lms,
          rostering:
            payload?.rosteringSystem === "Clever"
              ? "clever"
              : payload?.rosteringSystem === "ClassLink/OneRoster"
              ? "classLink"
              : undefined,
          tenantID:
            payload?.rosteringSystem === "Clever"
              ? payload?.cleverDistrictId
              : payload?.rosteringSystem === "ClassLink/OneRoster"
              ? payload?.classlinkTenantId
              : undefined,
          rosteringData: payload?.rosteringData,
        },
        hiddenFromView: false,
      },
      customerServiceHeader: true,
    });
  };

  const { mutate: activateLicense, isPending: activateLoading } = useMutation({
    mutationFn: activateLicenseFunc,
    mutationKey: [
      "license",
      `${payload.id || ""}-${payload.name || ""}-${payload.zip || ""}`,
    ],
    retry: false,
    onSuccess: (activateData: {
      success: boolean;
      message: string;
      code: number;
      invoice: number;
    }) => {
      queryClient.invalidateQueries({
        queryKey: [
          "license",
          `${payload.id || ""}-${payload.name || ""}-${payload.zip || ""}`,
        ],
      });
      if (activateData.success && activateData.message) {
        refetch();
        toastContext.addToast({
          status: "Success",
          message: activateData.message,
        });
      } else if (activateData.invoice) {
        refetch();
        toastContext.addToast({
          status: "Success",
          message: `License Activated Succesfully! Created Invoice ${activateData.invoice}`,
        });
      } else if (activateData.code === 400) {
        toastContext.addToast({
          status: "Error",
          message: activateData.message,
        });
      } else if (activateData?.success === false) {
        toastContext.addToast({
          status: "Error",
          message:
            activateData?.message ||
            "There was an error activating your license, please check all fields and try again.",
        });
        setEditMode(false);
      }
    },
    onError: (err: any) => {
      console.error(err);
      if (err.message) {
        toastContext.addToast({
          status: "Error",
          message: `${err.message}: ${JSON.stringify(err)}`,
        });
      } else {
        toastContext.addToast({
          status: "Error",
          message: `There was an error activating your license, please check all fields and try again.: ${JSON.stringify(
            err
          )}`,
        });
      }
    },
  });

  // If we have price data from quickbooks that does not match the invoice data (should not happen once we have run a data cleanup script), show warning
  useEffect(() => {
    if (Object.keys(quickbooksData).length !== 0) {
      if (
        typeof data?.amount !== "undefined" &&
        quickbooksData?.TotalAmt !== data?.amount
      ) {
        // setPayload({
        //   ...payload,
        //   priceInfo: {
        //     ...payload.priceInfo,
        //     price: quickbooksData.TotalAmt,
        //   },
        // });
        setPriceChangeWarning(
          `Quickbooks Estimate Price ($${quickbooksData.TotalAmt}) was different than the initial price ($${data?.amount}), please confirm the price and student numbers.`
        );
      }
    }
    //if there is quickbook data but no data from the invoices collection, populate form with quickbook data
    if (
      Object.keys(quickbooksData).length !== 0 &&
      !data &&
      !quickbooksData.message
    ) {
      const newPayload = payload;

      newPayload.time = Math.floor(Date.now() / 1000);

      if (quickbooksData?.Id) {
        newPayload.quoteId = quickbooksData.Id;
      }

      if (quickbooksData?.CustomerRef?.value) {
        newPayload.customerId = quickbooksData.CustomerRef.value;
      }

      if (quickbooksData?.CustomerMemo?.value) {
        const memo = quickbooksData.CustomerMemo.value.toLowerCase();

        // set LMS
        if (memo.includes("canvas")) {
          newPayload.lms = "Canvas";
        } else if (memo.includes("schoology")) {
          newPayload.lms = "Schoology";
        }

        // Set Rostering System
        if (memo.includes("clever")) {
          newPayload.rosteringSystem = "Clever";
        } else if (memo.includes("classlink")) {
          newPayload.rosteringSystem = "ClassLink/OneRoster";
        }

        const studentsMatch = memo
          .replace(/\n|\r/g, "")
          .match(/^.*(approximately)(.*)(students)/);
        if (studentsMatch && studentsMatch[2]) {
          const numberOfStudents = studentsMatch[2].replace(/\D/g, "");
          newPayload.priceInfo.numberOfStudents = parseInt(numberOfStudents);
        }
      }

      // Set the Quote Number
      if (quickbooksData?.DocNumber) {
        newPayload.quoteNumber = quickbooksData.DocNumber;
      }

      newPayload.id = "";

      if (quickbooksData?.TotalAmt) {
        //MB - we are now using amount as the total correct number and it should match the quickbooks total so we should set it to that
        newPayload.amount = quickbooksData.TotalAmt;
        newPayload.priceInfo.price = quickbooksData.TotalAmt;
      }

      if (quickbooksData?.Line) {
        // get the License Type and Tier from the Product
        const firstLineItem = quickbooksData.Line.filter(
          (l) => l.DetailType === "SalesItemLineDetail"
        );
        if (firstLineItem[0]) {
          const productDescription =
            firstLineItem[0]?.SalesItemLineDetail?.ItemRef?.name.toLowerCase();

          // Set License Tier
          if (productDescription.includes("integral")) {
            newPayload.licenseTier = "Integral";
          } else if (productDescription.includes("plus")) {
            newPayload.licenseTier = "Plus";
          }

          // Set License Type
          if (productDescription.includes("district license 6-12")) {
            newPayload.type = "6-12";
          } else if (productDescription.includes("district license 9-12")) {
            newPayload.type = "9-12";
          } else if (productDescription.includes("district license 6-8")) {
            newPayload.type = "6-8";
          } else if (productDescription.includes("district license 7-12")) {
            newPayload.type = "7-12";
          } else if (productDescription.includes("district license 7-8")) {
            newPayload.type = "7-8";
            // } else if (productDescription.includes("teacher")) {
            //   newPayload.type = "Individual License";
          } else if (productDescription.includes("school license")) {
            newPayload.type = "school";
          } else if (productDescription.includes("teacher")) {
            newPayload.type = "individual";
          } else {
            newPayload.type = "";
          }
        }
      }
      setPayload({ ...newPayload });
    }
  }, [quickbooksData]);

  // Once data is loaded
  useEffect(() => {
    setEdit(true);
    setPayload(defaultResetPayload);
    setPriceChangeWarning("");

    if (data) {
      if (data?.status === "Activated") {
        setEdit(false);
      }

      if (
        data?.cleverDistrictId === "54d263946cc968010000007a" &&
        !activateWarnings.includes("Zach")
      ) {
        setActivateWarnings((prev) => [...prev, "Zach"]);
      }

      let licenseType = data.type;
      if (data?.type) {
        if (data?.type.toLowerCase().includes("grades 6-12")) {
          licenseType = "6-12";
        } else if (data?.type.toLowerCase().includes("grades 9-12")) {
          licenseType = "9-12";
        } else if (data?.type.toLowerCase().includes("grades 6-8")) {
          licenseType = "6-8";
        } else if (data?.type.toLowerCase().includes("grades 7-12")) {
          licenseType = "7-12";
        } else if (data?.type.toLowerCase().includes("grades 7-8")) {
          licenseType = "7-8";
        } else if (data?.type.toLowerCase().includes("school license")) {
          licenseType = "school";
        }
      }
      // Convert epoch date into date object in state for the hold until date selector
      if (Number.isInteger(data.hold_until)) {
        const theHoldUntilDate = new Date(data.hold_until * 1000);
        setHoldUntilDate(theHoldUntilDate);
      }
      setPayload({
        id: data?.id,
        type: licenseType || "school",
        invoiceId: quickbooksInvoiceData?.Id || data.invoiceId || "",
        invoiceNumber:
          quickbooksInvoiceData?.DocNumber || data.invoiceNumber || "",
        name: data?.name || data?.school,
        contactName: data?.contactName || "",
        contactEmail: data?.contactEmail || "",
        adminFirst: data?.adminFirst || "",
        adminLast: data?.adminLast || "",
        adminEmail: data?.adminEmail || "",
        address: data?.address || "",
        city: data?.city || "",
        state: data?.state || "",
        zip: data?.zip || "",
        status: data?.status || null,
        poNumber: data?.poNumber || "",
        notes: data?.notes || "",
        hold_until: data?.hold_until || "",
        priceInfo: {
          price: data?.priceInfo?.price || 0,
          numberOfStudents: data?.priceInfo?.numberOfStudents || 0,
          numberOfTeachers: data?.priceInfo?.numberOfTeachers || 0,
          discount: data?.priceInfo?.discount || 0,
          priceWithDiscount: data?.priceInfo?.priceWithDiscount || 0,
          minPrice: data?.priceInfo?.minPrice || 0,
          sendCustomerEmail: data?.priceInfo?.sendCustomerEmail || true,
        },
        amount: data?.amount || 1,
        licenseTier: data?.licenseTier || "",
        lms: data?.lms || "",
        rosteringSystem: data?.rosteringSystem || "",
        integrationStatus: data?.integrationStatus || "",
        cleverDistrictId: data?.cleverDistrictId || "",
        classlinkTenantId: data?.classlinkTenantId || "",
        site_timezone: data?.site_timezone || "",
        expiration:
          typeof data?.expiration === "number"
            ? data.expiration
            : predictExpiration(data?.schoolYear, nextDates),
        rosteringData: data?.rosteringData,
        snnLicenseType: data?.snnLicenseType || null,
      });
      if (
        data?.status === "PO Received (Needs Updating)" ||
        data?.status === "PO Received (Good)"
      ) {
        setShowAddPONumber(true);
      }
      if (data?.rosteringData) {
        setRosteringData(data.rosteringData);
      }
      if (data?.snnLicenseType && data.snnLicenseType === "renewal") {
        setSnnRenewal(true);
      }
    }
  }, [data, quickbooksInvoiceData]);

  useEffect(() => {
    setActivationSummary({
      numStudents: payload.priceInfo.numberOfStudents,
      numTeachers: payload.priceInfo.numberOfTeachers,
      type: payload.type,
      adminEmail: payload.adminEmail,
      tier: payload.licenseTier,
      rostering: payload.rosteringSystem,
      lms: payload.lms,
    });
    // enabled by default
    let disableActivationButton = false;
    const disableMessages = [];

    // Check for Admin Name, Email
    if (payload.type === "individual") {
      if (
        payload?.adminEmail === "" ||
        typeof payload?.adminEmail === "undefined"
      ) {
        disableMessages.push(
          "For Individual Licenses, you must specify an admin email."
        );
        disableActivationButton = true;
      }
    } else {
      if (
        payload?.adminFirst === "" ||
        typeof payload?.adminFirst === "undefined" ||
        payload?.adminLast === "" ||
        typeof payload?.adminLast === "undefined" ||
        payload?.adminEmail === "" ||
        typeof payload?.adminEmail === "undefined"
      ) {
        disableMessages.push(
          "For School Licenses, you must specify an admin name, email, and address."
        );
        disableActivationButton = true;
      }
    }

    // Check that there's an expiration
    if (payload.expiration === 0) {
      disableMessages.push("You must specify an expiration date");
      disableActivationButton = true;
    }

    if (
      payload?.status != "CC/Check Payment Received" &&
      payload?.status !== "PO Received (Good)" &&
      payload?.status !== "Activated"
    ) {
      disableMessages.push(
        "You can only activate a license that has CC/Check Payment Received or PO Received (Good)"
      );
      disableActivationButton = true;
    }

    // It's it's PO Received check that it was uploaded
    if (
      payload?.status === "PO Received (Good)" &&
      (typeof data?.poUploadedTime === "undefined" ||
        data?.poUploadedTime === null)
    ) {
      disableMessages.push(
        "You marked that a PO was received, please upload it."
      );
      disableActivationButton = true;
    }

    if (!payload?.name) {
      disableMessages.push("Please enter a School/District name.");
      disableActivationButton = true;
    }

    if (payload?.type === "school_custom") {
      if (typeof snnRenewal === "undefined") {
        disableMessages.push(
          "For a School (No NCES ID), you must specify a SNN License Type"
        );
        disableActivationButton = true;
      }
      if (snnRenewal && !payload?.id && payload?.id === "") {
        disableMessages.push(
          "For a Renewal of a School (No NCES ID), please enter the custom NCES of the previous license."
        );
        disableActivationButton = true;
      }
      if (payload?.site_timezone === "") {
        disableMessages.push(
          "For a School (No NCES ID), you must set a timezone."
        );
        disableActivationButton = true;
      }
    }

    if (["ClassLink/OneRoster", "Clever"].includes(payload?.rosteringSystem)) {
      disableActivationButton =
        payload?.integrationStatus !== "verified" || !payload?.rosteringData
          ? true
          : disableActivationButton;
    }

    if (!payload?.licenseTier || payload?.licenseTier === "") {
      disableMessages.push("You Must Select a license tier.");
      disableActivationButton = true;
    }

    if (payload.hold_until && Number.isInteger(payload.hold_until)) {
      const theHoldUntilDate = new Date(payload.hold_until * 1000);
      if (isFuture(theHoldUntilDate)) {
        disableActivationButton = true;
        disableMessages.push(
          "You cannot enable this activation until after the Hold Until date has passed."
        );
      }
    }

    if (
      payload.type === "individual" &&
      (!payload.priceInfo?.numberOfTeachers ||
        isNaN(payload.priceInfo?.numberOfTeachers))
    ) {
      disableActivationButton = true;
      disableMessages.push(
        "The number of license codes must be a positive integer."
      );
    }

    setDisabledMessages(disableMessages);
    setActivateDisabled(disableActivationButton);
  }, [data, payload, snnRenewal]);

  /*Under certain circumstances where there is an active license matching the current quote, either there should be a warning message or the activation button should be disabled. The current logic is as follows
  
  1. If an active license matches the quote "exactly" (same type, id, licenseTier, expiration, integrations), the activation button should be disabled

  2. If not an exact match, but the quote is for a DISTRICT license and the DISTRICT already has a valid license which expires on the same date or after the quote expiration, a warning message will be displayed on the activation summary modal

  3. If the quote is for a SCHOOL license but the SCHOOL is covered under an existing DISTRICT license, then:
    a) The activation button will be disabled if the quote matches the existing license in terms of expiration, tier, and integrations
    b) If the license differs in tier or integrations, but the expiration of the active license is on or after the quote date, show a warning in the activation summary modal
  
  The useEffect below checks for these various conditions

  */
  useEffect(() => {
    setLicenseCompare("no match");
    if (typeof currentLicenseInfo !== "undefined" && payload) {
      const matches: string[] = [];
      for (const key in currentLicenseInfo) {
        if (key === "integrations") {
          if (
            currentLicenseInfo.integrations ===
              (getIntegrationsString() || "") ||
            (currentLicenseInfo.integrations === "none" &&
              !getIntegrationsString()) ||
            getIntegrationsString() === "none" ||
            (!currentLicenseInfo.integrations && !getIntegrationsString())
          ) {
            matches.push(key);
          }
        } else if (key === "expiration") {
          if (
            payload.expiration &&
            currentLicenseInfo.expiration >= payload.expiration
          ) {
            matches.push(key);
          }
        } else if (
          currentLicenseInfo[key as keyof typeof currentLicenseInfo] ===
          payload[key as keyof typeof payload]
        ) {
          matches.push(key);
        }
      }
      //check for exact match - will trigger activate disable
      if (matches.length === 5) {
        setLicenseCompare("exact match");
        //handle a district quote where there is alreadt a district license active. This will trigger a warning message
      } else if (
        matches.includes("expiration") &&
        isDistrictType(payload.type) &&
        isDistrictType(currentLicenseInfo.type)
      ) {
        setLicenseCompare("district match");

        //handle a school quote where there is already a school license active (no double license, not exact match)
      } else if (
        matches.includes("expiration") &&
        payload.type?.match(/school/) !== null &&
        currentLicenseInfo.type?.match(/school/) !== null &&
        !currentLicenseInfo.schoolAndDistLicense
      ) {
        setLicenseCompare("school match");
        //handle a school quote where there is only a district license active
      } else if (
        isDistrictType(currentLicenseInfo.type) &&
        payload.type?.match(/school/) !== null &&
        matches.includes("expiration")
      ) {
        //disable activation if the school quote is on the same tier and integrations as the existing district license
        if (["licenseTier", "integrations"].every((x) => matches.includes(x))) {
          setLicenseCompare("district to school disable");
        } else {
          //otherwise show a warning message
          setLicenseCompare("district to school warn");
        }
        //handle a school quote where there is both an active district and school license (but the school license did not exactly match the quote). Apply simiar logic to the above, but the district portion of the license did not go through the match check
      } else if (
        payload.type?.match(/school/) !== null &&
        currentLicenseInfo.schoolAndDistLicense
      ) {
        if (
          currentLicenseInfo.schoolAndDistLicense.expiration >=
          (payload?.expiration || 0)
        ) {
          if (
            currentLicenseInfo.schoolAndDistLicense.licenseTier ===
              payload.licenseTier &&
            currentLicenseInfo.schoolAndDistLicense.integrations ===
              getIntegrationsString()
          ) {
            setLicenseCompare("district to school disable");
          } else {
            setLicenseCompare("district to school warn");
          }
        }
      } else {
        setLicenseCompare("no match");
      }
    }
  }, [
    data,
    currentLicenseInfo,
    payload.type,
    payload.id,
    payload.licenseTier,
    payload.expiration,
    payload.lms,
    payload.rosteringSystem,
  ]);

  const uploadPDF = () => {
    if (selectedPDF && quickbooksData?.DocNumber && quickbooksData?.Id) {
      const formData = new FormData();
      formData.append("file", selectedPDF);
      formData.append(
        "customer_service_token",
        localStorage.getItem("customer_service_token") || ""
      );
      formData.append("quoteNumber", quickbooksData.DocNumber);
      formData.append("quoteId", quickbooksData.Id);
      axios({
        method: "post",
        url:
          deltamathAPI() + `/manager_new/quickbooks/add_quickbooks_attachment`,
        data: formData,
        headers: { "Content-Type": "multipart/form-data" },
      })
        .then(function (response) {
          if (response.status === 200) {
            toastContext.addToast({
              status: "Success",
              message: "Upload complete! Thanks!",
            });
            if (fileInputRef && fileInputRef.current) {
              fileInputRef.current.value = "";
            }
            refetch();
          }
        })
        .catch(function (response) {
          console.error(response);
        });
    }
  };

  return (
    <>
      <div className="inline-flex w-full justify-between">
        <InvoiceSearch quoteNumber={params.quoteNumber} />
      </div>

      <DmModal
        showModal={showActivationSummary && !updateLoading}
        setShowModal={setShowActivationSummary}
        cancelMessage={"Cancel"}
        confirmMessage={"Activate License"}
        modalTitle={"License Summary"}
        modalBodyText={
          <ul>
            {activationSummary?.type === "individual" && (
              <>
                <li>
                  <strong>Type:</strong> Teachers License Codes
                </li>
                {activationSummary?.adminEmail && (
                  <li>
                    <strong>Email Sent To:</strong>{" "}
                    {activationSummary?.adminEmail}
                  </li>
                )}
              </>
            )}
            {activationSummary?.numStudents &&
            activationSummary?.numStudents > 0 ? (
              <li>
                <b># Students:</b> {activationSummary?.numStudents}
              </li>
            ) : null}
            {activationSummary?.numTeachers &&
            activationSummary?.numTeachers > 0 ? (
              <li>
                <b>
                  {activationSummary?.type === "individual"
                    ? "# Codes:"
                    : "# Teachers:"}
                </b>{" "}
                {activationSummary?.numTeachers}
              </li>
            ) : null}
            <li>
              <b>License Tier:</b> {activationSummary?.tier}
            </li>
            {activationSummary?.tier === "Integral" && (
              <>
                <li>
                  <b>LMS:</b> {activationSummary?.lms}{" "}
                </li>
                <li>
                  <b>Rostering System:</b> {activationSummary?.rostering}
                </li>
              </>
            )}
            {(activateWarnings.includes("district match") ||
              activateWarnings.includes("district to school warn") ||
              activateWarnings.includes("school match")) && (
              <li className="mt-2 max-w-[700px] text-red-500">
                {currentLicenseInfo?.schoolAndDistLicense
                  ? "This site is currently covered by existing school and district licenses."
                  : "A current license for this site already exists."}
                {typeof linkToActivatedQuote !== undefined &&
                  linkToActivatedQuote !== "" && (
                    <Link
                      className="text-indigo-500 hover:underline"
                      to={`${REACT_APP_MANAGER_LINK}/quote-lookup/${linkToActivatedQuote}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      Click here to view the activated quote.
                    </Link>
                  )}{" "}
                {activateWarnings.includes("district to school warn")
                  ? `If you activate this license, the site may be paying for license features it already has access to. Only proceed if you are 100% SURE - otherwise stop and ask.`
                  : `If you activate this license it will overwrite, or replace,
                their existing license. Only proceed if you are 100% SURE -
                otherwise stop and ask.`}
              </li>
            )}
          </ul>
        }
        modalAction={activateLicense}
      />

      {(showSuccessMessageWithThisUpdate && isLoading) ||
        (showSuccessMessageWithThisUpdate && updateLoading && (
          <DmLoadingSpinner message="Loading..." />
        ))}
      <>
        <>
          <div className="mt-6">
            <div className="md:grid md:grid-cols-4 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Quickbooks Estimate
                  </h3>
                  <p className="mt-1 text-sm text-gray-600">
                    {quickbooksData?.Id && (
                      <a
                        className="text-indigo-500 hover:underline"
                        href={`https://app.qbo.intuit.com/app/estimate?txnId=${quickbooksData.Id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-external-link mr-2 inline-block"
                        >
                          <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6M15 3h6v6M10 14 21 3" />
                        </svg>
                        Open Quickbooks Estimate
                      </a>
                    )}
                  </p>
                  <p className="mt-2 text-sm text-gray-600">
                    {quickbooksInvoiceData?.Id && (
                      <a
                        className="text-indigo-500 hover:underline"
                        href={`https://app.qbo.intuit.com/app/invoice?txnId=${quickbooksInvoiceData.Id}`}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <svg
                          width="24"
                          height="24"
                          xmlns="http://www.w3.org/2000/svg"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          className="feather feather-external-link mr-2 inline-block"
                        >
                          <path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6M15 3h6v6M10 14 21 3" />
                        </svg>
                        Open Quickbooks Invoice
                      </a>
                    )}
                  </p>
                </div>
              </div>
              <div className="mt-5 rounded border-2 shadow-xl md:col-span-3 md:mt-0">
                <div className="overflow-hidden">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <EstimateInfo
                      setQuickbooksData={setQuickbooksData}
                      setQuickbooksInvoiceData={setQuickbooksInvoiceData}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-6">
            <div className="md:grid md:grid-cols-4 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Quote Status
                  </h3>
                  <p className="mt-1 text-sm text-gray-600"></p>
                </div>
              </div>
              <div className="mt-5 rounded border-2 shadow-xl md:col-span-3 md:mt-0">
                <div className="overflow-hidden">
                  <div className="bg-white px-4 py-5 sm:p-6">
                    <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                      <div className="">
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            Status
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {edit ? (
                              <select
                                className="w-full border-gray-300 md:rounded-md"
                                value={payload.status || ""}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    status: e.target.value || null,
                                  });
                                  setSaveDisabled(false);
                                  if (
                                    e.target.value === "PO Received (Good)" ||
                                    e.target.value ===
                                      "PO Received (Needs Updating)"
                                  ) {
                                    setShowAddPONumber(true);
                                  } else setShowAddPONumber(false);
                                }}
                              >
                                <option value="">--</option>
                                <option value="PO Received (Needs Updating)">
                                  PO Received -- needs updating
                                </option>
                                <option value="PO Received (Good)">
                                  {"PO Received (Good)"}
                                </option>
                                <option value="CC/Check Payment Requested">
                                  CC/Check Payment Requested
                                </option>
                                <option value="CC/Check Payment Received">
                                  CC/Check Payment Received
                                </option>
                                <option value="Activated">Activated</option>
                              </select>
                            ) : (
                              <div>{data?.status || "--"}</div>
                            )}
                          </dd>
                        </div>
                      </div>

                      <div className="ml-2">
                        {showAddPONumber && (
                          <>
                            <dt className="text-sm font-medium text-gray-500">
                              PO Number
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              <input
                                className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                type="text"
                                value={payload.poNumber}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    poNumber: e.target.value.trim(),
                                  });
                                  setSaveDisabled(false);
                                }}
                              ></input>
                            </dd>
                          </>
                        )}
                      </div>

                      <div className="">
                        {payload?.status !== "Activated" &&
                        quickbooksData?.Id &&
                        !data?.poUploadedTime ? (
                          <>
                            <dt className="text-sm font-medium text-gray-500">
                              Attach P.O. PDF
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              <input
                                className="form-control
                        m-0
                        block
                        w-full rounded border
                        border-solid
                        border-gray-300
                        bg-white bg-clip-padding
                        px-3 py-1.5 text-base
                        font-normal
                        text-gray-700
                        transition
                        ease-in-out focus:border-blue-600 focus:bg-white focus:text-gray-700 focus:outline-none"
                                id="file-upload"
                                name="file-upload"
                                type="file"
                                ref={fileInputRef}
                                accept="application/pdf"
                                onChange={(event) => {
                                  event.target.files &&
                                    setSelectedPDF(event.target.files[0]);
                                }}
                              />
                              <button
                                className="mt-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                type="button"
                                onClick={() => {
                                  uploadPDF();
                                  updateInvoice();
                                }}
                              >
                                Upload File
                              </button>
                            </dd>
                          </>
                        ) : (
                          <>
                            <dt className="text-sm font-medium text-gray-500">
                              PO Uploaded
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {timeConverter(data?.poUploadedTime)}
                            </dd>
                          </>
                        )}
                      </div>

                      <div>
                        <dt className="text-sm font-medium text-gray-500">
                          Internal Notes
                        </dt>
                        <dd className="mt-1 text-sm text-gray-900">
                          <textarea
                            className="w-full"
                            rows={3}
                            value={payload.notes}
                            onChange={(e) => {
                              setPayload({
                                ...payload,
                                notes: e.target.value,
                              });
                              setSaveDisabled(false);
                            }}
                          ></textarea>
                        </dd>
                      </div>

                      <div>
                        <button
                          type="submit"
                          className={
                            "justify-self-end rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-indigo-500 disabled:opacity-50"
                          }
                          disabled={
                            saveDisabled ||
                            updateLoading ||
                            isLoading ||
                            payload?.status === ""
                          }
                          onClick={() => {
                            updateInvoice();
                            setSaveDisabled(true);
                          }}
                        >
                          Save Updates
                        </button>
                        {payload?.status === "Activated" && !edit && (
                          <button
                            type="submit"
                            className={
                              "ml-16 mt-8 inline-flex items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            }
                            onClick={() => {
                              setEditMode(true);
                              setEdit(true);
                            }}
                          >
                            Edit
                          </button>
                        )}
                      </div>
                    </dl>

                    {payload?.status === "" && (
                      <p className="text-md my-6 font-bold text-orange-500">
                        Save Notice: You must set a status to save a quote.
                      </p>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          {(payload.id || (payload.name && payload.zip)) && (
            <CurrentLicense
              nces_id={payload.id}
              name={payload.name}
              zip={payload.zip}
              email={payload?.adminEmail || ""}
              setCurrentlyIntegral={setCurrentlyIntegral}
              setCurrentLicenseInfo={setCurrentLicenseInfo}
              setLinkToActivatedQuote={setLinkToActivatedQuote}
              activateWarnings={activateWarnings}
              quotedLicenseType={payload.type}
              setShowDistQuoteForSingleSchool={setShowDistQuoteForSingleSchool}
              setLicenseBasedWarnings={setLicenseBasedWarnings}
            />
          )}
          <div className="mt-6">
            <div className="md:grid md:grid-cols-4 md:gap-6">
              <div className="md:col-span-1">
                <div className="px-4 sm:px-0">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Create New License
                  </h3>
                  <p className="mt-1 text-sm text-gray-600"></p>
                </div>
              </div>
              <div className="mt-5 rounded border-2 shadow-xl md:col-span-3 md:mt-0">
                <div className="bg-white px-4 py-5 sm:p-6 sm:pb-24">
                  <div className="">
                    <div className="mb-4 overflow-hidden bg-white sm:rounded-lg">
                      {data && (
                        <div className="grid grid-cols-2">
                          {currentlyIntegral &&
                            payload.licenseTier !== "Integral" && (
                              <span className="text-md col-span-2 pb-4 font-bold text-red-500">
                                NOTICE: License is currently Integral, but
                                downgrading to Plus. Please confirm with
                                customer.
                              </span>
                            )}
                          {currentLicenseInfo?.type === "6-12" &&
                            payload.type !== "6-12" && (
                              <span className="text-md col-span-2 pb-4 font-bold text-red-500">
                                NOTICE: License is currently 6-12, but
                                downgrading scope. Please confirm with customer.
                              </span>
                            )}
                          <div className="col-span-1">
                            {
                              <div className="item mb-6 mr-6 inline-flex">
                                <dl className="grid grid-cols-1">
                                  <dt className="text-sm font-medium text-gray-500">
                                    Contact Name
                                  </dt>
                                  <dd className="mt-1 inline-flex text-sm text-gray-900">
                                    {edit ? (
                                      <input
                                        className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                        type="text"
                                        value={payload.contactName}
                                        onChange={(e) => {
                                          setPayload({
                                            ...payload,
                                            contactName: e.target.value,
                                          });
                                          setSaveDisabled(false);
                                        }}
                                      ></input>
                                    ) : (
                                      <div>{data?.contactName || "--"}</div>
                                    )}
                                  </dd>
                                </dl>
                              </div>
                            }
                            {
                              <div className="item inline-flex justify-end sm:col-span-1">
                                <dl className="mb-6 grid grid-cols-1">
                                  <dt className="text-sm font-medium text-gray-500">
                                    Contact Email
                                  </dt>
                                  <dd className="mt-1 inline-flex text-sm text-gray-900">
                                    {edit ? (
                                      <input
                                        className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                        type="email"
                                        value={payload.contactEmail}
                                        onChange={(e) => {
                                          setPayload({
                                            ...payload,
                                            contactEmail: e.target.value,
                                          });
                                          setSaveDisabled(false);
                                        }}
                                      ></input>
                                    ) : (
                                      <div>{data?.contactEmail || "--"}</div>
                                    )}
                                  </dd>
                                </dl>
                              </div>
                            }
                          </div>
                        </div>
                      )}
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
                        <div className="inline-flex sm:col-span-1">
                          <div className="">
                            <dt className="text-sm font-medium text-gray-500">
                              Admin First Name
                            </dt>
                            <dd className="mt-1 inline-flex text-sm text-gray-900">
                              {edit ? (
                                <input
                                  className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                  type="text"
                                  value={payload.adminFirst}
                                  onChange={(e) => {
                                    setPayload({
                                      ...payload,
                                      adminFirst: e.target.value,
                                    });
                                    setSaveDisabled(false);
                                  }}
                                ></input>
                              ) : (
                                <div>{data?.adminFirst || "--"}</div>
                              )}
                            </dd>
                          </div>
                          <div className="ml-2">
                            <dt className="text-sm font-medium text-gray-500">
                              Admin Last Name
                            </dt>
                            <dd className="mt-1 inline-flex text-sm text-gray-900">
                              {edit ? (
                                <input
                                  className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                  type="text"
                                  value={payload.adminLast}
                                  onChange={(e) => {
                                    setPayload({
                                      ...payload,
                                      adminLast: e.target.value,
                                    });
                                    setSaveDisabled(false);
                                  }}
                                ></input>
                              ) : (
                                <div>{data?.adminLast || "--"}</div>
                              )}
                            </dd>
                          </div>
                        </div>
                        <div className="inline-flex sm:col-span-1">
                          <div>
                            <dt className="text-sm font-medium text-gray-500">
                              Admin Email
                            </dt>
                            <dd className="mt-1 inline-flex text-sm text-gray-900">
                              {edit ? (
                                <input
                                  className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                  type="email"
                                  value={payload.adminEmail}
                                  onChange={(e) => {
                                    setPayload({
                                      ...payload,
                                      adminEmail: e.target.value,
                                    });
                                    setSaveDisabled(false);
                                  }}
                                ></input>
                              ) : (
                                <div>{data?.adminEmail || "--"}</div>
                              )}
                            </dd>
                          </div>
                        </div>
                      </dl>
                    </div>

                    <div className="relative flex items-center py-5">
                      <div className="flex-grow border-t border-gray-400"></div>
                      <span className="mx-4 flex-shrink text-gray-400">
                        School / District Information
                      </span>
                      <div className="flex-grow border-t border-gray-400"></div>
                    </div>

                    <div className="">
                      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-4">
                        <div className="">
                          <dt className="text-sm font-medium text-gray-500">
                            School/District Name
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {edit ? (
                              <input
                                className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                type="text"
                                value={payload.name || ""}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    name: e.target.value,
                                  });
                                  setSaveDisabled(false);
                                }}
                              ></input>
                            ) : (
                              <div>{data?.name || "--"}</div>
                            )}
                          </dd>
                        </div>
                        <div className="">
                          <dt className="text-sm font-medium text-gray-500">
                            State
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {edit ? (
                              <select
                                className="border-gray-300 md:rounded-md"
                                value={payload.state}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    state: e.target.value,
                                  });
                                  setSaveDisabled(false);
                                }}
                              >
                                <option value=""> - </option>
                                {Object.keys(US_STATES).map((state) => {
                                  return (
                                    <option key={state} value={state}>
                                      {state}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              <div>{data?.state || "--"}</div>
                            )}

                            {edit &&
                              !payload.id &&
                              payload.state &&
                              payload.name && (
                                <>
                                  <Link
                                    className="ml-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    to={`${REACT_APP_MANAGER_LINK}/?state=${payload.state}&school=${payload.name}`}
                                    target="_blank"
                                    rel="noreferrer"
                                  >
                                    Search for NCES
                                  </Link>
                                </>
                              )}
                          </dd>
                        </div>

                        {payload.type === "school_custom" && edit && (
                          <div className="">
                            <dt className="text-sm font-medium text-gray-500">
                              SNN License Type
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              <select
                                name="school_custom_renewal"
                                id="school_custom_renewal"
                                className="mr-2"
                                defaultValue={payload?.snnLicenseType || ""}
                                onChange={(e) => {
                                  if (e.target.value === "") {
                                    setSnnRenewal(undefined);
                                    setPayload({
                                      ...payload,
                                      snnLicenseType: null,
                                      id: "",
                                    });
                                  } else if (e.target.value === "renewal") {
                                    setSnnRenewal(true);
                                    setPayload({
                                      ...payload,
                                      id: payload.id || data?.id,
                                      snnLicenseType: "renewal",
                                    });
                                  } else {
                                    setSnnRenewal(false);
                                    setPayload({
                                      ...payload,
                                      snnLicenseType: "new",
                                      id: "",
                                    });
                                  }
                                  setSaveDisabled(false);
                                }}
                              >
                                <option value=""> - </option>
                                <option value="renewal"> Renewal </option>
                                <option value="new"> New License </option>
                              </select>
                            </dd>
                          </div>
                        )}

                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            NCES ID
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {(payload.type === "school_custom" && snnRenewal) ||
                            (payload.type !== "school_custom" && edit) ? (
                              <>
                                <input
                                  className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                  type="text"
                                  value={payload.id}
                                  onChange={(e) => {
                                    setPayload({
                                      ...payload,
                                      id: e.target.value.trim(),
                                    });
                                    setSaveDisabled(false);
                                  }}
                                />
                                {payload.id && payload.id.length >= 7 && (
                                  <button
                                    className="mt-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                    onClick={() => {
                                      if (payload.id) {
                                        schoolDiggerRefetch();
                                      }
                                    }}
                                  >
                                    Search Schooldigger
                                  </button>
                                )}
                              </>
                            ) : (
                              <div>{data?.id || "--"}</div>
                            )}
                          </dd>
                        </div>

                        {payload.type !== "school_custom" && <div></div>}
                        {showAddress && (
                          <>
                            {edit ? (
                              <>
                                <div className="">
                                  <dt className="text-sm font-medium text-gray-500">
                                    Address
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    <input
                                      className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                      type="text"
                                      value={payload.address}
                                      onChange={(e) => {
                                        setPayload({
                                          ...payload,
                                          address: e.target.value,
                                        });
                                        setSaveDisabled(false);
                                      }}
                                    ></input>
                                  </dd>
                                </div>
                                <div className="">
                                  <dt className="text-sm font-medium text-gray-500">
                                    City
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    <input
                                      className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                      type="text"
                                      value={payload.city}
                                      onChange={(e) => {
                                        setPayload({
                                          ...payload,
                                          city: e.target.value,
                                        });
                                        setSaveDisabled(false);
                                      }}
                                    ></input>
                                  </dd>
                                </div>
                                <div className="">
                                  <dt className="text-sm font-medium text-gray-500">
                                    Zip
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    <input
                                      className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                      type="text"
                                      value={payload.zip}
                                      onChange={(e) => {
                                        setPayload({
                                          ...payload,
                                          zip: e.target.value.trim(),
                                        });
                                        setSaveDisabled(false);
                                      }}
                                    ></input>
                                  </dd>
                                </div>
                              </>
                            ) : (
                              <>
                                <div className="mr-8">
                                  <dt className="text-sm font-medium text-gray-500">
                                    Address
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    {data?.address}
                                  </dd>
                                </div>
                                <div className="mr-8">
                                  <dt className="text-sm font-medium text-gray-500">
                                    City
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    {data?.city}
                                  </dd>
                                </div>
                                <div className="mr-8">
                                  <dt className="text-sm font-medium text-gray-500">
                                    State
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    {data?.state}
                                  </dd>
                                </div>
                                <div className="mr-8">
                                  <dt className="text-sm font-medium text-gray-500">
                                    Zip
                                  </dt>
                                  <dd className="mt-1 text-sm text-gray-900">
                                    {data?.zip}
                                  </dd>
                                </div>
                              </>
                            )}
                            <div className="sm:col-span-3">
                              <dt className="text-sm font-medium text-gray-500">
                                Timezone
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {edit ? (
                                  <select
                                    className="border-gray-300 md:rounded-md"
                                    value={payload.site_timezone}
                                    onChange={(e) => {
                                      setPayload({
                                        ...payload,
                                        site_timezone: e.target.value,
                                      });
                                      setSaveDisabled(false);
                                    }}
                                  >
                                    {Object.entries(TIMEZONES_FOR_PICKER).map(
                                      ([key, zone]) => {
                                        return (
                                          <option key={zone} value={zone}>
                                            {key}
                                          </option>
                                        );
                                      }
                                    )}
                                  </select>
                                ) : (
                                  <>{data?.site_timezone}</>
                                )}
                              </dd>
                            </div>
                          </>
                        )}
                      </dl>
                      <p className="mt-8 text-sm text-gray-600">
                        Note: Creating a license will either match NCES ID, or
                        try to match &quot;School / District Name&quot; and zip.
                      </p>
                    </div>

                    <div className="relative flex items-center py-5">
                      <div className="flex-grow border-t border-gray-400"></div>
                      <span className="mx-4 flex-shrink text-gray-400">
                        License Details
                      </span>
                      <div className="flex-grow border-t border-gray-400"></div>
                    </div>

                    {edit && priceChangeWarning !== "" && (
                      <div className="mb-5 rounded-md bg-yellow-50 p-4 text-yellow-700">
                        {priceChangeWarning}
                      </div>
                    )}

                    <dl className="grid grid-cols-1 gap-x-2 gap-y-8 sm:grid-cols-2">
                      <div className="inline-flex sm:col-span-1">
                        <div className="">
                          <dt className="text-sm font-medium text-gray-500">
                            License Type
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {edit ? (
                              <select
                                className="border-gray-300 md:rounded-md"
                                id="license_type"
                                name="license_type"
                                value={payload.type}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    type: e.target.value,
                                  });
                                  setSaveDisabled(false);
                                }}
                              >
                                <option value=""> - </option>
                                <option value="school">School License</option>
                                <option value="6-12">District (6-12)</option>
                                <option value="9-12">District (9-12)</option>
                                <option value="6-8">District (6-8)</option>
                                <option value="7-12">District (7-12)</option>
                                <option value="8-12">District (8-12)</option>
                                <option value="7-8">District (7-8)</option>
                                <option value="school_custom">
                                  School (No NCES ID)
                                </option>
                                <option value="individual">
                                  Individual Teacher License
                                </option>
                              </select>
                            ) : (
                              <div>{data?.type || "--"}</div>
                            )}
                          </dd>
                        </div>
                      </div>
                      <div className="inline-flex sm:col-span-1">
                        {payload.type !== "individual" && (
                          <div className="">
                            <dt className="text-sm font-medium text-gray-500">
                              Quote # Students
                            </dt>
                            <dd className="mt-1 inline-flex text-sm text-gray-900">
                              {edit && payload ? (
                                <input
                                  className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                  type="number"
                                  min="0"
                                  value={payload.priceInfo.numberOfStudents}
                                  onChange={(e) => {
                                    setPayload({
                                      ...payload,
                                      priceInfo: {
                                        ...payload.priceInfo,
                                        numberOfStudents: parseInt(
                                          e.target.value
                                        ),
                                        numberOfTeachers: 0,
                                      },
                                    });
                                    setSaveDisabled(false);
                                  }}
                                ></input>
                              ) : (
                                <div>
                                  {data?.priceInfo?.numberOfStudents || "--"}
                                </div>
                              )}
                            </dd>
                          </div>
                        )}
                        {payload.type === "individual" && (
                          <div>
                            <dt className="text-sm font-medium text-gray-500">
                              {payload.type === "individual" && (
                                <>Number of License Codes</>
                              )}
                            </dt>
                            <dd className="mt-1 inline-flex text-sm text-gray-900">
                              {edit && payload ? (
                                <input
                                  className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                  type="number"
                                  min="0"
                                  value={payload.priceInfo.numberOfTeachers}
                                  onChange={(e) => {
                                    setPayload({
                                      ...payload,
                                      priceInfo: {
                                        ...payload.priceInfo,
                                        numberOfTeachers: parseInt(
                                          e.target.value
                                        ),
                                        numberOfStudents: 0,
                                      },
                                    });
                                    setSaveDisabled(false);
                                  }}
                                ></input>
                              ) : (
                                <div>
                                  {data?.priceInfo?.numberOfTeachers || "--"}
                                </div>
                              )}
                            </dd>{" "}
                          </div>
                        )}
                      </div>
                      <div className="inline-flex sm:col-span-1">
                        <div className="">
                          <dt className="text-sm font-medium text-gray-500">
                            Price
                          </dt>
                          <dd className="relative mt-1 inline-flex text-sm text-gray-900">
                            {edit && payload ? (
                              <>
                                <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
                                  <span className="text-gray-500 sm:text-sm">
                                    $
                                  </span>
                                </div>
                                <input
                                  className="col-span-1 block border-gray-300 pl-7 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                  type="number"
                                  min="0"
                                  value={payload.amount}
                                  onChange={(e) => {
                                    setPayload({
                                      ...payload,
                                      amount: parseFloat(e.target.value),
                                    });
                                    setSaveDisabled(false);
                                  }}
                                ></input>
                              </>
                            ) : (
                              <div>$ {data?.amount || "--"}</div>
                            )}
                          </dd>
                        </div>
                      </div>
                      <div className="inline-flex sm:col-span-1">
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            Expiration
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {edit && payload ? (
                              <select
                                id="expiration"
                                className="border-gray-300 md:rounded-md"
                                name="expiration"
                                value={payload.expiration || ""}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    expiration: parseInt(e.target.value),
                                  });
                                  setSaveDisabled(false);
                                }}
                              >
                                <option value={0}>--</option>
                                {nextDates.map((date, index) => {
                                  return (
                                    <option
                                      key={index}
                                      value={Math.floor(date.getTime() / 1000)}
                                    >
                                      {`October ${date.getDate()}, ${date.getFullYear()}`}
                                    </option>
                                  );
                                })}
                              </select>
                            ) : (
                              <div>
                                {payload?.expiration &&
                                  timeConverter(payload?.expiration)}
                              </div>
                            )}
                          </dd>
                        </div>
                      </div>
                      <div className="inline-flex sm:col-span-1">
                        <div>
                          <dt className="text-sm font-medium text-gray-500">
                            License Tier
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            {edit && payload ? (
                              <select
                                id="license-tier"
                                className="border-gray-300 md:rounded-md"
                                name="license-tier"
                                value={payload.licenseTier || ""}
                                onChange={(e) => {
                                  setPayload({
                                    ...payload,
                                    licenseTier: e.target.value,
                                    lms:
                                      e.target.value === "Plus"
                                        ? ""
                                        : payload.lms,
                                    rosteringSystem:
                                      e.target.value === "Plus"
                                        ? ""
                                        : payload.rosteringSystem,
                                    integrationStatus:
                                      e.target.value === "Plus"
                                        ? ""
                                        : payload.integrationStatus,
                                    classlinkTenantId:
                                      e.target.value === "Plus"
                                        ? ""
                                        : payload.classlinkTenantId,
                                    cleverDistrictId:
                                      e.target.value === "Plus"
                                        ? ""
                                        : payload.cleverDistrictId,
                                  });
                                  setSaveDisabled(false);
                                }}
                              >
                                <option value="">--</option>
                                <option value="Integral">Integral</option>
                                <option value="Plus">Plus</option>
                              </select>
                            ) : (
                              <div>{data?.licenseTier || "--"}</div>
                            )}
                          </dd>
                        </div>
                      </div>
                      <div className="inline-flex sm:col-span-1">
                        {payload.licenseTier === "Integral" && (
                          <div>
                            <dt className="text-sm font-medium text-gray-500">
                              Rostering System
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900">
                              {edit && payload ? (
                                <select
                                  id="rostering-system"
                                  className="border-gray-300 md:rounded-md"
                                  name="rostering-system"
                                  value={payload.rosteringSystem || ""}
                                  onChange={(e) => {
                                    setPayload({
                                      ...payload,
                                      rosteringSystem: e.target.value,
                                      classlinkTenantId:
                                        e.target.value === "Clever"
                                          ? ""
                                          : payload.classlinkTenantId,
                                      cleverDistrictId:
                                        e.target.value === "ClassLink/OneRoster"
                                          ? ""
                                          : payload.cleverDistrictId,
                                      rosteringData: {},
                                    });
                                    queryClient.removeQueries({
                                      queryKey: ["lms-data"],
                                    });
                                    setSaveDisabled(false);
                                  }}
                                >
                                  <option value="">--</option>
                                  <option value="ClassLink/OneRoster">
                                    ClassLink/OneRoster
                                  </option>
                                  <option value="Clever">Clever</option>
                                </select>
                              ) : (
                                <div>{data?.rosteringSystem || "--"}</div>
                              )}
                            </dd>
                          </div>
                        )}
                      </div>
                      <div className="inline-flex sm:col-span-1">
                        {payload.licenseTier === "Integral" &&
                          payload.type !== "individual" && (
                            <div>
                              <dt className="text-sm font-medium text-gray-500">
                                L.M.S.
                              </dt>
                              <dd className="mt-1 text-sm text-gray-900">
                                {edit && payload ? (
                                  <select
                                    id="lms"
                                    className="border-gray-300 md:rounded-md"
                                    name="lms"
                                    value={payload.lms || ""}
                                    onChange={(e) => {
                                      setPayload({
                                        ...payload,
                                        lms: e.target.value,
                                      });
                                      setSaveDisabled(false);
                                    }}
                                  >
                                    <option value="">--</option>
                                    <option value="Schoology">Schoology</option>
                                    <option value="Canvas">Canvas</option>
                                  </select>
                                ) : (
                                  <div>{data?.lms || "--"}</div>
                                )}
                              </dd>
                            </div>
                          )}
                      </div>
                      <div className="inline-flex sm:col-span-1">
                        {payload &&
                          payload.licenseTier === "Integral" &&
                          payload.rosteringSystem != "" && (
                            <div className="inline-flex sm:col-span-1">
                              {payload &&
                                payload.licenseTier === "Integral" &&
                                payload.rosteringSystem != "" && (
                                  <>
                                    <div className="mr-4">
                                      {payload.rosteringSystem === "Clever" && (
                                        <>
                                          <dt className="text-sm font-medium text-gray-500">
                                            Clever District ID
                                          </dt>
                                          <dd className="mt-1 text-sm text-gray-900">
                                            {edit ? (
                                              <>
                                                <input
                                                  className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                                  type="text"
                                                  value={
                                                    payload.cleverDistrictId
                                                  }
                                                  onChange={(e) => {
                                                    setPayload({
                                                      ...payload,
                                                      cleverDistrictId:
                                                        e.target.value.trim(),
                                                    });
                                                    setSaveDisabled(false);
                                                  }}
                                                />
                                                {payload.cleverDistrictId &&
                                                  payload.cleverDistrictId
                                                    .length === 24 && (
                                                    <button
                                                      className="mt-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                      onClick={() => {
                                                        // queryClient.removeQueries(
                                                        //   ["lms-data"]
                                                        // );
                                                        lmsRefetch();
                                                        setSaveDisabled(false);
                                                      }}
                                                    >
                                                      Search Clever
                                                    </button>
                                                  )}
                                                {isLmsDataLoading && (
                                                  <DmLoadingSpinner
                                                    className="inline px-4"
                                                    message="Loading"
                                                  />
                                                )}
                                              </>
                                            ) : (
                                              <>{payload.cleverDistrictId}</>
                                            )}
                                          </dd>
                                        </>
                                      )}
                                      {payload.rosteringSystem ===
                                        "ClassLink/OneRoster" && (
                                        <>
                                          <dt className="text-sm font-medium text-gray-500">
                                            Classlink Tenant ID
                                          </dt>
                                          <dd className="mt-1 text-sm text-gray-900">
                                            {edit ? (
                                              <>
                                                <input
                                                  className="col-span-1 block border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:col-span-2 md:rounded-md"
                                                  type="text"
                                                  value={
                                                    payload.classlinkTenantId
                                                  }
                                                  onChange={(e) => {
                                                    setPayload({
                                                      ...payload,
                                                      classlinkTenantId:
                                                        e.target.value.trim(),
                                                    });
                                                    setSaveDisabled(false);
                                                  }}
                                                />
                                                {payload.classlinkTenantId &&
                                                  !isNaN(
                                                    +payload.classlinkTenantId
                                                  ) && (
                                                    <button
                                                      className="mt-2 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                      onClick={() => {
                                                        // queryClient.removeQueries(
                                                        //   ["lms-data"]
                                                        // );
                                                        lmsRefetch();
                                                        setSaveDisabled(false);
                                                      }}
                                                    >
                                                      Search ClassLink
                                                    </button>
                                                  )}
                                                {isLmsDataLoading && (
                                                  <DmLoadingSpinner
                                                    className="inline px-4"
                                                    message="Loading"
                                                  />
                                                )}
                                              </>
                                            ) : (
                                              <>{payload.classlinkTenantId}</>
                                            )}
                                          </dd>
                                        </>
                                      )}
                                    </div>
                                    <div>
                                      <dt className="text-sm font-medium text-gray-500">
                                        Integration Status
                                      </dt>
                                      <dd className="mt-1 text-sm text-gray-900">
                                        {edit &&
                                        payload &&
                                        payload.rosteringSystem != "" ? (
                                          <select
                                            id="integration-status"
                                            className="border-gray-300 md:rounded-md"
                                            name="integration-status"
                                            value={
                                              payload.integrationStatus || ""
                                            }
                                            onChange={(e) => {
                                              setPayload({
                                                ...payload,
                                                integrationStatus:
                                                  e.target.value,
                                              });
                                              setSaveDisabled(false);
                                            }}
                                          >
                                            <option value="">--</option>
                                            <option value="requested">
                                              Requested Data Share
                                            </option>
                                            <option value="verified">
                                              Verified Data Share
                                            </option>
                                            Indivdual License Form
                                          </select>
                                        ) : (
                                          <div>
                                            {data?.integrationStatus || "--"}
                                          </div>
                                        )}
                                      </dd>
                                    </div>
                                  </>
                                )}
                            </div>
                          )}
                      </div>
                      <div className="relative inline-flex sm:col-span-1">
                        <div className="">
                          <dt className="text-sm font-medium text-gray-500">
                            Hold Until
                          </dt>
                          <dd className="mt-1 text-sm text-gray-900">
                            <DatePicker
                              minDate={new Date()}
                              selected={holdUntilDate}
                              onChange={(newHoldUntilDate) => {
                                if (newHoldUntilDate instanceof Date) {
                                  setHoldUntilDate(newHoldUntilDate);
                                  setPayload({
                                    ...payload,
                                    hold_until:
                                      newHoldUntilDate.getTime() / 1000,
                                  });
                                  setSaveDisabled(false);
                                }
                              }}
                              popperPlacement="bottom"
                              popperModifiers={[
                                {
                                  name: "flip",
                                  options: {
                                    flipVariations: false,
                                    allowedAutoPlacements: [
                                      "bottom",
                                      "bottom-start",
                                    ],
                                    fallbackPlacements: [
                                      "bottom",
                                      "bottom-start",
                                    ],
                                  },
                                },
                                {
                                  name: "preventOverflow",
                                  enabled: false,
                                },
                                {
                                  name: "hide",
                                  enabled: false,
                                },
                              ]}
                              selectsStart
                              nextMonthButtonLabel=">"
                              previousMonthButtonLabel="<"
                              popperClassName="react-datepicker-left"
                              dateFormat="MM/dd/yyyy"
                            />
                          </dd>
                        </div>
                      </div>
                    </dl>

                    <RosteringActivation
                      rosteringData={rosteringData}
                      setRosteringData={setRosteringData}
                      lmsIntegrationData={lmsIntegrationData}
                      payload={payload}
                      setPayload={setPayload}
                      edit={edit}
                      setSaveDisabled={setSaveDisabled}
                      refetch={lmsRefetch}
                    />

                    <div className="mt-8 flex flex-row items-center">
                      <button
                        type="submit"
                        className={
                          "md:w-lg w-1/4 justify-self-end rounded-md border border-transparent bg-indigo-600 px-4 py-2 font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-indigo-500 disabled:opacity-50"
                        }
                        disabled={
                          saveDisabled ||
                          updateLoading ||
                          isLoading ||
                          payload?.status === ""
                        }
                        onClick={() => {
                          updateInvoice();
                          setSaveDisabled(true);
                        }}
                      >
                        Save Updates
                      </button>

                      {(payload?.status !== "Activated" || editMode) && data && (
                        <>
                          <button
                            type="submit"
                            className={
                              activateDisabled ||
                              licenseBasedWarnings ||
                              activateLoading ||
                              ((activateWarnings.includes("exact match") ||
                                activateWarnings.includes(
                                  "district to school disable"
                                )) &&
                                !activateAnyway)
                                ? "ml-16 w-1/4 cursor-not-allowed rounded-md bg-gray-500 px-4 py-2 text-base font-medium text-white opacity-50"
                                : "ml-16 w-1/4 rounded-md border border-transparent px-4 py-2 text-base font-medium text-white shadow-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2" +
                                  (activateWarnings.includes(
                                    "district match"
                                  ) ||
                                  activateWarnings.includes(
                                    "district to school warn"
                                  ) ||
                                  activateWarnings.includes("school match")
                                    ? //prettier-ignore
                                      `${` `}bg-dm-earth-yellow-500 hover:bg-dm-earth-yellow-600`
                                    : //prettier-ignore
                                      `${` `}bg-indigo-600 hover:bg-indigo-700`)
                            }
                            disabled={
                              activateDisabled ||
                              activateLoading ||
                              ((activateWarnings.includes("exact match") ||
                                activateWarnings.includes(
                                  "district to school disable"
                                )) &&
                                !activateAnyway) ||
                              licenseBasedWarnings
                            }
                            onClick={() => {
                              setShowSuccessMessageWithThisUpdate(false);
                              updateInvoice();
                              setShowActivationSummary(true);
                            }}
                          >
                            Activate
                          </button>
                          {(activateWarnings.includes("exact match") ||
                            activateWarnings.includes(
                              "district to school disable"
                            )) && (
                            <button
                              onClick={() => setActivateAnyway((prev) => !prev)}
                              className="ml-16 w-1/4 rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                            >
                              {activateAnyway
                                ? "Disable Activate"
                                : "Allow Activation Anyway"}
                            </button>
                          )}
                          {activateLoading && (
                            <span className="ml-6">Activating...</span>
                          )}
                          {activateWarnings.length > 0 && (
                            <span>
                              {activateWarnings.includes("Zach") && (
                                <p className="ml-6 text-red-500">
                                  <strong>
                                    WARNING: Zach must perform this activation
                                  </strong>
                                </p>
                              )}
                              {activateWarnings.includes("exact match") && (
                                <p className="ml-6 text-red-500">
                                  <strong>
                                    This site has an active license matching
                                    this quote exactly (i.e. the NCES,
                                    expiration, license type, license tier, and
                                    integrations all match)
                                  </strong>
                                </p>
                              )}
                              {activateWarnings.includes(
                                "district to school disable"
                              ) && (
                                <p className="ml-6 text-red-500">
                                  <strong>
                                    This school is covered under a district
                                    license which expires on or after the date
                                    of this quote and has the same integrations
                                    and tier.
                                  </strong>
                                </p>
                              )}
                              {(activateWarnings.includes(
                                "district to school disable"
                              ) ||
                                activateWarnings.includes("exact match")) &&
                                linkToActivatedQuote && (
                                  <Link
                                    className="ml-6 text-indigo-500 hover:underline"
                                    to={`${REACT_APP_MANAGER_LINK}/quote-lookup/${linkToActivatedQuote}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    Click here to view the activated quote.
                                  </Link>
                                )}
                            </span>
                          )}
                        </>
                      )}
                    </div>

                    {payload?.status === "" && (
                      <p className="text-md my-6 font-bold text-orange-500">
                        Save Notice: You must set a status to save a quote.
                      </p>
                    )}

                    {payload?.status === "Activated" &&
                      showDistQuoteForSingleSchool && (
                        <p className="text-md mt-6 font-bold">
                          {`License Notice: This activated quote was for a ${payload.type} district license, but only one school in the district was eligible for that product. On activation, the license was converted to a school license.`}
                        </p>
                      )}

                    {disabledMessages && disabledMessages.length > 0 && (
                      <>
                        <p className="text-md mt-6 font-bold text-red-500">
                          Activation Notice:
                        </p>
                        <ul className="ml-8 list-disc">
                          {disabledMessages.map((m) => (
                            <li
                              key={m}
                              className="text-md mt-2 font-bold text-red-500"
                            >
                              {m}
                            </li>
                          ))}
                        </ul>
                      </>
                    )}
                    {licenseBasedWarnings && (
                      <div className="text-md mt-2 font-bold text-red-500">
                        This site may need to be merged with another
                        post-activation. See current license for more
                        information.
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </>
      </>
    </>
  );
};

export default Invoice;
