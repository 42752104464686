import { useCallback } from "react";
import { withJsonHeader } from "../../../shared/axiosUtils";
import { deltamathAPI } from "../../../manager/utils";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";

/**
 * Hook that returns a function to send a request to remove the experiment
 * token cookie
 */
export function useResetExperiments() {
  const { mutateAsync: resetTokenMutation } = useMutation({
    mutationFn: async () => {
      await axios.post(
        `${deltamathAPI()}/experiments/reset`,
        JSON.stringify({}),
        withJsonHeader()
      );
    },
  });

  return useCallback(
    async () => await resetTokenMutation(),
    [resetTokenMutation]
  );
}
