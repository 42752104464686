import { ChangeEvent, useEffect, useState } from "react";
import InvoicePageEditableCard from "./InvoicePageEditableCard";
import { useActivationContext } from "./LicenseActivationContext";
import Button from "../../../student/components/generic/button";
import { discriminateInvoiceType } from "../../utils/quoteUtils";
import Warnings from "./Warnings";
import ChangePrimaryAdmins from "./Admin/ChangePrimaryAdmins";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { deltamathAPI } from "../../utils";
import axios from "axios";
import { DMModal } from "../../../shared/DMModal";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";

const ContactAdminDetails: React.FC<{
  quoteNumber: string;
  invoiceContactEmail: string;
  invoiceAdminLast?: string;
  invoiceAdminFirst?: string;
  invoiceAdminEmail?: string;
  invoiceContactName: string;
}> = ({
  quoteNumber,
  invoiceContactEmail,
  invoiceAdminLast,
  invoiceAdminFirst,
  invoiceAdminEmail,
  invoiceContactName,
}) => {
  const activationContext = useActivationContext();
  const queryClient = useQueryClient();
  const toastContext = useDeltaToastContext();
  const [edit, setEdit] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [showEditButton, setShowEditButton] = useState(
    activationContext?.invoiceDetails?.status === "Activated"
  );
  const [contactInfo, setContactInfo] = useState({
    contactName: invoiceContactName || "",
    contactEmail: invoiceContactEmail || "",
    adminFirst: invoiceAdminFirst || "",
    adminLast: invoiceAdminLast || "",
    adminEmail: invoiceAdminEmail || "",
  });

  const [showChangeAdminModal, setShowChangeAdminModal] = useState(false);
  const [entityType, setEntityType] = useState<
    "school" | "district" | "individual" | ""
  >("");
  const [refetchInvoiceOnModalClose, setRefetchInvoiceOnModalClose] =
    useState(false);

  // for post-activation admin edits, fetch admin data
  const { data: admins, isFetching: isAdminsFetching } = useQuery({
    queryFn: async () => {
      const queryParams = new URLSearchParams();
      queryParams.append("id", activationContext.invoiceDetails?.id || "");
      queryParams.append("entityType", entityType);
      if (entityType === "school") {
        queryParams.append("schoolLicenseType", "school");
      } else if (entityType === "district") {
        queryParams.append(
          "districtLicenseType",
          activationContext.invoiceDetails?.type || ""
        );
      }
      const response = await axios.get(
        deltamathAPI() +
          `/manager_new/invoices/details/${quoteNumber}/admins?${queryParams.toString()}`
      );
      return response.data?.admins;
    },
    queryKey: ["admins", quoteNumber],
    refetchOnWindowFocus: false,
    enabled:
      !!quoteNumber &&
      (entityType === "school" || entityType === "district") &&
      !!activationContext.invoiceDetails?.id &&
      activationContext.invoiceDetails?.status === "Activated",
  });

  const { mutate: updateIndividualContact } = useMutation({
    mutationFn: async () => {
      const response = await axios.put(
        deltamathAPI() +
          `/manager_new/invoices/post_activation_update/${quoteNumber}?updateAction=individualContact`,
        {
          newContactName: contactInfo.contactName,
          newContactEmail: contactInfo.contactEmail,
        }
      );
      return response.data;
    },
    onSuccess: () => {
      toastContext.addToast({
        status: "Success",
        message: "Contact information updated successfully.",
      });
      queryClient.invalidateQueries({
        queryKey: ["invoice", quoteNumber],
      });
      setEdit(false);
    },
    onError: (e) => {
      toastContext.addToast({
        status: "Error",
        message:
          (e as any).response?.data?.message ||
          e.message ||
          "Failed to update contact information: " + e,
      });
    },
  });

  useEffect(() => {
    if (activationContext.invoiceDetails?.type) {
      setEntityType(
        discriminateInvoiceType(activationContext.invoiceDetails?.type)
      );
    }
  }, [activationContext.invoiceDetails?.type]);

  const handleLocalSave = () => {
    if (
      activationContext.invoiceDetails?.status === "Activated" &&
      activationContext.invoiceDetails.type === "individual"
    ) {
      updateIndividualContact();
    } else {
      activationContext.simpleQuoteUpdate(contactInfo);
    }
  };

  const handeInputChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    let sanitizedValue = value;
    if (name === "contactEmail" || name === "adminEmail") {
      sanitizedValue = value?.trim().toLowerCase();
    }
    setContactInfo((prev) => ({
      ...prev,
      [name]: sanitizedValue || "",
    }));
  };

  const handlePrimaryAdminModalClose = () => {
    queryClient.invalidateQueries({
      queryKey: ["admins", quoteNumber],
    });
    queryClient.removeQueries({ queryKey: ["addAdmin"] });
    setShowChangeAdminModal(false);
    if (refetchInvoiceOnModalClose) {
      queryClient.invalidateQueries({
        queryKey: ["invoice", quoteNumber],
      });
    }
  };

  useEffect(() => {
    if (activationContext?.invoiceDetails?.status === "Activated" && !edit) {
      setShowEditButton(true);
    } else {
      setShowEditButton(false);
    }
  }, [activationContext?.invoiceDetails?.status, edit]);

  useEffect(() => {
    const isContactInfoChanged =
      contactInfo.contactName.trim() !== (invoiceContactName?.trim() || "") ||
      contactInfo.contactEmail.trim() !== (invoiceContactEmail?.trim() || "") ||
      contactInfo.adminFirst.trim() !== (invoiceAdminFirst?.trim() || "") ||
      contactInfo.adminLast.trim() !== (invoiceAdminLast?.trim() || "") ||
      contactInfo.adminEmail.trim() !== (invoiceAdminEmail?.trim() || "");
    setSaveDisabled(!isContactInfoChanged);
  }, [
    contactInfo.contactName,
    contactInfo.contactEmail,
    contactInfo.adminFirst,
    contactInfo.adminLast,
    contactInfo.adminEmail,
    invoiceContactName,
    invoiceContactEmail,
    invoiceAdminFirst,
    invoiceAdminLast,
    invoiceAdminEmail,
  ]);

  return (
    <InvoicePageEditableCard
      title="Contact / Admin Details"
      handleLocalSave={handleLocalSave}
      saveDisabled={saveDisabled}
      setEdit={setEdit}
      showEditButton={showEditButton}
    >
      <div className="flex flex-row gap-4">
        <div className="flex flex-col">
          <div className="mb-2 flex flex-row gap-2 xl:min-w-[500px]">
            <dl>
              <dt className="text-sm font-medium text-gray-500">
                Contact Name
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {edit ||
                activationContext.invoiceDetails?.status !== "Activated" ? (
                  <input
                    className="block rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                    type="text"
                    name="contactName"
                    value={contactInfo.contactName}
                    onChange={handeInputChange}
                  ></input>
                ) : (
                  <div>{invoiceContactName || "--"}</div>
                )}
              </dd>
            </dl>
            <dl className="mb-6 flex-1">
              <dt className="text-sm font-medium text-gray-500">
                Contact Email
              </dt>
              <dd className="mt-1 flex-1 text-sm text-gray-900">
                {edit ||
                activationContext.invoiceDetails?.status !== "Activated" ? (
                  <input
                    className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                    type="email"
                    name="contactEmail"
                    value={contactInfo.contactEmail}
                    onChange={handeInputChange}
                  ></input>
                ) : (
                  <div>{invoiceContactEmail || "--"}</div>
                )}
              </dd>
            </dl>
          </div>
          <div className="mb-2 flex flex-row gap-2 xl:min-w-[500px]">
            <dl>
              <dt className="text-sm font-medium text-gray-500">Admin First</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {activationContext.invoiceDetails?.status !== "Activated" ? (
                  <input
                    className="block rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                    type="text"
                    name="adminFirst"
                    value={contactInfo.adminFirst}
                    onChange={handeInputChange}
                  ></input>
                ) : (
                  <div>{invoiceAdminFirst || "--"}</div>
                )}
              </dd>
            </dl>
            <dl className="mb-6">
              <dt className="text-sm font-medium text-gray-500">Admin Last</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {activationContext.invoiceDetails?.status !== "Activated" ? (
                  <input
                    className="block rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
                    type="text"
                    name="adminLast"
                    value={contactInfo.adminLast}
                    onChange={handeInputChange}
                  ></input>
                ) : (
                  <div>{invoiceAdminLast || "--"}</div>
                )}
              </dd>
            </dl>
          </div>
          <div className="mb-2 flex flex-row gap-2 xl:min-w-[500px]">
            <dl className="mb-6 flex-1">
              <dt className="text-sm font-medium text-gray-500">Admin Email</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {activationContext.invoiceDetails?.status !== "Activated" ? (
                  <input
                    className="block rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 xl:min-w-[300px]"
                    type="text"
                    name="adminEmail"
                    value={contactInfo.adminEmail}
                    onChange={handeInputChange}
                  ></input>
                ) : (
                  <div>{invoiceAdminEmail || "--"}</div>
                )}
              </dd>
            </dl>
            {activationContext.invoiceDetails?.status === "Activated" &&
              (activationContext.invoiceDetails?.type === "school" ||
                discriminateInvoiceType(
                  activationContext.invoiceDetails?.type
                ) === "district") && (
                <div className="ml-2 flex flex-col items-center justify-center">
                  <Button
                    className="text-xs"
                    onClick={() => setShowChangeAdminModal(true)}
                  >
                    Change Primary Admin
                  </Button>
                </div>
              )}
          </div>
        </div>
        <Warnings>
          {activationContext.state?.activationDisableReasons.has("admin") && (
            <div>
              <div className="text-center text-sm text-red-500">
                Activation Warning:
              </div>
              <div className="text-center text-sm text-red-500">
                The saved quote must have admin information to activate.
              </div>
            </div>
          )}
        </Warnings>
      </div>
      {activationContext.invoiceDetails?.status === "Activated" &&
        (entityType === "school" || entityType === "district") && (
          <DMModal
            visible={showChangeAdminModal}
            onClose={handlePrimaryAdminModalClose}
            className={"!max-w-[1200px] items-center"}
          >
            <h2 className="mb-4 text-2xl font-bold">Change Primary Admin</h2>
            {!admins?.length && (
              <div className="flex flex-col items-center justify-center">
                <p className="text-md my-1 mb-6 text-gray-500">
                  No active admins for this site
                </p>
              </div>
            )}
            {!isAdminsFetching && (
              <ChangePrimaryAdmins
                admins={admins}
                setRefetchInvoiceOnModalClose={setRefetchInvoiceOnModalClose}
              />
            )}
          </DMModal>
        )}
    </InvoicePageEditableCard>
  );
};

export default ContactAdminDetails;
