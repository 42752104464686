import { NavLink, useParams } from "react-router-dom";
import { executeQuery, REACT_APP_MANAGER_LINK } from "../../../utils";
import { useMutation, useQuery } from "@tanstack/react-query";
import { SupportAction, SupportActionOutput } from "./types";
import { useState } from "react";
import { SupportActionResults } from "./SupportActionResults";
import { SubmitHandler, useForm } from "react-hook-form";
import axios from "axios";
import { deltamathAPI } from "../../utils";
import { withJsonHeader } from "../../../shared/axiosUtils";
import Button from "../../../student/components/generic/button";
import { useDocumentTitle } from "usehooks-ts";
import { compact } from "lodash";

export const SupportActionPage = () => {
  const { actionKey } = useParams<{ actionKey: string }>();
  const { handleSubmit, register, getValues } = useForm();
  const [outputResults, setOutputResults] = useState<
    SupportActionOutput[] | undefined
  >(undefined);
  const [outputError, setOutputError] = useState<string | undefined>(undefined);

  const { data: actionData } = useQuery<{ action: SupportAction }>({
    queryKey: ["supportAction", actionKey],
    queryFn: () =>
      executeQuery({ path: `/manager_new/support/actions/${actionKey}` }),
    refetchOnWindowFocus: false,
  });

  useDocumentTitle(
    compact([
      actionData?.action.name,
      "Support Actions",
      "DeltaMath Manager",
    ]).join(" | ")
  );

  const { mutate, status } = useMutation<
    { data: { results: SupportActionOutput[] } },
    unknown,
    Record<string, unknown>
  >({
    mutationFn: (d) => {
      const args = actionData?.action.inputs.map((input) => d[input.name]);
      return axios.post(
        `${deltamathAPI()}/manager_new/support/actions/${actionKey}`,
        JSON.stringify({ args }),
        withJsonHeader()
      );
    },
  });

  const performAction: SubmitHandler<any> = (d) => {
    mutate(d, {
      onSuccess: ({ data }) => {
        setOutputError(undefined);
        setOutputResults(data.results);
      },
      onError: (error: any) => {
        if (error?.response?.data?.error) {
          setOutputError(error.response.data.error);
        } else if (
          error?.response?.data?.message &&
          typeof error.response.data.message === "string"
        ) {
          setOutputError(error.response.data.message);
        } else {
          setOutputError("An unknown error occurred");
        }
      },
    });
  };

  return (
    <div className="flex flex-col gap-10 p-6">
      <div>
        <NavLink to={`${REACT_APP_MANAGER_LINK}/support/actions`}>
          &larr; Back
        </NavLink>
        <h1 className="text-2xl font-bold">{actionData?.action.name}</h1>
        <p className="text-dm-charcoal-500">{actionData?.action.description}</p>
      </div>

      {actionData && (
        <form
          className="flex flex-wrap items-start gap-8"
          onSubmit={handleSubmit(performAction)}
        >
          {actionData.action.inputs.map((input) => (
            <div key={input.name} className="flex flex-col gap-1">
              <label className="text-sm font-bold" htmlFor={input.name}>
                {input.label}
              </label>
              {input.type === "text" && input.choices ? (
                <select
                  className="rounded"
                  id={input.name}
                  defaultValue={input.default}
                  {...register(input.name)}
                >
                  <option value="" disabled selected>
                    Select an option
                  </option>
                  {input.choices.map((choice) => (
                    <option key={choice.value} value={choice.value}>
                      {choice.label}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  className="rounded"
                  type={input.type}
                  id={input.name}
                  defaultValue={input.default}
                  {...register(input.name)}
                />
              )}
            </div>
          ))}
          <Button className="mt-6 first:mt-0" isLoading={status === "pending"}>
            Submit
          </Button>
        </form>
      )}

      <SupportActionResults
        formData={getValues()}
        outputError={outputError}
        outputResults={outputResults}
      />
    </div>
  );
};
