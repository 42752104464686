import Button from "../../../student/components/generic/button";
import InvoicePageCard from "./InvoicePageCard";

const LoadingCard: React.FC<{
  title: string;
  fullWidth?: boolean;
  editable?: boolean;
}> = ({ title, fullWidth = false, editable = false }) => {
  return (
    <InvoicePageCard title={title} fullWidth={fullWidth}>
      <div className="flex flex-col gap-4">
        <div className="flex flex-col gap-4">
          <div className="h-8 w-full animate-pulse rounded-md bg-gray-200"></div>
          <div className="h-8 w-full animate-pulse rounded-md bg-gray-200"></div>
          <div className="h-8 w-full animate-pulse rounded-md bg-gray-200"></div>
          <div className="h-8 w-full animate-pulse rounded-md bg-gray-200"></div>
          <div className="h-8 w-full animate-pulse rounded-md bg-gray-200"></div>
        </div>
        {editable && (
          <div className="mt-auto">
            <Button className={"mr-2 mt-2"}>Save These Updates</Button>
          </div>
        )}
      </div>
    </InvoicePageCard>
  );
};

export default LoadingCard;
