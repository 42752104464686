import clsx from "clsx";
import { PropsWithChildren } from "react";

const InvoicePageCard: React.FC<
  PropsWithChildren<{ title: string; fullWidth?: boolean; overflow?: string }>
> = ({ title, fullWidth = false, children, overflow = "hidden" }) => {
  return (
    <div
      className={clsx(
        "flex flex-col flex-wrap justify-center gap-1 overflow-auto",
        fullWidth ? "col-span-2" : "col-span-2 lg:col-span-1"
      )}
      id={`invoice-page-${title
        .replaceAll("/", "")
        .replace(/\s+/g, "-")
        .toLowerCase()}`}
    >
      <h1 className="text-xl font-bold">{title}</h1>
      <div className="mt-1 flex-1 border-2 shadow-xl md:mt-0">
        <div className={clsx(`overflow-${overflow}`, "h-full")}>
          <div className="h-full w-full rounded-md bg-white px-4 py-5 sm:p-6">
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicePageCard;
