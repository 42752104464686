export interface IAdminData {
  account_type: "school" | "district" | "super_school" | "super_district";
  _id: string;
  first: string;
  email: string;
  last: string;
  schoolIDs: Array<string>;
  districtID: string;
}

export type AdminMutationOptionsType =
  | "downgradeSiteAdmin"
  | "upgradeSiteAdmin"
  | "addExistingAdminToSite"
  | "addNewAdminToSite"
  | "none";

export interface IAdminMutationData {
  email?: string;
  first?: string;
  last?: string;
  _id?: string;
  account_type?: IAdminData["account_type"];
  districtID?: string;
  schoolIDs?: Array<string>;
  existingIsTeacherOnly?: boolean;
}

export interface IChangeAdminStatusPayload {
  newAdminID?: string;
  currentAdminID?: string;
  newAdminEmail?: string;
  newAdminFirst?: string;
  newAdminLast?: string;
  downgradeOnly?: boolean;
  currentAdminEmail?: string;
  newAdminAccountType?:
    | "school"
    | "district"
    | "super_school"
    | "super_district";
}

export type UpdateBodyType = IChangeAdminStatusPayload & {
  quoteNumber?: string;
  integrations?: string;
  schoolID?: string;
  districtID?: string;
};

export interface INewAdminPayload {
  siteType: "school" | "district";
  siteId: string;
  userData: {
    firstName: string;
    lastName: string;
    email: string;
    account_type?: IAdminData["account_type"];
  };
  integrations: string;
}

//We are in a position where similar functions related to changing/adding admins have different expectations on data shape. This utility can take the underlying information and coerce the data into the correct shape for the given action
export function setAdminDataForMutation({
  mutationType,
  _id,
  email,
  first,
  last,
  account_type,
  addSchoolOnly = false,
}: {
  mutationType: AdminMutationOptionsType;
  email?: string;
  _id?: string;
  first?: string;
  last?: string;
  account_type?: INewAdminPayload["userData"]["account_type"];
  addSchoolOnly?: boolean;
}): IChangeAdminStatusPayload | INewAdminPayload["userData"] {
  switch (mutationType) {
    case "upgradeSiteAdmin":
      return {
        newAdminID: _id,
        newAdminEmail: email,
        newAdminFirst: first,
        newAdminLast: last,
        newAdminAccountType: account_type,
      };
    case "downgradeSiteAdmin":
      return {
        currentAdminID: _id,
        currentAdminEmail: email,
      };
    case "addExistingAdminToSite":
    case "addNewAdminToSite":
      if (addSchoolOnly) {
        return {
          newAdminEmail: email || "",
          newAdminFirst: first || "",
          newAdminLast: last || "",
        };
      }
      return {
        firstName: first || "",
        lastName: last || "",
        email: email || "",
        account_type,
      };
    default:
      return {
        email: "",
        firstName: "",
        lastName: "",
      };
  }
}
