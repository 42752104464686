import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deltamathAPI } from "../../../utils";
import {
  IAdminMutationData,
  AdminMutationOptionsType,
  UpdateBodyType,
  setAdminDataForMutation,
  IAdminData,
} from "./adminUtils";
import { useActivationContext } from "../LicenseActivationContext";
import axios, { AxiosError } from "axios";
import { useDeltaToastContext } from "../../../../shared/contexts/ToasterContext";
import Button from "../../../../student/components/generic/button";
import CreateAdmin from "./CreateAdmin";
import {
  coerceLicenseType,
  discriminateInvoiceType,
  getIntegrationsString,
  IFoundSiteLicenseForInvoice,
} from "../../../utils/quoteUtils";
import { useState } from "react";

interface IAdminMutationsProps {
  admins: IAdminData[];
  userDataForAdminMutation: IAdminMutationData;
  mutationAction: AdminMutationOptionsType;
  searchEmail: string;
  setMutationAction: React.Dispatch<
    React.SetStateAction<AdminMutationOptionsType>
  >;
  setUserDataForAdminMutation: React.Dispatch<
    React.SetStateAction<IAdminMutationData>
  >;
  setRefetchInvoiceOnModalClose: React.Dispatch<React.SetStateAction<boolean>>;
}

const AdminMutations: React.FC<IAdminMutationsProps> = ({
  admins,
  userDataForAdminMutation,
  mutationAction,
  searchEmail,
  setMutationAction,
  setUserDataForAdminMutation,
  setRefetchInvoiceOnModalClose,
}) => {
  const activationContext = useActivationContext();
  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();

  const [replacementInvoiceAdmin, setReplacementInvoiceAdmin] =
    useState<IAdminMutationData>();

  const handleCancel = () => {
    setMutationAction("none");
    setUserDataForAdminMutation({});
    setReplacementInvoiceAdmin(undefined);
  };

  const handleSelectChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedAdmin = admins.find((admin) => admin._id === e.target.value);
    if (selectedAdmin) {
      setReplacementInvoiceAdmin({
        email: selectedAdmin.email,
        first: selectedAdmin.first,
        last: selectedAdmin.last,
        _id: selectedAdmin._id,
      });
    } else {
      setReplacementInvoiceAdmin(undefined);
    }
  };

  const siteType = discriminateInvoiceType(
    coerceLicenseType(activationContext.invoiceDetails?.type || "")
  );

  //mutation for changing the status of admins already associated with the site
  const { mutate: updateAdmin } = useMutation({
    mutationFn: async ({
      addSchoolOnly = false,
      inviteExistingAsPrimary = false,
    }: {
      addSchoolOnly?: boolean;
      inviteExistingAsPrimary?: boolean;
    }) => {
      const body: UpdateBodyType = {
        quoteNumber: activationContext.invoiceDetails?.quoteNumber,
        [siteType === "district" ? "districtID" : "schoolID"]:
          activationContext.invoiceDetails?.id,
        ...setAdminDataForMutation({
          mutationType: mutationAction,
          ...(addSchoolOnly ? { addSchoolOnly: true } : {}),
          ...userDataForAdminMutation,
        }),
      };

      if (mutationAction === "downgradeSiteAdmin") {
        body.downgradeOnly = true;
        if (
          userDataForAdminMutation.email ===
          activationContext.invoiceDetails?.adminEmail
        ) {
          if (!replacementInvoiceAdmin?.email) {
            throw new Error(
              "You must select an existing primary admin to use as the new admin contact for this quote."
            );
          }
          body.newAdminEmail = replacementInvoiceAdmin?.email;
          body.newAdminFirst = replacementInvoiceAdmin?.first;
          body.newAdminLast = replacementInvoiceAdmin?.last;
        }
      }

      if (
        activationContext.invoiceDetails?.licenseTier === "Integral" &&
        (siteType === "school" || siteType === "district") &&
        activationContext.licenseData?.[siteType]?.status !== "none"
      ) {
        const integrationToAppend =
          typeof (
            activationContext.licenseData?.[
              siteType
            ] as IFoundSiteLicenseForInvoice
          )?.integrations === "string"
            ? (
                activationContext.licenseData?.[
                  siteType
                ] as IFoundSiteLicenseForInvoice
              )?.integrations
            : getIntegrationsString(activationContext.invoiceDetails);
        body["integrations"] = integrationToAppend;
      }

      const queryParams = new URLSearchParams();
      if (addSchoolOnly) {
        queryParams.append("addSchoolOnly", "true");
      }
      if (inviteExistingAsPrimary) {
        queryParams.append("inviteExistingAsPrimary", "true");
      }

      const response = await axios.post(
        deltamathAPI() +
          `/manager_new/invoices/change_${siteType}_admin${
            queryParams.toString() ? "?" + queryParams.toString() : ""
          }`,
        body
      );
      return response.data;
    },
    onSuccess: (res) => {
      toastContext.addToast({
        status: res.success ? "Success" : "Error",
        message: res.message,
      });
    },
    onError: (e: AxiosError) => {
      toastContext.addToast({
        status: "Error",
        message:
          (e.response?.data as any)?.message ||
          e?.message ||
          "There was an error with the request: " + e,
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["admins", activationContext.invoiceDetails?.quoteNumber],
      });
      queryClient.removeQueries({ queryKey: ["addAdmin"] });
      setRefetchInvoiceOnModalClose(true);
      setMutationAction("none");
    },
  });

  return (
    <div className="flex flex-col gap-2">
      {mutationAction === "upgradeSiteAdmin" && (
        <div className="flex flex-row justify-between gap-2">
          {`Upgrading this admin will downgrade super_${siteType} accounts to ${siteType} accounts${
            siteType === "school"
              ? " except that any super_school admin associated with multiple schools will not be downgraded"
              : ""
          }.`}
          <div className="flex flex-row items-center gap-2">
            <Button onClick={() => updateAdmin({})}>Proceed</Button>
            <Button
              onClick={handleCancel}
              className="bg-red-500 hover:bg-red-700"
            >
              Cancel
            </Button>
          </div>
        </div>
      )}
      {mutationAction === "downgradeSiteAdmin" && (
        <div className="flex flex-row justify-between gap-2">
          <div>
            Do you want to downgrade this admin to a regular admin?
            {userDataForAdminMutation.email ===
            activationContext.invoiceDetails?.adminEmail
              ? " Since you are downgrading the primary admin for this Quote, you must select a new primary."
              : ""}
          </div>
          <div className="flex flex-row items-center gap-2">
            <Button
              onClick={() => updateAdmin({})}
              className="disabled:bg-indigo-200"
            >
              Proceed
            </Button>
            <Button
              onClick={handleCancel}
              className="bg-red-500 hover:bg-red-700"
            >
              Cancel
            </Button>
            {userDataForAdminMutation.email ===
              activationContext.invoiceDetails?.adminEmail && (
              <div className="mx-2 flex flex-1 flex-row items-center">
                <select
                  onChange={handleSelectChange}
                  className="rounded-md border border-gray-300"
                >
                  <option value="" className="p-2">
                    Select Primary
                  </option>
                  {admins
                    .filter(
                      (admin) =>
                        admin.account_type === "super_" + siteType &&
                        admin.email !== userDataForAdminMutation.email
                    )
                    .map((admin) => (
                      <option className="p-2" key={admin._id} value={admin._id}>
                        {admin.email}
                      </option>
                    ))}
                </select>
              </div>
            )}
          </div>
        </div>
      )}

      {(mutationAction === "addNewAdminToSite" ||
        mutationAction === "addExistingAdminToSite") && (
        <CreateAdmin
          userDataForAdminMutation={userDataForAdminMutation}
          showAddNewAdminForm={mutationAction === "addNewAdminToSite"}
          setMutationAction={setMutationAction}
          updateAdmin={updateAdmin}
          searchEmail={searchEmail}
          showAddExistingAdmin={mutationAction === "addExistingAdminToSite"}
          setRefetchInvoiceOnModalClose={setRefetchInvoiceOnModalClose}
        />
      )}
    </div>
  );
};

export default AdminMutations;
