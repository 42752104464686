import axios from "axios";
import { useEffect, useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deltamathAPI } from "../../../manager/utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { getAdminParams } from "../../utils";
import { useUserContext } from "../../../shared/contexts/UserContext";

export default function InviteAdmins({ school_id }: { school_id: any }) {
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();

  const adminParams = getAdminParams();

  const [createAdminDisabled, setCreateAdminDisabled] = useState(true);
  const [adminToCreate, setAdminToCreate] = useState({
    account_type: "school",
    districtID: adminParams.districtID,
    first: "",
    last: "",
    email: "",
    schoolIDs: [school_id], // [school.schoolid],
    schoolid: school_id, // school.schoolid
  });

  const createAdmin = useMutation({
    mutationFn: (body: {
      account_type: string;
      districtID: any;
      first: string;
      last: string;
      email: string;
      schoolIDs: any[];
      schoolid: any;
    }) => {
      return axios.post(deltamathAPI() + "/admin_new/manage/create", body, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onSuccess: (data: any) => {
      if (data.data.error && data.data.message) {
        toastContext.addToast({
          status: "Error",
          message: data.data.message,
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ["/admin_new/data/admins"],
        });
        toastContext.addToast({
          status: "Success",
          message: "Admin created successfully",
        });
        setAdminToCreate({
          account_type: "school",
          districtID: adminParams.districtID,
          first: "",
          last: "",
          email: "",
          schoolIDs: [school_id], // [school.schoolid],
          schoolid: school_id, // school.schoolid
        });
      }
    },
    onError() {
      toastContext.addToast({
        status: "Error",
        message: "There was an error inviting your admin",
      });
    },
  });

  useEffect(() => {
    setCreateAdminDisabled(true);
    if (
      adminToCreate.account_type &&
      adminToCreate.email &&
      adminToCreate.first &&
      adminToCreate.last &&
      adminToCreate.schoolIDs &&
      adminToCreate.schoolid
    ) {
      const regex =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (regex.test(adminToCreate.email)) {
        setCreateAdminDisabled(false);
      }
    }
  }, [adminToCreate]);

  return (
    <>
      <div className="rounded-md border bg-white">
        <div className="border-b px-6 py-4 sm:px-6">
          <h3 className="text-lg font-medium leading-6 text-gray-900">
            Invite School Administrator
          </h3>
        </div>

        <div className="grid grid-cols-3 gap-3 space-y-4 px-6 py-6 sm:p-6">
          <div className="col-span-3 grid grid-cols-3">
            <div className="col-span-3 sm:col-span-1">
              <label
                htmlFor="first-name"
                className="block text-sm font-medium text-gray-700"
              >
                First Name
              </label>
              <input
                type="text"
                name="first-name"
                id="first-name"
                autoComplete="off"
                value={adminToCreate.first}
                onChange={(e) => {
                  e.preventDefault();
                  setAdminToCreate({
                    ...adminToCreate,
                    first: e.target.value,
                  });
                }}
                /**fixed width seems bad but i'm not sure what the desired effect should be with various screen sizes */
                className="focus:dm-brand-blue-500 mt-1 block w-[275px] rounded-md border border-dm-gray-200 focus:outline-none focus:ring-dm-brand-blue-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="col-span-3 grid grid-cols-3">
            <div className="col-span-3 sm:col-span-1">
              <label
                htmlFor="region"
                className="block text-sm font-medium text-gray-700"
              >
                Last Name
              </label>
              <input
                type="text"
                name="lastName"
                id="lastName"
                autoComplete="off"
                value={adminToCreate.last}
                onChange={(e) => {
                  e.preventDefault();
                  setAdminToCreate({
                    ...adminToCreate,
                    last: e.target.value,
                  });
                }}
                className="col-span-1 mt-1 block w-[275px] rounded-md border border-dm-gray-200 focus:border-dm-brand-blue-500 focus:outline-none focus:ring-dm-brand-blue-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="col-span-3 grid grid-cols-3">
            <div className="col-span-3 sm:col-span-1">
              <label
                htmlFor="email"
                className="block text-sm font-medium text-gray-700"
              >
                Email Address
              </label>
              <input
                type="text"
                name="email"
                id="email"
                autoComplete="off"
                value={adminToCreate.email}
                onChange={(e) => {
                  e.preventDefault();
                  setAdminToCreate({
                    ...adminToCreate,
                    email: e.target.value,
                  });
                }}
                className="mt-1 block w-[275px] rounded-md border border-dm-gray-200 shadow-sm focus:border-dm-brand-blue-500 focus:outline-none focus:ring-dm-brand-blue-500 sm:text-sm"
              />
            </div>
          </div>
          <div className="col-span-3 grid grid-cols-3">
            <div className="col-span-3 sm:col-span-1">
              <button
                type="submit"
                disabled={createAdminDisabled}
                onClick={() => {
                  adminToCreate.first = adminToCreate.first.trim();
                  adminToCreate.last = adminToCreate.last.trim();
                  createAdmin.mutate({ ...adminToCreate });
                }}
                className="inline-flex w-[275px] justify-center rounded-md border border-transparent bg-dm-brand-blue-500 px-4 py-3 text-sm font-bold text-white shadow-sm hover:bg-dm-brand-blue-500 focus:outline-none focus:ring-2 focus:ring-dm-brand-blue-500 focus:ring-offset-2 disabled:opacity-50"
              >
                Send Invite
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
