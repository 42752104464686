import { format } from "date-fns";
import { LearnerSubscriptionDetails } from "../../../types";
import { ProfileField } from "../ProfileField";
import { formatPrice } from "../../../utils/formatPrice";
import { getLearnerAccountStatus } from "../getLearnerAccountStatus";
import { useLearnerContext } from "../../../contexts/LearnerContext";

export const UpcomingChargesField: React.FC<{
  subscription: LearnerSubscriptionDetails | undefined;
}> = ({ subscription }) => {
  const { learner } = useLearnerContext();

  if (!subscription) {
    return null;
  }

  const { discount } = subscription;
  const status = learner ? getLearnerAccountStatus(learner) : null;
  const noUpcomingCharges = status === "canceled" || status === "inactive";

  return !noUpcomingCharges ? (
    <ProfileField label="Upcoming charges">
      <span>{format(new Date(subscription.periodEnd), "MM/dd/yy")}</span>
      <div className="flex items-center gap-2">
        <span className="font-serif text-lg font-bold">
          {discount
            ? formatPrice({
                value:
                  subscription.amount.value *
                  ((100 - discount.percentOff) / 100),
                currency: subscription.amount.currency,
              })
            : formatPrice(subscription.amount)}
        </span>
        {discount && discount.numMonths && (
          <span className="italic">Limited time offer</span>
        )}
      </div>
    </ProfileField>
  ) : null;
};
