import { useState } from "react";
import { IRenewal } from "../../utils/quoteUtils";
import { EmptyCell } from "../DataTable/EmptyCell";
import Button from "../../../student/components/generic/button";

export const MetadataCell: React.FC<
  { meta: IRenewal["renewalQuoteMetaData"] } | undefined
> = ({ meta }) => {
  const filteredMeta = meta?.filter((m) => m.quoteNumber) ?? [];

  const head = filteredMeta.slice(0, 2);
  const tail = filteredMeta.slice(2);
  const [showTail, setShowTail] = useState(false);

  if (filteredMeta.length === 0) {
    return <EmptyCell />;
  }

  return (
    <ul>
      {head.map((m) => (
        <li key={`${m.quoteNumber}-${m.action}-${m.when}`}>
          {m.action}, {m.quoteNumber}, {new Date(m.when).toLocaleString()}
        </li>
      ))}
      {tail.length > 0 && !showTail && (
        <li>
          <Button type="link" onClick={() => setShowTail(true)}>
            +{tail.length} more
          </Button>
        </li>
      )}
      {showTail && tail.length > 0 && (
        <>
          {tail.map((m) => (
            <li key={`${m.quoteNumber}-${m.action}-${m.when}`}>
              {m.action}, {m.quoteNumber}, {new Date(m.when).toLocaleString()}
            </li>
          ))}
          <li>
            <Button type="link" onClick={() => setShowTail(false)}>
              Collapse
            </Button>
          </li>
        </>
      )}
    </ul>
  );
};
