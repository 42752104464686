import {
  closestCenter,
  DndContext,
  DragEndEvent,
  PointerSensor,
  PointerSensorOptions,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { TableSettingsButton } from "./TableSettingsButton";
import { deltamathAPI } from "../../utils";
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { Menu } from "@headlessui/react";
import { restrictToVerticalAxis } from "@dnd-kit/modifiers";
import { SortableColumnItem } from "./SortableColumnItem";
import { PointerEvent } from "react";
import clsx from "clsx";
import { useDataTable } from "./DataTableContext";

// This is a pointer sensor that allows for interacting with inputs within the
// sortable list
export class InputFriendlyPointerSensor extends PointerSensor {
  static activators = [
    {
      eventName: "onPointerDown" as const,
      handler: (
        { nativeEvent: event }: PointerEvent,
        { onActivation }: PointerSensorOptions
      ) => {
        if (
          !event.isPrimary ||
          event.button !== 0 ||
          event.target instanceof HTMLInputElement ||
          event.target instanceof HTMLLabelElement
        ) {
          return false;
        }

        onActivation?.({ event });

        return true;
      },
    },
  ];
}

export const TableSettingsBar: React.FC = () => {
  const {
    source,
    toggleExpanded,
    isExpanded,
    table,
    externalFilterBy,
    isGlobalFilterEnabled,
  } = useDataTable();

  const sensors = useSensors(useSensor(InputFriendlyPointerSensor));

  const handleDownload = () => {
    const url = new URL(`${deltamathAPI()}${source}`);
    url.searchParams.append("format", "csv");
    if (table.state.filters.length > 0 || externalFilterBy.length > 0) {
      url.searchParams.append(
        "filters",
        JSON.stringify(
          [...table.state.filters, ...externalFilterBy].reduce(
            (acc, { id, value }) => {
              return { ...acc, [id]: { type: "regex", value } };
            },
            {}
          )
        )
      );
    }

    const link = document.createElement("a");
    link.href = url.href;
    link.click();
  };

  const handleDragEnd = (e: DragEndEvent) => {
    const { active, over } = e;
    table.setColumnOrder(
      arrayMove(
        active.data.current?.sortable.items,
        active.data.current?.sortable.index,
        over?.data.current?.sortable.index
      )
    );
  };

  return (
    <Menu>
      {({ open }) => (
        <div
          className={clsx(
            "flex w-full justify-between gap-2",
            isExpanded
              ? "sticky left-0 top-0 bg-dm-charcoal-500/80 px-4 py-2"
              : "sticky top-0 bg-dm-charcoal-100/70 px-4 py-2",
            isExpanded && open ? "z-50" : !isExpanded ? "z-10" : ""
          )}
        >
          <div>
            {isGlobalFilterEnabled && (
              <label
                className={clsx(
                  "flex items-center gap-2 rounded border bg-white px-2 text-sm focus-within:ring-1",
                  isExpanded
                    ? "border-dm-charcoal-500 ring-dm-charcoal-200 focus-within:border-dm-charcoal-600"
                    : "border-dm-charcoal-200 ring-dm-brand-blue-500 focus-within:border-dm-brand-blue-500"
                )}
              >
                <i className="fal fa-globe" title="Search multiple fields" />
                <span className="sr-only">Global search</span>
                <input
                  className="h-7 border-0 p-0 text-sm focus:ring-0"
                  type="text"
                  value={table.state.globalFilter ?? ""}
                  placeholder="Search multiple fields"
                  onChange={(e) => table.setGlobalFilter(e.target.value)}
                />
              </label>
            )}
          </div>
          <div className="flex items-end gap-2">
            {(table.state.filters.length > 0 || table.state.globalFilter) && (
              <TableSettingsButton
                onClick={() => {
                  table.setAllFilters([]);
                  table.setGlobalFilter("");
                }}
              >
                <i className="fas fa-filter" />
                Clear filters
              </TableSettingsButton>
            )}
            <TableSettingsButton onClick={handleDownload}>
              <i className="fas fa-file-download" />
              Download CSV
            </TableSettingsButton>
            <div className="relative">
              <Menu.Button as={TableSettingsButton}>
                <i className="fas fa-columns" />
                Columns
                {table.state.hiddenColumns &&
                  table.state.hiddenColumns.length > 0 && (
                    <>
                      <span className="absolute right-[3px] top-[3px] h-[6px] w-[6px] rounded-full bg-dm-warning-800/70" />
                      <span className="-ml-1 inline-block w-0" />
                    </>
                  )}
              </Menu.Button>
              <Menu.Items className="absolute right-0 top-full z-50 mt-1 w-64 rounded border border-dm-charcoal-200 bg-white px-4 py-2 shadow-lg">
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={handleDragEnd}
                  modifiers={[restrictToVerticalAxis]}
                  autoScroll={false}
                >
                  <SortableContext
                    items={table.allColumns}
                    strategy={verticalListSortingStrategy}
                  >
                    {table.allColumns.map((column) => (
                      <SortableColumnItem key={column.id} column={column} />
                    ))}
                  </SortableContext>
                </DndContext>
              </Menu.Items>
            </div>
            <TableSettingsButton onClick={toggleExpanded} active={isExpanded}>
              {isExpanded ? (
                <i className="fas fa-compress" />
              ) : (
                <i className="fas fa-expand" />
              )}
            </TableSettingsButton>
          </div>
        </div>
      )}
    </Menu>
  );
};
