import { useState } from "react";
import Button from "../../../../student/components/generic/button";
import Modal from "../../../../student/components/generic/Modal";
import { useSkipPreQuizMutation } from "../../../utils/index";
import { ResponsiveImage } from "../../ResponsiveImage";
import { getFilePath } from "../../../../utils";
import { ComponentTimer } from "../../../utils/useComponentTimer";

type Props = {
  courseId: string;
  unitId: string;
  subunitId: string;
  started?: boolean;
  callback: () => void;
  className?: string;
  testTimer?: ComponentTimer;
};

const SkipPreQuiz = (props: Props) => {
  const [skipClicked, setSkipClicked] = useState<boolean>(false);

  const skipPreQuizMutation = useSkipPreQuizMutation(props.testTimer);

  const skip = async () => {
    setSkipClicked(true);
    await skipPreQuizMutation(props.courseId, props.subunitId);
    props.callback();
    setSkipClicked(false);
  };

  const [showModal, setShowModal] = useState<boolean>(false);

  const closeModal = () => {
    setShowModal(false);
  };

  const title = props.started
    ? "Your pre-quiz is incomplete. Are you sure you want to submit?"
    : "Are you sure you want to skip your pre-quiz?";

  const bodyText = props.started
    ? "Submitting now will end this quiz and impact your score. Submitting an incomplete quiz means your practice problems will not be personalized for this section. Once submitted you will not be able to retake this quiz."
    : "If you skip the pre-quiz, your practice questions won't be personalized for this section. Note that skipping will end this session and you won't be able to retake the pre-quiz later. Are you sure you want to skip?";

  const buttonText = props.started
    ? "End and Submit Pre-Quiz"
    : "Skip Pre-Quiz";

  const imageFile = props.started
    ? "submit-incomplete-prequiz"
    : "skip-prequiz-you-sure";
  return (
    <>
      <Button
        type="link"
        onClick={() => setShowModal(true)}
        className={`cursor-pointer ${props.className}`}
      >
        {buttonText}
      </Button>
      <Modal
        visible={showModal}
        onClose={closeModal}
        onConfirm={skip}
        title={
          <>
            <ResponsiveImage
              className="mx-auto h-auto w-56"
              srcs={[
                getFilePath(`/images/learner/prequiz/${imageFile}.png`),
                getFilePath(`/images/learner/prequiz/${imageFile}@2x.png`),
              ]}
              alt="Skip Pre-Quiz"
              aria-hidden="true"
            />
            <span className="mt-4 block font-serif text-2xl font-bold">
              {title}
            </span>
          </>
        }
        confirmButtonText={buttonText}
        body={<span className="text-sm">{bodyText}</span>}
        confirmDisabled={skipClicked}
        confirmAriaLabel={buttonText}
        secondaryButtonText="Back To Quiz"
        secondaryAriaLabel="Back To Quiz"
        secondaryOnClick={() => {
          closeModal();
        }}
      />
    </>
  );
};

export default SkipPreQuiz;
