import { AnalyticsPlugin } from "analytics";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import { withJsonHeader } from "../../axiosUtils";
import { AnalyticsEvent } from "../types";

/**
 * Track an event for Brevo. Sends data to the backend which will then send it
 * to Brevo.
 *
 * Brevo has a frontend API, but using it is not currently feasible. To be able
 * to send events we have to identify the user. However, identifying a user
 * sets a cookie that contains a UUID, and Brevo adds that UUID to every
 * request the frontend sends. And it never resets the cookie. If users
 * share a computer (as a parent and child might), this can cause users to
 * overwrite each other's contact info and send events to the wrong user. We
 * don't have much visibility into their API, so we were never able to
 * completely remove all cases of this. Brevo has acknowledged this as a bug,
 * but they haven't fixed it yet. Until they do (or maybe forever), we'll be
 * sending events to the backend where we can more accurately attribute events
 * to the current user.
 */
async function trackEvent(event: string, properties: Record<string, any>) {
  const user = JSON.parse(localStorage.getItem("user") || "null");
  const isLearnerLoggedIn =
    user &&
    (user.entitlements?.includes("parent") ||
      user.entitlements?.includes("learner"));

  // Logged out events not supported
  if (!isLearnerLoggedIn) {
    return;
  }

  // Super basic email check. Backend has a much more thorough check.
  if (!/^.+@.+\..+$/.test(user.email)) {
    return;
  }

  await axios.post(
    `${deltamathAPI()}/a/l/brevo`,
    {
      event,
      properties: {
        href: window.location.href,
        host: window.location.host,
        pathname: window.location.pathname,
        hash: window.location.hash,
        search: window.location.search,
        ...properties,
      },
    },
    withJsonHeader()
  );
}

/**
 * This is an Analytics plugin that implements an interface for sending events
 * to Brevo
 * @see https://getanalytics.io/
 * @see https://getanalytics.io/plugins/writing-plugins/
 */
export const BREVO_PLUGIN: AnalyticsPlugin = {
  name: "brevo",

  initialize: () => {
    // Nothing to initialize
  },

  page: () => {
    // Page events no longer supported
  },

  track: ({ payload }: { payload: AnalyticsEvent<any> }) => {
    trackEvent(payload.event, payload.properties);
  },

  identify: () => {
    // No frontend identification.
  },

  loaded: () => true,

  reset: () => {
    // Nothing to reset
  },
};
