import { useState } from "react";
import { useDeltaToastContext } from "../../../../shared/contexts/ToasterContext";
import Modal from "../../generic/Modal";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { deltamathAPI } from "../../../../manager/utils";
import { withJsonHeader } from "../../../../shared/axiosUtils";
import { replaceModuleCodeSlashes } from "../utils";

type SharedModuleType = {
  code: string;
  html: Record<string, string>;
  skillcode: string;
  programmer_name: string;
  timed: boolean;
};

export const SharedModule: React.FC<{ module: SharedModuleType }> = ({
  module,
}) => {
  const toastContext = useDeltaToastContext();
  const [showModal, setShowModal] = useState(false);

  const uploadModule = useMutation({
    mutationFn: (body: {
      _id: string;
      code: string;
      html: Record<string, string>;
    }) => {
      return axios.post(
        `${deltamathAPI()}/programmer/upload/shared`,
        JSON.stringify(body),
        withJsonHeader()
      );
    },
    onSuccess: () => {
      const message = "Shared module code successfully uploaded!";
      toastContext.addToast({
        message,
        status: "Success",
      });
    },
    onError: () => {
      const message =
        "An error occurred while uploading the shared module code. Please try again.";
      toastContext.addToast({
        message,
        status: "Error",
      });
    },
  });

  const onClose = () => setShowModal(false);

  const handleUpload = () => {
    const { skillcode, code, html } = module;
    const _id = `shared/${skillcode}`;
    const codeToUpload = code.includes("SKIP_REPLACE_SLASHES")
      ? code
      : replaceModuleCodeSlashes(module.code);
    uploadModule.mutate({
      _id,
      code: codeToUpload,
      html,
    });
    onClose();
  };

  return (
    <>
      <div className="relative mt-10 rounded-lg border border-dm-charcoal-100 bg-white px-2 py-9 sm:px-5 md:px-9">
        <h4 className="font-serif text-lg font-bold">
          Skillcode: {module.skillcode}
        </h4>
        <div className="mt-4 flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <h5 className="font-serif text-base font-bold">Custom File</h5>
            <pre className="text-wrap bg-gray-100">
              {module.code?.toString()}
            </pre>
          </div>
          {Object.keys(module.html).map((key) => (
            <div key={key} className="flex flex-col gap-2">
              <h5 className="font-serif text-base font-bold">
                HTML File: {key}
              </h5>
              <pre className="text-wrap bg-gray-100">
                {module.html[key].toString()}
              </pre>
            </div>
          ))}
        </div>
      </div>
      <div className="mt-6">
        <button
          className="inline-flex justify-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 disabled:bg-gray-400"
          onClick={() => setShowModal(true)}
        >
          Upload Shared Module Code
        </button>
        <Modal
          visible={showModal}
          onClose={onClose}
          title="Confirm Shared Module Code Upload"
          body={"Are you sure you want to upload this shared module code?"}
          noLine
          confirmButtonText="Yes"
          onConfirm={handleUpload}
          secondaryButtonText="Cancel"
          secondaryOnClick={onClose}
          renderMath
        />
      </div>
    </>
  );
};
