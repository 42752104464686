import { EmptyCell } from "./EmptyCell";

export const BooleanCell: React.FC = ({ value }: any) =>
  value === true ? (
    <i className="fas fa-check" />
  ) : value === false ? (
    <i className="fas fa-times" />
  ) : (
    <EmptyCell />
  );
