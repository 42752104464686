import { useQuery } from "@tanstack/react-query";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import { Learner } from "../../types";
import {
  executeQuery,
  getFilePath,
  REACT_APP_HOMEPAGE_LINK,
  REACT_APP_LEARNER_LINK,
} from "../../../utils";
import { DmLoadingSpinner } from "../../../manager/utils/functions";
import { PropsWithChildren, useCallback, useEffect, useState } from "react";
import { ResponsiveImage } from "../ResponsiveImage";
import { GoogleLoginButton } from "../../../manager/components/GoogleLoginButton";
import { processLoginResponse } from "../../../utils/auth/processLoginResponse";
import { useUserContext } from "../../../shared/contexts/UserContext";
import { AcceptInviteForm } from "./AcceptInviteForm";
import { useLearnerDocumentTitle } from "../../utils/useLearnerDocumentTitle";

const InvitePageContent: React.FC<PropsWithChildren<unknown>> = ({
  children,
}) => {
  return (
    <main className="flex min-h-dvh w-full flex-col items-center">
      <header className="mb-14 w-full px-14 py-6">
        <img
          src={getFilePath("/images/DeltaMath-Logo_Home.svg")}
          alt="DeltaMath for Home"
        />
      </header>

      <div className="flex flex-grow flex-col">
        <h1 className="mb-10 text-center font-serif text-2xl font-bold text-dm-brand-blue-600">
          Complete Account Sign Up
        </h1>

        {children}
      </div>

      <footer className="flex w-full justify-end">
        <ResponsiveImage
          srcs={[getFilePath("/images/learner/invite/deltie-waving.png")]}
          alt="Deltie waving hello"
          aria-hidden
        />
      </footer>
    </main>
  );
};

export const InvitePage: React.FC = () => {
  useLearnerDocumentTitle("Complete Account Sign Up");
  const userContext = useUserContext();
  const { inviteCode } = useParams();
  const navigate = useNavigate();
  const [googleLoginError, setGoogleLoginError] = useState<string | null>(null);

  const user = JSON.parse(localStorage.getItem("user") || "{}");

  const {
    data: inviteData,
    isLoading,
    error,
  } = useQuery<{ learner: Learner }>({
    queryKey: ["invite/get"],
    queryFn: () =>
      executeQuery({
        path: "/learner/default/invite/get",
        params: { inviteCode },
      }),
    refetchOnWindowFocus: false,
    retry: false,
  });

  const handleGoogleLogin = useCallback(
    (loginData: unknown) => {
      const { success } = processLoginResponse(loginData, userContext, false);
      if (success) {
        navigate(`${REACT_APP_LEARNER_LINK}/dashboard`);
      } else {
        setGoogleLoginError(
          "Unable to login with that Google account. Please ensure that your email address above is correct."
        );
      }
    },
    [navigate, userContext]
  );

  useEffect(() => {
    if (user?.entitlements?.length) {
      navigate(`${REACT_APP_LEARNER_LINK}/dashboard`);
    }
  }, [navigate, user]);

  if (isLoading) {
    return (
      <InvitePageContent>
        <div className="mt-12 flex justify-center">
          <DmLoadingSpinner message="" />
        </div>
      </InvitePageContent>
    );
  }

  if (error) {
    return (
      <InvitePageContent>
        <div className="mt-10 w-full rounded bg-dm-error-100 px-6 py-4 text-center text-dm-error-600">
          {(error as any).message}
        </div>
      </InvitePageContent>
    );
  }

  return (
    <InvitePageContent>
      <div className="flex w-[300px] max-w-full flex-col gap-6">
        {inviteData?.learner && (
          <AcceptInviteForm learner={inviteData.learner} />
        )}
        <span className="text-center text-sm font-bold">Or</span>
        <div>
          <GoogleLoginButton
            onLogin={handleGoogleLogin}
            accountType="learner"
          />
          {googleLoginError && (
            <p className="mt-2 w-full text-center text-sm text-dm-error-500">
              {googleLoginError}
            </p>
          )}
        </div>
        <div className="flex flex-col gap-4 text-center">
          <p className="text-sm font-bold">
            Already have an account?{" "}
            <NavLink
              className="text-dm-brand-blue-500 underline-offset-2 hover:underline"
              to={`${REACT_APP_LEARNER_LINK}/sign-in`}
            >
              Log In
            </NavLink>
          </p>
          <p className="text-xs text-dm-charcoal-500">
            By signing up, you accept DeltaMath for Home
            <br />
            <a
              className="underline underline-offset-2 hover:no-underline"
              href={`${REACT_APP_HOMEPAGE_LINK}/terms-policies`}
              target="_blank"
              rel="noreferrer"
            >
              Terms & Policies
            </a>{" "}
          </p>
        </div>
      </div>
    </InvitePageContent>
  );
};
