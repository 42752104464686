import { FuseResult } from "fuse.js";

export const fuseOptions = {
  threshold: 0.4,
  ignoreLocation: true,
  ignoreFieldNorm: true,
  includeScore: true,
  minMatchCharLength: 2,
  includeMatches: true,
};

export function createScoreDistribution(
  results: FuseResult<unknown>[]
): Map<number, number> {
  return results.reduce((acc: Map<number, number>, result) => {
    const score = result.score ?? 0;
    const roundedScore = Math.round(score * 1000) / 1000;
    acc.set(roundedScore, (acc.get(roundedScore) ?? 0) + 1);
    return acc;
  }, new Map<number, number>());
}

export function findShortestBestList(
  results: FuseResult<unknown>[],
  idealMin: number,
  thresholds: number[]
): unknown[] {
  if (results.length < idealMin) {
    return results.map((result) => result.item);
  }

  for (const threshold of thresholds) {
    const bestList = results.filter(
      (result) => (result.score || 0) <= threshold
    );
    if (bestList.length >= idealMin) {
      return bestList.map((result) => result.item);
    }
  }

  return [];
}
