import lunr, { Token } from "lunr";
import {
  charPrefixOrSuffixExceptions,
  charWithinStrExceptions,
  commonWildcardTokens,
  mathPhrases,
  wordToNumber,
} from "../../utils/searchPrototypeData";

export function lunrNumberPlugIn(builder: lunr.Builder) {
  const pipelineFunction = function (currToken: Token) {
    const currTokenStr = currToken.toString();
    const isNumber = Object.hasOwn(wordToNumber, currTokenStr);
    if (isNumber) {
      const updatedToken = currToken.update(() => wordToNumber[currTokenStr]);
      return updatedToken;
    }
    return currToken;
  };
  lunr.Pipeline.registerFunction(pipelineFunction, "handleNumbers");
  builder.pipeline.after(lunr.stemmer, pipelineFunction);
  builder.searchPipeline.after(lunr.stemmer, pipelineFunction);
}

export function lunrCustomTrimmerPlugIn(builder: lunr.Builder) {
  lunr.trimmer = function (token: Token) {
    return token.update(function (s) {
      const hasPrefixOrSuffixException = Object.hasOwn(
        charPrefixOrSuffixExceptions,
        s
      );
      if (hasPrefixOrSuffixException) {
        return s;
      }

      const trimmedStr = s
        .replace(/^\W+/, "")
        .replace(/\W+$/, "")
        .replace(/('s)$/, "");

      const hasWithinStrException = Object.hasOwn(
        charWithinStrExceptions,
        trimmedStr
      );
      if (hasWithinStrException) {
        return charWithinStrExceptions[trimmedStr];
      }

      return trimmedStr;
    });
  };

  lunr.Pipeline.registerFunction(lunr.trimmer, "customTrimmer"); // replaces the default trimmer for the index pipeline
  builder.searchPipeline.before(lunr.stemmer, lunr.trimmer); // adds the custom trimmer to the search pipeline
}

export function lunrSearch(
  query: string,
  index: lunr.Index
): lunr.Index.Result[] {
  return index.query(function (q) {
    const tokens = lunr.tokenizer(query);
    tokens.forEach((currToken: Token, i: number) => {
      let currTokenStr = currToken.toString();
      let required = false;
      let isWildcard = false;

      if (currTokenStr.startsWith("+") && currTokenStr.length > 1) {
        required = true;
        currTokenStr = currTokenStr.slice(1);
      } else {
        const mathPhraseStart = Object.keys(mathPhrases).find((key) =>
          currTokenStr.startsWith(key.toLowerCase())
        );
        if (mathPhraseStart) {
          // Search remaining tokens for the math phrase
          const restOfPhrase = mathPhrases[mathPhraseStart];
          const restOfPhraseTokens = tokens.slice(i).filter((token: Token) => {
            const mathPhraseEnd = restOfPhrase.find((word) =>
              token.toString().startsWith(word.toLowerCase())
            );
            return !!mathPhraseEnd;
          });
          if (restOfPhraseTokens.length) {
            restOfPhraseTokens.forEach((token: Token) => {
              token.update(() => "+" + token.toString());
            });
            required = true;
          }
        }
        if (!required) {
          const wildcard = commonWildcardTokens.find((key) =>
            currTokenStr.startsWith(key)
          );
          if (wildcard) {
            currTokenStr = wildcard;
            isWildcard = true;
          }
        }
      }

      const settings = {
        presence: required
          ? lunr.Query.presence.REQUIRED
          : lunr.Query.presence.OPTIONAL,
        ...(isWildcard
          ? {
              wildcard: lunr.Query.wildcard.TRAILING,
              usePipeline: false,
            }
          : {}),
      };
      q.term(currTokenStr, settings);
    });
  });
}

export function whitelistPlugin(builder: lunr.Builder, whitelist: string[]) {
  function stopWordWhitelist(token: lunr.Token) {
    // pass any token in the whitelist through
    if (whitelist.includes(token.toString())) return token;
    // all other tokens should be passed through the normal filter
    return lunr.stopWordFilter(token);
  }

  lunr.Pipeline.registerFunction(stopWordWhitelist, "stopWordWhitelist");

  builder.pipeline.before(lunr.stopWordFilter, stopWordWhitelist);
  builder.pipeline.remove(lunr.stopWordFilter);
}

export const whitelistStopWords = ["least", "like", "likely"];

lunr.tokenizer.separator = /[\s\-/]+/;
