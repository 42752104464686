import Modal from "../../../student/components/generic/Modal";
import { executeQuery } from "../../../utils";
import {
  FormattedLearnerSubscriptionPlan,
  LearnerSubscriptionDetails,
  QuantityChangePreviewResponse,
} from "../../types";
import { formatPrice } from "../../utils/formatPrice";
import { format } from "date-fns";
import { useEffect, useState } from "react";
import { PricingQuantityInput } from "../Home/Payment/PricingQuantityInput";
import { useQuery } from "@tanstack/react-query";
import { useDebounceValue } from "usehooks-ts";
import { DmLoadingSpinner } from "../../../manager/utils/functions";
import clsx from "clsx";
import { capitalize } from "lodash";

export const AddLearnerModal: React.FC<{
  visible: boolean;
  onClose: () => void;
  subscription: LearnerSubscriptionDetails;
  plan: FormattedLearnerSubscriptionPlan;
  onConfirm: (q: number) => Promise<void>;
}> = ({ visible, onClose, subscription, plan, onConfirm }) => {
  const [numToAdd, setNumToAdd] = useState(1);
  const [numToAddForQuery, setNumToAddForQuery] = useDebounceValue(1, 500);
  const [isConfirming, setIsConfirming] = useState(false);

  // Use explicit state for previewData to avoid resetting to undefined when
  // loading a new quantity
  const [previewData, setPreviewData] = useState<
    QuantityChangePreviewResponse | undefined
  >(undefined);

  const newQuantity = subscription.quantity + numToAdd;

  const handleQuantityChange = (n: number) => {
    setNumToAdd(n);
    setNumToAddForQuery(n);
  };

  const {
    isLoading: isLoadingPreviewData,
    isRefetching: isRefetchingPreviewData,
    isError,
    isSuccess: previewSuccess,
    data: previewDataReturn,
  } = useQuery<QuantityChangePreviewResponse>({
    queryKey: ["quantity_preview", numToAddForQuery],
    queryFn: () =>
      executeQuery({
        path: "/payments/subscriptions/update/quantity/preview",
        params: { quantity: newQuantity },
      }),
    enabled: visible,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    if (previewSuccess) {
      setPreviewData(previewDataReturn);
    }
  }, [previewSuccess, previewDataReturn]);

  const formattedProratedAmount = formatPrice(
    previewData ? previewData.proratedAmount : { value: 0, currency: "USD" }
  );
  const formattedFullAmount = formatPrice({
    value:
      plan.learnerPrice.value +
      plan.additionalLearnerPrice.value * (newQuantity - 1),
    currency: plan.learnerPrice.currency,
  });
  const formattedBillingDate = format(
    new Date(subscription.periodEnd),
    "MMMM d, yyyy"
  );

  const handleConfirm = async () => {
    setIsConfirming(true);
    await onConfirm(newQuantity);
    setIsConfirming(false);
  };

  return (
    <Modal
      visible={visible}
      onClose={onClose}
      closeX
      body={
        <div className="flex flex-col gap-10">
          <h2 className="text-center font-serif text-2xl font-bold text-dm-brand-blue-600">
            Add a Learner
          </h2>
          <div className="flex flex-col gap-6">
            {isError ? (
              <p className="py-8 text-center text-dm-error-500">
                There was an error fetching your subscription details.
              </p>
            ) : !previewData ? (
              <div className="flex h-48 w-full flex-col items-center justify-center">
                <DmLoadingSpinner message="" />
              </div>
            ) : (
              <>
                <p
                  className={clsx(
                    (isLoadingPreviewData || isRefetchingPreviewData) &&
                      "opacity-50"
                  )}
                >
                  You&apos;re adding {numToAdd} learner(s). A prorated charge of{" "}
                  <b>{formattedProratedAmount}</b> will be applied today. From{" "}
                  {formattedBillingDate}, your subscription will renew at{" "}
                  <b>{formattedFullAmount}</b> per {subscription.interval}. Are
                  you sure you want to continue?
                </p>
                <div className="flex flex-col justify-between gap-4 sm:flex-row sm:items-center">
                  <PricingQuantityInput
                    numLearners={numToAdd}
                    onChange={handleQuantityChange}
                    title="How Many New Learners?"
                    subtext={`Additional Learners: ${formatPrice(
                      plan.additionalLearnerPrice
                    )}/${plan.interval === "monthly" ? "mo" : "yr"} each`}
                    incrementorType="blue"
                  />

                  <div className="relative w-full rounded-2xl border-[3px] border-dm-brand-blue-500 bg-white p-6 text-dm-charcoal-800 sm:w-[260px]">
                    <h5 className="mb-4 text-base font-bold">
                      New {capitalize(plan.interval)} Subscription
                    </h5>
                    <div className="flex items-baseline">
                      <span className="font-serif text-3xl font-bold !leading-10">
                        {formattedFullAmount}
                      </span>
                      <span>
                        /{plan.interval === "monthly" ? "month" : "year"}
                      </span>
                    </div>
                    <p className="text-sm !leading-[26px] text-dm-charcoal-500">
                      billed {plan.interval === "monthly" ? "month" : "year"}{" "}
                      for {newQuantity}{" "}
                      {newQuantity === 1 ? "learner" : "learners"}
                    </p>
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      }
      secondaryButtonText="Cancel"
      secondaryOnClick={onClose}
      confirmButtonText={`Pay ${formattedProratedAmount} Now`}
      confirmDisabled={!previewData || isRefetchingPreviewData || isConfirming}
      onConfirm={handleConfirm}
    />
  );
};
