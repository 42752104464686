import { SearchPlayground } from "../components/SearchPrototype/SearchPlayground";

export const SearchPrototype: React.FC = () => {
  return (
    <div className="relative flex max-h-[calc(100vh-64px)] gap-4 bg-white px-4">
      <div id="modal-container"></div>
      <SearchPlayground />
    </div>
  );
};
