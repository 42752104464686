import { DuplicateIcon, UploadIcon, XIcon } from "@heroicons/react/outline";
import { Fragment, useContext, useEffect, useState } from "react";
import { copyToClip } from "../../shared/DeltaMathModal";
import { Dialog, Transition } from "@headlessui/react";
import { skillToSolve } from "../utils";
import axios from "axios";
import { useMutation } from "@tanstack/react-query";
import { deltamathAPI } from "../../manager/utils";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import Button from "./generic/button";
import { useUserContext } from "../../shared/contexts/UserContext";
import { QRCodeSVG } from "qrcode.react";
import type * as CSS from "csstype";
import clsx from "clsx";
import { REACT_APP_NOTES_LINK } from "../../utils";
import VideoModal from "../../learner/components/Modals/VideoModal";

const UploadWork = ({
  section,
  buttonType = "none",
  inSidebar = false,
}: {
  section?: { ta: any; sa: any };
  buttonType?: "primary" | "outline" | "none";
  inSidebar?: boolean;
}) => {
  const [uploadOpen, setUploadOpen] = useState<boolean>(false);
  const [deleteOpen, setDeleteOpen] = useState<boolean>(false);

  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();

  const { setLoadingData } = useContext(StudentSectionsContext);
  const solveSkill: any = section ?? skillToSolve();
  const token = userContext.getJWT();

  const [showUploadWorkOptions, setShowUploadWorkOptions] = useState(false);
  const [uploadNotesAssignment, setUploadNotesAssignment] = useState({
    ...solveSkill.sa,
  });
  const [uploadNotesSessionURL, setUploadNotesSessionURL] = useState<string>();

  useEffect(() => {
    checkIfNotesPagesExist.mutate(solveSkill.sa._id);
  }, [solveSkill.sa._id]);

  const checkIfNotesPagesExist = useMutation({
    mutationFn: (assignmentId: number) => {
      return axios.get(
        deltamathAPI() + `/notes/assignment/has_pages/${assignmentId}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      setUploadNotesAssignment({
        ...solveSkill.sa,
        notes_uploaded_meta: {
          pages: data.data.pages,
        },
      });
    },
  });

  const handleInitializeNotesSession = useMutation({
    mutationFn: (assignmentId: number) => {
      return axios.post(
        deltamathAPI() + `/notes/session`,
        JSON.stringify({ assignmentId: assignmentId }),
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    onSuccess: async (data) => {
      setUploadNotesSessionURL(
        window.location.origin +
          REACT_APP_NOTES_LINK +
          "?id=" +
          data?.data?.session_id
      );
      setUploadNotesAssignment({
        ...solveSkill.sa,
        notes_uploaded_meta: data.data.notes_uploaded_meta,
      });
    },
    onError: (error: any) => {
      setLoadingData((prevState: any) => ({
        ...prevState,
        isShowing: true,
        error: true,
        title: "Error",
        message: error?.response?.data?.message,
      }));
      setShowUploadWorkOptions(false);
    },
  });

  const getNotesPdfUrl = useMutation({
    mutationFn: (): any => {
      setLoadingData((prevState: any) => ({
        ...prevState,
        isShowing: true,
        error: false,
      }));
      return axios.get(deltamathAPI() + `/notes/pdfForStudent`, {
        params: { assignmentId: solveSkill.sa._id },
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      });
    },
    onSuccess: async (data: { data: { existingPdfUrl: string } }) => {
      window.location.assign(data.data.existingPdfUrl);
      toastContext.addToast({
        status: "Success",
        message: "Note successfully downloaded.",
      });
    },
    onError: async (message: any) => {
      toastContext.addToast({
        status: "Error",
        message: "Unable to download uploaded work.",
      });
      console.log({ message });
    },
    onSettled: () => {
      setLoadingData((prevState: any) => ({
        ...prevState,
        isShowing: false,
        error: false,
      }));
    },
  });

  const deleteAssignment = useMutation({
    mutationFn: () => {
      setLoadingData((prevState: any) => ({
        ...prevState,
        isShowing: true,
        error: false,
      }));
      return axios.delete(
        deltamathAPI() + `/notes/document/${solveSkill.sa._id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    onSuccess: async () => {
      toastContext.addToast({
        status: "Success",
        message: "Note successfully deleted.",
      });
      setUploadNotesAssignment({
        ...solveSkill.sa,
        notes_uploaded_meta: {
          pages: 0,
        },
      });
    },
    onError: async () => {
      toastContext.addToast({
        status: "Error",
        message: "There was an error removing this note.",
      });
    },
    onSettled: () => {
      setDeleteOpen(false);
      setLoadingData((prevState: any) => ({
        ...prevState,
        isShowing: false,
        error: false,
      }));
    },
  });

  /**
   * create a new upload notes session for the selected assignment
   * @param assignment the selected assignment
   */
  const handleUploadWork = (assignment: any) => {
    setShowUploadWorkOptions(!showUploadWorkOptions);
    setUploadNotesAssignment({ ...assignment });
    handleInitializeNotesSession.mutate(assignment._id);
  };

  const handleOpen = () => {
    setUploadOpen(true);
    handleUploadWork(solveSkill.sa);
  };
  return (
    <>
      {buttonType !== "none" ? (
        <Button
          className="inline-flex items-center gap-x-1.5"
          type={buttonType}
          onClick={() => handleOpen()}
        >
          <UploadIcon className="h-4 w-4" aria-hidden="true" />
          Upload Work
        </Button>
      ) : (
        <div
          className={clsx(
            "inline-flex w-full items-center justify-start gap-x-2 text-sm leading-5 text-dm-gray-600",
            inSidebar ? "p-2" : "mt-2"
          )}
        >
          <div className="inline-flex grow items-center gap-x-2">
            {/* Upload work */}
            <button
              className="inline-flex items-center gap-x-2 text-left text-sm"
              onClick={() => handleOpen()}
            >
              <UploadIcon className="h-6 w-6 shrink-0 text-dm-gray-200" />
              <span>{`Upload ${
                uploadNotesAssignment?.notes_uploaded_meta?.pages ? "More" : ""
              } Work`}</span>
            </button>
            {/* Download work */}
            {uploadNotesAssignment &&
              uploadNotesAssignment.notes_uploaded_meta &&
              (uploadNotesAssignment.notes_uploaded_meta.pages ?? 0) > 0 && (
                <button
                  onClick={() => {
                    getNotesPdfUrl.mutate();
                  }}
                  className="text-left text-sm text-dm-brand-blue-500"
                >
                  {`(${uploadNotesAssignment.notes_uploaded_meta.pages} pages)`}
                </button>
              )}
          </div>
          {/* Delete work */}
          {(uploadNotesAssignment.notes_uploaded_meta?.pages ?? 0) > 0 && (
            <XIcon
              className="h-[22px] w-[22px] text-dm-gray-200 hover:cursor-pointer"
              aria-hidden="true"
              onClick={() => {
                handleInitializeNotesSession.mutate(solveSkill.sa._id);
                setDeleteOpen(!deleteOpen);
              }}
            />
          )}
        </div>
      )}
      {uploadNotesSessionURL && (
        <UploadWorkModal
          uploadUrl={uploadNotesSessionURL}
          isOpen={uploadOpen}
          setIsOpen={setUploadOpen}
          onClose={() => {
            checkIfNotesPagesExist.mutate(solveSkill?.sa?._id);
          }}
        />
      )}
      {(uploadNotesAssignment.notes_uploaded_meta?.pages ?? 0) > 0 && (
        <DeleteWorkModal
          isOpen={deleteOpen}
          setIsOpen={setDeleteOpen}
          confirmAction={() => deleteAssignment.mutate()}
          numPages={uploadNotesAssignment.notes_uploaded_meta.pages}
        />
      )}
    </>
  );
};

export default UploadWork;

const DeleteWorkModal = ({
  isOpen,
  setIsOpen,
  confirmAction,
  numPages,
}: {
  confirmAction: () => void;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  numPages: number;
}) => {
  return (
    <DmModalContainer
      headline={"Warning"}
      message={`Are you sure you want to delete all ${numPages} pages of your uploaded work for this assignment? This cannot be undone.`}
      footer={
        <div className="mt-4 inline-flex w-[100%] justify-between">
          {/* Outline Button */}
          <button
            onClick={() => setIsOpen(false)}
            className="inline-flex gap-2 rounded-md border border-dm-charcoal-800 bg-white px-8 py-2 text-black hover:border-dm-brand-blue-600 hover:text-dm-brand-blue-600"
          >
            Cancel
          </button>
          {/* Primary Button */}
          <button
            onClick={() => confirmAction()}
            className="inline-flex gap-2 rounded-md bg-dm-brand-blue-500 px-8 py-2 text-white hover:bg-dm-brand-blue-600"
          >
            Yes, Delete Work
          </button>
        </div>
      }
      isOpen={isOpen}
      setIsOpen={setIsOpen}
    />
  );
};

const UploadWorkModal = ({
  isOpen,
  setIsOpen,
  uploadUrl,
  onClose,
}: {
  uploadUrl: string;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  onClose?: () => void;
}) => {
  const [isDesktop, setDesktop] = useState(window.innerWidth > 744);

  const updateMedia = () => {
    setDesktop(window.innerWidth > 744);
  };

  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });
  const style: CSS.Properties = {
    height: "180px",
    width: "180px",
    borderRadius: "16px",
    border: "1px solid #E3E8EF",
  };
  const [showVideo, setShowVideo] = useState(false);
  return (
    <>
      <VideoModal
        visible={showVideo}
        onClose={() => setShowVideo(false)}
        skillCode={"Student-Work-Upload"}
        public={false}
      />

      {!showVideo &&
        (isDesktop ? (
          <DmModalContainer
            onClose={onClose}
            headline={
              <span className="w-[100%] text-center">Scan QR Code</span>
            }
            message={
              <>
                <div className="my-4 font-sans">
                  Scan this code to upload your work.
                </div>
                <div>
                  <QRCodeSVG
                    className="border-2 border-black p-4"
                    style={style}
                    value={uploadUrl}
                  />
                </div>
              </>
            }
            footer={
              // correct font color is #1E40AF, which does not exist in tailwind colors currently
              <div className="my-4">
                <a
                  className="font-sans text-dm-brand-blue-500 hover:underline"
                  href={uploadUrl}
                  target="_blank"
                  rel="noreferrer"
                >
                  upload from this device
                </a>
                <p className="mt-6">
                  Need help uploading work?{"  "}
                  <span
                    onClick={() => setShowVideo(true)}
                    className="cursor-pointer font-sans text-dm-brand-blue-500 hover:underline"
                  >
                    Watch our help video
                  </span>
                </p>
              </div>
            }
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            dividerText="Or"
            centerContent={true}
          />
        ) : (
          <DmModalContainer
            onClose={onClose}
            headline={"Upload Work"}
            message={
              <a
                className="font-sans text-dm-brand-blue-500 underline"
                href={uploadUrl}
                target="_blank"
                rel="noreferrer"
              >
                upload from this device
              </a>
            }
            footer={
              <div className="py-8 text-center">
                <span className="font-sans text-dm-gray-200">
                  Scan this code to upload your work.
                </span>
                <div className="mt-4">
                  <QRCodeSVG
                    className="border-2 border-black p-4"
                    style={style}
                    value={uploadUrl}
                  />
                </div>
              </div>
            }
            isOpen={isOpen}
            setIsOpen={setIsOpen}
            dividerText="Or"
            centerContent={true}
          />
        ))}
    </>
  );
};

export function DmModalContainer({
  headline,
  message,
  footer,
  copyToClipboard,
  isOpen,
  setIsOpen,
  dividerText,
  centerContent = false,
  onClose,
}: {
  headline: any;
  message: any;
  footer: any;
  copyToClipboard?: boolean;
  isOpen: boolean;
  setIsOpen: (state: boolean) => void;
  dividerText?: string;
  centerContent?: boolean;
  onClose?: () => void;
}) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-[1010]"
        onClose={() => {
          setIsOpen(false);
          if (onClose) {
            onClose();
          }
        }}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60 transition-opacity" />
        </Transition.Child>

        <div className="fixed inset-x-0 top-32 z-50 overflow-y-auto sm:inset-0">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative w-full transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:max-w-lg sm:p-6">
                <div className="absolute right-0 top-0 py-2 pr-4 sm:py-8 sm:pt-4">
                  <button
                    type="button"
                    className="rounded-md bg-white text-gray-900 hover:text-gray-500 focus:outline-none focus:ring-offset-2"
                    onClick={() => {
                      setIsOpen(false);
                      if (onClose) {
                        onClose();
                      }
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-8 w-8" aria-hidden="true" />
                  </button>
                </div>
                <Dialog.Title
                  as="h3"
                  className={clsx(
                    centerContent ? "text-center" : "",
                    "mt-3 font-serif text-2xl leading-6 text-gray-900 sm:mt-5"
                  )}
                >
                  {headline}
                </Dialog.Title>
                <div
                  className={clsx(centerContent ? "text-center" : "", "mt-2")}
                >
                  <div className="my-4 text-sm" id="foo">
                    {message}
                  </div>
                  {copyToClipboard && (
                    <div
                      className="mt-8 cursor-pointer text-center text-sm hover:text-slate-400"
                      onClick={() => {
                        const contentElement = document.getElementById("foo");
                        if (contentElement) {
                          copyToClip(contentElement.innerHTML);
                        }
                      }}
                    >
                      Copy to Clipboard
                      <DuplicateIcon width="18" className="inline-block" />
                    </div>
                  )}
                </div>
                {dividerText ? (
                  <div className="grid grid-cols-3 justify-center">
                    <span className="col w-150 col-span-1 mb-2 border-b-2"></span>
                    <span className="col col-span-1 text-center">
                      {dividerText}
                    </span>

                    <span className="col w-150 col-span-1 mb-2 border-b-2"></span>
                  </div>
                ) : (
                  <hr />
                )}
                <div className={clsx(centerContent ? "text-center" : "")}>
                  {footer}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
