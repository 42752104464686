import { useState } from "react";
import Button from "../../student/components/generic/button";
import {
  REACT_APP_HOMEPAGE_LINK,
  REACT_APP_LEARNER_LINK,
  useDMQuery,
} from "../../utils";
import { format } from "date-fns/esm";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deltamathAPI } from "../utils";
import axios from "axios";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { LearnerEntitlement } from "../../learner/types";
import { useDocumentTitle } from "usehooks-ts";

type Learner = {
  _id: string;
  first: string;
  last: string;
  email: string;
  password_reset?: {
    expire: number;
    token: string;
  };
  account_type: "learner" | "parent";
  entitlements?: LearnerEntitlement[];
};

const LearnerPasswordReset = () => {
  useDocumentTitle("Learner Password Reset");
  const [userName, setUserName] = useState<string>();
  const [disableButtons, setDisableButtons] = useState<boolean>(false);
  const queryClient = useQueryClient();
  const toastContext = useDeltaToastContext();

  const { data, refetch } = useDMQuery<{
    learner: Learner;
    relatedLearners: Learner[];
  }>({
    path: `/manager_new/parent-portal/learner-by-username/${userName?.toLowerCase()}`,
    queryOptions: {
      refetchOnWindowFocus: false,
      enabled: false,
    },
  });

  const resetPassword = useMutation({
    mutationFn: (body: string) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/parent-portal/reset_learner_password`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [
          `/manager_new/parent-portal/learner-by-username/${userName}`,
        ],
      });
      refetch();
      setDisableButtons(false);
    },
    onError() {
      toastContext.addToast({
        status: "Error",
        message: "Error resetting learner's password",
      });
    },
  });

  const resetLearnerPassword = (id: string) => {
    setDisableButtons(true);
    resetPassword.mutate(JSON.stringify({ id: id }));
  };

  return (
    <div className="px-6">
      <form
        onSubmit={(e) => {
          e.preventDefault();
          refetch();
        }}
      >
        <h2 className="mb-6 pt-10 text-2xl font-bold">
          Learner Password Reset
        </h2>
        <input
          type="text"
          name="learnerUsername"
          id="learnerUserName"
          className="block h-12 w-96 rounded-md border-gray-300 px-2 py-1 text-sm placeholder-gray-300 ring-inset placeholder:text-sm focus:border-dm-brand-blue-500 focus:ring-0 focus:ring-dm-brand-blue-500"
          placeholder="Username or email of learner to reset"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
        />
        <Button
          disabled={!userName || !userName.trim()}
          className="mt-6"
          submit
        >
          Search
        </Button>
      </form>
      {data && data.learner && (
        <div className="mt-6">
          <h2 className="text-xl font-bold">
            {(data.learner.entitlements ?? []).includes("parent")
              ? "Parent"
              : "Learner"}
          </h2>
          <p>
            <b>Name:</b> {data.learner.first} {data.learner.last}
          </p>
          <p>
            <b>Username:</b> {data.learner.email}
          </p>
          <p>
            <b>Entitlements:</b>{" "}
            {data.learner.entitlements
              ? data.learner.entitlements.length
                ? data.learner.entitlements.join(", ")
                : "none"
              : "not set"}
          </p>
          {data.learner.password_reset &&
            data.learner.password_reset.expire > Date.now() && (
              <>
                <h5 className="font-bold">Active Reset Link</h5>
                <p>
                  Expires:{" "}
                  {format(
                    new Date(data.learner.password_reset.expire),
                    "MMM d, h:mm aaa"
                  )}
                </p>
                <p>
                  Link:{" "}
                  {`${REACT_APP_HOMEPAGE_LINK}${REACT_APP_LEARNER_LINK}/sign-in?reset=${data.learner.password_reset.token}`}
                </p>
              </>
            )}
          <Button
            className="mt-2"
            onClick={() => resetLearnerPassword(data.learner._id)}
            disabled={
              disableButtons ||
              (data.learner.password_reset?.expire || 0) > Date.now()
            }
          >
            Reset Password
          </Button>
        </div>
      )}
      {data && data.relatedLearners && data.relatedLearners.length > 0 && (
        <div className="mt-6">
          <h2 className="text-xl font-bold">
            {data.learner.account_type === "learner"
              ? data.relatedLearners.length > 1
                ? "Parents"
                : "Parent"
              : data.relatedLearners.length > 1
              ? "Children"
              : "Child"}
          </h2>
          {data.relatedLearners.map((x) => (
            <div className="mt-4" key={`learner-${x._id}`}>
              <p>
                <b>Name:</b> {x.first} {x.last}
              </p>
              <p>
                <b>Username:</b> {x.email}
              </p>
              <p>
                <b>Entitlements: </b>{" "}
                {x.entitlements
                  ? x.entitlements.length
                    ? x.entitlements.join(", ")
                    : "none"
                  : "not set"}
              </p>
              {x.password_reset && x.password_reset.expire > Date.now() && (
                <>
                  <h5 className="font-bold">Active Reset Link</h5>
                  <p>
                    Expires:{" "}
                    {format(
                      new Date(x.password_reset.expire),
                      "MMM d, h:mm aaa"
                    )}
                  </p>
                  <p>
                    Link:{" "}
                    {`${REACT_APP_HOMEPAGE_LINK}${REACT_APP_LEARNER_LINK}/sign-in?reset=${x.password_reset.token}`}
                  </p>
                </>
              )}
              <Button
                className="mt-2"
                onClick={() => resetLearnerPassword(x._id)}
                disabled={
                  disableButtons || (x.password_reset?.expire || 0) > Date.now()
                }
              >
                Reset Password
              </Button>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LearnerPasswordReset;
