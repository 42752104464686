import { EmptyCell } from "./EmptyCell";

export const PercentageCell: React.FC = ({ value }: any) =>
  value === undefined ? (
    <EmptyCell />
  ) : (
    <>
      <span className="mr-1 inline-block">{Math.round(value * 100)}</span>
      <span className="text-dm-charcoal-500/50">%</span>
    </>
  );
