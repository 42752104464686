import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { useState } from "react";
import clsx from "clsx";
import Button from "../../../student/components/generic/button";
import { useResendInviteMutation } from "../../utils/useResendInviteMutation";

export const ResendInviteLink: React.FC<{
  learnerId: string;
  className?: string;
  buttonText?: string;
}> = ({ learnerId, className, buttonText }) => {
  const toastContext = useDeltaToastContext();
  const [wasSent, setWasSent] = useState(false);

  const { mutateAsync: resendInviteMutation, isPending } =
    useResendInviteMutation();

  const resendInvite = async () => {
    try {
      await resendInviteMutation({ learnerId });
      setWasSent(true);
      setTimeout(() => {
        setWasSent(false);
      }, 3000);
    } catch (error: any) {
      toastContext.addToast({
        status: "Error",
        message:
          error?.response?.data?.message ||
          "There was an error resending the invite email. Please try again later.",
      });
    }
  };

  return (
    <Button
      type="link"
      className={clsx(
        "text-sm",
        wasSent && "pointer-events-none",
        isPending && "pointer-events-none opacity-40",
        className
      )}
      onClick={resendInvite}
    >
      {wasSent ? "Sent!" : buttonText || "Resend Invite Link"}
    </Button>
  );
};
