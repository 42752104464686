import { useState, useEffect, Fragment } from "react";
import {
  IFoundIndividualLicenseForInvoice,
  IFoundSiteLicenseForInvoice,
  ILicenseSearchResult,
} from "../../utils/quoteUtils";
import InvoicePageCard from "./InvoicePageCard";
import SiteLicenseCard from "./SiteLicenseCard";
import IndividualLicensesCard from "./IndividualLicenseCard";
import { isBefore, sub } from "date-fns";

/**
 *
 * This component contains information about existing license info for a the site being quoted. The info displayed depends on the type of quote. A district quote show info if the quoted district has a district license. A school quote will find an existing school license but will also show info about a district license which currently covers that school. An individual quote will show individual licenses and site licenses associated w/ the purchaser being quoted. The match on for site licenses in the individual case is not perfect and depends on the purchaser name matching the name of the school/district as it exists in our database.
 */
const LicenseDetails: React.FC<{
  initialLicenseData: ILicenseSearchResult | undefined;
}> = ({ initialLicenseData }) => {
  const PAST_TIME_THRESHOLD = { months: 6 };

  const past_date_threshold = sub(new Date(), PAST_TIME_THRESHOLD);

  const [licenseData, setLicenseData] = useState<
    ILicenseSearchResult | undefined
  >(undefined);
  const [schoolLicense, setSchoolLicense] = useState<
    IFoundSiteLicenseForInvoice | undefined
  >(undefined);
  const [districtLicense, setDistrictLicense] = useState<
    IFoundSiteLicenseForInvoice | undefined
  >(undefined);
  const [individualLicenses, setIndividualLicenses] = useState<
    IFoundIndividualLicenseForInvoice[] | undefined
  >(undefined);
  const [noLicenses, setNoLicenses] = useState<boolean>(false);
  const [showIndividualNotFound, setShowIndividualNotFound] =
    useState<boolean>(false);

  useEffect(() => {
    setLicenseData(initialLicenseData);
    setSchoolLicense(undefined);
    setDistrictLicense(undefined);
    setIndividualLicenses(undefined);
    setNoLicenses(false);
  }, [initialLicenseData]);

  useEffect(() => {
    let noLicenses = true;
    if (
      (licenseData?.district?.status === "expired" &&
        !isBefore(
          new Date(licenseData.district.expiration),
          past_date_threshold
        )) ||
      licenseData?.district?.status === "active"
    ) {
      setDistrictLicense(licenseData.district);
      noLicenses = false;
    }

    if (
      (licenseData?.school?.status === "expired" &&
        !isBefore(
          new Date(licenseData.school.expiration),
          past_date_threshold
        )) ||
      licenseData?.school?.status === "active"
    ) {
      setSchoolLicense(licenseData.school);
      noLicenses = false;
    }

    let individualLicenses: IFoundIndividualLicenseForInvoice[] = [];
    if (licenseData?.individual?.integral?.length) {
      individualLicenses = individualLicenses
        .filter(
          (license) =>
            !isBefore(new Date(license.expiration), past_date_threshold)
        )
        .concat(licenseData.individual.integral);
      noLicenses = false;
    }
    if (licenseData?.individual?.plus?.length) {
      individualLicenses = individualLicenses
        .filter(
          (license) =>
            !isBefore(new Date(license.expiration), past_date_threshold)
        )
        .concat(licenseData.individual.plus);
      noLicenses = false;
    }
    if (individualLicenses.length) {
      setIndividualLicenses(individualLicenses);
    }
    if (
      typeof licenseData?.individual !== "undefined" &&
      (licenseData?.individual.none || !individualLicenses.length)
    ) {
      setShowIndividualNotFound(true);
    } else {
      setShowIndividualNotFound(false);
    }
    //individual is a bit of a special case since we wouldn't show any info about individual, whether they exist or not, except on an individual quote. At the same time, showing "no licenses" and "no individual licenses" is redundant so we handle that here.
    if (noLicenses) {
      setNoLicenses(true);
    } else {
      setNoLicenses(false);
    }
  }, [licenseData]);

  //A note on hadling when no license of the quoted type is found: for site licenses, that logic in handled in those cards b/c it's simple. Individual is a little more complicated just in terms of keeping integral/plus tracked and what is returned from Node depending on what exists so it's easier to handle here/
  return (
    <Fragment>
      {noLicenses && (
        <InvoicePageCard title="Current License Details">
          <div className="text-xl">No active site licenses found.</div>
        </InvoicePageCard>
      )}
      {schoolLicense && (
        <SiteLicenseCard licenseDetails={schoolLicense} siteType="school" />
      )}
      {districtLicense && (
        <SiteLicenseCard licenseDetails={districtLicense} siteType="district" />
      )}
      {individualLicenses && individualLicenses.length && (
        <IndividualLicensesCard licenseDetails={individualLicenses} />
      )}
      {showIndividualNotFound && (
        <InvoicePageCard title="Individual Licenses">
          <div className="text-xl">No current Individual licenses found.</div>
        </InvoicePageCard>
      )}
    </Fragment>
  );
};

export default LicenseDetails;
