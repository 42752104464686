import { AnalyticsAssignmentData } from "./types";
import { Learner, LearnerAssignmentTypes } from "../types";
import { useCallback } from "react";
import { useParams } from "react-router-dom";
import { useCourseContext } from "../contexts/CourseContext";
import { useUserContext } from "../../shared/contexts/UserContext";
import { AnalyticsEvent } from "../../shared/analytics/types";
import { getAnalyticsInstance } from "../../shared/analytics/getAnalyticsInstance";
import { ANALYTICS_PLUGINS } from "../../shared/analytics/plugins";
import { combinePluginsObject } from "../../shared/useAnalytics";

interface LearnerAnalyticsApi {
  /** Track a full page load or navigation */
  pageView: (data: { previousPath: string } & AnalyticsAssignmentData) => void;
  /** Track attributes for the current user */
  identify: (learner: Learner) => void;
  /** Track that an event has occurred */
  track: (payload: AnalyticsEvent<any>) => void;
  /** Get assignment data to use for analytics purposes */
  getAssignmentData: (
    assignmentType: string | undefined,
    courseId?: string,
    unitId?: string,
    subunitId?: string
  ) => AnalyticsAssignmentData;
  /** Reset analytics state */
  reset: () => void;
}

function validateAssignmentType(
  assignmentType: string | undefined
): assignmentType is LearnerAssignmentTypes {
  switch (assignmentType as LearnerAssignmentTypes) {
    case "preQuiz":
    case "practice":
    case "postQuiz":
    case "unitTest":
    case "courseTest":
      return true;
    default:
      return false;
  }
}

/** Hook to expose analytics functions */
export function useLearnerAnalytics(): LearnerAnalyticsApi {
  const analytics = getAnalyticsInstance();
  const { coursePath, unitPath, subunitPath } = useParams();
  const courseContext = useCourseContext();
  const userContext = useUserContext();

  // useCallback prevents this function from firing multiple times
  // when included in useEffect
  const pageView = useCallback(
    (data: { previousPath: string } & AnalyticsAssignmentData) => {
      if (userContext.isTeacherImpersonating) {
        return;
      }
      analytics.page(data);
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userContext.isTeacherImpersonating]
  );

  // useCallback prevents this function from firing multiple times
  // when included in useEffect
  const identify = useCallback(
    (learner: Learner) => {
      if (userContext.isTeacherImpersonating) {
        return;
      }
      const assignedCourse = learner?.assignedCourse
        ? courseContext.getCourseData(learner.assignedCourse)
        : undefined;
      const identificationData = {
        accountType: learner.account_type,
        entitlements: learner.entitlements,
        email: learner.email,
        accountCreatedAt: learner.createdAt,
        firstName: learner.first,
        lastName: learner.last,
        assignedCourse: assignedCourse?.name ?? "None",
      };

      // Identify everything but Mixpanel
      analytics.identify(learner._id, identificationData, {
        plugins: { mixpanel: false },
      });

      // Identify Mixpanel with additional special properties
      analytics.identify(
        learner._id,
        {
          ...identificationData,
          $email: learner.email,
          $first_name: learner.first,
          $last_name: learner.last,
        },
        { plugins: { all: false, mixpanel: true } }
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [courseContext, userContext.isTeacherImpersonating]
  );

  // useCallback prevents this function from firing multiple times
  // when included in useEffect
  const track = useCallback(
    (payload: AnalyticsEvent<any>) => {
      if (userContext.isTeacherImpersonating) {
        return;
      }
      const pluginsAsObject = {
        ...payload.plugins.reduce(
          (
            acc: Record<string, boolean>,
            plugin: typeof ANALYTICS_PLUGINS[keyof typeof ANALYTICS_PLUGINS]
          ) => {
            acc[plugin] = true;
            return acc;
          },
          {} as Record<string, boolean>
        ),
      };
      const combinedPluginsObject = combinePluginsObject(pluginsAsObject);
      analytics.track(payload.event, payload.properties, {
        plugins: {
          ...combinedPluginsObject,
        },
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [userContext.isTeacherImpersonating]
  );

  // useCallback prevents this function from firing multiple times
  // when included in useEffect
  const getAssignmentData = useCallback(
    (
      assignmentType: string | undefined,
      courseId?: string,
      unitId?: string,
      subunitId?: string
    ) => {
      if (!courseContext.state) {
        return {
          courseName: "Unknown Course",
          assignmentType: "unknown",
          unitNumber: -1,
          sectionNumber: -1,
        };
      }
      const courseData = courseContext.getCourseData(courseId || coursePath);
      const unitData = courseContext.getUnitData(
        unitId || unitPath,
        courseId || coursePath
      );
      const subunitData = courseContext.getSubunitData(
        subunitId || subunitPath,
        unitId || unitPath,
        courseId || coursePath
      );
      const unitIndex = (courseData?.unitOrder ?? []).findIndex(
        (o) => o === unitData?.id
      );
      const subunitIndex = (unitData?.subunitOrder ?? []).findIndex(
        (o) => o === subunitData?.id
      );
      const data: AnalyticsAssignmentData = {
        courseName: courseData?.name ?? "Unknown Course",
        assignmentType: validateAssignmentType(assignmentType)
          ? assignmentType
          : "unknown",
        unitNumber: unitIndex === -1 ? -1 : unitIndex + 1,
        sectionNumber: subunitIndex === -1 ? -1 : subunitIndex + 1,
      };
      return data;
    },
    [courseContext, coursePath, subunitPath, unitPath]
  );

  // useCallback prevents this function from firing multiple times
  // when included in useEffect
  const reset = useCallback(() => {
    analytics.reset();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    pageView,
    identify,
    track,
    getAssignmentData,
    reset,
  };
}
