import axios from "axios";
import { useState, useEffect } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { getAdminParams } from "../../admin/utils";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { EXPIRATION_OCTOBER_2025, deltamathAPI } from "../utils";
import { useUserContext } from "../../shared/contexts/UserContext";
import { Link } from "react-router-dom";
import { REACT_APP_HOMEPAGE_LINK } from "../../utils";

type Props = {
  schoolData: any;
  districtData: any;
  adminData: any;
};

const IntegralTrialBanner = (props: Props) => {
  const queryClient = useQueryClient();
  const [showTrialOptInBanner, setShowTrialOptInBanner] =
    useState<boolean>(false);
  const [showTrailActiveBanner, setShowTrialActiveBanner] =
    useState<boolean>(false);
  const [upgrading, setUpgrading] = useState<boolean>(false);
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const adminParams = getAdminParams();

  // Epoch for 10/1/2024
  const expirationDate = EXPIRATION_OCTOBER_2025;

  const user = JSON.parse(localStorage.getItem("user") || "{}");
  const id = user.admin ? user.admin : user._id;
  const currentUser =
    props.adminData && props.adminData.length > 0
      ? props.adminData.filter((user: { _id: string }) => user._id === id)
      : undefined;

  useEffect(() => {
    // check that current timestamp is before May 1
    const hideAfterDate = new Date("2025-05-01");
    const today = new Date();

    // Check to make sure the user was found in admin data
    if (
      props.districtData &&
      props.schoolData &&
      currentUser &&
      adminParams &&
      today < hideAfterDate
    ) {
      // Depending on the type of the user check to see if either the district or the schools are eligible for upgrades
      // with an expiration date of Oct 1 OR
      // Check the district for a non integral license with an expiration date of
      // Oct 1
      if (
        adminParams.account_type === "district" ||
        adminParams.account_type === "super_district"
      ) {
        if (
          props.districtData.dmLicense &&
          !props.districtData.dmLicense.has_integral &&
          props.districtData.dmLicense.expiration === expirationDate
        ) {
          setShowTrialOptInBanner(true);
        } else if (
          props.districtData.dmLicense &&
          props.districtData.dmLicense.is_trial === true
        ) {
          // if your district has been opted in and display trial active banner
          setShowTrialActiveBanner(true);
        }
      } else if (
        adminParams.account_type === "school" ||
        adminParams.account_type === "super_school"
      ) {
        // Loop through all schools to check to see if any are eligible for an upgrade
        if (
          props.schoolData.some(
            (school: any) =>
              school.dmLicense !== undefined &&
              !school.dmLicense.has_integral &&
              school.dmLicense.expiration === expirationDate
          )
        ) {
          setShowTrialOptInBanner(true);
        }
        // if any schools have been opted in and display trial active banner
        else if (
          (props.districtData.dmLicense &&
            props.districtData.dmLicense.is_trial === true) ||
          props.schoolData.some(
            (school: any) =>
              school.dmLicense !== undefined &&
              school.dmLicense.is_trial === true
          )
        ) {
          setShowTrialActiveBanner(true);
        }
      }
    }
  }, [props.districtData, props.schoolData, currentUser, adminParams]);

  const trialUpgrade = useMutation({
    mutationFn: () => {
      setUpgrading(true);
      const customer_service_token = localStorage.getItem(
        "customer_service_token"
      );
      const token = userContext.getJWT();
      return axios.post(
        deltamathAPI() + "/admin_new/license/trial_enroll",
        customer_service_token,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );
    },
    onSuccess: (data) => {
      // Look in here for query invalidation
      queryClient.invalidateQueries({ queryKey: ["/admin_new/data/school"] });
      queryClient.invalidateQueries({ queryKey: ["/admin_new/data/district"] });
      queryClient.invalidateQueries({
        queryKey: ["/admin_new/data/ltiCredentials"],
      });
      const user = JSON.parse(localStorage.getItem("user") || "{}");
      const updatedUser = {
        ...user,
        deltamath_integral_active: true,
      };
      localStorage.setItem("user", JSON.stringify(updatedUser));

      // schoolRefetch();
      toastContext.addToast({
        status: "Success",
        message: "Your account has been upgraded.",
      });
      setShowTrialActiveBanner(true);
    },
    onError() {
      setUpgrading(false);
      toastContext.addToast({
        status: "Error",
        message: "Please contact DeltaMath for assistance.",
      });
    },
  });

  if (!showTrialOptInBanner && !showTrailActiveBanner) {
    return <></>;
  }

  return (
    <div className="relative bg-sky-400">
      <div className="ml-64 px-3 py-3 sm:ml-0 sm:px-6 md:ml-64 lg:px-8">
        <div className="text-center sm:px-16">
          {showTrialOptInBanner && !showTrailActiveBanner ? (
            <p className="font-medium text-white">
              <span>
                Special Limited Offer: Receive a free upgrade to DeltaMath
                INTEGRAL through the end of the school year.
              </span>{" "}
              <a
                className="underline hover:cursor-pointer"
                onClick={() => {
                  // Stopping users from being able to spam click the banner
                  if (!upgrading) {
                    trialUpgrade.mutate();
                  }
                }}
              >
                Opt In Now!
              </a>
            </p>
          ) : (
            <p className="font-medium text-white">
              <span>
                Your{" "}
                {adminParams.account_type === "district" ||
                adminParams.account_type === "super_district"
                  ? "district"
                  : "school"}{" "}
                has been opted into our INTEGRAL site license trial. Learn about
                your{" "}
                <Link
                  to={`${REACT_APP_HOMEPAGE_LINK}/help-video/integral-site-license-trial`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="underline hover:cursor-pointer hover:no-underline"
                >
                  upgraded features here
                </Link>
                .
              </span>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};

export default IntegralTrialBanner;
