import { useQuery } from "@tanstack/react-query";
import { executeQuery } from "../../../utils";
import { useMemo, useState } from "react";
import { populateSearchTags } from "../../utils/searchPrototypeData";
import { SkillCodes } from "../../../manager/components/skill-codes/SkillCodes";
import { SearchInput } from "./SearchInput";
import { SearchResults } from "./SearchResults";
import Select from "react-select";

const includedYears = ["grade6", "algebra1new"];

export const SearchPlayground: React.FC = () => {
  const [query, setQuery] = useState("");
  const [filteredYears, setFilteredYears] = useState<string[]>(includedYears);

  const { data, status } = useQuery<SkillCodes>({
    queryKey: [`/manager_new/skill-codes/all`],
    queryFn: () =>
      executeQuery({
        path: `/manager_new/skill-codes/all`,
        customerServiceHeader: true,
      }),
    enabled: true,
    refetchOnWindowFocus: false,
    refetchOnMount: false,
  });

  const modifiedData = useMemo(
    () => (data ? populateSearchTags(data, includedYears) : undefined),
    [data]
  );

  if (status !== "success" || !modifiedData) {
    return <>Loading...</>;
  }

  const yearOptions = includedYears
    .filter((year) => data.years_order.includes(year))
    .map((year) => data.years[year])
    .map((x) => {
      return {
        label: x.name,
        value: x.code,
      };
    });

  return (
    <>
      <div className="relative flex flex-col gap-4 pt-8">
        <div className="sticky top-0 bg-white">
          <SearchInput onQuery={(q: string) => setQuery(q)} />
        </div>
        <div className="pb-8">
          <p className="mb-2 text-lg font-bold">Year</p>
          <Select
            placeholder="All Years"
            isMulti
            name="colors"
            value={yearOptions.filter((x) => filteredYears.includes(x.value))}
            options={yearOptions}
            isClearable={true}
            isSearchable={false}
            closeMenuOnSelect={false}
            className="w-96"
            onChange={(newValue) => {
              setFilteredYears(newValue.map((x) => x.value));
            }}
          />
        </div>
        <div>
          <h4 className="pb-2 text-xl font-bold">Search Results Using Lunr</h4>
          <SearchResults
            query={query}
            data={modifiedData}
            type="lunr"
            filteredYears={filteredYears}
          />
          <hr className="my-4" />
        </div>
        <div>
          <h4 className="pb-2 text-xl font-bold">Search Results Using Fuse</h4>
          <SearchResults
            query={query}
            data={modifiedData}
            type="fuse"
            filteredYears={filteredYears}
          />
        </div>
      </div>
    </>
  );
};
