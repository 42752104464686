import { format } from "date-fns";
import Modal from "../../../student/components/generic/Modal";
import Button from "../../../student/components/generic/button";
import { AccountFormData, EditAccountResponse, Learner } from "../../types";
import DebounceTextField from "../BetaSignUp/DebounceTextField";
import { ProfileField } from "../Profile/ProfileField";
import { TextField } from "../Splash/TextField";
import { useState, useEffect } from "react";
import { useMutation } from "@tanstack/react-query";
import axios from "axios";
import { deltamathAPI } from "../../../manager/utils";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { SubscriptionStatusPill } from "../Profile/SubscriptionStatusPill";
import { getLearnerAccountStatus } from "../Profile/getLearnerAccountStatus";
import { useCancellationContext } from "../Profile/Cancellation/CancellationContext";
import { useCheckEmail } from "../../utils/useCheckEmail";
import { PasswordFieldContainer } from "../Profile/Fields/PasswordFieldContainer";
import { isSubscriptionActive } from "../../utils/isSubscriptionActive";
import { Infotip } from "../../../shared/Infotip";
import ToggleChallengeMode from "../Profile/ToggleChallengeMode";

export const EditAccountInformationModal: React.FC<{
  user: Learner;
  visible: boolean;
  onClose: () => void;
  updateUser: (formData: AccountFormData) => void;
  updateUserUponAddPassword?: () => void;
  isParentEditingLearner?: boolean;
}> = ({
  user,
  visible,
  onClose,
  updateUser,
  isParentEditingLearner = false,
  updateUserUponAddPassword,
}) => {
  const { setShowCancelModal, setSelectedLearners } = useCancellationContext();
  const toastContext = useDeltaToastContext();
  const [formData, setFormData] = useState<AccountFormData>({
    email: "",
    first: "",
    last: "",
  });
  const [formErrors, setFormErrors] = useState<{
    [K in keyof AccountFormData]?: string | React.ReactElement;
  }>({});
  const { checkEmail, isLoading: isEmailAvailabilityLoading } = useCheckEmail(
    formData.email,
    user.entitlements.includes("billing") ? "email" : "username"
  );

  const { mutate: updateAccount, isPending: isUpdateAccountLoading } =
    useMutation<EditAccountResponse, unknown, AccountFormData & { id: string }>(
      {
        mutationFn: (body) => {
          return axios.put(
            `${deltamathAPI()}/learner/shared/update_account`,
            JSON.stringify(body),
            {
              headers: {
                "Content-Type": "application/json",
              },
            }
          );
        },
        onSuccess: (data) => {
          const response = data.data;
          if (response.success) {
            updateUser(formData);
            closeModal();
          } else {
            setFormErrors(response.errors);
          }
        },
        onError: () => {
          closeModal();
          toastContext.addToast({
            message: "There was an error updating your account.",
            status: "Error",
          });
        },
      }
    );

  useEffect(() => {
    if (visible) {
      setFormData({
        email: user.email,
        first: user.first,
        last: user.last,
      });
      setFormErrors({});
    }
    // Only run this when visibility changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  useEffect(() => {
    if (formData.email !== user.email) {
      (async () => {
        const { success, message } = await checkEmail();
        if (!success) {
          formErrors["email"] = message;
        } else if (formErrors.email) {
          delete formErrors["email"];
        }
      })();
    }
  }, [formData.email, user.email, checkEmail, formErrors]);

  const closeModal = () => {
    onClose();
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    fieldType: keyof AccountFormData
  ) => {
    if (isUpdateAccountLoading) return;
    if (!e.target.value.trim()) {
      formErrors[fieldType] = "This is a required field.";
    } else {
      delete formErrors[fieldType];
    }
    setFormData({
      ...formData,
      [fieldType]: e.target.value,
    });
  };

  const confirmEnabled =
    formData.email.trim() &&
    formData.first.trim() &&
    formData.last.trim() &&
    !isEmailAvailabilityLoading &&
    !isUpdateAccountLoading &&
    !Object.keys(formErrors).length;

  const accountStatus = getLearnerAccountStatus(user);

  const cancelLearner = () => {
    onClose();
    setSelectedLearners([user._id]);
    setShowCancelModal(true);
  };

  return (
    <Modal
      visible={visible}
      onClose={closeModal}
      body={
        <div>
          <h2 className="mb-6 text-center font-serif text-2xl font-bold text-dm-brand-blue-600">
            Edit Account Information
          </h2>
          <div className="m-1 flex flex-col gap-6">
            <div className="w-full sm:w-80">
              <TextField
                label="First Name"
                value={formData["first"]}
                error={formErrors["first"]}
                onChange={(e) => handleChange(e, "first")}
              />
            </div>
            <div className="w-full sm:w-80">
              <TextField
                label="Last Name"
                value={formData["last"]}
                error={formErrors["last"]}
                onChange={(e) => handleChange(e, "last")}
              />
            </div>
            <ProfileField label="Account Type">
              {user.entitlements.includes("parent") ? "Parent" : "Learner"}
            </ProfileField>
            <div className="w-full sm:w-80">
              <DebounceTextField
                label="User Name/Email"
                value={formData["email"]}
                error={formErrors["email"]}
                onChange={(e) => handleChange(e, "email")}
              />
            </div>
            <PasswordFieldContainer
              user={user}
              isParentEditingLearner={isParentEditingLearner}
              updateUserUponAddPassword={updateUserUponAddPassword}
            />
            {user.entitlements?.includes("learner") && (
              <ProfileField
                label={
                  <>
                    Challenge Mode{" "}
                    <Infotip
                      options={{
                        tooltipRight: true,
                        maxWidth: 200,
                        aligned: "center",
                      }}
                      message="Courses too easy? Enable challenge mode to see fewer basic problems and more challenging problems. Most content will be the same in each section whether or not Challenge Mode is enabled."
                    >
                      <i
                        className="far fa-info-circle text-lg font-normal leading-none text-dm-gray-200"
                        aria-hidden="true"
                      ></i>
                    </Infotip>
                  </>
                }
              >
                <ToggleChallengeMode
                  learner={user}
                  parentOverride={isParentEditingLearner}
                />
              </ProfileField>
            )}
            <ProfileField label="Status">
              <SubscriptionStatusPill status={accountStatus} />
              {isParentEditingLearner &&
                !user.subscriptionWillCancel &&
                isSubscriptionActive(user) && (
                  <Button type="link" onClick={cancelLearner}>
                    Cancel Subscription
                  </Button>
                )}
            </ProfileField>
            <ProfileField label="Start Date">
              {format(new Date(user.createdAt), "MM'/'dd'/'yy")}
            </ProfileField>
          </div>
        </div>
      }
      onConfirm={() => {
        updateAccount({
          ...formData,
          id: user._id,
        });
      }}
      confirmButtonText="Save Changes"
      confirmDisabled={!confirmEnabled}
      secondaryOnClick={closeModal}
      secondaryButtonText="Cancel"
    />
  );
};
