import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { REACT_APP_MANAGER_LINK, useDMQuery } from "../../../utils";
import Button from "../../../student/components/generic/button";
import { ArrowLeftIcon } from "@heroicons/react/solid";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { deltamathAPI } from "../../utils";
import axios from "axios";
import { CourseType, Skill, Subunit, Unit } from "../../types";
import DraggableTable from "../../../shared/DraggableTable/DraggableTable";
import EditName from "../GenericCourseBuilder/EditName";
import PublishVersion from "../GenericCourseBuilder/PublishVersion";
import DeleteVersion from "../GenericCourseBuilder/DeleteVersion";
import { compact, sum } from "lodash";
import { Tooltip } from "../../../shared/Tooltip";
import NewVersion from "../GenericCourseBuilder/NewVersion";
import MoveSubunitModal from "./MoveSubunitModal";

export type skillDataType = Skill & {
  time: string;
  seconds: number;
  subunitName: string;
  types: {
    [key: number]: string;
  };
};

const CourseUnit = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const toastContext = useDeltaToastContext();
  const [newSubunitName, setNewSubunitName] = useState<string>("");
  const params = useParams();
  const unitId = params.unitId ?? "";
  const version = urlParams.get("version");
  const [showMoveSubunitModal, setShowMoveSubunitModal] =
    useState<boolean>(false);
  const [subunitToMove, setSubunitToMove] = useState<Subunit | undefined>();

  const {
    data: unitData,
    refetch: unitRefetch,
    status: unitStatus,
  } = useDMQuery<Unit>({
    path: `/manager_new/parent-portal/unit/${unitId}?versionId=${urlParams.get(
      "version"
    )}`,
    queryOptions: {
      enabled: false,
    },
  });

  const {
    data: subunitData,
    refetch: subunitRefetch,
    status: subunitStatus,
  } = useDMQuery<Subunit[]>({
    path: `/manager_new/parent-portal/unit/${unitId}/subunits?versionId=${urlParams.get(
      "version"
    )}`,
    queryOptions: {
      enabled: false,
    },
  });

  const { data: courseData, refetch: courseRefetch } = useDMQuery<CourseType>({
    path: `/manager_new/parent-portal/course/${unitData?.courseId}?versionId=${unitData?.courseVersionKey}`,
    queryOptions: {
      enabled: false,
    },
  });

  const { data: skillData, refetch: skillRefetch } = useDMQuery<
    skillDataType[]
  >({
    path: `/manager_new/parent-portal/unit/${unitId}/skills?versionId=${urlParams.get(
      "version"
    )}`,
    queryOptions: {
      enabled: false,
    },
  });

  const createSubunit = useMutation({
    mutationFn: (body: string) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/parent-portal/subunit`,
        body,
        { headers: { "Content-Type": "application/json" } }
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [`/manager_new/parent-portal/unit/${unitId}/subunits`],
      });
      setNewSubunitName("");
      toastContext.addToast({
        status: "Success",
        message: "Subunit Created",
      });
      subunitRefetch();
      skillRefetch();
    },
    onError() {
      toastContext.addToast({
        status: "Error",
        message: "Problem creating Subunit",
      });
    },
  });

  const courseVersion = courseData?.versionHistory.find(
    (vh) => vh.versionKey === unitData?.courseVersionKey
  );

  useEffect(() => {
    if (unitId) {
      unitRefetch();
      subunitRefetch();
      skillRefetch();
    }
  }, [unitId, version]);

  useEffect(() => {
    if (unitData) {
      courseRefetch();
    }
  }, [unitData]);

  const create = () => {
    const body = {
      name: newSubunitName,
      unitId: unitId,
      unitVersion: urlParams.get("version"),
    };
    createSubunit.mutate(JSON.stringify(body));
  };

  const subunitReorder = useMutation({
    mutationFn: (body: string) => {
      return axios.post(
        `${deltamathAPI()}/manager_new/parent-portal/order_subunits`,
        body,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
    },
    onSuccess() {
      queryClient.invalidateQueries({
        queryKey: [`/manager_new/parent-portal/unit/${unitId}/subunits`],
      });
      toastContext.addToast({
        status: "Success",
        message: "Subunits Reordered",
      });
      subunitRefetch();
      skillRefetch();
    },
    onError() {
      toastContext.addToast({
        status: "Error",
        message: "Issue Reordering Subunits",
      });
    },
  });

  const updateOrder = (data: Subunit[]) => {
    const body = {
      unitId: unitId,
      unitVersionKey: urlParams.get("version"),
      subunitIds: data.map((su) => su._id),
    };
    subunitReorder.mutate(JSON.stringify(body));
  };

  const easySkills = skillData
    ? skillData.filter((s) => s.difficulty !== "hard")
    : [];
  const hardSkills = skillData
    ? skillData.filter((s) => s.difficulty !== "easy")
    : [];

  const newVersionCallback = (id: string, versionKey: string) => {
    navigate(`${REACT_APP_MANAGER_LINK}/unit/${id}?version=${versionKey}`);
  };

  if (unitStatus !== "success" || subunitStatus !== "success") {
    return <>Loading...</>;
  }

  const unitVersion = unitData.versionHistory.find(
    (vh) => vh.versionKey === urlParams.get("version")
  );

  const columns = compact([
    {
      Header: "Subunit Name",
      accessor: "name",
      align: "center",
      Cell: (props: any) => {
        return (
          <Button
            href={`${REACT_APP_MANAGER_LINK}/subunit/${props.row.original._id}`}
            type="link"
          >
            {props.row.original.name}
          </Button>
        );
      },
    },
    {
      Header: "# of Skills (E | H)",
      align: "center",
      Cell: (props: any) => {
        const skills = props.row.original.skills;
        return (
          <>
            {skills.filter((x: any) => x.difficulty !== "hard").length} |{" "}
            {skills.filter((x: any) => x.difficulty !== "easy").length}
          </>
        );
      },
    },
    unitVersion?.state === "draft"
      ? {
          Header: "Move Subunit",
          align: "center",
          Cell: (props: any) => {
            return (
              <Button
                onClick={() => {
                  setSubunitToMove(props.row.original);
                  setShowMoveSubunitModal(true);
                }}
              >
                Move to different unit
              </Button>
            );
          },
        }
      : undefined,
    {
      Header: "Delete",
      align: "center",
      Cell: (props: any) => {
        return (
          <DeleteVersion
            type="subunit"
            id={props.row.original._id}
            icon
            name={props.row.original.name}
            callback={() => {
              subunitRefetch();
              skillRefetch();
            }}
            deleteDisabled={unitVersion?.state !== "draft"}
          />
        );
      },
    },
  ]);

  const skillColumns = [
    "Count",
    "Subunit",
    "Skill",
    "Test Weight",
    "Time Estimate",
  ];

  const restrictSubUnitCreation = unitVersion?.state !== "draft";

  const easyQuestions = easySkills.length > 20 ? 20 : easySkills.length;
  const hardQuestions = hardSkills.length > 20 ? 20 : hardSkills.length;

  const easyTestWeight = sum(easySkills.map((es) => es.testWeight));
  const easyTimeEstimate =
    (sum(easySkills.map((x) => x.seconds * x.testWeight)) / easyTestWeight) *
    easyQuestions;
  const easyTime = `${Math.floor(easyTimeEstimate / 60)} Minutes, ${Math.round(
    easyTimeEstimate % 60
  )} seconds`;

  const hardTestWeigh = sum(hardSkills.map((hs) => hs.testWeight));
  const hardTimeEstimate =
    (sum(hardSkills.map((x) => x.seconds * x.testWeight)) / hardTestWeigh) *
    hardQuestions;
  const hardTime = `${Math.floor(hardTimeEstimate / 60)} Minutes, ${Math.round(
    hardTimeEstimate % 60
  )} seconds`;

  return (
    <div className="px-6">
      <MoveSubunitModal
        visible={showMoveSubunitModal}
        onClose={() => setShowMoveSubunitModal(false)}
        subunit={subunitToMove}
        courseId={unitData.courseId}
        courseVersion={unitData.courseVersionKey}
      />
      <div className="flex justify-between py-10">
        <Button
          href={
            unitStatus !== "success"
              ? `${REACT_APP_MANAGER_LINK}/course-builder`
              : `${REACT_APP_MANAGER_LINK}/course/${unitData.courseId}?version=${unitData.courseVersionKey}`
          }
          type="link"
          className="flex items-center gap-2"
        >
          <ArrowLeftIcon
            className="h-5 w-5 text-dm-gray-200"
            aria-hidden="true"
          />
          {unitStatus !== "success"
            ? "Back to Course List"
            : "Back to Course Details"}
        </Button>
        <div className="flex gap-4">
          <NewVersion
            type="unit"
            id={unitId}
            versionKey={unitVersion?.versionKey || ""}
            callback={newVersionCallback}
          />
          <PublishVersion
            type="unit"
            id={unitId}
            versionHistory={unitVersion}
            callback={unitRefetch}
          />
          {courseVersion?.state === "draft" && (
            <DeleteVersion
              type="unit"
              id={unitId}
              versionHistory={unitVersion}
              callback={() => {
                unitRefetch();
                subunitRefetch();
                skillRefetch();
              }}
            />
          )}
        </div>
      </div>

      <div className="mb-8 flex flex-col gap-6">
        <div className="max-w-3xl">
          <EditName
            name={unitData.name}
            friendlyUrl={unitData.friendlyPath ?? ""}
            description={unitData.description}
            type="unit"
            id={unitId}
            callback={() => unitRefetch()}
            versionState={unitVersion?.state || "draft"}
          />
        </div>
      </div>
      <p>Subunit Name</p>
      <form
        className="mb-8 flex gap-4"
        onSubmit={async (e) => {
          e.preventDefault();
        }}
      >
        <input
          type="text"
          name="subunitName"
          id="subunitName"
          className="mt-1 block h-12 w-96 rounded-none rounded-l-md border-gray-300 px-2 py-1 text-sm placeholder-gray-300 ring-inset placeholder:text-sm focus:border-dm-brand-blue-500 focus:ring-0 focus:ring-dm-brand-blue-500"
          placeholder="New Subunit Name"
          value={newSubunitName}
          onChange={(e) => setNewSubunitName(e.target.value)}
        />
        <Tooltip
          message={
            restrictSubUnitCreation
              ? "You cannot create a new subunit version in a published or previously published unit! Please create a new version if you would like to edit"
              : ""
          }
        >
          <Button
            className="flex items-center gap-2"
            onClick={create}
            submit
            disabled={restrictSubUnitCreation}
          >
            <i className="far fa-plus-circle cursor-pointer text-2xl" />
            Add Subunit
          </Button>
        </Tooltip>
      </form>
      {/* <DeltaMathTable columns={columns} data={[]}></DeltaMathTable> */}
      {!unitData || !subunitData || compact(subunitData).length === 0 ? (
        <div className="my-20 w-full bg-white py-20">
          <div className="text-center">
            <h2 className="my-10 text-xl font-bold">
              There are no subunits in this course yet
            </h2>
          </div>
        </div>
      ) : (
        <div>
          <DraggableTable
            cols={columns}
            data={compact(subunitData)}
            updateData={updateOrder}
          />
        </div>
      )}
      {(easySkills.length > 0 || hardSkills.length > 0) && (
        <div className="py-10">
          <div className="flex justify-between">
            <div></div>
            <div className="flex items-center gap-2">
              <h5 className="text-lg font-bold">Easy</h5>
              <p>
                Sum Test Weight: <b>{easyTestWeight}</b>
              </p>
              <p>
                Total Time Estimate: <b>{easyTime}</b>
              </p>
            </div>
            <div className="flex gap-2">
              <h5 className="text-lg font-bold">Hard</h5>
              <p>
                Sum Test Weight:{" "}
                <b>{sum(hardSkills.map((es) => es.testWeight))}</b>
              </p>
              <p>
                Total Time Estimate: <b>{hardTime}</b>
              </p>
            </div>
            <div></div>
          </div>
          <>
            {easySkills && easySkills.length > 0 && (
              <div className="flex justify-between gap-6 pb-10">
                <div className="w-1/2">
                  <h5 className="text-lg font-bold">EASY SKILLS</h5>
                  <table className="table-auto border-collapse border border-black">
                    <thead>
                      <tr>
                        {[...skillColumns, "Easy Only"].map((sc, index) => (
                          <th key={index} className="border border-black p-4">
                            {sc}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {easySkills.map((es, i) => (
                        <tr key={i}>
                          <td className="p-4">{i + 1}</td>
                          <td className="p-4">{es.subunitName}</td>
                          <td className="p-4">{es.skillName}</td>
                          <td className="p-4">{es.testWeight}</td>
                          <td className="p-4">{es.time}</td>
                          <td className="p-4">
                            {es.difficulty === "easy" ? (
                              <i className="far fa-check" />
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="w-1/2">
                  <h5 className="text-lg font-bold">HARD SKILLS</h5>
                  <table className="table-auto border-collapse border border-black">
                    <thead>
                      <tr>
                        {[...skillColumns, "Hard Only"].map((sc, index) => (
                          <th key={index} className="border border-black p-4">
                            {sc}
                          </th>
                        ))}
                      </tr>
                    </thead>
                    <tbody>
                      {hardSkills.map((hs, i) => (
                        <tr key={i}>
                          <td className="p-4">{i + 1}</td>
                          <td className="p-4">{hs.subunitName}</td>
                          <td className="p-4">{hs.skillName}</td>
                          <td className="p-4">{hs.testWeight}</td>
                          <td className="p-4">{hs.time}</td>
                          <td className="p-4">
                            {hs.difficulty === "hard" ? (
                              <i className="far fa-check" />
                            ) : (
                              <></>
                            )}
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            )}
          </>
        </div>
      )}
    </div>
  );
};

export default CourseUnit;
