import React, { useState, useEffect, Fragment } from "react";
import InvoicePageEditableCard from "./InvoicePageEditableCard";
import { useActivationContext } from "./LicenseActivationContext";
import { deltamathAPI, TIMEZONES_FOR_PICKER, US_STATES } from "../../utils";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import axios, { AxiosError } from "axios";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import { IInvoice, isSNNCase } from "../../utils/quoteUtils";
import { DMConfirmModal } from "../../../shared/DMModal/DMConfirmModal";
import Button from "../../../student/components/generic/button";
import Warnings from "./Warnings";

const SchoolDistrictDetailsForInvoice: React.FC = () => {
  const activationContext = useActivationContext();
  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();
  const [edit, setEdit] = useState(false);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [snnRenewal, setSnnRenewal] = useState<boolean | undefined>(undefined);
  //it is possible that NCES is really the only thing that should be editable here and that the rest should be read-only. For now, tracking the logic separately since entering NCES is used for more than just a simple update - there will be validation logic for associating the quote w/ an existing custom school.
  const [allowNCESEdit, setAllowNCESEdit] = useState(false);
  const [showCreateCustom, setShowCreateCustom] = useState(false);
  const [createCustomSchoolDisabled, setCreateCustomSchoolDisabled] =
    useState(true);

  const [currentSiteInfo, setCurrentSiteInfo] = useState({
    name: activationContext.invoiceDetails?.name || "",
    state: activationContext.invoiceDetails?.state || "",
    id: activationContext.invoiceDetails?.id || "",
    address: activationContext.invoiceDetails?.address || "",
    city: activationContext.invoiceDetails?.city || "",
    zip: activationContext.invoiceDetails?.zip || "",
    country: activationContext.invoiceDetails?.country,
    site_timezone: activationContext.invoiceDetails?.site_timezone || "",
    snnLicenseType: activationContext.invoiceDetails?.snnLicenseType || "",
    individualSiteType:
      activationContext.invoiceDetails?.individualSiteType || "",
  });

  const [showNewSchoolModal, setShowNewSchoolModal] = useState(false);

  //There may end up being some logic around whether/when the timzezone is editable. For now, should be editable pre-activation
  const [timeZoneEdit, setTimeZoneEdit] = useState(
    activationContext.invoiceDetails?.status !== "Activated"
  );

  const handleLocalSave = () => {
    const body = {
      ...currentSiteInfo,
      ...(isSNNCase({
        type: activationContext.state.currentQuoteType,
        id: activationContext.invoiceDetails?.id,
      })
        ? {
            snnLicenseType: currentSiteInfo.snnLicenseType,
          }
        : {}),
      ...(activationContext.state.currentQuoteType === "individual"
        ? {
            individualSiteType: currentSiteInfo.individualSiteType,
          }
        : {}),
    };
    activationContext.simpleQuoteUpdate(body);
  };

  const handleDetailChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, value } = e.target;
    setCurrentSiteInfo((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  };

  useEffect(() => {
    if (
      Object.keys(currentSiteInfo).every((key) => {
        return (
          currentSiteInfo[key as keyof typeof currentSiteInfo] ===
            activationContext.invoiceDetails?.[key as keyof IInvoice] ||
          (!currentSiteInfo[key as keyof typeof currentSiteInfo] &&
            !activationContext.invoiceDetails?.[key as keyof IInvoice])
        );
      })
    ) {
      setSaveDisabled(true);
    } else {
      setSaveDisabled(false);
    }
  }, [currentSiteInfo]);

  const handleSnnChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (e.target.value === "") {
      setSnnRenewal(undefined);
    } else {
      setSnnRenewal(e.target.value === "renewal");
    }
    setCurrentSiteInfo((prev) => {
      if (e.target.value !== "new") {
        return {
          name: activationContext.invoiceDetails?.name || "",
          state: activationContext.invoiceDetails?.state || "",
          id: activationContext.invoiceDetails?.id || "",
          address: activationContext.invoiceDetails?.address || "",
          city: activationContext.invoiceDetails?.city || "",
          zip: activationContext.invoiceDetails?.zip || "",
          country: activationContext.invoiceDetails?.country || "",
          site_timezone: activationContext.invoiceDetails?.site_timezone || "",
          snnLicenseType: e.target.value,
          individualSiteType: prev.individualSiteType,
        };
      } else {
        return {
          ...prev,
          snnLicenseType: e.target.value,
        };
      }
    });
  };

  const handleCreateClick = () => {
    setShowNewSchoolModal(true);
  };

  //TODO: decided if we need a school digger lookup at all for these details
  const applySchoolDiggerData = () => {
    console.log("applySchoolDiggerData");
    console.log(JSON.stringify(activationContext.siteData?.schooldigger));
  };

  const { mutate: createCustomSchool } = useMutation({
    mutationFn: () => {
      return axios.post(
        `${deltamathAPI()}/manager_new/invoices/create_custom_school/${
          activationContext.state.quoteNumber
        }`
      );
    },
    onSuccess: (res) => {
      toastContext.addToast({
        status: "Success",
        message: "Custom school created successfully with id: " + res.data.id,
        dismiss: "manual",
      });
    },
    onError: (error: AxiosError) => {
      toastContext.addToast({
        status: "Error",
        message:
          (error.response?.data as any)?.message ||
          error.message ||
          "Error creating custom school: " + JSON.stringify(error),
        dismiss: "manual",
      });
    },
    onSettled: () => {
      queryClient.invalidateQueries({
        queryKey: ["invoice", `${activationContext.state.quoteNumber}`],
      });
    },
  });

  //NCES should be editable (can be entered) for SNN Renewals only
  useEffect(() => {
    if (
      snnRenewal &&
      isSNNCase({
        type: activationContext.invoiceDetails?.type,
        id: activationContext.invoiceDetails?.id,
      }) &&
      (!activationContext.invoiceDetails?.id ||
        parseInt(activationContext.invoiceDetails?.id || "", 10) === -1)
    ) {
      setAllowNCESEdit(true);
    } else {
      setAllowNCESEdit(false);
    }
  }, [
    snnRenewal,
    currentSiteInfo.snnLicenseType,
    activationContext.invoiceDetails?.id,
  ]);

  //do we need to create a custom school?
  useEffect(() => {
    if (
      isSNNCase({
        type: activationContext.invoiceDetails?.type,
        id: activationContext.invoiceDetails?.id,
      }) &&
      currentSiteInfo.snnLicenseType === "new" &&
      activationContext.invoiceDetails?.status !== "Activated" &&
      parseInt(activationContext.invoiceDetails?.id || "0", 10) <= 0
    ) {
      setShowCreateCustom(true);
      setEdit(true);
    } else {
      setShowCreateCustom(false);
      setEdit(false);
    }
  }, [
    activationContext.invoiceDetails?.type,
    currentSiteInfo.snnLicenseType,
    activationContext.invoiceDetails?.status,
    activationContext.invoiceDetails?.id,
  ]);

  //we need to create, but is there data to create it?
  useEffect(() => {
    if (
      showCreateCustom &&
      activationContext.invoiceDetails?.name &&
      activationContext.invoiceDetails?.state &&
      activationContext.invoiceDetails?.address &&
      activationContext.invoiceDetails?.city &&
      activationContext.invoiceDetails?.zip &&
      activationContext.invoiceDetails?.site_timezone
    ) {
      setCreateCustomSchoolDisabled(false);
    } else {
      setCreateCustomSchoolDisabled(true);
    }
  }, [
    activationContext.invoiceDetails?.zip,
    activationContext.invoiceDetails?.state,
    activationContext.invoiceDetails?.city,
    activationContext.invoiceDetails?.address,
    activationContext.invoiceDetails?.name,
    activationContext.invoiceDetails?.site_timezone,
    showCreateCustom,
  ]);

  //since snn based activation warnings rely on active state in here, check for them here rather than in activate
  useEffect(() => {
    if (
      (activationContext.invoiceDetails?.snnLicenseType === "new" ||
        currentSiteInfo.snnLicenseType === "new") &&
      (!activationContext.invoiceDetails?.id ||
        !activationContext.invoiceDetails?.site_timezone ||
        !activationContext.invoiceDetails?.name)
    ) {
      activationContext.setActivationDisableReasons("snnDetails", "add");
    } else {
      activationContext.setActivationDisableReasons("snnDetails", "remove");
    }

    if (
      (activationContext.invoiceDetails?.snnLicenseType === "renewal" ||
        currentSiteInfo.snnLicenseType === "renewal") &&
      (!activationContext.invoiceDetails?.id ||
        parseInt(activationContext.invoiceDetails?.id || "0", 10) <= 0)
    ) {
      activationContext.setActivationDisableReasons("snnID", "add");
    } else {
      activationContext.setActivationDisableReasons("snnID", "remove");
    }
  }, [
    activationContext.invoiceDetails?.id,
    activationContext.invoiceDetails?.site_timezone,
    activationContext.invoiceDetails?.name,
    currentSiteInfo.snnLicenseType,
  ]);

  return (
    <InvoicePageEditableCard
      title={"School / District Information"}
      handleLocalSave={handleLocalSave}
      saveDisabled={saveDisabled}
      setEdit={setEdit}
      showEditButton={false}
      noPostActivationEdits={true}
    >
      <div className="flex flex-row">
        <div className="flex flex-col gap-4">
          <div className="flex flex-row gap-2">
            <div className="flex grow-0 flex-col">
              <div>
                <dt className="text-sm font-medium text-gray-500">NCES ID</dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {allowNCESEdit ? (
                    <>
                      <input
                        className="border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:rounded-md"
                        type="text"
                        name="id"
                        value={currentSiteInfo.id}
                        onChange={handleDetailChange}
                      />
                    </>
                  ) : (
                    <div>{activationContext?.invoiceDetails?.id || "--"}</div>
                  )}
                </dd>
              </div>
              {/* <div>
              {edit &&
                currentSiteInfo.id &&
                currentSiteInfo.state &&
                currentSiteInfo.name && 
                activationContext && (
                  <>
                    <Link
                      className="mt-1 inline-flex items-center rounded border border-gray-300 bg-white px-2.5 py-1.5 text-xs font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                      to={`${REACT_APP_MANAGER_LINK}/?state=${currentSiteInfo.state}&school=${currentSiteInfo.name}`}
                      target="_blank"
                      rel="noreferrer"
                    >
                      Search for NCES
                    </Link>
                  </>
                )}
            </div> */}
            </div>
            <div className="">
              <dt className="text-sm font-medium text-gray-500">
                School/District Name
              </dt>
              <dd className="mt-1 text-sm text-gray-900">
                {edit ? (
                  <input
                    className="w-full border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:rounded-md"
                    type="text"
                    name="name"
                    value={currentSiteInfo.name}
                    onChange={handleDetailChange}
                  ></input>
                ) : (
                  <div>{activationContext.invoiceDetails?.name || "--"}</div>
                )}
              </dd>
            </div>
            {isSNNCase({
              type: activationContext.invoiceDetails?.type,
              id: activationContext.invoiceDetails?.id,
            }) && (
              <div className="flex grow-0 flex-col items-center">
                <dt className="text-sm font-medium text-gray-500">
                  SNN License Type
                </dt>
                <dd className="mt-1 text-sm text-gray-900">
                  {edit ||
                  activationContext.invoiceDetails?.status !== "Activated" ? (
                    <select
                      name="school_custom_renewal"
                      id="school_custom_renewal"
                      defaultValue={
                        activationContext.invoiceDetails?.snnLicenseType || ""
                      }
                      onChange={handleSnnChange}
                    >
                      <option value=""> - </option>
                      <option value="renewal">Renewal</option>
                      <option value="new">New</option>
                    </select>
                  ) : (
                    <Fragment>
                      {activationContext.invoiceDetails?.snnLicenseType || "--"}
                    </Fragment>
                  )}
                </dd>
                {showCreateCustom && (
                  <Button
                    onClick={handleCreateClick}
                    disabled={createCustomSchoolDisabled}
                    className={"mt-2"}
                    size={"small"}
                  >
                    Create
                  </Button>
                )}
              </div>
            )}
          </div>
          <div className="">
            <dt className="w-full text-sm font-medium text-gray-500">
              Address
            </dt>
            <dd className="mt-1 w-full text-sm text-gray-900">
              {edit ? (
                <input
                  className="min-w-[300px] border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:rounded-md"
                  type="text"
                  value={currentSiteInfo.address}
                  onChange={handleDetailChange}
                ></input>
              ) : (
                <Fragment>
                  {activationContext?.invoiceDetails?.address}
                </Fragment>
              )}
            </dd>
          </div>
          <div className="flex flex-row gap-2">
            <div className="flex flex-col">
              <dt className="text-sm font-medium text-gray-500">City</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {edit ? (
                  <input
                    className="max-w-[200px] border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:rounded-md"
                    type="text"
                    name="city"
                    value={currentSiteInfo.city}
                    onChange={handleDetailChange}
                  ></input>
                ) : (
                  <div>{activationContext.invoiceDetails?.city || "--"}</div>
                )}
              </dd>
            </div>
            <div className="flex flex-col">
              <dt className="text-sm font-medium text-gray-500">State</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {edit ? (
                  <select
                    name="state"
                    id="state"
                    className="border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:rounded-md"
                    value={currentSiteInfo.state}
                    onChange={handleDetailChange}
                  >
                    <option value=""> - </option>
                    {Object.keys(US_STATES).map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                ) : (
                  <div>{activationContext?.invoiceDetails?.state || "--"}</div>
                )}
              </dd>
            </div>
            <div className="flex flex-col">
              <dt className="text-sm font-medium text-gray-500">Zip</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {edit ? (
                  <input
                    className="max-w-[150px] border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:rounded-md"
                    type="text"
                    value={currentSiteInfo.zip}
                    name={"zip"}
                    onChange={handleDetailChange}
                  ></input>
                ) : (
                  <div>{activationContext?.invoiceDetails?.zip || "--"}</div>
                )}
              </dd>
            </div>
          </div>

          <div className="flex flex-row gap-2">
            <div>
              <dt className="text-sm font-medium text-gray-500">Timezone</dt>
              <dd className="mt-1 text-sm text-gray-900">
                {edit || timeZoneEdit ? (
                  <select
                    name="site_timezone"
                    id="site_timezone"
                    className="border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:rounded-md"
                    value={currentSiteInfo.site_timezone}
                    onChange={handleDetailChange}
                  >
                    <option value=""> - </option>
                    {Object.keys(TIMEZONES_FOR_PICKER).map((timezone) => (
                      <option key={timezone} value={timezone}>
                        {timezone}
                      </option>
                    ))}
                  </select>
                ) : (
                  <div>
                    {activationContext?.invoiceDetails?.site_timezone || "--"}
                  </div>
                )}
              </dd>
            </div>
            {edit && (
              <div className="flex-flex-col self-end">
                <dt className="text-sm font-medium text-gray-500">Country</dt>
                <input
                  className="max-w-[150px] border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 md:rounded-md"
                  type="text"
                  value={currentSiteInfo.country}
                  name={"country"}
                  onChange={handleDetailChange}
                />
              </div>
            )}
          </div>
        </div>
        <div className="flex flex-col items-center justify-center gap-2">
          <Warnings className="flex flex-col gap-1">
            {activationContext.state.activationDisableReasons.has(
              "noEntityName"
            ) && (
              <div className="text-center text-sm text-red-500">
                Save entity name
              </div>
            )}
            {activationContext.state.activationDisableReasons.has(
              "noEntityID"
            ) && (
              <div className="text-center text-sm text-red-500">
                save entity ID
              </div>
            )}
            {activationContext.state.activationDisableReasons.has(
              "snnDetails"
            ) && (
              <div className="text-center text-sm text-red-500">
                Save info and create school. If you changed to SNN Renewal, save
                to clear this message
              </div>
            )}
            {activationContext.state.activationDisableReasons.has("snnID") && (
              <div className="text-center text-sm text-red-500">
                save the existing custom id. If you changed to new SNN, save to
                clear this message
              </div>
            )}
            {activationContext.state.activationDisableReasons.has(
              "snnType"
            ) && (
              <div className="text-center text-sm text-red-500">
                For SNN, save SNN license Type
              </div>
            )}
          </Warnings>
        </div>
        <DMConfirmModal
          title="Create Custom School - Confirm Details"
          confirmText={"Create Custom School"}
          onConfirm={() => createCustomSchool()}
          visible={showNewSchoolModal}
          onCancel={() => setShowNewSchoolModal(false)}
        >
          <div className="flex flex-col gap-4">
            <div className="text-lg font-bold">
              Are you sure you want to create a custom school with the following
              details?
            </div>
            {!saveDisabled && (
              <div className="font-bold text-red-500">
                There are unsaved details which will not be reflected in the
                created school. Below are the details that will be used
              </div>
            )}
            <div>
              <div>Name: {activationContext.invoiceDetails?.name}</div>
              <div>Address: {activationContext.invoiceDetails?.address}</div>
              <div>City: {activationContext.invoiceDetails?.city}</div>
              <div>State: {activationContext.invoiceDetails?.state}</div>
              <div>Zip: {activationContext.invoiceDetails?.zip}</div>
              <div>
                Timezone: {activationContext.invoiceDetails?.site_timezone}
              </div>
            </div>
          </div>
        </DMConfirmModal>
      </div>
    </InvoicePageEditableCard>
  );
};

export default SchoolDistrictDetailsForInvoice;
