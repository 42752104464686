import axios from "axios";
import { useDeltaToastContext } from "../../shared/contexts/ToasterContext";
import { ChangeEvent, useState } from "react";
import { deltamathAPI } from "../utils";
import { useUserContext } from "../../shared/contexts/UserContext";
import { handleErr } from "./standards/common/util";
import { useMutation } from "@tanstack/react-query";
import { useDocumentTitle } from "usehooks-ts";

// Standalone function for uploading the file
const uploadEmail = async ({ file, token }: { file: File; token: string }) => {
  const formData = new FormData();
  formData.append("file", file);
  const response = await axios.request<string[]>({
    method: "put",
    url: deltamathAPI() + "/manager_new/users/bulkChangeEmails",
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${token}`,
    },
    data: formData,
  });
  return response.data;
};

/**
 * Utility to allow manager users to bulk edit emails using an excel file.
 */
const BulkEditEmails = () => {
  useDocumentTitle("Bulk Edit Emails");
  const toastContext = useDeltaToastContext();
  const userContext = useUserContext();
  const token = userContext.getJWT();
  const [logs, setLogs] = useState<string[] | undefined>(undefined);

  // Use the useMutation hook
  const { mutate: uploadEmailFile } = useMutation({
    mutationFn: (file: File) => uploadEmail({ file, token }),
    onSuccess: (data) => {
      // Handle success
      toastContext.addToast({
        status: "Success",
        message: "Upload Complete",
      });
      setLogs(data);
    },
    onError: (error) => {
      // Handle error
      toastContext.addToast({ status: "Error", message: handleErr(error) });
    },
  });

  // Event handler that calls the mutate function
  const handleFileUpload = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      uploadEmailFile(file);
      event.target.value = ""; // Reset the input after upload
    }
  };

  return (
    <>
      <div className="grid grid-cols-4 gap-2 pb-4 pt-4">
        <div className="border-solid border-black">
          <div>
            <input
              className="focus:border-primary focus:shadow-primary relative m-0 block w-full min-w-0 flex-auto cursor-pointer rounded border border-solid border-neutral-300 bg-clip-padding px-3 py-[0.32rem] text-xs font-normal text-neutral-700 transition duration-300 ease-in-out file:-mx-3 file:-my-[0.32rem] file:cursor-pointer file:overflow-hidden file:rounded-none file:border-0 file:border-solid file:border-inherit file:bg-neutral-100 file:px-3 file:py-[0.32rem] file:text-neutral-700 file:transition file:duration-150 file:ease-in-out file:[border-inline-end-width:1px] file:[margin-inline-end:0.75rem] hover:file:bg-neutral-200 focus:text-neutral-700 focus:shadow-[0_0_0_1px] focus:outline-none"
              type="file"
              onChange={handleFileUpload}
            />
          </div>
        </div>
      </div>
      {logs && (
        <pre className="rounded bg-neutral-800 p-4 text-neutral-200">
          {logs.map((log, index) => (
            <code key={index}>
              {log}
              <br />
            </code>
          ))}
        </pre>
      )}
    </>
  );
};

export default BulkEditEmails;
