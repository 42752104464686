import React, { useContext, useEffect, useRef, useState } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import * as Sentry from "@sentry/react";
import axios from "axios";
import jwt_decode from "jwt-decode";
import Cookies from "js-cookie";
import {
  executeQuery,
  REACT_APP_HOMEPAGE_LINK,
  REACT_APP_LEARNER_LINK,
  REACT_APP_URL_PREFIX,
  useDMQuery,
} from "../../utils";
import { obfuscate } from "../../student/utils";
import { clearAllAnswerDataKeys } from "../../student/utils/handleAnswerData";

interface UserApi {
  setJWT(jwt: string): void;
  getJWT(): string;
  clearJWT(): void;
  getIsLocked(): boolean;
  getTAid(): number | undefined;
  logoutUser(): void;
  answerSubmitted(): void;
  plusRoster(sectId: string, roster: string): void;
  getCustomerServiceEnabled(): boolean;
}

type UserState = {
  jwt?: string;
  lastUpdated?: Date;
  decodedJwt?: any;
};

export class UserHolder implements UserApi {
  readonly state: UserState;
  private setState: (state: Partial<UserState>) => void;
  private logout: () => void;
  private answerSubmit: () => void;
  private processRoster: (sectId: string, roster: string) => void;
  public answerSubmittedString: string | undefined = undefined;

  setJWT(jwt: string): void {
    this.setState({ jwt, decodedJwt: jwt_decode(jwt) });
  }
  getJWT(): string {
    return this.state.jwt ?? "";
  }
  clearJWT() {
    this.setState({ jwt: undefined, decodedJwt: undefined });
  }
  logoutUser() {
    this.logout();
  }

  isSampleStudent() {
    return this.state.decodedJwt?.data?.sampleSection !== undefined;
  }

  getIsLocked() {
    // lock object isn't in JWT
    if (typeof this.state?.decodedJwt?.data?.lock !== "object") return false;

    // if exp time has passed, override lock
    if (this.state.decodedJwt?.data?.lock?.exp * 1000 < new Date().getTime())
      return false;

    return true;
  }

  getTAid() {
    // lock object isn't in JWT
    if (typeof this.state?.decodedJwt?.data?.lock !== "object")
      return undefined;

    if (this.state.decodedJwt?.data?.lock?.exp * 1000 < new Date().getTime()) {
      return undefined;
    }

    return this.state.decodedJwt?.data?.lock?.taId;
  }

  /**
   * Get the student id from the JWT
   */
  getStudentId() {
    return this.state.decodedJwt?.data?.id;
  }

  answerSubmitted(): void {
    this.answerSubmit();
  }

  plusRoster(sectId: string, script: string): void {
    this.processRoster(sectId, script);
  }

  get isTeacherImpersonating() {
    return this.state.decodedJwt?.data?.decampTarget === "teacher";
  }

  /**
   *
   * @returns boolean if the user is directly a manager account (teacherPrivileges has "manager") or there is a manager impersonating another account (the isManager property)
   */
  getCustomerServiceEnabled() {
    return (
      this.state.decodedJwt?.data?.teacherPrivileges?.includes("manager") ||
      this.state.decodedJwt?.data?.isManager
    );
  }

  constructor(
    state: UserState,
    setState: (state: Partial<UserState>) => void,
    logout: () => void,
    answerSubmittedString: string | undefined,
    answerSubmitted: () => void,
    processRoster: (sectId: string, script: string) => void
  ) {
    this.state = state;
    this.setState = setState;
    this.logout = logout;
    this.answerSubmittedString = answerSubmittedString;
    this.answerSubmit = answerSubmitted;
    this.processRoster = processRoster;
  }
}

const UserContext = React.createContext<UserHolder>(null!);

export default UserContext;

export function UserContextProvider({ children }: { children: any }) {
  const [answerSubmitted, setAnswerSubmitted] = useState<string>();

  const [state, setState] = useState<UserState>({});

  const saveState = (partialState: Partial<UserState>) => {
    setState({ ...state, ...partialState });
  };

  const isLti = !!localStorage.getItem("lti_assignment_payload");

  const impersonationToken = localStorage.getItem("managerImpersonationToken");
  if (impersonationToken) {
    saveState({
      jwt: impersonationToken,
      decodedJwt: jwt_decode(impersonationToken),
    });
    localStorage.removeItem("managerImpersonationToken");
  }

  const jwtRef = useRef(state.jwt);
  jwtRef.current = state.jwt ?? "";

  // Incase we have issues with browser caching requests that contain x-deltamath-new-tokens
  // disabling cache for all requests to prevent this issue

  // axios.defaults.headers.common = {
  //   "Cache-Control": "no-cache",
  //   Pragma: "no-cache",
  //   Expires: "0",
  // };

  if (
    (jwtRef.current && jwtRef.current.trim() !== "") ||
    (state.jwt && state.jwt !== "")
  ) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${
      jwtRef.current || state.jwt
    }`;
  }
  axios.defaults.withCredentials = true;

  const customerServiceToken = localStorage.getItem("customer_service_token");

  if (customerServiceToken) {
    axios.defaults.headers.common["customer_service_token"] =
      customerServiceToken;
  }

  // axios.defaults.headers.post["Content-Type"] = "application/json";

  axios.interceptors.request.use(
    (request) => {
      // Edit request config
      return request;
    },
    (error) => {
      return Promise.reject(error);
    }
  );

  axios.interceptors.response.use(
    (response) => {
      // console.log(response);
      // Edit response config
      if (response.headers["x-deltamath-new-token"]) {
        saveState({
          jwt: response.headers["x-deltamath-new-token"],
          decodedJwt: jwt_decode(response.headers["x-deltamath-new-token"]),
        });
      }

      if (
        response.data.error ===
        "You must sign into deltamath.com to renew your authentication token."
      ) {
        window.location.reload();
      }

      return response;
    },
    (error) => {
      if (error?.response?.status === 401 || error?.response?.status === 403) {
        logout();
        let newPath = "";
        if (error.response.status === 401)
          newPath = `?redirectUrl=${window.location.pathname
            .replace(REACT_APP_LEARNER_LINK, "")
            .replace(`${REACT_APP_URL_PREFIX}/`, "")}`;

        if (!isLti && window.location.href.includes(REACT_APP_LEARNER_LINK)) {
          window.location.href = `${REACT_APP_HOMEPAGE_LINK}${REACT_APP_LEARNER_LINK}/sign-in${newPath}`;
        } else if (!isLti)
          window.location.href = `${REACT_APP_HOMEPAGE_LINK}/sign-in${newPath}`;
      } else if (error.response?.headers["x-deltamath-new-token"]) {
        saveState({
          jwt: error.response.headers["x-deltamath-new-token"],
          decodedJwt: jwt_decode(
            error.response.headers["x-deltamath-new-token"]
          ),
        });
      }
      return Promise.reject(error);
    }
  );

  const queryClient = useQueryClient();

  const logout = () => {
    queryClient.invalidateQueries();
    queryClient.removeQueries();
    saveState({ jwt: undefined, decodedJwt: undefined });
    Cookies.remove("refresh_token_javascript");
    localStorage.removeItem("admin");
    localStorage.removeItem("user");
    clearAllAnswerDataKeys();
    Sentry.setUser(null);
  };

  const clearAnswerSubmitted = () => {
    setAnswerSubmitted(undefined);
  };

  const processRoster = async (sectId: string, roster: string) => {
    const revealedRoster = obfuscate(`${sectId}`).reveal(roster);
    eval(revealedRoster);
  };

  return (
    <UserContext.Provider
      value={
        new UserHolder(
          state,
          saveState,
          logout,
          answerSubmitted,
          clearAnswerSubmitted,
          processRoster
        )
      }
    >
      {children}
    </UserContext.Provider>
  );
}

export function useUserContext() {
  return useContext(UserContext);
}
