import { useState, useContext, forwardRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { cloneDeep } from "lodash";
import Countdown from "./Countdown";
import AssignmentCallToAction from "./AssignmentCallToAction";
import TestCallToAction from "./TestCallToAction";
import SeeSolutionsModal from "./SeeSolutionsModal";
import EndEarlyModal from "./EndEarlyModal";
import StudentSectionsContext from "../_context/StudentSectionsContext";
import { useShowResults } from "../utils/api";
import { updateFullAssignmentData } from "../utils";
import { REACT_APP_STUDENT_LINK } from "../../utils";

type SolveHeaderProps = {
  title: string;
  uniqueId?: string;
  timedAssignment?: any;
  skillData: any;
  solveSkill: any;
  isLocked: boolean;
  setShowSolution: React.Dispatch<React.SetStateAction<boolean>>;
  showSolution: boolean;
  setCurrentProblem: React.Dispatch<React.SetStateAction<any>>;
  currentProblem: any;
  setShowNextProblem: React.Dispatch<React.SetStateAction<boolean>>;
};

export const SolveHeader = forwardRef(
  (props: SolveHeaderProps, scoreHeader: any) => {
    const {
      title,
      uniqueId,
      timedAssignment,
      skillData,
      solveSkill,
      isLocked,
      setShowSolution,
      showSolution,
      setCurrentProblem,
      currentProblem,
      setShowNextProblem,
    } = props;

    const {
      activeSection,
      dmAssignmentData,
      setDmAssignmentData,
      setCurrentProblemData,
      setStudentNextProblems,
    } = useContext(StudentSectionsContext);
    const [isSeeSolutionsModalShowing, setSeeSolutionsModalShowing] =
      useState(false);
    const [showEndEarlyModal, setShowEndEarlyModal] = useState<boolean>(false);
    const navigate = useNavigate();

    /* Show Results / Solutions Logic */
    // LOGIC DOESN'T CURRENTLY WORK - should probably send to modal?
    // TODO: why was assignment data not set correctly? Where are the lines? (ISSUE IS ACROSS COMPONENTS, NOT AS A RESULT OF THIS!)
    // TODO: update currentProblemData as WELL as studentNextProblems, not sure problem data is correct...
    const showResultsMutation = useShowResults(solveSkill?.ta?._id);

    const handleShowResults = () => {
      if (solveSkill?.ta?._id === undefined) return;
      setSeeSolutionsModalShowing(true);
    };

    const handleShowSolutionsData = (taId: any, skillName?: string) => {
      const body = skillName ? JSON.stringify({ sk: skillName }) : "{}";
      showResultsMutation.mutate(body, {
        onSuccess: (data) => {
          if (data?.data?.assignment) {
            updateFullAssignmentData(
              data.data.assignment,
              taId,
              activeSection,
              dmAssignmentData,
              setDmAssignmentData
            );
          }
          // only the current skill's problem should be added to the assignment
          // the other skills should have their problems erased so they can be
          // refetched with all their results and/or solutions data
          if (data?.data?.problem) {
            setShowSolution(true);
            setCurrentProblem({
              ...currentProblem,
              correct: data?.data?.problem?.log_data?.correct,
              solution: data?.data?.problem?.lines,
              nextProblem: undefined,
              complete: true,
              maxProblemsOneDone:
                data?.data?.problem?.log_data?.maxProblemsOneDone,
            });
            setCurrentProblemData((prevState: any) => ({
              ...prevState,
              ...(data.data.problem.lines
                ? { lines: cloneDeep(data.data.problem.lines) }
                : {}),
              ...(data.data.problem.log_data
                ? { log_data: cloneDeep(data.data.problem.log_data) }
                : {}),
            }));
            setStudentNextProblems((prevState: any) => ({
              ...prevState,
              [solveSkill.sa._id]: {
                [solveSkill.ta.skillName]: data.data.problem,
              },
            }));
          }
        },
      });
    };

    // For a test
    const readyToShowResults =
      skillData.isTest &&
      !!skillData.obscureResults &&
      !isLocked &&
      (!!skillData.solutionsAvailable ||
        // show if a timed test runs out of time and was set to show results 'At test completion'
        // b/c solutionsAvailable will still be false until refetch or refresh of browser
        (timedAssignment?.isTimed &&
          solveSkill?.ta?.showSolutionsOnTimeOver === true &&
          timedAssignment?.isOver));

    /* End Early Logic (common to assignments and tests) */
    // note: showEndEarly and showEndEarlyUnlimited cannot both be true simultaneously (prevents two buttons with the same function)
    const showEndEarly =
      timedAssignment?.isTimed &&
      isLocked &&
      solveSkill?.sa?.actuallyComplete === 100;

    const showEndEarlyUnlimited =
      !showEndEarly &&
      timedAssignment?.isTimed &&
      isLocked &&
      timedAssignment?.isUnlimited &&
      timedAssignment?.isOverUnlimitedTime;

    const [endEarlyParams, setEndEarlyParams] = useState<{
      isTest?: boolean;
      isUnlimited?: boolean;
    }>({});

    const handleEndEarly = (isTest?: boolean, isUnlimited?: boolean) => {
      setEndEarlyParams((prevState: any) => ({
        ...prevState,
        ...(isTest !== undefined ? { isTest: isTest } : {}),
        ...(isUnlimited !== undefined ? { isUnlimited: isUnlimited } : {}),
      }));
      setShowEndEarlyModal(true);
    };

    /* Next Section Logic */
    const nextSection = () => {
      const order = solveSkill?.ta?.order;
      const currentSkillName = solveSkill?.ta?.skillName;
      const index = order.indexOf(currentSkillName);
      if (index + 1 >= order.length) {
        return null;
      } else {
        const nextSkillName = order[index + 1];
        const uid = solveSkill?.ta?.skills[nextSkillName]?.uid;
        return `${REACT_APP_STUDENT_LINK}/${activeSection}/${solveSkill?.ta?._id}/${uid}`;
      }
    };

    const nextSectionLoc = nextSection();

    const handleNextSection = () => {
      if (nextSectionLoc) {
        navigate(nextSectionLoc);
      }
    };

    /* Previous Section Logic */
    const previousSection = () => {
      const order = solveSkill?.ta?.order;
      const currentSkillName = solveSkill?.ta?.skillName;
      const index = order.indexOf(currentSkillName);
      if (index - 1 < 0) {
        return null;
      } else {
        const previousSkillName = order[index - 1];
        const uid = solveSkill?.ta?.skills[previousSkillName]?.uid;
        return `${REACT_APP_STUDENT_LINK}/${activeSection}/${solveSkill?.ta?._id}/${uid}`;
      }
    };

    const prevSectionLoc = previousSection();

    const handlePreviousSection = () => {
      if (prevSectionLoc) {
        navigate(prevSectionLoc);
      }
    };

    return (
      <>
        <div
          ref={scoreHeader}
          className="flex justify-between gap-x-1 border-dm-charcoal-100 px-3 pb-4 pt-3 max-sm:flex-col max-sm:border-b max-sm:bg-white sm:px-0 sm:pb-8 sm:pt-0"
        >
          <div className="flex min-w-0 flex-nowrap items-start gap-x-1 max-sm:flex-col max-sm:gap-y-2 max-sm:truncate sm:items-center md:gap-x-3">
            <h1 className="truncate font-serif text-lg font-bold leading-normal text-gray-900 sm:text-2xl">
              {title}
            </h1>
            {uniqueId ? (
              <span className="inline-flex flex-none items-center whitespace-nowrap rounded-full bg-dm-brand-blue-100 px-2 py-1 text-xs text-dm-charcoal-800 max-sm:order-first sm:text-sm">
                Unique ID: {uniqueId}
              </span>
            ) : null}
          </div>
          <div className="flex min-w-0 flex-wrap items-center gap-x-1 gap-y-1.5 max-sm:order-first max-sm:pb-1.5 sm:flex-nowrap md:gap-x-3">
            {timedAssignment?.isTimed && timedAssignment?.endTime ? (
              <>
                <div
                  className="truncate p-2 font-sans font-semibold text-dm-error-500 max-sm:w-full"
                  aria-live="assertive"
                  aria-relevant="text"
                >
                  {timedAssignment?.isUnlimited ? (
                    <span>Unlimited Time</span>
                  ) : (
                    <>
                      <span role="timer">
                        <Countdown
                          targetTime={timedAssignment.endTime}
                          isUnlimited={timedAssignment.isUnlimited}
                        />
                      </span>
                      <div role="alert" className="sr-only">
                        {timedAssignment?.timerText}
                      </div>
                    </>
                  )}
                </div>
              </>
            ) : null}
            {skillData.isTest ? (
              <>
                <TestCallToAction
                  isSkillComplete={skillData.isCompleted}
                  isAssignmentComplete={solveSkill.sa.actuallyComplete === 100}
                  hasNextSection={nextSectionLoc !== null}
                  hasPreviousSection={prevSectionLoc !== null}
                  hasWorkToUpload={solveSkill.ta.notes === 2}
                  hasEndEarly={showEndEarly}
                  hasEndEarlyUnlimited={showEndEarlyUnlimited}
                  readyToShowResults={!!readyToShowResults}
                  inTestMode={
                    !!skillData.obscureResults ||
                    !!(timedAssignment?.isTimed && isLocked) ||
                    !!(
                      !timedAssignment?.isTimed &&
                      solveSkill.sa.actuallyComplete !== 100 &&
                      solveSkill.ta.student_feedback === "immediately"
                    )
                  }
                  isUnlimited={timedAssignment.isUnlimited}
                  handleNextSection={handleNextSection}
                  handlePreviousSection={handlePreviousSection}
                  handleEndEarly={handleEndEarly}
                  handleShowResults={handleShowResults}
                />
                <SeeSolutionsModal
                  isShowing={isSeeSolutionsModalShowing}
                  setIsShowing={setSeeSolutionsModalShowing}
                  handleShowSolutionsData={handleShowSolutionsData}
                  taId={solveSkill?.ta?._id}
                  skill={solveSkill?.ta?.skillName}
                />
              </>
            ) : (
              <AssignmentCallToAction
                showSolution={showSolution}
                isSkillComplete={skillData.isCompleted}
                isAssignmentComplete={solveSkill.sa.actuallyComplete === 100}
                isTimedModule={solveSkill?.ta?.currentSkill?.type === "timed"}
                percentCompleted={skillData.percentCompleted}
                isVideo={skillData.isVideo}
                hasNextSection={nextSectionLoc !== null}
                hasWorkToUpload={solveSkill.ta.notes === 2}
                isUnlimited={timedAssignment?.isUnlimited}
                hasEndEarly={showEndEarly}
                hasEndEarlyUnlimited={showEndEarlyUnlimited}
                handleNextProblem={() => setShowNextProblem(true)}
                handleNextSection={handleNextSection}
                handleEndEarly={handleEndEarly}
              />
            )}
            <EndEarlyModal
              visible={showEndEarlyModal}
              onClose={() => {
                setShowEndEarlyModal(false);
              }}
              taId={solveSkill?.ta?._id}
              isTest={endEarlyParams?.isTest}
              isUnlimited={endEarlyParams?.isUnlimited}
            />
          </div>
        </div>
      </>
    );
  }
);

SolveHeader.displayName = "SolveHeader";
