import { Route, Routes, Navigate, useLocation } from "react-router-dom";
import ErrorBoundary from "../shared/ErrorBoundary";
import Placeholder from "./Placeholder";
import PlusFeatures from "./pages/PlusFeatures";
import { SearchPrototype } from "./pages/SearchPrototype";
import { useAnalytics } from "../shared/useAnalytics";
import { useEffect } from "react";

export default function Teacher(): JSX.Element {
  const location = useLocation();
  const { pageView } = useAnalytics();

  useEffect(() => {
    pageView(
      {},
      {
        plugins: {
          google_tag_manager: true,
        },
      }
    );
  }, [location]);

  return (
    <>
      <div className="mx-auto">
        <Routes>
          <Route
            path="assignments"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route path="data/" element={<Navigate replace to="logs" />} />
          <Route
            path="data/logs"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="data/last-action"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="create/"
            element={<Navigate replace to="assignment" />}
          />
          <Route
            path="create/assignment"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="create/test"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="create/correction"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />

          <Route
            path="manage"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="info"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="terms"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="admin"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="sample"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="ip-analyzer"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="plus/graph-creator"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="email"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="caller"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="plus-features"
            element={
              <ErrorBoundary>
                <PlusFeatures />
              </ErrorBoundary>
            }
          />
          <Route
            path="calculator/"
            element={<Navigate replace to="standard" />}
          />
          <Route
            path="calculator/standard"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="calculator/graphing"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="calculator/statistics"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="faq"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="help"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="profile"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="placeholder"
            element={
              <ErrorBoundary>
                <Placeholder />
              </ErrorBoundary>
            }
          />
          <Route
            path="search"
            element={
              <ErrorBoundary>
                <SearchPrototype />
              </ErrorBoundary>
            }
          />
        </Routes>
      </div>
    </>
  );
}
