import { PropsWithChildren, useEffect } from "react";
import InvoicePageCard from "./InvoicePageCard";
import Button from "../../../student/components/generic/button";
import { useActivationContext } from "./LicenseActivationContext";

const InvoicePageEditableCard: React.FC<
  PropsWithChildren<{
    title: string;
    handleLocalSave: () => void;
    handlePostActivationEdit?: () => void;
    saveDisabled: boolean;
    setEdit: React.Dispatch<React.SetStateAction<boolean>>;
    showEditButton: boolean;
    fullWidth?: boolean;
    overflow?: string;
    saveButtonTitle?: string;
    noPostActivationEdits?: boolean;
  }>
> = ({
  title,
  fullWidth = false,
  children,
  handleLocalSave,
  handlePostActivationEdit,
  saveDisabled,
  setEdit,
  showEditButton,
  overflow,
  saveButtonTitle,
  noPostActivationEdits = false,
}) => {
  const activationContext = useActivationContext();

  const handleEditClick = () => {
    setEdit(true);
  };

  const handleCancelClick = () => {
    setEdit(false);
  };

  const handleSaveClick = () => {
    if (
      activationContext.invoiceDetails?.status === "Activated" &&
      typeof handlePostActivationEdit === "function"
    ) {
      handlePostActivationEdit();
    } else {
      handleLocalSave();
    }
  };

  //We want to globally track which cards may have unsaved changes (using each card's title as a key and the local save button enable/disable logic as a proxy for whether changes are made) so that we can warn users if they are trying to activate but have not updated the invoice w/ pertinent info
  useEffect(() => {
    if (!saveDisabled) {
      activationContext.setUnsavedChanges(title, "add");
    } else {
      activationContext.setUnsavedChanges(title, "remove");
    }
  }, [saveDisabled]);

  return (
    <InvoicePageCard title={title} fullWidth={fullWidth} overflow={overflow}>
      <div className="flex h-full flex-col">
        <div>{children}</div>
        <div className="mt-auto">
          {!(
            activationContext.invoiceDetails?.status === "Activated" &&
            noPostActivationEdits
          ) && (
            <Button
              className={"mr-2 mt-2"}
              disabled={saveDisabled}
              onClick={handleSaveClick}
            >
              {saveButtonTitle || "Save These Updates"}
            </Button>
          )}
          {showEditButton && <Button onClick={handleEditClick}>Edit</Button>}
          {!showEditButton &&
            activationContext.invoiceDetails?.status === "Activated" && (
              <Button onClick={handleCancelClick}>Cancel</Button>
            )}
        </div>
      </div>
    </InvoicePageCard>
  );
};

export default InvoicePageEditableCard;
