import { Link } from "react-router-dom";
import { REACT_APP_MANAGER_LINK } from "../../../utils";
import { useState } from "react";
import Button from "../../../student/components/generic/button";

export const QuotesCell: React.FC<{ quotes: string[] }> = ({ quotes }) => {
  const head = quotes.slice(0, 2);
  const tail = quotes.slice(2);
  const [showTail, setShowTail] = useState(false);
  return (
    <ul>
      {head.map((quote: string) => (
        <li key={quote}>
          <Link
            className="text-indigo-500 hover:underline"
            to={`${REACT_APP_MANAGER_LINK}/quote-lookup/${quote}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {quote}
          </Link>
        </li>
      ))}
      {tail.length > 0 && !showTail && (
        <li>
          <Button type="link" onClick={() => setShowTail(true)}>
            +{tail.length} more
          </Button>
        </li>
      )}
      {showTail && tail.length && (
        <>
          {tail.map((quote: string) => (
            <li key={quote}>
              <Link
                className="text-indigo-500 hover:underline"
                to={`${REACT_APP_MANAGER_LINK}/quote-lookup/${quote}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                {quote}
              </Link>
            </li>
          ))}
          <Button type="link" onClick={() => setShowTail(false)}>
            Collapse
          </Button>
        </>
      )}
    </ul>
  );
};
