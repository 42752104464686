import {
  IAdminMutationData,
  IAdminData,
  AdminMutationOptionsType,
} from "./adminUtils";
import React, { useMemo } from "react";
import AdminInputSwitch from "./AdminInputSwtich";
import clsx from "clsx";
import DeltaMathTable from "../../../../shared/DeltaMathTable";
import {
  coerceLicenseType,
  discriminateInvoiceType,
} from "../../../utils/quoteUtils";
import { useActivationContext } from "../LicenseActivationContext";
import { act } from "react-dom/test-utils";

interface IAdminTableProps {
  admins: IAdminData[];
  disableInput: boolean;
  userDataForAdminMutation: IAdminMutationData;
  setUserDataForAdminMutation: React.Dispatch<
    React.SetStateAction<IAdminMutationData>
  >;
  setCannotDowngrade: React.Dispatch<React.SetStateAction<string>>;
  setMutationAction: React.Dispatch<
    React.SetStateAction<AdminMutationOptionsType>
  >;
}

const AdminTable: React.FC<IAdminTableProps> = ({
  disableInput,
  admins,
  userDataForAdminMutation,
  setMutationAction,
  setUserDataForAdminMutation,
  setCannotDowngrade,
}) => {
  const activationContext = useActivationContext();

  const siteType = discriminateInvoiceType(
    coerceLicenseType(activationContext.invoiceDetails?.type || "")
  );

  const handleCheckboxChange = (rowData: IAdminData, isChecked: boolean) => {
    if (isChecked) {
      setUserDataForAdminMutation({
        _id: rowData._id,
        email: rowData.email,
        first: rowData.first,
        last: rowData.last,
      });
      setMutationAction("upgradeSiteAdmin");
    } else {
      setUserDataForAdminMutation({
        _id: rowData._id,
        email: rowData.email,
      });
      if (
        admins.filter((a: any) => a.account_type === "super_school").length ===
        1
      ) {
        setCannotDowngrade(
          "You cannot downgrade the last primary admin at this school."
        );
      } else if (rowData.account_type.includes("district")) {
        setCannotDowngrade("You cannot downgrade a district level admin.");
      } else {
        setMutationAction("downgradeSiteAdmin");
      }
    }
  };

  const handleRadioChange = (rowData: IAdminData, isChecked: boolean) => {
    if (isChecked) {
      setMutationAction("upgradeSiteAdmin");
      setUserDataForAdminMutation({
        _id: rowData._id,
        email: rowData.email,
        first: rowData.first,
        last: rowData.last,
        account_type: rowData.account_type,
      });
    }
  };

  const columns = useMemo(() => {
    const columnsDefinition: {
      Header: string;
      accessor: string;
      align: string;
      Cell?: (props: any) => JSX.Element;
      disableSortBy?: boolean;
    }[] = [
      {
        Header: "First Name",
        accessor: "first",
        align: "left",
      },
      {
        Header: "Last Name",
        accessor: "last",
        align: "left",
      },
      {
        Header: "Email",
        accessor: "email",
        align: "left",
      },
      {
        Header: "Account Type",
        accessor: "account_type",
        align: "left",
      },
    ];

    if (siteType === "district") {
      columnsDefinition.push({
        Header: "Primary Admin?",
        accessor: "_id",
        align: "center",

        Cell: ({ row, value }: { row: any; value: any }) => (
          <AdminInputSwitch
            key={"input" + value}
            rowData={row.original}
            type="radio"
            checked={row.original.account_type === "super_district"}
            disabled={disableInput}
            className={clsx(
              disableInput
                ? "cursor-not-allowed text-indigo-200"
                : "cursor-pointer border-gray-300 text-indigo-600 shadow-sm focus:ring-indigo-500",
              row.original._id === userDataForAdminMutation._id &&
                "!border-4 !border-dm-earth-yellow-600"
            )}
            onValueChange={handleRadioChange}
          />
        ),
      });
    }

    if (siteType === "school") {
      columnsDefinition.push({
        Header: "Primary Admin?",
        accessor: "_id",
        align: "center",

        Cell: ({ row }: { row: any }) => {
          if (row.original.account_type.includes("school")) {
            return (
              <AdminInputSwitch
                key={"input" + row.original._id}
                rowData={row.original}
                type="checkbox"
                checked={row.original.account_type?.includes("super")}
                disabled={
                  (row.original.account_type?.includes("super") &&
                    row.original.schoolIDs.length > 1) ||
                  disableInput
                }
                className={clsx(
                  (row.original.account_type.includes("super") &&
                    row.original.schoolIDs.length > 1) ||
                    disableInput
                    ? "cursor-not-allowed text-indigo-200"
                    : "cursor-pointer text-indigo-600 shadow-sm focus:ring-indigo-500",
                  row.original._id === userDataForAdminMutation._id &&
                    "!border-4 !border-dm-earth-yellow-600 !text-dm-earth-yellow-600"
                )}
                onValueChange={handleCheckboxChange}
              />
            );
          } else {
            return <span>--</span>;
          }
        },
      });
    }
    return columnsDefinition;
  }, [disableInput, admins, userDataForAdminMutation]);

  return (
    <div className="flex w-full flex-col items-center justify-center gap-4">
      {admins && admins.length > 0 && (
        <DeltaMathTable
          columns={columns}
          data={admins}
          options={{
            initialState: {
              sortBy: [
                {
                  id: "last",
                  desc: false,
                },
              ],
            },
          }}
        />
      )}
    </div>
  );
};

export default AdminTable;
