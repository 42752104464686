import { createContext, useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useDeltaToastContext } from "../../../shared/contexts/ToasterContext";
import {
  coerceLicenseType,
  IInvoice,
  ILicenseSearchResult,
  ISchoolDiggerSearchResult,
  TLicenseRange,
} from "../../utils/quoteUtils";
import {
  IProcessedEstimate,
  IQuickbooksData,
  processQuickbooksEstimate,
} from "../../utils/quickbookDataUtils";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import axios from "axios";
import { deltamathAPI } from "../../utils";

interface ActivationFlowAPI {
  state: ActivationFlowState;
  qboEstimateData: IProcessedEstimate | undefined;
  qboInvoiceData: IQuickbooksData | undefined;
  invoiceDetails: IInvoice | undefined;
  licenseData: ILicenseSearchResult | undefined;
  siteData: ISchoolDiggerSearchResult | undefined;
  fetchingInvoice: boolean;
  setCurrentQuoteStatus(status: IInvoice["status"]): void;
  setQuoteNumber(quoteNumber: string): void;
  simpleQuoteUpdate(body: Partial<IInvoice>): void;
  refetchInvoice(): void;
  setCurrentQuoteType(type: TLicenseRange | ""): void;
  //see InvoicePageEditableCard.tsx for usage of the below function
  setUnsavedChanges(change: string, action: "add" | "remove"): void;
  setActivationWarnings(change: string, action: "add" | "remove"): void;
  setActivationWarnings(change: Set<string>, action: "completeUpdate"): void;
  setActivationDisableReasons(change: string, action: "add" | "remove"): void;
  setActivationDisableReasons(
    change: Set<string>,
    action: "completeUpdate"
  ): void;
}

type ActivationFlowState = {
  quoteNumber: string;
  //below are values for the invoice itself which are editable in some way and should be available in more than one card. While the intention is for updating an invoice to be modular, there is a need for some shared state across the cards, in particular for deciding whether / what warnings to show for activation
  currentQuoteStatus: IInvoice["status"];
  currentQuoteType: TLicenseRange | "";
  globalSaveDisabled: boolean;
  unsavedChanges: Set<string>;
  activationWarnings: Set<string>;
  activationDisableReasons: Set<string>;
};

export class ActivationFlowHolder implements ActivationFlowAPI {
  public readonly state: ActivationFlowState;
  public readonly qboEstimateData: IProcessedEstimate | undefined;
  public readonly qboInvoiceData: IQuickbooksData | undefined;
  public readonly invoiceDetails: IInvoice | undefined;
  public readonly licenseData: ILicenseSearchResult | undefined;
  public readonly siteData: ISchoolDiggerSearchResult | undefined;
  public readonly fetchingInvoice: boolean = false;
  private setState: (
    update:
      | Partial<ActivationFlowState>
      | ((prev: ActivationFlowState) => Partial<ActivationFlowState>)
  ) => void;

  refetchInvoice: () => void;
  simpleQuoteUpdate: (body: Partial<IInvoice>) => void;

  setCurrentQuoteStatus(status: IInvoice["status"]): void {
    this.setState({ currentQuoteStatus: status });
  }

  setQuoteNumber(quoteNumber: string): void {
    this.setState({ quoteNumber });
  }

  setCurrentQuoteType(type: TLicenseRange | ""): void {
    this.setState({ currentQuoteType: type });
  }

  setUnsavedChanges(change: string, action: "add" | "remove"): void {
    this.setState((prevState) => {
      const unsavedChanges = new Set(prevState.unsavedChanges);
      if (action === "add") {
        unsavedChanges.add(change);
      } else if (action === "remove") {
        unsavedChanges.delete(change);
      }
      return { unsavedChanges };
    });
  }

  setActivationWarnings(
    change: string | Set<string>,
    action: "add" | "remove" | "completeUpdate"
  ): void {
    this.setState((prevState) => {
      if (action === "completeUpdate" && change instanceof Set) {
        return { activationWarnings: change };
      } else if (
        (action === "add" || action === "remove") &&
        typeof change === "string"
      ) {
        const activationWarnings = new Set(prevState.activationWarnings);
        if (action === "add") {
          activationWarnings.add(change);
        } else if (action === "remove") {
          activationWarnings.delete(change);
        }
        return { activationWarnings };
      }
      return {};
    });
  }

  setActivationDisableReasons(
    change: string | Set<string>,
    action: "add" | "remove" | "completeUpdate"
  ): void {
    this.setState((prevState) => {
      if (action === "completeUpdate" && change instanceof Set) {
        return { activationDisableReasons: change };
      } else if (
        (action === "add" || action === "remove") &&
        typeof change === "string"
      ) {
        const activationDisableReasons = new Set(
          prevState.activationDisableReasons
        );
        if (action === "add") {
          activationDisableReasons.add(change);
        } else if (action === "remove") {
          activationDisableReasons.delete(change);
        }
        return { activationDisableReasons };
      }
      return {};
    });
  }

  constructor({
    state,
    qboEstimateData,
    qboInvoiceData,
    invoiceDetails,
    licenseData,
    siteData,
    fetchingInvoice,
    setState,
    refetchLicenseData,
    simpleQuoteUpdate,
  }: {
    state: ActivationFlowState;
    qboEstimateData: IProcessedEstimate | undefined;
    qboInvoiceData: IQuickbooksData | undefined;
    invoiceDetails: IInvoice | undefined;
    siteData: ISchoolDiggerSearchResult | undefined;
    licenseData: ILicenseSearchResult | undefined;
    fetchingInvoice?: boolean;
    setState: (
      update:
        | Partial<ActivationFlowState>
        | ((prev: ActivationFlowState) => Partial<ActivationFlowState>)
    ) => void;
    refetchLicenseData: () => void;
    simpleQuoteUpdate: (body: Partial<IInvoice>) => void;
  }) {
    this.state = state;
    this.setState = setState;
    this.qboEstimateData = qboEstimateData;
    this.qboInvoiceData = qboInvoiceData;
    this.invoiceDetails = invoiceDetails;
    this.licenseData = licenseData;
    this.siteData = siteData;
    this.fetchingInvoice = fetchingInvoice || false;
    this.refetchInvoice = refetchLicenseData;
    this.simpleQuoteUpdate = simpleQuoteUpdate;
  }
}

const ActivationFlowContext = createContext<ActivationFlowAPI>(null!);

export default ActivationFlowContext;

export function ActivationFlowContextProvider({
  children,
}: {
  children: React.ReactNode;
}) {
  const params = useParams<{ quoteNumber: string }>();
  const toastContext = useDeltaToastContext();
  const queryClient = useQueryClient();

  const [state, setState] = useState<ActivationFlowState>({
    quoteNumber: "",
    currentQuoteStatus: "",
    currentQuoteType: "",
    globalSaveDisabled: false,
    unsavedChanges: new Set<string>(),
    activationWarnings: new Set<string>(),
    activationDisableReasons: new Set<string>(),
  });

  const saveState = (
    update:
      | Partial<ActivationFlowState>
      | ((prev: ActivationFlowState) => Partial<ActivationFlowState>)
  ) => {
    setState((prev) => {
      const partial = typeof update === "function" ? update(prev) : update;
      return { ...prev, ...partial };
    });
  };

  const quoteNumber = params.quoteNumber;

  const {
    data: quickbooksData,
    isError: isQBOError,
    isFetching: isFetchingQBOData,
    isPending: isPendingQBOData,
    error: qboError,
  } = useQuery({
    queryFn: async () => {
      const { data } = await axios.get(
        deltamathAPI() +
          `/manager_new/invoices/details/${quoteNumber}/quickbooks?updateDMInvoice=true`
      );
      return {
        estimateData:
          typeof data?.estimateData?.DocNumber !== "undefined"
            ? processQuickbooksEstimate(data.estimateData)
            : data?.estimateData,
        invoiceData: data?.invoiceData,
      };
    },
    queryKey: ["qb_data", `${quoteNumber}`],
    enabled: !!quoteNumber,
    refetchOnWindowFocus: false,
  });

  const {
    data,
    isError,
    error,
    isFetching,
    refetch: fetchInvoiceData,
  } = useQuery({
    queryFn: async () => {
      const { data } = await axios.get(
        deltamathAPI() + `/manager_new/invoices/details/${quoteNumber}`
      );
      if (
        data?.invoice?.type &&
        ![
          "individual",
          "school",
          "School License",
          "school_custom",
          "6-12",
          "7-12",
          "7-8",
          "6-8",
          "8-12",
          "9-12",
        ].includes(data.invoice.type)
      ) {
        data.invoice.type = coerceLicenseType(data.invoice.type);
      }
      //name migration issue
      if (!data.invoice?.name && data.invoice?.school) {
        data.invoice.name = data.invoice.school;
      }
      return data;
    },
    queryKey: ["invoice", `${quoteNumber}`],
    enabled: !!quoteNumber && !isPendingQBOData && !isFetchingQBOData,
    refetchOnWindowFocus: false,
  });

  useEffect(() => {
    saveState({ quoteNumber });
  }, [quoteNumber]);

  const { mutate: simpleQuoteUpdate, isPending: contextUpdateIsPending } =
    useMutation({
      mutationFn: (body: Partial<IInvoice>) => {
        return axios.put(
          deltamathAPI() + `/manager_new/invoices/details/${quoteNumber}`,
          body
        );
      },
      onSuccess: () => {
        toastContext.addToast({
          status: "Success",
          message: "Quote updated successfully",
        });
      },
      onError: (error) => {
        toastContext.addToast({
          status: "Error",
          message:
            error.message || "Error updating quote: " + JSON.stringify(error),
        });
      },
      onSettled: () => {
        queryClient.invalidateQueries({
          queryKey: ["invoice", `${quoteNumber}`],
        });
      },
    });

  useEffect(() => {
    if (isError) {
      toastContext.addToast({
        status: "Error",
        message: error.message || "An error occurred: " + JSON.stringify(error),
      });
    }
  }, [isError, error]);

  useEffect(() => {
    if (isQBOError) {
      toastContext.addToast({
        status: "Error",
        message:
          qboError.message || "An error occurred: " + JSON.stringify(qboError),
      });
    }
  }, [isQBOError, qboError]);

  useEffect(() => {
    if (contextUpdateIsPending || isFetching) {
      saveState({ globalSaveDisabled: true });
    } else {
      saveState({ globalSaveDisabled: false });
    }
  }, [contextUpdateIsPending, isFetching]);

  useEffect(() => {
    return () => {
      queryClient.removeQueries({ queryKey: ["qb_data", quoteNumber] });
      queryClient.removeQueries({ queryKey: ["invoice", quoteNumber] });
    };
  }, [queryClient, quoteNumber]);

  return (
    <ActivationFlowContext.Provider
      value={
        new ActivationFlowHolder({
          state,
          setState: saveState,
          refetchLicenseData: fetchInvoiceData,
          simpleQuoteUpdate,
          qboEstimateData: quickbooksData?.estimateData,
          qboInvoiceData: quickbooksData?.invoiceData,
          invoiceDetails: data?.invoice,
          licenseData: data?.licenseData,
          siteData: data?.schoolDiggerData,
          fetchingInvoice: isFetching,
        })
      }
    >
      {children}
    </ActivationFlowContext.Provider>
  );
}

export function useActivationContext() {
  return useContext(ActivationFlowContext);
}
