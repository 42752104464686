import { Analytics } from "analytics";

import { AnalyticsInstance } from "analytics";
import { ANALYTICS_PLUGINS } from "./plugins";
import { isRequestFromProd } from "../../utils";

let analyticsInstance: AnalyticsInstance | null = null;

export function getAnalyticsInstance() {
  if (analyticsInstance === null) {
    analyticsInstance = Analytics({
      app: "DeltaMath",
      debug: !isRequestFromProd(window.location.origin),
      plugins: Object.values(ANALYTICS_PLUGINS),
    });
  }
  return analyticsInstance;
}
