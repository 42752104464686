import { EmptyCell } from "./EmptyCell";

export const CurrencyCell: React.FC = ({ value }: any) =>
  value === undefined ? (
    <EmptyCell />
  ) : (
    <>
      <span className="text-dm-charcoal-500/50">$</span>
      <span className="ml-2 inline-block min-w-16">
        {Intl.NumberFormat("en-us", {
          style: "decimal",
          minimumFractionDigits: 2,
        }).format(value)}
      </span>
    </>
  );
